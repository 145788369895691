import React, { useState, useEffect } from "react";
import "./newpasswordemail.css";
import { useParams, useNavigate } from "react-router-dom";
import Message from "../../LeftBanner";
import { Button } from "react-bootstrap";
import Showpassword from "../../../assets/showPass.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Resetpassword = () => {
  const [visible, setVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(false);
  const navigate = useNavigate();

  const changeVisiblityPass = () => {
    setVisible(!visible);
  };

  const changeVisiblityConfirmPass = () => {
    setVisibleConfirm(!visibleConfirm);
  };

  const { id, token } = useParams();

  const params = {
    id: id,
    token: token,
  };
  console.log(params);

  const userValidCheck = async () => {
    try {
      const response = await axios.get(`/api/forgotpassword/${id}/${token}`, {
        params,
      });
      console.log("response is ", response);
      // const data = response.json();
      // axios se response ek function nhi bnta , its an object
      console.log("verify data is ", data);
      if (response.data.status === 201) {
        console.log("user valid");
      }
    } catch (err) {
      navigate("/linkExpired");
      console.log(err);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("This will run every second!");
      userValidCheck();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleReset = async (e) => {
    e.preventDefault();
    if (password === confirmpassword) {
      const userdetails = {
        id: id,
        token: token,
        password: password,
      };
      console.log(userdetails);
      try {
        const response = await axios.post(
          "/api/updatepassword/email",
          userdetails
        );
        const data = JSON.stringify(response?.data);
        console.log("data is ", JSON.stringify(response?.data));
        if (data) {
          setPassword("");
          setConfirmPassword("");
          setSuccess(true);
        }
        toast("Password is updated");
      } catch (err) {
        console.log(err);
      }
    } else {
      toast("The Passwords do not match");
    }
  };

  return (
    <div className="resetPassWrapper">
      <div className="messageLogin">
        <Message></Message>
      </div>
      <div className="resetFormWrapper">
        <div className="midWrapperReset">
          <div className="pageName">Reset password</div>
          <div className="dontWorryMsg">
            Your new password must be different from previous passwords.
          </div>
          <form onSubmit={handleReset} id="resetForm">
            <div className="passwordWrap">
              <input
                type={visible ? "text" : "password"}
                id="password"
                placeholder="Password"
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                className="inputPassword"
              />
              <div className="showPassword">
                <Button
                  className="showPasswordBtn"
                  onClick={changeVisiblityPass}
                >
                  <img src={Showpassword} alt="/" />
                </Button>
              </div>
            </div>
            <div className="passwordWrap">
              <input
                type={visibleConfirm ? "text" : "password"}
                id="confirmpassword"
                placeholder="Confirm New Password"
                autoComplete="off"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmpassword}
                required
                className="inputPassword"
              />
              <div className="showPassword">
                <Button
                  className="showPasswordBtn"
                  onClick={changeVisiblityConfirmPass}
                >
                  <img src={Showpassword} alt="/" />
                </Button>
              </div>
            </div>
            {/* <Button onClick={userValidCheck}>Check User</Button> */}
          </form>
          <button
            type="submit"
            className="yellowBtn"
            form="resetForm"
            value="Submit"
          >
            Reset Password
          </button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
