import React, { useState, useEffect } from "react";
import Header from "../../Headers/HeaderForms";
import Footer from "../../Footer/Footer.js";
import Dropdown from "../../Usables/Dropdown/Dropdown.js";
import SearchDropdown from "../../Usables/SearchDropdown/index.js";
import MultiSelectDropdown from "../../Usables/MultiSelectDropdown/index.js";
import { useNavigate } from "react-router-dom";
import ResumePicker from "../../Usables/ResumePicker";
import FutureFields from "../FormsData/FutureFields.js";
import OlympiadList from "../FormsData/Olympiads.js";
import CollegeList from "../FormsData/Colleges.js";
import YearOfStudy from "../FormsData/Year.js";
import RegionsModal from "../../Usables/RegionModal/index.js";
import InternDetails from "./InternshipDetails";
import SchoolBoard from "../../options/schoolBoard.js";
import useAuth from "../../../hooks/useAuth.js";
import axios from "../../../api/axios";
import BachelorExams from "../Bachelors/BachelorsExam";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CrossBtn from "./Icons/CrossIcon.js";
import FloatInputField from "../../Usables/FloatInputField/index.js";
import { useLoader } from "../../../context/LoaderContext.js";
import "./index.css";

const BachelorsForm = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { setIsLoading } = useLoader();

  const [college, setCollege] = useState(null);
  const [collegeName, setCollegeName] = useState(null);
  const [year, setYear] = useState(null);
  const [schoolboard, setSchoolBoard] = useState(null);
  const [tenthPercentage, setTenthPercentage] = useState(null);
  const [err, setErr] = useState(null);
  const [schoolboard2, setSchoolBoard2] = useState(null);
  const [twelthPercentage, setTwelthPercentage] = useState(null);
  const [futurefields, setFutureFields] = useState([]);
  const [olympiads, setOlympiads] = useState([]);
  const [intern, setIntern] = useState(null);
  const [err2, setErr2] = useState(null);
  const [fileName, setFileName] = useState("");

  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";

  const newRegion = [
    {
      id: 1,
      regionName: "Select Region",
      subRegions: [],
      check: false,
    },
  ];

  const [regions, setRegions] = useState(newRegion);

  const newExam = [
    {
      type: "text",
      id: 1,
      name: "",
      date: "",
      percentage: "",
      criteria: "100 %",
    },
  ];

  const [exams, setExams] = useState(newExam);

  const handleFile = (file) => {
    setFileName(file.name);
  };

  const handleFileRemove = () => {
    setFileName("");
  };

  const handleYear = (e) => {
    setYear(e.value);
  };

  const handleTenthBoard = (e) => {
    setSchoolBoard(e.value);
  };

  const handleTwelthBoard = (e) => {
    setSchoolBoard2(e.value);
  };

  const handleCollege = (value, label) => {
    setCollegeName(label);
    setCollege(value);
  };

  const handleTenthPercentage = (event) => {
    const value = event.target.value;
    // Check if the value is empty or not a number
    if (value !== "" && isNaN(value)) {
      setErr("Please enter a valid number between 0 and 100");
    } else if (value < 0 || value > 100) {
      setErr("Please enter a number between 0 and 100");
    } else {
      setErr(null); // Clear the error if the value is valid
    }
    setTenthPercentage(value);
  };

  const handleTwelthPercentage = (event) => {
    const value = event.target.value;
    // Check if the value is empty or not a number
    if (value !== "" && isNaN(value)) {
      setErr2("Please enter a valid number between 0 and 100");
    } else if (value < 0 || value > 100) {
      setErr2("Please enter a number between 0 and 100");
    } else {
      setErr2(null); // Clear the error if the value is valid
    }
    setTwelthPercentage(value);
  };

  const handleOlympiads = (options) => {
    setOlympiads(options);
  };

  const handleFutureFields = (options) => {
    setFutureFields(options);
  };

  const handleInternDetails = (internDetails) => {
    // Handle intern details, you can set them in the state or send to the server
    setIntern(internDetails);
    console.log("Intern Details:", JSON.stringify(internDetails));
  };

  const getFutureFields = (arr) => {
    let FutureFieldsLabels = [];
    let FutureFieldsValues = [];
    for (let i = 0; i < arr.length; i++) {
      FutureFieldsLabels.push(arr[i].label);
      FutureFieldsValues.push(arr[i].value);
    }
    return { FutureFieldsLabels, FutureFieldsValues };
  };

  const getOlympiads = (arr) => {
    let OlympiadLabels = [];
    let OlympiadValues = [];
    for (let i = 0; i < arr.length; i++) {
      OlympiadLabels.push(arr[i].label);
      OlympiadValues.push(arr[i].value);
    }
    return { OlympiadLabels, OlympiadValues };
  };

  const { OlympiadLabels, OlympiadValues } = getOlympiads(olympiads);

  const { FutureFieldsLabels, FutureFieldsValues } =
    getFutureFields(futurefields);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const userDetail = await axios.get("/api/getuserdata", {
        params: {
          userID: auth.userID,
        },
      });
      const userData = userDetail.data;

      console.log("userDetail is ", userData);
      const data = {
        userID: JSON.stringify(auth.userID),
        college: JSON.stringify(college),
        year: JSON.stringify(year),
        tenthBoard: JSON.stringify(schoolboard),
        olympiadpartipated: JSON.stringify(OlympiadValues),
        futurefields: JSON.stringify(FutureFieldsValues),
        twelthBoard: JSON.stringify(schoolboard2),
        tenthPercentage: JSON.stringify(tenthPercentage),
        twelthPercentage: JSON.stringify(twelthPercentage),
        exams: JSON.stringify(exams),
        interestedregions: JSON.stringify(regions),
        intern: JSON.stringify(intern),
      };
      console.log("all data is", data);

      const controller = new AbortController();
      const response1 = await axiosPrivate.post("/api/bachelors", data, {
        signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      });

      if (response1.ok) {
        const responseData = await response1.json();
        console.log("Bachelors details saved successfully:", responseData);
      } else {
        console.log("The current data state is :", response1.statusText);
      }
      await axiosPrivate.post(
        SAVE_LOGIN_ROUTE,
        {
          userID: auth.userID,
          loginroute: "profile",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );
      fetchOpportunities(userData);
      // DONT PUT AWAIT IN FETCH OPPORTUNITIES
      navigate("/profile");
      controller.abort();
    } catch (err) {
      // Handle abort errors explicitly
      if (err.name === "AbortError") {
        console.log("Request was aborted");
      } else {
        console.log("An error occurred:", err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOpportunities = async (userData) => {
    try {
      const gender = userData.gender;
      const country = userData.country;
      const response = await axios.post(
        "https://opgururag.azurewebsites.net/search",
        {
          query: `Find ten different opportunities that best match the profile of a user who is currently a Bachelor's student. The student is a ${gender}, studying at ${collegeName}, and is currently in their ${year} Year. The student is from ${country} and their fields of interest for future study include ${JSON.stringify(
            FutureFieldsLabels
          )}. They scored ${tenthPercentage}% in 10th grade from the ${schoolboard} board and ${twelthPercentage}% in 12th grade from the ${schoolboard2} board. The student is interested in opportunities in the following regions: ${JSON.stringify(
            regions
          )}. In the past, the student has participated in the following Olympiads: ${JSON.stringify(
            OlympiadLabels
          )}, and they have appeared for the following proficiency exams: ${JSON.stringify(
            exams
          )}. Please find opportunities including internships, scholarships, fellowships, exchange programs, and conferences. Provide maximum information for each opportunity, including the title, application deadline, eligibility criteria (such as age limits, GPA requirements, current degree pursuing, eligible regions, citizenship, work experience), location/country, description, and type of opportunity. Return the results in a structured format for easy parsing and integration.`,
          userID: auth.userID,
        }
      );
      console.log("Response data after OPGURURAG API is =>", response.data);
      const finalData = response.data;
      console.log("finalData is ", finalData);
    } catch (error) {
      console.error("Error fetching Opportunities from OPGURURAG :", error);
    }
  };

  return (
    <div className="userFormWrapper">
      <Header />
      <div className="studentFormWrapper">
        <div className="formHeading">Registration Form for Bachelors</div>
        <div className="formMessage">
          Enter all Your personal details mention below
        </div>
        <form style={{ width: "100%", marginBottom: "1rem" }}>
          <div className="inputForm">
            <SearchDropdown
              options={CollegeList}
              onSelect={handleCollege}
              placeholderInput="Search your College"
              placeholderDropdown="College Name"
            />
            <br />
            <Dropdown
              name="TenthBoard"
              value={schoolboard}
              options={SchoolBoard}
              placeHolder="TenthBoard"
              onChange={handleTenthBoard}
            ></Dropdown>
            <FloatInputField
              name="TenthPercentage"
              placeHolder="Tenth Percentage"
              handleInput={handleTenthPercentage}
              err={err}
              inputVal={tenthPercentage}
            ></FloatInputField>
            <Dropdown
              name="TwelthBoard"
              value={schoolboard2}
              options={SchoolBoard}
              placeHolder="Twelth Board"
              onChange={handleTwelthBoard}
            ></Dropdown>
            <FloatInputField
              name="TwelthPercentage"
              placeHolder="12th Percentage"
              handleInput={handleTwelthPercentage}
              err={err2}
              inputVal={twelthPercentage}
            ></FloatInputField>
            <Dropdown
              name="currentYear"
              value={year}
              options={YearOfStudy}
              placeHolder="Current Year of Study"
              onChange={handleYear}
            ></Dropdown>
          </div>
          <div className="inputForm FutureFields">
            <MultiSelectDropdown
              options={FutureFields}
              onSelect={handleFutureFields}
              placeholderDropdown="Interested fields of study in Future"
              placeholderInput="Search for interested fields"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
            <br />
            <MultiSelectDropdown
              options={OlympiadList}
              onSelect={handleOlympiads}
              placeholderDropdown="Olympiads participated"
              placeholderInput="Search olympiads"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
          </div>
          <div className="inputForm">
            <BachelorExams exams={exams} setExams={setExams}></BachelorExams>
          </div>
        </form>
        <div style={{ width: "100%" }}>
          <RegionsModal
            regions={regions}
            setRegions={setRegions}
          ></RegionsModal>
        </div>
        <div
          className="bachelorFormInput"
          style={{ alignSelf: "flex-start", margin: "2rem" }}
        >
          <ResumePicker
            fileName={fileName}
            handleFile={handleFile}
            handleFileRemove={handleFileRemove}
          ></ResumePicker>
        </div>
        {fileName !== "" ? (
          <div className="inputForm uploadedFile">
            <div
              className="inputForm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "92%",
              }}
            >
              <div>Uploaded file : {fileName}</div>
            </div>
            <button className="removeBtn" onClick={handleFileRemove}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {CrossBtn}
              </div>
            </button>
          </div>
        ) : (
          <div></div>
        )}
        <div style={{ width: "100%", margin: "1rem" }}>
          <InternDetails onInternDetails={handleInternDetails}></InternDetails>
        </div>
        <button
          onClick={handleClick}
          style={{ width: "50%" }}
          className="yellowBtn"
        >
          Save and Continue
        </button>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default BachelorsForm;
