import React from "react";
import { useNavigate } from "react-router-dom";
import SidebarFields from "./SidebarFields.js";
import "./index.css";
import useLogout from "../../../hooks/useLogout";

const Sidebar = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const signOut = async () => {
    await logout();
    navigate("/");
  };
  return (
    <div className="sidebarWrapper">
      <div className="sidebarRoutes">
        {SidebarFields.map((val, key) => {
          return (
            <div
              className="sidebarRoute"
              id={
                window.location.pathname === "/" + val.linkTo
                  ? "sideActive"
                  : "sideInactive"
              }
              onClick={() => {
                window.location.pathname = val.linkTo;
              }}
            >
              <div className="routeIcon">
                <img src={val.Icon} alt="/" />
              </div>
              <div className="routeName">{val.name}</div>
            </div>
          );
        })}
      </div>
      <div className="sidebarOptions">
        <div className="invoice" style={{ padding: "1rem" }}>
          Invoice
        </div>
        <div className="faq" style={{ padding: "1rem" }}>
          FAQ
        </div>
        <div className="helpSupport" style={{ padding: "1rem" }}>
          Help & Support
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
