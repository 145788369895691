import React from "react";
import "./index.css";

const CategoryCard = (props) => {
  return (
    <div className="category">
      <div className="categoryImg">
        <img src={props.categoryImg} alt="/" />
      </div>
      <div className="categoryType">{props.type}</div>
      <div className="categoryInfo">{props.info}</div>
    </div>
  );
};

export default CategoryCard;
