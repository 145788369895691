import React, { useEffect, useState } from "react";
import BachelorExam from "./BachelorExam";
import axios from "../../../../api/axios";

const BachelorsExam = ({ exams, setExams }) => {
  const [options, setOptions] = useState([]);

  const addExam = () => {
    setExams((s) => {
      const lastId = s[s.length - 1].id;
      return [
        ...s,
        {
          id: lastId + 1,
          type: "text",
          name: "",
          date: "",
          percentage: "",
        },
      ];
    });
  };

  useEffect(() => {
    const fetchexams = async () => {
      try {
        const response = await axios.get("/api/proficiencyExams");
        const originalArr = response.data;
        console.log("originaldata is", originalArr);
        const arr = originalArr.map((e) => ({
          name: e.examname,
          criteria: e.criteria,
        }));
        setOptions(arr);
      } catch (error) {
        console.error("Error fetching exams :", error);
      }
    };
    fetchexams();
  }, []);

  return (
    <div className="bachelorExamsWrapper">
      {" "}
      <div>
        {exams.map((item, i) => {
          return (
            <div>
              <div className="addedExam">
                <BachelorExam
                  key={item}
                  exams={exams}
                  setExams={setExams}
                  options={options}
                  id={i + 1}
                ></BachelorExam>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <div className="addMoreBtnCon" onClick={addExam}>
          <div className="addMoreBtn">Add More +</div>
        </div>
      </div>
    </div>
  );
};

export default BachelorsExam;
