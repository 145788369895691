const Degrees = [
  { label: "Master of Business Administration (MBA)", value: "1" },
  { label: "Master of Science in Management (MSM)", value: "2" },
  { label: "Master of Public Administration (MPA)", value: "3" },
  { label: "Master of Finance (MFin)", value: "4" },
  { label: "Master of Marketing (MMktg)", value: "5" },
  { label: "Master of Engineering (MEng)", value: "6" },
  { label: "Master of Science in Engineering (MSE)", value: "7" },
  { label: "Master of Information Technology (MIT)", value: "8" },
  { label: "Master of Computer Science (MCS)", value: "9" },
  { label: "Master of Science (MS)", value: "10" },
  { label: "Master of Mathematics (MMath)", value: "11" },
  { label: "Master of Statistics (MStat)", value: "12" },
  { label: "Master of Arts (MA)", value: "13" },
  { label: "Master of Fine Arts (MFA)", value: "14" },
  { label: "Master of Music (MMus)", value: "15" },
  { label: "Master of Social Work (MSW)", value: "16" },
  { label: "Master of Public Health (MPH)", value: "17" },
  { label: "Master of International Relations (MIR)", value: "18" },
  { label: "Master of Education (MEd)", value: "19" },
  { label: "Master of Arts in Teaching (MAT)", value: "20" },
  { label: "Master of Science in Education (MSEd)", value: "21" },
  { label: "Master of Laws (LLM)", value: "22" },
  { label: "Master of Legal Studies (MLS)", value: "23" },
  { label: "Master of Science in Nursing (MSN)", value: "24" },
  { label: "Master of Health Administration (MHA)", value: "25" },
  { label: "Master of Environmental Science (MEnvSci)", value: "26" },
  { label: "Master of Data Science (MDS)", value: "27" },
  { label: "Master of Urban Planning (MUP)", value: "28" },
];

export default Degrees;
