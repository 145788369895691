import React from "react";
import "./ResetSuccess.css";
import Message from "../../LeftBanner";
import ResetSuccessImg from "../../../assets/ResetSuccess.png";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ResetSuccess = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/login");
  };
  return (
    <div className="ResetSuccess">
      <div className="messageLogin">
        <Message></Message>
      </div>
      <div className="ResetSuccessRight">
        <div className="midWrapperResetSuccess">
          <div className="otpMessage">Password Updated</div>
          <div className="ResetSuccessMsg">
            Your new password has been successfully updated
          </div>
          <div className="ResetSuccessImgCon">
            <img src={ResetSuccessImg} alt="/" className="ResetSuccessImg" />
          </div>
          <Button
            className="BacktoLoginBtn"
            onClick={handleClick}
            className="yellowBtn"
          >
            Back to Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetSuccess;
