import React, { useState } from "react";
import Message from "../LeftBanner";
import "./Choices.css";
import SelectRole from "../SelectRoles/SelectRole.js";
import Classes from "../SelectRoles/Classes.js";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios.js";
import useAuth from "../../hooks/useAuth.js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate.js";
import { useLoader } from "../../context/LoaderContext.js";

const SelectClass = () => {
  const { setIsLoading } = useLoader();

  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";

  const { auth } = useAuth();

  const roleSetter = (roleval) => {
    setRole(roleval);
    console.log(role);
  };

  const handleClick = async () => {
    setIsLoading(true);
    let nextUrl = "";
    if (role === "Upto 8th") {
      nextUrl = "below8thform";
    } else if (role === "9th-10th") {
      nextUrl = "9-10th";
    } else if (role === "11th-12th") {
      nextUrl = "11-12th";
    }

    await axiosPrivate.post(
      SAVE_LOGIN_ROUTE,
      {
        userID: auth.userID,
        loginroute: nextUrl,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      }
    );
    setIsLoading(false);
    navigate(`/${nextUrl}`);
  };

  const handleBack = async () => {
    const response = await axiosPrivate.post(
      SAVE_LOGIN_ROUTE,
      {
        userID: auth.userID,
        loginroute: "selectEducation",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      }
    );
    console.log("response from saving route api is ", response);
    // If everything is successful, then only we will navigate
    navigate("/selectEducation");
  };

  return (
    <div className="roleWrapper">
      <div className="messageRoleWrapper">
        <Message></Message>
      </div>
      <div className="Roles">
        <SelectRole
          options={Classes}
          roleSetter={roleSetter}
          heading="Select Class"
          subHeading="Select your current class"
        ></SelectRole>
        <div className="personalDetailsBtns width58">
          <button className="yellowBtn Btn1" onClick={handleBack}>
            Back
          </button>
          <button className="yellowBtn Btn1" onClick={handleClick}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectClass;
