// ChatIcon.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import ChatModal from "../../ChatBot/ChatBot.js";

const ChatIcon = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faComments}
        onClick={toggleModal}
        style={{
          cursor: "pointer",
          position: "fixed",
          bottom: "15px",
          right: "15px",
          fontSize: "24px",
          color: "#dfa114",
          zIndex: "2000",
        }}
      />
      {isModalOpen && <ChatModal onClose={closeModal} />}
    </>
  );
};

export default ChatIcon;
