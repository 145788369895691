import React from "react";
import Navbar from "../Navbar";
import Landing from "../Landing";
import AboutUsInfo from "./AboutUsInfo";
import OurTeam from "./OurTeam";
import Mission from "./Mission";
import Vision from "./Vision";
import Footer from "../Footer/Footer.js";
import "./index.css";
import { ScrollRestoration } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="aboutusWrapper">
      <div className="aboutUsNavbar">
        <Navbar></Navbar>
      </div>
      <div className="aboutUsLanding">
        <Landing
          heading1="Your AI-powered gateway to global opportunities & mentorship."
          heading2="We empower students to find their perfect fit, wherever in the world it may be."
          buttonName="Apply Now"
          buttonNav="Apply Now"
        ></Landing>
      </div>
      <div className="aboutUsSection">
        <AboutUsInfo></AboutUsInfo>
      </div>
      <div className="aboutUsSection">
        <OurTeam></OurTeam>
      </div>
      <div className="aboutUsSection">
        <Mission></Mission>
      </div>
      <div className="aboutUsSection">
        <Vision></Vision>
      </div>

      <div className="aboutUsSection">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default AboutUs;
