import React from "react";
import "./index.css";
import CategoryCard from "./CategoryCard";
import CategoryInfo from "./categories.js";

const Categories = () => {
  return (
    <div className="categoryWrapper">
      <div className="homeHeading1">
        Opportunities{" "}
        <span className="homeHeadingSpan" style={{ color: "#DFA114" }}>
          that
        </span>{" "}
        you{" "}
        <span className="homeHeadingSpan" style={{ color: "#DFA114" }}>
          deserve
        </span>
      </div>
      <div className="categoryHeading2">
        Better platform for a better future
      </div>
      <div className="categories">
        {CategoryInfo.map((category) => {
          return (
            <div className="eachCategory">
              <CategoryCard
                type={category.type}
                info={category.Info}
                categoryImg={category.url}
              ></CategoryCard>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
