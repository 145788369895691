import React, { useState } from "react";
import "./index.css";

const Dropdown = ({
  options = [],
  onSelect,
  placeholderDropdown,
  placeholderInput,
}) => {
  const DropdownIcon = () => {
    return (
      <svg height="20" width="20" viewBox="0 0 20 20">
        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
      </svg>
    );
  };

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option.value, option.label);
    }
  };

  return (
    <div className="searchDropdownWrapper">
      <div
        className="searchDropdownToggle"
        onClick={() => setIsOpen(!isOpen)}
        role="button" // Using div with role button as an alternative to an actual button
      >
        {selectedOption && (
          <span className="searchDropdownLabel">{placeholderDropdown}</span>
        )}
        <div>
          {" "}
          {selectedOption ? selectedOption.label : placeholderDropdown}
        </div>
        <div>
          <DropdownIcon />
        </div>
      </div>

      {isOpen && (
        <div className="searchDropdownMenu">
          <input
            type="text"
            placeholder={placeholderInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="dropdownSearchInput"
          />
          <ul>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li key={option.value} onClick={() => handleSelect(option)}>
                  {option.label}
                </li>
              ))
            ) : (
              <li>No options found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
