import React from "react";
import Sidebar from "../../Usables/Sidebar";
import Header from "../../Headers/HeaderProfile";
import Mentors from "./Mentors";
import "./index.css";
import OpportunityDetail from "./OpportunityDetail";
const OpportunityDetails = () => {
  return (
    <div className="opportunityWrapper">
      <div className="opportunityHeader">
        <Header></Header>
      </div>
      <div className="opportunityBottom">
        <div className="opportunitySidebar">
          <Sidebar></Sidebar>
        </div>
        <div className="opportunityDetailsRight">
          <div className="oppDetails">
            <OpportunityDetail></OpportunityDetail>
          </div>
          <div className="mentors">
            <Mentors></Mentors>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
