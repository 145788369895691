const OpGuruRoutes = [
  {
    name: "Profile",
    linkTo: "profile",
  },
  {
    name: "Dashboard",
    linkTo: "dashboard",
  },
  {
    name: "Opportunities",
    linkTo: "opportunities",
  },
  {
    name: "Support",
    linkTo: "support",
  },
];

export default OpGuruRoutes;
