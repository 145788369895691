const WorldRegion = [
  {
    id: 1,
    label: "Northern America",
  },
  {
    id: 2,
    label: "Oceania",
  },
  {
    id: 3,
    label: "Africa",
  },
  {
    id: 4,
    label: "Europe",
  },
  {
    id: 5,
    label: "Asia",
  },
  {
    id: 6,
    label: "The Carribean and Latin America",
  },
  {
    id: 7,
    label: "Middle East",
  },
  {
    id: 8,
    label: "Baltics",
  },
];

export default WorldRegion;
