import React, { useState } from "react";
import Message from "../LeftBanner";
import "./Choices.css";
import SelectRole from "../SelectRoles/SelectRole.js";
import Roles from "../SelectRoles/Roles.js";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate.js";
import { useLoader } from "../../context/LoaderContext.js";

const UserType = () => {
  const { setIsLoading } = useLoader();

  const navigate = useNavigate();
  const { auth } = useAuth();
  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";
  const SAVE_USERTYPE_ROUTE = "/api/saveUserType";

  const axiosPrivate = useAxiosPrivate();

  const [role, setRole] = useState(null);
  const accessToken = auth.accessToken;

  const roleSetter = (roleval) => {
    setRole(roleval);
    console.log(role);
  };

  const handleClick = async () => {
    try {
      // Also save the current role in the database
      setIsLoading(true);
      const response1 = await axiosPrivate.post(
        SAVE_USERTYPE_ROUTE,
        {
          userID: auth.userID,
          userType: role,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );
      console.log("response from saving userType api is ", response1);

      // Here you need to save the next route ie personal details into your db
      const response2 = await axiosPrivate.post(
        SAVE_LOGIN_ROUTE,
        {
          userID: auth.userID,
          loginroute: "personaldetails",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );
      console.log("response from saving route api is ", response2);
      // If everything is successful, then only we will navigate
      navigate("/personaldetails", { state: { usertype: role } });
    } catch (err) {
      console.log("err is ", err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="roleWrapper">
      <div className="messageRoleWrapper">
        <Message></Message>
      </div>
      <div className="Roles">
        <SelectRole
          options={Roles}
          roleSetter={roleSetter}
          heading="Select Role"
          subHeading="Please Select your role"
        ></SelectRole>
        <button
          title="Next"
          onClick={handleClick}
          className="yellowBtn width58"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UserType;
