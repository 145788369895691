import React from "react";
import Navbar from "../Navbar";
import Landing from "../Landing/index.js";
import Testimonials from "./Testimonials";
import Categories from "./Categories";
import Associations from "./Associations";
import Benifits from "./Benifits";
import FaqPage from "./FaqPage/index.js";
import ContactForm from "./ContactPage/index.js";
import Footer from "../Footer/Footer.js";
import "./index.css";

const Homepage = () => {
  return (
    <div className="HomeWrapper">
      <div className="homeLandingWrapper">
        <div className="homeNavbar">
          <Navbar />
        </div>
        <div className="homeLanding">
          <Landing
            heading1="We help you find the best opportunities globally"
            heading2="Taking your progress to the next level"
            buttonName="Lets explore!"
            buttonNav="Lets explore!"
          ></Landing>
        </div>
      </div>
      <div className="homeSection">
        <Testimonials></Testimonials>
      </div>
      <div className="homeSection">
        <Categories></Categories>
      </div>
      <div className="homeSection">
        <Associations></Associations>
      </div>
      <div className="homeSection">
        <Benifits></Benifits>
      </div>
      <div className="homeSection">
        <FaqPage></FaqPage>
      </div>
      <div className="homeSection">
        <ContactForm></ContactForm>
      </div>
      <div className="homeSection">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Homepage;
