import Students from "../../../assets/Students.png";
import Employees from "../../../assets/Employees.png";
import Parents from "../../../assets/Parents.png";
import Institutions from "../../../assets/Institutions.png";

const CategoryInfo = [
  {
    id: 1,
    url: Students,
    type: "Students",
    Info: "Students can have a chance to get to know better options for their bright future",
  },
  {
    id: 2,
    url: Employees,
    type: "Employees",
    Info: "Freshers as well as experienced employees can find a better place to build themselves ",
  },
  {
    id: 3,
    url: Parents,
    type: "Parents",
    Info: "Parents will get to know about options that might help their children to expand their knowledge ",
  },
  {
    id: 4,
    url: Institutions,
    type: "Institutions",
    Info: "By using our platform, schools and colleges will be able to offer more opportunities to their students",
  },
];

export default CategoryInfo;
