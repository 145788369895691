import React from "react";
import BenifitsImg from "../../../assets/BenifitsImg.jpg";
import Benifit1 from "../../../assets/benifit1.png";
import Benifit2 from "../../../assets/benifit2.png";
import Benifit3 from "../../../assets/benifit3.png";
import Benifit4 from "../../../assets/benifit4.png";
import BenifitsImgHomepage from "../../../assets/BenifitsImgHomepage.png";
import "./index.css";
import LazyImage from "../../Usables/LazyImage";

const Benifits = () => {
  return (
    <div className="benifitWrapper">
      <div className="homeHeading1">
        <span className="homeHeadingSpan" style={{}}>
          Maximize
        </span>{" "}
        Your Potential with
        <span className="homeHeadingSpan" style={{}}>
          Tailored Guidance
        </span>{" "}
        and
        <span className="homeHeadingSpan" style={{ color: "#DFA114" }}>
          Global Opportunities.
        </span>
      </div>

      <div className="benefitsInfo">
        <LazyImage
          src={BenifitsImgHomepage}
          alt="Benifits Image"
          style={{ width: "85%", paddingLeft: "10rem" }}
          containerStyle={{ width: "50%" }}
          mobileStyle={{ width: "100%" }}
          mobileContainerStyle={{
            width: "80%",
            display: "flex",
            margin: "auto",
          }}
        />
        <LazyImage
          src={BenifitsImg}
          alt="Benifits Image"
          style={{ width: "60%", paddingLeft: "10rem" }} // Optional: Custom styles for the image
          containerStyle={{ width: "50%" }}
          mobileStyle={{ width: "100%" }}
          mobileContainerStyle={{
            width: "70%",
            display: "flex",
            margin: "auto",
          }}
        />
      </div>
    </div>
  );
};

export default Benifits;
