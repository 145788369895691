import React, { useState } from "react";
import Header from "../../Headers/HeaderForms";
import Footer from "../../Footer/Footer.js";
import Dropdown from "../../Usables/Dropdown/Dropdown.js";
import "./index.css";
import CurrentClass from "../../options/CurrentClass8th.js";
import SchoolBoard from "../../options/schoolBoard.js";
import Olympiads from "../../options/Olympiads.js";
import Opportunities from "../../options/OpportunitiesTypes8th.js";
import Subjects from "../../options/InterestedSubjects8th.js";
import { useLocation, useNavigate } from "react-router-dom";
import Budget from "../../options/Budget.js";

import axios from "axios";

const ParentsForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [currentclass, setClass] = useState(null);
  const [schoolboard, setBoard] = useState(null);
  const [olympiads, setOlympiads] = useState(null);
  const [opportunities, setOpportunities] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [budget, setBudget] = useState([]);
  const [err, setErr] = useState(null);

  const handleClass = (e) => {
    setClass(e.value);
  };

  const handleBoard = (e) => {
    setBoard(e.value);
  };

  const handleOlympiads = (e) => {
    let olympiadValues = [];
    console.log(typeof e);
    console.log(e);
    // e is an object here with all olympiads with label and value which are selected
    Object.keys(e).forEach(function (key) {
      console.log(e[key].value);
      olympiadValues = [...olympiadValues, e[key].value];
    });
    // console.log(olympiadValues);
    olympiadValues.sort();
    console.log(olympiadValues);
    setOlympiads(JSON.stringify(olympiadValues));
    console.log(olympiads);
  };

  const handleOpportunities = (e) => {
    let opps = [];
    console.log(typeof e);
    console.log(e);
    // e is an object here with all olympiads with label and value which are selected
    Object.keys(e).forEach(function (key) {
      console.log(e[key].value);
      opps = [...opps, e[key].value];
    });
    // console.log(olympiadValues);
    opps.sort();
    console.log(opps);
    setOpportunities(JSON.stringify(opps));
  };

  const handleSubjects = (e) => {
    let subjects = [];
    console.log(typeof e);
    console.log(e);
    // e is an object here with all olympiads with label and value which are selected
    Object.keys(e).forEach(function (key) {
      console.log(e[key].value);
      subjects = [...subjects, e[key].value];
    });
    subjects.sort();
    console.log(subjects);
    setSubjects(JSON.stringify(subjects));
  };

  const handleBudget = (selectedOption) => {
    setBudget(selectedOption.value);
  };

  const handleClick = async () => {
    try {
      const data = {
        currentclass: currentclass,
        schoolboard: schoolboard,
        user_id: state.user_id,
        olympiads: olympiads,
        // add more fields from state which you want to add
      };
      console.log(data);
      const response = await axios.post("/api/parents", data);
      console.log(JSON.stringify(response?.data));
      navigate("/dashboard");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="userFormWrapper">
      <Header></Header>
      <div className="studentFormWrapper">
        <div className="formHeading">Registration Form for Parents</div>
        <div className="formMessage">
          Enter all the details about your child here
        </div>
        <form className="smallForm" style={{ width: "100%" }}>
          <div className="inputForm">
            <Dropdown
              name="class"
              value={currentclass}
              options={CurrentClass}
              placeHolder="Current Class"
              onChange={handleClass}
            ></Dropdown>
          </div>
          <div className="inputForm Board">
            <Dropdown
              name="schoolboard"
              value={schoolboard}
              options={SchoolBoard}
              placeHolder="School Board"
              onChange={handleBoard}
            ></Dropdown>
          </div>
          <div className="inputForm Olympiads">
            <Dropdown
              name="olympiads"
              isMulti
              value={olympiads}
              options={Olympiads}
              placeHolder="Olympiads Participated"
              onChange={handleOlympiads}
            ></Dropdown>
          </div>
          <div className="inputForm Olympiads">
            <Dropdown
              name="opportunities"
              isMulti
              value={opportunities}
              options={Opportunities}
              placeHolder="Opportunities Types Interested In"
              onChange={handleOpportunities}
            ></Dropdown>
          </div>
          <div className="inputForm Olympiads">
            <Dropdown
              name="subjects"
              isMulti
              value={subjects}
              options={Subjects}
              placeHolder="Subjects Preference"
              onChange={handleSubjects}
            ></Dropdown>
            <br />
            <Dropdown
              name="budget"
              value={budget}
              options={Budget}
              placeHolder="Budget in INR"
              onChange={handleBudget}
            ></Dropdown>
            <br />
          </div>
        </form>
        <button onClick={handleClick} className="yellowBtn">
          Save and Continue
        </button>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ParentsForm;
