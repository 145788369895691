const FutureFields = [
  { label: "Business", value: "1" },
  { label: "Marketing", value: "2" },
  { label: "Digital Marketing", value: "3" },
  { label: "AI", value: "4" },
  { label: "Quantum Computing", value: "5" },
  { label: "Computer Science", value: "6" },
  { label: "Data Science", value: "7" },
  { label: "Software Development", value: "8" },
  { label: "Information Technology", value: "9" },
  { label: "Cybersecurity", value: "10" },
  { label: "Sports Management", value: "11" },
  { label: "Psychology", value: "12" },
  { label: "Medicine", value: "13" },
  { label: "Game Design", value: "14" },
  { label: "Graphic Design", value: "15" },
  { label: "Economics", value: "16" },
  { label: "Finance", value: "17" },
  { label: "Accounting", value: "18" },
  { label: "Architecture", value: "19" },
  { label: "Biomedical Sciences", value: "20" },
  { label: "Environmental Science", value: "21" },
  { label: "Sustainable Agriculture", value: "22" },
  { label: "Biology", value: "23" },
  { label: "Chemistry", value: "24" },
  { label: "Physics", value: "25" },
  { label: "Political Science", value: "26" },
  { label: "Mathematics", value: "27" },
  { label: "Human Resources", value: "28" },
  { label: "Public Relations", value: "29" },
  { label: "Statistics", value: "30" },
  { label: "Law", value: "31" },
  { label: "Criminology", value: "32" },
  { label: "Sociology", value: "33" },
  { label: "Robotics", value: "34" },
  { label: "History", value: "35" },
  { label: "Hospitality Management", value: "36" },
  { label: "Industrial Engineering", value: "37" },
  { label: "Interior Design", value: "38" },
  { label: "Journalism", value: "39" },
  { label: "Liberal Arts", value: "40" },
  { label: "Mechanical Engineering", value: "41" },
  { label: "Music", value: "42" },
  { label: "Pharmacology", value: "43" },
];

export default FutureFields;
