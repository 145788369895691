import React, { useState } from "react";
import CrossBtn from "../../../assets/cross-button.svg";
import MyButton from "../../Usables/Buttons/Button";
import MyInputField from "../../Usables/InputField/Input";

function EditLinksModal({ isOpen, onClose, onSave, currentLinks }) {
  const [editedLinks, setEditedLinks] = useState(currentLinks);

  const handleSaveClick = () => {
    onSave(editedLinks);
  };

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    setEditedLinks((prevLinks) => ({
      ...prevLinks,
      [key]: value,
    }));
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <img className="crossBtnImg" src={CrossBtn} alt="/" />
        </button>
        <h2 className="bold-text">Edit Social Links</h2>
        <div className="inputEditProfile">
          <MyInputField
            label="Linkedin :"
            value={editedLinks.linkedin}
            onChange={(e) => handleInputChange(e, "linkedin")}
            placeholder="Please enter your Linkedin profile URL"
          ></MyInputField>
        </div>
        <div className="inputEditProfile">
          <MyInputField
            label="Facebook :"
            value={editedLinks.facebook}
            onChange={(e) => handleInputChange(e, "facebook")}
            placeholder="Please enter your Facebook profile URL"
          ></MyInputField>
        </div>
        <div className="inputEditProfile">
          <MyInputField
            label="Twitter :"
            value={editedLinks.twitter}
            onChange={(e) => handleInputChange(e, "twitter")}
            placeholder="Please enter your Twitter profile URL"
          ></MyInputField>
        </div>
        <div className="inputEditProfile">
          <MyInputField
            label="Instagram :"
            value={editedLinks.instagram}
            onChange={(e) => handleInputChange(e, "instagram")}
            placeholder="Please enter your Instagram profile URL"
          ></MyInputField>
        </div>
        <MyButton
          buttonOnClick={handleSaveClick}
          buttonFontSize="2rem"
          buttonWidth="50%"
          borderRadius="10px"
          title="Save and Continue"
          paddingV="1rem"
          paddingH="20px"
        ></MyButton>
      </div>
    </div>
  );
}

export default EditLinksModal;
