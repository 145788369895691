import React from "react";
import "./index.css";
import AboutUsImg from "../../../assets/aboutUsImg.png";
import LazyImage from "../../Usables/LazyImage";
import CircleImage from "../../../assets/Circle Shape.png";
import CircleImageRight from "../../../assets/Circle_Shape_Right.png";

const AboutUsInfo = () => {
  return (
    <div className="aboutusInfoWrapper">
      <img src={CircleImage} alt="/" className="bgCircleLeft" />
      <img src={CircleImageRight} alt="/" className="bgCircleRight" />
      <div className="aboutUsHeading">About Us</div>
      <div className="aboutUsContent">
        <LazyImage
          src={AboutUsImg}
          alt="Benifits Image"
          style={{ width: "70%", paddingLeft: "10rem" }} // Optional: Custom styles for the image
          containerStyle={{ width: "50%" }} // Optional: Custom styles for the container
          mobileContainerStyle={{
            width: "80%",
            margin: "auto",
            paddingBottom: "4rem",
          }}
        />
        <div className="aboutUsRight">
          <div className="aboutUsInfo">
            OPGuru is a transformative platform dedicated to unlocking the
            potential of individuals worldwide. Through personalized mentorship,
            tailored guidance, and a vast network of opportunities, we empower
            our members to navigate their career journeys with confidence and
            clarity. Whether you're seeking international placements, industry
            insights, or professional development, OPGuru provides the tools and
            support you need to thrive. Join us and discover a world of
            possibilities as you embark on your path to success.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsInfo;
