import React, { useState } from "react";
import Message from "../LeftBanner";
import "./Choices.css";
import SelectRole from "../SelectRoles/SelectRole.js";
import CurrentEducation from "../SelectRoles/CurrentEducation.js";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios.js";
import useAuth from "../../hooks/useAuth.js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate.js";
import { useLoader } from "../../context/LoaderContext.js";

const Education = () => {
  const { setIsLoading } = useLoader();

  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();

  const { auth } = useAuth();

  const usertype = location.state?.usertype;

  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const roleSetter = (roleval) => {
    setRole(roleval);
  };

  // role is your education here

  const handleClick = async () => {
    setIsLoading(true);
    let nextUrl = "";
    if (role === "School") {
      nextUrl = "selectClass";
    }
    if (role === "Bachelors") {
      nextUrl = "bachelorDetails";
    }
    if (role === "Masters") {
      nextUrl = "mastersDetails";
    }
    if (role === "PhD") {
      nextUrl = "phdDetails";
    }

    await axiosPrivate.post(
      SAVE_LOGIN_ROUTE,
      {
        userID: auth.userID,
        loginroute: nextUrl,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      }
    );
    setIsLoading(false);
    navigate(`/${nextUrl}`);
  };

  const handleBack = async () => {
    const response = await axiosPrivate.post(
      SAVE_LOGIN_ROUTE,
      {
        userID: auth.userID,
        loginroute: "personalDetails",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      }
    );
    console.log("response from saving route api is ", response);
    // If everything is successful, then only we will navigate
    navigate("/personalDetails");
  };

  return (
    <div className="roleWrapper">
      <div className="messageRoleWrapper">
        <Message></Message>
      </div>
      <div className="Roles">
        <SelectRole
          options={CurrentEducation}
          roleSetter={roleSetter}
          heading={
            usertype === "Students"
              ? "Select your current level of education"
              : "Select your child's current level of education"
          }
          subHeading="what is your current level of education ?"
        ></SelectRole>
        <div className="personalDetailsBtns width58">
          <button className="yellowBtn Btn1" onClick={handleBack}>
            Back
          </button>
          <button className="yellowBtn Btn1" onClick={handleClick}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Education;
