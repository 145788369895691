import EmployeesImg from "../../assets/Employees.png";
import InstitutionsImg from "../../assets/Institutions.png";
import StudentsImg from "../../assets/Students.png";
import ParentsImg from "../../assets/Parents.png";

const Roles = {
  activeOption: null,
  objects: [
    {
      id: 1,
      type: "Students",
      image: StudentsImg,
      selected: true,
    },
    // {
    //   id: 2,
    //   type: "Employees",
    //   image: EmployeesImg,
    //   selected: false,
    // },
    {
      id: 3,
      type: "Parents",
      image: ParentsImg,
      selected: false,
    },
    // {
    //   id: 4,
    //   type: "Institutons",
    //   image: InstitutionsImg,
    //   selected: false,
    // },
  ],
};

export default Roles;
