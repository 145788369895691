import React from "react";
import "./index.css";
import Robot from "../../../assets/Robot.png";
import { useNavigate } from "react-router-dom";
const AIMessage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/chatbot");
  };
  return (
    <div className="aiMsgWrapper">
      <div className="aiMsgHeading">AI OPPORTUNITIES</div>
      <div className="aiMsgDescription">
        AI generate a best opportunities for you only.
      </div>
      <div className="aiMsgImgCon">
        <img src={Robot} alt="/" />
      </div>
      <div className="aiMsgButton">
        <button className="whiteBtn" onClick={handleClick}>
          Grab Now
        </button>
      </div>
    </div>
  );
};

export default AIMessage;
