const LatestOpps = [
  {
    name: "Spotify",
    views: "5433",
    time: "2",
  },
  {
    name: "Adobe",
    views: "2134",
    time: "4",
  },
  {
    name: "Accenture",
    views: "732",
    time: "7",
  },
];

export default LatestOpps;
