import React from "react";
import Oppurtunities from "./TotalOppurtunities.js";
import OppurtunityCard from "./OppurtunityCard";
import totalFavourites from "../../../assets/Dashboard/totalFavourites.png";
import totalOPGuru from "../../../assets/Dashboard/totalOPGuru.png";
import "./index.css";

const AllOppurtunities = () => {
  return (
    <div className="allOppWrapper">
      <div className="allOpps">
        <div className="oppCard">
          <OppurtunityCard
            name="OpGuru Recommended Opportunities"
            Img={totalOPGuru}
            count="1.4k"
            activatecount="5113"
            deactivatecount="45"
          ></OppurtunityCard>
        </div>
        <div className="oppCard">
          <OppurtunityCard
            name="Favourite
            Opportunities"
            Img={totalFavourites}
            count="9.8k"
            activatecount="9846"
            deactivatecount="345"
          ></OppurtunityCard>
        </div>
      </div>
      <div className="allOppTypes">
        {Oppurtunities.map((opp) => {
          return (
            <div className="eachType">
              <div className="oppDetailsCard">
                <div className="oppCount">{opp.count}</div>
                <div className="oppName">{opp.type}</div>
              </div>
              <div className="oppImageWrapper">
                <img className="oppImg" src={opp.Img} alt="/" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllOppurtunities;
