import React, { useState, useEffect, useRef } from "react";
import "./Dropdown.css";

const Icon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

const Dropdown = ({ placeHolder, options, onChange, isMulti, arr, diff }) => {
  const [visible, setVisible] = useState(false);
  const [value, SetValue] = useState(isMulti ? [] : null);
  const inputRef = useRef();
  const toogleDropdown = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setVisible(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const getDisplay = () => {
    if (!value || value.length === 0) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <div
          className="dropdown-tags"
          style={{ display: "flex", flexDirection: "row" }}
        >
          {value.map((option) => (
            <div key={option.value} className="dropdown-tag-item">
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="dropdown-tag-close"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return value.label;
  };

  const removeOption = (option) => {
    return value.filter((o) => o.value !== option.value);
  };
  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    SetValue(newValue);
    onChange(newValue);
  };

  const OnItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (value.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...value, option];
      }
    } else {
      newValue = option;
    }
    SetValue(newValue);
    onChange(newValue);
  };

  let leftOptions = options.filter((option) => {
    if (!arr) return true;
    // coz agar khi array defined nhi hai toh we returned true already
    const opName = option.label;
    console.log(opName);
    console.log("arr is ", arr);
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].regionName === opName) return false;
    }
    return true;
  });

  return (
    <div className="dropdownWrapper">
      <div ref={inputRef} className="dropdown-input" onClick={toogleDropdown}>
        {value && <span className="dropdownLabel">{placeHolder}</span>}
        <div className="dropdown-selected-value">
          {diff ? placeHolder : getDisplay()}
        </div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Icon />
          </div>
        </div>
      </div>
      <div
        className="dropdown-menu"
        style={{ display: visible ? "flex" : "none" }}
      >
        {leftOptions.map((option) => (
          <div className="dropdown-item" onClick={() => OnItemClick(option)}>
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
