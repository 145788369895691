import React, { useState, useEffect, useRef } from "react";
import "./index.css";

const Icon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

const Dropdown = ({
  placeHolder,
  options,
  onChange,
  isMulti,
  worldRegion,
  arr,
}) => {
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  let regionCountries = options.filter((option) => {
    return option.Region === worldRegion;
  });

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = regionCountries.filter((reg) => {
      return (
        reg.label
          .toString()
          .toLowerCase()
          .includes(searchWord.toString().toLowerCase()) &&
        reg.Region === worldRegion
      );
    });
    const selectAll = { label: "Select All", check: false };
    newFilter.unshift(selectAll);
    // adding select all object at top
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
    {
      arr.map((option) => {
        option.check = false;
      });
    }
    onChange([]);
  };

  // The fields which are getting displayed should not have Select All
  const [newOptions, setNewOptions] = useState(arr);

  useEffect(() => {
    let temp = arr
      .filter(
        (option) => option.label !== null && option.label !== "Select All"
      )
      .map((option) => option);
    // THIS IS NEWLY COMMENTED

    setNewOptions(temp);
    console.log("new options are", newOptions);
  }, [arr]);

  // Open and close dropdown
  const toogleDropdown = () => {
    setVisible(!visible);
  };

  // Helps to close thing when you click outside
  const inputRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setVisible(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const getDisplay = () => {
    if (!newOptions || newOptions.length === 0) {
      return "Search for interested Countries";
    }
    if (isMulti) {
      console.log("size of newoptions is", newOptions.length);
      return (
        <div
          className="dropdown-tags"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <>
            {newOptions.slice(0, 3).map((option) => (
              <div key={option.value} className="dropdown-tag-item">
                {option.label}
                <span
                  onClick={(e) => onTagRemove(e, option)}
                  className="dropdown-tag-close"
                >
                  <CloseIcon />
                </span>
              </div>
            ))}
            {newOptions.length > 3 && (
              <div className="show-more-button">
                +{newOptions.length - 3} more
              </div>
            )}
          </>

          <div
            className="removeAll"
            style={{
              display: arr.length > 0 ? "flex" : "none",
              fontColor: "Red",
            }}
            onClick={clearInput}
          >
            Remove All
          </div>
        </div>
      );
    }
    return arr.label;
  };

  // Will run getDisplay again to show correct results after delete

  useEffect(() => {
    getDisplay();
  }, [arr, placeHolder, options, worldRegion]);

  const removeOption = (option) => {
    return arr.filter((o) => o.label !== option.label);
  };
  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    //Find index of specific object using findIndex method.
    const objIndex = options.findIndex((obj) => obj.label === option.label);

    //Update object's name property.
    options[objIndex].check = false;
    onChange(newValue);
  };

  const OnItemClick = (option) => {
    let newValue;
    console.log("option is ", option);
    console.log("arr in onItemClick is ", arr);
    if (isMulti) {
      if (option.label === "Select All") {
        regionCountries.map((country) => {
          country.check = true;
        });
        newValue = regionCountries;
        setFilteredData(regionCountries);
      } else if (arr.findIndex((o) => o.label === option.label) >= 0) {
        option.check = false;
        newValue = removeOption(option);
      } else {
        option.check = true;
        newValue = [...arr, option];
      }
    } else {
      newValue = option;
    }
    onChange(newValue);
    setChecked(!checked);
    setVisible(true);
  };

  return (
    <div ref={inputRef} className="dropdownWrapper">
      <div className="dropdown-input" onClick={toogleDropdown}>
        <div
          className="dropdown-arr-
        setArrvalue"
        >
          {visible ? "Interested Countries" : getDisplay()}
        </div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Icon />
          </div>
        </div>
      </div>
      <div
        className="searchDropdown"
        style={{ display: visible ? "flex" : "none" }}
      >
        <input
          type="text"
          placeholder={placeHolder}
          value={wordEntered}
          onChange={handleFilter}
          className="dropdownSearch"
        />
      </div>
      <div
        className="optionsDropdown"
        style={{ display: visible ? "flex" : "none" }}
      >
        {getDisplay()}
      </div>
      <div
        className="dropdown-menu"
        style={{ display: visible ? "flex" : "none" }}
      >
        {filteredData.map((option) => (
          <div className="dropdown-item" onClick={() => OnItemClick(option)}>
            <div className="singleItem">
              <div className="itemCheckbox">
                <input type="checkbox" checked={option.check} />
              </div>
              <div className="itemName">{option.label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
