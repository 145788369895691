import React, { useState } from "react";
import deleteImg from "../../../../assets/delete.png";
import Dropdown from "../../../Usables/Dropdown/Dropdown.js";
import Domains from "./Domains.js";
import InputField from "../../../Usables/InputField";
import "./index.css";

const InternDetails = ({ onInternDetails }) => {
  const [show, setShow] = useState(true);
  const [domains, setDomains] = useState(null);
  const [stipend, setStipend] = useState("");
  const [remoteInternship, setRemoteInternship] = useState(false);
  const [regions, setRegions] = useState([
    {
      id: 1,
      regionName: "Select Region",
      subRegions: [],
      check: false,
    },
  ]);

  const handleChange = () => {
    setShow(!show);
  };

  const handleDomains = (e) => {
    let temp = [];
    Object.keys(e).forEach(function (key) {
      temp = [...temp, e[key].value];
    });
    temp.sort();
    setDomains(JSON.stringify(temp));
  };

  const handleStipend = (e) => {
    setStipend(e.target.value);
  };

  const handleRemoteInternship = () => {
    setRemoteInternship(!remoteInternship);
  };

  const handleInternDetails = () => {
    // Collect intern details and pass them to the parent component
    const internDetails = {
      domains,
      stipend,
      remoteInternship,
      regions,
    };
    onInternDetails(internDetails);
  };

  return (
    <div className="internDWrapper">
      {show ? (
        <div className="internCheckbox">
          <input
            type="checkbox"
            className="checkBoxInput"
            onChange={handleChange}
          />
          Are you interested in an internship?
        </div>
      ) : (
        //</div>
        <div className="internForm">
          <div className="internFormHeader">
            <div className="internFormHeading">Internship Details</div>
            <div className="internFormShow" onClick={handleChange}>
              <img src={deleteImg} alt="/" />
            </div>
          </div>
          <div className="internFormBottom">
            <div className="inputIntern">
              <Dropdown
                name="futureFields"
                isMulti
                value={domains}
                options={Domains}
                placeHolder="Interested fields of study in the future"
                onChange={handleDomains}
              ></Dropdown>
            </div>
            <div className="inputIntern">
              <InputField
                type="text"
                value={stipend}
                placeholder="Expected Stipend"
                name="stipend"
                onChange={handleStipend}
              />
            </div>
          </div>
          <div className="internCheckbox">
            <div className="checkBoxRemoteIntern">
              <input
                type="checkbox"
                className="rememberCheckBox"
                onChange={handleRemoteInternship}
              />
            </div>
            <div className="remoteText">
              Are you interested in remote internships?
            </div>
          </div>
          <button onClick={handleInternDetails} className="yellowBtn">
            Save Internship Details
          </button>
        </div>
      )}
    </div>
  );
};

export default InternDetails;
