import React, { useState } from "react";
import "./index.css";
import BellIcon from "../../../assets/Bell.png";
import ProfileImgHeader from "../../../assets/profileImage.png";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import OpguruLogo from "../../../assets/opgurulogowhite.png";

const Header = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  return (
    <div className="headerWrapper">
      <div className="headerLogoForms" onClick={() => navigate("/home")}>
        <img className="headerLogoFormsImg" src={OpguruLogo} alt="/" />
      </div>
      <div className="userDetailsHeader">
        <div className="iconContainer">
          <img src={BellIcon} alt="Notification Bell" className="icon" />
        </div>
        <div className="userName">
          {auth?.name ? auth?.name : "Not logged in"}
        </div>
        <div className="iconContainer">
          <img src={ProfileImgHeader} alt="Profile" className="profileIcon" />
        </div>
        <div className="headerLogoutWrapper">
          <button className="yellowBtn" onClick={signOut}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
