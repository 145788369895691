// index.js
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./State/Store.js";
import { UserAuthContextProvider } from "./context/UserAuthContext.js";
import { AuthProvider } from "./context/AuthProvider";
import ChatIcon from "./components/ChatIcon/ChatIcon.js"; // Import the ChatIcon component
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { LoaderProvider } from "./context/LoaderContext.js";
import Loader from "./components/WebsiteLoader/index.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

const handleChatIconClick = () => {
  // Add logic to handle the chat icon click (e.g., open a chat window)
  console.log("Chat icon clicked!");
};

root.render(
  <React.StrictMode>
    <Router>
      {/* Render the ChatIcon at the top level */}
      {/* <ChatIcon onClick={handleChatIconClick} /> */}
      <Provider store={store}>
        <UserAuthContextProvider>
          <AuthProvider>
            <LoaderProvider>
              <Loader />
              <ChatIcon onClick={handleChatIconClick} />
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </LoaderProvider>
          </AuthProvider>
        </UserAuthContextProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
