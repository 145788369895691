import React, { useState, useEffect, useRef } from "react";
import "./SelectRole.css";

const SelectRole = (props) => {
  const [options, setOptions] = useState(props.options);
  console.log(options);

  const toggleActive = (index) => {
    console.log("index is ", index);
    setOptions({ ...options, activeOption: options.objects[index] });
    props.roleSetter(options.objects[index].type);
  };

  const toggleStyle = (index) => {
    if (options.objects[index] === options.activeOption) {
      return "box active";
    } else {
      return "box inactive";
    }
  };

  return (
    <div className="selectroleWrapper">
      <div className="optionsHeading">{props.heading}</div>
      <div className="optionsSubHeading">{props.subHeading}</div>
      <div className="allOptions">
        {options.objects.map((option, index) => (
          <div
            onClick={() => toggleActive(index)}
            key={option.id}
            className={toggleStyle(index)}
          >
            <div className="optionImgCon">
              <div className="optionImg">
                <img src={option.image} alt="/" className="optionsImage" />
              </div>
            </div>
            <div className="optionName">{option.type}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectRole;
