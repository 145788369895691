const Budget = [
  { label: "Less than Rs 50k", value: "1" },
  { label: "Rs 50k to 1 lakh", value: "2" },
  { label: "Rs 1 lakh to 2 lakh", value: "3" },
  { label: "Rs 2 lakh to Rs 5 lakh", value: "4" },
  { label: "Rs 5 lakh to 10 lakh", value: "5" },
  { label: "Rs 10 lakh to 20 lakh", value: "6" },
  { label: "Rs 20 lakh to 40 lakh", value: "7" },
  { label: "Greater than 40 lakhs", value: "8" },
];

export default Budget;
