import React from "react";
import "./index.css";

function MyInputField({
  label,
  placeholder,
  borderRadius,
  widthInput,
  widthWrapper,
  textColor,
  borderColor,
  backgroundColor,
  type,
  value,
  onChange,
}) {
  const inputStyles = {
    width: widthInput || "100%", // Default width if not provided
    borderRadius: borderRadius || "10px", // Default border radius if not provided
    color: textColor || "#000", // Default text color if not provided
    borderColor: borderColor || "#ccc", // Default border color if not provided
    backgroundColor: backgroundColor || "#fff", // Default background color if not provided
    padding: "1rem",
    border: `1px solid ${borderColor || "#ccc"}`, // Default border style
    fontSize: "1.6rem",
    outline: "none",
  };

  const wrapperStyles = {
    padding: "1rem",
    width: widthWrapper || "100%", // Default wrapper width if not provided
  };

  return (
    <div className="input-field-container" style={wrapperStyles}>
      {label && <label className="input-label">{label}</label>}
      <input
        type={type || "text"} // Default type is text
        placeholder={placeholder || ""}
        value={value}
        onChange={onChange}
        style={inputStyles}
        className="input-field"
      />
    </div>
  );
}

export default MyInputField;
