import MastersImg from "../../assets/Masters.png";
import BachelorsImg from "../../assets/Bachelors.png";
import SchoolImg from "../../assets/School.png";

const CurrentEducation = {
  activeOption: null,
  objects: [
    {
      id: 1,
      type: "Upto 8th",
      image: SchoolImg,
      selected: true,
    },
    {
      id: 2,
      type: "9th-10th",
      image: BachelorsImg,
      selected: false,
    },
    {
      id: 3,
      type: "11th-12th",
      image: MastersImg,
      selected: false,
    },
  ],
};

export default CurrentEducation;
