const Specializations = [
  { label: "Computer Science - Artificial Intelligence", value: "1" },
  { label: "Computer Science - Machine Learning", value: "2" },
  { label: "Computer Science - Cybersecurity", value: "3" },
  { label: "Computer Science - Data Science", value: "4" },
  { label: "Electrical Engineering - Power Systems", value: "5" },
  { label: "Electrical Engineering - Telecommunications", value: "6" },
  { label: "Electrical Engineering - Signal Processing", value: "7" },
  { label: "Electrical Engineering - Microelectronics", value: "8" },
  { label: "Mechanical Engineering - Robotics", value: "9" },
  { label: "Mechanical Engineering - Thermodynamics", value: "10" },
  { label: "Mechanical Engineering - Fluid Mechanics", value: "11" },
  { label: "Mechanical Engineering - Materials Science", value: "12" },
  { label: "Civil Engineering - Structural Engineering", value: "13" },
  { label: "Civil Engineering - Environmental Engineering", value: "14" },
  { label: "Civil Engineering - Geotechnical Engineering", value: "15" },
  { label: "Civil Engineering - Transportation Engineering", value: "16" },
  { label: "Biology - Molecular Biology", value: "17" },
  { label: "Biology - Genetics", value: "18" },
  { label: "Biology - Ecology", value: "19" },
  { label: "Biology - Evolutionary Biology", value: "20" },
  { label: "Chemistry - Organic Chemistry", value: "21" },
  { label: "Chemistry - Inorganic Chemistry", value: "22" },
  { label: "Chemistry - Physical Chemistry", value: "23" },
  { label: "Chemistry - Analytical Chemistry", value: "24" },
  { label: "Physics - Theoretical Physics", value: "25" },
  { label: "Physics - Experimental Physics", value: "26" },
  { label: "Physics - Astrophysics", value: "27" },
  { label: "Physics - Condensed Matter Physics", value: "28" },
  { label: "Mathematics - Pure Mathematics", value: "29" },
  { label: "Mathematics - Applied Mathematics", value: "30" },
  { label: "Mathematics - Statistics", value: "31" },
  { label: "Mathematics - Computational Mathematics", value: "32" },
  { label: "Environmental Science - Climate Science", value: "33" },
  { label: "Environmental Science - Environmental Policy", value: "34" },
  { label: "Environmental Science - Conservation Biology", value: "35" },
  { label: "Environmental Science - Sustainable Development", value: "36" },
  { label: "Economics - Microeconomics", value: "37" },
  { label: "Economics - Macroeconomics", value: "38" },
  { label: "Economics - Econometrics", value: "39" },
  { label: "Economics - Development Economics", value: "40" },
  { label: "Sociology - Social Theory", value: "41" },
  { label: "Sociology - Criminology", value: "42" },
  { label: "Sociology - Urban Sociology", value: "43" },
  { label: "Sociology - Sociology of Health", value: "44" },
  { label: "Psychology - Clinical Psychology", value: "45" },
  { label: "Psychology - Cognitive Psychology", value: "46" },
  { label: "Psychology - Developmental Psychology", value: "47" },
  { label: "Psychology - Social Psychology", value: "48" },
  { label: "Political Science - Comparative Politics", value: "49" },
  { label: "Political Science - International Relations", value: "50" },
  { label: "Political Science - Political Theory", value: "51" },
  { label: "Political Science - Public Policy", value: "52" },
  { label: "History - Ancient History", value: "53" },
  { label: "History - Medieval History", value: "54" },
  { label: "History - Modern History", value: "55" },
  { label: "History - Cultural History", value: "56" },
  { label: "English Literature - American Literature", value: "57" },
  { label: "English Literature - British Literature", value: "58" },
  { label: "English Literature - Comparative Literature", value: "59" },
  { label: "English Literature - Postcolonial Literature", value: "60" },
  { label: "Philosophy - Ethics", value: "61" },
  { label: "Philosophy - Metaphysics", value: "62" },
  { label: "Philosophy - Epistemology", value: "63" },
  { label: "Philosophy - Logic", value: "64" },
  { label: "Linguistics - Phonetics", value: "65" },
  { label: "Linguistics - Syntax", value: "66" },
  { label: "Linguistics - Sociolinguistics", value: "67" },
  { label: "Linguistics - Computational Linguistics", value: "68" },
  { label: "Anthropology - Cultural Anthropology", value: "69" },
  { label: "Anthropology - Physical Anthropology", value: "70" },
  { label: "Anthropology - Archaeology", value: "71" },
  { label: "Anthropology - Linguistic Anthropology", value: "72" },
  { label: "Education - Educational Leadership", value: "73" },
  { label: "Education - Curriculum and Instruction", value: "74" },
  { label: "Education - Special Education", value: "75" },
  { label: "Education - Educational Psychology", value: "76" },
  { label: "Business Administration - Finance", value: "77" },
  { label: "Business Administration - Marketing", value: "78" },
  { label: "Business Administration - Management", value: "79" },
  { label: "Business Administration - Operations Management", value: "80" },
  { label: "Public Health - Epidemiology", value: "81" },
  { label: "Public Health - Biostatistics", value: "82" },
  { label: "Public Health - Health Policy", value: "83" },
  { label: "Public Health - Environmental Health", value: "84" },
  { label: "Law - Constitutional Law", value: "85" },
  { label: "Law - International Law", value: "86" },
  { label: "Law - Corporate Law", value: "87" },
  { label: "Law - Criminal Law", value: "88" },
  { label: "Architecture - Urban Design", value: "89" },
  { label: "Architecture - Sustainable Architecture", value: "90" },
  { label: "Architecture - Architectural History", value: "91" },
  { label: "Architecture - Landscape Architecture", value: "92" },
  { label: "Geography - Human Geography", value: "93" },
  { label: "Geography - Physical Geography", value: "94" },
  { label: "Geography - Geographic Information Systems (GIS)", value: "95" },
  { label: "Geography - Environmental Geography", value: "96" },
  { label: "Material Science - Nanotechnology", value: "97" },
  { label: "Material Science - Polymer Science", value: "98" },
  { label: "Material Science - Biomaterials", value: "99" },
  { label: "Material Science - Electronic Materials", value: "100" },
  { label: "Neuroscience - Cognitive Neuroscience", value: "101" },
  { label: "Neuroscience - Behavioral Neuroscience", value: "102" },
  { label: "Neuroscience - Molecular Neuroscience", value: "103" },
  { label: "Neuroscience - Computational Neuroscience", value: "104" },
  { label: "Bioinformatics - Genomics", value: "105" },
  { label: "Bioinformatics - Proteomics", value: "106" },
  { label: "Bioinformatics - Computational Biology", value: "107" },
  { label: "Bioinformatics - Systems Biology", value: "108" },
  { label: "Chemical Engineering - Process Engineering", value: "109" },
  { label: "Chemical Engineering - Biochemical Engineering", value: "110" },
  { label: "Chemical Engineering - Materials Engineering", value: "111" },
  { label: "Chemical Engineering - Environmental Engineering", value: "112" },
  { label: "Biomedical Engineering - Tissue Engineering", value: "113" },
  { label: "Biomedical Engineering - Biomedical Imaging", value: "114" },
  { label: "Biomedical Engineering - Biomechanics", value: "115" },
  { label: "Biomedical Engineering - Biomaterials", value: "116" },
  { label: "Astrophysics - Stellar Astrophysics", value: "117" },
];

export default Specializations;
