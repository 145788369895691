import React from "react";
import "./index.css";

const PersonCard = ({ name, job, testimonial, companyImg }) => {
  return (
    <div className="personCardWrapper">
      <div className="personName">{name}</div>
      <div className="personJob">{job}</div>
      <div className="personInfo">{testimonial}</div>
    </div>
  );
};

export default PersonCard;
