import React from "react";
import "./index.css";
import Company1 from "../../../assets/company1.png";
import Company2 from "../../../assets/company2.png";
import Company3 from "../../../assets/company3.png";

const Associations = () => {
  return (
    <div className="associationWrapper">
      <div className="associationHeading1">
        <span className="associationHeadingSpan" style={{ color: "#DFA114" }}>
          The
        </span>{" "}
        Opportunities
        <span className="associationHeadingSpan" style={{ color: "#DFA114" }}>
          we offer are
        </span>
      </div>
      <div className="associationHeading2">
        Select the best career path from the pool of opportunities we offer
      </div>
      <div className="hiringCompaniesDesktop">
        <div className="companyLogosDesktop">
          <div className="companyDesktop zoomdiff">Competitions</div>
        </div>
        <div className="companyLogosDesktop">
          <div className="companyDesktop">Fellowships</div>
          <div className="companyDesktop zoomdiff">Exchange programmes</div>
        </div>
        <div className="companyLogosDesktop">
          <div className="companyDesktop zoomdiff">Scholarships</div>
          <div className="companyDesktop">Internships</div>
          <div className="companyDesktop">Mentorships</div>
        </div>
        <div className="companyLogosDesktop">
          <div className="companyDesktop">Conferences</div>
          <div className="companyDesktop zoomdiff">Full time jobs</div>
        </div>
        <div className="companyLogosDesktop">
          <div className="companyDesktop zoomdiff">Training</div>
        </div>
      </div>
    </div>
  );
};

export default Associations;
