const OpGuruRoutes = [
  {
    name: "Home",
    linkTo: "",
  },
  {
    name: "About Us",
    linkTo: "aboutus",
  },
  // {
  //   name: "Our Collaborations",
  //   linkTo: "ourcollaborations",
  // },
  {
    name: "Blogs",
    linkTo: "blogs",
  },
];

export default OpGuruRoutes;
