import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImage = ({
  src,
  alt = "/",
  effect = "blur",
  style = {},
  containerStyle = {},
  mobileStyle = {},
  mobileContainerStyle = {},
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Check if screen width is mobile size (<= 768px)
    };

    // Set initial value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const appliedStyle = isMobile ? mobileStyle : style;
  const appliedContainerStyle = isMobile
    ? mobileContainerStyle
    : containerStyle;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...appliedContainerStyle,
      }}
    >
      <LazyLoadImage
        alt={alt}
        src={src}
        effect={effect}
        style={{ maxWidth: "100%", ...appliedStyle }}
      />
    </div>
  );
};

export default LazyImage;
