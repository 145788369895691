export const updatePhno = (phonenum) => {
  return {
    type: "updatephno",
    payload: phonenum,
  };
};

export const updateName = (nameofuser) => {
  return {
    type: "updatename",
    payload: nameofuser,
  };
};

export const userType = (usertype) => {
  return {
    type: "updateusertype",
    payload: usertype,
  };
};
