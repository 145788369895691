import React from "react";
import ProfileImg from "../../../assets/profileImg.jpg";
import banner from "../../../assets/profileBanner.png";
import "./index.css";

const UserProfile = ({ personalData }) => {
  return (
    <div className="hero-card">
      <div className="hero-banner">
        <img src={banner} alt="" />
      </div>
      <div className="hero-avatar">
        <img src={ProfileImg} alt="" />
      </div>
      <div className="hero-details">
        <h1 className="hero-title">{personalData.fullName}</h1>
        {/* <p className="hero-subtitle">UI/UX Designer</p> */}
      </div>
    </div>
  );
};

export default UserProfile;
