import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

function Card({ content, onEditClick }) {
  const iconStyle = {
    color: 'black',
  };

  const cardStyle = {
    //width: '150px',
    //height: '200px',
    overflow: 'hidden', // Add overflow property to handle long text
  };

  const cardDetailsStyle = {
    maxHeight: '200%', // Set a max height for the text
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap', // Preserve white spaces and allow wrapping
  };

  return (
    <div className="card" style={cardStyle}>
      <button className="edit-button" onClick={onEditClick}>
        <FontAwesomeIcon icon={faPencilAlt} style={iconStyle} />
      </button>
      <div className="card-title">About</div>
      <p className="card-details" style={cardDetailsStyle}>
        {content==='' ? "Write something about yourself!" : content}
      </p>
    </div>
  );
}

export default Card;
