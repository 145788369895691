import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
const Landing = (props) => {
  const navigate = useNavigate();
  return (
    <div className="landingWrapper">
      <div className="landingHeading1">{props.heading1}</div>
      <div className="landingHeading2">{props.heading2}</div>
      <div className="landingBtn">
        <button
          onClick={() => {
            navigate("/register");
          }}
          className="yellowBtn homeBtn"
        >
          {props.buttonName}
        </button>
      </div>
    </div>
  );
};

export default Landing;
