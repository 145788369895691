import React, { useRef } from "react";
import "./index.css";
import { BlobServiceClient } from "@azure/storage-blob";

const ResumePicker = ({ fileName, handleFile }) => {
  const hiddenFileInput = useRef();

  //Storage account credentials
  const account = "opguruijstorage"; // get the storage account name from the .env file
  const sasToken =
    "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-02-18T17:20:34Z&st=2024-02-18T09:20:34Z&spr=https,http&sig=xnLjvMB4kfGBN28r3QfGMqaI03b%2F4hVhPRGZVIiJOPY%3D"; // get the SAS token from the .env file
  const containerName = "resumestorage"; // get the container name from the .env file
  const temp = `https://${account}.blob.core.windows.net/?${sasToken}`;
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net/?${sasToken}`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
    event.preventDefault();
    if (!fileUploaded) {
      // check if the file is selected
      alert("Please select an image to upload");
      return;
    }
    if (!account || !sasToken || !containerName) {
      // check if the credentials are set
      alert(
        "Please make sure you have set the Azure Storage credentials in the .env file"
      );
      return;
    }
    try {
      // setLoading(true);
      const blobName = `${new Date().getTime()}-${fileUploaded.name}`; // Specify a default blob name if needed
      console.log("blobName is ", blobName);
      const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
      await blobClient.uploadData(fileUploaded, {
        blobHTTPHeaders: { blobContentType: fileUploaded.type },
      }); // upload the image
      // await fetchImages();   // fetch all images again after the upload is completed
    } catch (error) {
      console.error(error); // Handle error
    } finally {
      // setLoading(false); // Turn off loading
    }
    console.log("fileUploaded is ", fileUploaded);
    // pass a function named handleFile in parent to handle this file
  };
  return (
    <div className="resumePickerWrapper">
      {fileName !== "" ? (
        <div></div>
      ) : (
        <button className="resumeBtn" onClick={handleClick}>
          + Add Resume
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
            className="pickerInput"
          />
        </button>
      )}
    </div>
  );
};

export default ResumePicker;
