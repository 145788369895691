import React, { useState } from "react";
import AddedRegion from "./AddedRegion";
import "./index.css";
const Regions = ({ arr, setArr, toggleModal }) => {
  const [agreement, setAgreement] = useState(false);

  // const handleCheck = (event) => {
  //   setAgreement(event.target.checked);
  //   console.log(agreement);
  // };
  const handleChangeName = (e, ind) => {
    const index = ind;
    setArr((s) => {
      const newArr = s.slice();
      newArr[index].regionName = e.label;
      return newArr;
    });
  };
  const handleChangeSubRegion = (temp, ind) => {
    const index = ind;
    setArr((s) => {
      const newArr = s.slice();
      newArr[index].subRegions = temp;
      return newArr;
    });
  };

  const addExam = () => {
    setArr((s) => {
      let lastId;
      if (s.length === 0) {
        lastId = 1;
      } else {
        lastId = s[s.length - 1].id;
      }
      return [
        ...s,
        {
          id: lastId + 1,
          regionName: "Select Region",
          subRegions: [],
          check: false,
        },
      ];
    });
  };

  const saveRegions = () => {
    setArr(arr);
    toggleModal();
  };
  return (
    <div className="regionsWrapper">
      {arr.map((item, i) => {
        return (
          <div className="region">
            <AddedRegion
              agreement={agreement}
              regionName={item.regionName}
              id={i}
              type={item.type}
              handleChangeName={handleChangeName}
              handleChangeSubRegion={handleChangeSubRegion}
              arr={arr}
            ></AddedRegion>
          </div>
        );
      })}
      <div className="addMoreRegionCon" onClick={addExam}>
        <button className="addMoreRegionBtn">+ Add Region</button>
      </div>
      {/* <div
        className="allRegionSelect"
        style={{ display: "flex", width: "80%" }}
      >
        <div
          className="allRegionCheckbox"
          style={{ marginLeft: "3rem", paddingRight: "1rem" }}
        >
          <input type="checkbox" name="agreement" onChange={handleCheck} />
        </div>
        <div className="allRegionInfo">
          Select all regions as Interested Regions
        </div>
      </div> */}
      <button
        className="yellowBtn"
        style={{ width: "50%", margin: "2rem" }}
        onClick={saveRegions}
      >
        Save and Continue
      </button>
    </div>
  );
};

export default Regions;
