import React from "react";
import "./index.css";

const OppCard = (props) => {
  return (
    <div className="oppCardWrapper">
      <div className="oppCardTop">
        <div className="oppCardType">
          <div className="oppCardImgCon">
            <img src={props.Img} className="oppCardImg" alt="/" />
          </div>
          <div className="oppCardName">{props.name}</div>
        </div>
        <div className="oppCardCount">{props.count}</div>
      </div>
      <div className="oppCardBottom">
        <div className="oppActivated">
          <div className="statusCount">{props.activatecount}</div>
          <div className="statusType" style={{ color: "#01BC82" }}>
            Activated
          </div>
        </div>
        <div className="oppDeactivated">
          <div className="statusCount">{props.deactivatecount}</div>
          <div className="statusType" style={{ color: "#FA1212" }}>
            Deactivated
          </div>
        </div>
      </div>
    </div>
  );
};

export default OppCard;
