import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import OpguruLogo from "../../assets/opgurulogowhite.png";

const Message = () => {
  const navigate = useNavigate();
  return (
    <div className="messageWrapper">
      <div className="titleCompany" onClick={() => navigate("/")}>
        <img className="opguruLogoImgBanner" src={OpguruLogo} alt="/" />
      </div>
      <div className="messageCompany">
        Register and apply to{" "}
        <span className="changeCol font5-6">10000+ opportunities</span>
      </div>
    </div>
  );
};

export default Message;
