import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

function SocialLinksCard({ onEditClick, editedLinks }) {
  const iconStyle = {
    color: "black",
  };

  return (
    <div className="card">
      <button className="edit-button" onClick={onEditClick}>
        <FontAwesomeIcon icon={faPencilAlt} style={iconStyle} />
      </button>
      <h2 className="card-title">Social Links</h2>
      <div className="flex flex-wrap">
        <div className="flex flex-column flex-cell-2">
          <div className="card-details">LinkedIn</div>
          <div className="card-subtitle">{editedLinks.linkedin}</div>
        </div>

        <div className="flex flex-column flex-cell-2">
          <div className="card-details">Facebook</div>
          <div className="card-subtitle">{editedLinks.facebook}</div>
        </div>

        <div className="flex flex-column flex-cell-2">
          <div className="card-details">Twitter</div>
          <div className="card-subtitle">{editedLinks.twitter}</div>
        </div>

        <div className="flex flex-column flex-cell-2">
          <div className="card-details">Instagram</div>
          <div className="card-subtitle">{editedLinks.instagram}</div>
        </div>
      </div>
    </div>
  );
}

export default SocialLinksCard;
