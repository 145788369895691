import React from "react";
import "./index.css";

const FloatInputField = ({ name, inputVal, placeHolder, handleInput, err }) => {
  return (
    <div className="formInputWrapper">
      {inputVal && <span className="inputFlatingLabel">{placeHolder}</span>}
      <input
        type="text"
        value={inputVal}
        name={name}
        placeholder={placeHolder}
        onChange={handleInput}
      />
      {err && <div style={{ color: "red", fontSize: "1.8rem" }}>{err}</div>}
    </div>
  );
};

export default FloatInputField;
