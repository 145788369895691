const Colleges = [
  {
    value: "1",
    label: "University of Andorra",
    website: "http://www.uda.ad/",
  },
  {
    value: "2",
    label: "Abu Dhabi University",
    website: "http://www.adu.ac.ae/",
  },
  {
    value: "3",
    label: "Ajman University of Science & Technology",
    website: "http://www.ajman.ac.ae/",
  },
  {
    value: "4",
    label: "Alain University of Science and Technology",
    website: "http://www.alainuniversity.ac.ae/",
  },
  {
    value: "5",
    label: "Al Ghurair University",
    website: "http://www.agu.ae/",
  },
  {
    value: "6",
    label: "Alhosn University",
    website: "http://www.alhosnu.ae/",
  },
  {
    value: "7",
    label: "Al Khawarizmi International College",
    website: "http://www.khawarizmi.com/",
  },
  {
    value: "8",
    label: "American College Of Dubai",
    website: "http://www.acd.ac.ae/",
  },
  {
    value: "9",
    label: "American University in Dubai",
    website: "http://www.aud.edu/",
  },
  {
    value: "10",
    label: "American University in the Emirates",
    website: "http://www.aue.ae/",
  },
  {
    value: "11",
    label: "American University of Sharjah",
    website: "http://www.aus.edu/",
  },
  {
    value: "12",
    label: "British University in Dubai",
    website: "http://www.buid.ac.ae/",
  },
  {
    value: "13",
    label: "Dubai Medical College for Girls",
    website: "http://www.dmcg.edu/",
  },
  {
    value: "14",
    label: "Dubai Pharmacy College",
    website: "http://www.dpc.edu/",
  },
  {
    value: "15",
    label: "Etisalat University College",
    website: "http://www.ece.ac.ae/",
  },
  {
    value: "16",
    label: "Gulf Medical University",
    website: "http://www.gmu.ac.ae/",
  },
  {
    value: "17",
    label: "Hamdan Bin Mohammed e-University",
    website: "http://www.hbmeu.ac.ae/",
  },
  {
    value: "18",
    label: "Higher Colleges of Technology",
    website: "http://www.hct.ac.ae/",
  },
  {
    value: "19",
    label: "Ittihad University",
    website: "http://www.ittihad.ac.ae/",
  },
  {
    value: "20",
    label: "Jumeira University",
    website: "http://www.ju.ac.ae/",
  },
  {
    value: "21",
    label: "Khalifa University",
    website: "http://www.kustar.ac.ae/",
  },
  {
    value: "22",
    label: "Khalifa University of Science, Technology and Research",
    website: "http://www.ku.ac.ae/",
  },
  {
    value: "23",
    label: "Masdar University Of Science And Technology",
    website: "http://www.masdar.ac.ae/",
  },
  {
    value: "24",
    label: "New York University, Abu Dhabi",
    website: "http://nyuad.nyu.edu/",
  },
  {
    value: "25",
    label: "Paris-Sorbonne University Abu Dhabi",
    website: "http://www.sorbonne.ae/",
  },
  {
    value: "26",
    label: "Rak Medical & Health Sciences University",
    website: "http://www.rakmhsu.com/",
  },
  {
    value: "27",
    label: "Rochester Institute of Technology, Dubai",
    website: "http://dubai.rit.edu/",
  },
  {
    value: "28",
    label: "Skyline University College, Sharjah",
    website: "http://www.skylineuniversity.com/",
  },
  {
    value: "29",
    label: "Synergy University, Dubai Campus",
    website: "http://www.synergydubai.ae/",
  },
  {
    value: "30",
    label: "The Emirates Academy of Hotel Managment",
    website: "http://www.emiratesacademy.edu/",
  },
  {
    value: "31",
    label: "The Petroleum Institute",
    website: "http://www.pi.ac.ae/",
  },
  {
    value: "32",
    label: "United Arab Emirates University",
    website: "http://www.uaeu.ac.ae/",
  },
  {
    value: "33",
    label: "University Of Dubai",
    website: "http://www.ud.ac.ae/",
  },
  {
    value: "34",
    label: "University of Jazeera",
    website: "http://www.uojazeera.com/",
  },
  {
    value: "35",
    label: "University of Sharjah",
    website: "http://www.sharjah.ac.ae/",
  },
  {
    value: "36",
    label: "University of Wollongong - Dubai Campus",
    website: "http://www.uowdubai.ac.ae/",
  },
  {
    value: "37",
    label: "Zayed University",
    website: "http://www.zu.ac.ae/",
  },
  {
    value: "38",
    label: "Afghan University",
    website: "http://www.afghanuniversity.edu.af/",
  },
  {
    value: "39",
    label: "Al-Birony University",
    website: "http://www.au.edu.af/",
  },
  {
    value: "40",
    label: "American University of Afghanistan",
    website: "http://www.auaf.edu.af/",
  },
  {
    value: "41",
    label: "Aria Institute of Higher Education",
    website: "http://www.aria.edu.af/",
  },
  {
    value: "42",
    label: "Badakhshan University",
    website: "http://www.badakhshan.edu.af/",
  },
  {
    value: "43",
    label: "Baghlan University",
    website: "http://www.baghlan.edu.af/",
  },
  {
    value: "44",
    label: "Bakhtar University",
    website: "http://www.bakhtar.edu.af/",
  },
  {
    value: "45",
    label: "Balkh University",
    website: "http://www.ba.edu.af/",
  },
  {
    value: "46",
    label: "Bamiyan University",
    website: "http://www.bu.edu.af/",
  },
  {
    value: "47",
    label: "Bost University",
    website: "http://www.bost.edu.af/",
  },
  {
    value: "48",
    label: "Dawat University",
    website: "http://www.dawat.edu.af/",
  },
  {
    value: "49",
    label: "Dunya Institute of Higher Education",
    website: "http://www.dunya.edu.af/",
  },
  {
    value: "50",
    label: "Faryab Higher Education Institute",
    website: "http://www.faryab.edu.af/",
  },
  {
    value: "51",
    label: "Ghazni University",
    website: "http://www.gu.edu.af/",
  },
  {
    value: "52",
    label: "Herat University",
    website: "http://www.hu.edu.af/",
  },
  {
    value: "53",
    label: "Ibn Sina University",
    website: "http://www.ibnesina.edu.af/",
  },
  {
    value: "54",
    label: "Jawzjan University",
    website: "http://www.ju.edu.af/",
  },
  {
    value: "55",
    label: "Kaboora Institute of Higher Education",
    website: "http://www.kaboora.edu.af/",
  },
  {
    value: "56",
    label: "Kabul Education University",
    website: "http://www.keu.edu.af/",
  },
  {
    value: "57",
    label: "Kabul Health Sciences Institute",
    website: "http://www.kabuli.edu.af/",
  },
  {
    value: "58",
    label: "Kabul Medical University",
    website: "http://www.kmu.edu.af/",
  },
  {
    value: "59",
    label: "Kabul University",
    website: "http://www.ku.edu.af/",
  },
  {
    value: "60",
    label: "Kandahar University",
    website: "http://www.kan.edu.af/",
  },
  {
    value: "61",
    label: "Kardan University",
    website: "http://www.kardan.edu.af/",
  },
  {
    value: "62",
    label: "Karwan Institute of Higher Education",
    website: "http://www.karwan.edu.af/",
  },
  {
    value: "63",
    label: "Kateb Institute of Higher Education",
    website: "http://www.kateb.edu.af/",
  },
  {
    value: "64",
    label: "Khana-e-Noor Institute of Higher Education",
    website: "http://www.ken.edu.af/",
  },
  {
    value: "65",
    label: "Khurasan University",
    website: "http://www.kumsa.net/",
  },
  {
    value: "66",
    label: "Maryam Institute of Higher Education",
    website: "http://www.maryam.edu.af/",
  },
  {
    value: "67",
    label: "Nangarhar University",
    website: "http://www.nu.edu.af/",
  },
  {
    value: "68",
    label: "National Military Academy of Afghanistan",
    website: "http://www.nmaa.edu.af/",
  },
  {
    value: "69",
    label: "Paktia University",
    website: "http://www.pu.edu.af/",
  },
  {
    value: "70",
    label: "Pamir University",
    website: "http://www.pamir.edu.af/",
  },
  {
    value: "71",
    label: "Parwan University",
    website: "http://www.parwan.edu.af/",
  },
  {
    value: "72",
    label: "Polytechnical University of Kabul",
    website: "http://www.polytechnic-kabul.org/",
  },
  {
    value: "73",
    label: "Rana Institute of Higher Education",
    website: "http://www.rihs.edu.af/",
  },
  {
    value: "74",
    label: "Sadat Institute of Higher Education",
    website: "http://www.sadat.edu.af/",
  },
  {
    value: "75",
    label: "Salam University",
    website: "http://www.salam.edu.af/",
  },
  {
    value: "76",
    label: "Shaikh Zayed University",
    website: "http://www.szu.edu.af/",
  },
  {
    value: "77",
    label: "Taj Institute of Higher Education",
    website: "http://www.taj.edu.af/",
  },
  {
    value: "78",
    label: "Takhar University",
    website: "http://www.tu.edu.af/",
  },
  {
    value: "79",
    label: "American University of Antigua",
    website: "http://www.auamed.org/",
  },
  {
    value: "80",
    label: "University of Health Sciences Antigua",
    website: "http://www.uhsa.ag/",
  },
  {
    value: "81",
    label: "Academy of Arts",
    website: "http://www.akademiaearteve.edu.al/",
  },
  {
    value: "82",
    label: "Academy of Sports and Physical Training",
    website: "http://www.aefs.edu.al/",
  },
  {
    value: "83",
    label: "Agricultural University of Tirane",
    website: "http://www.ubt.edu.al/",
  },
  {
    value: "84",
    label: "American University of Tirana",
    website: "http://www.uat.edu.al/",
  },
  {
    value: "85",
    label: "Beder University",
    website: "http://www.beder.edu.al/",
  },
  {
    value: "86",
    label: "Epoka University",
    website: "http://www.epoka.edu.al/",
  },
  {
    value: "87",
    label: "Polytechnic University of Tirane",
    website: "http://www.upt.al/",
  },
  {
    value: "88",
    label: 'University of Elbasan "Aleksander Xhuvani"',
    website: "http://www.uniel.edu.al/",
  },
  {
    value: "89",
    label: 'University of Gjirokstra "Eqerem Cabej"',
    website: "http://www.uogj.edu.al/",
  },
  {
    value: "90",
    label: 'University of Korca "Fan Noli"',
    website: "http://www.unkorce.edu.al/",
  },
  {
    value: "91",
    label: "University of New York Tirana",
    website: "http://www.unyt.edu.al/",
  },
  {
    value: "92",
    label: 'University of Shkodra "Luigj Gurakuqi"',
    website: "http://www.unishk.edu.al/",
  },
  {
    value: "93",
    label: "University of Tirana",
    website: "http://www.unitir.edu.al/",
  },
  {
    value: "94",
    label: 'University of Vlora "Ismail Qemali"',
    website: "http://www.univlora.edu.al/",
  },
  {
    value: "95",
    label: "University “Pavaresia” Vlore",
    website: "http://www.unipavaresia.edu.al/",
  },
  {
    value: "96",
    label: "American University of Armenia",
    website: "http://www.aua.am/",
  },
  {
    value: "97",
    label: "Armenian State Agrarian University",
    website: "http://www.asau.am/",
  },
  {
    value: "98",
    label: "Armenian State University of Economics",
    website: "http://www.asue.am/",
  },
  {
    value: "99",
    label: "Eurasia International University",
    website: "http://www.eiu.am/",
  },
  {
    value: "100",
    label: "European Regional Educational Academy of Armenia",
    website: "http://www.eriicta.am/",
  },
  {
    value: "101",
    label: "French University in Armenia (UFAR)",
    website: "http://www.ufar.am/",
  },
  {
    value: "102",
    label: "Mehrabyan Medical Institute and Medical College",
    website: "http://www.armmed.am/",
  },
  {
    value: "103",
    label: "Russian-Armenian (Slavonic) State University",
    website: "http://www.rau.am/",
  },
  {
    value: "104",
    label: "State Engineering University of Armenia",
    website: "http://www.seua.am/",
  },
  {
    value: "105",
    label: "Yerevan Haibusak University",
    website: "http://www.haybusak.org/",
  },
  {
    value: "106",
    label: "Yerevan State Medical University",
    website: "http://www.ysmu.am/",
  },
  {
    value: "107",
    label: "Yerevan State University",
    website: "http://www.ysu.am/",
  },
  {
    value: "108",
    label: "American University of the Caribbean, Sint Maarten",
    website: "http://www.aucmed.edu/",
  },
  {
    value: "109",
    label: "International University School of Medicine (IUSOM)",
    website: "http://www.internationaluniversity-schoolofmedicine.org/",
  },
  {
    value: "110",
    label: "St.James's School of Medicine, Bonaire",
    website: "http://www.sjsm.org/",
  },
  {
    value: "111",
    label: "University of Sint Eustatius School of Medicine",
    website: "http://www.eustatiusmed.edu/",
  },
  {
    value: "112",
    label: "University of the Netherlands Antilles, Curacao",
    website: "http://www.una.an/",
  },
  {
    value: "113",
    label: "Universidade Católica de Angola",
    website: "http://www.ucan.edu/",
  },
  {
    value: "114",
    label: "Universidade Gregorio Semedo",
    website: "http://www.ugs.ed.ao/",
  },
  {
    value: "115",
    label: "Universidade Independente de Angola",
    website: "http://www.unia.ao/",
  },
  {
    value: "116",
    label: "Universidade Lusíada de Angola",
    website: "http://www.ulangola.net/",
  },
  {
    value: "117",
    label: "Universidade Metodista de Angola",
    website: "http://www.uma.co.ao/",
  },
  {
    value: "118",
    label: "Universidade Metropolitana de Angola",
    website: "http://www.unimetroangola.com/",
  },
  {
    value: "119",
    label: "Universidade Tecnica de Angola",
    website: "http://www.utanga.co.ao/",
  },
  {
    value: "120",
    label: "Univesidade Agostinho Neto",
    website: "http://www.uan.ao/",
  },
  {
    value: "121",
    label: "Instituto de Enseñanza Superior del Ejército",
    website: "http://www.iese.edu.ar/",
  },
  {
    value: "122",
    label: 'Instituto Nacional de Educación Física "General Manuel Belgrano"',
    website: "http://www.unlu.edu.ar/Belgrano.htm",
  },
  {
    value: "123",
    label: "Instituto Tecnológico de Buenos Aires",
    website: "http://www.itba.edu.ar/",
  },
  {
    value: "124",
    label: "Instituto Universitario Aeronáutico",
    website: "http://www.iua.edu.ar/",
  },
  {
    value: "125",
    label: "Instituto Universitario CEMA",
    website: "http://www.cema.edu.ar/",
  },
  {
    value: "126",
    label:
      "Instituto Universitario de Ciencias de la Salud Fundación H.A. Barceló",
    website: "http://www.barcelo.edu.ar/",
  },
  {
    value: "127",
    label: "Instituto Universitario de Estudios Navales y Marítimos",
    website: "http://www.inun.edu.ar/",
  },
  {
    value: "128",
    label: "Instituto Universitario de la Policia Federal",
    website: "http://www.universidad-policial.edu.ar/",
  },
  {
    value: "129",
    label: "Instituto Universitario Nacional del Arte",
    website: "http://www.iuna.edu.ar/",
  },
  {
    value: "130",
    label: "Universidad Abierta Interamericana",
    website: "http://www.vaneduc.edu.ar/uai/",
  },
  {
    value: "131",
    label: "Universidad Adventista del Plata",
    website: "http://www.uapar.edu/",
  },
  {
    value: "132",
    label: "Universidad Argentina de la Empresa",
    website: "http://www.uade.edu.ar/",
  },
  {
    value: "133",
    label: 'Universidad Argentina "John F. Kennedy"',
    website: "http://www.kennedy.edu.ar/",
  },
  {
    value: "134",
    label: "Universidad Atlantida Argentina",
    website: "http://www.atlantida.edu.ar/",
  },
  {
    value: "135",
    label: "Universidad Austral Buenos Aires",
    website: "http://www.austral.edu.ar/",
  },
  {
    value: "136",
    label: "Universidad Autónoma de Entre Ríos",
    website: "http://www.uader.edu.ar/",
  },
  {
    value: "137",
    label: "Universidad Blas Pascal",
    website: "http://www.ubp.edu.ar/",
  },
  {
    value: "138",
    label: "Universidad CAECE, Buenos Aires",
    website: "http://www.caece.edu.ar/",
  },
  {
    value: "139",
    label: "Universidad Católica Argentina Santa Maria de los Buenos Aires",
    website: "http://www.uca.edu.ar/",
  },
  {
    value: "140",
    label: "Universidad Católica de Córdoba",
    website: "http://www.uccor.edu.ar/",
  },
  {
    value: "141",
    label: "Universidad Católica de Cuyo",
    website: "http://www.uccuyo.edu.ar/",
  },
  {
    value: "142",
    label: "Universidad Católica de La Plata",
    website: "http://www.ucalp.edu.ar/",
  },
  {
    value: "143",
    label: "Universidad Católica de Salta",
    website: "http://www.ucasal.edu.ar/",
  },
  {
    value: "144",
    label: "Universidad Católica de Santa Fé",
    website: "http://www.ucsf.edu.ar/",
  },
  {
    value: "145",
    label: "Universidad Católica de Santiago del Estero",
    website: "http://www.ucse.edu.ar/",
  },
  {
    value: "146",
    label: "Universidad Champagnat",
    website: "http://www.uch.edu.ar/",
  },
  {
    value: "147",
    label: "Universidad de Belgrano",
    website: "http://www.ub.edu.ar/",
  },
  {
    value: "148",
    label: "Universidad de Bologna - Representación en Buenos Aires",
    website: "http://www.unibo.edu.ar/",
  },
  {
    value: "149",
    label: "Universidad de Buenos Aires",
    website: "http://www.uba.ar/",
  },
  {
    value: "150",
    label: "Universidad de Ciencias Empresariales y Sociales",
    website: "http://www.uces.edu.ar/",
  },
  {
    value: "151",
    label: "Universidad de Concepción del Uruguay",
    website: "http://www.ucu.edu.ar/",
  },
  {
    value: "152",
    label: "Universidad de Congreso",
    website: "http://www.ucongres.edu.ar/",
  },
  {
    value: "153",
    label: "Universidad de Flores - Buenos Aires",
    website: "http://www.uflo.edu.ar/",
  },
  {
    value: "154",
    label: "Universidad del Aconcagua",
    website: "http://www.uda.edu.ar/",
  },
  {
    value: "155",
    label: "Universidad de la Cuenca del Plata",
    website: "http://www.ucp.edu.ar/",
  },
  {
    value: "156",
    label:
      "Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)",
    website: "http://www.ufasta.edu.ar/",
  },
  {
    value: "157",
    label: "Universidad de la Marina Mercante",
    website: "http://www.udemm.edu.ar/",
  },
  {
    value: "158",
    label: "Universidad del Centro Educativo Latinoamericano Rosario",
    website: "http://www.ucel.edu.ar/",
  },
  {
    value: "159",
    label: "Universidad del Cine - Buenos Aires",
    website: "http://www.ucine.edu.ar/",
  },
  {
    value: "160",
    label: "Universidad del Museo Social Argentino",
    website: "http://www.umsa.edu.ar/",
  },
  {
    value: "161",
    label: 'Universidad del Norte "Santo Tomás de Aquino"',
    website: "http://www.unsta.edu.ar/",
  },
  {
    value: "162",
    label: "Universidad del Salvador",
    website: "http://www.salvador.edu.ar/",
  },
  {
    value: "163",
    label: "Universidad de Mendoza",
    website: "http://www.um.edu.ar/",
  },
  {
    value: "164",
    label: "Universidad de Morón",
    website: "http://www.unimoron.edu.ar/",
  },
  {
    value: "165",
    label: "Universidad de Palermo",
    website: "http://www.palermo.edu.ar/",
  },
  {
    value: "166",
    label: "Universidad de San Andres",
    website: "http://www.udesa.edu.ar/",
  },
  {
    value: "167",
    label: "Universidad Empresarial Siglio 21",
    website: "http://www.uesiglo21.edu.ar/",
  },
  {
    value: "168",
    label: "Universidad ISALUD",
    website: "http://www.isalud.edu.ar/",
  },
  {
    value: "169",
    label: 'Universidad "Juan Agustín Maza"',
    website: "http://www.umaza.edu.ar/",
  },
  {
    value: "170",
    label: "Universidad Maimónides",
    website: "http://www.maimonides.edu.ar/",
  },
  {
    value: "171",
    label: "Universidad Nacional de Catamarca",
    website: "http://www.unca.edu.ar/",
  },
  {
    value: "172",
    label: "Universidad Nacional de Córdoba",
    website: "http://www.unc.edu.ar/",
  },
  {
    value: "173",
    label: "Universidad Nacional de Cuyo",
    website: "http://www.uncu.edu.ar/",
  },
  {
    value: "174",
    label: "Universidad Nacional de Entre Ríos",
    website: "http://www.uner.edu.ar/",
  },
  {
    value: "175",
    label: "Universidad Nacional de Formosa",
    website: "http://www.unf.edu.ar/",
  },
  {
    value: "176",
    label: "Universidad Nacional de General San Martín",
    website: "http://www.unsam.edu.ar/",
  },
  {
    value: "177",
    label: "Universidad Nacional de General Sarmiento",
    website: "http://www.ungs.edu.ar/",
  },
  {
    value: "178",
    label: "Universidad Nacional de Jujuy",
    website: "http://www.unju.edu.ar/",
  },
  {
    value: "179",
    label: "Universidad Nacional de La Matanza",
    website: "http://www.unlam.edu.ar/",
  },
  {
    value: "180",
    label: "Universidad Nacional de Lanus",
    website: "http://www.unla.edu.ar/",
  },
  {
    value: "181",
    label: "Universidad Nacional de La Pampa",
    website: "http://www.unlpam.edu.ar/",
  },
  {
    value: "182",
    label: "Universidad Nacional de la Patagonia Austral",
    website: "http://www.unpa.edu.ar/",
  },
  {
    value: "183",
    label: 'Universidad Nacional de la Patagonia "San Juan Bosco"',
    website: "http://www.unp.edu.ar/",
  },
  {
    value: "184",
    label: "Universidad Nacional de La Plata",
    website: "http://www.unlp.edu.ar/",
  },
  {
    value: "185",
    label: "Universidad Nacional de La Rioja",
    website: "http://www.unlar.edu.ar/",
  },
  {
    value: "186",
    label: "Universidad Nacional del Centro de la Provincia de Buenos Aires",
    website: "http://www.unicen.edu.ar/",
  },
  {
    value: "187",
    label: "Universidad Nacional del Comahue",
    website: "http://www.uncoma.edu.ar/",
  },
  {
    value: "188",
    label: "Universidad Nacional del Littoral",
    website: "http://www.unl.edu.ar/",
  },
  {
    value: "189",
    label: "Universidad Nacional del Nordeste",
    website: "http://www.unne.edu.ar/",
  },
  {
    value: "190",
    label: "Universidad Nacional del Noroeste de la Provincia de Buenos Aires",
    website: "http://www.unnoba.edu.ar/",
  },
  {
    value: "191",
    label: "Universidad Nacional de Lomas de Zamora",
    website: "http://www.unlz.edu.ar/",
  },
  {
    value: "192",
    label: "Universidad Nacional del Sur",
    website: "http://www.uns.edu.ar/",
  },
  {
    value: "193",
    label: "Universidad Nacional de Luján",
    website: "http://www.unlu.edu.ar/",
  },
  {
    value: "194",
    label: "Universidad Nacional de Mar del Plata",
    website: "http://www.mdp.edu.ar/",
  },
  {
    value: "195",
    label: "Universidad Nacional de Misiones",
    website: "http://www.unam.edu.ar/",
  },
  {
    value: "196",
    label: "Universidad Nacional de Quilmes",
    website: "http://www.unq.edu.ar/",
  },
  {
    value: "197",
    label: "Universidad Nacional de Río Cuarto",
    website: "http://www.unrc.edu.ar/",
  },
  {
    value: "198",
    label: "Universidad Nacional de Rosario",
    website: "http://www.unr.edu.ar/",
  },
  {
    value: "199",
    label: "Universidad Nacional de Salta",
    website: "http://www.unsa.edu.ar/",
  },
  {
    value: "200",
    label: "Universidad Nacional de San Juan",
    website: "http://www.unsj.edu.ar/",
  },
  {
    value: "201",
    label: "Universidad Nacional de San Luis",
    website: "http://www.unsl.edu.ar/",
  },
  {
    value: "202",
    label: "Universidad Nacional de Santiago del Estero",
    website: "http://www.unse.edu.ar/",
  },
  {
    value: "203",
    label: "Universidad Nacional de Tres de Febrero",
    website: "http://www.untref.edu.ar/",
  },
  {
    value: "204",
    label: "Universidad Nacional de Tucumán",
    website: "http://www.unt.edu.ar/",
  },
  {
    value: "205",
    label: "Universidad Nacional de Villa María",
    website: "http://www.unvm.edu.ar/",
  },
  {
    value: "206",
    label: "Universidad Notarial Argentina",
    website: "http://www.universidadnotarial.edu.ar/",
  },
  {
    value: "207",
    label: "Universidad Tecnológica Nacional",
    website: "http://www.utn.edu.ar/",
  },
  {
    value: "208",
    label: "Universidad Torcuato di Tella",
    website: "http://www.utdt.edu/",
  },
  {
    value: "209",
    label: "Akademie der bildenden Künste Wien",
    website: "http://www.akbild.ac.at/",
  },
  {
    value: "210",
    label: "Donau-Universität Krems",
    website: "http://www.donau-uni.ac.at/",
  },
  {
    value: "211",
    label: "Fachhochschule Burgenland",
    website: "http://www.fh-burgenland.at/",
  },
  {
    value: "212",
    label: "Fachhochschule für Bank- und Finanzwirtschaft",
    website: "http://www.fh-vie.ac.at/",
  },
  {
    value: "213",
    label: "Fachhochschule JOANNEUM",
    website: "http://www.fh-joanneum.at/",
  },
  {
    value: "214",
    label: "Fachhochschule Kärnten",
    website: "http://www.fh-kaernten.ac.at/",
  },
  {
    value: "215",
    label: "Fachhochschule Krems",
    website: "http://www.fh-krems.ac.at/",
  },
  {
    value: "216",
    label: "Fachhochschule Kufstein (Tirol)",
    website: "http://www.fh-kufstein.ac.at/",
  },
  {
    value: "217",
    label: "Fachhochschule Salzburg",
    website: "http://www.fh-sbg.ac.at/",
  },
  {
    value: "218",
    label: "Fachhochschule St. Pölten",
    website: "http://www.fh-stpoelten.ac.at/",
  },
  {
    value: "219",
    label: "Fachhochschule Vorarlberg",
    website: "http://www.fhv.at/",
  },
  {
    value: "220",
    label: "Fachhochschule Wiener Neustadt",
    website: "http://www.fhwn.ac.at/",
  },
  {
    value: "221",
    label: "Fachhochschulstudiengänge der Wiener Wirtschaft",
    website: "http://www.fhw.at/",
  },
  {
    value: "222",
    label: "Fachhochschulstudiengänge Hagenberg",
    website: "http://www.fh-hagenberg.at/",
  },
  {
    value: "223",
    label: "Fachhochschulstudiengänge Krems IMC",
    website: "http://www.imc-krems.ac.at/",
  },
  {
    value: "224",
    label: "Fachhochschulstudiengänge Steyr",
    website: "http://www.fh-steyr.at/",
  },
  {
    value: "225",
    label: "Fachhochschulstudiengänge Wels",
    website: "http://www.fh-wels.at/",
  },
  {
    value: "226",
    label: "Institute of Science and Technology",
    website: "http://www.ist.ac.at/",
  },
  {
    value: "227",
    label: "Katholisch-Theologische Privatuniversität Linz",
    website: "http://www.kth-linz.ac.at/",
  },
  {
    value: "228",
    label: "MCI-Management Center Innsbruck",
    website: "http://www.mci.edu/",
  },
  {
    value: "229",
    label: "Medizinische Universität Graz",
    website: "http://www.meduni-graz.at/",
  },
  {
    value: "230",
    label: "Medizinische Universität Innsbruck",
    website: "http://www.i-med.ac.at/",
  },
  {
    value: "231",
    label: "Medizinische Universität Wien",
    website: "http://www.meduniwien.at/",
  },
  {
    value: "232",
    label: "Montanuniversität Leoben",
    website: "http://www.unileoben.ac.at/",
  },
  {
    value: "233",
    label: "Private Universität für Medizinische Informatik und Technik Tirol",
    website: "http://www.umit.at/",
  },
  {
    value: "234",
    label: "Technikum Wien",
    website: "http://www.technikum-wien.at/",
  },
  {
    value: "235",
    label: "Technische Universität Graz",
    website: "http://www.tugraz.at/",
  },
  {
    value: "236",
    label: "Technische Universität Wien",
    website: "http://www.tuwien.ac.at/",
  },
  {
    value: "237",
    label: "Universität für angewandte Kunst Wien",
    website: "http://ns2.uni-ak.ac.at/",
  },
  {
    value: "238",
    label: "Universität für Bodenkultur Wien",
    website: "http://www.boku.ac.at/",
  },
  {
    value: "239",
    label: "Universität für künstlerische und industrielle Gestaltung Linz",
    website: "http://www.khs-linz.ac.at/",
  },
  {
    value: "240",
    label: "Universität für Musik und darstellende Kunst Graz",
    website: "http://www.kug.ac.at/",
  },
  {
    value: "241",
    label: "Universität für Musik und darstellende Kunst  Wien",
    website: "http://www.mdw.ac.at/",
  },
  {
    value: "242",
    label: "Universität Graz",
    website: "http://www.kfunigraz.ac.at/",
  },
  {
    value: "243",
    label: "Universität Innsbruck",
    website: "http://www.uibk.ac.at/",
  },
  {
    value: "244",
    label: "Universität Klagenfurt",
    website: "http://www.uni-klu.ac.at/",
  },
  {
    value: "245",
    label: "Universität Linz",
    website: "http://www.uni-linz.ac.at/",
  },
  {
    value: "246",
    label: "Universität Mozarteum Salzburg",
    website: "http://www.moz.ac.at/",
  },
  {
    value: "247",
    label: "Universität Salzburg",
    website: "http://www.sbg.ac.at/",
  },
  {
    value: "248",
    label: "Universität Vienna",
    website: "http://www.univie.ac.at/",
  },
  {
    value: "249",
    label: "Veterinärmedizinische Universität Wien",
    website: "http://www.vu-wien.ac.at/",
  },
  {
    value: "250",
    label: "Webster University, Vienna",
    website: "http://www.webster.ac.at/",
  },
  {
    value: "251",
    label: "Wirtschaftsuniversität Wien",
    website: "http://www.wu-wien.ac.at/",
  },
  {
    value: "252",
    label: "Australian Catholic University",
    website: "http://www.acu.edu.au/",
  },
  {
    value: "253",
    label: "Australian Correspondence Schools",
    website: "http://www.acs.edu.au/",
  },
  {
    value: "254",
    label: "Australian Defence Force Academy",
    website: "http://www.adfa.oz.au/",
  },
  {
    value: "255",
    label: "Australian Lutheran College",
    website: "http://www.alc.edu.au/",
  },
  {
    value: "256",
    label: "Australian Maritime College",
    website: "http://www.amc.edu.au/",
  },
  {
    value: "257",
    label: "Australian National University",
    website: "http://www.anu.edu.au/",
  },
  {
    value: "258",
    label: "Avondale College",
    website: "http://www.avondale.edu.au/",
  },
  {
    value: "259",
    label: "Batchelor Institute of Indigenous Tertiary Education",
    website: "http://www.nt.gov.au/batc/",
  },
  {
    value: "260",
    label: "Blue Mountains Hotel School",
    website: "http://www.hotelschool.com.au/",
  },
  {
    value: "261",
    label: "Bond University",
    website: "http://www.bond.edu.au/",
  },
  {
    value: "262",
    label: "Central Queensland University",
    website: "http://www.cqu.edu.au/",
  },
  {
    value: "263",
    label: "Charles Darwin University",
    website: "http://www.cdu.edu.au/",
  },
  {
    value: "264",
    label: "Charles Sturt University",
    website: "http://www.csu.edu.au/",
  },
  {
    value: "265",
    label: "Curtin University of Technology",
    website: "http://www.curtin.edu.au/",
  },
  {
    value: "266",
    label: "Deakin University",
    website: "http://www.deakin.edu.au/",
  },
  {
    value: "267",
    label: "Edith Cowan University",
    website: "http://www.ecu.edu.au/",
  },
  {
    value: "268",
    label: "Flinders University of South Australia",
    website: "http://www.flinders.edu.au/",
  },
  {
    value: "269",
    label: "Griffith University",
    website: "http://www.gu.edu.au/",
  },
  {
    value: "270",
    label: "Holmes Institute",
    website: "http://www.holmes.edu.au/",
  },
  {
    value: "271",
    label: "Institute Of Technology, Australia",
    website: "http://www.iota.edu.au/",
  },
  {
    value: "272",
    label: "International College of Tourism and Hotel Management",
    website: "http://www.icthm.edu.au/",
  },
  {
    value: "273",
    label: "James Cook University of North Queensland",
    website: "http://www.jcu.edu.au/",
  },
  {
    value: "274",
    label: "La Trobe University",
    website: "http://www.latrobe.edu.au/",
  },
  {
    value: "275",
    label: "Macquarie University",
    website: "http://www.mq.edu.au/",
  },
  {
    value: "276",
    label: "Marcus Oldham College",
    website: "http://www.marcusoldham.vic.edu.au/",
  },
  {
    value: "277",
    label: "Monash University",
    website: "http://www.monash.edu.au/",
  },
  {
    value: "278",
    label: "Murdoch University",
    website: "http://www.murdoch.edu.au/",
  },
  {
    value: "279",
    label: "Queensland University of Technology",
    website: "http://www.qut.edu.au/",
  },
  {
    value: "280",
    label: "Royal Melbourne Institute of Technology",
    website: "http://www.rmit.edu.au/",
  },
  {
    value: "281",
    label: "Southern Cross University",
    website: "http://www.scu.edu.au/",
  },
  {
    value: "282",
    label: "Swinburne University of Technology",
    website: "http://www.swin.edu.au/",
  },
  {
    value: "283",
    label: "University of Adelaide",
    website: "http://www.adelaide.edu.au/",
  },
  {
    value: "284",
    label: "University of Ballarat",
    website: "http://www.ballarat.edu.au/",
  },
  {
    value: "285",
    label: "University of Canberra",
    website: "http://www.canberra.edu.au/",
  },
  {
    value: "286",
    label: "University of Divinity",
    website: "http://www.divinity.edu.au/",
  },
  {
    value: "287",
    label: "University of Melbourne",
    website: "http://www.unimelb.edu.au/",
  },
  {
    value: "288",
    label: "University of Newcastle",
    website: "http://www.newcastle.edu.au/",
  },
  {
    value: "289",
    label: "University of New England",
    website: "http://www.une.edu.au/",
  },
  {
    value: "290",
    label: "University of New South Wales",
    website: "http://www.unsw.edu.au/",
  },
  {
    value: "291",
    label: "University of Notre Dame Australia",
    website: "http://www.nd.edu.au/",
  },
  {
    value: "292",
    label: "University of Queensland",
    website: "http://www.uq.edu.au/",
  },
  {
    value: "293",
    label: "University of South Australia",
    website: "http://www.unisa.edu.au/",
  },
  {
    value: "294",
    label: "University of Southern Queensland",
    website: "http://www.usq.edu.au/",
  },
  {
    value: "295",
    label: "University of Sydney",
    website: "http://www.usyd.edu.au/",
  },
  {
    value: "296",
    label: "University of Tasmania",
    website: "http://www.utas.edu.au/",
  },
  {
    value: "297",
    label: "University of Technology Sydney",
    website: "http://www.uts.edu.au/",
  },
  {
    value: "298",
    label: "University of the Sunshine Coast",
    website: "http://www.usc.edu.au/",
  },
  {
    value: "299",
    label: "University of Western Australia",
    website: "http://www.uwa.edu.au/",
  },
  {
    value: "300",
    label: "University of Western Sydney",
    website: "http://www.uws.edu.au/",
  },
  {
    value: "301",
    label: "University of Wollongong",
    website: "http://www.uow.edu.au/",
  },
  {
    value: "302",
    label: "Academy of Public Administration",
    website: "http://www.dia.edu.az/",
  },
  {
    value: "303",
    label: "Azerbaijan Diplomatic Academy",
    website: "http://www.ada.edu.az/",
  },
  {
    value: "304",
    label: "Azerbaijan International University",
    website: "http://www.abu.az/",
  },
  {
    value: "305",
    label: "Azerbaijan Medical University",
    website: "http://www.amu.edu.az/",
  },
  {
    value: "306",
    label: "Azerbaijan National Conservatorie",
    website: "http://www.conservatory.az/",
  },
  {
    value: "307",
    label: "Azerbaijan State Economic University",
    website: "http://www.aseu.az/",
  },
  {
    value: "308",
    label: "Azerbaijan State Oil Academy",
    website: "http://adna.jis.az/",
  },
  {
    value: "309",
    label: "Azerbaijan Technical University",
    website: "http://www.aztu.az/",
  },
  {
    value: "310",
    label: "Azerbaijan Technology University",
    website: "http://www.aztu.edu.az/",
  },
  {
    value: "311",
    label: "Azerbaijan Toursim Institute",
    website: "http://www.tourism.edu.az/",
  },
  {
    value: "312",
    label: "Azerbaijan University",
    website: "http://www.au.edu.az/",
  },
  {
    value: "313",
    label: "Azerbaijan University ol Languages",
    website: "http://www.adu.edu.az/",
  },
  {
    value: "314",
    label: "Baki Business University",
    website: "http://www.bbu.edu.az/",
  },
  {
    value: "315",
    label: "Baku Slavic University",
    website: "http://www.bsu-edu.org/",
  },
  {
    value: "316",
    label: "Baku State University",
    website: "http://www.bsu.az/",
  },
  {
    value: "317",
    label: "Ganja State University ",
    website: "http://www.gsu.az/",
  },
  {
    value: "318",
    label: "Khazar University",
    website: "http://www.khazar.org/",
  },
  {
    value: "319",
    label: "Lankaran State University",
    website: "http://www.lsu.edu.az/",
  },
  {
    value: "320",
    label: "Nakhchivan Private University",
    website: "http://www.az-npu.org/",
  },
  {
    value: "321",
    label: "Nakhchivan State University",
    website: "http://www.ndu.edu.az/",
  },
  {
    value: "322",
    label: "National Aviation Academy",
    website: "http://www.naa.edu.az/",
  },
  {
    value: "323",
    label: "Odlar Yurdu University",
    website: "http://www.oyu.edu.az/",
  },
  {
    value: "324",
    label: "Police Academy",
    website: "http://www.pa.edu.az/",
  },
  {
    value: "325",
    label: "Qafqaz University",
    website: "http://www.qafqaz.edu.az/",
  },
  {
    value: "326",
    label: "Sumgait State University",
    website: "http://www.sdu.edu.az/",
  },
  {
    value: "327",
    label: "Western University",
    website: "http://www.wu.edu.az/",
  },
  {
    value: "328",
    label: "American University",
    website: "http://www.aubih.ba/",
  },
  {
    value: "329",
    label: "International Burch University",
    website: "http://www.ibu.edu.ba/",
  },
  {
    value: "330",
    label: "International University of Sarajevo",
    website: "http://www.ius.edu.ba/",
  },
  {
    value: "331",
    label: "International University of Travnik",
    website: "http://www.iu-travnik.com/",
  },
  {
    value: "332",
    label: "Sarajevo Film Academy",
    website: "http://www.sfa.ba/",
  },
  {
    value: "333",
    label: "Slobomir P Univerzitet",
    website: "http://www.spu.ba/",
  },
  {
    value: "334",
    label: "University of Banja Luka",
    website: "http://www.unibl.org/",
  },
  {
    value: "335",
    label: "University of Bihac",
    website: "http://www.unbi.ba/",
  },
  {
    value: "336",
    label: "University of East Srarajevo",
    website: "http://www.unssa.rs.ba/",
  },
  {
    value: "337",
    label: "University of Mostar",
    website: "http://www.sve-mo.ba/",
  },
  {
    value: "338",
    label: "University of Sarajevo",
    website: "http://www.unsa.ba/",
  },
  {
    value: "339",
    label: "University of Tuzla",
    website: "http://www.untz.ba/",
  },
  {
    value: "340",
    label: "University of Zenica",
    website: "http://www.unze.ba/",
  },
  {
    value: "341",
    label: "University Vitez In Travnik",
    website: "http://www.unvi.edu.ba/",
  },
  {
    value: "342",
    label: 'Univerzitet u Mostaru "Dzemal Bijedic"',
    website: "http://www.unmo.ba/",
  },
  {
    value: "343",
    label: "University of the West Indies, Cave Hill",
    website: "http://www.uwichill.edu.bb/",
  },
  {
    value: "344",
    label: "Ahsanullah University of Science & Technology",
    website: "http://www.aust.edu/",
  },
  {
    value: "345",
    label: "American International University - Bangladesh",
    website: "http://www.aiub.edu/",
  },
  {
    value: "346",
    label: "Asa University Bangladesh",
    website: "http://www.asaub.edu.bd/",
  },
  {
    value: "347",
    label: "Asian University of Bangladesh",
    website: "http://www.aub-bd.org/",
  },
  {
    value: "348",
    label: "Atish Dipankar University",
    website: "http://www.atishdipankaruniversity.edu.bd/",
  },
  {
    value: "349",
    label: "Bangabandhu Sheikh Mujibur Rahman Agricultural University",
    website: "http://www.bsmrau.edu.bd/",
  },
  {
    value: "350",
    label: "Bangabandhu Sheikh Mujibur Rahman Medical University",
    website: "http://www.bsmmu.edu.bd/",
  },
  {
    value: "351",
    label: "Bangladesh Agricultural University",
    website: "http://www.bau.edu.bd/",
  },
  {
    value: "352",
    label: "Bangladesh Open University",
    website: "http://www.bou.edu.bd/",
  },
  {
    value: "353",
    label: "Bangladesh University",
    website: "http://www.bu.edu.bd/",
  },
  {
    value: "354",
    label: "Bangladesh University of Business & Technology",
    website: "http://www.bubt.edu.bd/",
  },
  {
    value: "355",
    label: "Bangladesh University of Engineering and Technology",
    website: "http://www.buet.ac.bd/",
  },
  {
    value: "356",
    label: "Bangladesh University of Professionals",
    website: "http://www.bup.edu.bd/",
  },
  {
    value: "357",
    label: "Bangladesh University of Textiles",
    website: "http://www.butex.edu.bd/",
  },
  {
    value: "358",
    label: "Begum Rokeya University, Rangpur",
    website: "http://www.brur.ac.bd/",
  },
  {
    value: "359",
    label: "BGC Trust University, Bangladesh",
    website: "http://www.bgctrustbd.org/btub/",
  },
  {
    value: "360",
    label: "Brac University",
    website: "http://www.bracuniversity.net/",
  },
  {
    value: "361",
    label: "Chittagong Independent University",
    website: "http://www.ciu.edu.bd/",
  },
  {
    value: "362",
    label: "Chittagong University of Engineering and Technology",
    website: "http://www.cuet.ac.bd/",
  },
  {
    value: "363",
    label: "City University",
    website: "http://www.cityuniversity.edu.bd/",
  },
  {
    value: "364",
    label: "Comilla University",
    website: "http://www.cou.ac.bd/",
  },
  {
    value: "365",
    label: "Daffodil International University",
    website: "http://www.daffodilvarsity.edu.bd/",
  },
  {
    value: "366",
    label: "Darul Ihsan University",
    website: "http://www.diu.ac.bd/",
  },
  {
    value: "367",
    label: "Dhaka International University",
    website: "http://www.diu.net.bd/",
  },
  {
    value: "368",
    label: "Dhaka University of Engineering and Technology",
    website: "http://www.duet.ac.bd/",
  },
  {
    value: "369",
    label: "East Delta University",
    website: "http://www.eastdelta.edu.bd/",
  },
  {
    value: "370",
    label: "Eastern University",
    website: "http://www.easternuni.edu.bd/",
  },
  {
    value: "371",
    label: "East West University",
    website: "http://www.ewubd.edu/",
  },
  {
    value: "372",
    label: "East-West University, Mohakhali",
    website: "http://www.ewubd.edu/",
  },
  {
    value: "373",
    label: "Green University of Bangladesh",
    website: "http://www.green.edu.bd/",
  },
  {
    value: "374",
    label: "Hajee Mohammad Danesh Science and Technology University",
    website: "http://www.hstu.ac.bd/",
  },
  {
    value: "375",
    label: "IBAIS University",
    website: "http://www.ibaisuniv.edu.bd/",
  },
  {
    value: "376",
    label: "Independent University, Bangladesh",
    website: "http://www.iub.edu.bd/",
  },
  {
    value: "377",
    label: "International Culture University ",
    website: "http://www.icu-edu.org/",
  },
  {
    value: "378",
    label: "International Islamic University Chittagong",
    website: "http://www.iiuc.ac.bd/",
  },
  {
    value: "379",
    label: "International University of Business Agriculture and Technology",
    website: "http://www.iubat.edu/",
  },
  {
    value: "380",
    label: "Islamic University Kushtia",
    website: "http://www.iu.ac.bd/",
  },
  {
    value: "381",
    label: "Islamic University of Technology",
    website: "http://www.iutoic-dhaka.edu/",
  },
  {
    value: "382",
    label: "Jagannath University",
    website: "http://www.jnu.ac.bd/",
  },
  {
    value: "383",
    label: "Jahangirnagar University",
    website: "http://www.juniv.edu/",
  },
  {
    value: "384",
    label: "Jatiya Kabi Kazi Nazrul Islam University",
    website: "http://www.jkkniu.edu.bd/",
  },
  {
    value: "385",
    label: "Khulna University",
    website: "http://www.ku.ac.bd/",
  },
  {
    value: "386",
    label: "Khulna University of Engineering And Technology",
    website: "http://www.kuet.ac.bd/",
  },
  {
    value: "387",
    label: "Leading University",
    website: "http://www.lus.ac.bd/",
  },
  {
    value: "388",
    label: "Manarat International University",
    website: "http://www.manarat.ac.bd/",
  },
  {
    value: "389",
    label: "Mawlana Bhashani Science And Technology University",
    website: "http://www.mbstu.ac.bd/",
  },
  {
    value: "390",
    label: "Metropolitan University",
    website: "http://www.metrouni.edu.bd/",
  },
  {
    value: "391",
    label: "Military Institute of Science and Technology",
    website: "http://www.mist.ac.bd/",
  },
  {
    value: "392",
    label: "National University",
    website: "http://www.nu.edu.bd/",
  },
  {
    value: "393",
    label: "Noakhali University of Science and Technology",
    website: "http://www.nstu.edu.bd/",
  },
  {
    value: "394",
    label: "North East University Bangladesh",
    website: "http://www.neub.edu.bd/",
  },
  {
    value: "395",
    label: "Northern University Bangladesh",
    website: "http://www.nub.ac.bd/",
  },
  {
    value: "396",
    label: "North South University",
    website: "http://www.northsouth.edu/",
  },
  {
    value: "397",
    label: "Pabna University of Science and Technology",
    website: "http://www.pust.ac.bd/",
  },
  {
    value: "398",
    label: "Patuakhali Science and Technology University",
    website: "http://www.pstu.ac.bd/",
  },
  {
    value: "399",
    label: "People's University of Bangladesh",
    website: "http://www.pub.ac.bd/",
  },
  {
    value: "400",
    label: "Premier University",
    website: "http://www.puc.ac.bd/",
  },
  {
    value: "401",
    label: "Presidency University",
    website: "http://www.presidency.edu.bd/",
  },
  {
    value: "402",
    label: "Queens University",
    website: "http://www.queensuniversity.edu.bd/",
  },
  {
    value: "403",
    label: "Rajshahi University of Engineering and Technology",
    website: "http://www.ruet.ac.bd/",
  },
  {
    value: "404",
    label: "Shahjalal University of Science and Technology",
    website: "http://www.sust.edu/",
  },
  {
    value: "405",
    label: "Southeast University",
    website: "http://www.seu.ac.bd/",
  },
  {
    value: "406",
    label: "Southern University Bangladesh",
    website: "http://www.southern.edu.bd/",
  },
  {
    value: "407",
    label: "Stamford University",
    website: "http://www.stamforduniversity.edu.bd/",
  },
  {
    value: "408",
    label: "Sylhet Agricultural University",
    website: "http://www.sau.ac.bd/",
  },
  {
    value: "409",
    label: "Sylhet Engineering College",
    website: "http://www.sec.ac.bd/",
  },
  {
    value: "410",
    label: "Sylhet International University",
    website: "http://www.siu.edu.bd/",
  },
  {
    value: "411",
    label: "United International University",
    website: "http://www.uiu.ac.bd/",
  },
  {
    value: "412",
    label: "University of Asia Pacific, Dhanmondi",
    website: "http://www.uap-bd.edu/",
  },
  {
    value: "413",
    label: "University of Chittagong",
    website: "http://www.cu.ac.bd/",
  },
  {
    value: "414",
    label: "University of Development Alternative",
    website: "http://www.uoda.edu.bd/",
  },
  {
    value: "415",
    label: "University of Dhaka",
    website: "http://www.du.ac.bd/",
  },
  {
    value: "416",
    label: "University of Information Technology & Sciences",
    website: "http://www.uits.edu.bd/",
  },
  {
    value: "417",
    label: "University of Liberal Arts",
    website: "http://www.ulab.edu.bd/",
  },
  {
    value: "418",
    label: "University of Rajshahi",
    website: "http://www.ru.ac.bd/",
  },
  {
    value: "419",
    label: "University of Science & Technology Chittagong",
    website: "http://www.ustc.ac.bd/",
  },
  {
    value: "420",
    label: "World University of Bangladesh",
    website: "http://www.wub.edu/",
  },
  {
    value: "421",
    label: "Brexgata University Academy",
    website: "http://www.brexgata.eu/",
  },
  {
    value: "422",
    label: "Brussels Management School (ICHEC)",
    website: "http://www.ichec.be/",
  },
  {
    value: "423",
    label: "Brussels School of International Studies",
    website: "http://www.ukc.ac.uk/international/bsis/",
  },
  {
    value: "424",
    label: "College of Europe",
    website: "http://www.coleurope.eu/",
  },
  {
    value: "425",
    label: "Continental Theological Seminary",
    website: "http://www.ctsem.edu/",
  },
  {
    value: "426",
    label: "ECAM - Institut Supérieur Industriel",
    website: "http://www.ecam.be/",
  },
  {
    value: "427",
    label: "EHSAL - Europese Hogeschool Brussel",
    website: "http://www.ehsal.be/",
  },
  {
    value: "428",
    label: "Erasmushogeschool Brussel",
    website: "http://www.ehb.be/",
  },
  {
    value: "429",
    label: "European Carolus Magnus University",
    website: "http://www.carolus-magnus-university.eu/",
  },
  {
    value: "430",
    label: "European International University",
    website: "http://www.europeaniu.org/",
  },
  {
    value: "431",
    label: "Evangelische Theologische Faculteit, Leuven",
    website: "http://www.etf.edu/",
  },
  {
    value: "432",
    label: "Faculté Polytechnique de Mons",
    website: "http://www.fpms.ac.be/",
  },
  {
    value: "433",
    label: "Facultés Universitaires Catholiques de Mons",
    website: "http://www.fucam.ac.be/",
  },
  {
    value: "434",
    label: "Facultés Universitaires Notre-Dame de la Paix",
    website: "http://www.fundp.ac.be/",
  },
  {
    value: "435",
    label: "Facultés Universitaires Saint-Louis",
    website: "http://www.fusl.ac.be/",
  },
  {
    value: "436",
    label: "Faculté Universitaire des Sciences Agronomiques de Gembloux",
    website: "http://www.fsagx.ac.be/",
  },
  {
    value: "437",
    label: "Fondation Universitaire Luxembourgeoise",
    website: "http://www.ful.ac.be/",
  },
  {
    value: "438",
    label: "Hasselt University",
    website: "http://www.uhasselt.be/",
  },
  {
    value: "439",
    label: "Hogere Zeevaartschool - Maritime Academy",
    website: "http://www.hzs.be/",
  },
  {
    value: "440",
    label: "Hogeschool Antwerpen",
    website: "http://www.ha.be/",
  },
  {
    value: "441",
    label: "Hogeschool voor Wetenschap en Kunst (VLEKHO), Brussel",
    website: "http://www.vlekho.be/",
  },
  {
    value: "442",
    label: "Hogeschool voor Wetenschap & Kunst",
    website: "http://www.wenk.be/",
  },
  {
    value: "443",
    label: "Hogeschool West-Vlaanderen (TU)",
    website: "http://www.howest.be/",
  },
  {
    value: "444",
    label: "Karel De Grote Hogeschool",
    website: "http://www.kdg.be/",
  },
  {
    value: "445",
    label: "Katholieke Hogeschool Kempen",
    website: "http://www.khk.be/",
  },
  {
    value: "446",
    label: "Katholieke Hogeschool Leuven",
    website: "http://www.khleuven.be/",
  },
  {
    value: "447",
    label: "Katholieke Hogeschool Limburg",
    website: "http://www.khlim.be/",
  },
  {
    value: "448",
    label: "Katholieke Universiteit Brussel",
    website: "http://www.kubrussel.ac.be/",
  },
  {
    value: "449",
    label: "Katholieke Universiteit Leuven",
    website: "http://www.kuleuven.ac.be/",
  },
  {
    value: "450",
    label: "Katholieke Universiteit Leuven, Campus Kortrijk",
    website: "http://www.kulak.ac.be/",
  },
  {
    value: "451",
    label: "Royal Military Academy",
    website: "http://www.rma.ac.be/",
  },
  {
    value: "452",
    label: "Thierry Graduate School of Leadership",
    website: "http://www.thierryschool.org/",
  },
  {
    value: "453",
    label: "United Business Institute",
    website: "http://www.ubi.edu/",
  },
  {
    value: "454",
    label: "Université Catholique de Louvain",
    website: "http://www.ucl.ac.be/",
  },
  {
    value: "455",
    label: "Université de Liège",
    website: "http://www.ulg.ac.be/",
  },
  {
    value: "456",
    label: "Université de Mons-Hainaut",
    website: "http://www.umh.ac.be/",
  },
  {
    value: "457",
    label: "Universiteit Antwerpen",
    website: "http://www.ua.ac.be/",
  },
  {
    value: "458",
    label: "Universiteit Antwerpen Management School",
    website: "http://www.uams.be/",
  },
  {
    value: "459",
    label: "Universiteit Antwerpen, UFSIA",
    website: "http://www.ufsia.ac.be/",
  },
  {
    value: "460",
    label: "Universiteit Gent",
    website: "http://www.rug.ac.be/",
  },
  {
    value: "461",
    label: "Université Libre de Bruxelles",
    website: "http://www.ulb.ac.be/",
  },
  {
    value: "462",
    label: "Vesalius College",
    website: "http://www.vesalius.edu/",
  },
  {
    value: "463",
    label: "Vlerick Leuven Gent Management School",
    website: "http://www.vlerick.be/",
  },
  {
    value: "464",
    label: "Vrije Universiteit Brussel",
    website: "http://www.vub.ac.be/",
  },
  {
    value: "465",
    label: "Université de Ouagadougou",
    website: "http://www.univ-ouaga.bf/",
  },
  {
    value: "466",
    label: 'Academy of Economics "Dimitur A. Tscenov"',
    website: "http://www.uni-svishtov.bg/",
  },
  {
    value: "467",
    label: "Academy of Music, Dance and Fine Arts",
    website: "http://amti.hit.bg/",
  },
  {
    value: "468",
    label: "Agricultural University of Plovdiv",
    website: "http://www.au-plovdiv.bg/",
  },
  {
    value: "469",
    label: "American University in Bulgaria",
    website: "http://www.aubg.bg/",
  },
  {
    value: "470",
    label: "Bourgas Free University",
    website: "http://www.bfu.bg/",
  },
  {
    value: "471",
    label: 'Bourgas University "Prof. Assen Zlatarov"',
    website: "http://www.btu.bg/",
  },
  {
    value: "472",
    label: "City University Programs in Bulgaria ",
    website: "http://www.cityu.bg/",
  },
  {
    value: "473",
    label: "International University College",
    website: "http://www.vumk.eu/",
  },
  {
    value: "474",
    label: "Medical University of Sofia",
    website: "http://www.mu-sofia.bg/",
  },
  {
    value: "475",
    label: "Medical University Pleven",
    website: "http://www.mu-pleven.bg/",
  },
  {
    value: "476",
    label: "Medical University Plovdiv",
    website: "http://www.meduniversity-plovdiv.bg/",
  },
  {
    value: "477",
    label: "Medical University Varna",
    website: "http://www.mu-varna.bg/",
  },
  {
    value: "478",
    label: "Military University Shoumen",
    website: "http://www.pv-ma.bg/",
  },
  {
    value: "479",
    label: 'National Academy for Theatre and Film Arts "Krustju Sarafov"',
    website: "http://www.natfiz.bg/",
  },
  {
    value: "480",
    label: "National Academy of Arts",
    website: "http://www.nha-bg.org/",
  },
  {
    value: "481",
    label: 'National Academy of Music "Pantcho Vladigerov"',
    website: "http://www.nma.bg/",
  },
  {
    value: "482",
    label: "National Sports Academy Sofia",
    website: "http://www.nsa.bg/",
  },
  {
    value: "483",
    label: "New Bulgarian University",
    website: "http://www.nbu.bg/",
  },
  {
    value: "484",
    label: 'Shoumen University "Konstantin Preslavski"',
    website: "http://www.shu-bg.net/",
  },
  {
    value: "485",
    label: 'Sofia University "St. Kliment Ohridski"',
    website: "http://www.uni-sofia.bg/",
  },
  {
    value: "486",
    label: 'South-West University "Neofit Rilski"',
    website: "http://www.swu.bg/",
  },
  {
    value: "487",
    label: "Technical University of Gabrovo",
    website: "http://www.tugab.bg/",
  },
  {
    value: "488",
    label: "Technical University of Sofia",
    website: "http://www.tu-sofia.bg/",
  },
  {
    value: "489",
    label: "Technical University of Varna",
    website: "http://www.tu-varna.acad.bg/",
  },
  {
    value: "490",
    label: "Trakia University Stara Zagora",
    website: "http://www.uni-sz.bg/",
  },
  {
    value: "491",
    label: "University of Architecture, Civil Engineering and Geodesy",
    website: "http://www.uacg.bg/",
  },
  {
    value: "492",
    label: "University of Chemical Technology and Metallurgy",
    website: "http://www.uctm.edu/",
  },
  {
    value: "493",
    label: "University of Economics Varna",
    website: "http://www.ue-varna.bg/",
  },
  {
    value: "494",
    label: "University of Food Technology",
    website: "http://www.uft-plovdiv.bg/",
  },
  {
    value: "495",
    label: "University of Forestry Sofia",
    website: "http://www.ltu.bg/",
  },
  {
    value: "496",
    label: 'University of Mining and Geology "St. Ivan Rils"',
    website: "http://www.mgu.bg/",
  },
  {
    value: "497",
    label: "University of National and World Economy",
    website: "http://www.unwe.acad.bg/",
  },
  {
    value: "498",
    label: "University of Plovdiv",
    website: "http://www.uni-plovdiv.bg/",
  },
  {
    value: "499",
    label: "University of Rousse",
    website: "http://www.ru.acad.bg/",
  },
  {
    value: "500",
    label: "Varna Free University",
    website: "http://www.vfu.bg/",
  },
  {
    value: "501",
    label: 'Veliko Turnovo University "Cyril and Methodius"',
    website: "http://www.uni-vt.bg/",
  },
  {
    value: "502",
    label: "Al Ahlia University",
    website: "http://www.ahliauniversity.org/",
  },
  {
    value: "503",
    label: "Ama International University",
    website: "http://www.amaiu.edu.bh/",
  },
  {
    value: "504",
    label: "Applied Science University",
    website: "http://www.asu.edu.bh/",
  },
  {
    value: "505",
    label: "Arabian Gulf University",
    website: "http://www.agu.edu.bh/",
  },
  {
    value: "506",
    label: "Arab Open University",
    website: "http://www.aou.org.bh/",
  },
  {
    value: "507",
    label: "Bahrain Polytechnic",
    website: "http://www.polytechnic.bh/",
  },
  {
    value: "508",
    label: "Gulf University College",
    website: "http://www.gulfuniversity.net/",
  },
  {
    value: "509",
    label: "New York Instiute of Technology",
    website: "http://www.nyit.edu.bh/",
  },
  {
    value: "510",
    label: "RCSI-Medical University of Bahrain",
    website: "http://www.rcsi-mub.com/",
  },
  {
    value: "511",
    label: "The Kingdom University",
    website: "http://www.ku.edu.bh/",
  },
  {
    value: "512",
    label: "University College Bahrain",
    website: "http://www.ucb.edu.bh/",
  },
  {
    value: "513",
    label: "University of Bahrain",
    website: "http://www.uob.edu.bh/",
  },
  {
    value: "514",
    label: "Hope Africa University",
    website: "http://hopeafricauniversity.org/",
  },
  {
    value: "515",
    label: "Université du Burundi",
    website: "http://www.ub.edu.bi/",
  },
  {
    value: "516",
    label: "Université Lumière de Bujumbura",
    website: "http://www.ulbu.bi/",
  },
  {
    value: "517",
    label: "Espam Formation University",
    website: "http://www.espam-formationuc.org/",
  },
  {
    value: "518",
    label: "Houdegbe North American University Benin",
    website: "http://www.hnaubenin.org/",
  },
  {
    value: "519",
    label: "Université d'Abomey-Calavi (UAC)",
    website: "http://www.uac.bj/",
  },
  {
    value: "520",
    label: "Bermuda College",
    website: "http://www.bercol.bm/",
  },
  {
    value: "521",
    label: "Institut Teknologi Brunei",
    website: "http://www.itb.edu.bn/",
  },
  {
    value: "522",
    label: "Universiti Islam Sultan Sharif Ali",
    website: "http://www.unissa.edu.bn/",
  },
  {
    value: "523",
    label: "University of Brunei Darussalam",
    website: "http://www.ubd.edu.bn/",
  },
  {
    value: "524",
    label: "Escuela Militar de Ingeniería",
    website: "http://www.emi.edu.bo/",
  },
  {
    value: "525",
    label: "Universidad Adventista de Bolivia",
    website: "http://www.uab.edu.bo/",
  },
  {
    value: "526",
    label: "Universidad Amazonica de Pando",
    website: "http://www.uapnet.edu.bo/",
  },
  {
    value: "527",
    label: "Universidad Andina Simón Bolivar",
    website: "http://www.uasb.edu.bo/",
  },
  {
    value: "528",
    label: 'Universidad Autónoma del Beni "José Ballivián"',
    website: "http://www.uabjb.edu.bo/",
  },
  {
    value: "529",
    label: "Universidad Autónoma Gabriel René Moreno",
    website: "http://www.uagrm.edu.bo/",
  },
  {
    value: "530",
    label: "Universidad Autónoma Juan Misael Saracho",
    website: "http://www.uajms.edu.bo/",
  },
  {
    value: "531",
    label: "Universidad Autónoma Tomás Frías",
    website: "http://www.uatf.edu.bo/",
  },
  {
    value: "532",
    label: "Universidad Católica Boliviana, Cochabamba",
    website: "http://www.ucbcba.edu.bo/",
  },
  {
    value: "533",
    label: "Universidad Católica Boliviana, La Paz",
    website: "http://www.ucb.edu.bo/",
  },
  {
    value: "534",
    label: "Universidad Católica Boliviana, Santa Cruz",
    website: "http://www.ucbscz.edu.bo/",
  },
  {
    value: "535",
    label: "Universidad Católica Boliviana, Tarija",
    website: "http://www.ucbtja.edu.bo/",
  },
  {
    value: "536",
    label: "Universidad Central",
    website: "http://www.unicen.edu.bo/",
  },
  {
    value: "537",
    label: "Universidad de Aquino Bolivia",
    website: "http://www.udabol.edu.bo/",
  },
  {
    value: "538",
    label: "Universidad Domingo Savio",
    website: "http://www.upds.edu.bo/",
  },
  {
    value: "539",
    label: "Universidad Empresarial Mateo Kuljis",
    website: "http://www.unikuljis.edu.bo/",
  },
  {
    value: "540",
    label: "Universidad Juan Misael Saracho",
    website: "http://www.uajms.edu.bo/",
  },
  {
    value: "541",
    label: "Universidad Los Andes",
    website: "http://www.udelosandes.edu.bo/",
  },
  {
    value: "542",
    label: "Universidad Mayor de San Andrés",
    website: "http://www.umsanet.edu.bo/",
  },
  {
    value: "543",
    label: "Universidad Mayor de San Simón",
    website: "http://www.umss.edu.bo/",
  },
  {
    value: "544",
    label: "Universidad Nacional Siglo XX Llallagua",
    website: "http://www.unsxx.edu.bo/",
  },
  {
    value: "545",
    label: "Universidad Nuestra Senora de La Paz",
    website: "http://www.unslp.edu.bo/",
  },
  {
    value: "546",
    label: "Universidad NUR",
    website: "http://www.nur.edu/",
  },
  {
    value: "547",
    label: "Universidad Privada Abierta Latinoamericana",
    website: "http://www.upal.edu/",
  },
  {
    value: "548",
    label: "Universidad Privada Boliviana",
    website: "http://www.upb.edu/",
  },
  {
    value: "549",
    label: "Universidad Privada del Valle",
    website: "http://www.univalle.edu/",
  },
  {
    value: "550",
    label: "Universidad Privada de Santa Cruz de la Sierra",
    website: "http://www.upsa.edu.bo/",
  },
  {
    value: "551",
    label: "Universidad Privada Franz Tamayo (UNIFRANZ)",
    website: "http://www.unifranz.edu.bo/",
  },
  {
    value: "552",
    label: "Universidad Salesiana de Bolivia",
    website: "http://www.usalesiana.edu.bo/",
  },
  {
    value: "553",
    label: "Universidad San Francisco Xavier",
    website: "http://www.usfx.info/",
  },
  {
    value: "554",
    label: "Universidad San Francisco Xavier de Chuquisaca",
    website: "http://www.usfx.edu.bo/",
  },
  {
    value: "555",
    label: "Universidad Técnica de Oruro",
    website: "http://www.uto.edu.bo/",
  },
  {
    value: "556",
    label: "Centro Regional Universitário de Espiríto Santo do Pinhal",
    website: "http://www.creupi.br/",
  },
  {
    value: "557",
    label: "Centro Universitário Adventista de São Paulo",
    website: "http://www.unasp.edu.br/",
  },
  {
    value: "558",
    label: "Centro Universitário Barao de Maua",
    website: "http://www.baraodemaua.br/",
  },
  {
    value: "559",
    label: "Centro Universitário Claretiano",
    website: "http://www.claretiano.edu.br/",
  },
  {
    value: "560",
    label: "Centro Universitário de Araraquara",
    website: "http://www.uniara.com.br/",
  },
  {
    value: "561",
    label: "Centro Universitário de João Pessoa",
    website: "http://www.unipe.br/",
  },
  {
    value: "562",
    label: "Centro Universitário Monte Serrat",
    website: "http://www.unimonte.br/",
  },
  {
    value: "563",
    label: "Centro Universitário Newton Paiva",
    website: "http://www.newtonpaiva.br/",
  },
  {
    value: "564",
    label: "Centro Universitário Plinio Leite",
    website: "http://www.unipli.com.br/",
  },
  {
    value: "565",
    label: "Centro Universitário Senac",
    website: "http://www.sp.senac.br/",
  },
  {
    value: "566",
    label: "Centro Universitário Serra dos Órgãos",
    website: "http://www.unifeso.edu.br/",
  },
  {
    value: "567",
    label:
      "EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória",
    website: "http://www.emescam.br/",
  },
  {
    value: "568",
    label: "FAAP - Fundação Armando Alvares Penteado",
    website: "http://www.faap.br/",
  },
  {
    value: "569",
    label: "Faculdade Integradas do Ceará",
    website: "http://www.fic.br/",
  },
  {
    value: "570",
    label: "Faculdade Italo Brasileira",
    website: "http://www.italo.br/",
  },
  {
    value: "571",
    label: "Faculdade Jaguariúna",
    website: "http://www.faj.br/",
  },
  {
    value: "572",
    label: "Faculdades Integradas Curitiba",
    website: "http://www.faculdadescuritiba.br/",
  },
  {
    value: "573",
    label: "Faculdades Integradas do Brasil - UNIBRASIL",
    website: "http://www.unibrasil.com.br/",
  },
  {
    value: "574",
    label: "Faculdades Integradas Toledo",
    website: "http://www.toledo.br/",
  },
  {
    value: "575",
    label: "Faculdades Integradas UPIS",
    website: "http://www.upis.br/",
  },
  {
    value: "576",
    label: "FAE Business School - Faculdade de Administração e Economia",
    website: "http://www.fae.edu/",
  },
  {
    value: "577",
    label: "Fundação Educacional de Ituverava",
    website: "http://www.feituverava.com.br/",
  },
  {
    value: "578",
    label: "Instituto Mauá de Tecnologia",
    website: "http://www.maua.br/",
  },
  {
    value: "579",
    label: "Instituto Nacional de Matemática Pura e Aplicada - IMPA",
    website: "http://www.impa.br/",
  },
  {
    value: "580",
    label: "Instituto Tecnológico de Aeronáutica",
    website: "http://www.ita.br/",
  },
  {
    value: "581",
    label: "Pontifícia Universidade Católica de Campinas",
    website: "http://www.puccamp.br/",
  },
  {
    value: "582",
    label: "Pontifícia Universidade Católica de Minas Gerais",
    website: "http://www.pucminas.br/",
  },
  {
    value: "583",
    label: "Pontifícia Universidade Católica de São Paulo",
    website: "http://www.pucsp.br/",
  },
  {
    value: "584",
    label: "Pontificia Universidade Católica do Paraná",
    website: "http://www.pucpr.br/",
  },
  {
    value: "585",
    label: "Pontifícia Universidade Católica do Rio de Janeiro",
    website: "http://www.puc-rio.br/",
  },
  {
    value: "586",
    label: "Pontifícia Universidade Católica do Rio Grande do Sul",
    website: "http://www.pucrs.br/",
  },
  {
    value: "587",
    label: "Universidade Bandeirante de São Paulo",
    website: "http://www.uniban.br/",
  },
  {
    value: "588",
    label: "Universidade Braz Cubas",
    website: "http://www.brazcubas.br/",
  },
  {
    value: "589",
    label: "Universidade Camilo Castelo Branco",
    website: "http://www.unicastelo.br/",
  },
  {
    value: "590",
    label: "Universidade Candido Mendes",
    website: "http://www.candidomendes.br/",
  },
  {
    value: "591",
    label: "Universidade Castelo Branco",
    website: "http://www.castelobranco.br/",
  },
  {
    value: "592",
    label: "Universidade Católica de Brasília",
    website: "http://www.ucb.br/",
  },
  {
    value: "593",
    label: "Universidade Católica de Goiás",
    website: "http://www.ucg.br/",
  },
  {
    value: "594",
    label: "Universidade Católica de Pelotas",
    website: "http://www.ucpel.tche.br/",
  },
  {
    value: "595",
    label: "Universidade Católica de Pernambuco",
    website: "http://www.unicap.br/",
  },
  {
    value: "596",
    label: "Universidade Católica de Petrópolis",
    website: "http://www.ucp.br/",
  },
  {
    value: "597",
    label: "Universidade Católica de Salvador",
    website: "http://www.ucsal.br/",
  },
  {
    value: "598",
    label: "Universidade Católica de Santos",
    website: "http://www.unisantos.com.br/",
  },
  {
    value: "599",
    label: "Universidade Católica Dom Bosco",
    website: "http://www.unibosco.br/",
  },
  {
    value: "600",
    label: "Universidade Cidade de São Paulo",
    website: "http://www.unicid.br/",
  },
  {
    value: "601",
    label: "Universidade Cruzeiro do Sul",
    website: "http://www.unicsul.br/",
  },
  {
    value: "602",
    label: "Universidade da Amazônia",
    website: "http://www.unama.br/",
  },
  {
    value: "603",
    label: "Universidade da Região da Campanha",
    website: "http://www.urcamp.tche.br/",
  },
  {
    value: "604",
    label: "Universidade de Alfenas",
    website: "http://www.unifenas.br/",
  },
  {
    value: "605",
    label: "Universidade de Brasília",
    website: "http://www.unb.br/",
  },
  {
    value: "606",
    label: "Universidade de Caxias do Sul",
    website: "http://www.ucs.tche.br/",
  },
  {
    value: "607",
    label: "Universidade de Cruz Alta",
    website: "http://www.unicruz.tche.br/",
  },
  {
    value: "608",
    label: "Universidade de Fortaleza",
    website: "http://www.unifor.br/",
  },
  {
    value: "609",
    label: "Universidade de Franca",
    website: "http://www.unifran.br/",
  },
  {
    value: "610",
    label: "Universidade de Guarulhos",
    website: "http://www.ung.br/",
  },
  {
    value: "611",
    label: "Universidade de Itaúna",
    website: "http://web.prover.com.br/fui/",
  },
  {
    value: "612",
    label: "Universidade de Marília",
    website: "http://www.unimar.br/",
  },
  {
    value: "613",
    label: "Universidade de Mogi das Cruzes",
    website: "http://www.umc.br/",
  },
  {
    value: "614",
    label: "Universidade de Nova Iguaçu",
    website: "http://www.unig.br/",
  },
  {
    value: "615",
    label: "Universidade de Passo Fundo",
    website: "http://www.upf.tche.br/",
  },
  {
    value: "616",
    label: "Universidade de Pernambuco",
    website: "http://www.upe.br/",
  },
  {
    value: "617",
    label: "Universidade de Ribeirão Preto",
    website: "http://www.unaerp.br/",
  },
  {
    value: "618",
    label: "Universidade de Santa Cruz do Sul",
    website: "http://www.unisc.br/",
  },
  {
    value: "619",
    label: "Universidade de Santo Amaro",
    website: "http://www.unisa.br/",
  },
  {
    value: "620",
    label: "Universidade de São Paulo",
    website: "http://www.usp.br/",
  },
  {
    value: "621",
    label: "Universidade de Taubaté",
    website: "http://www.unitau.br/",
  },
  {
    value: "622",
    label: "Universidade de Tocantins",
    website: "http://www.pop-to.rnp.br/",
  },
  {
    value: "623",
    label: "Universidade de Uberaba",
    website: "http://www.uniube.br/",
  },
  {
    value: "624",
    label: "Universidade de Vila Velha",
    website: "http://www.uvv.br/",
  },
  {
    value: "625",
    label: "Universidade do Amazonas",
    website: "http://www.fua.br/",
  },
  {
    value: "626",
    label: "Universidade do Estado da Bahia",
    website: "http://www.uneb.br/",
  },
  {
    value: "627",
    label: "Universidade do Estado de Santa Catarina",
    website: "http://www.udesc.br/",
  },
  {
    value: "628",
    label: "Universidade do Estado do Rio de Janeiro",
    website: "http://www.uerj.br/",
  },
  {
    value: "629",
    label: "Universidade do Estado do Rio Grande do Norte",
    website: "http://www.uern.br/",
  },
  {
    value: "630",
    label: "Universidade do Extremo Sul Catarinense",
    website: "http://www.unesc.rct-sc.br/",
  },
  {
    value: "631",
    label: "Universidade do Grande Rio",
    website: "http://www.unigranrio.br/",
  },
  {
    value: "632",
    label: "Universidade do Oeste de Santa Catarina",
    website: "http://www.unoesc.edu.br/",
  },
  {
    value: "633",
    label: "Universidade do Oeste Paulista",
    website: "http://www.unoeste.br/",
  },
  {
    value: "634",
    label: "Universidade do Rio de Janeiro",
    website: "http://www.unirio.br/",
  },
  {
    value: "635",
    label: "Universidade do Rio Grande",
    website: "http://www.furg.br/",
  },
  {
    value: "636",
    label: "Universidade do Sagrado Coração",
    website: "http://www.usc.br/",
  },
  {
    value: "637",
    label: "Universidade do Sul de Santa Catarina",
    website: "http://www.unisul.br/",
  },
  {
    value: "638",
    label: "Universidade do Vale do Itajaí",
    website: "http://www.univali.rct-sc.br/",
  },
  {
    value: "639",
    label: "Universidade Estácio de Sá",
    website: "http://www.estacio.br/",
  },
  {
    value: "640",
    label: "Universidade Estadual da Paraíba",
    website: "http://www.uepb.edu.br/",
  },
  {
    value: "641",
    label: "Universidade Estadual de Campinas",
    website: "http://www.unicamp.br/",
  },
  {
    value: "642",
    label: "Universidade Estadual de Feira de Santana",
    website: "http://www.uefs.br/",
  },
  {
    value: "643",
    label: "Universidade Estadual de Londrina",
    website: "http://www.uel.br/",
  },
  {
    value: "644",
    label: "Universidade Estadual de Maringá",
    website: "http://www.uem.br/",
  },
  {
    value: "645",
    label: "Universidade Estadual de Montes Claros",
    website: "http://www.unimontes.br/",
  },
  {
    value: "646",
    label: "Universidade Estadual de Ponta Grossa",
    website: "http://www.uepg.br/",
  },
  {
    value: "647",
    label: "Universidade Estadual de Santa Cruz",
    website: "http://www.uesc.br/",
  },
  {
    value: "648",
    label: "Universidade Estadual do Ceará",
    website: "http://www.uece.br/",
  },
  {
    value: "649",
    label: "Universidade Estadual do Centro-Oeste",
    website: "http://www.unicentro.br/",
  },
  {
    value: "650",
    label: "Universidade Estadual do Maranhão",
    website: "http://www.uema.br/",
  },
  {
    value: "651",
    label: "Universidade Estadual do Norte Fluminense",
    website: "http://www.uenf.br/",
  },
  {
    value: "652",
    label: "Universidade Estadual do Oeste do Paraná",
    website: "http://www.unioeste.br/",
  },
  {
    value: "653",
    label: "Universidade Estadual do Piauí",
    website: "http://www.uespi.br/",
  },
  {
    value: "654",
    label: "Universidade Estadual do Vale do Acaraú",
    website: "http://www.uvanet.br/",
  },
  {
    value: "655",
    label: "Universidade Estadual Paulista",
    website: "http://www.unesp.br/",
  },
  {
    value: "656",
    label: "Universidade Estadual Sudoeste da Bahia",
    website: "http://www.uesb.br/",
  },
  {
    value: "657",
    label: "Universidade Federal da Bahia",
    website: "http://www.ufba.br/",
  },
  {
    value: "658",
    label: "Universidade Federal da Grande Dourados",
    website: "http://www.ufgd.edu.br/",
  },
  {
    value: "659",
    label: "Universidade Federal da Paraíba",
    website: "http://www.ufpb.br/",
  },
  {
    value: "660",
    label: "Universidade Federal de Alagoas",
    website: "http://www.ufal.br/",
  },
  {
    value: "661",
    label: "Universidade Federal de Campina Grande",
    website: "http://www.ufcg.edu.br/",
  },
  {
    value: "662",
    label: "Universidade Federal de Goiás",
    website: "http://www.ufg.br/",
  },
  {
    value: "663",
    label: "Universidade Federal de Juiz de Fora",
    website: "http://www.ufjf.br/",
  },
  {
    value: "664",
    label: "Universidade Federal de Lavras",
    website: "http://www.ufla.br/",
  },
  {
    value: "665",
    label: "Universidade Federal de Mato Grosso",
    website: "http://www.ufmt.br/",
  },
  {
    value: "666",
    label: "Universidade Federal de Mato Grosso do Sul",
    website: "http://www.ufms.br/",
  },
  {
    value: "667",
    label: "Universidade Federal de Minas Gerais",
    website: "http://www.ufmg.br/",
  },
  {
    value: "668",
    label: "Universidade Federal de Ouro Preto",
    website: "http://www.ufop.br/",
  },
  {
    value: "669",
    label: "Universidade Federal de Pelotas",
    website: "http://www.ufpel.tche.br/",
  },
  {
    value: "670",
    label: "Universidade Federal de Pernambuco",
    website: "http://www.ufpe.br/",
  },
  {
    value: "671",
    label: "Universidade Federal de Rondônia",
    website: "http://www.unir.br/",
  },
  {
    value: "672",
    label: "Universidade Federal de Roraima",
    website: "http://www.technet.com.br/vestibular/",
  },
  {
    value: "673",
    label: "Universidade Federal de Santa Catarina",
    website: "http://www.ufsc.br/",
  },
  {
    value: "674",
    label: "Universidade Federal de Santa Maria",
    website: "http://www.ufsm.br/",
  },
  {
    value: "675",
    label: "Universidade Federal de São Carlos",
    website: "http://www.ufscar.br/",
  },
  {
    value: "676",
    label: "Universidade Federal de São Paulo",
    website: "http://www.epm.br/",
  },
  {
    value: "677",
    label: "Universidade Federal de Sergipe",
    website: "http://www.ufs.br/",
  },
  {
    value: "678",
    label: "Universidade Federal de Uberlândia",
    website: "http://www.ufu.br/",
  },
  {
    value: "679",
    label: "Universidade Federal de Viçosa",
    website: "http://www.ufv.br/",
  },
  {
    value: "680",
    label: "Universidade Federal do ABC",
    website: "http://www.ufabc.edu.br/",
  },
  {
    value: "681",
    label: "Universidade Federal do Acre",
    website: "http://www.ufac.br/",
  },
  {
    value: "682",
    label: "Universidade Federal do Amapá",
    website: "http://www.unifap.br/",
  },
  {
    value: "683",
    label: "Universidade Federal do Ceará",
    website: "http://www.ufc.br/",
  },
  {
    value: "684",
    label: "Universidade Federal do Espírito Santo",
    website: "http://www.ufes.br/",
  },
  {
    value: "685",
    label: "Universidade Federal do Maranhão",
    website: "http://www.ufma.br/",
  },
  {
    value: "686",
    label: "Universidade Federal do Pará",
    website: "http://www.ufpa.br/",
  },
  {
    value: "687",
    label: "Universidade Federal do Paraná",
    website: "http://www.ufpr.br/",
  },
  {
    value: "688",
    label: "Universidade Federal do Piauí",
    website: "http://www.ufpi.br/",
  },
  {
    value: "689",
    label: "Universidade Federal do Rio de Janeiro",
    website: "http://www.ufrj.br/",
  },
  {
    value: "690",
    label: "Universidade Federal do Rio Grande do Norte",
    website: "http://www.ufrn.br/",
  },
  {
    value: "691",
    label: "Universidade Federal do Rio Grande do Sul",
    website: "http://www.ufrgs.br/",
  },
  {
    value: "692",
    label: "Universidade Federal do Vale do São Francisco",
    website: "http://www.univasf.edu.br/",
  },
  {
    value: "693",
    label: "Universidade Federal Fluminense",
    website: "http://www.uff.br/",
  },
  {
    value: "694",
    label: "Universidade Federal Rural de Pernambuco",
    website: "http://www.ufrpe.br/",
  },
  {
    value: "695",
    label: "Universidade Federal Rural do Rio de Janeiro",
    website: "http://www.ufrrj.br/",
  },
  {
    value: "696",
    label: "Universidade Federal Rural do Semi-Árido",
    website: "http://www.ufersa.edu.br/",
  },
  {
    value: "697",
    label: "Universidade Gama Filho",
    website: "http://www.ugf.br/",
  },
  {
    value: "698",
    label: "Universidade Ibirapuera",
    website: "http://www.unib.br/",
  },
  {
    value: "699",
    label: "Universidade Luterana do Brasil",
    website: "http://www.ulbra.br/",
  },
  {
    value: "700",
    label: "Universidade Mackenzie",
    website: "http://www.mackenzie.br/",
  },
  {
    value: "701",
    label: "Universidade Metodista de Piracicaba",
    website: "http://www.unimep.br/",
  },
  {
    value: "702",
    label: "Universidade Metropolitana de Santos",
    website: "http://www.unimes.com.br/",
  },
  {
    value: "703",
    label: "Universidade para o Desenvolvimento do Alto Vale do Itajaí",
    website: "http://www.unidavi.rct-sc.br/",
  },
  {
    value: "704",
    label: "Universidade Paulista",
    website: "http://www.unip-objetivo.br/",
  },
  {
    value: "705",
    label: "Universidade Potiguar",
    website: "http://www.unp.br/",
  },
  {
    value: "706",
    label: "Universidade Regional de Blumenau",
    website: "http://www.furb.rct-sc.br/",
  },
  {
    value: "707",
    label: "Universidade Regional do Cariri",
    website: "http://www.urca.br/",
  },
  {
    value: "708",
    label: "Universidade Regional do Noroeste do Estado do Rio Grande do Sul",
    website: "http://www.unijui.tche.br/",
  },
  {
    value: "709",
    label: "Universidade Regional Integrada do Alto Uruguai e das Missões",
    website: "http://www.uri.br/",
  },
  {
    value: "710",
    label: "Universidade Salgado de Oliveira",
    website: "http://www.universo.br/",
  },
  {
    value: "711",
    label: "Universidade Salvador",
    website: "http://www.unifacs.br/",
  },
  {
    value: "712",
    label: "Universidade Santa Cecília dos Bandeirantes",
    website: "http://www.stcecilia.br/",
  },
  {
    value: "713",
    label: "Universidade Santa Úrsula",
    website: "http://www.usu.br/",
  },
  {
    value: "714",
    label: "Universidade São Francisco",
    website: "http://www.usf.br/",
  },
  {
    value: "715",
    label: "Universidade São Judas Tadeu",
    website: "http://www.saojudas.br/",
  },
  {
    value: "716",
    label: "Universidade São Marcos",
    website: "http://www.smarcos.br/",
  },
  {
    value: "717",
    label: "Universidade Tiradentes",
    website: "http://www.unit.br/",
  },
  {
    value: "718",
    label: "Universidade Vale do Paraiba",
    website: "http://www.univap.br/",
  },
  {
    value: "719",
    label: "Universidade Vale do Rio Doce",
    website: "http://www.univale.br/",
  },
  {
    value: "720",
    label: "Universidade Vale do Rio dos Sinos",
    website: "http://www.unisinos.br/",
  },
  {
    value: "721",
    label: "Universidade Veiga de Almeida",
    website: "http://www.uva.br/",
  },
  {
    value: "722",
    label: "The College of The Bahamas",
    website: "http://www.cob.edu.bs/",
  },
  {
    value: "723",
    label: "Royal University of Bhutan",
    website: "http://www.rub.edu.bt/",
  },
  {
    value: "724",
    label: "ABM University College",
    website: "http://www.abm.ac.bw/",
  },
  {
    value: "725",
    label: "Botho University",
    website: "http://www.bothocollege.ac.bw/",
  },
  {
    value: "726",
    label: "Botswana Accountancy College",
    website: "http://www.bac.ac.bw/",
  },
  {
    value: "727",
    label: "Botswana College of Agriculture",
    website: "http://www.bca.bw/",
  },
  {
    value: "728",
    label: "Botswana International University of Science & Technology",
    website: "http://www.biust.ac.bw/",
  },
  {
    value: "729",
    label: "Gaborone Universal College of Law",
    website: "http://www.guc.co.bw/",
  },
  {
    value: "730",
    label: "New Era College of Arts, Science & Technology",
    website: "http://www.neweracollege.ac.bw/",
  },
  {
    value: "731",
    label: "University of Botswana",
    website: "http://www.ub.bw/",
  },
  {
    value: "732",
    label: "Academy of Public Administration of Belarus",
    website: "http://www.pac.by/",
  },
  {
    value: "733",
    label:
      "Academy of the Ministry of Internal Affairs of the Republic of Belarus",
    website: "http://academy.mia.by/",
  },
  {
    value: "734",
    label: "Belarusian-Russian University",
    website: "http://www.bru.mogilev.by/",
  },
  {
    value: "735",
    label: "Belarussian National Technical University",
    website: "http://www.bntu.by/",
  },
  {
    value: "736",
    label: "Belarussian State Academy of Music",
    website: "http://www.bgam.edu.by/",
  },
  {
    value: "737",
    label: "Belarussian State Agrarian Technical University",
    website: "http://www.batu.edu.by/",
  },
  {
    value: "738",
    label: "Belarussian State Agricultural Academy",
    website: "http://www.baa.by/",
  },
  {
    value: "739",
    label: "Belarussian State Economic University",
    website: "http://www.bseu.by/",
  },
  {
    value: "740",
    label: "Belarussian State Medical University",
    website: "http://www.bsmu.by/",
  },
  {
    value: "741",
    label: "Belarussian State Pedagogical University M. Tanka",
    website: "http://www.bspu.unibel.by/",
  },
  {
    value: "742",
    label: "Belarussian State Polytechnical Academy",
    website: "http://www.bspa.unibel.by/",
  },
  {
    value: "743",
    label: "Belarussian State Technological University",
    website: "http://www.bstu.unibel.by/",
  },
  {
    value: "744",
    label: "Belarussian State University",
    website: "http://www.bsu.by/",
  },
  {
    value: "745",
    label: "Belarussian State University of Culture and Arts",
    website: "http://www.buk.by/",
  },
  {
    value: "746",
    label: "Belarussian State University of Informatics and Radioelectronics",
    website: "http://www.bsuir.by/",
  },
  {
    value: "747",
    label: "Belarussian State University of Transport",
    website: "http://www.belsut.gomel.by/",
  },
  {
    value: "748",
    label: "Brest State Technical University",
    website: "http://www.bstu.by/",
  },
  {
    value: "749",
    label: "Brest State University",
    website: "http://www.brsu.brest.by/",
  },
  {
    value: "750",
    label: "European Humanities University",
    website: "http://www.data.minsk.by/ehu/",
  },
  {
    value: "751",
    label: "Gomel State Medical University",
    website: "http://www.meduniver.gomel.by/",
  },
  {
    value: "752",
    label: "Gomel State Technical University Pavel Sukhoi",
    website: "http://www.gstu.gomel.by/",
  },
  {
    value: "753",
    label: "Gomel State University Francisk Scarnia",
    website: "http://www.gsu.unibel.by/",
  },
  {
    value: "754",
    label: "Grodno State Agrarian University",
    website: "http://www.uni-agro.grodno.by/",
  },
  {
    value: "755",
    label: "Grodno State Medical University",
    website: "http://www.grsmu.by/",
  },
  {
    value: "756",
    label: "Grodno State University Yanka Kupaly",
    website: "http://www.grsu.by/",
  },
  {
    value: "757",
    label:
      "Institute for Command Engineers of The Ministry for Emergency Situations",
    website: "http://www.kii.gov.by/",
  },
  {
    value: "758",
    label: "International Sakharov Environmental University",
    website: "http://www.iseu.by/",
  },
  {
    value: "759",
    label: "Minsk Institute of Management",
    website: "http://www.miu.by/",
  },
  {
    value: "760",
    label: "Minsk State Linguistic University",
    website: "http://www.mslu.by/",
  },
  {
    value: "761",
    label: "Mogilev State University A.A. Kuleshov",
    website: "http://msu.mogilev.by/",
  },
  {
    value: "762",
    label: "Mozyr State Pedagogical Institute",
    website: "http://www.mgpu.gomel.by/",
  },
  {
    value: "763",
    label: "Polotsk State University",
    website: "http://www.psu.by/",
  },
  {
    value: "764",
    label: "Vitebsk State Academy of Veterinary Medicine",
    website: "http://www.vsavm.com/",
  },
  {
    value: "765",
    label: "Vitebsk State Medical University",
    website: "http://www.vgmu.vitebsk.by/",
  },
  {
    value: "766",
    label: "Vitebsk State Technological University",
    website: "http://www.vstu.vitebsk.by/",
  },
  {
    value: "767",
    label: "Vitebsk State University",
    website: "http://www.vsu.by/",
  },
  {
    value: "768",
    label: "American University of the Caribbean, School of Medicine",
    website: "http://www.aucmed.edu/",
  },
  {
    value: "769",
    label:
      "Central America Health Sciences University (Belize Medical College)",
    website: "http://www.cahsu.edu/",
  },
  {
    value: "770",
    label: "Greenford International University",
    website: "http://www.giuedu.bz/",
  },
  {
    value: "771",
    label: "University of Belize",
    website: "http://www.ub.edu.bz/",
  },
  {
    value: "772",
    label: "West Coast University (WCU) ",
    website: "http://www.westcoastuniversity.bz/",
  },
  {
    value: "773",
    label: "Acadia University",
    website: "http://www.acadiau.ca/",
  },
  {
    value: "774",
    label: "Algonquin College",
    website: "http://www.algonquincollege.com/",
  },
  {
    value: "775",
    label: "Ashton College",
    website: "http://www.ashtoncollege.com/",
  },
  {
    value: "776",
    label: "Assumption University",
    website: "http://www.assumptionu.ca/",
  },
  {
    value: "777",
    label: "Athabasca University",
    website: "http://www.athabascau.ca/",
  },
  {
    value: "778",
    label: "Augustana University College",
    website: "http://www.augustana.ab.ca/",
  },
  {
    value: "779",
    label: "Bishop's University",
    website: "http://www.ubishops.ca/",
  },
  {
    value: "780",
    label: "Bow Valley College",
    website: "http://www.bowvalleycollege.ca/",
  },
  {
    value: "781",
    label: "Brandon University",
    website: "http://www.brandonu.ca/",
  },
  {
    value: "782",
    label: "Brescia College",
    website: "http://www.uwo.ca/brescia/",
  },
  {
    value: "783",
    label: "British Columbia Institute of Technology",
    website: "http://www.bcit.ca/",
  },
  {
    value: "784",
    label: "British Columbia Open University",
    website: "http://www.bcou.ca/",
  },
  {
    value: "785",
    label: "Brock University",
    website: "http://www.brocku.ca/",
  },
  {
    value: "786",
    label: "Camosun College",
    website: "http://www.camosun.bc.ca/",
  },
  {
    value: "787",
    label: "Campion College",
    website: "http://www.uregina.ca/campion/",
  },
  {
    value: "788",
    label: "Canadian College of Business & Computers",
    website: "http://www.ccbc.ca/",
  },
  {
    value: "789",
    label: "Canadian Mennonite University",
    website: "http://www.cmu.ca/",
  },
  {
    value: "790",
    label: "Capilano College",
    website: "http://www.capcollege.bc.ca/",
  },
  {
    value: "791",
    label: "Carleton University",
    website: "http://www.carleton.ca/",
  },
  {
    value: "792",
    label: "Centennial College",
    website: "http://www.centennialcollege.ca/",
  },
  {
    value: "793",
    label: "Collège Boréal",
    website: "http://www.borealc.on.ca/",
  },
  {
    value: "794",
    label: "College of New Caledonia",
    website: "http://www.cnc.bc.ca/",
  },
  {
    value: "795",
    label: "College of the Rockies",
    website: "http://www.cotr.bc.ca/",
  },
  {
    value: "796",
    label: "Columbia College",
    website: "http://www.columbiacollege.ca/",
  },
  {
    value: "797",
    label: "Concordia University",
    website: "http://www.concordia.ca/",
  },
  {
    value: "798",
    label: "Concordia University College of Alberta",
    website: "http://www.concordia.ab.ca/",
  },
  {
    value: "799",
    label: "Conestoga College",
    website: "http://www.conestogac.on.ca/",
  },
  {
    value: "800",
    label: "Dalhousie University",
    website: "http://www.dal.ca/",
  },
  {
    value: "801",
    label: "DeVry Institute of Technology",
    website: "http://www.cal.devry.ca/",
  },
  {
    value: "802",
    label: "Dominican College of Philosophy and Theology",
    website: "http://www.op.org/canada/college.htm",
  },
  {
    value: "803",
    label: "Douglas College",
    website: "http://www.douglas.bc.ca/",
  },
  {
    value: "804",
    label: "Durham College",
    website: "http://www.durhamc.on.ca/",
  },
  {
    value: "805",
    label: "École des Hautes Études Commerciales",
    website: "http://www.hec.ca/",
  },
  {
    value: "806",
    label: "École de technologie supérieure, Université du Québec",
    website: "http://www.etsmtl.ca/",
  },
  {
    value: "807",
    label: "École nationale d'administration publique, Université du Québec",
    website: "http://www.enap.uquebec.ca/",
  },
  {
    value: "808",
    label: "École Polytechnique de Montréal, Université de Montréal",
    website: "http://www.polymtl.ca/",
  },
  {
    value: "809",
    label: "Emily Carr Institute of Art + Design",
    website: "http://www.eciad.bc.ca/",
  },
  {
    value: "810",
    label: "First Nations University of Canada",
    website: "http://www.firstnationsuniversity.ca/",
  },
  {
    value: "811",
    label: "George Brown College",
    website: "http://www.gbrownc.on.ca/",
  },
  {
    value: "812",
    label: "Humber College",
    website: "http://www.humber.ca/",
  },
  {
    value: "813",
    label: "Huron University College",
    website: "http://www.huronuc.on.ca/",
  },
  {
    value: "814",
    label: "Institut Armand-Frappier, Université du Québec",
    website: "http://www.iaf.inrs.ca/",
  },
  {
    value: "815",
    label:
      "Institut National de la Recherche Scientifique, Université du Québec",
    website: "http://www.inrs.uquebec.ca/",
  },
  {
    value: "816",
    label: "King's College",
    website: "http://www.uwo.ca/kings/",
  },
  {
    value: "817",
    label: "Kingston College",
    website: "http://www.kingston.edu/",
  },
  {
    value: "818",
    label: "King's University College",
    website: "http://www.kingsu.ab.ca/",
  },
  {
    value: "819",
    label: "Kwantleen University College",
    website: "http://www.kwantlen.bc.ca/",
  },
  {
    value: "820",
    label: "Lakehead University",
    website: "http://www.lakeheadu.ca/",
  },
  {
    value: "821",
    label: "Langara College",
    website: "http://www.langara.bc.ca/",
  },
  {
    value: "822",
    label: "Lansbridge University",
    website: "http://www.lansbridge.com/",
  },
  {
    value: "823",
    label: "Laurentian University of Sudbury",
    website: "http://www.laurentian.ca/",
  },
  {
    value: "824",
    label: "Luther College",
    website: "http://www.saskweb.com/luthercollege/",
  },
  {
    value: "825",
    label: "MacEwan University",
    website: "http://www.macewan.ca/",
  },
  {
    value: "826",
    label: "Malaspina University College",
    website: "http://www.mala.bc.ca/",
  },
  {
    value: "827",
    label: "McGill University",
    website: "http://www.mcgill.ca/",
  },
  {
    value: "828",
    label: "McMaster University",
    website: "http://www.mcmaster.ca/",
  },
  {
    value: "829",
    label: "Memorial University of Newfoundland",
    website: "http://www.ucs.mun.ca/",
  },
  {
    value: "830",
    label: "Mount Allison University",
    website: "http://www.mta.ca/",
  },
  {
    value: "831",
    label: "Mount Royal College",
    website: "http://www.mtroyal.ab.ca/",
  },
  {
    value: "832",
    label: "Mount Saint Vincent University",
    website: "http://www.msvu.ca/",
  },
  {
    value: "833",
    label: "Nicola Valley Institute of Technology",
    website: "http://www.nvit.bc.ca/",
  },
  {
    value: "834",
    label: "Nipissing University",
    website: "http://www.nipissingu.ca/",
  },
  {
    value: "835",
    label: "Northern Alberta Institute of Technology",
    website: "http://www.nait.ca/",
  },
  {
    value: "836",
    label: "Northern Lights College",
    website: "http://www.nlc.bc.ca/",
  },
  {
    value: "837",
    label: "North Island College",
    website: "http://www.nic.bc.ca/",
  },
  {
    value: "838",
    label: "Nova Scotia Agricultural College",
    website: "http://www.nsac.ns.ca/",
  },
  {
    value: "839",
    label: "Nova Scotia College of Art and Design",
    website: "http://www.nscad.ns.ca/",
  },
  {
    value: "840",
    label: "Okanagan University College",
    website: "http://www.okanagan.bc.ca/",
  },
  {
    value: "841",
    label: "Ontario College of Art and Design",
    website: "http://www.ocad.ca/",
  },
  {
    value: "842",
    label: "Pacific International College",
    website: "http://www.picollege.ca/",
  },
  {
    value: "843",
    label: "Queen's University",
    website: "http://www.queensu.ca/",
  },
  {
    value: "844",
    label: "Quest University",
    website: "http://www.questu.ca/",
  },
  {
    value: "845",
    label: "Redeemer College",
    website: "http://www.redeemer.ca/",
  },
  {
    value: "846",
    label: "Regent College",
    website: "http://www.regent-college.edu/",
  },
  {
    value: "847",
    label: "Royal Military College of Canada",
    website: "http://www.rmc.ca/",
  },
  {
    value: "848",
    label: "Royal Roads University",
    website: "http://www.royalroads.ca/",
  },
  {
    value: "849",
    label: "Ryerson Polytechnic University",
    website: "http://www.ryerson.ca/",
  },
  {
    value: "850",
    label: "Saskatchewan Indian Federated College",
    website: "http://www.sifc.edu/",
  },
  {
    value: "851",
    label: "Sauder School of Business",
    website: "http://www.sauder.ubc.ca/",
  },
  {
    value: "852",
    label: "Selkirk College",
    website: "http://www.selkirk.bc.ca/",
  },
  {
    value: "853",
    label: "Seneca College",
    website: "http://www.senecac.on.ca/",
  },
  {
    value: "854",
    label: "Sheridan College",
    website: "http://www.sheridanc.on.ca/",
  },
  {
    value: "855",
    label: "Simon Fraser University",
    website: "http://www.sfu.ca/",
  },
  {
    value: "856",
    label: "Southern Alberta Institute of Technology",
    website: "http://www.sait.ca/",
  },
  {
    value: "857",
    label: "St. Anne University",
    website: "http://www.ustanne.ednet.ns.ca/",
  },
  {
    value: "858",
    label: "St. Clair College",
    website: "http://www.stclairc.on.ca/",
  },
  {
    value: "859",
    label: "St. Francis Xavier University",
    website: "http://www.stfx.ca/",
  },
  {
    value: "860",
    label: "St. Mary's University",
    website: "http://www.stmarys.ca/",
  },
  {
    value: "861",
    label: "St. Paul University",
    website: "http://www.ustpaul.ca/",
  },
  {
    value: "862",
    label: "St. Thomas University",
    website: "http://www.stthomasu.ca/",
  },
  {
    value: "863",
    label: "Télé-université, Université du Québec",
    website: "http://www.teluq.uquebec.ca/",
  },
  {
    value: "864",
    label: "Thompson Rivers University",
    website: "http://www.tru.ca/",
  },
  {
    value: "865",
    label: "Trent University",
    website: "http://www.trentu.ca/",
  },
  {
    value: "866",
    label: "Trinity Western University",
    website: "http://www.twu.ca/",
  },
  {
    value: "867",
    label: "Université de Montréal",
    website: "http://www.umontreal.ca/",
  },
  {
    value: "868",
    label: "Université de Sherbrooke",
    website: "http://www.usherb.ca/",
  },
  {
    value: "869",
    label: "Université du Québec à Chicoutimi",
    website: "http://www.uqac.ca/",
  },
  {
    value: "870",
    label: "Université du Québec à Montréal",
    website: "http://www.uqam.ca/",
  },
  {
    value: "871",
    label: "Université du Québec à Rimouski",
    website: "http://www.uqar.uquebec.ca/",
  },
  {
    value: "872",
    label: "Université du Québec à Trois-Rivières",
    website: "http://www.uqtr.uquebec.ca/",
  },
  {
    value: "873",
    label: "Université du Québec en Abitibi-Témiscamingue",
    website: "http://www.uqat.uquebec.ca/",
  },
  {
    value: "874",
    label: "Université du Québec en Outaouais",
    website: "http://www.uqo.ca/",
  },
  {
    value: "875",
    label: "Université Laval",
    website: "http://www.ulaval.ca/",
  },
  {
    value: "876",
    label: "University Canada West",
    website: "http://www.universitycanadawest.ca/",
  },
  {
    value: "877",
    label: "University College of Cape Breton",
    website: "http://www.uccb.ns.ca/",
  },
  {
    value: "878",
    label: "University College of Saint-Boniface",
    website: "http://www.ustboniface.mb.ca/",
  },
  {
    value: "879",
    label: "University College of the Cariboo",
    website: "http://www.cariboo.bc.ca/",
  },
  {
    value: "880",
    label: "University College of the Fraser Valley",
    website: "http://www.ucfv.bc.ca/",
  },
  {
    value: "881",
    label: "University of Alberta",
    website: "http://www.ualberta.ca/",
  },
  {
    value: "882",
    label: "University of British Columbia",
    website: "http://www.ubc.ca/",
  },
  {
    value: "883",
    label: "University of Calgary",
    website: "http://www.ucalgary.ca/",
  },
  {
    value: "884",
    label: "University of Guelph",
    website: "http://www.uoguelph.ca/",
  },
  {
    value: "885",
    label: "University of King's College",
    website: "http://www.ukings.ns.ca/",
  },
  {
    value: "886",
    label: "University of Lethbridge",
    website: "http://www.uleth.ca/",
  },
  {
    value: "887",
    label: "University of Manitoba",
    website: "http://www.umanitoba.ca/",
  },
  {
    value: "888",
    label: "University of Moncton",
    website: "http://www.umoncton.ca/",
  },
  {
    value: "889",
    label: "University of Moncton, Edmundston",
    website: "http://www.cuslm.ca/",
  },
  {
    value: "890",
    label: "University of Moncton, Shippagan",
    website: "http://www.cus.ca/",
  },
  {
    value: "891",
    label: "University of New Brunswick",
    website: "http://www.unb.ca/",
  },
  {
    value: "892",
    label: "University of New Brunswick, Saint John",
    website: "http://www.unbsj.ca/",
  },
  {
    value: "893",
    label: "University of Northern British Columbia",
    website: "http://www.unbc.ca/",
  },
  {
    value: "894",
    label: "University of Ontario Institute of Technology",
    website: "http://www.uoit.ca/",
  },
  {
    value: "895",
    label: "University of Ottawa",
    website: "http://www.uottawa.ca/",
  },
  {
    value: "896",
    label: "University of Prince Edward Island",
    website: "http://www.upei.ca/",
  },
  {
    value: "897",
    label: "University of Québec",
    website: "http://www.uquebec.ca/",
  },
  {
    value: "898",
    label: "University of Regina",
    website: "http://www.uregina.ca/",
  },
  {
    value: "899",
    label: "University of Saskatchewan",
    website: "http://www.usask.ca/",
  },
  {
    value: "900",
    label: "University of St. Jerome's College",
    website: "http://www.usjc.uwaterloo.ca/",
  },
  {
    value: "901",
    label: "University of St. Michael's College",
    website: "http://www.utoronto.ca/stmikes/",
  },
  {
    value: "902",
    label: "University of Sudbury",
    website: "http://www.usudbury.com/",
  },
  {
    value: "903",
    label: "University of Toronto",
    website: "http://www.utoronto.ca/",
  },
  {
    value: "904",
    label: "University of Toronto, Mississauga",
    website: "http://www.utm.utoronto.ca/",
  },
  {
    value: "905",
    label: "University of Toronto, Scarborough",
    website: "http://www.scar.utoronto.ca/",
  },
  {
    value: "906",
    label: "University of Trinity College",
    website: "http://www.trinity.utoronto.ca/",
  },
  {
    value: "907",
    label: "University of Victoria",
    website: "http://www.uvic.ca/",
  },
  {
    value: "908",
    label: "University of Waterloo",
    website: "http://www.uwaterloo.ca/",
  },
  {
    value: "909",
    label: "University of Western Ontario",
    website: "http://www.uwo.ca/",
  },
  {
    value: "910",
    label: "University of Windsor",
    website: "http://www.uwindsor.ca/",
  },
  {
    value: "911",
    label: "University of Winnipeg",
    website: "http://www.uwinnipeg.ca/",
  },
  {
    value: "912",
    label: "Vancouver Community College",
    website: "http://www.vcc.ca/",
  },
  {
    value: "913",
    label: "Victoria University Toronto, University of Toronto",
    website: "http://vicu.utoronto.ca/",
  },
  {
    value: "914",
    label: "Wilfrid Laurier University",
    website: "http://www.wlu.ca/",
  },
  {
    value: "915",
    label: "William and Catherine Booth College",
    website: "http://www.boothcollege.ca/",
  },
  {
    value: "916",
    label: "York University",
    website: "http://www.yorku.ca/",
  },
  {
    value: "917",
    label: "Yukon College",
    website: "http://www.yukoncollege.yk.ca/",
  },
  {
    value: "918",
    label: "Université Catholique de Bukavu",
    website: "http://www.ucbukavu.ac.cd/",
  },
  {
    value: "919",
    label: "Université Chrétienne Bilingue du Congo",
    website: "http://www.ucbc.org/",
  },
  {
    value: "920",
    label: "Université de Bandundu Ville",
    website: "http://www.uniband.org/",
  },
  {
    value: "921",
    label: "Université de Kamina",
    website: "http://www.ukamina.org/",
  },
  {
    value: "922",
    label: "Université de Kikwite",
    website: "http://www.unikik.net/",
  },
  {
    value: "923",
    label: "Université de Kinshasa",
    website: "http://www.unikin.cd/",
  },
  {
    value: "924",
    label: "Université de Kisangani",
    website: "http://www.unikis.ac.cd/",
  },
  {
    value: "925",
    label: "Université de Kolwezi",
    website: "http://www.unikol.ac.cd/",
  },
  {
    value: "926",
    label: "Université de Lubumbashi",
    website: "http://www.unilu.ac.cd/",
  },
  {
    value: "927",
    label: "Université de l'Uélé",
    website: "http://www.uniuele.ac.cd/",
  },
  {
    value: "928",
    label: "Université Kongo",
    website: "http://www.universitekongo.org/",
  },
  {
    value: "929",
    label: "Université Protestante au Congo",
    website: "http://www.upc.ac.cd/",
  },
  {
    value: "930",
    label: "Université Shalom de Bunia",
    website: "http://www.unishabunia.org/",
  },
  {
    value: "931",
    label: "Université de Bangui",
    website: "http://www.univ-bangui.net/",
  },
  {
    value: "932",
    label: "University Marien Ngouabi Brazzaville",
    website: "http://www.univ-mngb.net/",
  },
  {
    value: "933",
    label: "Business and Hotel Management School",
    website: "http://www.bhms.ch/",
  },
  {
    value: "934",
    label: "Business School Lausanne (BSL)",
    website: "http://www.bsl-lausanne.ch/",
  },
  {
    value: "935",
    label: "Cubidor University Switzerland",
    website: "http://www.cubidor.com/",
  },
  {
    value: "936",
    label: "DCT International Hotel & Business Management School",
    website: "http://www.dct.ch/",
  },
  {
    value: "937",
    label: "European Graduate School, Media & Communications",
    website: "http://www.egs.edu/",
  },
  {
    value: "938",
    label: "European University",
    website: "http://www.euruni.edu/",
  },
  {
    value: "939",
    label: "Fachhochschule Pur",
    website: "http://www.phw.info/",
  },
  {
    value: "940",
    label: "Fachhochschule St. Gallen",
    website: "https://www.fhsg.ch/",
  },
  {
    value: "941",
    label: "Franklin College Switzerland",
    website: "http://www.fc.edu/",
  },
  {
    value: "942",
    label: "Geneva Business School",
    website: "http://www.gbs-ge.ch/",
  },
  {
    value: "943",
    label: "Glion Institute of Higher Education",
    website: "http://www.glion.edu/",
  },
  {
    value: "944",
    label: "Graduate School of Business Administration Zurich (GSBA Zurich)",
    website: "http://www.gsba.ch/",
  },
  {
    value: "945",
    label: "Hochschule für Gestaltung und Kunst Zürich",
    website: "http://www.hgkz.ch/",
  },
  {
    value: "946",
    label: "International Culinary Institute (ICI)",
    website: "http://www.ici-luzern.com/",
  },
  {
    value: "947",
    label: "International Hotel Management Institute",
    website: "http://www.imi-luzern.com/",
  },
  {
    value: "948",
    label: "International School of Business Management",
    website: "http://www.isbm-school.com/",
  },
  {
    value: "949",
    label: "International University in Geneva",
    website: "http://www.iun.ch/",
  },
  {
    value: "950",
    label: "Lausanne Hotel School (EHL)",
    website: "http://www.ehl.ch/",
  },
  {
    value: "951",
    label: "Les Roches Gruyère University of Applied Sciences",
    website: "http://www.lrguas.ch/",
  },
  {
    value: "952",
    label: "Les Roches International School of Hotel Management",
    website: "http://www.lesroches.edu/",
  },
  {
    value: "953",
    label: "Libera Università degli Studi di Scienze Umane e Tecnologiche",
    website: "http://www.uniludes.ch/",
  },
  {
    value: "954",
    label: "Lucerne University of Applied Sciences and Arts ",
    website: "http://www.hslu.ch/",
  },
  {
    value: "955",
    label: "NTB Interstate University of Applied Sciences of Technology",
    website: "http://www.ntb.ch/",
  },
  {
    value: "956",
    label: "Pädagogische Hochschule Zürich (Zurich School of Education)",
    website: "http://www.phzh.ch/",
  },
  {
    value: "957",
    label: "Schiller International University, American College of Switzerland",
    website: "http://www.american-college.com/",
  },
  {
    value: "958",
    label: "School of Management Fribourg",
    website: "http://www.heg-fr.ch/",
  },
  {
    value: "959",
    label: "Swiss Business School Zurich (SBS)",
    website: "http://www.sbs.edu/",
  },
  {
    value: "960",
    label: "Swiss Federal Institute of Technology, Lausanne",
    website: "http://www.epfl.ch/",
  },
  {
    value: "961",
    label: "Swiss Federal Institute of Technology, Zurich",
    website: "http://www.ethz.ch/",
  },
  {
    value: "962",
    label: "Swiss Management Center",
    website: "http://www.swissmc.ch/",
  },
  {
    value: "963",
    label: 'University Center "César Ritz"',
    website: "http://www.uccr.edu/",
  },
  {
    value: "964",
    label: "University of Applied Sciences Aargau",
    website: "http://www.fh-aargau.ch/",
  },
  {
    value: "965",
    label: "University of Applied Sciences Basel (FHBB )",
    website: "http://www.fhbb.ch/",
  },
  {
    value: "966",
    label: "University of Applied Sciences Chur",
    website: "http://www.fh-htachur.ch/",
  },
  {
    value: "967",
    label: "University of Applied Sciences Rapperswil",
    website: "http://www.hsr.ch/",
  },
  {
    value: "968",
    label: "University of Applied Sciences Solothurn Northwestern Switzerland",
    website: "http://www.fhso.ch/",
  },
  {
    value: "969",
    label: "University of Basel",
    website: "http://www.unibas.ch/",
  },
  {
    value: "970",
    label: "University of Berne",
    website: "http://www.unibe.ch/",
  },
  {
    value: "971",
    label: "University of Fribourg",
    website: "http://www.unifr.ch/",
  },
  {
    value: "972",
    label: "University of Geneva",
    website: "http://www.unige.ch/",
  },
  {
    value: "973",
    label: "University of Lausanne",
    website: "http://www.unil.ch/",
  },
  {
    value: "974",
    label: "University of Lucerne",
    website: "http://www.unilu.ch/",
  },
  {
    value: "975",
    label: "University of Neuchatel",
    website: "http://www.unine.ch/",
  },
  {
    value: "976",
    label: "University of St. Gallen",
    website: "http://www.unisg.ch/",
  },
  {
    value: "977",
    label: "University of Switzerland",
    website: "http://www.swissu.org/",
  },
  {
    value: "978",
    label: "University of the Italian-speaking Part of Switzerland",
    website: "http://www.unisi.ch/",
  },
  {
    value: "979",
    label: "University of Zürich",
    website: "http://www.unizh.ch/",
  },
  {
    value: "980",
    label: "Victoria University",
    website: "http://www.victoria-uni.ch/",
  },
  {
    value: "981",
    label: "Webster University Geneva",
    website: "http://www.webster.ch/",
  },
  {
    value: "982",
    label: "Zurich University of Applied Sciences Winterthur",
    website: "http://www.zhwin.ch/",
  },
  {
    value: "983",
    label: "Université d'Abobo-Adjamé",
    website: "http://www.uabobo.ci/",
  },
  {
    value: "984",
    label: "Université de Bouaké",
    website: "http://www.ubouake.ci/",
  },
  {
    value: "985",
    label: "Université de Cocody",
    website: "http://www.univ-cocody.ci/",
  },
  {
    value: "986",
    label: "Escuela de Arquitectura y Diseño",
    website: "http://www.arquitecturaucv.cl/",
  },
  {
    value: "987",
    label: "Pontificia Universidad Catolica de Chile",
    website: "http://www.puc.cl/",
  },
  {
    value: "988",
    label: "Universidad Academia de Humanismo Cristiano",
    website: "http://www.academia.cl/",
  },
  {
    value: "989",
    label: "Universidad Academia de Humanismo Cristiano",
    website: "http://www.academia.cl/",
  },
  {
    value: "990",
    label: 'Universidad "Adolfo Ibañez"',
    website: "http://www.uai.cl/",
  },
  {
    value: "991",
    label: "Universidad Adolfo Ibáñez",
    website: "http://www.uai.cl/",
  },
  {
    value: "992",
    label: "Universidad Adventista de Chile",
    website: "http://www.unachile.cl/",
  },
  {
    value: "993",
    label: "Universidad Alberto Hurtado",
    website: "http://www.uahurtado.cl/",
  },
  {
    value: "994",
    label: "Universidad Arcis",
    website: "http://www.universidadarcis.cl/",
  },
  {
    value: "995",
    label: 'Universidad "Arturo Prat"',
    website: "http://www.unap.cl/",
  },
  {
    value: "996",
    label: "Universidad Austral de Chile",
    website: "http://www.uach.cl/",
  },
  {
    value: "997",
    label: "Universidad Autonoma del Sur",
    website: "http://www.uas.cl/",
  },
  {
    value: "998",
    label: "Universidad Bernardo O'Higgins",
    website: "http://www.ubohiggins.cl/",
  },
  {
    value: "999",
    label: "Universidad Bolivariana",
    website: "http://www.ubolivariana.cl/",
  },
  {
    value: "1000",
    label: "Universidad Católica Cardenal Raúl Silva Henríquez",
    website: "http://www.ucsh.cl/",
  },
  {
    value: "1001",
    label: "Universidad Catolica de La Santísima Concepción",
    website: "http://www.ucsc.cl/",
  },
  {
    value: "1002",
    label: "Universidad Catolica del Maule",
    website: "http://www.ucm.cl/",
  },
  {
    value: "1003",
    label: "Universidad Catolica del Norte",
    website: "http://www.ucn.cl/",
  },
  {
    value: "1004",
    label: "Universidad Catolica de Temuco",
    website: "http://www.uctem.cl/",
  },
  {
    value: "1005",
    label: "Universidad Catolica de Valparaiso",
    website: "http://www.ucv.cl/",
  },
  {
    value: "1006",
    label: "Universidad Central",
    website: "http://www.ucentral.cl/",
  },
  {
    value: "1007",
    label: "Universidad Chileno Britanica de Cultura",
    website: "http://www.ubritanica.cl/",
  },
  {
    value: "1008",
    label: "Universidad de Aconcagua",
    website: "http://www.uaconcagua.cl/",
  },
  {
    value: "1009",
    label: "Universidad de Antofagasta",
    website: "http://www.uantof.cl/",
  },
  {
    value: "1010",
    label: "Universidad de Artes, Ciencias y Comunicación",
    website: "http://www.uniacc.cl/",
  },
  {
    value: "1011",
    label: "Universidad de Atacama",
    website: "http://www.uda.cl/",
  },
  {
    value: "1012",
    label: "Universidad de Chile",
    website: "http://www.uchile.cl/",
  },
  {
    value: "1013",
    label: "Universidad de Ciencias de la Informatica",
    website: "http://www.ucinf.cl/",
  },
  {
    value: "1014",
    label: "Universidad de Concepcion",
    website: "http://www.udec.cl/",
  },
  {
    value: "1015",
    label: "Universidad de La Frontera",
    website: "http://www.ufro.cl/",
  },
  {
    value: "1016",
    label: "Universidad de Las Américas",
    website: "http://www.uamericas.cl/",
  },
  {
    value: "1017",
    label: "Universidad de La Serena",
    website: "http://www.userena.cl/",
  },
  {
    value: "1018",
    label: "Universidad del Bío-Bío",
    website: "http://www.ubiobio.cl/",
  },
  {
    value: "1019",
    label: "Universidad del Desarrollo",
    website: "http://www.udesarrollo.cl/",
  },
  {
    value: "1020",
    label: "Universidad del Mar",
    website: "http://www.udelmar.cl/",
  },
  {
    value: "1021",
    label: "Universidad de Los Andes",
    website: "http://www.uandes.cl/",
  },
  {
    value: "1022",
    label: "Universidad de Los Lagos",
    website: "http://www.ulagos.cl/",
  },
  {
    value: "1023",
    label: "Universidad del Pacífico",
    website: "http://www.upacifico.cl/",
  },
  {
    value: "1024",
    label: "Universidad de Magallanes",
    website: "http://www.umag.cl/",
  },
  {
    value: "1025",
    label: "Universidad de Playa Ancha de Ciencias de la Educacion",
    website: "http://www.upa.cl/",
  },
  {
    value: "1026",
    label: "Universidad de San Andres",
    website: "http://www.usanandres.cl/",
  },
  {
    value: "1027",
    label: "Universidad de Santiago de Chile",
    website: "http://www.usach.cl/",
  },
  {
    value: "1028",
    label: "Universidad de Talca",
    website: "http://www.utalca.cl/",
  },
  {
    value: "1029",
    label: "Universidad de Tarapacá",
    website: "http://www.uta.cl/",
  },
  {
    value: "1030",
    label: "Universidad de Valparaiso",
    website: "http://www.uv.cl/",
  },
  {
    value: "1031",
    label: "Universidad de Viña del Mar",
    website: "http://www.uvm.cl/",
  },
  {
    value: "1032",
    label: "Universidad Diego Portales",
    website: "http://www.udp.cl/",
  },
  {
    value: "1033",
    label: "Universidad Finis Terrae",
    website: "http://www.ufinis.cl/",
  },
  {
    value: "1034",
    label: "Universidad Francisco de Aguirre",
    website: "http://www.unfa.cl/",
  },
  {
    value: "1035",
    label: "Universidad Gabriela Mistral",
    website: "http://www.ugm.cl/",
  },
  {
    value: "1036",
    label: "Universidad Iberoamericana de Ciencias y Tecnologia",
    website: "http://www.unicit.cl/",
  },
  {
    value: "1037",
    label: "Universidad International SEK, Santiago",
    website: "http://www.uisek.cl/",
  },
  {
    value: "1038",
    label: "Universidad José Santos Ossa",
    website: "http://www.ujso.cl/",
  },
  {
    value: "1039",
    label: "Universidad La Republica",
    website: "http://www.ulare.cl/",
  },
  {
    value: "1040",
    label: "Universidad Mariano Egaña",
    website: "http://www.ume.cl/",
  },
  {
    value: "1041",
    label: "Universidad Maritima de Chile",
    website: "http://www.umaritima.cl/",
  },
  {
    value: "1042",
    label: "Universidad Mayor",
    website: "http://www.umayor.cl/",
  },
  {
    value: "1043",
    label: "Universidad Metropolitana de Ciencias de la Educación",
    website: "http://www.umce.cl/",
  },
  {
    value: "1044",
    label: "Universidad Miguel de Cervantes",
    website: "http://www.ucervantes.cl/",
  },
  {
    value: "1045",
    label: "Universidad Nacional Andrés Bello",
    website: "http://www.unab.cl/",
  },
  {
    value: "1046",
    label: "Universidad San Sebastian",
    website: "http://www.uss.cl/",
  },
  {
    value: "1047",
    label: "Universidad Santo Tomás",
    website: "http://www.ust.cl/",
  },
  {
    value: "1048",
    label: 'Universidad Técnica "Federico Santa María"',
    website: "http://www.utfsm.cl/",
  },
  {
    value: "1049",
    label: "Universidad Tecnológica Metropolitana",
    website: "http://www.utem.cl/",
  },
  {
    value: "1050",
    label: 'Universidad Tecnologica "Vicente Perez Rosales"',
    website: "http://www.uvipro.cl/",
  },
  {
    value: "1051",
    label: "Bamenda University of Science & Technology",
    website: "http://www.bamendauniversity.com/",
  },
  {
    value: "1052",
    label: "Fomic Polytechnic",
    website: "http://www.fomicgroup.cm/",
  },
  {
    value: "1053",
    label: "Université de Bamenda",
    website: "http://www.unibda.net/",
  },
  {
    value: "1054",
    label: "Université de Buéa",
    website: "http://www.ubuea.cm/",
  },
  {
    value: "1055",
    label: "Université de Douala",
    website: "http://www.univ-douala.com/",
  },
  {
    value: "1056",
    label: "Université de Dschang",
    website: "http://www.univ-dschang.org/",
  },
  {
    value: "1057",
    label: "Université de Ngaoundéré",
    website: "http://www.univ-ndere.cm/",
  },
  {
    value: "1058",
    label: "Université des Montagnes",
    website: "http://www.udesmontagnes.org/",
  },
  {
    value: "1059",
    label: "Université de Yaoundé I",
    website: "http://www.uy1.uninet.cm/",
  },
  {
    value: "1060",
    label: "Université de Yaoundé II",
    website: "http://www.universite-yde2.org/",
  },
  {
    value: "1061",
    label: "2nd Military Medical University",
    website: "http://www.smmu.edu.cn/",
  },
  {
    value: "1062",
    label: "3rd Military Medical University",
    website: "http://www.tmmu.edu.cn/",
  },
  {
    value: "1063",
    label: "4th Military Medical University",
    website: "http://www.fmmu.edu.cn/",
  },
  {
    value: "1064",
    label: "Anhui Medical University",
    website: "http://www.ahmu.edu.cn/",
  },
  {
    value: "1065",
    label: "Anhui Normal University",
    website: "http://www.ahnu.edu.cn/",
  },
  {
    value: "1066",
    label: "Anhui Technical College of Water Resources and Hydroelectric Power",
    website: "http://www.ahsdxy.ah.edu.cn/",
  },
  {
    value: "1067",
    label: "Anhui University",
    website: "http://www.ahu.edu.cn/",
  },
  {
    value: "1068",
    label: "Anhui University of Finance and Economics",
    website: "http://www.aufe.edu.cn/",
  },
  {
    value: "1069",
    label: "Anhui University of Traditional Chinese Medicine",
    website: "http://www.ahtcm.edu.cn/",
  },
  {
    value: "1070",
    label: "Beijing Foreign Studies University",
    website: "http://www.bfsu.edu.cn/",
  },
  {
    value: "1071",
    label: "Beijing Forestry University",
    website: "http://www.bjfu.edu.cn/",
  },
  {
    value: "1072",
    label: "Beijing Information Science and Technology University",
    website: "http://www.biti.edu.cn/",
  },
  {
    value: "1073",
    label: "Beijing Institute of Technology",
    website: "http://www.bit.edu.cn/",
  },
  {
    value: "1074",
    label: "Beijing International Studies University",
    website: "http://www.bisu.edu.cn/",
  },
  {
    value: "1075",
    label: "Beijing Language and Culture University",
    website: "http://www.blcu.edu.cn/",
  },
  {
    value: "1076",
    label: "Beijing Medical University",
    website: "http://www.bjmu.edu.cn/",
  },
  {
    value: "1077",
    label: "Beijing New Asia University",
    website: "http://www.newasia.bj.edu.cn/",
  },
  {
    value: "1078",
    label: "Beijing Normal University",
    website: "http://www.bnu.edu.cn/",
  },
  {
    value: "1079",
    label: "Beijing Petroleum University",
    website: "http://www.bjpeu.edu.cn/",
  },
  {
    value: "1080",
    label: "Beijing Polytechnic University",
    website: "http://www.bjpu.edu.cn/",
  },
  {
    value: "1081",
    label: "Beijing Sport University",
    website: "http://www.bsu.edu.cn/",
  },
  {
    value: "1082",
    label: "Beijing Union University",
    website: "http://www.ipdbuu.com.cn/",
  },
  {
    value: "1083",
    label: "Beijing University of Aeronautics and Astronautics",
    website: "http://www.buaa.edu.cn/",
  },
  {
    value: "1084",
    label: "Beijing University of Agriculture",
    website: "http://www.bac.edu.cn/",
  },
  {
    value: "1085",
    label: "Beijing University of Chemical Technology",
    website: "http://www.buct.edu.cn/",
  },
  {
    value: "1086",
    label: "Beijing University of Chinese Medicine and Pharmacology",
    website: "http://www.bjucmp.edu.cn/",
  },
  {
    value: "1087",
    label: "Beijing University of Posts and Telecommunications",
    website: "http://www.bupt.edu.cn/",
  },
  {
    value: "1088",
    label: "Beijing University of Science and Technology",
    website: "http://www.ustb.edu.cn/",
  },
  {
    value: "1089",
    label: "Bohai University",
    website: "http://www.bhu.edu.cn/",
  },
  {
    value: "1090",
    label: "Buddhist Acamedy of China",
    website: "http://www.zgfxy.cn/",
  },
  {
    value: "1091",
    label: "Capital Normal University",
    website: "http://www.cnu.edu.cn/",
  },
  {
    value: "1092",
    label: "Capital University of Economics and Business",
    website: "http://www.cueb.edu.cn/",
  },
  {
    value: "1093",
    label: "Capital University of Medical Sciences",
    website: "http://www.cpums.edu.cn/",
  },
  {
    value: "1094",
    label: "Central Academy of  Fine Art",
    website: "http://www.cafa.edu.cn/",
  },
  {
    value: "1095",
    label: "Central China Normal University",
    website: "http://www.ccnu.edu.cn/",
  },
  {
    value: "1096",
    label: "Central Radio and TV University",
    website: "http://www.crtvu.edu.cn/",
  },
  {
    value: "1097",
    label: "Central South Forestry University",
    website: "http://www.csfu.edu.cn/",
  },
  {
    value: "1098",
    label: "Central South University",
    website: "http://www.csu.edu.cn/",
  },
  {
    value: "1099",
    label: "Central University for Nationalities",
    website: "http://www.cun.edu.cn/",
  },
  {
    value: "1100",
    label: "Central University of Finance and Economics",
    website: "http://www.cufe.edu.cn/",
  },
  {
    value: "1101",
    label: "Changchun Teachers College",
    website: "http://www.cncnc.edu.cn/",
  },
  {
    value: "1102",
    label: "Changchun University of Technology",
    website: "http://www.ccut.edu.cn/",
  },
  {
    value: "1103",
    label: "Changsha Railway University",
    website: "http://www.csru.edu.cn/",
  },
  {
    value: "1104",
    label: "Changsha University of Electric Power",
    website: "http://www.csuep.edu.cn/",
  },
  {
    value: "1105",
    label: "Chengdu Institute of Sichuan International Studies University",
    website: "http://www.cisisu.edu.cn/",
  },
  {
    value: "1106",
    label: "Chengdu University",
    website: "http://www.cdu.edu.cn/",
  },
  {
    value: "1107",
    label: "Chengdu University of Technology",
    website: "http://www.cdut.edu.cn/",
  },
  {
    value: "1108",
    label: "Chengdu University of Traditional Chinese Medicine",
    website: "http://www.cdutcm.edu.cn/",
  },
  {
    value: "1109",
    label: "China Academy of Art",
    website: "http://www.chinaacademyofart.com/",
  },
  {
    value: "1110",
    label: "China Agricultural University",
    website: "http://www.cau.edu.cn/",
  },
  {
    value: "1111",
    label: "China Agriculture University East",
    website: "http://www.bjaeu.edu.cn/",
  },
  {
    value: "1112",
    label: "China Foreign Affairs University",
    website: "http://www.cfau.edu.cn/",
  },
  {
    value: "1113",
    label: "China Medical University Shenyang",
    website: "http://www.cmu.edu.cn/",
  },
  {
    value: "1114",
    label: "China Pharmaceutical University Nanjing",
    website: "http://www.cpu.edu.cn/",
  },
  {
    value: "1115",
    label: "China Three Gorges University",
    website: "http://www.ctgu.edu.cn/",
  },
  {
    value: "1116",
    label: "China University of Geoscience Beijing",
    website: "http://www.cugb.edu.cn/",
  },
  {
    value: "1117",
    label: "China University of Geosciences Wuhan",
    website: "http://www.cug.edu.cn/",
  },
  {
    value: "1118",
    label: "China University of Mining Technology - Beijing",
    website: "http://www.cumtb.edu.cn/",
  },
  {
    value: "1119",
    label: "China University of Mining Technology - Xuzhou",
    website: "http://www.cumt.edu.cn/",
  },
  {
    value: "1120",
    label: "China University of Political Science and Law",
    website: "http://www.cupl.edu.cn/",
  },
  {
    value: "1121",
    label: "China USA Business University",
    website: "http://www.cubu.edu/",
  },
  {
    value: "1122",
    label: "China youth college for political science",
    website: "http://www.cyc.edu.cn/",
  },
  {
    value: "1123",
    label: "Chinese People's Public Security University",
    website: "http://www.cppsu.edu.cn/",
  },
  {
    value: "1124",
    label: "Chongqing Education College",
    website: "http://www.cqec.net.cn/",
  },
  {
    value: "1125",
    label: "Chongqing Medical University",
    website: "http://www.cqmu.edu.cn/",
  },
  {
    value: "1126",
    label: "Chongqing Normal University",
    website: "http://www.cqnu.edu.cn/",
  },
  {
    value: "1127",
    label: "Chongqing Normal University Foreign Trade and Business College",
    website: "http://www.swsm.cn/",
  },
  {
    value: "1128",
    label: "Chongqing Technology and Business University",
    website: "http://www.ctbu.edu.cn/",
  },
  {
    value: "1129",
    label: "Chongqing Telecommunication College",
    website: "http://www.cqtcedu.com/",
  },
  {
    value: "1130",
    label: "Chongqing Three Gorges University",
    website: "http://www.sanxiau.edu.cn/",
  },
  {
    value: "1131",
    label: "Chongqing University",
    website: "http://www.cqu.edu.cn/",
  },
  {
    value: "1132",
    label: "Chongqing University of Communications",
    website: "http://www.cquc.edu.cn/",
  },
  {
    value: "1133",
    label: "Chongqing University of Post and Telecommunications",
    website: "http://www.cqupt.edu.cn/",
  },
  {
    value: "1134",
    label: "Chongqing University of Science and Technology ",
    website: "http://www.cqust.cn/",
  },
  {
    value: "1135",
    label: "Chongqing University of Technology",
    website: "http://www.cqut.edu.cn/",
  },
  {
    value: "1136",
    label: "Chongqing Vocational College of Public Transportation",
    website: "http://www.cqgyzy.com/",
  },
  {
    value: "1137",
    label: "Chongqing Wenli University",
    website: "http://www.cqwu.net/",
  },
  {
    value: "1138",
    label: "Civil Aviation University of China",
    website: "http://www.cauc.edu/",
  },
  {
    value: "1139",
    label: "Communication University of China",
    website: "http://www.cuc.edu.cn/",
  },
  {
    value: "1140",
    label: "Dalian Martime University",
    website: "http://www.dlmu.edu.cn/",
  },
  {
    value: "1141",
    label: "Dalian Medical University",
    website: "http://www.dlmedu.edu.cn/",
  },
  {
    value: "1142",
    label: "Dalian Polytechnic University",
    website: "http://www.dlpu.edu.cn/",
  },
  {
    value: "1143",
    label: "Dalian University",
    website: "http://www.dlu.edu.cn/",
  },
  {
    value: "1144",
    label: "Dalian University of Foreign Language",
    website: "http://www.dlufl.edu.cn/",
  },
  {
    value: "1145",
    label: "Dalian University of Technology",
    website: "http://www.dlut.edu.cn/",
  },
  {
    value: "1146",
    label: "Dongbei University of Finance And Economics",
    website: "http://www.dufe.edu.cn/",
  },
  {
    value: "1147",
    label: "Donghua University, Shanghai",
    website: "http://www.dhu.edu.cn/",
  },
  {
    value: "1148",
    label: "East China Jiao Tong University",
    website: "http://www.ecjtu.jx.cn/",
  },
  {
    value: "1149",
    label: "East China Normal University",
    website: "http://www.ecnu.edu.cn/",
  },
  {
    value: "1150",
    label: "East China University of Science and Technology",
    website: "http://www.ecust.edu.cn/",
  },
  {
    value: "1151",
    label: "Emeishan Buddhist College",
    website: "http://www.emsfj.com/",
  },
  {
    value: "1152",
    label: "Foshan University",
    website: "http://www.fosu.edu.cn/",
  },
  {
    value: "1153",
    label: "Fudan University",
    website: "http://www.fudan.edu.cn/",
  },
  {
    value: "1154",
    label: "Fujian Agricultural University",
    website: "http://www.fjau.edu.cn/",
  },
  {
    value: "1155",
    label: "Fujian Medical University",
    website: "http://www.fjmu.edu.cn/",
  },
  {
    value: "1156",
    label: "Fujian Normal University",
    website: "http://www.fjnu.edu.cn/",
  },
  {
    value: "1157",
    label: "Fujian University of Traditional Chinese Medicine",
    website: "http://www.fjtcm.edu.cn/",
  },
  {
    value: "1158",
    label: "Fushun Petroleum University",
    website: "http://www.fspu.edu.cn/",
  },
  {
    value: "1159",
    label: "Fuzhou University",
    website: "http://www.fzu.edu.cn/",
  },
  {
    value: "1160",
    label: "Gansu Agricultural University",
    website: "http://www.gsau.edu.cn/",
  },
  {
    value: "1161",
    label: "Gansu University of Technology",
    website: "http://www.gsut.edu.cn/",
  },
  {
    value: "1162",
    label: "Guangdong Peizheng College ",
    website: "http://www.peizheng.com.cn/",
  },
  {
    value: "1163",
    label: "Guangdong Polytechnic Normal University",
    website: "http://www.gdin.edu.cn/",
  },
  {
    value: "1164",
    label: "Guangdong Radio & TV University",
    website: "http://www.gdrtvu.edu.cn/",
  },
  {
    value: "1165",
    label: "Guangdong University of Foreign Studies",
    website: "http://www.gdufs.edu.cn/",
  },
  {
    value: "1166",
    label: "Guangdong University of Technology",
    website: "http://www.gdut.edu.cn/",
  },
  {
    value: "1167",
    label: "Guangxi Medical University",
    website: "http://www.gxmu.edu.cn/",
  },
  {
    value: "1168",
    label: "Guangxi Normal University",
    website: "http://www.gxnu.edu.cn/",
  },
  {
    value: "1169",
    label: "Guangxi Traditional Chinese Medical University",
    website: "http://www.gxtcmu.edu.cn/",
  },
  {
    value: "1170",
    label: "Guangxi University",
    website: "http://www.gxu.edu.cn/",
  },
  {
    value: "1171",
    label: "Guangxi University for Nationalities",
    website: "http://www.gxun.edu.cn/",
  },
  {
    value: "1172",
    label: "Guangzhou Academy of Fine Art",
    website: "http://www.gzarts.edu.cn/",
  },
  {
    value: "1173",
    label: "Guangzhou Normal University",
    website: "http://www.guangztc.edu.cn/",
  },
  {
    value: "1174",
    label: "Guangzhou University",
    website: "http://www.gzhu.edu.cn/",
  },
  {
    value: "1175",
    label: "Guangzhou University of Traditional Chinese Medicine",
    website: "http://www.gzhtcm.edu.cn/",
  },
  {
    value: "1176",
    label: "Guizhou Normal University",
    website: "http://www.gznu.edu.cn/",
  },
  {
    value: "1177",
    label: "Guizhou University",
    website: "http://www.gzu.edu.cn/",
  },
  {
    value: "1178",
    label: "Hainan Normal University",
    website: "http://www.hainnu.edu.cn/",
  },
  {
    value: "1179",
    label: "Hainan University",
    website: "http://www.hainu.edu.cn/",
  },
  {
    value: "1180",
    label: "Harbin Engineering University",
    website: "http://www.hrbeu.edu.cn/",
  },
  {
    value: "1181",
    label: "Harbin Institute of Technology",
    website: "http://www.hit.edu.cn/",
  },
  {
    value: "1182",
    label: "Harbin Medical University",
    website: "http://www.hrbmu.edu.cn/",
  },
  {
    value: "1183",
    label: "Harbin Normal University",
    website: "http://www.hrbnu.edu.cn/",
  },
  {
    value: "1184",
    label: "Harbin University of Civil Engineering & Architecture",
    website: "http://www.hrbucea.edu.cn/",
  },
  {
    value: "1185",
    label: "Harbin University of Science and Technology",
    website: "http://www.hrbust.edu.cn/",
  },
  {
    value: "1186",
    label: "Hebei Academy of Fine Art",
    website: "http://www.hbafa.com/",
  },
  {
    value: "1187",
    label: "Hebei Agricultural University",
    website: "http://www.hebau.edu.cn/",
  },
  {
    value: "1188",
    label: "Hebei Medical University",
    website: "http://www.hebmu.edu.cn/",
  },
  {
    value: "1189",
    label: "Hebei Normal University",
    website: "http://www.hebnu.edu.cn/",
  },
  {
    value: "1190",
    label: "Hebei United University",
    website: "http://www.hebeiuniteduniversity.com/",
  },
  {
    value: "1191",
    label: "Hebei University",
    website: "http://www.hbu.edu.cn/",
  },
  {
    value: "1192",
    label: "Hebei University of Economics and Trade",
    website: "http://www.heuet.edu.cn/",
  },
  {
    value: "1193",
    label: "Hebei University of Science and Technology",
    website: "http://www.hebust.edu.cn/",
  },
  {
    value: "1194",
    label: "Hebei University of Technology",
    website: "http://www.hebut.edu.cn/",
  },
  {
    value: "1195",
    label: "Hefei University of Technology",
    website: "http://www.hfut.edu.cn/",
  },
  {
    value: "1196",
    label: "Hehai University",
    website: "http://www.hhu.edu.cn/",
  },
  {
    value: "1197",
    label: "Heilongjiang August 1st Reclamation University",
    website: "http://www.hlaflru.edu.cn/",
  },
  {
    value: "1198",
    label: "Heilongjiang Commercial University",
    website: "http://www.hljcu.edu.cn/",
  },
  {
    value: "1199",
    label: "Heilongjiang University",
    website: "http://www.hlju.edu.cn/",
  },
  {
    value: "1200",
    label: "Henan Agriculture University",
    website: "http://www.henau.edu.cn/",
  },
  {
    value: "1201",
    label: "Henan Buddhist College",
    website: "http://www.hnfjxy.cn/",
  },
  {
    value: "1202",
    label: "Henan Normal University",
    website: "http://www.henannu.edu.cn/",
  },
  {
    value: "1203",
    label: "Henan Univeristy",
    website: "http://www.henu.edu.cn/",
  },
  {
    value: "1204",
    label: "Hohai University Changzhou",
    website: "http://www.hhuc.edu.cn/",
  },
  {
    value: "1205",
    label: "Huaihai Institute of Technology",
    website: "http://www.hhit.edu.cn/",
  },
  {
    value: "1206",
    label: "Huaihua Medical College",
    website: "http://www.yizhuan.com/",
  },
  {
    value: "1207",
    label: "Huaihua Radio and Television University",
    website: "http://www.hhrtu.com/",
  },
  {
    value: "1208",
    label: " Huaihua University",
    website: "http://www.hhu.edu.cn/",
  },
  {
    value: "1209",
    label: "Huanghe Science & Technology University",
    website: "http://www.hhstu.edu.cn/",
  },
  {
    value: "1210",
    label: "Huaqiao University Quanzhuo",
    website: "http://www.hqu.edu.cn/",
  },
  {
    value: "1211",
    label: "Huazhong Agricultural University",
    website: "http://www.hzau.edu.cn/",
  },
  {
    value: "1212",
    label: "Huazhong University of Science and Technology",
    website: "http://www.hust.edu.cn/",
  },
  {
    value: "1213",
    label: "Hubei University",
    website: "http://www.hubu.edu.cn/",
  },
  {
    value: "1214",
    label: "Huizhou University",
    website: "http://www.hzu.edu.cn/",
  },
  {
    value: "1215",
    label: "Hunan Agricultural University",
    website: "http://www.hunau.net/",
  },
  {
    value: "1216",
    label: "Hunan Normal University",
    website: "http://www.hunnu.edu.cn/",
  },
  {
    value: "1217",
    label: "Hunan University",
    website: "http://www.hunu.edu.cn/",
  },
  {
    value: "1218",
    label: "Inner Mongolia Agricultural University",
    website: "http://www.imau.edu.cn/",
  },
  {
    value: "1219",
    label: "Inner Mongolia Normal University",
    website: "http://www.imnu.edu.cn/",
  },
  {
    value: "1220",
    label: "Inner Mongolia Polytechnic University",
    website: "http://www.impu.edu.cn/",
  },
  {
    value: "1221",
    label: "Inner Mongolia University",
    website: "http://www.imu.edu.cn/",
  },
  {
    value: "1222",
    label: "International Business University of Beijing",
    website: "http://www.ibub.edu.cn/",
  },
  {
    value: "1223",
    label: "Jiangsu University of Science and Technology",
    website: "http://www.jsust.edu.cn/",
  },
  {
    value: "1224",
    label: "Jiangxi Agricultural University",
    website: "http://www.jxau.edu.cn/",
  },
  {
    value: "1225",
    label: "Jiangxi Normal University",
    website: "http://www.jxnu.edu.cn/",
  },
  {
    value: "1226",
    label: "Jiangxi University of Finance and Economics",
    website: "http://www.jxufe.edu.cn/",
  },
  {
    value: "1227",
    label: "Jiangxi University of Traditional Chinese Medicine",
    website: "http://www.jutcm.com/",
  },
  {
    value: "1228",
    label: "Jiaying University",
    website: "http://www.jyu.edu.cn/",
  },
  {
    value: "1229",
    label: "Jilin Agricultural University",
    website: "http://www.jlau.edu.cn/",
  },
  {
    value: "1230",
    label: "Jilin University",
    website: "http://www.jlu.edu.cn/",
  },
  {
    value: "1231",
    label: "Jilin University of Technology",
    website: "http://www.jut.edu.cn/",
  },
  {
    value: "1232",
    label: "Jimei University",
    website: "http://www.jmu.edu.cn/",
  },
  {
    value: "1233",
    label: "Jinan University",
    website: "http://www.jnu.edu.cn/",
  },
  {
    value: "1234",
    label: "Jingdezhen China Institute",
    website: "http://www.jci.edu.cn/",
  },
  {
    value: "1235",
    label: "Jishou University",
    website: "http://www.jsu.edu.cn/",
  },
  {
    value: "1236",
    label: "Kunmimg University of Science and Technology",
    website: "http://www.kmust.edu.cn/",
  },
  {
    value: "1237",
    label: "Lanzhou University",
    website: "http://www.lzu.edu.cn/",
  },
  {
    value: "1238",
    label: "Liaocheng Teachers University",
    website: "http://www.lctu.edu.cn/",
  },
  {
    value: "1239",
    label: "Liaoning Normal University",
    website: "http://www.lnnu.edu.cn/",
  },
  {
    value: "1240",
    label: "Liaoning Technical University",
    website: "http://www.lntu.edu.cn/",
  },
  {
    value: "1241",
    label: "Liaoning University",
    website: "http://www.lnu.edu.cn/",
  },
  {
    value: "1242",
    label: "Logistics Engineering University of PLA",
    website: "http://www.hqgc.net/",
  },
  {
    value: "1243",
    label: "Ludong University",
    website: "http://www.ytnc.edu.cn/",
  },
  {
    value: "1244",
    label: "Luxun Academy of Fine Art",
    website: "http://www.lumei.edu.cn/",
  },
  {
    value: "1245",
    label: "Luzhou Medical College",
    website: "http://www.lzmc.edu.cn/",
  },
  {
    value: "1246",
    label: "Nanchang University",
    website: "http://www.ncu.edu.cn/",
  },
  {
    value: "1247",
    label: "Nanjing Agricultural University",
    website: "http://www.njau.edu.cn/",
  },
  {
    value: "1248",
    label: "Nanjing Forestry University",
    website: "http://www.njfu.edu.cn/",
  },
  {
    value: "1249",
    label: "Nanjing Institute of Meteorology",
    website: "http://www.njim.edu.cn/",
  },
  {
    value: "1250",
    label: "Nanjing Medical University",
    website: "http://www.njmu.edu.cn/",
  },
  {
    value: "1251",
    label: "Nanjing Normal University",
    website: "http://www.njnu.edu.cn/",
  },
  {
    value: "1252",
    label: "Nanjing Union Theological Seminary",
    website: "http://www.njuts.cn/",
  },
  {
    value: "1253",
    label: "Nanjing University",
    website: "http://www.nju.edu.cn/",
  },
  {
    value: "1254",
    label: "Nanjing University of Aeronautics and Astronautics",
    website: "http://www.nuaa.edu.cn/",
  },
  {
    value: "1255",
    label: "Nanjing University of Chemical Technology",
    website: "http://www.njuct.edu.cn/",
  },
  {
    value: "1256",
    label: "Nanjing University of Economics",
    website: "http://www.njue.edu.cn/",
  },
  {
    value: "1257",
    label: "Nanjing University of Posts and Telecommunications",
    website: "http://www.njupt.edu.cn/",
  },
  {
    value: "1258",
    label: "Nanjing University of Science and Technology",
    website: "http://www.njust.edu.cn/",
  },
  {
    value: "1259",
    label: "Nanjing University of Traditional Chinese Medicine",
    website: "http://www.njutcm.edu.cn/",
  },
  {
    value: "1260",
    label: "Nankai University",
    website: "http://nku.nankai.edu.cn/",
  },
  {
    value: "1261",
    label: "National University of Defense Technology",
    website: "http://www.nudt.edu.cn/",
  },
  {
    value: "1262",
    label: "Neijiang Teacher University",
    website: "http://www.neijtc.edu.cn/",
  },
  {
    value: "1263",
    label: "Ningbo University",
    website: "http://www.nbu.edu.cn/",
  },
  {
    value: "1264",
    label: "Ningbo University of Technology",
    website: "http://www.nbut.edu.cn/",
  },
  {
    value: "1265",
    label: "Ningxia Medical College",
    website: "http://www.nxmc.edu.cn/",
  },
  {
    value: "1266",
    label: "Ningxia University",
    website: "http://www.nxu.edu.cn/",
  },
  {
    value: "1267",
    label: "North China Electric Power University",
    website: "http://www.ncepubj.edu.cn/",
  },
  {
    value: "1268",
    label: "North China University of Technology",
    website: "http://www.ncut.edu.cn/",
  },
  {
    value: "1269",
    label: "Northeast Agricultural University",
    website: "http://www.neau.edu.cn/",
  },
  {
    value: "1270",
    label: "Northeastern University",
    website: "http://www.neu.edu.cn/",
  },
  {
    value: "1271",
    label: "Northeast Forest University",
    website: "http://www.nefu.edu.cn/",
  },
  {
    value: "1272",
    label: "Northeast Normal University",
    website: "http://www.nenu.edu.cn/",
  },
  {
    value: "1273",
    label: "Northeast University at Qinhuangdao Campus",
    website: "http://www.neuq.edu.cn/",
  },
  {
    value: "1274",
    label: "Northern Jiaotong University",
    website: "http://www.njtu.edu.cn/",
  },
  {
    value: "1275",
    label: "Northern Sichuan Medical College",
    website: "http://www.nsmc.edu.cn/",
  },
  {
    value: "1276",
    label: "Northwest A&F University",
    website: "http://www.nwau.edu.cn/",
  },
  {
    value: "1277",
    label: "Northwest Normal University Lanzhou",
    website: "http://www.nwnu.edu.cn/",
  },
  {
    value: "1278",
    label: "Northwest Polytechnical University Xi'an",
    website: "http://www.nwpu.edu.cn/",
  },
  {
    value: "1279",
    label: "Northwest University Xi'an",
    website: "http://www.nwu.edu.cn/",
  },
  {
    value: "1280",
    label: "Ocean University of China",
    website: "http://www.ouc.edu.cn/",
  },
  {
    value: "1281",
    label: "Panzhihua University",
    website: "http://www.pzhu.edu.cn/",
  },
  {
    value: "1282",
    label: "Peking University",
    website: "http://www.pku.edu.cn/",
  },
  {
    value: "1283",
    label: "Qingdao University",
    website: "http://www.qdu.edu.cn/",
  },
  {
    value: "1284",
    label: "Qingdao University of Science and Technology",
    website: "http://www.qust.edu.cn/",
  },
  {
    value: "1285",
    label: "Qinghai Normal University",
    website: "http://www.qhnu.edu.cn/",
  },
  {
    value: "1286",
    label: "Qinghai Radio & Television University",
    website: "http://www.qhrtvu.edu.cn/",
  },
  {
    value: "1287",
    label: "Qinghai University",
    website: "http://www.qhu.edu.cn/",
  },
  {
    value: "1288",
    label: "Qiongzhou University",
    website: "http://www.qzu.edu.cn/",
  },
  {
    value: "1289",
    label: "Qufu Normal University",
    website: "http://www.qfnu.edu.cn/",
  },
  {
    value: "1290",
    label: "Renmin University of China",
    website: "http://www.ruc.edu.cn/",
  },
  {
    value: "1291",
    label: "Shaanxi Normal University",
    website: "http://www.snnu.edu.cn/",
  },
  {
    value: "1292",
    label: "Shandong Agricultural University",
    website: "http://www.sdau.edu.cn/",
  },
  {
    value: "1293",
    label: "Shandong Economic University",
    website: "http://www.sdie.edu.cn/",
  },
  {
    value: "1294",
    label: "Shandong Medical University",
    website: "http://www.sdmu.edu.cn/",
  },
  {
    value: "1295",
    label: "Shandong Normal University",
    website: "http://www.sdnu.edu.cn/",
  },
  {
    value: "1296",
    label: "Shandong University",
    website: "http://www.sdu.edu.cn/",
  },
  {
    value: "1297",
    label: "Shandong University of Art and Design",
    website: "http://www.sdada.edu.cn/",
  },
  {
    value: "1298",
    label: "Shandong University of Science and Technology",
    website: "http://www.sdust.edu.cn/",
  },
  {
    value: "1299",
    label: "Shandong University of Technology",
    website: "http://www.sdut.edu.cn/",
  },
  {
    value: "1300",
    label: "Shandong University of Triaditional Chinese Medicine",
    website: "http://www.sdutcm.edu.cn/",
  },
  {
    value: "1301",
    label: "Shanghai Business School",
    website: "http://www.sbs.edu.cn/",
  },
  {
    value: "1302",
    label: "Shanghai City College",
    website: "http://www.umcollege.com/",
  },
  {
    value: "1303",
    label: "Shanghai Customs College",
    website: "http://shanghai_edu.customs.gov.cn/",
  },
  {
    value: "1304",
    label: "Shanghai Dainji University",
    website: "http://www.sdju.edu.cn/",
  },
  {
    value: "1305",
    label: "Shanghai Institue of Foreign Trade",
    website: "http://www.shift.edu.cn/",
  },
  {
    value: "1306",
    label: "Shanghai International Studies University",
    website: "http://www.shisu.edu.cn/",
  },
  {
    value: "1307",
    label: "Shanghai Jiaotong University",
    website: "http://www.sjtu.edu.cn/",
  },
  {
    value: "1308",
    label: "Shanghai Lida Polytechnic Institute",
    website: "http://www.lidapoly.com/",
  },
  {
    value: "1309",
    label: "Shanghai Lixin University of Commerce",
    website: "http://www.lixin.edu.cn/",
  },
  {
    value: "1310",
    label: "Shanghai Maritime University",
    website: "http://www.shmtu.edu.cn/",
  },
  {
    value: "1311",
    label: "Shanghai Medical University",
    website: "http://www.shmu.edu.cn/",
  },
  {
    value: "1312",
    label: "Shanghai Normal University",
    website: "http://www.shtu.edu.cn/",
  },
  {
    value: "1313",
    label: "Shanghai Ocean University",
    website: "http://www.shou.edu.cn/",
  },
  {
    value: "1314",
    label: "Shanghai Ouhua Vocational Technical College",
    website: "http://www.shouhua.net.cn/",
  },
  {
    value: "1315",
    label: "Shanghai Sanda University",
    website: "http://www.sandau.edu.cn/",
  },
  {
    value: "1316",
    label: "Shanghai Second Medical University",
    website: "http://www.shsmu.edu.cn/",
  },
  {
    value: "1317",
    label: "Shanghai Second Polytechnic University",
    website: "http://www.shspu.edu.cn/",
  },
  {
    value: "1318",
    label: "Shanghai Sipo Polytechnic",
    website: "http://www.sh-sipopolytechnic.com/",
  },
  {
    value: "1319",
    label: "Shanghai Television University",
    website: "http://www.shtvu.edu.cn/",
  },
  {
    value: "1320",
    label: "Shanghai TieDao University",
    website: "http://www.shtdu.edu.cn/",
  },
  {
    value: "1321",
    label: "Shanghai University",
    website: "http://www.shu.edu.cn/",
  },
  {
    value: "1322",
    label: "Shanghai University of Engineering Science",
    website: "http://www.sues.edu.cn/",
  },
  {
    value: "1323",
    label: "Shanghai University of Finance and Economics",
    website: "http://www.shufe.edu.cn/",
  },
  {
    value: "1324",
    label: "Shanghai University of Science and Technology",
    website: "http://www.usst.edu.cn/",
  },
  {
    value: "1325",
    label:
      "Shanghai University of Traditional Chinese Medicine and Pharmacology",
    website: "http://www.shutcm.edu.cn/",
  },
  {
    value: "1326",
    label: "Shantou University",
    website: "http://www.stu.edu.cn/",
  },
  {
    value: "1327",
    label: "Shanxi Agricultural University",
    website: "http://www.sxau.edu.cn/",
  },
  {
    value: "1328",
    label: "Shanxi Normal University",
    website: "http://www.snnu.edu.cn/",
  },
  {
    value: "1329",
    label: "Shanxi University",
    website: "http://www.sxu.edu.cn/",
  },
  {
    value: "1330",
    label: "Shaoguan University",
    website: "http://www.sgu.edu.cn/",
  },
  {
    value: "1331",
    label: "Shenyang Institute of Chemical Technology",
    website: "http://www.syict.edu.cn/",
  },
  {
    value: "1332",
    label: "Shenyang Jianzhu University",
    website: "http://www.sjzu.edu.cn/",
  },
  {
    value: "1333",
    label: "Shenyang Pharmaceutical University",
    website: "http://www.syphu.edu.cn/",
  },
  {
    value: "1334",
    label: "Shenyang Polytechnic University",
    website: "http://www.sypu.edu.cn/",
  },
  {
    value: "1335",
    label: "Shenyang University",
    website: "http://www.syu.edu.cn/",
  },
  {
    value: "1336",
    label: "Shenzhen University",
    website: "http://www.szu.edu.cn/",
  },
  {
    value: "1337",
    label: "Shihezi University ",
    website: "http://www.shzu.edu.cn/",
  },
  {
    value: "1338",
    label: "Sichuan Agricultural University",
    website: "http://www.sicau.edu.cn/",
  },
  {
    value: "1339",
    label: "Sichuan Fine Art Institute",
    website: "http://zsb.scfai.edu.cn/",
  },
  {
    value: "1340",
    label: "Sichuan International Studies University",
    website: "http://www.sisu.edu.cn/",
  },
  {
    value: "1341",
    label: "Sichuan Normal University",
    website: "http://www.sicnu.edu.cn/",
  },
  {
    value: "1342",
    label: "Sichuan University",
    website: "http://www.scu.edu.cn/",
  },
  {
    value: "1343",
    label: "South China Agricultural University",
    website: "http://www.scau.edu.cn/",
  },
  {
    value: "1344",
    label: "South China Construction University",
    website: "http://www.sccu.edu.cn/",
  },
  {
    value: "1345",
    label: "South China Normal University",
    website: "http://www.scnu.edu.cn/",
  },
  {
    value: "1346",
    label: "South China University",
    website: "http://www.usc.edu.cn/",
  },
  {
    value: "1347",
    label: "South China University of Technology",
    website: "http://www.scut.edu.cn/",
  },
  {
    value: "1348",
    label: "Southeast University",
    website: "http://www.seu.edu.cn/",
  },
  {
    value: "1349",
    label: "Southern Medial University",
    website: "http://www.fimmu.edu.cn/",
  },
  {
    value: "1350",
    label: "Southern Yangtze University",
    website: "http://www.sytu.edu.cn/",
  },
  {
    value: "1351",
    label: "South University of Science and Technology of China ",
    website: "http://www.sustc.edu.cn/",
  },
  {
    value: "1352",
    label: "Southwest Agricultural University",
    website: "http://www.swau.edu.cn/",
  },
  {
    value: "1353",
    label: "Southwest Forestry University",
    website: "http://www.swfc.edu.cn/",
  },
  {
    value: "1354",
    label: "Southwest Jiaotong University",
    website: "http://www.swjtu.edu.cn/",
  },
  {
    value: "1355",
    label: "Southwest Petroleum University",
    website: "http://www.swpu.edu.cn/",
  },
  {
    value: "1356",
    label: "Southwest University",
    website: "http://www.swnu.edu.cn/",
  },
  {
    value: "1357",
    label: "Southwest University of Finance and Economics",
    website: "http://www.swufe.edu.cn/",
  },
  {
    value: "1358",
    label: "Southwest University of Nationalities",
    website: "http://www.swun.edu.cn/",
  },
  {
    value: "1359",
    label: "Southwest University of Political Science and Law",
    website: "http://www.swupl.edu.cn/",
  },
  {
    value: "1360",
    label: "Southwest University of Science and Technology",
    website: "http://www.swust.edu.cn/",
  },
  {
    value: "1361",
    label: "South West University Yucai College",
    website: "http://www.swuyc.edu.cn/",
  },
  {
    value: "1362",
    label: "Sun Yat-Sen University of Medical Sciences",
    website: "http://www.gzsums.edu.cn/",
  },
  {
    value: "1363",
    label: "Suzhou University",
    website: "http://www.suda.edu.cn/",
  },
  {
    value: "1364",
    label: "Taiyuan University of Technology",
    website: "http://www.tyut.edu.cn/",
  },
  {
    value: "1365",
    label: "Taizhou College",
    website: "http://www.tzc.edu.cn/",
  },
  {
    value: "1366",
    label: "Tarim University",
    website: "http://www.taru.edu.cn/",
  },
  {
    value: "1367",
    label: "The University of Nottingham Ningbo China",
    website: "http://www.nottingham.edu.cn/",
  },
  {
    value: "1368",
    label: "Tianjin Academy of Fine Art",
    website: "http://www.tjarts.edu.cn/",
  },
  {
    value: "1369",
    label: "Tianjin Medical University",
    website: "http://www.tijmu.edu.cn/",
  },
  {
    value: "1370",
    label: "Tianjin Normal University",
    website: "http://www.tjnu.edu.cn/",
  },
  {
    value: "1371",
    label: "Tianjin Open University",
    website: "http://www.tjrtvu.edu.cn/",
  },
  {
    value: "1372",
    label: "Tianjin Polytechnic University",
    website: "http://www.tjpu.edu.cn/",
  },
  {
    value: "1373",
    label: "Tianjin University",
    website: "http://www.tju.edu.cn/",
  },
  {
    value: "1374",
    label: "Tianjin University of Commerce",
    website: "http://www.tjcu.edu.cn/",
  },
  {
    value: "1375",
    label: "Tianjin University of Finance & Economics",
    website: "http://www.tjufe.edu.cn/",
  },
  {
    value: "1376",
    label: "Tianjin University of Technology",
    website: "http://www.tjut.edu.cn/",
  },
  {
    value: "1377",
    label: "Tibet Agricultural and Animal Husbandry College",
    website: "http://www.xza.cn/",
  },
  {
    value: "1378",
    label: "Tibet Tibetan Medical College",
    website: "http://www.ttmc.edu.cn/",
  },
  {
    value: "1379",
    label: "Tibet University",
    website: "http://www.utibet.edu.cn/",
  },
  {
    value: "1380",
    label: "Tibet University of Nationalities",
    website: "http://www.xzmy.edu.cn/",
  },
  {
    value: "1381",
    label: "Tongji Medical University",
    website: "http://www.tjmu.edu.cn/",
  },
  {
    value: "1382",
    label: "Tongji University",
    website: "http://www.tongji.edu.cn/",
  },
  {
    value: "1383",
    label: "Tsinghua University",
    website: "http://www.tsinghua.edu.cn/",
  },
  {
    value: "1384",
    label: "University of Electronic Science and Technology of China",
    website: "http://www.uestc.edu.cn/",
  },
  {
    value: "1385",
    label: "University of Hydraulic Electric Engineering",
    website: "http://www.uheeyc.edu.cn/",
  },
  {
    value: "1386",
    label: "University of International Business and Economics",
    website: "http://www.uibe.edu.cn/",
  },
  {
    value: "1387",
    label: "University of Jinan",
    website: "http://www.ujn.edu.cn/",
  },
  {
    value: "1388",
    label: "University of Petroleum (East China)",
    website: "http://www.hdpu.edu.cn/",
  },
  {
    value: "1389",
    label: "University of Science and Technology Beijing",
    website: "http://www.ustb.edu.cn/",
  },
  {
    value: "1390",
    label: "University of Science and Technology of China",
    website: "http://www.ustc.edu.cn/",
  },
  {
    value: "1391",
    label: "Urumqi Vocational University",
    website: "http://www.uvu.edu.cn/",
  },
  {
    value: "1392",
    label: "Wenzhou Medical College",
    website: "http://www.wzmu.net/",
  },
  {
    value: "1393",
    label: "Wenzhou University",
    website: "http://www.wzu.edu.cn/",
  },
  {
    value: "1394",
    label: "West China University of Medical Sciences",
    website: "http://www.wcums.edu.cn/",
  },
  {
    value: "1395",
    label: "Wudanshan Taoist College ",
    website: "http://www.wdsdjxy.com/",
  },
  {
    value: "1396",
    label: "Wuhan Automobile Polytechnical University",
    website: "http://www.whapu.edu.cn/",
  },
  {
    value: "1397",
    label: "Wuhan Technical University of Surveying and Mapping",
    website: "http://www.wtusm.edu.cn/",
  },
  {
    value: "1398",
    label: "Wuhan Transportation University",
    website: "http://www.whtu.edu.cn/",
  },
  {
    value: "1399",
    label: "Wuhan University",
    website: "http://www.whu.edu.cn/",
  },
  {
    value: "1400",
    label: "Wuhan University of Hydraulic and Electric Engineering",
    website: "http://www.wuhee.edu.cn/",
  },
  {
    value: "1401",
    label: "Wuhan University of Technology",
    website: "http://www.whut.edu.cn/",
  },
  {
    value: "1402",
    label: "Wuhan University School of Medicine",
    website: "http://wsm.whu.edu.cn/",
  },
  {
    value: "1403",
    label: "Wuxi University of Light Industry",
    website: "http://www.wxuli.edu.cn/",
  },
  {
    value: "1404",
    label: "Wuyi University",
    website: "http://www.wyu.edu.cn/",
  },
  {
    value: "1405",
    label: "Xiamen University",
    website: "http://www.xmu.edu.cn/",
  },
  {
    value: "1406",
    label: "Xi'an Academy of Fine Art",
    website: "http://www.xafa.edu.cn/",
  },
  {
    value: "1407",
    label: "Xiangtan Normal University",
    website: "http://www.xtnu.edu.cn/",
  },
  {
    value: "1408",
    label: "Xiangtan University",
    website: "http://www.xtu.edu.cn/",
  },
  {
    value: "1409",
    label: "Xi'an Highway University",
    website: "http://www.xahu.edu.cn/",
  },
  {
    value: "1410",
    label: "Xi'an International Studies University",
    website: "http://www.xisu.edu.cn/",
  },
  {
    value: "1411",
    label: "Xi'an Jiaotong-Liverpool University",
    website: "http://www.xjtlu.edu.cn/",
  },
  {
    value: "1412",
    label: "Xi'an Jiaotong University",
    website: "http://www.xjtu.edu.cn/",
  },
  {
    value: "1413",
    label: "Xi'an University of Architecture and Technology",
    website: "http://www.xauat.edu.cn/",
  },
  {
    value: "1414",
    label: "Xi'an University of Electronic Science and Technology",
    website: "http://www.xidian.edu.cn/",
  },
  {
    value: "1415",
    label: "Xi'an University of Technology",
    website: "http://www.xaut.edu.cn/",
  },
  {
    value: "1416",
    label: "Xihua University",
    website: "http://www.xhu.edu.cn/",
  },
  {
    value: "1417",
    label: "Xijiang University",
    website: "http://www.xiju.edu.cn/",
  },
  {
    value: "1418",
    label: "Xinghai Conservatory of Music",
    website: "http://www.xhcom.edu.cn/",
  },
  {
    value: "1419",
    label: "Xinjiang Agriculture University",
    website: "http://www.xjau.edu.cn/",
  },
  {
    value: "1420",
    label: "Xinjiang Normal University",
    website: "http://www.xjnu.edu.cn/",
  },
  {
    value: "1421",
    label: "Xinjiang University",
    website: "http://www.xju.edu.cn/",
  },
  {
    value: "1422",
    label: "Xinjiang University of Finance and Economics",
    website: "http://www.xjufe.edu.cn/",
  },
  {
    value: "1423",
    label: "Xuzhou Normal University",
    website: "http://www.xznu.edu.cn/",
  },
  {
    value: "1424",
    label: "Yan'an University",
    website: "http://www.yau.edu.cn/",
  },
  {
    value: "1425",
    label: "Yanbian University",
    website: "http://www.ybu.edu.cn/",
  },
  {
    value: "1426",
    label: "Yanbian University of Science and Technology",
    website: "http://www.ybust.edu.cn/",
  },
  {
    value: "1427",
    label: "Yangtze Normal University",
    website: "http://www.yznu.cn/",
  },
  {
    value: "1428",
    label: "Yangtze University",
    website: "http://www.yangtzeu.edu.cn/",
  },
  {
    value: "1429",
    label: "Yangzhou University",
    website: "http://www.yzu.edu.cn/",
  },
  {
    value: "1430",
    label: "Yan Shan University",
    website: "http://www.ysu.edu.cn/",
  },
  {
    value: "1431",
    label: "Yantai Education Institute & Yantai Television University",
    website: "http://www.ytet.edu.cn/",
  },
  {
    value: "1432",
    label: "Yantai University",
    website: "http://www.ytu.edu.cn/",
  },
  {
    value: "1433",
    label: "Yunnan Agriculture University",
    website: "http://www.ynau.edu.cn/",
  },
  {
    value: "1434",
    label: "Yunnan Normal University",
    website: "http://www.ynnu.edu.cn/",
  },
  {
    value: "1435",
    label: "Yunnan University",
    website: "http://www.ynu.edu.cn/",
  },
  {
    value: "1436",
    label: "Zhanjiang Ocean University",
    website: "http://www.zjou.edu.cn/",
  },
  {
    value: "1437",
    label: "Zhejiang Forestry University",
    website: "http://www.zjfc.edu.cn/",
  },
  {
    value: "1438",
    label: "Zhejiang Gongshang University",
    website: "http://www.zjgsu.edu.cn/",
  },
  {
    value: "1439",
    label: "Zhejiang Normal University",
    website: "http://www.zjnu.edu.cn/",
  },
  {
    value: "1440",
    label: "Zhejiang Sci-Tech University ",
    website: "http://www.zstu.edu.cn/",
  },
  {
    value: "1441",
    label: "Zhejiang University",
    website: "http://www.zju.edu.cn/",
  },
  {
    value: "1442",
    label: "Zhejiang University of Technology",
    website: "http://www.zjut.edu.cn/",
  },
  {
    value: "1443",
    label: "Zhengda Software College",
    website: "http://www.zdsoft.com.cn/",
  },
  {
    value: "1444",
    label: "Zhengzhou Grain University",
    website: "http://www.zzgc.edu.cn/",
  },
  {
    value: "1445",
    label: "Zhengzhou University of Technology",
    website: "http://www.zzut.edu.cn/",
  },
  {
    value: "1446",
    label: "Zhongnan University of Economics and Law",
    website: "http://www.znuel.net/",
  },
  {
    value: "1447",
    label: "Zhongnan University of Finance and Economics",
    website: "http://www.znufe.edu.cn/",
  },
  {
    value: "1448",
    label: "Zhongnan University of Technology",
    website: "http://www.zhnut.edu.cn/",
  },
  {
    value: "1449",
    label: "Zhongshan University",
    website: "http://www.zsu.edu.cn/",
  },
  {
    value: "1450",
    label: "Centro de Estudios Investigación y Tecnología (CEIT)",
    website: "http://www.iceit.edu.co/",
  },
  {
    value: "1451",
    label: "Colegio de Estudios Superiores de Administración (CESA)",
    website: "http://www.cesa.edu.co/",
  },
  {
    value: "1452",
    label: "Conservatorio del Tolima",
    website: "http://www.bundenet.com/umusical/",
  },
  {
    value: "1453",
    label: 'Corporación Educativa Mayor del Desarrollo "Simón Bolivar"',
    website: "http://www.unisimonbolivar.edu.co/",
  },
  {
    value: "1454",
    label: "Corporación Universitaria de Ibagué (CORUNIVERSITARIA)",
    website: "http://www.cui.edu.co/",
  },
  {
    value: "1455",
    label: "Corporación Universitaria de la Costa (UNICOSTA)",
    website: "http://www.cuc.edu.co/",
  },
  {
    value: "1456",
    label: "Corporación Universitaria de Santander (UDES)",
    website: "http://www.udes.edu.co/",
  },
  {
    value: "1457",
    label: "Corporación Universitaria Tecnológica de Bolivar",
    website: "http://www.cutb.edu.co/",
  },
  {
    value: "1458",
    label: "Escuela Colombiana de Carreras Industriales",
    website: "http://www.ecci.edu.co/",
  },
  {
    value: "1459",
    label: "Escuela Colombiana de Ingeniería Julio Garavito",
    website: "http://www.escuelaing.edu.co/",
  },
  {
    value: "1460",
    label: "Escuela de Administración de Negocios",
    website: "http://www.ean.edu.co/",
  },
  {
    value: "1461",
    label: "Escuela de Ingeniería de Antioquia",
    website: "http://www.eia.edu.co/",
  },
  {
    value: "1462",
    label: 'Escuela de Policia "General Santander"',
    website: "http://www.escueladepolicia.edu.co/",
  },
  {
    value: "1463",
    label: "Escuela Superiore de Administración Pública",
    website: "http://www.esap.edu.co/",
  },
  {
    value: "1464",
    label: "Fundacion Escuela Colombiana de Rehabiliación",
    website: "http://www.ecr.edu.co/",
  },
  {
    value: "1465",
    label: "Fundación Universitaria de Boyacá",
    website: "http://www.uniboyaca.edu.co/",
  },
  {
    value: "1466",
    label: "Fundación Universitaria del Area Andina. Sede Pereira",
    website: "http://www.funandi.edu.co/",
  },
  {
    value: "1467",
    label: "Fundación Universitaria Luis Amigó",
    website: "http://www.funlam.edu.co/",
  },
  {
    value: "1468",
    label: "Fundación Universitaria Manuela Beltrán",
    website: "http://www.umb.edu.co/",
  },
  {
    value: "1469",
    label: "Fundación Universitaria San Martín",
    website: "http://www.sanmartin.edu.co/",
  },
  {
    value: "1470",
    label: "Institucion Universitaria de Envigado",
    website: "http://www.iue.edu.co/",
  },
  {
    value: "1471",
    label: "Institución Universitaria Iberoamericana",
    website: "http://www.iberoamericana.edu.co/",
  },
  {
    value: "1472",
    label: "Instituto Tecnológico Metropolitano",
    website: "http://www.itm.edu.co/",
  },
  {
    value: "1473",
    label: "Instituto Universitario de La Paz",
    website: "http://www.unipaz.edu.co/",
  },
  {
    value: "1474",
    label: 'Politécnico Columbiano "Jaime Isaza Cadavid"',
    website: "http://www.politecjic.edu.co/",
  },
  {
    value: "1475",
    label: "Politécnico Grancolombiano - Institución Universitaria",
    website: "http://www.poligran.edu.co/",
  },
  {
    value: "1476",
    label: "Pontificia Universidad Javeriana",
    website: "http://www.javeriana.edu.co/",
  },
  {
    value: "1477",
    label: "Universidad Adventista de Colombia",
    website: "http://www.unac.edu.co/",
  },
  {
    value: "1478",
    label: "Universidad Antonio Nariño",
    website: "http://www.uanarino.edu.co/",
  },
  {
    value: "1479",
    label: "Universidad Autónoma de Bucaramanga",
    website: "http://www.unab.edu.co/",
  },
  {
    value: "1480",
    label: "Universidad Autónoma de Colombia",
    website: "http://www.fuac.edu.co/",
  },
  {
    value: "1481",
    label: "Universidad Autónoma de Las Américas",
    website: "http://www.uam.edu.co/",
  },
  {
    value: "1482",
    label: "Universidad Autónoma del Caribe",
    website: "http://www.uac.edu.co/",
  },
  {
    value: "1483",
    label: "Universidad Autónoma de Manizales",
    website: "http://www.autonoma.edu.co/",
  },
  {
    value: "1484",
    label: "Universidad Autónoma de Occidente",
    website: "http://www.cuao.edu.co/",
  },
  {
    value: "1485",
    label: "Universidad Autónoma Latinoamericana",
    website: "http://www.unaula.edu.co/",
  },
  {
    value: "1486",
    label: "Universidad Bellas Artes",
    website: "http://www.bellasartes.edu.co/",
  },
  {
    value: "1487",
    label: "Universidad Católica de Colombia",
    website: "http://www.ucatolica.edu.co/",
  },
  {
    value: "1488",
    label: "Universidad Católica del Norte",
    website: "http://www.ucn.edu.co/",
  },
  {
    value: "1489",
    label: "Universidad Católica del Oriente",
    website: "http://www.uco.edu.co/",
  },
  {
    value: "1490",
    label: "Universidad Católica de Manizales",
    website: "http://www.ucatolicamz.edu.co/",
  },
  {
    value: "1491",
    label: "Universidad Católica Popular del Risaralda",
    website: "http://www.ucpr.edu.co/",
  },
  {
    value: "1492",
    label: "Universidad Central",
    website: "http://www.ucentral.edu.co/",
  },
  {
    value: "1493",
    label: "Universidad Central del Valle del Cauca",
    website: "http://www.uceva.edu.co/",
  },
  {
    value: "1494",
    label: "Universidad CES",
    website: "http://www.ces.edu.co/",
  },
  {
    value: "1495",
    label: "Universidad Cooperativa de Colombia",
    website: "http://www.ucc.edu.co/",
  },
  {
    value: "1496",
    label: "Universidad de América Bogotá",
    website: "http://www.uamerica.edu.co/",
  },
  {
    value: "1497",
    label: "Universidad de Antioquia",
    website: "http://www.udea.edu.co/",
  },
  {
    value: "1498",
    label: "Universidad de Antioquía",
    website: "http://www.udea.edu.co/",
  },
  {
    value: "1499",
    label: 'Universidad de Bogotá "Jorge Tadeo Lozano"',
    website: "http://www.utadeo.edu.co/",
  },
  {
    value: "1500",
    label: "Universidad de Caldas",
    website: "http://www.ucaldas.edu.co/",
  },
  {
    value: "1501",
    label: "Universidad de Cartagena",
    website: "http://www.unicartagena.edu.co/",
  },
  {
    value: "1502",
    label: "Universidad de Ciencias Aplicadas y Ambientales (UDCA)",
    website: "http://www.udca.edu.co/",
  },
  {
    value: "1503",
    label: "Universidad de Córdoba",
    website: "http://www.unicordoba.edu.co/",
  },
  {
    value: "1504",
    label: "Universidad de La Amazonia",
    website: "http://www.uniamazonia.edu.co/",
  },
  {
    value: "1505",
    label: "Universidad de La Guajira",
    website: "http://www.uniguajira.edu.co/",
  },
  {
    value: "1506",
    label: "Universidad de La Sabana",
    website: "http://www.unisabana.edu.co/",
  },
  {
    value: "1507",
    label: "Universidad de La Salle",
    website: "http://www.lasalle.edu.co/",
  },
  {
    value: "1508",
    label: "Universidad del Atlántico",
    website: "http://www.uniatlantico.edu.co/",
  },
  {
    value: "1509",
    label: "Universidad del Cauca",
    website: "http://www.ucauca.edu.co/",
  },
  {
    value: "1510",
    label: "Universidad del Magdalena",
    website: "http://www.unimagdalena.edu.co/",
  },
  {
    value: "1511",
    label: "Universidad del Norte",
    website: "http://www.uninorte.edu.co/",
  },
  {
    value: "1512",
    label: "Universidad de Los Andes",
    website: "http://www.uniandes.edu.co/",
  },
  {
    value: "1513",
    label: "Universidad de Los Llanos",
    website: "http://www.unillanos.edu.co/",
  },
  {
    value: "1514",
    label: "Universidad del Quindío",
    website: "http://www.uniquindio.edu.co/",
  },
  {
    value: "1515",
    label: "Universidad del Rosario",
    website: "http://www.urosario.edu.co/",
  },
  {
    value: "1516",
    label: "Universidad del Sinú",
    website: "http://www.unisinucartagena.edu.co/",
  },
  {
    value: "1517",
    label: "Universidad del Tolima",
    website: "http://www.ut.edu.co/",
  },
  {
    value: "1518",
    label: "Universidad del Valle del Cauca",
    website: "http://www.univalle.edu.co/",
  },
  {
    value: "1519",
    label: "Universidad de Manizales",
    website: "http://www.umanizales.edu.co/",
  },
  {
    value: "1520",
    label: "Universidad de Medellín",
    website: "http://www.udem.edu.co/",
  },
  {
    value: "1521",
    label: "Universidad de Nariño",
    website: "http://www.udenar.edu.co/",
  },
  {
    value: "1522",
    label: "Universidad de Pamplona",
    website: "http://www.unipamplona.edu.co/",
  },
  {
    value: "1523",
    label: "Universidad de San Buenaventura",
    website: "http://www.usb.edu.co/",
  },
  {
    value: "1524",
    label: "Universidad de San Buenaventura Medellin",
    website: "http://www.usbmed.edu.co/",
  },
  {
    value: "1525",
    label: "Universidad de Sucre",
    website: "http://www.unisucre.edu.co/",
  },
  {
    value: "1526",
    label: 'Universidad Distral "Francisco José de Caldas"',
    website: "http://www.udistrital.edu.co/",
  },
  {
    value: "1527",
    label: "Universidad EAFIT",
    website: "http://www.eafit.edu.co/",
  },
  {
    value: "1528",
    label: "Universidad El Bosque",
    website: "http://www.unbosque.edu.co/",
  },
  {
    value: "1529",
    label: "Universidad Externado de Colombia",
    website: "http://www.uexternado.edu.co/",
  },
  {
    value: "1530",
    label: "Universidad Francisco de Paula Santander",
    website: "http://www.ufps.edu.co/",
  },
  {
    value: "1531",
    label: "Universidad ICESI",
    website: "http://www.icesi.edu.co/",
  },
  {
    value: "1532",
    label: "Universidad INCCA de Colombia",
    website: "http://www.unincca.edu.co/",
  },
  {
    value: "1533",
    label: "Universidad Industrial de Santander",
    website: "http://www.uis.edu.co/",
  },
  {
    value: "1534",
    label: "Universidad La Gran Colombia",
    website: "http://www.ugrancolombia.edu.co/",
  },
  {
    value: "1535",
    label: "Universidad Libre de Colombia",
    website: "http://www.unilibre.edu.co/",
  },
  {
    value: "1536",
    label: "Universidad Mariana",
    website: "http://www.umariana.edu.co/",
  },
  {
    value: "1537",
    label: "Universidad Metropolitana",
    website: "http://www.unimetro.edu.co/",
  },
  {
    value: "1538",
    label: "Universidad Militar Nueva Granada",
    website: "http://www.umng.edu.co/",
  },
  {
    value: "1539",
    label: "Universidad Nacional Abierta y a Distancia",
    website: "http://www.unad.edu.co/",
  },
  {
    value: "1540",
    label: "Universidad Nacional de Colombia",
    website: "http://www.unal.edu.co/",
  },
  {
    value: "1541",
    label: "Universidad Pedagogica Nacional",
    website: "http://www.pedagogica.edu.co/",
  },
  {
    value: "1542",
    label: "Universidad Pedagógica y Tecnológica de Colombia",
    website: "http://www.uptc.edu.co/",
  },
  {
    value: "1543",
    label: "Universidad Piloto de Colombia",
    website: "http://www.unipiloto.edu.co/",
  },
  {
    value: "1544",
    label: "Universidad Pontificia Bolivariana",
    website: "http://www.upb.edu.co/",
  },
  {
    value: "1545",
    label: "Universidad Popular del Cesar",
    website: "http://www.unicesar.edu.co/",
  },
  {
    value: "1546",
    label: "Universidad Santiago de Cali",
    website: "http://www.usaca.edu.co/",
  },
  {
    value: "1547",
    label: "Universidad Santo Tomás",
    website: "http://www.usta.edu.co/",
  },
  {
    value: "1548",
    label: "Universidad Sergio Arboleda",
    website: "http://www.usergioarboleda.edu.co/",
  },
  {
    value: "1549",
    label: "Universidad Surcolombiana",
    website: "http://www.usurcolombia.com/",
  },
  {
    value: "1550",
    label: 'Universidad Tecnológica del Choco "Diego Luis Cordoba"',
    website: "http://www.utch.edu.co/",
  },
  {
    value: "1551",
    label: "Universidad Tecnológica de Pereira",
    website: "http://www.utp.edu.co/",
  },
  {
    value: "1552",
    label: "Instituto Tecnológico de Costa Rica",
    website: "http://www.itcr.ac.cr/",
  },
  {
    value: "1553",
    label: "Universidad Adventista de Centro América",
    website: "http://www.unadeca.ac.cr/",
  },
  {
    value: "1554",
    label: "Universidad Autónoma de Centro América",
    website: "http://www.uaca.ac.cr/",
  },
  {
    value: "1555",
    label: "Universidad Autonoma Monterrey",
    website: "http://www.unam.ac.cr/",
  },
  {
    value: "1556",
    label: "Universidad Braulio Carrillo",
    website: "http://www.universidadbrauliocarrillo.com/",
  },
  {
    value: "1557",
    label: "Universidad Católica Anselmo Llorente",
    website: "http://www.ucatolica.ac.cr/",
  },
  {
    value: "1558",
    label: "Universidad Central",
    website: "http://www.universidadcentral.com/",
  },
  {
    value: "1559",
    label: "Universidad de Cartago Florencio del Castillo",
    website: "http://www.uca.ac.cr/",
  },
  {
    value: "1560",
    label: "Universidad de Costa Rica",
    website: "http://www.ucr.ac.cr/",
  },
  {
    value: "1561",
    label: "Universidad de Iberoamérica",
    website: "http://www.unibe.ac.cr/",
  },
  {
    value: "1562",
    label: "Universidad de La Salle",
    website: "http://www.ulasalle.ac.cr/",
  },
  {
    value: "1563",
    label: "Universidad del Turismo",
    website: "http://www.utur.ac.cr/",
  },
  {
    value: "1564",
    label: "Universidad de San José",
    website: "http://www.universidadsanjosecr.com/",
  },
  {
    value: "1565",
    label: "Universidad Empresarial",
    website: "http://www.unem.edu/internacional/",
  },
  {
    value: "1566",
    label: "Universidad Estatal a Distancia",
    website: "http://www.uned.ac.cr/",
  },
  {
    value: "1567",
    label: "Universidad Fidélitas",
    website: "http://www.ufidelitas.ac.cr/",
  },
  {
    value: "1568",
    label: "Universidad Fundepos Alma Mater",
    website: "http://www.fundepos.ac.cr/",
  },
  {
    value: "1569",
    label: "Universidad Hispanoamericana",
    website: "http://www.uhispanoamericana.ac.cr/",
  },
  {
    value: "1570",
    label: "Universidad Internacional de las Américas",
    website: "http://www.uia.ac.cr/",
  },
  {
    value: "1571",
    label: "Universidad Isaac Newton",
    website: "http://www.isaacnewtoncr.com/",
  },
  {
    value: "1572",
    label: "Universidad Latina de Costa Rica",
    website: "http://www.ulatina.ac.cr/",
  },
  {
    value: "1573",
    label: "Universidad Latinoamericana de Ciencia y Tecnología",
    website: "http://www.ulacit.ac.cr/",
  },
  {
    value: "1574",
    label: "Universidad Libre de Costa Rica",
    website: "http://www.ulicori.ac.cr/",
  },
  {
    value: "1575",
    label: "Universidad Metropolitana Castro Carazo",
    website: "http://www.umca.net/",
  },
  {
    value: "1576",
    label: "Universidad Nacional",
    website: "http://www.una.ac.cr/",
  },
  {
    value: "1577",
    label: "Universidad para la Cooperación Internacional",
    website: "http://www.uci.ac.cr/",
  },
  {
    value: "1578",
    label: "Universidad San Juan de la Cruz",
    website: "http://www.universidadsanjuan.ac.cr/",
  },
  {
    value: "1579",
    label: "Universidad San Juan de la Cruz",
    website: "http://www.sjdlc.com/",
  },
  {
    value: "1580",
    label: "Universidad San Marcos",
    website: "http://www.usam.ac.cr/",
  },
  {
    value: "1581",
    label: "Universidad Santa Paula",
    website: "http://www.uspsantapaula.com/",
  },
  {
    value: "1582",
    label: "Universidad Veritas",
    website: "http://www.uveritas.ac.cr/",
  },
  {
    value: "1583",
    label: "University for Peace",
    website: "http://www.upeace.org/",
  },
  {
    value: "1584",
    label: 'Instituto Superior Minero Metalúrgico "Dr. Antonio Núñez Jiménez"',
    website: "http://www.ismm.edu.cu/",
  },
  {
    value: "1585",
    label: "Instituto Superior Politécnico José Antonio Echeverría",
    website: "http://www.cujae.edu.cu/",
  },
  {
    value: "1586",
    label: "Universidad Central de Las Villas",
    website: "http://www.uclv.edu.cu/",
  },
  {
    value: "1587",
    label: "Universidad de Camagüey",
    website: "http://www.reduc.edu.cu/",
  },
  {
    value: "1588",
    label: "Universidad de Ciego de Avila",
    website: "http://www.unica.cu/",
  },
  {
    value: "1589",
    label: "Universidad de Cienfuegos",
    website: "http://www.ucf.edu.cu/",
  },
  {
    value: "1590",
    label: "Universidad de Granma",
    website: "http://www.udg.co.cu/",
  },
  {
    value: "1591",
    label: "Universidad de Holguín",
    website: "http://www.uho.edu.cu/",
  },
  {
    value: "1592",
    label: "Universidad de La Habana",
    website: "http://www.uh.cu/",
  },
  {
    value: "1593",
    label: "Universidad de Matanzas Camilo Cienfuegos",
    website: "http://www.umcc.cu/",
  },
  {
    value: "1594",
    label: "Universidad de Oriente",
    website: "http://www.uo.edu.cu/",
  },
  {
    value: "1595",
    label: "Universidad de Pinar del Río",
    website: "http://www.upr.edu.cu/",
  },
  {
    value: "1596",
    label: 'Universidad Pedagógica "José Martí", Camagüey',
    website: "http://www.ispcmw.rimed.cu/",
  },
  {
    value: "1597",
    label: "Universidade Jean Piaget de Cabo Verde",
    website: "http://cv.unipiaget.org/",
  },
  {
    value: "1598",
    label: "Americanos College",
    website: "http://www.ac.ac.cy/",
  },
  {
    value: "1599",
    label: "Cyprus College",
    website: "http://www.cycollege.ac.cy/",
  },
  {
    value: "1600",
    label: "Cyprus International Institute of Management (CIIM)",
    website: "http://www.ciim.ac.cy/",
  },
  {
    value: "1601",
    label: "Cyprus International University",
    website: "http://www.ciu.edu.tr/",
  },
  {
    value: "1602",
    label: "Cyprus University of Technology",
    website: "http://www.cut.ac.cy/",
  },
  {
    value: "1603",
    label: "Eastern Mediterranean University",
    website: "http://www.emu.edu.tr/",
  },
  {
    value: "1604",
    label: "European University Cyprus",
    website: "http://www.euc.ac.cy/",
  },
  {
    value: "1605",
    label: "European University of Lefke",
    website: "http://www.lefke.edu.tr/",
  },
  {
    value: "1606",
    label: "Frederick University",
    website: "http://www.frederick.ac.cy/",
  },
  {
    value: "1607",
    label: "Girne American University",
    website: "http://www.gau.edu.tr/",
  },
  {
    value: "1608",
    label: "Intercollege",
    website: "http://www.intercollege.ac.cy/",
  },
  {
    value: "1609",
    label: "Near East University",
    website: "http://www.neu.edu.tr/",
  },
  {
    value: "1610",
    label: "Open University of Cyprus",
    website: "http://www.ouc.ac.cy/",
  },
  {
    value: "1611",
    label: "The CTL Eurocollege",
    website: "http://www.ctleuro.ac.cy/",
  },
  {
    value: "1612",
    label: "University of Cyprus",
    website: "http://www.ucy.ac.cy/",
  },
  {
    value: "1613",
    label: "University of Nicosia",
    website: "http://www.unic.ac.cy/",
  },
  {
    value: "1614",
    label: "Academy of Performing Arts, Film and TV Fakulty",
    website: "http://www.famu.cz/",
  },
  {
    value: "1615",
    label: "Anglo-American University",
    website: "http://www.aauni.edu/",
  },
  {
    value: "1616",
    label: "Brno University of Technology",
    website: "http://www.vutbr.cz/",
  },
  {
    value: "1617",
    label: "Cevro Institut College",
    website: "http://www.vsci.cz/",
  },
  {
    value: "1618",
    label: "Charles University Prague",
    website: "http://www.cuni.cz/",
  },
  {
    value: "1619",
    label: "Czech Technical University of Prague",
    website: "http://www.cvut.cz/",
  },
  {
    value: "1620",
    label: "Czech University of Agriculture Prague",
    website: "http://www.czu.cz/",
  },
  {
    value: "1621",
    label: "Masaryk University",
    website: "http://www.muni.cz/",
  },
  {
    value: "1622",
    label: "Mendel University of Agriculture and Forestry",
    website: "http://www.mendelu.cz/",
  },
  {
    value: "1623",
    label: "Palacky University",
    website: "http://www.upol.cz/",
  },
  {
    value: "1624",
    label: "Prague College",
    website: "http://www.praguecollege.cz/",
  },
  {
    value: "1625",
    label: "Prague Institute of Chemical Technology",
    website: "http://www.vscht.cz/",
  },
  {
    value: "1626",
    label: "Prague International University",
    website: "http://www.mpu-prague.cz/",
  },
  {
    value: "1627",
    label: "Silesian University",
    website: "http://www.slu.cz/",
  },
  {
    value: "1628",
    label: "Technical University of Liberec",
    website: "http://www.vslib.cz/",
  },
  {
    value: "1629",
    label: "Technical University of Mining and Metallurgy Ostrava",
    website: "http://www.vsb.cz/",
  },
  {
    value: "1630",
    label: "Tiffin University Prague",
    website: "http://www.tiffinprague.cz/",
  },
  {
    value: "1631",
    label: "Tomas Bata University in Zlin",
    website: "http://www.utb.cz/",
  },
  {
    value: "1632",
    label: "University of Economics Prague",
    website: "http://www.vse.cz/",
  },
  {
    value: "1633",
    label: "University of Education Hradec Kralove",
    website: "http://www.vsp.cz/",
  },
  {
    value: "1634",
    label: "University of Jan Evangelista Purkyne",
    website: "http://www.ujep.cz/",
  },
  {
    value: "1635",
    label: "University of New York in Prague",
    website: "http://www.unyp.cz/",
  },
  {
    value: "1636",
    label: "University of Northern Virginia, Prague Campus",
    website: "http://www.unva.cz/",
  },
  {
    value: "1637",
    label: "University of Ostrava",
    website: "http://www.osu.cz/",
  },
  {
    value: "1638",
    label: "University of Pardubice",
    website: "http://www.upce.cz/",
  },
  {
    value: "1639",
    label: "University of South Bohemia",
    website: "http://www.jcu.cz/",
  },
  {
    value: "1640",
    label: "University of Veterinary and Pharmaceutical Science",
    website: "http://www.vfu.cz/",
  },
  {
    value: "1641",
    label: "University of West Bohemia",
    website: "http://www.zcu.cz/",
  },
  {
    value: "1642",
    label: "AKAD Hochschulen für Berufstätige, Fachhochschule Leipzig",
    website: "http://www.akad.de/",
  },
  {
    value: "1643",
    label: "Albert-Ludwigs-Universität Freiburg",
    website: "http://www.uni-freiburg.de/",
  },
  {
    value: "1644",
    label:
      "Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin",
    website: "http://www.asfh-berlin.de/",
  },
  {
    value: "1645",
    label: "Augustana Hochschule Neuendettelsau",
    website: "http://www.augustana.de/",
  },
  {
    value: "1646",
    label: "Bauhaus Universität Weimar",
    website: "http://www.uni-weimar.de/",
  },
  {
    value: "1647",
    label: "Bayerische Julius-Maximilians-Universität Würzburg",
    website: "http://www.uni-wuerzburg.de/",
  },
  {
    value: "1648",
    label: "Bergische Universität Gesamthochschule Wuppertal",
    website: "http://www.uni-wuppertal.de/",
  },
  {
    value: "1649",
    label: "BiTS - Business and Information Technology School gGmbH",
    website: "http://www.bits-iserlohn.de/",
  },
  {
    value: "1650",
    label: "Brandenburgische Technische Universität Cottbus",
    website: "http://www.tu-cottbus.de/",
  },
  {
    value: "1651",
    label: "Carl von Ossietzky Universität Oldenburg",
    website: "http://www.uni-oldenburg.de/",
  },
  {
    value: "1652",
    label: "Christian-Albrechts-Universität Kiel",
    website: "http://www.uni-kiel.de/",
  },
  {
    value: "1653",
    label: "Cologne Business School",
    website: "http://www.cbs.de/",
  },
  {
    value: "1654",
    label: "Deutsche Hochschule für Verwaltungswissenschaften Speyer",
    website: "http://www.dhv-speyer.de/",
  },
  {
    value: "1655",
    label: "Deutsche Sporthochschule Köln",
    website: "http://www.dshs-koeln.de/",
  },
  {
    value: "1656",
    label: "Deutsche Telekom Fachhochschule Leipzig",
    website: "http://www.fh-telekom-leipzig.de/",
  },
  {
    value: "1657",
    label:
      "Deutsch-Ordens Fachhochschule Riedlingen, Hochschule für Wirtschaft",
    website: "http://www.fh-riedlingen.de/",
  },
  {
    value: "1658",
    label: "DIPLOMA-Fachhochschule Ölsnitz/Vogtland",
    website: "http://www.diploma.de/",
  },
  {
    value: "1659",
    label: "Duale Hochschule Baden-Württemberg",
    website: "http://www.dhbw.de/",
  },
  {
    value: "1660",
    label: "E.A.P. Europäische Wirtschaftshochschule Berlin",
    website: "http://www.eap.net/",
  },
  {
    value: "1661",
    label: "Eberhard-Karls-Universität Tübingen",
    website: "http://www.uni-tuebingen.de/",
  },
  {
    value: "1662",
    label: "Ernst-Moritz-Arndt Universität Greifswald",
    website: "http://www.uni-greifswald.de/",
  },
  {
    value: "1663",
    label: "Europa Fachhochschule Fresenius",
    website: "http://www.fh-fresenius.de/",
  },
  {
    value: "1664",
    label: "Europäische Betriebswirtschafts-Akademie",
    website: "http://www.eba-muenchen.de/",
  },
  {
    value: "1665",
    label: "Europäische Fachhochschule",
    website: "http://www.eufh.de/",
  },
  {
    value: "1666",
    label: "Europa-Universität Viadrina Frankfurt (Oder)",
    website: "http://www.euv-frankfurt-o.de/",
  },
  {
    value: "1667",
    label: "European Business School Schloß Reichartshausen",
    website: "http://www.ebs.de/",
  },
  {
    value: "1668",
    label: "European College of Liberal Arts",
    website: "http://www.ecla.de/",
  },
  {
    value: "1669",
    label: "European Management School",
    website: "http://www.ems-mainz.de/",
  },
  {
    value: "1670",
    label:
      "Evangelische Fachhochschule Berlin, Fachhochschule für Sozialarbeit und Sozialpädagogik",
    website: "http://www.evfh-berlin.de/",
  },
  {
    value: "1671",
    label: "Evangelische Fachhochschule Darmstadt",
    website: "http://www.efh-darmstadt.de/",
  },
  {
    value: "1672",
    label:
      "Evangelische Fachhochschule Freiburg, Hochschule für Soziale Arbeit, Diakonie und Religionspädagogik",
    website: "http://www.efh-freiburg.de/",
  },
  {
    value: "1673",
    label:
      "Evangelische Fachhochschule für Religionspädagogik, und Gemeindediakonie Moritzburg",
    website: "http://www.fhs-moritzburg.de/",
  },
  {
    value: "1674",
    label:
      'Evangelische Fachhochschule für Sozialpädagogik der "Diakonenanstalt des Rauhen Hauses" Hamburg',
    website: "http://www.rauheshaus.de/fachhochschule/",
  },
  {
    value: "1675",
    label: "Evangelische Fachhochschule Hannover",
    website: "http://www.efh-hannover.de/",
  },
  {
    value: "1676",
    label:
      "Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen",
    website: "http://www.efhlu.de/",
  },
  {
    value: "1677",
    label: "Evangelische Fachhochschule Nürnberg",
    website: "http://www.evfh-nuernberg.de/",
  },
  {
    value: "1678",
    label:
      "Evangelische Fachhochschule Reutlingen-Ludwigsburg, Hochschule für Soziale Arbeit, Religionspädagogik und Diakonie",
    website: "http://www.efh-reutlingen-ludwigsburg.de/",
  },
  {
    value: "1679",
    label: "Evangelische Fachhochschule Rheinland-Westfalen-Lippe",
    website: "http://www.efh-bochum.de/",
  },
  {
    value: "1680",
    label: "Evangelische Hochschule für Soziale Arbeit Dresden (FH)",
    website: "http://www.ehs-dresden.de/",
  },
  {
    value: "1681",
    label: "Fachhochschule Aachen",
    website: "http://www.fh-aachen.de/",
  },
  {
    value: "1682",
    label: "Fachhochschule Aschaffenburg",
    website: "http://www.fh-aschaffenburg.de/",
  },
  {
    value: "1683",
    label: "Fachhochschule Augsburg",
    website: "http://www.fh-augsburg.de/",
  },
  {
    value: "1684",
    label: "Fachhochschule Biberach, Hochschule für Bauwesen und Wirtschaft",
    website: "http://www.fh-biberach.de/",
  },
  {
    value: "1685",
    label: "Fachhochschule Bielefeld",
    website: "http://www.fh-bielefeld.de/",
  },
  {
    value: "1686",
    label: "Fachhochschule Bingen",
    website: "http://www.fh-bingen.de/",
  },
  {
    value: "1687",
    label: "Fachhochschule Bochum",
    website: "http://www.fh-bochum.de/",
  },
  {
    value: "1688",
    label: "Fachhochschule Bonn-Rhein-Sieg",
    website: "http://www.fh-bonn-rhein-sieg.de/",
  },
  {
    value: "1689",
    label: "Fachhochschule Brandenburg",
    website: "http://www.fh-brandenburg.de/",
  },
  {
    value: "1690",
    label: "Fachhochschule Braunschweig/Wolfenbüttel",
    website: "http://www.fh-wolfenbuettel.de/",
  },
  {
    value: "1691",
    label: "Fachhochschule Deggendorf",
    website: "http://www.fh-deggendorf.de/",
  },
  {
    value: "1692",
    label: "Fachhochschule der Wirtschaft",
    website: "http://www.fhdw.de/",
  },
  {
    value: "1693",
    label: "Fachhochschule des Mittelstandes (FHM)",
    website: "http://www.fhm-mittelstand.de/",
  },
  {
    value: "1694",
    label: "Fachhochschule Dortmund",
    website: "http://www.fh-dortmund.de/",
  },
  {
    value: "1695",
    label: "Fachhochschule Düsseldorf",
    website: "http://www.fh-duesseldorf.de/",
  },
  {
    value: "1696",
    label: "Fachhochschule Eberswalde",
    website: "http://www.fh-eberswalde.de/",
  },
  {
    value: "1697",
    label: "Fachhochschule Erfurt",
    website: "http://www.fh-erfurt.de/",
  },
  {
    value: "1698",
    label: "Fachhochschule Flensburg",
    website: "http://www.fh-flensburg.de/",
  },
  {
    value: "1699",
    label: "Fachhochschule Frankfurt am Main",
    website: "http://www.fh-frankfurt.de/",
  },
  {
    value: "1700",
    label: "Fachhochschule für das öffentliche Bibliothekswesen Bonn",
    website: "http://www.fhoebb.de/",
  },
  {
    value: "1701",
    label: "Fachhochschule für die Wirtschaft",
    website: "http://www.fhdw.bib.de/",
  },
  {
    value: "1702",
    label: "Fachhochschule für Oekonomie und Management (FOM)",
    website: "http://www.fom.de/",
  },
  {
    value: "1703",
    label: "Fachhochschule für Technik und Wirtschaft Berlin",
    website: "http://www.fhtw-berlin.de/",
  },
  {
    value: "1704",
    label: "Fachhochschule Furtwangen, Hochschule für Technik und Wirtschaft",
    website: "http://www.fh-furtwangen.de/",
  },
  {
    value: "1705",
    label: "Fachhochschule für Verwaltung und Rechtspflege Berlin",
    website: "http://www.fhvr.berlin.de/",
  },
  {
    value: "1706",
    label: "Fachhochschule für Wirtschaft Berlin",
    website: "http://www.fhw-berlin.de/",
  },
  {
    value: "1707",
    label: "Fachhochschule Gelsenkirchen",
    website: "http://www.fh-gelsenkirchen.de/",
  },
  {
    value: "1708",
    label: "Fachhochschule Gießen-Friedberg",
    website: "http://www.fh-giessen.de/",
  },
  {
    value: "1709",
    label: "Fachhochschule Hamburg",
    website: "http://www.fh-hamburg.de/",
  },
  {
    value: "1710",
    label: "Fachhochschule Hannover",
    website: "http://www.fh-hannover.de/",
  },
  {
    value: "1711",
    label: "Fachhochschule Heidelberg",
    website: "http://www.fh-heidelberg.de/",
  },
  {
    value: "1712",
    label: "Fachhochschule Heilbronn, Hochschule für Technik und Wirtschaft",
    website: "http://www.fh-heilbronn.de/",
  },
  {
    value: "1713",
    label:
      "Fachhochschule Hildesheim/Holzminden/Göttingen, Hochschule für angewandte Wissenschaft und Kunst",
    website: "http://www.fh-hildesheim.de/",
  },
  {
    value: "1714",
    label: "Fachhochschule Hof",
    website: "http://www.fh-hof.de/",
  },
  {
    value: "1715",
    label: "Fachhochschule Ingolstadt",
    website: "http://www.fh-ingolstadt.de/",
  },
  {
    value: "1716",
    label: "Fachhochschule Jena",
    website: "http://www.fh-jena.de/",
  },
  {
    value: "1717",
    label: "Fachhochschule Kaiserslautern",
    website: "http://www.fh-kl.de/",
  },
  {
    value: "1718",
    label: "Fachhochschule Kempten, Hochschule für Technik und Wirtschaft",
    website: "http://www.fh-Kempten.de/",
  },
  {
    value: "1719",
    label: "Fachhochschule Kiel",
    website: "http://www.fh-kiel.de/",
  },
  {
    value: "1720",
    label: "Fachhochschule Koblenz",
    website: "http://www.fh-koblenz.de/",
  },
  {
    value: "1721",
    label: "Fachhochschule Köln",
    website: "http://www.fh-koeln.de/",
  },
  {
    value: "1722",
    label:
      "Fachhochschule Konstanz, Hochschule für Technik, Wirtschaft und Gestaltung",
    website: "http://www.fh-konstanz.de/",
  },
  {
    value: "1723",
    label:
      "Fachhochschule Landshut, Hochschule für Wirtschaft - Sozialwesen - Technik",
    website: "http://www.fh-landshut.de/",
  },
  {
    value: "1724",
    label: "Fachhochschule Lausitz",
    website: "http://www.fh-lausitz.de/",
  },
  {
    value: "1725",
    label: "Fachhochschule Lippe",
    website: "http://www.fh-lippe.de/",
  },
  {
    value: "1726",
    label: "Fachhochschule Lübeck",
    website: "http://www.fh-luebeck.de/",
  },
  {
    value: "1727",
    label: "Fachhochschule Ludwigshafen, Hochschule für Wirtschaft",
    website: "http://www.fh-ludwigshafen.de/",
  },
  {
    value: "1728",
    label: "Fachhochschule Mainz",
    website: "http://www.fh-mainz.de/",
  },
  {
    value: "1729",
    label: "Fachhochschule Mannheim, Hochschule für Sozialwesen",
    website: "http://www.fhs-mannheim.de/",
  },
  {
    value: "1730",
    label: "Fachhochschule Mannheim, Hochschule für Technik und Gestaltung",
    website: "http://www.fh-mannheim.de/",
  },
  {
    value: "1731",
    label: "Fachhochschule Merseburg",
    website: "http://www.fh-merseburg.de/",
  },
  {
    value: "1732",
    label: "Fachhochschule München",
    website: "http://www.fh-muenchen.de/",
  },
  {
    value: "1733",
    label: "Fachhochschule Münster",
    website: "http://www.fh-muenster.de/",
  },
  {
    value: "1734",
    label: "Fachhochschule Neubrandenburg",
    website: "http://www.fh-nb.de/",
  },
  {
    value: "1735",
    label: "Fachhochschule Neu-Ulm",
    website: "http://www.fh-neu-ulm.de/",
  },
  {
    value: "1736",
    label: "Fachhochschule Niederrhein",
    website: "http://www.fh-niederrhein.de/",
  },
  {
    value: "1737",
    label: "Fachhochschule Nordhausen",
    website: "http://www.fh-nordhausen.de/",
  },
  {
    value: "1738",
    label: "Fachhochschule Nordhessen",
    website: "http://www.diploma.de/",
  },
  {
    value: "1739",
    label: "Fachhochschule Nordostniedersachsen",
    website: "http://www.fhnon.de/",
  },
  {
    value: "1740",
    label:
      "Fachhochschule Nürtingen, Hochschule für Wirtschaft, Landwirtschaft und Landespflege",
    website: "http://www.fh-nuertingen.de/",
  },
  {
    value: "1741",
    label: "Fachhochschule Offenburg, Hochschule für Technik und Wirtschaft",
    website: "http://www.fh-offenburg.de/",
  },
  {
    value: "1742",
    label: "Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven",
    website: "http://www.fh-oow.de/",
  },
  {
    value: "1743",
    label: "Fachhochschule Osnabrück",
    website: "http://www.fh-osnabrueck.de/",
  },
  {
    value: "1744",
    label:
      "Fachhochschule Pforzheim, Hochschule für Gestaltung, Technik und Wirtschaft",
    website: "http://www.fh-pforzheim.de/",
  },
  {
    value: "1745",
    label: "Fachhochschule Potsdam",
    website: "http://www.fh-potsdam.de/",
  },
  {
    value: "1746",
    label: "Fachhochschule Ravensburg-Weingarten",
    website: "http://www.fh-weingarten.de/",
  },
  {
    value: "1747",
    label: "Fachhochschule Regensburg",
    website: "http://www.fh-regensburg.de/",
  },
  {
    value: "1748",
    label: "Fachhochschule Reutlingen, Hochschule für Technik und Wirtschaft",
    website: "http://www.fh-reutlingen.de/",
  },
  {
    value: "1749",
    label: "Fachhochschule Rosenheim, Hochschule für Technik und Wirtschaft",
    website: "http://www.fh-rosenheim.de/",
  },
  {
    value: "1750",
    label: "Fachhochschule Rottenburg, Hochschule für Forstwirtschaft",
    website: "http://www.fh-rottenburg.de/",
  },
  {
    value: "1751",
    label: "Fachhochschule Schmalkalden",
    website: "http://www.fh-schmalkalden.de/",
  },
  {
    value: "1752",
    label: "Fachhochschule Schwäbisch Gmünd, Hochschule für Gestaltung",
    website: "http://www.hfg-gmuend.de/",
  },
  {
    value: "1753",
    label: "Fachhochschule Schwäbisch Hall, Hochschule für Gestaltung",
    website: "http://www.fh-schwaebischhall.de/",
  },
  {
    value: "1754",
    label: "Fachhochschule Stralsund",
    website: "http://www.fh-stralsund.de/",
  },
  {
    value: "1755",
    label: "Fachhochschule Stuttgart, Hochschule der Medien",
    website: "http://www.hdm-stuttgart.de/",
  },
  {
    value: "1756",
    label: "Fachhochschule Stuttgart, Hochschule für Technik",
    website: "http://www.fht-stuttgart.de/",
  },
  {
    value: "1757",
    label:
      "Fachhochschule Trier, Hochschule für Technik, Wirtschaft und Gestaltung",
    website: "http://www.fh-trier.de/",
  },
  {
    value: "1758",
    label: "Fachhochschule Ulm, Hochschule für Technik",
    website: "http://www.fh-ulm.de/",
  },
  {
    value: "1759",
    label:
      "Fachhochschule und Berufskollegs NTA, Prof.Dr. Grübler gemein. GmbH",
    website: "http://www.fh-isny.de/",
  },
  {
    value: "1760",
    label: "Fachhochschule Wedel",
    website: "http://www.fh-wedel.de/",
  },
  {
    value: "1761",
    label: "Fachhochschule Weihenstephan",
    website: "http://www.fh-weihenstephan.de/",
  },
  {
    value: "1762",
    label: "Fachhochschule Westküste, Hochschule für Wirtschaft und Technik",
    website: "http://www.fh-westkueste.de/",
  },
  {
    value: "1763",
    label: "Fachhochschule Wiesbaden",
    website: "http://www.fh-wiesbaden.de/",
  },
  {
    value: "1764",
    label: "Fachhochschule Worms",
    website: "http://www.fh-worms.de/",
  },
  {
    value: "1765",
    label: "Fachhochschule Würzburg - Schweinfurt",
    website: "http://www.fh-wuerzburg.de/",
  },
  {
    value: "1766",
    label: "Fern-Fachhochschule Hamburg",
    website: "http://www.fern-fh.de/",
  },
  {
    value: "1767",
    label: "Fernuniversität Gesamthochschule Hagen",
    website: "http://www.fernuni-hagen.de/",
  },
  {
    value: "1768",
    label: "Freie Kunst-Studienstätte Ottersberg",
    website: "http://www.fh-ottersberg.de/",
  },
  {
    value: "1769",
    label: "Freie Universität Berlin",
    website: "http://www.fu-berlin.de/",
  },
  {
    value: "1770",
    label: "Friedrich-Alexander Universität Erlangen-Nürnberg",
    website: "http://www.uni-erlangen.de/",
  },
  {
    value: "1771",
    label: "Friedrich-Schiller Universität Jena",
    website: "http://www.uni-jena.de/",
  },
  {
    value: "1772",
    label: "Georg-August Universität Göttingen",
    website: "http://www.uni-goettingen.de/",
  },
  {
    value: "1773",
    label: "Georg-Simon-Ohm-Fachhochschule Nürnberg",
    website: "http://www.fh-nuernberg.de/",
  },
  {
    value: "1774",
    label: "Gustav-Siewerth-Akademie",
    website: "http://www.siewerth-akademie.de/",
  },
  {
    value: "1775",
    label: "Hafencity Universität Hamburg",
    website: "http://www.hcu-hamburg.de/",
  },
  {
    value: "1776",
    label: "Handelshochschule Leipzig",
    website: "http://www.hhl.de/",
  },
  {
    value: "1777",
    label: "Heinrich-Heine Universität Düsseldorf",
    website: "http://www.uni-duesseldorf.de/",
  },
  {
    value: "1778",
    label: "Hertie School of Governance",
    website: "http://www.hertie-school.org/",
  },
  {
    value: "1779",
    label: "Hochschule Albstadt-Sigmaringen",
    website: "http://www.hs-albsig.de/",
  },
  {
    value: "1780",
    label: "Hochschule Anhalt (FH), Hochschule für angewandte Wissenschaften",
    website: "http://www.hs-anhalt.de/",
  },
  {
    value: "1781",
    label: "Hochschule Bremen",
    website: "http://www.hs-bremen.de/",
  },
  {
    value: "1782",
    label: "Hochschule Bremerhaven",
    website: "http://www.hs-bremerhaven.de/",
  },
  {
    value: "1783",
    label: "Hochschule Coburg",
    website: "http://www.hs-coburg.de/",
  },
  {
    value: "1784",
    label: "Hochschule Darmstadt",
    website: "http://www.hs-darmstadt.de/",
  },
  {
    value: "1785",
    label: "Hochschule Esslingen",
    website: "http://www.hs-esslingen.de/",
  },
  {
    value: "1786",
    label: "Hochschule Fulda",
    website: "http://www.hs-fulda.de/",
  },
  {
    value: "1787",
    label:
      "Hochschule für Bankwirtschaft (HfB), Private Fachhochschule der Bankakademie",
    website: "http://www.hfb.de/",
  },
  {
    value: "1788",
    label: "Hochschule für Berufstätige Rendsburg",
    website: "http://www.akad.de/",
  },
  {
    value: "1789",
    label: "Hochschule für Internationales Management",
    website: "http://www.himh.de/",
  },
  {
    value: "1790",
    label: "Hochschule für Jüdische Studien Heidelberg",
    website: "http://www.hjs.uni-heidelberg.de/",
  },
  {
    value: "1791",
    label: "Hochschule für Philosophie München",
    website: "http://www.hfph.mwn.de/",
  },
  {
    value: "1792",
    label: "Hochschule für Politik (HFP)",
    website: "http://www.hfp.mhn.de/",
  },
  {
    value: "1793",
    label: "Hochschule für Technik und Wirtschaft des Saarlandes",
    website: "http://www.htw-saarland.de/",
  },
  {
    value: "1794",
    label: "Hochschule für Technik und Wirtschaft Dresden (FH)",
    website: "http://www.htw-dresden.de/",
  },
  {
    value: "1795",
    label: "Hochschule für Technik und Wirtschaft Karlsruhe",
    website: "http://www.hs-karlsruhe.de/",
  },
  {
    value: "1796",
    label: "Hochschule für Technik, Wirtschaft und Kultur Leipzig (FH)",
    website: "http://www.htwk-leipzig.de/",
  },
  {
    value: "1797",
    label: "Hochschule Harz, Hochschule für angewandte Wissenschaften (FH)",
    website: "http://www.hs-harz.de/",
  },
  {
    value: "1798",
    label: "Hochschule Magdeburg-Stendal (FH)",
    website: "http://www.hs-magdeburg.de/",
  },
  {
    value: "1799",
    label: "Hochschule Mittweida (FH)",
    website: "http://www.htwm.de/",
  },
  {
    value: "1800",
    label: "Hochschule Vechta",
    website: "http://www.uni-vechta.de/",
  },
  {
    value: "1801",
    label:
      "Hochschule Wismar, Fachhochschule für Technik, Wirtschaft und Gestaltung",
    website: "http://www.hs-wismar.de/",
  },
  {
    value: "1802",
    label: "Hochschule Zittau/Görlitz (FH)",
    website: "http://www.hs-zigr.de/",
  },
  {
    value: "1803",
    label: "Humboldt Universität Berlin",
    website: "http://www.hu-berlin.de/",
  },
  {
    value: "1804",
    label: "HWP - Hamburger Universität für Wirtschaft und Politik",
    website: "http://www.hwp-hamburg.de/",
  },
  {
    value: "1805",
    label: "Internationale Fachhochschule Aalen",
    website: "http://www.international.fh-aalen.de/",
  },
  {
    value: "1806",
    label: "Internationale Fachhochschule Bad Honnef",
    website: "http://www.fh-bad-honnef.de/",
  },
  {
    value: "1807",
    label: "Internationales Hochschulinstitut Zittau",
    website: "http://www.ihi-zittau.de/",
  },
  {
    value: "1808",
    label: "International School of Management ISM Dortmund",
    website: "http://www.ism-dortmund.de/",
  },
  {
    value: "1809",
    label: "International School of New Media, University of Lübeck",
    website: "http://www.isnm.de/",
  },
  {
    value: "1810",
    label: "International University in Germany",
    website: "http://www.i-u.de/",
  },
  {
    value: "1811",
    label: "Jacobs University Bremen",
    website: "http://www.jacobs-university.de/",
  },
  {
    value: "1812",
    label: "Johannes-Gutenberg Universität Mainz",
    website: "http://www.uni-mainz.de/",
  },
  {
    value: "1813",
    label: "Johann Wolfgang Goethe Universität Frankfurt am Main",
    website: "http://www.uni-frankfurt.de/",
  },
  {
    value: "1814",
    label: "Justus Liebig Universität Gießen",
    website: "http://www.uni-giessen.de/",
  },
  {
    value: "1815",
    label: "Karlshochschule International University",
    website: "http://www.karlshochschule.de/",
  },
  {
    value: "1816",
    label: "Katholische Fachhochschule Berlin (KFB)",
    website: "http://www.kfb-berlin.de/",
  },
  {
    value: "1817",
    label:
      "Katholische Fachhochschule Freiburg, Hochschule für Sozialwesen, Religionspädagogik und Pflege",
    website: "http://www.kfh-Freiburg.de/",
  },
  {
    value: "1818",
    label: "Katholische Fachhochschule Mainz",
    website: "http://www.kfh-mainz.de/",
  },
  {
    value: "1819",
    label: "Katholische Fachhochschule Norddeutschland",
    website: "http://www.kath-fh-nord.de/",
  },
  {
    value: "1820",
    label: "Katholische Fachhochschule Nordrhein-Westfalen",
    website: "http://www.kfhnw.de/",
  },
  {
    value: "1821",
    label: "Katholische Hochschule für Soziale Arbeit Saarbrücken",
    website: "http://www.khsa.de/",
  },
  {
    value: "1822",
    label: "Katholische Stiftungsfachhochschule München",
    website: "http://www.ksfh.de/",
  },
  {
    value: "1823",
    label: "Katholische Universität Eichstätt",
    website: "http://www.ku-eichstaett.de/",
  },
  {
    value: "1824",
    label: "Kirchliche Hochschule Bethel",
    website: "http://www.bethel.de/kiho/",
  },
  {
    value: "1825",
    label: "Kirchliche Hochschule Wuppertal",
    website: "http://www.uni-wuppertal.de/inst/kiho/",
  },
  {
    value: "1826",
    label: "Kunstakademie Düsseldorf.",
    website: "http://www.kunstakademie-duesseldorf.de/",
  },
  {
    value: "1827",
    label: "Kunsthochschule Berlin-Weissensee, Hochschule für Gestaltung ",
    website: "http://www.kh-berlin.de/",
  },
  {
    value: "1828",
    label: "Ludwig-Maximilians-Universität München",
    website: "http://www.uni-muenchen.de/",
  },
  {
    value: "1829",
    label: "Lutherische Theologische Hochschule Oberursel",
    website: "http://www.selk.de/LThH/",
  },
  {
    value: "1830",
    label: "Märkische Fachhochschule Iserlohn",
    website: "http://www.mfh-iserlohn.de/",
  },
  {
    value: "1831",
    label: "Martin-Luther Universität Halle-Wittenberg",
    website: "http://www.uni-halle.de/",
  },
  {
    value: "1832",
    label: "Medizinische Hochschule Hannover",
    website: "http://www.mh-hannover.de/",
  },
  {
    value: "1833",
    label: "Medizinische Universität Lübeck",
    website: "http://www.mu-luebeck.de/",
  },
  {
    value: "1834",
    label: "Merkur Internationale FH Karlsruhe",
    website: "http://www.merkur-fh.org/",
  },
  {
    value: "1835",
    label: "Merz Akademie, Hochschule für Gestaltung Stuttgart",
    website: "http://www.merz-akademie.de/",
  },
  {
    value: "1836",
    label: "Muthesius-Hochschule, Fachhochschule für Kunst und Gestaltung",
    website: "http://www.muthesius.de/",
  },
  {
    value: "1837",
    label:
      "Nordakademie, Staatlich anerkannte private Fachhochschule mit dualen Studiengängen",
    website: "http://www.nordakademie.de/",
  },
  {
    value: "1838",
    label: "Northern Institute of Technology Management (NIT)",
    website: "http://www.nithh.de/",
  },
  {
    value: "1839",
    label: "Otto-Friedrich Universität Bamberg",
    website: "http://www.uni-bamberg.de/",
  },
  {
    value: "1840",
    label: "Otto-von-Guericke Universität Magdeburg",
    website: "http://www.uni-magdeburg.de/",
  },
  {
    value: "1841",
    label: "Pädagogische Hochschule Erfurt/Mühlhausen",
    website: "http://www.ph-erfurt.de/",
  },
  {
    value: "1842",
    label: "Pädagogische Hochschule Freiburg",
    website: "http://www.ph-freiburg.de/",
  },
  {
    value: "1843",
    label: "Pädagogische Hochschule Heidelberg",
    website: "http://www.ph-heidelberg.de/",
  },
  {
    value: "1844",
    label: "Pädagogische Hochschule Karlsruhe",
    website: "http://www.ph-karlsruhe.de/",
  },
  {
    value: "1845",
    label: "Pädagogische Hochschule Ludwigsburg",
    website: "http://www.ph-ludwigsburg.de/",
  },
  {
    value: "1846",
    label: "Pädagogische Hochschule Schwäbisch Gmünd",
    website: "http://www.ph-gmuend.de/",
  },
  {
    value: "1847",
    label: "Pädagogische Hochschule Weingarten",
    website: "http://www.ph-weingarten.de/",
  },
  {
    value: "1848",
    label: "Phillips-Universität Marburg",
    website: "http://www.uni-marburg.de/",
  },
  {
    value: "1849",
    label: "Philosophisch-Theologische Hochschule der Salesianer Don Boscos",
    website: "http://www.pth-bb.de/",
  },
  {
    value: "1850",
    label: "Philosophisch-Theologische Hochschule Münster",
    website: "http://www.pth-muenster.de/",
  },
  {
    value: "1851",
    label: "Philosophisch-Theologische Hochschule Sankt Georgen",
    website: "http://www.st-georgen.uni-frankfurt.de/",
  },
  {
    value: "1852",
    label: "Philosophisch-Theologische Hochschule SVD Sankt Augustin",
    website: "http://www.philtheol-augustin.de/",
  },
  {
    value: "1853",
    label:
      "Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)",
    website: "http://www.pthv.de/",
  },
  {
    value: "1854",
    label:
      "Philosophisch-Theologisches Studium Erfurt, Staatlich anerkannte Wissenschaftliche Hochschule",
    website: "http://www.uni-erfurt.de/phil-theol/",
  },
  {
    value: "1855",
    label: "Popakademie Baden-Württemberg",
    website: "http://www.popakademie.de/",
  },
  {
    value: "1856",
    label: "Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz",
    website: "http://www.fhwt.de/",
  },
  {
    value: "1857",
    label: "Private Fachhochschule Göttingen",
    website: "http://www.pfh-goettingen.de/",
  },
  {
    value: "1858",
    label: "Private FernFachhochschule Darmstadt",
    website: "http://www.privatfh-da.de/",
  },
  {
    value: "1859",
    label: "Private Hanseuniversität",
    website: "http://www.hanseuni.de/",
  },
  {
    value: "1860",
    label: "Private Universität Witten/Herdecke",
    website: "http://www.uni-wh.de/",
  },
  {
    value: "1861",
    label: "Rheinische Fachhochschule Köln",
    website: "http://www.rfh-koeln.de/",
  },
  {
    value: "1862",
    label: "Rheinische Friedrich-Wilhelms Universität Bonn",
    website: "http://www.uni-bonn.de/",
  },
  {
    value: "1863",
    label: "Rheinisch Westfälische Technische Hochschule Aachen",
    website: "http://www.rwth-aachen.de/",
  },
  {
    value: "1864",
    label: "Ruhr-Universität Bochum",
    website: "http://www.ruhr-uni-bochum.de/",
  },
  {
    value: "1865",
    label: "Ruprecht-Karls-Universität Heidelberg",
    website: "http://www.uni-heidelberg.de/",
  },
  {
    value: "1866",
    label: "Schiller International University, Heidelberg",
    website: "http://www.siu-heidelberg.de/",
  },
  {
    value: "1867",
    label: "SRH University of Applied Sciences",
    website: "http://www.srh-berlin.de/",
  },
  {
    value: "1868",
    label: "Staatlich anerkannte Fachhochschule für Kunsttherapie",
    website: "http://www.fhkt.de/",
  },
  {
    value: "1869",
    label: "Staatliche Hochschule für Musik",
    website: "http://www.mh-trossingen.de/",
  },
  {
    value: "1870",
    label: "Steinbeis-Hochschule-Berlin",
    website: "http://www.stw.de/shb/",
  },
  {
    value: "1871",
    label: "Technische Fachhochschule Berlin",
    website: "http://www.tfh-berlin.de/",
  },
  {
    value: "1872",
    label:
      "Technische Fachhochschule Georg Agricola für Rohstoff, Energie und, Umwelt zu Bochum",
    website: "http://www.tfh-bochum.de/",
  },
  {
    value: "1873",
    label: "Technische Fachhochschule Wildau",
    website: "http://www.tfh-wildau.de/",
  },
  {
    value: "1874",
    label: "Technische Universität Bergakademie Freiberg",
    website: "http://www.tu-freiberg.de/",
  },
  {
    value: "1875",
    label: "Technische Universität Berlin",
    website: "http://www.tu-berlin.de/",
  },
  {
    value: "1876",
    label: "Technische Universität Carolo-Wilhelmina Braunschweig",
    website: "http://www.tu-bs.de/",
  },
  {
    value: "1877",
    label: "Technische Universität Chemnitz",
    website: "http://www.tu-chemnitz.de/",
  },
  {
    value: "1878",
    label: "Technische Universität Clausthal",
    website: "http://www.tu-clausthal.de/",
  },
  {
    value: "1879",
    label: "Technische Universität Darmstadt",
    website: "http://www.tu-darmstadt.de/",
  },
  {
    value: "1880",
    label: "Technische Universität Dresden",
    website: "http://www.tu-dresden.de/",
  },
  {
    value: "1881",
    label: "Technische Universität Hamburg-Harburg",
    website: "http://www.tu-harburg.de/",
  },
  {
    value: "1882",
    label: "Technische Universität Ilmenau",
    website: "http://www.tu-ilmenau.de/",
  },
  {
    value: "1883",
    label: "Technische Universität München",
    website: "http://www.tu-muenchen.de/",
  },
  {
    value: "1884",
    label: "Theologische Fakultät Paderborn",
    website: "http://www.paderborn.de/theofak/",
  },
  {
    value: "1885",
    label: "Theologische Fakultät Trier",
    website: "http://www.uni-trier.de/uni/theo/",
  },
  {
    value: "1886",
    label: "Theologische Hochschule Friedensau",
    website: "http://www.thh-friedensau.de/",
  },
  {
    value: "1887",
    label: "Tierärztliche Hochschule Hannover",
    website: "http://www.tiho-hannover.de/",
  },
  {
    value: "1888",
    label: "Universität Augsburg",
    website: "http://www.uni-augsburg.de/",
  },
  {
    value: "1889",
    label: "Universität Bayreuth",
    website: "http://www.uni-bayreuth.de/",
  },
  {
    value: "1890",
    label: "Universität Bielefeld",
    website: "http://www.uni-bielefeld.de/",
  },
  {
    value: "1891",
    label: "Universität Bremen",
    website: "http://www.uni-bremen.de/",
  },
  {
    value: "1892",
    label: "Universität der Bundeswehr Hamburg",
    website: "http://www.unibw-hamburg.de/",
  },
  {
    value: "1893",
    label: "Universität der Bundeswehr München",
    website: "http://www.unibw-muenchen.de/",
  },
  {
    value: "1894",
    label: "Universität des Saarlandes",
    website: "http://www.uni-saarland.de/",
  },
  {
    value: "1895",
    label: "Universität Dortmund",
    website: "http://www.uni-dortmund.de/",
  },
  {
    value: "1896",
    label: "Universität Duisburg-Essen",
    website: "http://www.uni-duisburg-essen.de/",
  },
  {
    value: "1897",
    label: "Universität Erfurt",
    website: "http://www.uni-erfurt.de/",
  },
  {
    value: "1898",
    label: "Universität Flensburg",
    website: "http://www.uni-flensburg.de/",
  },
  {
    value: "1899",
    label: "Universität Fridericana Karlsruhe (Technische Hochschule)",
    website: "http://www.uni-karlsruhe.de/",
  },
  {
    value: "1900",
    label: "Universität Hamburg",
    website: "http://www.uni-hamburg.de/",
  },
  {
    value: "1901",
    label: "Universität Hannover",
    website: "http://www.uni-hannover.de/",
  },
  {
    value: "1902",
    label: "Universität Hildesheim",
    website: "http://www.uni-hildesheim.de/",
  },
  {
    value: "1903",
    label: "Universität Hohenheim",
    website: "http://www.uni-hohenheim.de/",
  },
  {
    value: "1904",
    label: "Universität Kaiserslautern",
    website: "http://www.uni-kl.de/",
  },
  {
    value: "1905",
    label: "Universität Kassel",
    website: "http://www.uni-kassel.de/",
  },
  {
    value: "1906",
    label: "Universität Koblenz-Landau",
    website: "http://www.uni-koblenz-landau.de/",
  },
  {
    value: "1907",
    label: "Universität Köln",
    website: "http://www.uni-koeln.de/",
  },
  {
    value: "1908",
    label: "Universität Konstanz",
    website: "http://www.uni-konstanz.de/",
  },
  {
    value: "1909",
    label: "Universität Leipzig",
    website: "http://www.uni-leipzig.de/",
  },
  {
    value: "1910",
    label: "Universität Lüneburg",
    website: "http://www.uni-lueneburg.de/",
  },
  {
    value: "1911",
    label: "Universität Mannheim",
    website: "http://www.uni-mannheim.de/",
  },
  {
    value: "1912",
    label: "Universität Osnabrück",
    website: "http://www.uni-osnabrueck.de/",
  },
  {
    value: "1913",
    label: "Universität Paderborn",
    website: "http://www.uni-paderborn.de/",
  },
  {
    value: "1914",
    label: "Universität Passau",
    website: "http://www.uni-passau.de/",
  },
  {
    value: "1915",
    label: "Universität Potsdam",
    website: "http://www.uni-potsdam.de/",
  },
  {
    value: "1916",
    label: "Universität Regensburg",
    website: "http://www.uni-regensburg.de/",
  },
  {
    value: "1917",
    label: "Universität Rostock",
    website: "http://www.uni-rostock.de/",
  },
  {
    value: "1918",
    label: "Universität Siegen",
    website: "http://www.uni-siegen.de/",
  },
  {
    value: "1919",
    label: "Universität Stuttgart",
    website: "http://www.uni-stuttgart.de/",
  },
  {
    value: "1920",
    label: "Universität Trier",
    website: "http://www.uni-trier.de/",
  },
  {
    value: "1921",
    label: "Universität Ulm",
    website: "http://www.uni-ulm.de/",
  },
  {
    value: "1922",
    label: "Westfälische Wilhelms-Universität Münster",
    website: "http://www.uni-muenster.de/",
  },
  {
    value: "1923",
    label: "Westsächsische Hochschule Zwickau (FH)",
    website: "http://www.fh-zwickau.de/",
  },
  {
    value: "1924",
    label:
      "Wissenschaftliche Hochschule für Unternehmensführung, Otto-Beisheim Hochschule",
    website: "http://www.whu-koblenz.de/",
  },
  {
    value: "1925",
    label: "Université de Djibouti",
    website: "http://www.univ.edu.dj/",
  },
  {
    value: "1926",
    label: "Aalborg Business College",
    website: "http://www.ah.dk/",
  },
  {
    value: "1927",
    label: "Aalborg University",
    website: "http://www.auc.dk/",
  },
  {
    value: "1928",
    label: "Aarhus School of Architecture",
    website: "http://www.a-aarhus.dk/",
  },
  {
    value: "1929",
    label: "Aarhus School of Business",
    website: "http://www.hha.dk/",
  },
  {
    value: "1930",
    label: "Aarhus Technical College",
    website: "http://www.ats.dk/",
  },
  {
    value: "1931",
    label: "Aarhus University",
    website: "http://www.au.dk/",
  },
  {
    value: "1932",
    label: "Copenhagen Business School",
    website: "http://www.cbs.dk/",
  },
  {
    value: "1933",
    label: "Copenhagen University",
    website: "http://www.ku.dk/",
  },
  {
    value: "1934",
    label: "Danish Business Academy",
    website: "http://www.ddea.dk/",
  },
  {
    value: "1935",
    label: "Danish University of Education",
    website: "http://www.dpu.dk/",
  },
  {
    value: "1936",
    label: "Engineering College of Aarhus",
    website: "http://www.iha.dk/",
  },
  {
    value: "1937",
    label: "Engineering College of Copenhagen",
    website: "http://www.ihk.dk/",
  },
  {
    value: "1938",
    label: "International Business School of Scandinavia",
    website: "http://www.ibss.eu/",
  },
  {
    value: "1939",
    label: "International People's College",
    website: "http://www.ipc.dk/",
  },
  {
    value: "1940",
    label: "IT University of Copenhagen",
    website: "http://www.itu.dk/",
  },
  {
    value: "1941",
    label: "Knightsbridge University",
    website: "http://www.knightsbridgeuniversity.com/",
  },
  {
    value: "1942",
    label: "Lutheran School Of Theology In Aarhus",
    website: "http://www.teologi.dk/",
  },
  {
    value: "1943",
    label: "Odense University",
    website: "http://www.ou.dk/",
  },
  {
    value: "1944",
    label: "Roskilde University",
    website: "http://www.ruc.dk/",
  },
  {
    value: "1945",
    label: "Royal Academy of Music",
    website: "http://www.musik-kons.dk/",
  },
  {
    value: "1946",
    label: "Royal Danish Academy of Fine Arts, School of Architecture",
    website: "http://www.karch.dk/",
  },
  {
    value: "1947",
    label: "Royal Danish Academy of Fine Arts, School of Visual Art",
    website: "http://www.kunstakademiet.dk/",
  },
  {
    value: "1948",
    label: "Royal Danish Academy of Music",
    website: "http://www.dkdm.dk/",
  },
  {
    value: "1949",
    label: "Royal Danish School of Educational Sciences",
    website: "http://www.dlh.dk/",
  },
  {
    value: "1950",
    label: "Royal Danish School of Pharmacy",
    website: "http://www.dfh.dk/",
  },
  {
    value: "1951",
    label: "Royal School of Library and Information Science",
    website: "http://www.db.dk/",
  },
  {
    value: "1952",
    label: "Royal Veterinary and Agricultural University",
    website: "http://www.kvl.dk/",
  },
  {
    value: "1953",
    label: "Southern Denmark Business School",
    website: "http://www.hhs.dk/",
  },
  {
    value: "1954",
    label: "Technical University of Denmark",
    website: "http://www.dtu.dk/",
  },
  {
    value: "1955",
    label: "Tietgen Business College",
    website: "http://www.tietgen.dk/",
  },
  {
    value: "1956",
    label: "University of Southern Denmark",
    website: "http://www.sdu.dk/",
  },
  {
    value: "1957",
    label: "Ballsbridge University ",
    website: "http://www.acedu.org/",
  },
  {
    value: "1958",
    label: "Dominica State College",
    website: "http://www.dsc.dm/",
  },
  {
    value: "1959",
    label: "New World University",
    website: "http://www.newworld.ac/",
  },
  {
    value: "1960",
    label: "Ross University Caribbean School of Medicine",
    website: "http://www.rossu.edu/medical-school/",
  },
  {
    value: "1961",
    label: "Instituto Tecnológico de Santo Domingo",
    website: "http://www.intec.edu.do/",
  },
  {
    value: "1962",
    label: "Pontificia Universidad Católica Madre y Maestra (PUCMM)",
    website: "http://www.pucmm.edu.do/",
  },
  {
    value: "1963",
    label: "Universidad Abierta Para Adultos",
    website: "http://www.uapa.edu.do/",
  },
  {
    value: "1964",
    label: "Universidad Adventista Dominicana",
    website: "http://www.unad.edu.do/",
  },
  {
    value: "1965",
    label: "Universidad Agroforestal Fernando A.Meriño",
    website: "http://www.uafam.edu.do/",
  },
  {
    value: "1966",
    label: "Universidad APEC",
    website: "http://www.unapec.edu.do/",
  },
  {
    value: "1967",
    label: "Universidad Autónoma de Santo Domingo",
    website: "http://www.uasd.edu.do/",
  },
  {
    value: "1968",
    label: "Universidad Católica de Santo Domingo",
    website: "http://www.ucsd.edu.do/",
  },
  {
    value: "1969",
    label: "Universidad Católica Madre y Maestra",
    website: "http://www.pucmm.edu.do/",
  },
  {
    value: "1970",
    label: "Universidad Católica Nordestana",
    website: "http://www.ucne.edu.do/",
  },
  {
    value: "1971",
    label: "Universidad Católica Tecnológica del Cibao",
    website: "http://www.ucateci.edu.do/",
  },
  {
    value: "1972",
    label: "Universidad Central del Este",
    website: "http://www.uce.edu.do/",
  },
  {
    value: "1973",
    label: "Universidad Central Dominicana de Estudio Profesionales",
    website: "http://66.98.64.31/",
  },
  {
    value: "1974",
    label: "Universidad de la Tercera Edad",
    website: "http://www.ute.edu.do/",
  },
  {
    value: "1975",
    label: "Universidad del Caribe",
    website: "http://www.unicaribe.edu.do/",
  },
  {
    value: "1976",
    label: "Universidad Dominicana O&M",
    website: "http://www.udoym.edu.do/",
  },
  {
    value: "1977",
    label: "Universidad Dominico-Americana",
    website: "http://www.icda.edu.do/espanol/unicda/",
  },
  {
    value: "1978",
    label: "Universidad Eugenio Maria de Hostos",
    website: "http://www.uniremhos.edu.do/",
  },
  {
    value: "1979",
    label: "Universidad Experimental Felix Adam",
    website: "http://www.unefa.edu.do/",
  },
  {
    value: "1980",
    label: "Universidad Federico Henríquez y Carvajal",
    website: "http://www.ufhec.edu.do/",
  },
  {
    value: "1981",
    label: "Universidad Iberoamericana",
    website: "http://www.unibe.edu.do/",
  },
  {
    value: "1982",
    label: "Universidad Interamericana",
    website: "http://www.unica.edu.do/",
  },
  {
    value: "1983",
    label: "Universidad Nacional Evangélica",
    website: "http://www.unev-rd.edu.do/",
  },
  {
    value: "1984",
    label: "Universidad Nacional Pedro Henríquez Ureña",
    website: "http://www.unphu.edu.do/",
  },
  {
    value: "1985",
    label: "Universidad Odontológica Dominicana",
    website: "http://www.uod.edu.do/",
  },
  {
    value: "1986",
    label: "Universidad Tecnológica del Sur",
    website: "http://www.utesur.edu.do/",
  },
  {
    value: "1987",
    label: "Universidad Tecnológica de Santiago",
    website: "http://www.utesa.edu/",
  },
  {
    value: "1988",
    label: "Universidad Ulises Francisco Espaillat",
    website: "http://www.ufhec.edu.do/",
  },
  {
    value: "1989",
    label: "Centre Universitaire de Jijel",
    website: "http://www.univ-jijel.dz/",
  },
  {
    value: "1990",
    label: "Centre Universitaire de Tebessa",
    website: "http://www.univ-tebessa.dz/",
  },
  {
    value: "1991",
    label: "Centre Universitaire de Tiaret",
    website: "http://www.univ-tiaret.dz/",
  },
  {
    value: "1992",
    label: "Centre Universitaire d'Oum El Bouaghi",
    website: "http://www.univ-oeb.dz/",
  },
  {
    value: "1993",
    label: "Université Abou Bekr Belkaid, Tlemcen",
    website: "http://www.univ-tlemcen.dz/",
  },
  {
    value: "1994",
    label: "Université Amar Telidji",
    website: "http://www.lagh-univ.dz/",
  },
  {
    value: "1995",
    label: "Université Badji Mokhtar",
    website: "http://www.univ-annaba.dz/",
  },
  {
    value: "1996",
    label: "Université d'Alger",
    website: "http://www.univ-alger.dz/",
  },
  {
    value: "1997",
    label: "Université d'Alger 3",
    website: "http://www.univ-alger3.dz/",
  },
  {
    value: "1998",
    label: "Université de Batna",
    website: "http://www.univ-batna.dz/",
  },
  {
    value: "1999",
    label: "Université de Béjaia",
    website: "http://www.univ-bejaia.dz/",
  },
  {
    value: "2000",
    label: "Université de Biskra",
    website: "http://www.univ-biskra.dz/",
  },
  {
    value: "2001",
    label: "Université de Blida",
    website: "http://www.univ-blida.dz/",
  },
  {
    value: "2002",
    label: "Université de Chlef",
    website: "http://www.univ-chlef.dz/",
  },
  {
    value: "2003",
    label: "Université de Guelma",
    website: "http://www.univ-guelma.dz/",
  },
  {
    value: "2004",
    label: "Université de Mostaganem",
    website: "http://www.univ-mosta.dz/",
  },
  {
    value: "2005",
    label: "Université de M'sila",
    website: "http://www.univ-msila.dz/",
  },
  {
    value: "2006",
    label: "Université de Saida",
    website: "http://www.univ-saida.dz/",
  },
  {
    value: "2007",
    label: "Université de Sétif",
    website: "http://www.univ-setif.dz/",
  },
  {
    value: "2008",
    label: "Université de Sidi-Bel-Abbès (Djillali Liabès)",
    website: "http://www.univ-sba.dz/",
  },
  {
    value: "2009",
    label: "Université de Skikda",
    website: "http://www.univ-skikda.dz/",
  },
  {
    value: "2010",
    label: "Université des Sciences et de la Technologie d'Oran",
    website: "http://www.univ-usto.dz/",
  },
  {
    value: "2011",
    label: "Université des Sciences et de la Technologie Houari Boumediène",
    website: "http://www.usthb.dz/",
  },
  {
    value: "2012",
    label: "Universite des Sciences Islamiques de Constantine",
    website: "http://www.univ-emir.dz/",
  },
  {
    value: "2013",
    label: "Université d'Oran Es-Senia",
    website: "http://www.univ-oran.dz/",
  },
  {
    value: "2014",
    label: "Université Dr Tahar Moulay",
    website: "http://www.univ-saida.dz/",
  },
  {
    value: "2015",
    label: "Université Kasdi Merbah Ouargla",
    website: "http://www.univ-ouargla.dz/",
  },
  {
    value: "2016",
    label: "Université Mentouri Constantine",
    website: "http://www.umc.edu.dz/",
  },
  {
    value: "2017",
    label: "Université M'hamed Bouguerra de Boumerdes",
    website: "http://www.umbb.dz/",
  },
  {
    value: "2018",
    label: "Université Mouloud Mammeri de Tizi Ouzou",
    website: "http://www.ummto.dz/",
  },
  {
    value: "2019",
    label: "Brookdale Community College",
    website: "http://www.brookdale.edu.ec/",
  },
  {
    value: "2020",
    label: "Escuela Politécnica de Chimborazo",
    website: "http://www.espoch.edu/",
  },
  {
    value: "2021",
    label: "Escuela Politécnica del Ejercito",
    website: "http://www.espe.edu.ec",
  },
  {
    value: "2022",
    label: "Escuela Politécnica Nacional",
    website: "http://www.epn.edu.ec/",
  },
  {
    value: "2023",
    label: "Escuela Superior Politécnica del Litoral",
    website: "http://www.espol.edu.ec/",
  },
  {
    value: "2024",
    label: "Pontificia Universidad Católica del Ecuador",
    website: "http://www.puce.edu.ec/",
  },
  {
    value: "2025",
    label: "Universidad Agraria del Ecuador",
    website: "http://www.uagraria.edu.ec/",
  },
  {
    value: "2026",
    label: "Universidad Andina Simón Bolívar",
    website: "http://www.uasb.edu.ec/",
  },
  {
    value: "2027",
    label: "Universidad Casa Grande",
    website: "http://www.casagrande.edu.ec/",
  },
  {
    value: "2028",
    label: "Universidad Catolica de Azogues",
    website: "http://www.ucaazo.edu.ec/",
  },
  {
    value: "2029",
    label: "Universidad Católica de Cuenca",
    website: "http://www.ucacue.edu.ec/",
  },
  {
    value: "2030",
    label: "Universidad Católica de Santiago de Guayaquil",
    website: "http://www.ucsg.edu.ec/",
  },
  {
    value: "2031",
    label: "Universidad Central del Ecuador",
    website: "http://www.ucentral.edu.ec/",
  },
  {
    value: "2032",
    label: "Universidad de Cuenca",
    website: "http://www.ucuenca.edu.ec/",
  },
  {
    value: "2033",
    label: "Universidad de Especialidades del Espíritu Santo",
    website: "http://www.uees.edu.ec/",
  },
  {
    value: "2034",
    label: "Universidad de Especialidades Turísticas ",
    website: "http://www.uct.edu.ec/",
  },
  {
    value: "2035",
    label: "Universidad de Guayaquil",
    website: "http://www.ug.edu.ec/",
  },
  {
    value: "2036",
    label: "Universidad del Azuay",
    website: "http://www.uazuay.edu.ec/",
  },
  {
    value: "2037",
    label: "Universidad del Pacifico",
    website: "http://www.upacifico.edu.ec/",
  },
  {
    value: "2038",
    label: "Universidad Estatal de Bolívar",
    website: "http://www.ueb.isfun.net/",
  },
  {
    value: "2039",
    label: "Universidad Internacional del Ecuador",
    website: "http://www.internacional.edu.ec/",
  },
  {
    value: "2040",
    label: "Universidad Internacional SEK, Quito",
    website: "http://www.sek.edu/html/ecuador.html",
  },
  {
    value: "2041",
    label: 'Universidad Laica "Eloy Alfaro" de Manabi',
    website: "http://www.uleam.edu.ec/",
  },
  {
    value: "2042",
    label: 'Universidad Laica "Vicente Rocafuerte" de Guayaquil',
    website: "http://www.ulaicavr.com/",
  },
  {
    value: "2043",
    label: "Universidad Nacional de Chimborazo",
    website: "http://www.unach.edu.ec/",
  },
  {
    value: "2044",
    label: "Universidad Nacional de Loja",
    website: "http://www.unl.edu.ec/",
  },
  {
    value: "2045",
    label: "Universidad Politécnica Salesiana",
    website: "http://www.ups.edu.ec/",
  },
  {
    value: "2046",
    label: "Universidad San Francisco de Quito",
    website: "http://www.usfq.edu.ec/",
  },
  {
    value: "2047",
    label: "Universidad Santa María Campus Guayaquil",
    website: "http://www.usm.edu.ec/",
  },
  {
    value: "2048",
    label: "Universidad Técnica de Ambato",
    website: "http://www.uta.edu.ec/",
  },
  {
    value: "2049",
    label: "Universidad Técnica de Babahoyo",
    website: "http://www.utb.edu.ec/",
  },
  {
    value: "2050",
    label: "Universidad Técnica de Cotopaxi",
    website: "http://www.utc.edu.ec/",
  },
  {
    value: "2051",
    label: 'Universidad Técnica de Esmeraldas "Luis Vargas Torres"',
    website: "http://www.utelvt.edu.ec/",
  },
  {
    value: "2052",
    label: "Universidad Técnica del Norte",
    website: "http://www.utn.edu.ec/",
  },
  {
    value: "2053",
    label: "Universidad Técnica de Machala",
    website: "http://www.utmachala.edu.ec/",
  },
  {
    value: "2054",
    label: "Universidad Técnica de Manabí",
    website: "http://www.utm.edu.ec/",
  },
  {
    value: "2055",
    label: "Universidad Técnica Estatal de Quevedo",
    website: "http://www.uteq.edu.ec/",
  },
  {
    value: "2056",
    label: "Universidad Técnica Particular de Loja",
    website: "http://www.utpl.edu.ec/",
  },
  {
    value: "2057",
    label: "Universidad Tecnológica América",
    website: "http://www.unita.edu.ec/",
  },
  {
    value: "2058",
    label: "Universidad Tecnológica Equinoccial",
    website: "http://www.ute.edu.ec/",
  },
  {
    value: "2059",
    label: "Universidad Tecnológica Indoamérica",
    website: "http://www.indoamerica.edu.ec/",
  },
  {
    value: "2060",
    label: "Universidad Tecnologica Israel",
    website: "http://www.uisrael.ec/",
  },
  {
    value: "2061",
    label: "Universidad Tecnológica San Antonio de Machala",
    website: "http://www.utsam.edu.ec/",
  },
  {
    value: "2062",
    label: "Estonian Academy of Arts",
    website: "http://www.artun.ee/",
  },
  {
    value: "2063",
    label: "Estonian Academy of Music and Theatre",
    website: "http://www.ema.edu.ee/",
  },
  {
    value: "2064",
    label: "Estonian Academy of Security Sciences",
    website: "http://www.sisekaitse.ee/",
  },
  {
    value: "2065",
    label: "Estonian Business School",
    website: "http://www.ebs.ee/",
  },
  {
    value: "2066",
    label: "Estonian University of Life Sciences",
    website: "http://www.emu.ee/",
  },
  {
    value: "2067",
    label: "Tallinn University",
    website: "http://www.tlu.ee/",
  },
  {
    value: "2068",
    label: "Tallinn University of Technology",
    website: "http://www.ttu.ee/",
  },
  {
    value: "2069",
    label: "Tartu Health Care College",
    website: "http://www.nooruse.ee/",
  },
  {
    value: "2070",
    label: "University of Applied Sciences",
    website: "http://www.tktk.ee/",
  },
  {
    value: "2071",
    label: "University of Tartu",
    website: "http://www.ut.ee/",
  },
  {
    value: "2072",
    label: "Ain Shams University",
    website: "http://www.shams.edu.eg/",
  },
  {
    value: "2073",
    label: "Akhbar El Yom Academy",
    website: "http://www.akhbaracademy.edu.eg/",
  },
  {
    value: "2074",
    label: "Al Azhar University",
    website: "http://www.azhar.edu.eg/",
  },
  {
    value: "2075",
    label: "Alexandria University",
    website: "http://www.alex.edu.eg/",
  },
  {
    value: "2076",
    label: "American University in Cairo",
    website: "http://www.aucegypt.edu/",
  },
  {
    value: "2077",
    label: "Arab Academy for Science & Technology",
    website: "http://www.aast.edu/",
  },
  {
    value: "2078",
    label: "Arab Open University",
    website: "http://www.aou.edu.eg/",
  },
  {
    value: "2079",
    label: "Assiut University",
    website: "http://www.aun.edu.eg/",
  },
  {
    value: "2080",
    label: "Benha University",
    website: "http://www.bu.edu.eg/",
  },
  {
    value: "2081",
    label: "Beni Suef University",
    website: "http://www.bsu.edu.eg/",
  },
  {
    value: "2082",
    label: "Cairo University",
    website: "http://www.cu.edu.eg/",
  },
  {
    value: "2083",
    label: "Damanhour University",
    website: "http://www.damanhour.edu.eg/",
  },
  {
    value: "2084",
    label: "Damietta University",
    website: "http://www.du.edu.eg/",
  },
  {
    value: "2085",
    label: "Delta University",
    website: "http://www.deltauniv.edu.eg/",
  },
  {
    value: "2086",
    label: "El Shorouk Academy",
    website: "http://www.elshoroukacademy.edu.eg/",
  },
  {
    value: "2087",
    label: "Fayoum University",
    website: "http://www.fayoum.edu.eg/",
  },
  {
    value: "2088",
    label: "Future University",
    website: "http://www.futureuniversity.edu.eg/",
  },
  {
    value: "2089",
    label: "German University in Cairo",
    website: "http://www.guc.edu.eg/",
  },
  {
    value: "2090",
    label: "Helwan University",
    website: "http://www.helwan.edu.eg/",
  },
  {
    value: "2091",
    label: "Higher Technological Institute",
    website: "http://www.hti.edu.eg/",
  },
  {
    value: "2092",
    label: "Kafr El-Sheikh University",
    website: "http://www.kfs.edu.eg/",
  },
  {
    value: "2093",
    label: "Mansoura University",
    website: "http://www.mans.edu.eg/",
  },
  {
    value: "2094",
    label: "Menoufia University",
    website: "http://www.menofia.edu.eg/",
  },
  {
    value: "2095",
    label: "Military Technical College",
    website: "http://www.mtc.edu.eg/",
  },
  {
    value: "2096",
    label: "Minia University",
    website: "http://www.minia.edu.eg/",
  },
  {
    value: "2097",
    label: "Misr International University",
    website: "http://www.miuegypt.edu.eg/",
  },
  {
    value: "2098",
    label: "Misr University for Sience and Technology",
    website: "http://www.must.edu.eg/",
  },
  {
    value: "2099",
    label: "Modern Acadmy",
    website: "http://www.modern-academy.edu.eg/",
  },
  {
    value: "2100",
    label: "Modern Sciences & Arts University",
    website: "http://www.msa.eun.eg/",
  },
  {
    value: "2101",
    label: "Modern University For Technology and Information",
    website: "http://www.mti.edu.eg/",
  },
  {
    value: "2102",
    label: "Nile University",
    website: "http://www.nileu.edu.eg/",
  },
  {
    value: "2103",
    label: "October 6 university",
    website: "http://www.o6u.edu.eg/",
  },
  {
    value: "2104",
    label: "Pharos International University",
    website: "http://www.pua.edu.eg/",
  },
  {
    value: "2105",
    label: "Port Said University",
    website: "http://www.psu.edu.eg/",
  },
  {
    value: "2106",
    label: "Sadat Academy for Management Sciences",
    website: "http://www.sadatacademy.edu.eg/",
  },
  {
    value: "2107",
    label: "Sinai University",
    website: "http://www.su.edu.eg/",
  },
  {
    value: "2108",
    label: "Sohag University",
    website: "http://www.sohag-univ.edu.eg/",
  },
  {
    value: "2109",
    label: "South Valley University",
    website: "http://www.svu.edu.eg/",
  },
  {
    value: "2110",
    label: "Suez Canal University",
    website: "http://www.suez.edu.eg/",
  },
  {
    value: "2111",
    label: "Tanta University",
    website: "http://www.tanta.edu.eg/",
  },
  {
    value: "2112",
    label: "Université Française d'Égypte",
    website: "http://www.ufe.edu.eg/",
  },
  {
    value: "2113",
    label: "Université Senghor d'Alexandrie",
    website: "http://www.usenghor-francophonie.org/",
  },
  {
    value: "2114",
    label: "University of Sadat City",
    website: "http://www.usc.edu.eg/",
  },
  {
    value: "2115",
    label: "Zagazig University",
    website: "http://www.zu.edu.eg/",
  },
  {
    value: "2116",
    label: "Eritrea Institute of Technology",
    website: "http://www.eit.edu.er/",
  },
  {
    value: "2117",
    label: "Barcelona Graduate School of Economics",
    website: "http://www.barcelonagse.eu/",
  },
  {
    value: "2118",
    label: "Bircham International University",
    website: "http://www.bircham.edu/",
  },
  {
    value: "2119",
    label: "Centro Universitario Villanueva",
    website: "http://www.villanueva.edu/",
  },
  {
    value: "2120",
    label: "Escuela Superior de Gestion Comercial y Marketing (ESIC)",
    website: "http://www.esic.es/",
  },
  {
    value: "2121",
    label: "Global Business School Barcelona",
    website: "http://www.global-business-school.org/",
  },
  {
    value: "2122",
    label: "IE University",
    website: "http://www.ie.edu/",
  },
  {
    value: "2123",
    label: "Iniciativa Universidad del Atlantico",
    website: "http://www.universidadatlantico.org/",
  },
  {
    value: "2124",
    label: "International University of Southern Europe",
    website: "http://www.iuse.edu.es/",
  },
  {
    value: "2125",
    label: "La Salle Universities - International Programmes",
    website: "http://www.lasalleuniversities.net/",
  },
  {
    value: "2126",
    label: "Mondragon Univertsitatea",
    website: "http://www.muni.es/",
  },
  {
    value: "2127",
    label: "Schiller International University, Madrid",
    website: "http://www.schillermadrid.edu/",
  },
  {
    value: "2128",
    label: "Universidad Abierta Interactiva",
    website: "http://www.uniactiva.com/",
  },
  {
    value: "2129",
    label: "Universidad Alfonso X El Sabio",
    website: "http://www.uax.es/",
  },
  {
    value: "2130",
    label: "Universidad Antonio de Nebrija",
    website: "http://www.unnet.es/",
  },
  {
    value: "2131",
    label: "Universidad Autónoma de Madrid",
    website: "http://www.uam.es/",
  },
  {
    value: "2132",
    label: "Universidad Cardenal Herrera-CEU",
    website: "http://www.uchceu.es/",
  },
  {
    value: "2133",
    label: "Universidad Carlos III de Madrid",
    website: "http://www.uc3m.es/",
  },
  {
    value: "2134",
    label: "Universidad Católica de Ávila",
    website: "http://www.ucavila.es/",
  },
  {
    value: "2135",
    label: "Universidad Católica de Valencia",
    website: "http://www.ucv.es/",
  },
  {
    value: "2136",
    label: "Universidad Católica San Antonio",
    website: "http://www.ucam.edu/",
  },
  {
    value: "2137",
    label: "Universidad Complutense de Madrid",
    website: "http://www.ucm.es/",
  },
  {
    value: "2138",
    label: "Universidad de Alcalá de Henares",
    website: "http://www.uah.es/",
  },
  {
    value: "2139",
    label: "Universidad de Alicante",
    website: "http://www.ua.es/",
  },
  {
    value: "2140",
    label: "Universidad de Almería",
    website: "http://www.ualm.es/",
  },
  {
    value: "2141",
    label: "Universidad de Burgos",
    website: "http://www.ubu.es/",
  },
  {
    value: "2142",
    label: "Universidad de Cádiz",
    website: "http://www.uca.es/",
  },
  {
    value: "2143",
    label: "Universidad de Cantabria",
    website: "http://www.unican.es/",
  },
  {
    value: "2144",
    label: "Universidad de Castilla La Mancha",
    website: "http://www.uclm.es/",
  },
  {
    value: "2145",
    label: "Universidad de Córdoba",
    website: "http://www.uco.es/",
  },
  {
    value: "2146",
    label: "Universidad de Deusto",
    website: "http://www.deusto.es/",
  },
  {
    value: "2147",
    label: "Universidad de Extremadura",
    website: "http://www.unex.es/",
  },
  {
    value: "2148",
    label: "Universidad de Gerona",
    website: "http://www.udg.es/",
  },
  {
    value: "2149",
    label: "Universidad de Granada",
    website: "http://www.ugr.es/",
  },
  {
    value: "2150",
    label: "Universidad de Huelva",
    website: "http://www.uhu.es/",
  },
  {
    value: "2151",
    label: "Universidad de Jaén",
    website: "http://www.ujaen.es/",
  },
  {
    value: "2152",
    label: "Universidad de La Coruña",
    website: "http://www.udc.es/",
  },
  {
    value: "2153",
    label: "Universidad de La Laguna",
    website: "http://www.ull.es/",
  },
  {
    value: "2154",
    label: "Universidad de La Rioja",
    website: "http://www.unirioja.es/",
  },
  {
    value: "2155",
    label: "Universidad de Las Palmas de Gran Canaria",
    website: "http://www.ulpgc.es/",
  },
  {
    value: "2156",
    label: "Universidad de León",
    website: "http://www.unileon.es/",
  },
  {
    value: "2157",
    label: "Universidad del País Vasco",
    website: "http://www.ehu.es/",
  },
  {
    value: "2158",
    label: "Universidad de Málaga",
    website: "http://www.uma.es/",
  },
  {
    value: "2159",
    label: "Universidad de Murcia",
    website: "http://www.um.es/",
  },
  {
    value: "2160",
    label: "Universidad de Navarra",
    website: "http://www.unav.es/",
  },
  {
    value: "2161",
    label: "Universidad de Oviedo",
    website: "http://www.uniovi.es/",
  },
  {
    value: "2162",
    label: "Universidad de Salamanca",
    website: "http://www.usal.es/",
  },
  {
    value: "2163",
    label: "Universidad de San Pablo CEU",
    website: "http://www.ceu.es/",
  },
  {
    value: "2164",
    label: "Universidad de Santiago de Compostela",
    website: "http://www.usc.es/",
  },
  {
    value: "2165",
    label: "Universidad de Sevilla",
    website: "http://www.us.es/",
  },
  {
    value: "2166",
    label: "Universidad de Valencia",
    website: "http://www.uv.es/",
  },
  {
    value: "2167",
    label: "Universidad de Valladolid",
    website: "http://www.uva.es/",
  },
  {
    value: "2168",
    label: "Universidad de Vigo",
    website: "http://www.uvigo.es/",
  },
  {
    value: "2169",
    label: "Universidad de Zaragoza",
    website: "http://www.unizar.es/",
  },
  {
    value: "2170",
    label: "Universidad Europea de Madrid",
    website: "http://www.uem.es/",
  },
  {
    value: "2171",
    label: "Universidad Francisco de Vitoria",
    website: "http://www.ufv.es/",
  },
  {
    value: "2172",
    label: "Universidad Internacional de Andalucía",
    website: "http://www.uia.es/",
  },
  {
    value: "2173",
    label:
      "Universidad Internacional de Andalucía, Sede Antonio Machado de Baeza",
    website: "http://www.uniaam.uia.es/",
  },
  {
    value: "2174",
    label: "Universidad Internacional de Andalucía, Sede La Rábida",
    website: "http://www.uniara.uia.es/",
  },
  {
    value: "2175",
    label: "Universidad Internacional Menéndez Pelayo",
    website: "http://www.uimp.es/",
  },
  {
    value: "2176",
    label: "Universidad Miguel Hernández de Elche",
    website: "http://www.umh.es/",
  },
  {
    value: "2177",
    label: "Universidad Nacional de Educación a Distancia",
    website: "http://www.uned.es/",
  },
  {
    value: "2178",
    label: "Universidad Pablo de Olavide",
    website: "http://www.upo.es/",
  },
  {
    value: "2179",
    label: "Universidad Politécnica de Cartagena",
    website: "http://www.upct.es/",
  },
  {
    value: "2180",
    label: "Universidad Politécnica de Cataluna",
    website: "http://www.upc.edu/",
  },
  {
    value: "2181",
    label: "Universidad Politécnica de Madrid",
    website: "http://www.upm.es/",
  },
  {
    value: "2182",
    label: "Universidad Politécnica de Valencia",
    website: "http://www.upv.es/",
  },
  {
    value: "2183",
    label: "Universidad Pontificia Comillas",
    website: "http://www.upco.es/",
  },
  {
    value: "2184",
    label: "Universidad Pontificia de Salamanca",
    website: "http://www.upsa.es/",
  },
  {
    value: "2185",
    label: "Universidad Pública de Navarra",
    website: "http://www.unavarra.es/",
  },
  {
    value: "2186",
    label: "Universidad Rey Juan Carlos",
    website: "http://www.urjc.es/",
  },
  {
    value: "2187",
    label: "Universitat Autónoma de Barcelona",
    website: "http://www.uab.es/",
  },
  {
    value: "2188",
    label: "Universitat de Barcelona",
    website: "http://www.ub.es/",
  },
  {
    value: "2189",
    label: "Universitat de les Illes Balears",
    website: "http://www.uib.es/",
  },
  {
    value: "2190",
    label: "Universitat de Lleida",
    website: "http://www.udl.es/",
  },
  {
    value: "2191",
    label: "Universitat de Vic",
    website: "http://www.uvic.es/",
  },
  {
    value: "2192",
    label: "Universitat Internacional de Catalunya",
    website: "http://www.uic.es/",
  },
  {
    value: "2193",
    label: "Universitat Jaume I de Castellón",
    website: "http://www.uji.es/",
  },
  {
    value: "2194",
    label: "Universitat Oberta de Catalunya",
    website: "http://www.uoc.es/",
  },
  {
    value: "2195",
    label: "Universitat Pompeu Fabra",
    website: "http://www.upf.es/",
  },
  {
    value: "2196",
    label: "Universitat Rámon Llull",
    website: "http://www.url.es/",
  },
  {
    value: "2197",
    label: "Universitat Rovira I Virgili Tarragona",
    website: "http://www.urv.es/",
  },
  {
    value: "2198",
    label: "Adama Science and Technology University",
    website: "http://www.astu.edu.et/",
  },
  {
    value: "2199",
    label: "Addis Ababa Science & Technology University",
    website: "http://www.aastu.org/",
  },
  {
    value: "2200",
    label: "Addis Ababa University",
    website: "http://www.aau.edu.et/",
  },
  {
    value: "2201",
    label: "Adigrat University",
    website: "http://www.adu.edu.et/",
  },
  {
    value: "2202",
    label: "Aksum University",
    website: "http://www.aku.edu.et/",
  },
  {
    value: "2203",
    label: "Ambo University",
    website: "http://www.ambou.edu.et/",
  },
  {
    value: "2204",
    label: "Arba Minch University",
    website: "http://www.amu.edu.et/",
  },
  {
    value: "2205",
    label: "Assosa University",
    website: "http://www.asu.edu.et/",
  },
  {
    value: "2206",
    label: "Bahir Dar University",
    website: "http://www.bdu.edu.et/",
  },
  {
    value: "2207",
    label: "Bule Hora  University",
    website: "http://www.bulehorauniversity.com/",
  },
  {
    value: "2208",
    label: "Debre Birhan University",
    website: "http://www.dbu.edu.et/",
  },
  {
    value: "2209",
    label: "Debre Markos University",
    website: "http://www.dmu.edu.et/",
  },
  {
    value: "2210",
    label: "Dilla University",
    website: "http://www.dillauniversity.edu.et/",
  },
  {
    value: "2211",
    label: "Dire Dawa University",
    website: "http://www.ddu.edu.et/",
  },
  {
    value: "2212",
    label: "Ethiopian Civil Service University",
    website: "http://www.ecsc.edu.et/",
  },
  {
    value: "2213",
    label: "Gondar University",
    website: "http://www.uog.edu.et/",
  },
  {
    value: "2214",
    label: "Haramaya University",
    website: "http://www.haramaya.edu.et/",
  },
  {
    value: "2215",
    label: "Hawassa University",
    website: "http://www.hu.edu.et/",
  },
  {
    value: "2216",
    label: "Jigjiga University",
    website: "http://www.jju.edu.et/",
  },
  {
    value: "2217",
    label: "Jimma University",
    website: "http://www.ju.edu.et/",
  },
  {
    value: "2218",
    label: "Madawalabu University",
    website: "http://www.mwu.edu.et/",
  },
  {
    value: "2219",
    label: "Mekelle Institute of Technology",
    website: "http://www.mitethiopia.edu.et/",
  },
  {
    value: "2220",
    label: "Mekelle University",
    website: "http://www.mu.edu.et/",
  },
  {
    value: "2221",
    label: "Mizan Tepi University",
    website: "http://www.mtu.edu.et/",
  },
  {
    value: "2222",
    label: "Semera University",
    website: "http://www.su.edu.et/",
  },
  {
    value: "2223",
    label: "Unity  University",
    website: "http://www.uuc.edu.et/",
  },
  {
    value: "2224",
    label: "Wolaita Sodo University",
    website: "http://www.wsu.edu.et/",
  },
  {
    value: "2225",
    label: "Wolkite University",
    website: "http://www.wku.edu.et/",
  },
  {
    value: "2226",
    label: "Wollega University",
    website: "http://www.wuni.edu.et/",
  },
  {
    value: "2227",
    label: "Wollo University",
    website: "http://www.wu.edu.et/",
  },
  {
    value: "2228",
    label: "Abo Akademi University",
    website: "http://www.abo.fi/",
  },
  {
    value: "2229",
    label: "Academy of Fine Arts",
    website: "http://www.kuva.fi/",
  },
  {
    value: "2230",
    label: "Central Ostrobothnia University of Applied Sciences",
    website: "http://www.cou.fi/",
  },
  {
    value: "2231",
    label: "Diaconia University of Applied Sciences",
    website: "http://www.diak.fi/",
  },
  {
    value: "2232",
    label: "EVTEK University of Applied Sciences",
    website: "http://www.evtek.fi/",
  },
  {
    value: "2233",
    label: "Helsinki School of Economics and Business Administration",
    website: "http://www.hkkk.fi/",
  },
  {
    value: "2234",
    label: "Helsinki University of Technology",
    website: "http://www.hut.fi/",
  },
  {
    value: "2235",
    label: "Lahti Polytechnic",
    website: "http://www.lamk.fi/",
  },
  {
    value: "2236",
    label: "Lappeenranta University of Technology",
    website: "http://www.lut.fi/",
  },
  {
    value: "2237",
    label: "Oulu Polytechnic",
    website: "http://www.oamk.fi/",
  },
  {
    value: "2238",
    label: "Rovaniemi University of Applied Sciences",
    website: "http://www.ramk.fi/",
  },
  {
    value: "2239",
    label: "Satakunta University Of Applied Scinces",
    website: "http://www.samk.fi/",
  },
  {
    value: "2240",
    label: "Scandinavian Art and Business Institute",
    website: "http://www.sabi.eu.com/",
  },
  {
    value: "2241",
    label: "Sibelius Academy",
    website: "http://www.siba.fi/",
  },
  {
    value: "2242",
    label: "South Carelian Polytechnic",
    website: "http://www.scp.fi/",
  },
  {
    value: "2243",
    label: "Swedish School of Economics and Business Administration, Finland",
    website: "http://www.shh.fi/",
  },
  {
    value: "2244",
    label: "Tampere Polytechnic",
    website: "http://www.tpu.fi/",
  },
  {
    value: "2245",
    label: "Tampere University of Technology",
    website: "http://www.tut.fi/",
  },
  {
    value: "2246",
    label: "Theatre Academy Finland",
    website: "http://www.teak.fi/",
  },
  {
    value: "2247",
    label: "Turku School of Economics and Business Administration",
    website: "http://www.tukkk.fi/",
  },
  {
    value: "2248",
    label: "University of Art and Design Helsinki",
    website: "http://www.uiah.fi/",
  },
  {
    value: "2249",
    label: "University of Helsinki",
    website: "http://www.helsinki.fi/",
  },
  {
    value: "2250",
    label: "University of Joensuu",
    website: "http://www.joensuu.fi/",
  },
  {
    value: "2251",
    label: "University of Jyväskylä",
    website: "http://www.jyu.fi/",
  },
  {
    value: "2252",
    label: "University of Kuopio",
    website: "http://www.uku.fi/",
  },
  {
    value: "2253",
    label: "University of Lapland",
    website: "http://www.ulapland.fi/",
  },
  {
    value: "2254",
    label: "University of Oulu",
    website: "http://www.oulu.fi/",
  },
  {
    value: "2255",
    label: "University of Tampere",
    website: "http://www.uta.fi/",
  },
  {
    value: "2256",
    label: "University of Turku",
    website: "http://www.utu.fi/",
  },
  {
    value: "2257",
    label: "University of Vaasa",
    website: "http://www.uwasa.fi/",
  },
  {
    value: "2258",
    label: "Vaasa University of Applied Sciences",
    website: "http://www.puv.fi/",
  },
  {
    value: "2259",
    label: "Fiji National University ",
    website: "http://www.fnu.ac.fj/",
  },
  {
    value: "2260",
    label: "Fiji School of Medicine",
    website: "http://www.fsm.ac.fj/",
  },
  {
    value: "2261",
    label: "University of Fiji",
    website: "http://www.unifiji.ac.fj/",
  },
  {
    value: "2262",
    label: "University of the South Pacific",
    website: "http://www.usp.ac.fj/",
  },
  {
    value: "2263",
    label: "University of the Faroe Islands",
    website: "http://www.setur.fo/",
  },
  {
    value: "2264",
    label: "AgroParisTech",
    website: "http://www.agroparistech.fr/",
  },
  {
    value: "2265",
    label: "American Graduate School in Paris",
    website: "http://www.ags.edu/",
  },
  {
    value: "2266",
    label: "American University of Paris",
    website: "http://www.aup.fr/",
  },
  {
    value: "2267",
    label: "British Institute in Paris, University of London",
    website: "http://www.bip.lon.ac.uk/",
  },
  {
    value: "2268",
    label: "Centre de Formation et de Perfectionnement des Journalistes",
    website: "http://www.cfpj.com/",
  },
  {
    value: "2269",
    label: "Centre d'Etudes Supérieures des Techniques Industrielles",
    website: "http://www.ismcm-cesti.fr/",
  },
  {
    value: "2270",
    label: "Centre d'Etudes Supérieures Industrielles Paris",
    website: "http://www.cesi.fr/",
  },
  {
    value: "2271",
    label: "Centre National d'Etudes Agronomiques des Régions Chaudes",
    website: "http://www.cnearc.fr/",
  },
  {
    value: "2272",
    label: "Conservatoire National des Arts et Métiers",
    website: "http://www.cnam.fr/",
  },
  {
    value: "2273",
    label: "Ecole Catholique d'Arts & Metiers",
    website: "http://www.ecam.fr/",
  },
  {
    value: "2274",
    label: "Ecole Centrale d'Electronique - ECE",
    website: "http://www.ece.fr/",
  },
  {
    value: "2275",
    label: "Ecole Centrale de Lille",
    website: "http://www.ec-lille.fr/",
  },
  {
    value: "2276",
    label: "Ecole Centrale de Lyon",
    website: "http://www.ec-lyon.fr/",
  },
  {
    value: "2277",
    label: "Ecole Centrale de Nantes",
    website: "http://www.ec-nantes.fr/",
  },
  {
    value: "2278",
    label: "Ecole d'Architecture de Nancy",
    website: "http://www.nancy.archi.fr/",
  },
  {
    value: "2279",
    label: "Ecole de l'Air",
    website: "http://www.aea.asso.fr/",
  },
  {
    value: "2280",
    label: "Ecole des Hautes Etudes Commerciales",
    website: "http://www.hec.fr/",
  },
  {
    value: "2281",
    label: "Ecole des Hautes Etudes Commerciales du Nord",
    website: "http://edu.edhec.com/",
  },
  {
    value: "2282",
    label: "Ecole des Hautes Etudes Industrielles de Lille",
    website: "http://www.hei.fr/",
  },
  {
    value: "2283",
    label: "Ecole des Ingénieurs de la Ville de Paris",
    website: "http://www.eivp-paris.fr/",
  },
  {
    value: "2284",
    label: "Ecole d'Ingénieurs en Informatique pour l'Industrie",
    website: "http://www.e3i.univ-tours.fr/",
  },
  {
    value: "2285",
    label: "Ecole Européen des Affaires",
    website: "http://www.eap.fr/",
  },
  {
    value: "2286",
    label: "Ecole Européenne de Chimie, Polymères et Matériaux de Strasbourg",
    website: "http://www-ecpm.u-strasbg.fr/",
  },
  {
    value: "2287",
    label: "Ecole Française d'Electronique et d'Informatique",
    website: "http://www.efrei.fr/",
  },
  {
    value: "2288",
    label: "Ecole Française de Papeterie et des Industries Graphiques",
    website: "http://www.efpg.inpg.fr/",
  },
  {
    value: "2289",
    label: "Ecole Nationale d'Administration",
    website: "http://www.ena.fr/",
  },
  {
    value: "2290",
    label: "Ecole Nationale de la Météorologie",
    website: "http://www.enm.meteo.fr/",
  },
  {
    value: "2291",
    label:
      "Ecole Nationale de la Statistique et de l'Administration Economique",
    website: "http://www.ensae.fr/",
  },
  {
    value: "2292",
    label: "Ecole Nationale de la Statistique et de l'Analyse de l'information",
    website: "http://www.ensai.fr/",
  },
  {
    value: "2293",
    label: "Ecole Nationale de l'Aviation Civile",
    website: "http://www.enac.fr/",
  },
  {
    value: "2294",
    label: "Ecole Nationale des Ponts et Chausees",
    website: "http://www.enpc.fr/",
  },
  {
    value: "2295",
    label: "Ecole Nationale des Sciences Géographiques",
    website: "http://www.ensg.ign.fr/",
  },
  {
    value: "2296",
    label: "Ecole Nationale des Travaux Publics de l'Etat",
    website: "http://www.entpe.fr/",
  },
  {
    value: "2297",
    label: "Ecole Nationale d'Ingénieurs de Metz",
    website: "http://www.enim.fr/",
  },
  {
    value: "2298",
    label: "Ecole Nationale d'Ingénieurs de Saint-Etienne",
    website: "http://www.enise.fr/",
  },
  {
    value: "2299",
    label:
      "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires",
    website: "http://www.enitiaa-nantes.fr/",
  },
  {
    value: "2300",
    label: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux",
    website: "http://www.enitab.fr/",
  },
  {
    value: "2301",
    label:
      "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand",
    website: "http://www.enitac.fr/",
  },
  {
    value: "2302",
    label: "Ecole Nationale d'Ingénieurs de Tarbes",
    website: "http://www.enit.fr/",
  },
  {
    value: "2303",
    label:
      "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg",
    website: "http://www-engees.u-strasbg.fr/",
  },
  {
    value: "2304",
    label: "Ecole Nationale Supérieur de Géologie de Nancy",
    website: "http://www.ensg.u-nancy.fr/",
  },
  {
    value: "2305",
    label: "Ecole Nationale Supérieur de Mécanique et d'Aéronautique",
    website: "http://www.ensma.fr/",
  },
  {
    value: "2306",
    label: "Ecole Nationale Supérieur de Mécaniques et des Microtechniques",
    website: "http://www.ens2m.fr/",
  },
  {
    value: "2307",
    label:
      "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement",
    website: "http://www.ensieta.fr/",
  },
  {
    value: "2308",
    label:
      "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique",
    website: "http://www.ensica.fr/",
  },
  {
    value: "2309",
    label: "Ecole Nationale Supérieure Agronomique de Toulouse",
    website: "http://www.ensat.fr/",
  },
  {
    value: "2310",
    label: "Ecole Nationale Supérieure d'Agronomie de Montpellier",
    website: "http://www.ensam.inra.fr/",
  },
  {
    value: "2311",
    label: "Ecole Nationale Supérieure d'Agronomie de Rennes",
    website: "http://agro.roazhon.inra.fr/",
  },
  {
    value: "2312",
    label:
      "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires",
    website: "http://www.ensaia.u-nancy.fr/",
  },
  {
    value: "2313",
    label: "Ecole Nationale Supérieure d'Arts et Métiers de Paris",
    website: "http://www.paris.ensam.fr/",
  },
  {
    value: "2314",
    label:
      "Ecole Nationale Supérieure de Biologie Appliquée à la Nutrition et à l'Alementation",
    website: "http://www.u-bourgogne.fr/ENSBANA/",
  },
  {
    value: "2315",
    label: "Ecole Nationale Supérieure de Chimie de Clermont-Ferrand",
    website: "http://ensccf.univ-bpclermont.fr/",
  },
  {
    value: "2316",
    label: "Ecole Nationale Supérieure de Chimie de Lille",
    website: "http://www.ensc-lille.fr/",
  },
  {
    value: "2317",
    label: "Ecole Nationale Supérieure de Chimie de Montpellier",
    website: "http://www.enscm.fr/",
  },
  {
    value: "2318",
    label: "Ecole Nationale Supérieure de Chimie de Mulhouse",
    website: "http://www.enscmu.univ-mulhouse.fr/",
  },
  {
    value: "2319",
    label: "Ecole Nationale Supérieure de Chimie de Paris",
    website: "http://www.enscp.jussieu.fr/",
  },
  {
    value: "2320",
    label: "Ecole Nationale Supérieure de Chimie de Rennes",
    website: "http://www.ensc-rennes.fr/",
  },
  {
    value: "2321",
    label: "Ecole Nationale Supérieure de Chimie de Toulouse",
    website: "http://www.univ-inpt.fr/~ensct/",
  },
  {
    value: "2322",
    label: "Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux",
    website: "http://www.enscpb.u-bordeaux.fr/",
  },
  {
    value: "2323",
    label: "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace",
    website: "http://www.supaero.fr/",
  },
  {
    value: "2324",
    label:
      "Ecole Nationale Supérieure d'Electrochimie et d'Electrométallurgie de Gernoble",
    website: "http://www.inpg.fr/INPG/fr_see.html",
  },
  {
    value: "2325",
    label:
      "Ecole Nationale Supérieure d'Electronique, d'Electrotechnique, d'Informatique et d'Hydraulique de Toulouse",
    website: "http://www.enseeiht.fr/",
  },
  {
    value: "2326",
    label:
      "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux",
    website: "http://www.enserb.u-bordeaux.fr/",
  },
  {
    value: "2327",
    label:
      "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Grenoble",
    website: "http://www.enserg.fr/",
  },
  {
    value: "2328",
    label:
      "Ecole Nationale Supérieure de l'Electronique et de ses Applications",
    website: "http://www.ensea.fr/",
  },
  {
    value: "2329",
    label: "Ecole Nationale Supérieure de Physique de Grenoble",
    website: "http://www-enspg.inpg.fr/",
  },
  {
    value: "2330",
    label: "Ecole Nationale Supérieure de Physique de Marseille",
    website: "http://www.enspm.u-3mrs.fr/",
  },
  {
    value: "2331",
    label: "Ecole Nationale Supérieure de Physique de Strasbourg",
    website: "http://www-ensps.u-strasbg.fr/",
  },
  {
    value: "2332",
    label: "Ecole Nationale Supérieure des Arts et Industries de Strasbourg",
    website: "http://www-ensais.u-strasbg.fr/",
  },
  {
    value: "2333",
    label: "Ecole Nationale Supérieure des Arts et Industries Textiles",
    website: "http://www.ensait.fr/",
  },
  {
    value: "2334",
    label: "Ecole Nationale Supérieure des Industries Chimiques de Nancy",
    website: "http://www.ensic.u-nancy.fr/",
  },
  {
    value: "2335",
    label: "Ecole Nationale Supérieure des Industries Textiles de Mulhouse",
    website: "http://www.ensitm.fr/",
  },
  {
    value: "2336",
    label: "Ecole Nationale Supérieure des Mines d'Alès",
    website: "http://www.ensm-ales.fr/",
  },
  {
    value: "2337",
    label: "Ecole Nationale Supérieure des Mines de Douai",
    website: "http://www.ensm-douai.fr/",
  },
  {
    value: "2338",
    label: "Ecole Nationale Supérieure des Mines de Nancy",
    website: "http://www.mines.u-nancy.fr/",
  },
  {
    value: "2339",
    label: "Ecole Nationale Supérieure des Mines de Paris",
    website: "http://www.ensmp.fr/",
  },
  {
    value: "2340",
    label: "Ecole Nationale Supérieure des Mines de St-Etienne",
    website: "http://www.emse.fr/",
  },
  {
    value: "2341",
    label: "Ecole Nationale Supérieure des Telecommunications de Bretagne",
    website: "http://www.enst-bretagne.fr/",
  },
  {
    value: "2342",
    label: "Ecole Nationale Supérieure des Telecommunications de Paris",
    website: "http://www.enst.fr/",
  },
  {
    value: "2343",
    label: "Ecole Nationale Supérieure de Techniques Avancées",
    website: "http://www.ensta.fr/",
  },
  {
    value: "2344",
    label:
      "Ecole Nationale Supérieure d'Hydraulique et de Mécanique de Grenoble",
    website: "http://www.hmg.inpg.fr/",
  },
  {
    value: "2345",
    label:
      "Ecole Nationale Superieure d'Informatique et de Mathematiques Appliquees de Grenoble",
    website: "http://www-ensimag.imag.fr/",
  },
  {
    value: "2346",
    label:
      "École Nationale Supérieure d'Ingénieurs de Constructions Aéronautiques",
    website: "http://www.ensica.fr/",
  },
  {
    value: "2347",
    label: "Ecole Nationale Supérieure d'Ingénieurs de Génie Chimique",
    website: "http://www.inp-fc.fr/~ensigc/",
  },
  {
    value: "2348",
    label: "Ecole Nationale Supérieure d'Ingenieurs Electriciens de Grenoble",
    website: "http://www.ensieg.inpg.fr/",
  },
  {
    value: "2349",
    label:
      "Ecole Nationale Supérieure d'Ingénieurs en Mécanique et Energétique de Valenciennes",
    website: "http://www.univ-valenciennes.fr/ensimev/",
  },
  {
    value: "2350",
    label: "Ecole Nationale Supérieure du Pétrole et des Monteurs",
    website: "http://www.ifp.fr/EC/EC000GP1.html",
  },
  {
    value: "2351",
    label: "Ecole Nationale Supérieure Electricité et Mécanique",
    website: "http://www.ensem.u-nancy.fr/",
  },
  {
    value: "2352",
    label:
      "Ecole Nationale Supérieure en Electrotechnique, Electronique, Informatique et Hydraulique de Toulouse",
    website: "http://www.enseeiht.fr/",
  },
  {
    value: "2353",
    label: "Ecole Nationale Vétérinaire d'Alfort",
    website: "http://www.vet-alfort.fr/",
  },
  {
    value: "2354",
    label: "Ecole Nationale Vétérinaire de Lyon",
    website: "http://www.vet-lyon.fr/",
  },
  {
    value: "2355",
    label: "Ecole Nationale Vétérinaire de Nantes",
    website: "http://www.vet-nantes.fr/",
  },
  {
    value: "2356",
    label: "Ecole Nationale Vétérinaire de Toulouse",
    website: "http://www.envt.fr/",
  },
  {
    value: "2357",
    label: "Ecole Normale Supérieure de Cachan",
    website: "http://www.ens-cachan.fr/",
  },
  {
    value: "2358",
    label: "Ecole Normale Supérieure de Fontenay-Saint Cloud",
    website: "http://www.ens-fcl.fr/",
  },
  {
    value: "2359",
    label: "Ecole Normale Supérieure de Lyon",
    website: "http://www.ens-lyon.fr/",
  },
  {
    value: "2360",
    label: "Ecole Normale Supérieure de Paris",
    website: "http://www.ens.fr/",
  },
  {
    value: "2361",
    label: "Ecole Polytechnique",
    website: "http://www.polytechnique.fr/",
  },
  {
    value: "2362",
    label: "Ecole Polytechnique Marseille",
    website: "http://www.polytech-marseille.fr/",
  },
  {
    value: "2363",
    label: "Ecole Polytechnique Universitaire de Lille",
    website: "http://www.polytech-lille.fr/",
  },
  {
    value: "2364",
    label:
      "Ecole pour les Etudes et la Recherche en Informatique et Electronique",
    website: "http://www.eerie.fr/",
  },
  {
    value: "2365",
    label: "Ecole Spéciale de Mécanique et d'Electricité",
    website: "http://www.esme.fr/",
  },
  {
    value: "2366",
    label: "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie",
    website: "http://www.estp.fr/",
  },
  {
    value: "2367",
    label: "Ecole Superieur d'Ingenieurs Leonard de Vinci",
    website: "http://www.devinci.fr/",
  },
  {
    value: "2368",
    label: "Ecole Supérieure d'Agriculture d'Angers",
    website: "http://www.groupe-esa.com/",
  },
  {
    value: "2369",
    label: "Ecole Supérieure d'Agriculture de Purpan",
    website: "http://www.esa-purpan.fr/",
  },
  {
    value: "2370",
    label: "Ecole Supérieure de Chimie Organique et Minérale",
    website: "http://www.escom.fr/",
  },
  {
    value: "2371",
    label: "Ecole Supérieure de Chimie Physique Electronique de Lyon",
    website: "http://www.cpe.fr/",
  },
  {
    value: "2372",
    label: "Ecole Supérieure de Commerce de Bordeaux",
    website: "http://www.esc-bordeaux.fr/",
  },
  {
    value: "2373",
    label: "Ecole Supérieure de Commerce de Brest",
    website: "http://www.esc-brest.fr/",
  },
  {
    value: "2374",
    label: "Ecole Supérieure de Commerce de Clermont-Ferrand",
    website: "http://www.esc-clermont.fr/",
  },
  {
    value: "2375",
    label: "Ecole Supérieure de Commerce de Dijon",
    website: "http://www.escdijon.com/",
  },
  {
    value: "2376",
    label: "Ecole Supérieure de Commerce de Grenoble",
    website: "http://www.esc-grenoble.fr/",
  },
  {
    value: "2377",
    label: "Ecole Supérieure de Commerce de Le Havre/Caen",
    website: "http://www.esc-normandie.fr/",
  },
  {
    value: "2378",
    label: "Ecole Supérieure de Commerce de Lille",
    website: "http://www.esc-lille.fr/",
  },
  {
    value: "2379",
    label: "Ecole Supérieure de Commerce de Lyon",
    website: "http://www.em-lyon.com/",
  },
  {
    value: "2380",
    label: "Ecole Supérieure de Commerce de Marseille-Provence",
    website: "http://www.esc-marseille.fr/",
  },
  {
    value: "2381",
    label: "Ecole Supérieure de Commerce de Nantes-Atlantique",
    website: "http://www.escna.fr/",
  },
  {
    value: "2382",
    label: "Ecole Supérieure de Commerce de Paris",
    website: "http://www.escp.fr/",
  },
  {
    value: "2383",
    label: "Ecole Supérieure de Commerce de Pau",
    website: "http://www.esc-pau.fr/",
  },
  {
    value: "2384",
    label: "Ecole Supérieure de Commerce de Reims",
    website: "http://www.esc-reims.edu/",
  },
  {
    value: "2385",
    label: "Ecole Supérieure de Commerce de Rouen",
    website: "http://www.esc-rouen.fr/",
  },
  {
    value: "2386",
    label: "Ecole Supérieure de Commerce de Sophia Antipolis",
    website: "http://www.ceram.edu/",
  },
  {
    value: "2387",
    label: "Ecole Supérieure de Commerce de Toulouse",
    website: "http://www.esc-toulouse.fr/",
  },
  {
    value: "2388",
    label: "Ecole Supérieure de Commerce et Management",
    website: "http://www.escem.fr/",
  },
  {
    value: "2389",
    label: "Ecole Supérieure d'Electricité",
    website: "http://www.supelec.fr/",
  },
  {
    value: "2390",
    label: "Ecole Supérieure d'Electronique de l'Ouest",
    website: "http://www.eseo.fr/",
  },
  {
    value: "2391",
    label: "Ecole Supérieure de Physique et de Chimie Industrielles",
    website: "http://www.espci.fr/",
  },
  {
    value: "2392",
    label: "Ecole Supérieure des Sciences Commerciales d'Angers",
    website: "http://www.essca.asso.fr/",
  },
  {
    value: "2393",
    label: "Ecole Supérieure des Sciences Economiques et Commerciales",
    website: "http://www.essec.fr/",
  },
  {
    value: "2394",
    label:
      "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy",
    website: "http://www.esstin.u-nancy.fr/",
  },
  {
    value: "2395",
    label: "Ecole Supérieure des Techniques Industrielles et des Textiles",
    website: "http://www.fupl.asso.fr/estit/",
  },
  {
    value: "2396",
    label: "Ecole Supérieure d'Informatique-Electronique-Automatique",
    website: "http://www.esiea.fr/",
  },
  {
    value: "2397",
    label: "Ecole Supérieure d'Ingénieurs de Marseille",
    website: "http://esim.imt-mrs.fr/",
  },
  {
    value: "2398",
    label: "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique",
    website: "http://www.esiee.fr/",
  },
  {
    value: "2399",
    label: "Ecole Supérieure d'Ingénieurs en Génie Electrique",
    website: "http://www.esigelec.fr/",
  },
  {
    value: "2400",
    label: "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture",
    website: "http://www.esitpa.org/",
  },
  {
    value: "2401",
    label: "Ecole Supérieure d'Optique",
    website: "http://www.iota.u-psud.fr/~ecole/",
  },
  {
    value: "2402",
    label: "Ecole Supérieure Internationale d'Administration des Entreprises",
    website: "http://www.esiae.fr/",
  },
  {
    value: "2403",
    label: "Ecole Superieure Robert de Sorbon",
    website: "http://www.sorbon.fr/",
  },
  {
    value: "2404",
    label: "Ecole Universitaire d'Ingénieurs de Lille",
    website: "http://www.eudil.fr/",
  },
  {
    value: "2405",
    label: "ENIC Telecom Lille 1",
    website: "http://www.enic.fr/",
  },
  {
    value: "2406",
    label: "EPF Ecole d'Ingénieurs",
    website: "http://www.epf.fr/",
  },
  {
    value: "2407",
    label: "European Business School",
    website: "http://www.ebs-paris.com/",
  },
  {
    value: "2408",
    label: "European Management Center Paris",
    website: "http://www.emc-campus.com/",
  },
  {
    value: "2409",
    label: "Grenoble Ecole de Management",
    website: "http://www.grenoble-em.com/",
  },
  {
    value: "2410",
    label: "Groupe Sup de Co Amiens Picardie",
    website: "http://www.supco-amiens.fr/",
  },
  {
    value: "2411",
    label: "Groupe Sup de Co Montpellier",
    website: "http://www.supco-montpellier.fr/",
  },
  {
    value: "2412",
    label: "IDRAC (Institut de recherche en action commerciale)",
    website: "http://www.ecoles-idrac.com/",
  },
  {
    value: "2413",
    label: "Institut Catholique d'Arts et Métiers Lille",
    website: "http://www.icam.fr/",
  },
  {
    value: "2414",
    label: "Institut Catholique d'Arts et Métiers Nantes",
    website: "http://www.icam.fr/presentation/nantes.htm",
  },
  {
    value: "2415",
    label: "Institut Catholique de Paris",
    website: "http://www.icp.fr/",
  },
  {
    value: "2416",
    label: "Institut Catholique de Toulouse",
    website: "http://www.ict-toulouse.asso.fr/",
  },
  {
    value: "2417",
    label: "Institut Commercial de Nancy",
    website: "http://www.icn-nancy.com/",
  },
  {
    value: "2418",
    label:
      "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique",
    website: "http://www.ireste.fr/",
  },
  {
    value: "2419",
    label: "Institut des hautes études économiques et commerciales",
    website: "http://www.inseec.fr/",
  },
  {
    value: "2420",
    label: "Institut des Sciences de la Matière et du Rayonnement",
    website: "http://www.ismra.fr/",
  },
  {
    value: "2421",
    label: "Institut des Sciences de l'Ingénieur de Clermont-Ferrand",
    website: "http://cust.univ-bpclermont.fr/",
  },
  {
    value: "2422",
    label: "Institut des Sciences de l'Ingénieur de Montpellier",
    website: "http://www.isim.univ-montp2.fr/",
  },
  {
    value: "2423",
    label: "Institut d'Etudes Politiques de Bordeaux",
    website: "http://www.iep.u-bordeaux.fr/",
  },
  {
    value: "2424",
    label: "Institut d'Etudes Politiques de Paris (Sciences Po)",
    website: "http://www.sciences-po.fr/",
  },
  {
    value: "2425",
    label: "Institut National des Sciences Appliquées de Lyon",
    website: "http://www.insa-lyon.fr/",
  },
  {
    value: "2426",
    label: "Institut National des Sciences Appliquées de Rennes",
    website: "http://www.insa-rennes.fr/",
  },
  {
    value: "2427",
    label: "Institut National des Sciences Appliquées de Rouen",
    website: "http://www.insa-rouen.fr/",
  },
  {
    value: "2428",
    label: "Institut National des Sciences Appliquées de Toulouse",
    website: "http://www.insa-tlse.fr/",
  },
  {
    value: "2429",
    label: "Institut National des Télécommunications",
    website: "http://www.int-evry.fr/",
  },
  {
    value: "2430",
    label: "Institut National Polytechnique de Grenoble",
    website: "http://www.inpg.fr/",
  },
  {
    value: "2431",
    label: "Institut National Polytechnique de Lorraine",
    website: "http://www.inpl-nancy.fr/",
  },
  {
    value: "2432",
    label: "Institut National Polytechnique de Toulouse",
    website: "http://www.inp-fc.fr/",
  },
  {
    value: "2433",
    label: "Institut National Supérieur de Formation Agro-Alimentaire",
    website: "http://agro.roazhon.inra.fr/etab/insfa/accueil.htm",
  },
  {
    value: "2434",
    label: "Institut Supérieur Agricole de Beauvais",
    website: "http://www.isab.fr/",
  },
  {
    value: "2435",
    label: "Institut Supérieur d'Agriculture Lille",
    website: "http://www.isa.fupl.asso.fr/",
  },
  {
    value: "2436",
    label: "Institut Supérieur d'Agriculture Rhone-Alpes",
    website: "http://www.isara.fr/",
  },
  {
    value: "2437",
    label: "Institut Supérieure d'Electronique de Paris",
    website: "http://www.isep.fr/",
  },
  {
    value: "2438",
    label: "Institut Supérieure d'Electronique du Nord",
    website: "http://www.isen.fr/",
  },
  {
    value: "2439",
    label: "Institut Textile et Chimique de Lyon",
    website: "http://www.itech.fr/",
  },
  {
    value: "2440",
    label: "Schiller International University, Paris",
    website: "http://www.schillerparis.com/",
  },
  {
    value: "2441",
    label: "Schiller International University, Strasbourg",
    website: "http://www.schillerstrasbourg.com/",
  },
  {
    value: "2442",
    label: "Université Blaise Pascal (Clermont-Ferrand II)",
    website: "http://www.univ-bpclermont.fr/",
  },
  {
    value: "2443",
    label: "Université Bordeaux I",
    website: "http://www.cribx1.u-bordeaux.fr/",
  },
  {
    value: "2444",
    label: "Université Catholique de Lille",
    website: "http://www.fupl.asso.fr/",
  },
  {
    value: "2445",
    label: "Université Catholique de l' Ouest",
    website: "http://www.uco.fr/",
  },
  {
    value: "2446",
    label: "Université Catholique de Lyon",
    website: "http://www.univ-catholyon.fr/",
  },
  {
    value: "2447",
    label: "Université Charles-de-Gaulle (Lille III)",
    website: "http://www.univ-lille3.fr/",
  },
  {
    value: "2448",
    label: "Université Claude Bernard (Lyon I)",
    website: "http://www.univ-lyon1.fr/",
  },
  {
    value: "2449",
    label: "Université d'Aix-Marseille III",
    website: "http://www.u-3mrs.fr/",
  },
  {
    value: "2450",
    label: "Université d'Angers",
    website: "http://www.univ-angers.fr/",
  },
  {
    value: "2451",
    label: "Université d'Artois",
    website: "http://www.univ-artois.fr/",
  },
  {
    value: "2452",
    label: "Université d'Auvergne (Clermont-Ferrand I)",
    website: "http://www.u-clermont1.fr/",
  },
  {
    value: "2453",
    label: "Université d'Avignon",
    website: "http://www.univ-avignon.fr/",
  },
  {
    value: "2454",
    label: "Université de Bourgogne",
    website: "http://www.u-bourgogne.fr/",
  },
  {
    value: "2455",
    label: "Université de Bretagne Occidentale",
    website: "http://www.univ-brest.fr/",
  },
  {
    value: "2456",
    label: "Université de Bretagne Sud",
    website: "http://www.univ-ubs.fr/",
  },
  {
    value: "2457",
    label: "Université de Caen Basse Normandie",
    website: "http://www.unicaen.fr/",
  },
  {
    value: "2458",
    label: "Université de Cergy-Pontoise",
    website: "http://www.u-cergy.fr/",
  },
  {
    value: "2459",
    label: "Université de Corse Pascal Paoli",
    website: "http://www.univ-corse.fr/",
  },
  {
    value: "2460",
    label: "Université de Franche-Comté",
    website: "http://www.univ-fcomte.fr/",
  },
  {
    value: "2461",
    label: "Université de Haute-Alsace",
    website: "http://www.univ-mulhouse.fr/",
  },
  {
    value: "2462",
    label: "Université de la Méditerranée (Aix Marseille II)",
    website: "http://www.univmed.fr/",
  },
  {
    value: "2463",
    label: "Université de La Rochelle",
    website: "http://www.univ-lr.fr/",
  },
  {
    value: "2464",
    label: "Université de Limoges",
    website: "http://www.unilim.fr/",
  },
  {
    value: "2465",
    label: "Université de Marne la Vallée",
    website: "http://www.univ-mlv.fr/",
  },
  {
    value: "2466",
    label: "Université de Metz",
    website: "http://www.univ-metz.fr/",
  },
  {
    value: "2467",
    label: "Université de Montpellier I",
    website: "http://www.univ-montp1.fr/",
  },
  {
    value: "2468",
    label: "Université de Montpellier II",
    website: "http://www.univ-montp2.fr/",
  },
  {
    value: "2469",
    label: "Université de Nantes",
    website: "http://www.univ-nantes.fr/",
  },
  {
    value: "2470",
    label: "Université de Nice-Sophia Antipolis",
    website: "http://www.unice.fr/",
  },
  {
    value: "2471",
    label: "Université Denis Diderot (Paris VII)",
    website: "http://www.sigu7.jussieu.fr/",
  },
  {
    value: "2472",
    label: "Université de Pau et des Pays de l'Adour",
    website: "http://www.univ-pau.fr/",
  },
  {
    value: "2473",
    label: "Université de Perpignan",
    website: "http://www.univ-perp.fr/",
  },
  {
    value: "2474",
    label: "Université de Picardie Jules-Verne",
    website: "http://www.u-picardie.fr/",
  },
  {
    value: "2475",
    label: "Université de Poitiers",
    website: "http://www.univ-poitiers.fr/",
  },
  {
    value: "2476",
    label: "Université de Provence (Aix-Marseille I)",
    website: "http://www.up.univ-mrs.fr/",
  },
  {
    value: "2477",
    label: "Université de Reims Champagne-Ardenne",
    website: "http://www.univ-reims.fr/",
  },
  {
    value: "2478",
    label: "Université de Rouen - Haute Normandie",
    website: "http://www.univ-rouen.fr/",
  },
  {
    value: "2479",
    label: "Université de Savoie",
    website: "http://www.univ-savoie.fr/",
  },
  {
    value: "2480",
    label: "Université des Sciences et Technologies de Lille (Lille I)",
    website: "http://www.univ-lille1.fr/",
  },
  {
    value: "2481",
    label: "Université des Sciences Humaines (Strasbourg II)",
    website: "http://ushs.u-strasbg.fr/",
  },
  {
    value: "2482",
    label: "Université des Sciences Sociales (Toulouse I)",
    website: "http://www.univ-tlse1.fr/",
  },
  {
    value: "2483",
    label: "Université de Technologie de Belfort Montbéliard",
    website: "http://www.utbm.fr/",
  },
  {
    value: "2484",
    label: "Université de Technologie de Compiègne",
    website: "http://www.utc.fr/",
  },
  {
    value: "2485",
    label: "Université de Technologie de Troyes",
    website: "http://www.utt.fr/",
  },
  {
    value: "2486",
    label: "Université de Toulouse",
    website: "http://www.univ-toulouse.fr/",
  },
  {
    value: "2487",
    label: "Université de Toulouse-le-Mirail (Toulouse II)",
    website: "http://www.univ-tlse2.fr/",
  },
  {
    value: "2488",
    label: "Université de Valenciennes et du Hainaut-Cambrésis",
    website: "http://www.univ-valenciennes.fr/",
  },
  {
    value: "2489",
    label: "Université de Versailles Saint-Quentin-en-Yvelines",
    website: "http://www.uvsq.fr/",
  },
  {
    value: "2490",
    label: "Université d'Evry Val d'Essonne",
    website: "http://www.univ-evry.fr/",
  },
  {
    value: "2491",
    label: "Université d'Orléans",
    website: "http://www.univ-orleans.fr/",
  },
  {
    value: "2492",
    label: "Université du Droit et de la Sante (Lille II)",
    website: "http://www.univ-lille2.fr/",
  },
  {
    value: "2493",
    label: "Université du Havre",
    website: "http://www.univ-lehavre.fr/",
  },
  {
    value: "2494",
    label: "Université du Littoral Cote d'Opale",
    website: "http://www.univ-littoral.fr/",
  },
  {
    value: "2495",
    label: "Université du Maine",
    website: "http://www.univ-lemans.fr/",
  },
  {
    value: "2496",
    label: "Université du Sud, Toulon et Var",
    website: "http://www.univ-tln.fr/",
  },
  {
    value: "2497",
    label: "Université François Rabelais de Tours",
    website: "http://www.univ-tours.fr/",
  },
  {
    value: "2498",
    label: "Université Henri Poincaré (Nancy I)",
    website: "http://www.uhp-nancy.fr/",
  },
  {
    value: "2499",
    label: "Université Jean Monnet",
    website: "http://www.univ-st-etienne.fr/",
  },
  {
    value: "2500",
    label: "Université Jean Moulin (Lyon III)",
    website: "http://www.univ-lyon3.fr/",
  },
  {
    value: "2501",
    label: "Université Joseph Fourier (Grenoble I)",
    website: "http://www.ujf-grenoble.fr/",
  },
  {
    value: "2502",
    label: "Université Louis Pasteur (Strasbourg I)",
    website: "http://www-ulp.u-strasbg.fr/",
  },
  {
    value: "2503",
    label: "Université Lumiére (Lyon II)",
    website: "http://www.univ-lyon2.fr/",
  },
  {
    value: "2504",
    label: "Université Michel de Montaigne (Bordeaux III )",
    website: "http://www.montaigne.u-bordeaux.fr/",
  },
  {
    value: "2505",
    label: "Université Montesquieu (Bordeaux IV)",
    website: "http://www.montesquieu.u-bordeaux.fr/",
  },
  {
    value: "2506",
    label: "Université Nancy II",
    website: "http://www.univ-nancy2.fr/",
  },
  {
    value: "2507",
    label: "Université Panthéon-Assas (Paris II)",
    website: "http://www.u-paris2.fr/",
  },
  {
    value: "2508",
    label: "Université Panthéon-Sorbonne (Paris I)",
    website: "http://www.univ-paris1.fr/",
  },
  {
    value: "2509",
    label: "Université Paris-Dauphine (Paris IX)",
    website: "http://www.dauphine.fr/",
  },
  {
    value: "2510",
    label: "Université Paris Nanterre (Paris X)",
    website: "http://www.u-paris10.fr/",
  },
  {
    value: "2511",
    label: "Université Paris Nord (Paris XIII)",
    website: "http://www.univ-paris13.fr/",
  },
  {
    value: "2512",
    label: "Université Paris-Sorbonne (Paris IV)",
    website: "http://www.paris4.sorbonne.fr/",
  },
  {
    value: "2513",
    label: "Université Paris Sud (Paris XI)",
    website: "http://www.u-psud.fr/",
  },
  {
    value: "2514",
    label: "Université Paul Sabatier (Toulouse III)",
    website: "http://www.ups-tlse.fr/",
  },
  {
    value: "2515",
    label: "Université Paul Valéry (Montpellier III)",
    website: "http://www.univ-montp3.fr/",
  },
  {
    value: "2516",
    label: "Université Pierre et Marie Curie (Paris VI)",
    website: "http://www.jussieu.fr/",
  },
  {
    value: "2517",
    label: "Université Pierre Mendes-France (Grenoble II)",
    website: "http://www.upmf-grenoble.fr/",
  },
  {
    value: "2518",
    label: "Université René Descartes (Paris V)",
    website: "http://www.univ-paris5.fr/",
  },
  {
    value: "2519",
    label: "Université Rennes I",
    website: "http://www.univ-rennes1.fr/",
  },
  {
    value: "2520",
    label: "Université Rennes II - Haute-Bretagne",
    website: "http://www.uhb.fr/",
  },
  {
    value: "2521",
    label: "Université Robert Schuman (Strasbourg III)",
    website: "http://www-urs.u-strasbg.fr/",
  },
  {
    value: "2522",
    label: "Université Sorbonne-Nouvelle (Paris III)",
    website: "http://www.univ-paris3.fr/",
  },
  {
    value: "2523",
    label: "Université Stendhal (Grenoble III)",
    website: "http://www.u-grenoble3.fr/",
  },
  {
    value: "2524",
    label: "Université Val-de-Marne (Paris XII)",
    website: "http://www.univ-paris12.fr/",
  },
  {
    value: "2525",
    label: "Université Victor Segalen (Bordeaux II)",
    website: "http://www.u-bordeaux2.fr/",
  },
  {
    value: "2526",
    label: "Université Vincennes Saint-Denis (Paris VIII)",
    website: "http://www.univ-paris8.fr/",
  },
  {
    value: "2527",
    label: "Université Omar Bongo",
    website: "http://www.uob.ga/",
  },
  {
    value: "2528",
    label: "Aga Khan University",
    website: "http://www.aku.edu/",
  },
  {
    value: "2529",
    label: "American InterContinental University - London",
    website: "http://www.aiuniv.edu/",
  },
  {
    value: "2530",
    label: "Anglia Ruskin University",
    website: "http://www.anglia.ac.uk/",
  },
  {
    value: "2531",
    label: "Aston University",
    website: "http://www.aston.ac.uk/",
  },
  {
    value: "2532",
    label: "Birkbeck College, University of London",
    website: "http://www.bbk.ac.uk/",
  },
  {
    value: "2533",
    label: "Birmingham City University",
    website: "http://www.bcu.ac.uk/",
  },
  {
    value: "2534",
    label: "Bournemouth University",
    website: "http://www.bournemouth.ac.uk/",
  },
  {
    value: "2535",
    label: "British College of Osteopathic Medicine",
    website: "http://www.bcom.ac.uk/",
  },
  {
    value: "2536",
    label: "Brunel University Uxbridge",
    website: "http://www.brunel.ac.uk/",
  },
  {
    value: "2537",
    label: "Buckinghamshire New University",
    website: "http://www.bucks.ac.uk/",
  },
  {
    value: "2538",
    label: "Camberwell College of Arts",
    website: "http://www.camb.linst.ac.uk/",
  },
  {
    value: "2539",
    label: "Canterbury Christ Church University",
    website: "http://www.canterbury.ac.uk/",
  },
  {
    value: "2540",
    label: "Cardiff University",
    website: "http://www.cardiff.ac.uk/",
  },
  {
    value: "2541",
    label: "Central Saint Martins College of Art & Design",
    website: "http://www.csm.linst.ac.uk/",
  },
  {
    value: "2542",
    label: "Chelsea College of Art and Design",
    website: "http://www.chelsea.linst.ac.uk/",
  },
  {
    value: "2543",
    label: "City University",
    website: "http://www.city.ac.uk/",
  },
  {
    value: "2544",
    label: "Courtauld Institute of Art, University of London",
    website: "http://www.courtauld.ac.uk/",
  },
  {
    value: "2545",
    label: "Coventry University",
    website: "http://www.coventry.ac.uk/",
  },
  {
    value: "2546",
    label: "Cranfield University",
    website: "http://www.cranfield.ac.uk/",
  },
  {
    value: "2547",
    label: "De Montfort University Leicester",
    website: "http://www.dmu.ac.uk/",
  },
  {
    value: "2548",
    label: "European School of Economics",
    website: "http://www.eselondon.ac.uk/",
  },
  {
    value: "2549",
    label: "Falmouth University",
    website: "http://www.falmouth.ac.uk/",
  },
  {
    value: "2550",
    label: "Glasgow Caledonian University",
    website: "http://www.gcal.ac.uk/",
  },
  {
    value: "2551",
    label: "Glasgow School of Art",
    website: "http://www.gsa.ac.uk/",
  },
  {
    value: "2552",
    label: "Goldsmiths College, University of London",
    website: "http://www.gold.ac.uk/",
  },
  {
    value: "2553",
    label: "Heriot-Watt University",
    website: "http://www.hw.ac.uk/",
  },
  {
    value: "2554",
    label: "Heythrop College, University of London",
    website: "http://www.heythrop.ac.uk/",
  },
  {
    value: "2555",
    label: "Huron University USA in London",
    website: "http://www.huron.ac.uk/",
  },
  {
    value: "2556",
    label: "ifs University College",
    website: "http://www.ifslearning.ac.uk/",
  },
  {
    value: "2557",
    label: "Imperial College London",
    website: "http://www.ic.ac.uk/",
  },
  {
    value: "2558",
    label: "Imperial College School of Medicine",
    website: "http://www.med.ic.ac.uk/",
  },
  {
    value: "2559",
    label: "Institue of Historical Research, University of London",
    website: "http://ihr.sas.ac.uk/",
  },
  {
    value: "2560",
    label: "Institute of Advanced Legal Studies, University of London",
    website: "http://www.sas.ac.uk/ials/",
  },
  {
    value: "2561",
    label: "Institute of Classical Studies, University of London",
    website: "http://www.sas.ac.uk/icls/",
  },
  {
    value: "2562",
    label: "Institute of Commonwealth Studies, University of London",
    website: "http://www.ihr.sas.ac.uk/ics/",
  },
  {
    value: "2563",
    label: "Institute of Education, University of London",
    website: "http://www.ioe.ac.uk/",
  },
  {
    value: "2564",
    label: "Institute of Germanic Studies, University of London",
    website: "http://www.sas.ac.uk/igs/",
  },
  {
    value: "2565",
    label: "Institute of Latin American Studies, University of London",
    website: "http://www.sas.ac.uk/ilas/",
  },
  {
    value: "2566",
    label: "International Centre for Isclamic Science",
    website: "http://www.kolieh.com/",
  },
  {
    value: "2567",
    label: "International Colleges of Islamic Science",
    website: "http://www.islamiccolleges.com/",
  },
  {
    value: "2568",
    label: "Keele University",
    website: "http://www.keele.ac.uk/",
  },
  {
    value: "2569",
    label: "King's College London, University of London",
    website: "http://www.kcl.ac.uk/",
  },
  {
    value: "2570",
    label: "Kingston University",
    website: "http://www.kingston.ac.uk/",
  },
  {
    value: "2571",
    label: "Leeds Metropolitan University",
    website: "http://www.lmu.ac.uk/",
  },
  {
    value: "2572",
    label: "Liverpool Hope University College",
    website: "http://www.hope.ac.uk/",
  },
  {
    value: "2573",
    label: "Liverpool John Moores University",
    website: "http://www.livjm.ac.uk/",
  },
  {
    value: "2574",
    label: "London Business School",
    website: "http://www.lbs.lon.ac.uk/",
  },
  {
    value: "2575",
    label: "London College of Science & Technology",
    website: "http://www.lcst.ac/",
  },
  {
    value: "2576",
    label: "London Guildhall University",
    website: "http://www.lgu.ac.uk/",
  },
  {
    value: "2577",
    label: "London Institute of Management and Technology",
    website: "http://www.limt.co.uk/",
  },
  {
    value: "2578",
    label: "London Metropolitan University",
    website: "http://www.londonmet.ac.uk/",
  },
  {
    value: "2579",
    label: "London School of Business & Finance",
    website: "http://www.lsbf.org.uk/",
  },
  {
    value: "2580",
    label:
      "London School of Economics and Political Science, University of London",
    website: "http://www.lse.ac.uk/",
  },
  {
    value: "2581",
    label: "London School of Hygiene & Tropical Medicine, University of London",
    website: "http://www.lshtm.ac.uk/",
  },
  {
    value: "2582",
    label: "London School of Jewish Studies",
    website: "http://www.brijnet.org/lsjs/",
  },
  {
    value: "2583",
    label: "Loughborough University",
    website: "http://www.lboro.ac.uk/",
  },
  {
    value: "2584",
    label: "Middlesex University",
    website: "http://www.mdx.ac.uk/",
  },
  {
    value: "2585",
    label: "Napier University",
    website: "http://www.napier.ac.uk/",
  },
  {
    value: "2586",
    label: "Newport International University",
    website: "http://www.niu.org.uk/",
  },
  {
    value: "2587",
    label: "Nottingham Trent University",
    website: "http://www.ntu.ac.uk/",
  },
  {
    value: "2588",
    label: "Open University",
    website: "http://www.open.ac.uk/",
  },
  {
    value: "2589",
    label: "Oxford Brookes University",
    website: "http://www.brookes.ac.uk/",
  },
  {
    value: "2590",
    label: "Queen Mary and Westfield College, University of London",
    website: "http://www.qmw.ac.uk/",
  },
  {
    value: "2591",
    label:
      "Richmond University - The American International University in London",
    website: "http://www.richmond.ac.uk/",
  },
  {
    value: "2592",
    label: "Roehampton University of Surrey",
    website: "http://www.roehampton.ac.uk/",
  },
  {
    value: "2593",
    label: "Royal Academy of Music, University of London",
    website: "http://www.ram.ac.uk/",
  },
  {
    value: "2594",
    label: "Royal College of Art",
    website: "http://www.rca.ac.uk/",
  },
  {
    value: "2595",
    label: "Royal College of Music, University of London",
    website: "http://www.rcm.ac.uk/",
  },
  {
    value: "2596",
    label: "Royal Free Hospital School of Medicine, University of London",
    website: "http://www.rfhsm.ac.uk/",
  },
  {
    value: "2597",
    label: "Royal Holloway and Bedford New College",
    website: "http://www.rhbnc.ac.uk/",
  },
  {
    value: "2598",
    label: "Saint George's Hospital Medical School, University of London",
    website: "http://www.sghms.ac.uk/",
  },
  {
    value: "2599",
    label: "Schiller International University, London",
    website: "http://www.schillerlondon.ac.uk/",
  },
  {
    value: "2600",
    label: "School of Oriental and African Studies, University of London",
    website: "http://www.soas.ac.uk/",
  },
  {
    value: "2601",
    label: "School of Pharmacy, University of London",
    website: "http://www.ulsop.ac.uk/",
  },
  {
    value: "2602",
    label: "School of Slavonic and East European Studies, University of London",
    website: "http://www.ssees.ac.uk/",
  },
  {
    value: "2603",
    label: "Sheffield Hallam University",
    website: "http://www.shu.ac.uk/",
  },
  {
    value: "2604",
    label: "Sotheby´s Institute of Art - London",
    website: "http://www.sothebysinstitutelondon.com/",
  },
  {
    value: "2605",
    label: "Southampton Solent University",
    website: "http://www.solent.ac.uk/",
  },
  {
    value: "2606",
    label: "South Bank University",
    website: "http://www.sbu.ac.uk/",
  },
  {
    value: "2607",
    label: "Staffordshire University",
    website: "http://www.staffs.ac.uk/",
  },
  {
    value: "2608",
    label: "St.Patrick's International College, London",
    website: "http://www.st-patricks.ac.uk/",
  },
  {
    value: "2609",
    label: "Stratford College London",
    website: "http://www.sclondon.ac/",
  },
  {
    value: "2610",
    label: "Swansea Metropolitan University",
    website: "http://www.smu.ac.uk/",
  },
  {
    value: "2611",
    label: "Thames Valley University",
    website: "http://www.tvu.ac.uk/",
  },
  {
    value: "2612",
    label: "The American University in London",
    website: "http://www.aul.edu/",
  },
  {
    value: "2613",
    label: "The Manchester Metropolitan University",
    website: "http://www.mmu.ac.uk/",
  },
  {
    value: "2614",
    label: "The Queen's University Belfast",
    website: "http://www.qub.ac.uk/",
  },
  {
    value: "2615",
    label: "The Robert Gordon University",
    website: "http://www.rgu.ac.uk/",
  },
  {
    value: "2616",
    label: "Trinity College Carmarthen",
    website: "http://www.trinity-cm.ac.uk/",
  },
  {
    value: "2617",
    label: "Trinity College of Music",
    website: "http://www.tcm.ac.uk/",
  },
  {
    value: "2618",
    label: "United Medical and Dental Schools, University of London",
    website: "http://www.umds.ac.uk/",
  },
  {
    value: "2619",
    label: "University Campus Suffolk",
    website: "http://www.ucs.ac.uk/",
  },
  {
    value: "2620",
    label: "University College London, University of London",
    website: "http://www.ucl.ac.uk/",
  },
  {
    value: "2621",
    label: "University of Aberdeen",
    website: "http://www.abdn.ac.uk/",
  },
  {
    value: "2622",
    label: "University of Abertay Dundee",
    website: "http://www.abertay.ac.uk/",
  },
  {
    value: "2623",
    label: "University of Bath",
    website: "http://www.bath.ac.uk/",
  },
  {
    value: "2624",
    label: "University of Bedfordshire",
    website: "http://www.beds.ac.uk/",
  },
  {
    value: "2625",
    label: "University of Birmingham",
    website: "http://www.bham.ac.uk/",
  },
  {
    value: "2626",
    label: "University of Bolton",
    website: "http://www.bolton.ac.uk/",
  },
  {
    value: "2627",
    label: "University of Bradford",
    website: "http://www.brad.ac.uk/",
  },
  {
    value: "2628",
    label: "University of Brighton",
    website: "http://www.bton.ac.uk/",
  },
  {
    value: "2629",
    label: "University of Bristol",
    website: "http://www.bris.ac.uk/",
  },
  {
    value: "2630",
    label: "University of Buckingham",
    website: "http://www.buck.ac.uk/",
  },
  {
    value: "2631",
    label: "University of Cambridge",
    website: "http://www.cam.ac.uk/",
  },
  {
    value: "2632",
    label: "University of Central Lancashire",
    website: "http://www.uclan.ac.uk/",
  },
  {
    value: "2633",
    label: "University of Chester",
    website: "http://www.chester.ac.uk/",
  },
  {
    value: "2634",
    label: "University of Derby",
    website: "http://www.derby.ac.uk/",
  },
  {
    value: "2635",
    label: "University of Dundee",
    website: "http://www.dundee.ac.uk/",
  },
  {
    value: "2636",
    label: "University of Durham",
    website: "http://www.dur.ac.uk/",
  },
  {
    value: "2637",
    label: "University of East Anglia",
    website: "http://www.uea.ac.uk/",
  },
  {
    value: "2638",
    label: "University of East London",
    website: "http://www.uel.ac.uk/",
  },
  {
    value: "2639",
    label: "University of Edinburgh",
    website: "http://www.ed.ac.uk/",
  },
  {
    value: "2640",
    label: "University of Essex",
    website: "http://www.essex.ac.uk/",
  },
  {
    value: "2641",
    label: "University of Exeter",
    website: "http://www.ex.ac.uk/",
  },
  {
    value: "2642",
    label: "University of Glamorgan",
    website: "http://www.glam.ac.uk/",
  },
  {
    value: "2643",
    label: "University of Glasgow",
    website: "http://www.gla.ac.uk/",
  },
  {
    value: "2644",
    label: "University of Gloucestershire",
    website: "http://www.glos.ac.uk/",
  },
  {
    value: "2645",
    label: "University of Greenwich",
    website: "http://www.gre.ac.uk/",
  },
  {
    value: "2646",
    label: "University of Halifax, Birmingham Campus",
    website: "http://www.halifaxuni.ac/",
  },
  {
    value: "2647",
    label: "University of Hertfordshire",
    website: "http://www.herts.ac.uk/",
  },
  {
    value: "2648",
    label: "University of Huddersfield",
    website: "http://www.hud.ac.uk/",
  },
  {
    value: "2649",
    label: "University of Hull",
    website: "http://www.hull.ac.uk/",
  },
  {
    value: "2650",
    label: "University of Kent at Canterbury",
    website: "http://www.ukc.ac.uk/",
  },
  {
    value: "2651",
    label: "University of Lancaster",
    website: "http://www.lancs.ac.uk/",
  },
  {
    value: "2652",
    label: "University of Leeds",
    website: "http://www.leeds.ac.uk/",
  },
  {
    value: "2653",
    label: "University of Leicester",
    website: "http://www.le.ac.uk/",
  },
  {
    value: "2654",
    label: "University of Lincoln",
    website: "http://www.lincoln.ac.uk/",
  },
  {
    value: "2655",
    label: "University of Liverpool",
    website: "http://www.liv.ac.uk/",
  },
  {
    value: "2656",
    label: "University of London",
    website: "http://www.lon.ac.uk/",
  },
  {
    value: "2657",
    label: "University of Manchester",
    website: "http://www.man.ac.uk/",
  },
  {
    value: "2658",
    label: "University of Newcastle-upon-Tyne",
    website: "http://www.ncl.ac.uk/",
  },
  {
    value: "2659",
    label: "University of Northampton",
    website: "http://www.northampton.ac.uk/",
  },
  {
    value: "2660",
    label: "University of North London",
    website: "http://www.unl.ac.uk/",
  },
  {
    value: "2661",
    label: "University of Northumbria at Newcastle",
    website: "http://www.unn.ac.uk/",
  },
  {
    value: "2662",
    label: "University of Nottingham",
    website: "http://www.nottingham.ac.uk/",
  },
  {
    value: "2663",
    label: "University of Oxford",
    website: "http://www.ox.ac.uk/",
  },
  {
    value: "2664",
    label: "University of Paisley",
    website: "http://www.paisley.ac.uk/",
  },
  {
    value: "2665",
    label: "University of Plymouth",
    website: "http://www.plymouth.ac.uk/",
  },
  {
    value: "2666",
    label: "University of Portsmouth",
    website: "http://www.port.ac.uk/",
  },
  {
    value: "2667",
    label: "University of Reading",
    website: "http://www.rdg.ac.uk/",
  },
  {
    value: "2668",
    label: "University of Salford",
    website: "http://www.salford.ac.uk/",
  },
  {
    value: "2669",
    label: "University of Sheffield",
    website: "http://www.shef.ac.uk/",
  },
  {
    value: "2670",
    label: "University of Southampton",
    website: "http://www.soton.ac.uk/",
  },
  {
    value: "2671",
    label: "University of St. Andrews",
    website: "http://www.st-and.ac.uk/",
  },
  {
    value: "2672",
    label: "University of Stirling",
    website: "http://www.stir.ac.uk/",
  },
  {
    value: "2673",
    label: "University of Strathclyde",
    website: "http://www.strath.ac.uk/",
  },
  {
    value: "2674",
    label: "University of Sunderland",
    website: "http://www.sunderland.ac.uk/",
  },
  {
    value: "2675",
    label: "University of Surrey",
    website: "http://www.surrey.ac.uk/",
  },
  {
    value: "2676",
    label: "University of Sussex",
    website: "http://www.sussex.ac.uk/",
  },
  {
    value: "2677",
    label: "University of Teesside",
    website: "http://www.tees.ac.uk/",
  },
  {
    value: "2678",
    label: "University of the West of England, Bristol",
    website: "http://www.uwe.ac.uk/",
  },
  {
    value: "2679",
    label: "University of Ulster",
    website: "http://www.ulst.ac.uk/",
  },
  {
    value: "2680",
    label: "University of Wales",
    website: "http://www.wales.ac.uk/",
  },
  {
    value: "2681",
    label: "University of Wales, Aberystwyth",
    website: "http://www.aber.ac.uk/",
  },
  {
    value: "2682",
    label: "University of Wales, Bangor",
    website: "http://www.bangor.ac.uk/",
  },
  {
    value: "2683",
    label: "University of Wales College of Medicine",
    website: "http://www.uwcm.ac.uk/",
  },
  {
    value: "2684",
    label: "University of Wales Institute, Cardiff",
    website: "http://www.uwic.ac.uk/",
  },
  {
    value: "2685",
    label: "University of Wales, Lampeter",
    website: "http://www.lamp.ac.uk/",
  },
  {
    value: "2686",
    label: "University of Wales, Newport",
    website: "http://www.newport.ac.uk/",
  },
  {
    value: "2687",
    label: "University of Wales, Swansea",
    website: "http://www.swan.ac.uk/",
  },
  {
    value: "2688",
    label: "University of Warwick",
    website: "http://www.warwick.ac.uk/",
  },
  {
    value: "2689",
    label: "University of Westminster",
    website: "http://www.westminster.ac.uk/",
  },
  {
    value: "2690",
    label: "University of Wolverhampton",
    website: "http://www.wlv.ac.uk/",
  },
  {
    value: "2691",
    label: "University of Worcester",
    website: "http://www.worc.ac.uk/",
  },
  {
    value: "2692",
    label: "University of York",
    website: "http://www.york.ac.uk/",
  },
  {
    value: "2693",
    label: "Warburg Institute, University of London",
    website: "http://www.sas.ac.uk/warburg/",
  },
  {
    value: "2694",
    label: "Warnborough University",
    website: "http://www.warnborough.edu/",
  },
  {
    value: "2695",
    label: "William Gilbert College",
    website: "http://www.williamgilbert.co.uk/",
  },
  {
    value: "2696",
    label: "Wimbledon School of Art",
    website: "http://www.wimbledon.ac.uk/",
  },
  {
    value: "2697",
    label: "St. George's University",
    website: "http://www.sgu.edu/",
  },
  {
    value: "2698",
    label: "Agricultural University of Georgia",
    website: "http://www.agruni.edu.ge/",
  },
  {
    value: "2699",
    label: "Akaki Tsereteli State University",
    website: "http://www.atsu.edu.ge/",
  },
  {
    value: "2700",
    label: "Caucasus University",
    website: "http://www.cu.edu.ge/",
  },
  {
    value: "2701",
    label: "Free University of Tbilisi",
    website: "http://www.freeuni.ge/",
  },
  {
    value: "2702",
    label: "Georgian Technical University",
    website: "http://www.gtu.edu.ge/",
  },
  {
    value: "2703",
    label: "Ilia Chavchavadze State University",
    website: "http://www.iliauni.edu.ge/",
  },
  {
    value: "2704",
    label: "International Blacksea University",
    website: "http://www.ibsu.edu.ge/",
  },
  {
    value: "2705",
    label: "St. Andrew Georgian University",
    website: "http://www.sangu.ge/",
  },
  {
    value: "2706",
    label: "Tbilisi State Medical University",
    website: "http://www.tsmu.edu/",
  },
  {
    value: "2707",
    label: "Tbilisi State University",
    website: "http://www.tsu.edu.ge/",
  },
  {
    value: "2708",
    label: "Tbilisi Teaching University",
    website: "http://www.tbuniver.edu.ge/",
  },
  {
    value: "2709",
    label: "University of Grigol Robakidze",
    website: "http://www.gruni.edu.ge/",
  },
  {
    value: "2710",
    label: "Université des Antilles et de la Guyane",
    website: "http://www.univ-ag.fr/",
  },
  {
    value: "2711",
    label: "Accra Polytechnic ",
    website: "http://www.accrapolytechnic.edu.gh/",
  },
  {
    value: "2712",
    label: "Akrofi-Christaller Institute of Theeology, Mission and  Culture",
    website: "http://www.acighana.org/",
  },
  {
    value: "2713",
    label: "All Nations University College",
    website: "http://www.anuc.edu.gh/",
  },
  {
    value: "2714",
    label: "Ashesi University",
    website: "http://www.ashesi.edu.gh/",
  },
  {
    value: "2715",
    label: "Cape Coast Polytechnic ",
    website: "http://www.cpoly.edu.gh/",
  },
  {
    value: "2716",
    label: "Central University College",
    website: "http://www.central.edu.gh/",
  },
  {
    value: "2717",
    label: "Ghana Christian University College",
    website: "http://www.ghanacu.org/",
  },
  {
    value: "2718",
    label: "Ghana Institute of Management and Public Administration (GIMPA)",
    website: "http://www.gimpa.edu.gh/",
  },
  {
    value: "2719",
    label: "Ghana Telecom University College",
    website: "http://www.gtuc.edu.gh/",
  },
  {
    value: "2720",
    label: "Ho Polytechnic ",
    website: "http://www.hopoly.edu.gh/",
  },
  {
    value: "2721",
    label: "Islamic University College",
    website: "http://www.iug.edu.gh/",
  },
  {
    value: "2722",
    label: "Koforidua Polytechnic ",
    website: "http://www.koforiduapoly.edu.gh/",
  },
  {
    value: "2723",
    label: "Kumasi Polytechnic ",
    website: "http://www.kpoly.edu.gh/",
  },
  {
    value: "2724",
    label: "Kwame Nkrumah University of Science and Technology",
    website: "http://www.knust.edu.gh/",
  },
  {
    value: "2725",
    label: "Lancaster University Ghana",
    website: "http://www.lancaster.edu.gh/",
  },
  {
    value: "2726",
    label: "Pan African Christian University College",
    website: "http://www.pacuc.edu.gh/",
  },
  {
    value: "2727",
    label: "Prempeh College",
    website: "http://www.prempeh-college.com/",
  },
  {
    value: "2728",
    label: "Presbyterian University College",
    website: "http://www.presbyuniversity.edu.gh/",
  },
  {
    value: "2729",
    label: "Regent University College of Science and Technology  ",
    website: "http://www.regentghana.net/",
  },
  {
    value: "2730",
    label: "Takoradi Polytechnic ",
    website: "http://www.tpoly.edu.gh/",
  },
  {
    value: "2731",
    label: "Trinity Theological Seminary",
    website: "http://www.trinity.edu.gh/",
  },
  {
    value: "2732",
    label: "University for Development Studies",
    website: "http://www.uds.edu.gh/",
  },
  {
    value: "2733",
    label: "University of Cape Coast",
    website: "http://www.ucc.edu.gh/",
  },
  {
    value: "2734",
    label: "University of Education, Winneba",
    website: "http://www.uew.edu.gh/",
  },
  {
    value: "2735",
    label: "University of Ghana",
    website: "http://www.ug.edu.gh/",
  },
  {
    value: "2736",
    label: "Valley View University",
    website: "http://www.vvu.edu.gh/",
  },
  {
    value: "2737",
    label: "University of Greenland",
    website: "http://www.ilisimatusarfik.gl/",
  },
  {
    value: "2738",
    label: "American International University West Africa",
    website: "http://www.aiu.edu.gm/",
  },
  {
    value: "2739",
    label: "EUCLID University",
    website: "http://www.euclid.int/",
  },
  {
    value: "2740",
    label: "University of the Gambia",
    website: "http://www.utg.edu.gm/",
  },
  {
    value: "2741",
    label: "Université Gamal Abdel Nasser de Conakry",
    website: "http://www.uganc.org/",
  },
  {
    value: "2742",
    label: "Université Julius Nyerere Kankan",
    website: "http://www.ujnk.org/",
  },
  {
    value: "2743",
    label: "Université Kofi Annan",
    website: "http://www.univ-kag.org/",
  },
  {
    value: "2744",
    label: "Université Thierno Amadou Diallo",
    website: "http://www.utad-petel-edu.org/",
  },
  {
    value: "2745",
    label: "Université des Antilles et de la Guyane",
    website: "http://www.univ-ag.fr/",
  },
  {
    value: "2746",
    label: "Universidad Nacional de Guinea Ecuatorial",
    website: "http://www.unge.gq/",
  },
  {
    value: "2747",
    label: "Aegean University",
    website: "http://www.aegean.gr/",
  },
  {
    value: "2748",
    label: "Agricultural University of Athens",
    website: "http://www.aua.gr/",
  },
  {
    value: "2749",
    label: "American College of Greece",
    website: "http://www.acg.gr/",
  },
  {
    value: "2750",
    label: "American College of Thessaloniki",
    website: "http://www.act.edu/",
  },
  {
    value: "2751",
    label: "Aristotle University of Thessaloniki",
    website: "http://www.auth.gr/",
  },
  {
    value: "2752",
    label: "Athens Graduate School of Management (AGSM)",
    website: "http://www.agsm.gr/",
  },
  {
    value: "2753",
    label: "Athens Laboratory of Business Administration (ALBA)",
    website: "http://www.alba.edu.gr/",
  },
  {
    value: "2754",
    label: "Athens School of Fine Arts",
    website: "http://www.asfa.gr/",
  },
  {
    value: "2755",
    label: "Athens University of Economics and Business",
    website: "http://www.aueb.gr/",
  },
  {
    value: "2756",
    label: "City University Athens",
    website: "http://www.cityu.gr/",
  },
  {
    value: "2757",
    label: "DEI Bachelor & Master Degrees",
    website: "http://www.dei.edu.gr/",
  },
  {
    value: "2758",
    label: "Dimocritus University of Thrace",
    website: "http://www.duth.gr/",
  },
  {
    value: "2759",
    label: "European University, Athens Campus",
    website: "http://www.euruni.edu/",
  },
  {
    value: "2760",
    label: "Harokopio University",
    website: "http://www.forthnet.gr/harokopio/",
  },
  {
    value: "2761",
    label: "Hellenic Army Academy",
    website: "http://www.sse.gr/",
  },
  {
    value: "2762",
    label: "Hellenic Open University",
    website: "http://www.eap.gr/",
  },
  {
    value: "2763",
    label: "Institute of Management Development - Ohrid",
    website: "http://www.imd.edu.gr/",
  },
  {
    value: "2764",
    label: "Institute of Management & Enteurpreneurship of South East Europe",
    website: "http://www.imese.gr/",
  },
  {
    value: "2765",
    label: "International Hellenic University",
    website: "http://www.ihu.edu.gr/",
  },
  {
    value: "2766",
    label: "Ionian University Corfu",
    website: "http://www.uion.edu.gr/",
  },
  {
    value: "2767",
    label: "National Technical University of Athens",
    website: "http://www.ntua.gr/",
  },
  {
    value: "2768",
    label: "Panteios University of Economics and Political Sciences Athens",
    website: "http://www.panteion.gr/",
  },
  {
    value: "2769",
    label: "Technical University of Crete",
    website: "http://www.tuc.gr/",
  },
  {
    value: "2770",
    label: "Technological Education Institute of Athens",
    website: "http://www.teiath.gr/",
  },
  {
    value: "2771",
    label: "Technological Education Institute of Epiros",
    website: "http://www.teiep.gr/",
  },
  {
    value: "2772",
    label: "Technological Education Institute of Halkida, Euboea",
    website: "http://www.teihal.gr/",
  },
  {
    value: "2773",
    label: "Technological Education Institute of Heraklion",
    website: "http://www.teiher.gr/",
  },
  {
    value: "2774",
    label: "Technological Education Institute of Kalamata",
    website: "http://www.teikal.gr/",
  },
  {
    value: "2775",
    label: "Technological Education Institute of Kavala",
    website: "http://www.teikav.edu.gr/",
  },
  {
    value: "2776",
    label: "Technological Education Institute of Kozani",
    website: "http://www.teikoz.gr/",
  },
  {
    value: "2777",
    label: "Technological Education Institute of Lamia",
    website: "http://www.teilam.gr/",
  },
  {
    value: "2778",
    label: "Technological Education Institute of Larissa",
    website: "http://www.teilar.gr/",
  },
  {
    value: "2779",
    label: "Technological Education Institute of Mesologgi",
    website: "http://www.teimes.gr/",
  },
  {
    value: "2780",
    label: "Technological Education Institute of Patras",
    website: "http://www.teipat.gr/",
  },
  {
    value: "2781",
    label: "Technological Education Institute of Piraeus",
    website: "http://www.teipir.gr/",
  },
  {
    value: "2782",
    label: "Technological Education Institute of Serres",
    website: "http://www.teiser.gr/",
  },
  {
    value: "2783",
    label: "Technological Education Institute of Thessaloniki",
    website: "http://www.teithe.gr/",
  },
  {
    value: "2784",
    label: "University of Athens",
    website: "http://www.uoa.gr/",
  },
  {
    value: "2785",
    label: "University of Central Greece",
    website: "http://www.ucg.gr/",
  },
  {
    value: "2786",
    label: "University of Crete",
    website: "http://www.uoc.gr/",
  },
  {
    value: "2787",
    label: "University of Indianapolis in Athens",
    website: "http://www.uindy.edu.gr/",
  },
  {
    value: "2788",
    label: "University of Ioannina",
    website: "http://www.uoi.gr/",
  },
  {
    value: "2789",
    label: "University of LaVerne in Athens",
    website: "http://www.laverne.edu.gr/",
  },
  {
    value: "2790",
    label: "University of Macedonia",
    website: "http://www.uom.gr/",
  },
  {
    value: "2791",
    label: "University of Patras",
    website: "http://www.upatras.gr/",
  },
  {
    value: "2792",
    label: "University of Peloponnese",
    website: "http://www.uop.gr/",
  },
  {
    value: "2793",
    label: "University of Piraeus",
    website: "http://www.unipi.gr/",
  },
  {
    value: "2794",
    label: "University of Thessaly",
    website: "http://www.uth.gr/",
  },
  {
    value: "2795",
    label: "University of Western Macedonia",
    website: "http://www.uowm.gr/",
  },
  {
    value: "2796",
    label: "Yorker International University, Athens",
    website: "http://www.nyuniversity.net/",
  },
  {
    value: "2797",
    label: "Centro Universitario Ciudad Vieja",
    website: "http://www.cucv.edu.gt/",
  },
  {
    value: "2798",
    label: "Centro Universitario de Occidente",
    website: "http://www.cunoc.edu.gt/",
  },
  {
    value: "2799",
    label: "Universidad del Istmo",
    website: "http://www.unis.edu.gt/",
  },
  {
    value: "2800",
    label: "Universidad del Valle de Guatemala",
    website: "http://www.uvg.edu.gt/",
  },
  {
    value: "2801",
    label: "Universidad de San Carlos de Guatemala",
    website: "http://www.usac.edu.gt/",
  },
  {
    value: "2802",
    label: "Universidad Francisco Marroquín",
    website: "http://www.ufm.edu.gt/",
  },
  {
    value: "2803",
    label: "Universidad Galileo",
    website: "http://www.galileo.edu/",
  },
  {
    value: "2804",
    label: "Universidad Mariano Gálvez",
    website: "http://www.umg.edu.gt/",
  },
  {
    value: "2805",
    label: "Universidad Panamericana",
    website: "http://www.upana.edu.gt/",
  },
  {
    value: "2806",
    label: "Universidad Rafael Landívar",
    website: "http://www.url.edu.gt/",
  },
  {
    value: "2807",
    label: "Universidad Rural de Guatemala",
    website: "http://www.urural.edu.gt/",
  },
  {
    value: "2808",
    label: "University of Guam",
    website: "http://www.uog.edu/",
  },
  {
    value: "2809",
    label: "Gemsville Technical University",
    website: "http://www.gemsvilleuniversity.com/",
  },
  {
    value: "2810",
    label: "Greenheart Medical School",
    website: "http://www.greenheartmed.org/",
  },
  {
    value: "2811",
    label: "Texila American University",
    website: "http://www.tauedu.org/",
  },
  {
    value: "2812",
    label: "University of Guyana",
    website: "http://www.uog.edu.gy/",
  },
  {
    value: "2813",
    label: "Chinese University of Hong Kong",
    website: "http://www.cuhk.hk/",
  },
  {
    value: "2814",
    label: "Chu Hai College",
    website: "http://www.chuhai.edu.hk/",
  },
  {
    value: "2815",
    label: "City University of Hong Kong",
    website: "http://www.cityu.edu.hk/",
  },
  {
    value: "2816",
    label: "Hong Kong Academy for Performing Arts ",
    website: "http://www.hkapa.edu/",
  },
  {
    value: "2817",
    label: "Hong Kong Baptist University",
    website: "http://www.hkbu.edu.hk/",
  },
  {
    value: "2818",
    label: "Hong Kong Institute of Education",
    website: "http://www.ied.edu.hk/",
  },
  {
    value: "2819",
    label: "Hong Kong Polytechnic University",
    website: "http://www.polyu.edu.hk/",
  },
  {
    value: "2820",
    label: "Hong Kong Shue Yan College",
    website: "http://www.hksyc.edu/",
  },
  {
    value: "2821",
    label: "Hong Kong University of Science and Technology",
    website: "http://www.ust.hk/",
  },
  {
    value: "2822",
    label: "Lingnan University",
    website: "http://www.ln.edu.hk/",
  },
  {
    value: "2823",
    label: "Open University of Hong Kong",
    website: "http://www.ouhk.edu.hk/",
  },
  {
    value: "2824",
    label: "University of Hong Kong",
    website: "http://www.hku.hk/",
  },
  {
    value: "2825",
    label: "Escuela Agricola Panamericana Zamorano",
    website: "http://www.zamorano.edu/",
  },
  {
    value: "2826",
    label: "Universidad Católica de Honduras",
    website: "http://www.unicah.edu/",
  },
  {
    value: "2827",
    label: "Universidad de San Pedro Sula",
    website: "http://www.usps.edu.hn/",
  },
  {
    value: "2828",
    label: "Universidad José Cecilio del Valle",
    website: "http://www.ujcv.edu.hn/",
  },
  {
    value: "2829",
    label: "Universidad Metropolitana de Honduras",
    website: "http://www.unimetro.edu.hn/",
  },
  {
    value: "2830",
    label: "Universidad Nacional Autónoma de Honduras",
    website: "http://www.unah.edu.hn/",
  },
  {
    value: "2831",
    label: 'Universidad Pedagógica Nacional "Francisco Morazán"',
    website: "http://www.upnfm.edu.hn/",
  },
  {
    value: "2832",
    label: "Universidad Tecnológica Centroamericana",
    website: "http://www.unitec.edu/",
  },
  {
    value: "2833",
    label: "Universidad Tecnológica de Honduras",
    website: "http://www.uth.hn/",
  },
  {
    value: "2834",
    label: "University of Dubrovnik",
    website: "http://www.unidu.hr/",
  },
  {
    value: "2835",
    label: "University of Osijek",
    website: "http://www.unios.hr/",
  },
  {
    value: "2836",
    label: "University of Pula",
    website: "http://www.unipu.hr/",
  },
  {
    value: "2837",
    label: "University of Rijeka",
    website: "http://www.uniri.hr/",
  },
  {
    value: "2838",
    label: "University of Split",
    website: "http://www.unist.hr/",
  },
  {
    value: "2839",
    label: "University of Zadar",
    website: "http://www.unizd.hr/",
  },
  {
    value: "2840",
    label: "University of Zagreb",
    website: "http://www.unizg.hr/",
  },
  {
    value: "2841",
    label: "Zagreb School of Economics and Management",
    website: "http://www.zsem.hr/",
  },
  {
    value: "2842",
    label: "American University of the Caribbean",
    website: "http://www.auchaiti.org/",
  },
  {
    value: "2843",
    label: "Université Chrétienne du Nord d'Haiti",
    website: "http://www.ucnh.org/",
  },
  {
    value: "2844",
    label: "Université d'Etat d'Haiti",
    website: "http://www.ueh.edu.ht/",
  },
  {
    value: "2845",
    label: "Université Épiscopale d'Haiti",
    website: "http://www.uneph.org/",
  },
  {
    value: "2846",
    label: "Université Notre Dame d'Haïti",
    website: "http://www.undh.org/",
  },
  {
    value: "2847",
    label: "Université Quisqueya",
    website: "http://www.uniq.edu/",
  },
  {
    value: "2848",
    label: "Academy of Drama and Film",
    website: "http://www.filmacademy.hu/",
  },
  {
    value: "2849",
    label: "Andrassy Gyula German Speaking University Budapest",
    website: "http://www.andrassyuni.hu/",
  },
  {
    value: "2850",
    label: "Avicenna International College",
    website: "http://www.avicenna.hu/",
  },
  {
    value: "2851",
    label: "Budapest Buddhist University",
    website: "http://www.tkbf.hu/",
  },
  {
    value: "2852",
    label: "Budapest University of Economic Sciences and Public Administration",
    website: "http://www.bke.hu/",
  },
  {
    value: "2853",
    label: "Central European University",
    website: "http://www.ceu.hu/",
  },
  {
    value: "2854",
    label: "College of Dunaujvaros",
    website: "http://www.duf.hu/",
  },
  {
    value: "2855",
    label: "Dániel Berzsenyi Teacher Training College",
    website: "http://www.bdtf.hu/",
  },
  {
    value: "2856",
    label: "Debrecen University of Agricultural Sciences",
    website: "http://www.agr.unideb.hu/",
  },
  {
    value: "2857",
    label: "Eötvös Lorand University",
    website: "http://www.elte.hu/",
  },
  {
    value: "2858",
    label: "Esztergom Theological College",
    website: "http://www.ehf.hu/",
  },
  {
    value: "2859",
    label: "Haynal Imre University of Health Sciences Budapest",
    website: "http://www.hiete.hu/",
  },
  {
    value: "2860",
    label: "Hungarian Academy of Craft and Design",
    website: "http://www.mif.hu/",
  },
  {
    value: "2861",
    label: "Hungarian Academy of Fine Arts Budapest",
    website: "http://www.arts7.hu/",
  },
  {
    value: "2862",
    label: "Hungarian University of Physical Education",
    website: "http://www.hupe.hu/",
  },
  {
    value: "2863",
    label: "Janus Pannonius University",
    website: "http://www.jpte.hu/",
  },
  {
    value: "2864",
    label: "Károl Gáspár University of the Reformed Church",
    website: "http://www.kgre.hu/",
  },
  {
    value: "2865",
    label: "Kodolanyi Janos University College",
    website: "http://www.kodolanyi.hu/",
  },
  {
    value: "2866",
    label: "Kossuth Lajos University",
    website: "http://www.lib.klte.hu/",
  },
  {
    value: "2867",
    label: "Liszt Ferenc Academy of Music Budapest",
    website: "http://www.lfze.hu/",
  },
  {
    value: "2868",
    label: "Pázmány Péter Catholic University",
    website: "http://www.ppke.hu/",
  },
  {
    value: "2869",
    label: "Reformed Theological Academy of Debrecen",
    website: "http://www.drk.hu/",
  },
  {
    value: "2870",
    label: "Séchenyi István University",
    website: "http://www.sze.hu/",
  },
  {
    value: "2871",
    label: "Semmelweis University of Medical Sciences",
    website: "http://www.sote.hu/",
  },
  {
    value: "2872",
    label: "Szent István University",
    website: "http://www.szie.hu/",
  },
  {
    value: "2873",
    label: "Technical University of Budapest",
    website: "http://www.bme.hu/",
  },
  {
    value: "2874",
    label: "University Medical School of Debrecen",
    website: "http://www.dote.hu/",
  },
  {
    value: "2875",
    label: "University Medical School of Pécs",
    website: "http://www.pote.hu/",
  },
  {
    value: "2876",
    label: "University of Debrecen ",
    website: "http://www.unideb.hu/",
  },
  {
    value: "2877",
    label: "University of Horticulture and Food Industry",
    website: "http://www.kee.hu/",
  },
  {
    value: "2878",
    label: "University of Miskolc",
    website: "http://www.uni-miskolc.hu/",
  },
  {
    value: "2879",
    label: "University of Pannonia",
    website: "http://www.uni-pannon.hu/",
  },
  {
    value: "2880",
    label: "University of Pecs",
    website: "http://www.pte.hu/",
  },
  {
    value: "2881",
    label: "University of Sopron",
    website: "http://www.efe.hu/",
  },
  {
    value: "2882",
    label: "University of Szeged",
    website: "http://www.u-szeged.hu/",
  },
  {
    value: "2883",
    label: "University of Veterinary Science",
    website: "http://www.univet.hu/",
  },
  {
    value: "2884",
    label: "University of West Hungary",
    website: "http://www.nyme.hu/",
  },
  {
    value: "2885",
    label: "Abfi Institute Perbanas Jakarta",
    website: "http://www.perbanasinstitute.ac.id/",
  },
  {
    value: "2886",
    label: "Binus University",
    website: "http://www.binus.ac.id/",
  },
  {
    value: "2887",
    label: "Bogor Agricultural University",
    website: "http://www.ipb.ac.id/",
  },
  {
    value: "2888",
    label: "Institut Sains dan Teknologi Al Kamal",
    website: "http://www.ista.ac.id/",
  },
  {
    value: "2889",
    label: "Institut Sains & Teknologi Akprind",
    website: "http://www.akprind.ac.id/",
  },
  {
    value: "2890",
    label: "Institut Teknologi Adhi Tama Surabaya",
    website: "http://www.itats.ac.id/",
  },
  {
    value: "2891",
    label: "Institut Teknologi Bandung",
    website: "http://www.itb.ac.id/",
  },
  {
    value: "2892",
    label: "Institut Teknologi Sepuluh Nopember",
    website: "http://www.its.ac.id/",
  },
  {
    value: "2893",
    label: "Institut Teknologi Telkom",
    website: "http://www.stttelkom.ac.id/",
  },
  {
    value: "2894",
    label: "Politeknik Negeri Bandung",
    website: "http://www.polban.ac.id/",
  },
  {
    value: "2895",
    label: "Politeknik Negeri Jakarta",
    website: "http://www.pnj.ac.id/",
  },
  {
    value: "2896",
    label: "Politeknik Negeri Lhokseumawe",
    website: "http://www.pnl.ac.id/",
  },
  {
    value: "2897",
    label: "Politeknik Negeri Malang",
    website: "http://www.poltek-malang.ac.id/",
  },
  {
    value: "2898",
    label: "Politeknik Negeri Padang",
    website: "http://www.polinpdg.ac.id/",
  },
  {
    value: "2899",
    label: "Politeknik Negeri Pontianak",
    website: "http://www.polnep.ac.id/",
  },
  {
    value: "2900",
    label: "Politeknik Negeri Sambas",
    website: "http://www.poltesa.ac.id/",
  },
  {
    value: "2901",
    label: "Politeknik Negeri Semarang",
    website: "http://www.polines.ac.id/",
  },
  {
    value: "2902",
    label: "Politeknik Pos Indonesia",
    website: "http://www.poltekpos.ac.id/",
  },
  {
    value: "2903",
    label: "Prasetiya Mulya Business School",
    website: "http://www.pmbs.ac.id/",
  },
  {
    value: "2904",
    label: "Sekolah Tinggi Akuntansi Negara (STAN)",
    website: "http://www.stan.ac.id/",
  },
  {
    value: "2905",
    label: "Stie Perbanas Surabaya",
    website: "http://www.perbanas.ac.id/",
  },
  {
    value: "2906",
    label: "STMIK AMIKOM Yogyakarta",
    website: "http://www.amikom.ac.id/",
  },
  {
    value: "2907",
    label: "STMIK Sinar Nusantara",
    website: "http://www.sinus.ac.id/",
  },
  {
    value: "2908",
    label: "Swiss German University",
    website: "http://www.sgu.ac.id/",
  },
  {
    value: "2909",
    label: "Trisakti Institute of Tourism ",
    website: "http://www.stptrisakti.ac.id/",
  },
  {
    value: "2910",
    label: "Unitomo Surabaya",
    website: "http://www.unitomo.ac.id/",
  },
  {
    value: "2911",
    label: "Universitas 17 Agustus 1945 Banyuwangi",
    website: "http://www.untag-banyuwangi.ac.id/",
  },
  {
    value: "2912",
    label: "Universitas 17 Agustus 1945 Cirebon",
    website: "http://www.untagcirebon.info/",
  },
  {
    value: "2913",
    label: "Universitas 17 Agustus 1945 Jakarta",
    website: "http://www.untag-jkt.org/",
  },
  {
    value: "2914",
    label: "Universitas 17 Agustus 1945 Samarinda",
    website: "http://www.untag-smd.ac.id/",
  },
  {
    value: "2915",
    label: "Universitas 17 Agustus 1945 Semarang",
    website: "http://www.untagsmg.ac.id/",
  },
  {
    value: "2916",
    label: "Universitas 17 Agustus 1945 Surabaya",
    website: "http://www.untag.ac.id/",
  },
  {
    value: "2917",
    label: "Universitas Advent Indonesia",
    website: "http://www.unai.edu/",
  },
  {
    value: "2918",
    label: "Universitas Ahmad Dahlan",
    website: "http://www.uad.ac.id/",
  },
  {
    value: "2919",
    label: "Universitas Airlangga",
    website: "http://www.unair.ac.id/",
  },
  {
    value: "2920",
    label: "Universitas Amir Hamzah",
    website: "http://www.unhamzah.ac.id/",
  },
  {
    value: "2921",
    label: "Universitas Andalas",
    website: "http://www.unand.ac.id/",
  },
  {
    value: "2922",
    label: "Universitas Atma Jaya Yogyakarta",
    website: "http://www.uajy.ac.id/",
  },
  {
    value: "2923",
    label: "Universitas Bakrie",
    website: "http://www.bakrie.ac.id/",
  },
  {
    value: "2924",
    label: "Universitas Bengkulu",
    website: "http://www.unib.ac.id/",
  },
  {
    value: "2925",
    label: "Universitas Bhayangkara Jakarta Raya",
    website: "http://www.ubharajaya.ac.id/",
  },
  {
    value: "2926",
    label: "Universitas Bhayangkara Surabaya",
    website: "http://www.ubhara.ac.id/",
  },
  {
    value: "2927",
    label: "Universitas Bojonegoro",
    website: "http://www.unigoro.ac.id/",
  },
  {
    value: "2928",
    label: "Universitas Brawijaya",
    website: "http://www.ub.ac.id/",
  },
  {
    value: "2929",
    label: "Universitas Bunda Mulia Jakarta",
    website: "http://www.ubm.ac.id/",
  },
  {
    value: "2930",
    label: "Universitas Bung Hatta",
    website: "http://www.bunghatta.ac.id/",
  },
  {
    value: "2931",
    label: "Universitas Cenderawasih",
    website: "http://www.uncen.ac.id/",
  },
  {
    value: "2932",
    label: "Universitas Darma Agung",
    website: "http://www.uda.ac.id/",
  },
  {
    value: "2933",
    label: "Universitas Darma Persada",
    website: "http://www.unsada.ac.id/",
  },
  {
    value: "2934",
    label: "Universitas Dian Nuswantoro",
    website: "http://www.dinus.ac.id/",
  },
  {
    value: "2935",
    label: "Universitas Diponegoro",
    website: "http://www.undip.ac.id/",
  },
  {
    value: "2936",
    label: "Universitas Dr. R. Moestopo",
    website: "http://www.moestopo.ac.id/",
  },
  {
    value: "2937",
    label: "Universitas Gadjah Mada",
    website: "http://www.ugm.ac.id/",
  },
  {
    value: "2938",
    label: "Universitas Gajayana Malang",
    website: "http://www.unigamalang.ac.id/",
  },
  {
    value: "2939",
    label: "Universitas Gunadarma",
    website: "http://www.gunadarma.ac.id/",
  },
  {
    value: "2940",
    label: "Universitas Haluoleo",
    website: "http://www.unhalu.ac.id/",
  },
  {
    value: "2941",
    label: "Universitas Hasanuddin",
    website: "http://www.unhas.ac.id/",
  },
  {
    value: "2942",
    label: "Universitas HKBP Nommensen",
    website: "http://www.nommensen.org/",
  },
  {
    value: "2943",
    label: "Universitas Ibn Chaldun Jakarta",
    website: "http://www.ibnuchaldun.com/",
  },
  {
    value: "2944",
    label: "Universitas Ibn Khaldun Bogor",
    website: "http://www.uika-bogor.ac.id/",
  },
  {
    value: "2945",
    label: "Universitas Indonesia",
    website: "http://www.ui.ac.id/",
  },
  {
    value: "2946",
    label: "Universitas Indonusa Esa Unggul",
    website: "http://www.indonusa.ac.id/",
  },
  {
    value: "2947",
    label: "Universitas Islam Bandung",
    website: "http://www.unisba.ac.id/",
  },
  {
    value: "2948",
    label: "Universitas Islam Indonesia",
    website: "http://www.uii.ac.id/",
  },
  {
    value: "2949",
    label: "Universitas Islam Jakarta",
    website: "http://www.uinjkt.ac.id/",
  },
  {
    value: "2950",
    label: "Universitas Islam Malang",
    website: "http://www.unisma.ac.id/",
  },
  {
    value: "2951",
    label: "Universitas Islam Nusantara",
    website: "http://www.uninus.ac.id/",
  },
  {
    value: "2952",
    label: "Universitas Islam Riau",
    website: "http://www.uir.ac.id/",
  },
  {
    value: "2953",
    label: "Universitas Islam Sultan Agung",
    website: "http://www.unissula.ac.id/",
  },
  {
    value: "2954",
    label: "Universitas Islam Sumatera Utara",
    website: "http://www.uisu.ac.id/",
  },
  {
    value: "2955",
    label: "Universitas Islam Syekh-Yusuf ",
    website: "http://www.unistangerang.ac.id/",
  },
  {
    value: "2956",
    label: "Universitas Jambi",
    website: "http://www.unja.ac.id/",
  },
  {
    value: "2957",
    label: "Universitas Janabadra",
    website: "http://www.janabadra.ac.id/",
  },
  {
    value: "2958",
    label: "Universitas Jayabaya",
    website: "http://www.jayabaya.ac.id/",
  },
  {
    value: "2959",
    label: "Universitas Jember",
    website: "http://www.unej.ac.id/",
  },
  {
    value: "2960",
    label: "Universitas Jenderal Achmad Yani",
    website: "http://www.unjani.ac.id/",
  },
  {
    value: "2961",
    label: "Universitas Jenderal Soedirman",
    website: "http://www.unsoed.ac.id/",
  },
  {
    value: "2962",
    label: "Universitas Katolik Indonesia Atma Jaya",
    website: "http://www.atmajaya.ac.id/",
  },
  {
    value: "2963",
    label: "Universitas Katolik Parahyangan",
    website: "http://www.unpar.ac.id/",
  },
  {
    value: "2964",
    label: "Universitas Katolik Soegijapranoto",
    website: "http://www.unika.ac.id/",
  },
  {
    value: "2965",
    label: "Universitas Katolik Widya Karya",
    website: "http://www.widyakarya.ac.id/",
  },
  {
    value: "2966",
    label: "Universitas Katolik Widya Manadala",
    website: "http://www.wima.ac.id/",
  },
  {
    value: "2967",
    label: "Universitas Kediri",
    website: "http://www.unik-kediri.ac.id/",
  },
  {
    value: "2968",
    label: "Universitas Khairun",
    website: "http://www.unkhair.ac.id/",
  },
  {
    value: "2969",
    label: "Universitas Klabat",
    website: "http://www.tagnet.org/unklab/",
  },
  {
    value: "2970",
    label: "Universitas Komputer Indonesia",
    website: "http://www.unikom.ac.id/",
  },
  {
    value: "2971",
    label: "Universitas Krisnadwipayana",
    website: "http://www.unkris.ac.id/",
  },
  {
    value: "2972",
    label: "Universitas Kristen Duta Wacana",
    website: "http://www.ukdw.ac.id/",
  },
  {
    value: "2973",
    label: "Universitas Kristen Indonesia",
    website: "http://www.uki.ac.id/",
  },
  {
    value: "2974",
    label: "Universitas Kristen Indonesia Tomohon",
    website: "http://www.ukit-tomohon.org/",
  },
  {
    value: "2975",
    label: "Universitas Kristen Krida Wacana",
    website: "http://www.ukrida.ac.id/",
  },
  {
    value: "2976",
    label: "Universitas Kristen Maranatha",
    website: "http://www.maranatha.edu/",
  },
  {
    value: "2977",
    label: "Universitas Kristen Petra",
    website: "http://www.petra.ac.id/",
  },
  {
    value: "2978",
    label: "Universitas Kristen Satya Wacana",
    website: "http://www.uksw.edu/",
  },
  {
    value: "2979",
    label: "Universitas Lambung Mangkurat",
    website: "http://www.unlam.ac.id/",
  },
  {
    value: "2980",
    label: "Universitas Lampung",
    website: "http://www.unila.ac.id/",
  },
  {
    value: "2981",
    label: "Universitas Lancang Kuning",
    website: "http://unilak.ac.id/",
  },
  {
    value: "2982",
    label: "Universitas Ma Chung",
    website: "http://www.machung.ac.id/",
  },
  {
    value: "2983",
    label: "Universitas Madura",
    website: "http://www.unira-pmk.net/",
  },
  {
    value: "2984",
    label: "Universitas Mahasaraswati Denpasar",
    website: "http://www.unmas.org/",
  },
  {
    value: "2985",
    label: "Universitas Mahendradatta",
    website: "http://www.mahendradatta.org/",
  },
  {
    value: "2986",
    label: "Universitas Mataram",
    website: "http://www.unram.ac.id/",
  },
  {
    value: "2987",
    label: "Universitas Mercu Buana",
    website: "http://www.mercubuana.ac.id/",
  },
  {
    value: "2988",
    label: "Universitas Merdeka Madiun",
    website: "http://www.unmer-madiun.ac.id/",
  },
  {
    value: "2989",
    label: "Universitas Merdeka Malang",
    website: "http://www.unmer.ac.id/",
  },
  {
    value: "2990",
    label: "Universitas Methodist Indonesia",
    website: "http://www.umi-mdn.edu/",
  },
  {
    value: "2991",
    label: "Universitas Muhammadiyah Jakarta",
    website: "http://www.umj.ac.id/",
  },
  {
    value: "2992",
    label: "Universitas Muhammadiyah Jember",
    website: "http://www.unmuhjember.ac.id/",
  },
  {
    value: "2993",
    label: "Universitas Muhammadiyah Magelang",
    website: "http://www.ummgl.ac.id/",
  },
  {
    value: "2994",
    label: "Universitas Muhammadiyah Makassar",
    website: "http://www.unismuh.ac.id/",
  },
  {
    value: "2995",
    label: "Universitas Muhammadiyah Malang",
    website: "http://www.umm.ac.id/",
  },
  {
    value: "2996",
    label: "Universitas Muhammadiyah Mataram",
    website: "http://www.unmuhmataram.com/",
  },
  {
    value: "2997",
    label: "Universitas Muhammadiyah Sumatra Utara",
    website: "http://www.umsu.ac.id/",
  },
  {
    value: "2998",
    label: "Universitas Muhammadiyah Surakarta",
    website: "http://www.ums.ac.id/",
  },
  {
    value: "2999",
    label: "Universitas Muhammadiyah Yogyakarta",
    website: "http://www.umy.ac.id/",
  },
  {
    value: "3000",
    label: "Universitas Mulawarman",
    website: "http://www.unmul.ac.id/",
  },
  {
    value: "3001",
    label: "Universitas Muria Kudus",
    website: "http://www.umk.ac.id/",
  },
  {
    value: "3002",
    label: "Universitas Muslim Indonesia",
    website: "http://www.umi.ac.id/",
  },
  {
    value: "3003",
    label: "Universitas Narotama",
    website: "http://www.narotama.ac.id/",
  },
  {
    value: "3004",
    label: "Universitas Nasional Jakarta",
    website: "http://www.unas.ac.id/",
  },
  {
    value: "3005",
    label: "Universitas Nasional Pasim",
    website: "http://www.pasim.ac.id/",
  },
  {
    value: "3006",
    label: "Universitas Negeri Gorontalo",
    website: "http://www.ung.ac.id/",
  },
  {
    value: "3007",
    label: "Universitas Negeri Jakarta",
    website: "http://www.unj.ac.id/",
  },
  {
    value: "3008",
    label: "Universitas Negeri Makassar",
    website: "http://www.unm.ac.id/",
  },
  {
    value: "3009",
    label: "Universitas Negeri Malang",
    website: "http://www.malang.ac.id/",
  },
  {
    value: "3010",
    label: "Universitas Negeri Manado",
    website: "http://www.unima.ac.id/",
  },
  {
    value: "3011",
    label: "Universitas Negeri Medan",
    website: "http://www.unimed.ac.id/",
  },
  {
    value: "3012",
    label: "Universitas Negeri Padang",
    website: "http://www.unp.ac.id/",
  },
  {
    value: "3013",
    label: "Universitas Negeri Semarang",
    website: "http://www.unnes.ac.id/",
  },
  {
    value: "3014",
    label: "Universitas Negeri Surabaya",
    website: "http://www.unesa.ac.id/",
  },
  {
    value: "3015",
    label: "Universitas Negeri Yogyakarta",
    website: "http://www.uny.ac.id/",
  },
  {
    value: "3016",
    label: "Universitas Ngurah Rai",
    website: "http://www.unr.ac.id/",
  },
  {
    value: "3017",
    label: "Universitas Nusa Cendana",
    website: "http://www.undana.ac.id/",
  },
  {
    value: "3018",
    label: "Universitas Padjadjaran",
    website: "http://www.unpad.ac.id/",
  },
  {
    value: "3019",
    label: "Universitas Pakuan",
    website: "http://www.unpak.ac.id/",
  },
  {
    value: "3020",
    label: "Universitas Palangka Raya",
    website: "http://www.upr.ac.id/",
  },
  {
    value: "3021",
    label: "Universitas Pancasakti Tegal",
    website: "http://www.upstegal.ac.id/",
  },
  {
    value: "3022",
    label: "Universitas Pancasila",
    website: "http://www.univpancasila.ac.id/",
  },
  {
    value: "3023",
    label: "Universitas Paramadina Mulya",
    website: "http://www.paramadina.ac.id/",
  },
  {
    value: "3024",
    label: "Universitas Pasundan",
    website: "http://www.unpas.ac.id/",
  },
  {
    value: "3025",
    label: "Universitas Pattimura",
    website: "http://www.unpatti.ac.id/",
  },
  {
    value: "3026",
    label: "Universitas Pekalongan",
    website: "http://www.unikal.ac.id/",
  },
  {
    value: "3027",
    label: "Universitas Pelita Harapan",
    website: "http://www.uph.edu/",
  },
  {
    value: "3028",
    label: 'Universitas Pembangunan Nasional "Veteran" East Java',
    website: "http://www.upnjatim.ac.id/",
  },
  {
    value: "3029",
    label: "Universitas Pembangunan Panca Budi",
    website: "http://www.pancabudi.ac.id/",
  },
  {
    value: "3030",
    label: "Universitas Pendidikan Indonesia",
    website: "http://www.upi.edu/",
  },
  {
    value: "3031",
    label: "Universitas Persada Indonesia Y.A.I",
    website: "http://www.yai.ac.id/",
  },
  {
    value: "3032",
    label: "Universitas Pesantren Darul Ulum Jombang",
    website: "http://www.unipdu.ac.id/",
  },
  {
    value: "3033",
    label: "Universitas Proklamasi 45",
    website: "http://www.up45.ac.id/",
  },
  {
    value: "3034",
    label: "Universitas Putera Batam",
    website: "http://www.puterabatam.ac.id/",
  },
  {
    value: "3035",
    label: "Universitas Riau",
    website: "http://www.unri.ac.id/",
  },
  {
    value: "3036",
    label: "Universitas Sam Ratulangi",
    website: "http://www.unsrat.ac.id/",
  },
  {
    value: "3037",
    label: "Universitas Sanata Dharma",
    website: "http://www.usd.ac.id/",
  },
  {
    value: "3038",
    label: "Universitas Sarjanawiyata Tamansiswa",
    website: "http://www.sarjanawiyata.ac.id/",
  },
  {
    value: "3039",
    label: "Universitas Sebelas Maret",
    website: "http://www.uns.ac.id/",
  },
  {
    value: "3040",
    label: "Universitas Semarang",
    website: "http://www.usm.ac.id/",
  },
  {
    value: "3041",
    label: "Universitas Siliwangi",
    website: "http://www.unsil.ac.id/",
  },
  {
    value: "3042",
    label: "Universitas Simalungun",
    website: "http://www.usi.ac.id/",
  },
  {
    value: "3043",
    label: "Universitas Slamet Riyadi Surakarta",
    website: "http://www.unisri.ac.id/",
  },
  {
    value: "3044",
    label: "Universitas Sriwijaya",
    website: "http://www.unsri.ac.id/",
  },
  {
    value: "3045",
    label: "Universitas Sultan Ageng Tirtayasa",
    website: "http://www.untirta.ac.id/",
  },
  {
    value: "3046",
    label: "Universitas Sumatera Utara",
    website: "http://www.usu.ac.id/",
  },
  {
    value: "3047",
    label: "Universitas Surabaya",
    website: "http://www.ubaya.ac.id/",
  },
  {
    value: "3048",
    label: "Universitas Swadaya Gunung Djati",
    website: "http://www.unswagati-crb.ac.id/",
  },
  {
    value: "3049",
    label: "Universitas Syiah Kuala",
    website: "http://www.unsyiah.ac.id/",
  },
  {
    value: "3050",
    label: "Universitas Tadulako",
    website: "http://www.untad.ac.id/",
  },
  {
    value: "3051",
    label: "Universitas Tanjungpura",
    website: "http://www.untan.ac.id/",
  },
  {
    value: "3052",
    label: "Universitas Tarumanagara",
    website: "http://www.untar.ac.id/",
  },
  {
    value: "3053",
    label: "Universitas Terbuka Indonesia",
    website: "http://www.ut.ac.id/",
  },
  {
    value: "3054",
    label: "Universitas Tidar Magelang",
    website: "http://www.utm.ac.id/",
  },
  {
    value: "3055",
    label: "Universitas Trilogi",
    website: "http://www.universitas-trilogi.ac.id/",
  },
  {
    value: "3056",
    label: "Universitas Trisakti",
    website: "http://www.trisakti.ac.id/",
  },
  {
    value: "3057",
    label: "Universitas Tunas Pembangunan",
    website: "http://www.utp.ac.id/",
  },
  {
    value: "3058",
    label: "Universitas Udayana",
    website: "http://www.unud.ac.id/",
  },
  {
    value: "3059",
    label: "Universitas Widya Gama Malang",
    website: "http://www.widyagama.ac.id/",
  },
  {
    value: "3060",
    label: "Universitas Widyatama",
    website: "http://www.widyatama.ac.id/",
  },
  {
    value: "3061",
    label: "Universitas Wijaya Kusuma Purwokerto",
    website: "http://www.unwiku.ac.id/",
  },
  {
    value: "3062",
    label: "Universitas Wijaya Kusuma Surabaya",
    website: "http://www.wijayakusumasby.ac.id/",
  },
  {
    value: "3063",
    label: "Universitas Wisnuwardhana",
    website: "http://www.wisnuwardhana.ac.id/",
  },
  {
    value: "3064",
    label: "Universitas Yarsi",
    website: "http://www.yarsi.ac.id/",
  },
  {
    value: "3065",
    label: "Athlone Institute of Technology",
    website: "http://www.ait.ie/",
  },
  {
    value: "3066",
    label: "Carlow Institute of Technology",
    website: "http://www.itcarlow.ie/",
  },
  {
    value: "3067",
    label: "Cork Institute of Technology",
    website: "http://www.cit.ie/",
  },
  {
    value: "3068",
    label: "Dublin City University",
    website: "http://www.dcu.ie/",
  },
  {
    value: "3069",
    label: "Dublin Institute for Advanced Studies",
    website: "http://www.dias.ie/",
  },
  {
    value: "3070",
    label: "Dublin Institute of Technology",
    website: "http://www.dit.ie/",
  },
  {
    value: "3071",
    label: "Galway Mayo Institute of Technology",
    website: "http://www.gmit.ie/",
  },
  {
    value: "3072",
    label: "Griffith College",
    website: "http://www.gcd.ie/",
  },
  {
    value: "3073",
    label: "Institue of Technology, Tralee",
    website: "http://www.ittralee.ie/",
  },
  {
    value: "3074",
    label: "Irish International University (Distance Education)",
    website: "http://www.iiuedu.ie/",
  },
  {
    value: "3075",
    label: "LSB College",
    website: "http://www.lsb.ie/",
  },
  {
    value: "3076",
    label: "National College of Ireland",
    website: "http://www.ncirl.ie/",
  },
  {
    value: "3077",
    label: "National University of Ireland",
    website: "http://www.nui.ie/",
  },
  {
    value: "3078",
    label: "National University of Ireland, Galway",
    website: "http://www.ucg.ie/",
  },
  {
    value: "3079",
    label: "National University of Ireland, Maynooth",
    website: "http://www.may.ie/",
  },
  {
    value: "3080",
    label: "Royal College of Physicians of Ireland",
    website: "http://www.rcpi.ie/",
  },
  {
    value: "3081",
    label: "Royal College of Surgeons",
    website: "http://www.rcsi.ie/",
  },
  {
    value: "3082",
    label: "Shannon College of Hotel Management",
    website: "http://www.shannoncollege.com/",
  },
  {
    value: "3083",
    label: "University College Cork",
    website: "http://www.ucc.ie/",
  },
  {
    value: "3084",
    label: "University College Dublin",
    website: "http://www.ucd.ie/",
  },
  {
    value: "3085",
    label: "University of Dublin, Trinity College",
    website: "http://www.tcd.ie/",
  },
  {
    value: "3086",
    label: "University of Limerick",
    website: "http://www.ul.ie/",
  },
  {
    value: "3087",
    label: "Warnborough University",
    website: "http://www.warnborough.edu/",
  },
  {
    value: "3088",
    label: "Waterford Institute Of Technology",
    website: "http://www.wit.ie/",
  },
  {
    value: "3089",
    label: "Acdemic Center for Law and Business",
    website: "http://www.clb.ac.il/",
  },
  {
    value: "3090",
    label: "Afeka Tel Aviv Academic College of Engineering",
    website: "http://www.afeka.ac.il/",
  },
  {
    value: "3091",
    label: "Ariel University Center of Samaria",
    website: "http://www.ariel.ac.il/",
  },
  {
    value: "3092",
    label: "Ashkelon Academic College",
    website: "http://www.ash-college.ac.il/",
  },
  {
    value: "3093",
    label: "Bar-Ilan University",
    website: "http://www.biu.ac.il/",
  },
  {
    value: "3094",
    label: "Ben-Gurion University of the Negev",
    website: "http://www.bgu.ac.il/",
  },
  {
    value: "3095",
    label: "Bezalel Academy of Art and Design",
    website: "http://www.bezalel.ac.il/",
  },
  {
    value: "3096",
    label: "College of Management",
    website: "http://www.colman.ac.il/",
  },
  {
    value: "3097",
    label: "Emeq Yizrael College",
    website: "http://www.yvc.ac.il/",
  },
  {
    value: "3098",
    label: "Galillee College",
    website: "http://www.galilcol.ac.il/",
  },
  {
    value: "3099",
    label: "Hebrew University of Jerusalem",
    website: "http://www.huji.ac.il/",
  },
  {
    value: "3100",
    label: "Jerusalem University College",
    website: "http://www.juc.edu/",
  },
  {
    value: "3101",
    label: "Open University of Israel",
    website: "http://www.openu.ac.il/",
  },
  {
    value: "3102",
    label: "Russell Berrie Nanotechnology Institute",
    website: "http://rbni.technion.ac.il/",
  },
  {
    value: "3103",
    label: "Sami Shamoon College of Engineering",
    website: "http://www.sce.ac.il/",
  },
  {
    value: "3104",
    label: "Shenkar School of Engineering & Design",
    website: "http://www.shenkar.ac.il/",
  },
  {
    value: "3105",
    label: "Technion - Israel Institute of Technology",
    website: "http://www.technion.ac.il/",
  },
  {
    value: "3106",
    label: "Tel Aviv University",
    website: "http://www.tau.ac.il/",
  },
  {
    value: "3107",
    label: "The Interdisciplinary Center Herzliya",
    website: "http://www.idc.ac.il/",
  },
  {
    value: "3108",
    label: "University of Haifa",
    website: "http://www.haifa.ac.il/",
  },
  {
    value: "3109",
    label: "Weizmann Institute of Science",
    website: "http://www.weizmann.ac.il/",
  },
  {
    value: "3110",
    label: "Western Galilee College",
    website: "http://www.wgalil.ac.il/",
  },
  {
    value: "3111",
    label: "Acharya Nagarjuna University",
    website: "http://www.nagarjunauniversity.ac.in/",
  },
  {
    value: "3112",
    label: "Acharya Ranga Agricultural University",
    website: "http://www.angrau.net/",
  },
  {
    value: "3113",
    label: "Ahmedabad University",
    website: "http://www.ahduni.edu.in/",
  },
  {
    value: "3114",
    label: "AISECT University",
    website: "http://www.aisectuniversity.ac.in/",
  },
  {
    value: "3115",
    label: "Alagappa University",
    website: "http://www.alagappauniversity.ac.in/",
  },
  {
    value: "3116",
    label: "Aligarh Muslim University",
    website: "http://www.amu.ac.in/",
  },
  {
    value: "3117",
    label: "Allahabad Agricultural Institute, Deemed University",
    website: "http://www.aaidu.org/",
  },
  {
    value: "3118",
    label: "Allahabad University",
    website: "http://www.alldunivpio.org/",
  },
  {
    value: "3119",
    label: "All India Institute of Medical Sciences",
    website: "http://www.aiims.ac.in/",
  },
  {
    value: "3120",
    label: "Amity University",
    website: "http://www.amity.edu/",
  },
  {
    value: "3121",
    label: "Amravati University",
    website: "http://www.amtuni.com/",
  },
  {
    value: "3122",
    label: "Amrita Vishwa Vidyapeetham (Deemed University)",
    website: "http://www.amrita.edu/",
  },
  {
    value: "3123",
    label: "Andhra University",
    website: "http://www.andhrauniversity.info/",
  },
  {
    value: "3124",
    label: "Annamalai University",
    website: "http://www.annamalaiuniversity.ac.in/",
  },
  {
    value: "3125",
    label: "Anna University",
    website: "http://www.annauniv.edu/",
  },
  {
    value: "3126",
    label: "Anna University of Technology, Tirunelveli",
    website: "http://www.annauniv.edu.in/",
  },
  {
    value: "3127",
    label: "ARYA Institute of Engineering & Technology",
    website: "http://www.aryainstitutejpr.com/",
  },
  {
    value: "3128",
    label: "Asian Business School",
    website: "http://www.abs.edu.in/",
  },
  {
    value: "3129",
    label: "Assam Agricultural University",
    website: "http://www.aau.ac.in/",
  },
  {
    value: "3130",
    label: "Assam University",
    website: "http://www.assamuniversity.nic.in/",
  },
  {
    value: "3131",
    label:
      "Avinashilingam Institute for Home Science and Higher Education for Women",
    website: "http://www.tnuniv.ac.in/adu/",
  },
  {
    value: "3132",
    label: "Awadhesh Pratap Singh University",
    website: "http://www.apsurewa.nic.in/",
  },
  {
    value: "3133",
    label: "Babasaheb Bhimrao Ambedkar University",
    website: "http://www.bbauindia.org/",
  },
  {
    value: "3134",
    label: "Banaras Hindu University",
    website: "http://www.bhu.ac.in/",
  },
  {
    value: "3135",
    label: "Banasthali University",
    website: "http://www.banasthali.org/",
  },
  {
    value: "3136",
    label: "Bangalore University",
    website: "http://bengaluruuniversity.com/",
  },
  {
    value: "3137",
    label: "Bankura University",
    website: "http://www.bankurauniv.com/",
  },
  {
    value: "3138",
    label: "Barkatullah University",
    website: "http://www.bubhopal.nic.in/",
  },
  {
    value: "3139",
    label: "Bengal Engineering College",
    website: "http://www.becs.ac.in/",
  },
  {
    value: "3140",
    label: "Berhampur University",
    website: "http://www.bamu.nic.in/",
  },
  {
    value: "3141",
    label: "Bhagwant University",
    website: "http://www.bhagwantuniversity.com/",
  },
  {
    value: "3142",
    label: "Bharathiar University",
    website: "http://www.bharathi.ernet.in/",
  },
  {
    value: "3143",
    label: "Bharathidasan University",
    website: "http://www.bdu.ernet.in/",
  },
  {
    value: "3144",
    label: "Bharath Institue Of Higher Education & Research",
    website: "http://www.bharathuniv.com/",
  },
  {
    value: "3145",
    label: "Bharati Vidyapeeth University",
    website: "http://www.bharatividyapeeth.edu/",
  },
  {
    value: "3146",
    label: "Bhavnagar University",
    website: "http://www.bhavuni.edu/",
  },
  {
    value: "3147",
    label: "Bhupendra Narayan Mandal University",
    website: "http://www.bnmu.in/",
  },
  {
    value: "3148",
    label: "Bidhan Chandra Agricultural University",
    website: "http://www.bckv.edu.in/",
  },
  {
    value: "3149",
    label: "Biju Pattnaik University of Technology",
    website: "http://www.bput.org/",
  },
  {
    value: "3150",
    label: "Birla Institute of Technology and Science",
    website: "http://www.bits-pilani.ac.in/",
  },
  {
    value: "3151",
    label: "Birla Institute of Technology, Ranchi",
    website: "http://bitmesraranchi.org/",
  },
  {
    value: "3152",
    label: "Birsa Agricultural University",
    website: "http://www.bau.nic.in/",
  },
  {
    value: "3153",
    label: "B. R. Ambedkar Bihar University",
    website: "http://www.brabu.net/",
  },
  {
    value: "3154",
    label: "B. S. Abdur Rahman University",
    website: "http://www.bsauniv.ac.in/",
  },
  {
    value: "3155",
    label: "Bundelkhand University",
    website: "http://www.bundelkhanduniversity.org/",
  },
  {
    value: "3156",
    label: "Burdwan University",
    website: "http://www.buruniv.ac.in/",
  },
  {
    value: "3157",
    label: "CCS Haryana Agricultural University",
    website: "http://hau.ernet.in/",
  },
  {
    value: "3158",
    label: "Center for Entrepreneurship and Small Business Management",
    website: "http://www.cesbm.ac.in/",
  },
  {
    value: "3159",
    label: "Central Agricultural University",
    website: "http://www.cau.org.in/",
  },
  {
    value: "3160",
    label: "Central Institute of English and Foreign Languages",
    website: "http://www.ciefl.org/",
  },
  {
    value: "3161",
    label: "Central Institute of Fisheries Education",
    website: "http://www.cife.edu.in/",
  },
  {
    value: "3162",
    label: "Central Institute of Higher Tibetan Studies",
    website: "http://www.cihts.ac.in/",
  },
  {
    value: "3163",
    label: "Chandra Shekhar Azad University of Agriculture and Technology",
    website: "http://www.csauk.ac.in/",
  },
  {
    value: "3164",
    label: "Chaudhary Charan Singh Haryana Agricultural University",
    website: "http://www.hau.ernet.in/",
  },
  {
    value: "3165",
    label: "Chaudhary Charan Singh University",
    website: "http://www.ccsuniversity.ac.in/",
  },
  {
    value: "3166",
    label: "Ch. Charan Singh University",
    website: "http://www.ccsuniversity.org/",
  },
  {
    value: "3167",
    label: "Chhatrapati Shahu Ji Maharaj University",
    website: "http://www.kanpuruniversity.org/",
  },
  {
    value: "3168",
    label: "Christ University",
    website: "http://www.christuniversity.in/",
  },
  {
    value: "3169",
    label: "CMJ University",
    website: "http://www.cmjuniversity.edu.in/",
  },
  {
    value: "3170",
    label: "Cochin University of Science and Technology",
    website: "http://www.cusat.ac.in/",
  },
  {
    value: "3171",
    label: "Darul Huda Islamic University",
    website: "http://www.darulhuda.com/",
  },
  {
    value: "3172",
    label: "Dav University",
    website: "http://www.davuniversity.org/",
  },
  {
    value: "3173",
    label: "Dayalbagh Educational Institute",
    website: "http://www.dei.ac.in/",
  },
  {
    value: "3174",
    label: "Deccan College Postgraduate and Research Institute",
    website: "http://www.deccancollegepune.ac.in/",
  },
  {
    value: "3175",
    label: "Deen Dayal Upadhyay Gorakhpur University",
    website: "http://www.ddugu.edu.in/",
  },
  {
    value: "3176",
    label: "Delhi College of Engineering (DCE)",
    website: "http://www.dceonline.net/",
  },
  {
    value: "3177",
    label: "Devi Ahilya University of Indore",
    website: "http://www.davv.ac.in/",
  },
  {
    value: "3178",
    label:
      "Dhirubhai Ambani Institute of Information and Communication Technology",
    website: "http://www.da-iict.org/",
  },
  {
    value: "3179",
    label: "Dibrugarh University",
    website: "http://www.dibru.ernet.in/",
  },
  {
    value: "3180",
    label: "Dravidian University",
    website: "http://www.dravidianuniversity.ac.in/",
  },
  {
    value: "3181",
    label: "Dr. Babasaheb Ambedkar Marathwada Universtiy",
    website: "http://www.bamu.net/",
  },
  {
    value: "3182",
    label: "Dr. Babasaheb Ambedkar Technological University",
    website: "http://www.dbatechuni.org/",
  },
  {
    value: "3183",
    label: "Dr. Bhim Rao Abdekar University",
    website: "http://www.brauagra.com/",
  },
  {
    value: "3184",
    label: "Dr. B.R. Ambedkar Open University",
    website: "http://www.braou.ac.in/",
  },
  {
    value: "3185",
    label: "Dr. C.V. Raman University",
    website: "http://www.cvru.ac.in/",
  },
  {
    value: "3186",
    label: "Dr. D.Y. Patil University",
    website: "http://www.dypatiluniversity.org/",
  },
  {
    value: "3187",
    label: "Dr. Hari Singh Gour University, formerly University of Sagar",
    website: "http://www.sagaruniversity.nic.in/",
  },
  {
    value: "3188",
    label: "Dr. Panjabrao Deshmukh Krishi Vidyapeeth",
    website: "http://www.pdkv.ac.in/",
  },
  {
    value: "3189",
    label: "Dr. Ram Manohar Lohia Avadh University",
    website: "http://www.rmlau.ac.in/",
  },
  {
    value: "3190",
    label: "Dr. YS Parmar University of Horticulture and Forestry",
    website: "http://www.ysparmaruniversity.org/",
  },
  {
    value: "3191",
    label: "Eiilm University",
    website: "http://www.eiilmuniversity.ac.in/",
  },
  {
    value: "3192",
    label: "Engineering Colleges in Tamil Nadu",
    website: "http://www.sonatech.ac.in/",
  },
  {
    value: "3193",
    label: "Fakir Mohan University",
    website: "http://www.fmuniversity.nic.in/",
  },
  {
    value: "3194",
    label: "Fatima mata national college kollam kerala",
    website: "http://www.fatimacollege.net/",
  },
  {
    value: "3195",
    label: "First Global University to teaching Jainism",
    website: "http://www.jainuniversity.org/",
  },
  {
    value: "3196",
    label: "Forest Research Institute Dehradun",
    website: "http://fri.icfre.gov.in/",
  },
  {
    value: "3197",
    label: "Gandhigram Rural Institute",
    website: "http://www.tnuniv.ac.in/gri/",
  },
  {
    value: "3198",
    label: "Gandhi Institute of Technology and Managment",
    website: "http://www.gitam.edu/",
  },
  {
    value: "3199",
    label: "Gauhati University",
    website: "http://www.gu.nic.in/",
  },
  {
    value: "3200",
    label: "Global Open University , Nagaland",
    website: "http://www.government.ind.in/",
  },
  {
    value: "3201",
    label: "Goa University",
    website: "http://www.unigoa.ac.in/",
  },
  {
    value: "3202",
    label: "Gokhale Institute of Politics and Economics",
    website: "http://www.gipe.ernet.in/",
  },
  {
    value: "3203",
    label: "Govind Ballabh Pant University of Agriculture and Technology",
    website: "http://www.gbpuat.ac.in/",
  },
  {
    value: "3204",
    label: "Gujarat Ayurved University",
    website: "http://www.ayurveduniversity.com/",
  },
  {
    value: "3205",
    label: "Gujarat Technological University Ahmedabad",
    website: "http://www.gtu.ac.in/",
  },
  {
    value: "3206",
    label: "Gujarat University Ahmedabad",
    website: "http://www.gujaratuniversity.org.in/",
  },
  {
    value: "3207",
    label: "Gulbarga University",
    website: "http://www.gulbargauniversity.kar.nic.in/",
  },
  {
    value: "3208",
    label: "Guru Ghasidas University",
    website: "http://www.ggu.ac.in/",
  },
  {
    value: "3209",
    label: "Guru Gobind Singh Indraprastha University",
    website: "http://www.ipu.ac.in/",
  },
  {
    value: "3210",
    label: "Guru Jambeshwar University",
    website: "http://www.gju.ernet.in/",
  },
  {
    value: "3211",
    label: "Gurukula Kangri University",
    website: "http://www.gkvharidwar.org/",
  },
  {
    value: "3212",
    label: "Gurukul University",
    website: "http://www.gurukuluniversity.org/",
  },
  {
    value: "3213",
    label: "Guru Nanak Dev University",
    website: "http://www.gnduonline.org/",
  },
  {
    value: "3214",
    label: "Hemchandracharay North Gujarat University",
    website: "http://www.ngu.ac.in/",
  },
  {
    value: "3215",
    label: "Hemwati Nandan Bahuguna Garhwal University",
    website: "http://www.hnbgu.ac.in/",
  },
  {
    value: "3216",
    label: "Hidayatullah National Law University, Raipur",
    website: "http://www.hnluraipur.com/",
  },
  {
    value: "3217",
    label: "Himachal Pradesh Agricultural University",
    website: "http://hillagric.ernet.in/",
  },
  {
    value: "3218",
    label: "Himachal Pradesh University",
    website: "http://hpuniv.nic.in/",
  },
  {
    value: "3219",
    label: "IASE (Institute of Advanced Studies) Deemed University",
    website: "http://www.iaseduniv.org/",
  },
  {
    value: "3220",
    label: "ICFAI University",
    website: "http://www.icfai.org/",
  },
  {
    value: "3221",
    label: "Indian Agricultural Research Institute",
    website: "http://www.iari.ernet.in/",
  },
  {
    value: "3222",
    label: "Indian Board of Alternative Medicine",
    website: "http://www.altmedworld.net/",
  },
  {
    value: "3223",
    label: "Indian Institute of Information Technology",
    website: "http://www.iiita.com/",
  },
  {
    value: "3224",
    label: "Indian Institute of Information Technology and Management - Kerala",
    website: "http://www.iiitmk.ac.in/",
  },
  {
    value: "3225",
    label: "Indian Institute of Management, Tiruchirappalli",
    website: "http://www.iimtrichy.ac.in/",
  },
  {
    value: "3226",
    label: "Indian Institute of Science",
    website: "http://www.iisc.ernet.in/",
  },
  {
    value: "3227",
    label: "Indian Institute of Technology, Bombay",
    website: "http://www.iitb.ac.in/",
  },
  {
    value: "3228",
    label: "Indian Institute of Technology, Delhi",
    website: "http://www.iitd.ernet.in/",
  },
  {
    value: "3229",
    label: "Indian Institute of Technology, Guwahati",
    website: "http://www.iitg.ernet.in/",
  },
  {
    value: "3230",
    label: "Indian Institute of Technology, Hyderabad",
    website: "http://www.iith.ac.in/",
  },
  {
    value: "3231",
    label: "Indian Institute of Technology, Kanpur",
    website: "http://www.iitk.ac.in/",
  },
  {
    value: "3232",
    label: "Indian Institute of Technology, Kharagpur",
    website: "http://www.iitkgp.ernet.in/",
  },
  {
    value: "3233",
    label: "Indian Institute of Technology, Madras",
    website: "http://www.iitm.ac.in/",
  },
  {
    value: "3234",
    label: "Indian Institute of Technology, Roorkee",
    website: "http://www.iitr.ernet.in/",
  },
  {
    value: "3235",
    label: "Indian Institute of Technology, Roorkee",
    website: "http://www.iitr.ac.in/",
  },
  {
    value: "3236",
    label: "Indian Law Institue",
    website: "http://www.ilidelhi.org/",
  },
  {
    value: "3237",
    label: "Indian School of Business Management and Administration",
    website: "http://isbm.org.in/",
  },
  {
    value: "3238",
    label: "Indian School of Mines (ISM)",
    website: "http://www.ismdhanbad.ac.in/",
  },
  {
    value: "3239",
    label: "Indian Statistical Institute",
    website: "http://www.isical.ac.in/",
  },
  {
    value: "3240",
    label: "Indian University",
    website: "http://www.iur.ac.in/",
  },
  {
    value: "3241",
    label: "Indian Veterinary Research Institute",
    website: "http://www.ivri.nic.in/",
  },
  {
    value: "3242",
    label: "Indira Gandhi Agricultural University",
    website: "http://www.igau.edu.in/",
  },
  {
    value: "3243",
    label: "Indira Gandhi Institute of Development and Research",
    website: "http://www.igidr.ac.in/",
  },
  {
    value: "3244",
    label: "Indira Gandhi Institute of Medical Sciences",
    website: "http://www.igims.org/",
  },
  {
    value: "3245",
    label: "Indira Gandhi National Open University",
    website: "http://www.ignou.ac.in/",
  },
  {
    value: "3246",
    label: "Institute of Management and Technical Studies ",
    website: "http://www.imtsinstitute.com/",
  },
  {
    value: "3247",
    label: "Institute of Technology and Management",
    website: "http://www.itmindia.edu/",
  },
  {
    value: "3248",
    label: "Integral University",
    website: "http://www.integraluniversity.ac.in/",
  },
  {
    value: "3249",
    label: "International Institute for Population Sciences",
    website: "http://www.iipsindia.org/",
  },
  {
    value: "3250",
    label: "Jadavpur University",
    website: "http://www.jadavpur.edu/",
  },
  {
    value: "3251",
    label: "Jagran Lakecity University",
    website: "http://www.jlu.edu.in/",
  },
  {
    value: "3252",
    label: "Jai Narayan Vyas University",
    website: "http://www.jnvu.edu.in/",
  },
  {
    value: "3253",
    label: "Jain University, Centre for Management Studies",
    website: "http://www.cms.ac.in/",
  },
  {
    value: "3254",
    label: "Jain Vishva Bharati Institute",
    website: "http://www.jvbi.ac.in/",
  },
  {
    value: "3255",
    label: "Jai Prakash University",
    website: "http://jpv.bih.nic.in/",
  },
  {
    value: "3256",
    label: "Jaipur National University",
    website: "http://www.jnujaipur.ac.in/",
  },
  {
    value: "3257",
    label: "Jamia Hamdard University",
    website: "http://www.jamiahamdard.edu/",
  },
  {
    value: "3258",
    label: "Jamia Millia Islamia University",
    website: "http://jmi.nic.in/",
  },
  {
    value: "3259",
    label: "Jawaharlal Nehru Agricultural University",
    website: "http://www.jnkvv.nic.in/",
  },
  {
    value: "3260",
    label: "Jawaharlal Nehru Centre for Advanced  Scientific Research",
    website: "http://www.jncasr.ac.in/",
  },
  {
    value: "3261",
    label: "Jawaharlal Nehru Technological University",
    website: "http://www.jntu.ac.in/",
  },
  {
    value: "3262",
    label: "Jawaharlal Nehru University",
    website: "http://www.jnu.ac.in/",
  },
  {
    value: "3263",
    label: "Jaypee Institute of Information Technology",
    website: "http://www.jiit.ac.in/",
  },
  {
    value: "3264",
    label: "Jiwaji University",
    website: "http://www.jiwaji.edu/",
  },
  {
    value: "3265",
    label: "Jodhpur National University",
    website: "http://www.jodhpurnationaluniversity.com/",
  },
  {
    value: "3266",
    label: "Kakatiya University",
    website: "http://www.kuwarangal.com/",
  },
  {
    value: "3267",
    label: "Kalinga University",
    website: "http://www.kalingauniversity.com/",
  },
  {
    value: "3268",
    label: "Kameshwar Singh Darbhanga Sanskrit University",
    website: "http://www.ksdsu.edu.in/",
  },
  {
    value: "3269",
    label: "Kamrah International Institute of Technology (KIIT)",
    website: "http://www.kiit.in/",
  },
  {
    value: "3270",
    label: "Kannada University",
    website: "http://www.kannadauniversity.org/",
  },
  {
    value: "3271",
    label: "Kannur University",
    website: "http://www.kannuruniversity.ac.in/",
  },
  {
    value: "3272",
    label: "Kanpur University",
    website: "http://www.kanpuruniversity.org/",
  },
  {
    value: "3273",
    label: "Karnataka State Law University",
    website: "http://www.kslu.ac.in/",
  },
  {
    value: "3274",
    label: "Karnataka State Open University",
    website: "http://www.karnatakastateopenuniversity.in/",
  },
  {
    value: "3275",
    label: "Karnatak University",
    website: "http://www.kud.ac.in/",
  },
  {
    value: "3276",
    label: "Karpagam University",
    website: "http://www.karpagamuniv.com/",
  },
  {
    value: "3277",
    label: "Karunya Institute Of Technology ( Deemed University)",
    website: "http://www.karunya.edu/",
  },
  {
    value: "3278",
    label: "Kerala Agricultural University",
    website: "http://www.kau.edu/",
  },
  {
    value: "3279",
    label: "Kerala University of Fisheries and Ocean Sciences",
    website: "http://www.kufos.ac.in/",
  },
  {
    value: "3280",
    label: "Kohinoor School of Hospitality Management",
    website: "http://kimi.kohinoor.ac.in/",
  },
  {
    value: "3281",
    label: "Konkan Agricultural University",
    website: "http://www.dbskkv.org/",
  },
  {
    value: "3282",
    label: "Krishna University",
    website: "http://www.krishnauniversity.ac.in/",
  },
  {
    value: "3283",
    label: "Kumaun University",
    website: "http://www.kumaununiversity.org/",
  },
  {
    value: "3284",
    label: "Kurukshetra University",
    website: "http://www.kukinfo.com/",
  },
  {
    value: "3285",
    label: "Kuvempu University",
    website: "http://www.kuvempu.ac.in/",
  },
  {
    value: "3286",
    label: "Lakshmibai National Institute of Physical Education",
    website: "http://www.lnipe.edu/",
  },
  {
    value: "3287",
    label: "Lalit Narayan Mithila University",
    website: "http://lnmu.bih.nic.in/",
  },
  {
    value: "3288",
    label: " L.D.College of Engineering",
    website: "http://www.ldceahd.org/",
  },
  {
    value: "3289",
    label: "Madhya Pradesh Bhoj (Open) University",
    website: "http://www.mpbou.org/",
  },
  {
    value: "3290",
    label: "Madurai Kamaraj University",
    website: "http://www.mkuhyd.com/",
  },
  {
    value: "3291",
    label: "Magadh University",
    website: "http://www.magadhuniversity.org/",
  },
  {
    value: "3292",
    label: "Maharaja Ganga Singh University, Bikaner",
    website: "http://www.mgsubikaner.ac.in/",
  },
  {
    value: "3293",
    label: "Maharaja Sayajirao University of Baroda",
    website: "http://www.msubaroda.ac.in/",
  },
  {
    value: "3294",
    label: "Maharishi Dayanand University, Rohtak (Haryana )",
    website: "http://www.mdurohtak.com/",
  },
  {
    value: "3295",
    label: "Maharshi Dayanand Sarswati University Ajmer",
    website: "http://www.mdsuajmer.ac.in/",
  },
  {
    value: "3296",
    label: "Mahatma Gandhi Chitrakut Gramoday University",
    website: "http://www.ruraluniversity-chitrakoot.org/",
  },
  {
    value: "3297",
    label: "Mahatma Gandhi Kashi Vidyapith ",
    website: "http://www.mgkvp.ac.in/",
  },
  {
    value: "3298",
    label: "Mahatma Gandhi University, Kerala",
    website: "http://www.mgu.ac.in/",
  },
  {
    value: "3299",
    label: "Mahatma Gandhi University Meghalaya ",
    website: "http://www.mgu.edu.in/",
  },
  {
    value: "3300",
    label: "Mahatma Jyotiba Phule Rohilkhand University Bareilly ",
    website: "http://www.mjpru.ac.in/",
  },
  {
    value: "3301",
    label: "Mahatma Phule Agricultural University",
    website: "http://mpkv.mah.nic.in/",
  },
  {
    value: "3302",
    label: "Makanlal Chutur Vedi University",
    website: "http://www.mcu.ac.in/",
  },
  {
    value: "3303",
    label:
      "Makhanlal Chaturvedi National University of Journalism and Communication",
    website: "http://www.mcu.ac.in/",
  },
  {
    value: "3304",
    label: "Mangalore University",
    website: "http://www.mangaloreuniversity.ac.in/",
  },
  {
    value: "3305",
    label: "Manipal University",
    website: "http://www.manipal.edu/",
  },
  {
    value: "3306",
    label: "Manipur University",
    website: "http://www.manipur.nic.in/maniuniversity.htm",
  },
  {
    value: "3307",
    label: "Manonmaniam Sundaranar University",
    website: "http://www.tnuniv.ac.in/msu/",
  },
  {
    value: "3308",
    label: "Mar Athanasios College for Advanced Studies",
    website: "http://www.macfast.org/",
  },
  {
    value: "3309",
    label: "Marathwada Agricultural University",
    website: "http://mkv2.mah.nic.in/",
  },
  {
    value: "3310",
    label: "Mohan Lal Sukhadia University",
    website: "http://www.mlsu.ac.in/",
  },
  {
    value: "3311",
    label: "Mother Teresa Women's University",
    website: "http://www.tnuniv.ac.in/mteresa/",
  },
  {
    value: "3312",
    label: "Motilal Nehru National Institute Of Technology",
    website: "http://www.mnnit.ac.in/",
  },
  {
    value: "3313",
    label: "Moulana Azad national Urdu University",
    website: "http://www.manuu.ac.in/",
  },
  {
    value: "3314",
    label: "Nagaland University",
    website: "http://www.nagauniv.org.in/",
  },
  {
    value: "3315",
    label: "Nagpur University",
    website: "http://www.nagpur-university.com/",
  },
  {
    value: "3316",
    label: "Nalanda Open University",
    website: "http://www.nalandaopenuniversity.info/",
  },
  {
    value: "3317",
    label: "Narendra Deva University of Agriculture and Technology",
    website: "http://www.nduat.nic.in/",
  },
  {
    value: "3318",
    label: "Narsee Monjee Institute of Management Studies",
    website: "http://www.nmims.edu/",
  },
  {
    value: "3319",
    label: "National Dairy Research Institute",
    website: "http://ndri.nic.in/",
  },
  {
    value: "3320",
    label: "National Institute of Industrial Engineering",
    website: "http://www.nitie.edu/",
  },
  {
    value: "3321",
    label: "National Institute of Mental Health and Neuro Sciences",
    website: "http://www.nimhans.kar.nic.in/",
  },
  {
    value: "3322",
    label: "National Institute of Technology, Calicut",
    website: "http://www.nitc.ac.in/",
  },
  {
    value: "3323",
    label: "National Institute of Technology, Durgapur",
    website: "http://www.nitdgp.ac.in/",
  },
  {
    value: "3324",
    label: "National Institute of Technology, Jamshedpur",
    website: "http://www.nitjsr.ac.in/",
  },
  {
    value: "3325",
    label: "National Institute of Technology Kurukshetra",
    website: "http://www.nitkkr.net/",
  },
  {
    value: "3326",
    label: "National Institute of Technology, Rourkela",
    website: "http://www.nitrkl.ac.in/",
  },
  {
    value: "3327",
    label: "National Institute of Technology, Trichy",
    website: "http://www.nitt.edu/",
  },
  {
    value: "3328",
    label: "National Institute of Technology, Warangal",
    website: "http://www.nitw.ernet.in/",
  },
  {
    value: "3329",
    label: "National Law School of India University",
    website: "http://www.nls.ac.in/",
  },
  {
    value: "3330",
    label:
      "National Museum Institute of History of Art, Conservation and Museology",
    website: "http://www.nationalmuseumindia.org/nmi/index.htm",
  },
  {
    value: "3331",
    label: "National Sanskrit University",
    website: "http://www.sanskrit.nic.in/",
  },
  {
    value: "3332",
    label: "Netaji Subhas Inst of Technology",
    website: "http://www.nsit.ac.in/",
  },
  {
    value: "3333",
    label: "Nirma University",
    website: "http://www.nirmauni.ac.in/",
  },
  {
    value: "3334",
    label: "Nizam's Institute of Medical Sciences",
    website: "http://nims.ap.nic.in/",
  },
  {
    value: "3335",
    label: "North Bengal University",
    website: "http://www.nbu.ac.in/",
  },
  {
    value: "3336",
    label: "North Eastern Hill University",
    website: "http://www.nehu.ac.in/",
  },
  {
    value: "3337",
    label: "North Maharashtra University",
    website: "http://www.nmu.ac.in/",
  },
  {
    value: "3338",
    label: "Open International University for Alternative Medicines",
    website: "http://www.altmeduniversity.com/",
  },
  {
    value: "3339",
    label: "Orissa University of Agriculture and Technology",
    website: "http://bhub.ori.nic.in/ouat/",
  },
  {
    value: "3340",
    label: "Osmania University",
    website: "http://www.osmania.ac.in/",
  },
  {
    value: "3341",
    label: "Pandit Ravi Shankar Shukla University",
    website: "http://www.rsuniversity.com/",
  },
  {
    value: "3342",
    label: "Panjab University",
    website: "http://www.puchd.ac.in/",
  },
  {
    value: "3343",
    label: "Patna University",
    website: "http://puonline.bih.nic.in/",
  },
  {
    value: "3344",
    label: "Periyar University",
    website: "http://www.spuniv.org/",
  },
  {
    value: "3345",
    label: "Pes University",
    website: "http://www.pes.edu/",
  },
  {
    value: "3346",
    label: "Pondicherry University",
    website: "http://www.pondiuni.org/",
  },
  {
    value: "3347",
    label: "Postgraduate lnstitute of Medical Education and Research",
    website: "http://www.pgimer.nic.in/",
  },
  {
    value: "3348",
    label: "Potti Sreeramulu Telugu University",
    website: "http://www.teluguuniversity.ac.in/",
  },
  {
    value: "3349",
    label: "PSG College of Technology",
    website: "http://www.psgtech.edu/",
  },
  {
    value: "3350",
    label: "Punjab Agricultural University",
    website: "http://www.pau.edu/",
  },
  {
    value: "3351",
    label: "Punjab Engineering College Deemed University",
    website: "http://www.pec.ac.in/",
  },
  {
    value: "3352",
    label: "Punjabi University Patiala",
    website: "http://www.punjabiuniversity.ac.in/",
  },
  {
    value: "3353",
    label: "Punjab Technical University",
    website: "http://www.ptu.ac.in/",
  },
  {
    value: "3354",
    label: "Rabindra Bharati University",
    website: "http://www.rabindrabharatiuniversity.net/",
  },
  {
    value: "3355",
    label: "Rajasthan Agricultural University, Bikaner",
    website: "http://www.raubikaner.org/",
  },
  {
    value: "3356",
    label: "Rajasthan Technical University",
    website: "http://www.rtu.ac.in/",
  },
  {
    value: "3357",
    label: "Rajasthan Vidyapeeth University",
    website: "http://www.jrnrvu.org/",
  },
  {
    value: "3358",
    label: "Rajendra Agricultural University",
    website: "http://www.pusavarsity.org.in/",
  },
  {
    value: "3359",
    label: "Rajitlal Institute of Technology & Health Sciences (RITHS)",
    website: "http://www.riths.ac.in/",
  },
  {
    value: "3360",
    label:
      "Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)",
    website: "http://www.rgtu.net/",
  },
  {
    value: "3361",
    label: "Rajiv Gandhi University",
    website: "http://www.rgu.ac.in/",
  },
  {
    value: "3362",
    label: "Ranchi University",
    website: "http://www.ranchiuniversity.org.in/",
  },
  {
    value: "3363",
    label: "Rani Durgavati University",
    website: "http://www.rdunijbpin.org/",
  },
  {
    value: "3364",
    label: "RKDF University",
    website: "http://www.rkdf.ac.in/",
  },
  {
    value: "3365",
    label: "Sambalpur University",
    website: "http://www.sambalpuruniversitypgc.in/",
  },
  {
    value: "3366",
    label: "Sampurnanand Sanskrit University",
    website: "http://ssvv.up.nic.in/",
  },
  {
    value: "3367",
    label: "Sanjay Gandhi Postgraduate lnstitute of Medical Sciences",
    website: "http://www.sgpgi.ac.in/",
  },
  {
    value: "3368",
    label: "Sardarkrushinagar Dantiwada Agricultural University",
    website: "http://www.sdau.edu.in/",
  },
  {
    value: "3369",
    label: "Sardar Patel University",
    website: "http://www.spuvvn.edu/",
  },
  {
    value: "3370",
    label: "Sardar Vallabhai Patel University of Agriculture Amd Technology",
    website: "http://www.svbpuctm.org/",
  },
  {
    value: "3371",
    label: "SASTRA Deemed University",
    website: "http://www.sastra.edu/",
  },
  {
    value: "3372",
    label: "Sathyabama University",
    website: "http://www.sathyabamauniv.ac.in/",
  },
  {
    value: "3373",
    label: "Saurashtra University",
    website: "http://www.saurashtrauniversity.edu/",
  },
  {
    value: "3374",
    label: "School of Planning and Architecture",
    website: "http://www.spa.ac.in/",
  },
  {
    value: "3375",
    label: "Shanmugha Arts Science Technology and Research Academy",
    website: "http://www.sce.ac.in/",
  },
  {
    value: "3376",
    label: "Sher-E-Kashmir University of Agricultural Sciences and Technology",
    website: "http://www.skuastkashmir.ac.in/",
  },
  {
    value: "3377",
    label: "Shivaji University",
    website: "http://www.unishivaji.ac.in/",
  },
  {
    value: "3378",
    label: "Shobhit University, Meerut",
    website: "http://www.shobhituniversity.ac.in/",
  },
  {
    value: "3379",
    label: "Shreemati Nathibai Damodar Thackersey Women's University",
    website: "http://www.sndt.edu/",
  },
  {
    value: "3380",
    label: "Shridhar University",
    website: "http://www.shridharuniversity.ac.in/",
  },
  {
    value: "3381",
    label: "Shri Jagannath Sanskrit University",
    website: "http://www.sjsv.nic.in/",
  },
  {
    value: "3382",
    label: "Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha",
    website: "http://www.slbsrsv.ac.in/",
  },
  {
    value: "3383",
    label: "Shri Mata Vaishno Devi University",
    website: "http://www.smvdu.net.in/",
  },
  {
    value: "3384",
    label: "Sido Kanhu Murmu University",
    website: "http://www.skmu.edu.in/",
  },
  {
    value: "3385",
    label: "Sikha 'O' Anushandhan University",
    website: "http://www.soauniversity.ac.in/",
  },
  {
    value: "3386",
    label:
      "Sikkim Manipal University of Health, Medical and Technological Sciences",
    website: "http://www.smu.edu.in/",
  },
  {
    value: "3387",
    label: "Singhania University Rajasthan",
    website: "http://www.singhaniauniversity.co.in/",
  },
  {
    value: "3388",
    label: "SMK Fomra Institute of Technology",
    website: "http://www.smkfomra.net/",
  },
  {
    value: "3389",
    label: "Solapur University",
    website: "http://su.digitaluniversity.ac/",
  },
  {
    value: "3390",
    label: "South Asian University",
    website: "http://www.southasianuniversity.org/",
  },
  {
    value: "3391",
    label: "South Gujarat University",
    website: "http://www.sgu.ernet.in/",
  },
  {
    value: "3392",
    label: "Sree Chitra Tirunal Institute for Medical Sciences and Technology",
    website: "http://sctimst.ker.nic.in/",
  },
  {
    value: "3393",
    label: "Sree Rama Krishna Degree College",
    website: "http://www.srkdc.org/",
  },
  {
    value: "3394",
    label: "Sree Sankaracharya University of Sanskrit",
    website: "http://www.ssus.ac.in/",
  },
  {
    value: "3395",
    label: "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya",
    website: "http://www.kanchiuniv.ac.in/",
  },
  {
    value: "3396",
    label: "Sri Krishnadevaraya University",
    website: "http://skuniv.ap.nic.in/",
  },
  {
    value: "3397",
    label: "Sri Padmavati Women's University",
    website: "http://www.padmavatiwomen-univ.org/",
  },
  {
    value: "3398",
    label: "Sri Ramachardra Medical College and Research Institute",
    website: "http://www.hostindia.com/srmc/",
  },
  {
    value: "3399",
    label: "Sri Sant Gajanan Maharaj College of Engineering",
    website: "http://www.ssgmce.org/",
  },
  {
    value: "3400",
    label: "Sri Sathya Sai Institute of Higher Learning",
    website: "http://www.srisathyasai.org.in/pages/instts/HighLearn.htm",
  },
  {
    value: "3401",
    label: "Sri Venkateswara Institute of Medical Sciences",
    website: "http://svimstpt.ap.nic.in/",
  },
  {
    value: "3402",
    label: "Sri Venkateswara University",
    website: "http://svuni.ap.nic.in/",
  },
  {
    value: "3403",
    label: "SRM Institute Of Science & Technology ( Deemed University)",
    website: "http://www.srmuniv.ac.in/",
  },
  {
    value: "3404",
    label: "Sunrise University Alwar",
    website: "http://www.sunriseuniversity.in/",
  },
  {
    value: "3405",
    label: "Swami Ramanand Teerth Marathwada University",
    website: "http://www.srtmun.org/",
  },
  {
    value: "3406",
    label: "Symbiosis International University",
    website: "http://www.siu.edu.in/",
  },
  {
    value: "3407",
    label: "Takhatmal Shrivallabh Homoeopathic Medical College",
    website: "http://www.tshmc.org/",
  },
  {
    value: "3408",
    label: "Tamil Nadu Agricultural University",
    website: "http://dbs.tn.nic.in/tnau/",
  },
  {
    value: "3409",
    label: "Tamil Nadu Dr. Ambedkar Law University",
    website: "http://www.tndalu.org/",
  },
  {
    value: "3410",
    label: "Tamil Nadu Dr. M.G.R. Medical University",
    website: "http://www.tnmmu.ac.in/",
  },
  {
    value: "3411",
    label: "Tamil Nadu Veterinary and Animal Sciences University",
    website: "http://www.tanuvas.com/",
  },
  {
    value: "3412",
    label: "Tamil University",
    website: "http://www.tamiluniversity.ac.in/",
  },
  {
    value: "3413",
    label: "Tata Institute of Social Sciences",
    website: "http://www.tiss.edu/",
  },
  {
    value: "3414",
    label: "Telugu University",
    website: "http://www.teluguuniversity.ac.in/",
  },
  {
    value: "3415",
    label: "Tezpur University",
    website: "http://www.tezu.ernet.in/",
  },
  {
    value: "3416",
    label: "Thapar Institute of Engineering and Technology",
    website: "http://www.tiet.ac.in/",
  },
  {
    value: "3417",
    label: "The New College",
    website: "http://www.thenewcollege.org/",
  },
  {
    value: "3418",
    label: "Thiagarajar College of Engineering",
    website: "http://www.tce.edu/",
  },
  {
    value: "3419",
    label: "Thiruvalluvar University",
    website: "http://www.tvuni.in/",
  },
  {
    value: "3420",
    label: "Tilak Maharashtra Vidyapeeth",
    website: "http://www.tmv.edu.in/",
  },
  {
    value: "3421",
    label: "Tilka Manjhi Bhagalpur University",
    website: "http://www.tmbu.org/",
  },
  {
    value: "3422",
    label: "Tripura University",
    website: "http://www.tripurauniv.in/",
  },
  {
    value: "3423",
    label: "Tumkur University",
    website: "http://www.tumkuruniversity.ac.in/",
  },
  {
    value: "3424",
    label: "University of Agricultural Sciences, Bangalore",
    website: "http://uasbng.kar.nic.in/",
  },
  {
    value: "3425",
    label: "University of Agricultural Sciences, Dharwad",
    website: "http://www.uasd.net/",
  },
  {
    value: "3426",
    label: "University of Burdwan",
    website: "http://www.burdwanuniversity.ac.in/",
  },
  {
    value: "3427",
    label: "University of Calcutta",
    website: "http://www.caluniv.ac.in/",
  },
  {
    value: "3428",
    label: "University of Calicut",
    website: "http://unical.ac.in/",
  },
  {
    value: "3429",
    label: "University of Delhi",
    website: "http://www.du.ac.in/",
  },
  {
    value: "3430",
    label: "University of Health Sciences Andhra Pradesh",
    website: "http://59.163.116.210/",
  },
  {
    value: "3431",
    label: "University of Hyderabad",
    website: "http://www.uohyd.ernet.in/",
  },
  {
    value: "3432",
    label: "University of Jammu",
    website: "http://www.jammuvarsity.org/",
  },
  {
    value: "3433",
    label: "University of Kalyani",
    website: "http://www.klyuniv.ac.in/",
  },
  {
    value: "3434",
    label: "University of Kashmir",
    website: "http://kashmiruniversity.net/",
  },
  {
    value: "3435",
    label: "University of Kerala",
    website: "http://www.keralauniversity.ac.in/",
  },
  {
    value: "3436",
    label: "University of Kota",
    website: "http://www.uok.ac.in/",
  },
  {
    value: "3437",
    label: "University of Lucknow",
    website: "http://www.lucknowuniv.org/",
  },
  {
    value: "3438",
    label: "University of Madras",
    website: "http://www.unom.ac.in/",
  },
  {
    value: "3439",
    label: "University of Media Arts",
    website: "http://www.uma.ac.in/",
  },
  {
    value: "3440",
    label: "University of Mumbai",
    website: "http://www.mu.ac.in/",
  },
  {
    value: "3441",
    label: "University of Music and Fine Arts",
    website: "http://www.iksvv.com/",
  },
  {
    value: "3442",
    label: "University of Mysore",
    website: "http://www.uni-mysore.ac.in/",
  },
  {
    value: "3443",
    label: "University of North Bengal",
    website: "http://www.nbu.ac.in/",
  },
  {
    value: "3444",
    label: "University of Pune",
    website: "http://www.unipune.ernet.in/",
  },
  {
    value: "3445",
    label: "University of Rajasthan",
    website: "http://www.uniraj.org/",
  },
  {
    value: "3446",
    label: "University of Roorkee",
    website: "http://www.uor.org/",
  },
  {
    value: "3447",
    label: "Utkal University",
    website: "http://www.utkaluniv.org/",
  },
  {
    value: "3448",
    label: "Uttarakhand Open University ",
    website: "http://www.uou.ac.in/",
  },
  {
    value: "3449",
    label: "Uttarakhand Technical University",
    website: "http://www.uktech.ac.in/",
  },
  {
    value: "3450",
    label: "Uttar Pradesh Technical University",
    website: "http://www.uptu.ac.in/",
  },
  {
    value: "3451",
    label: "Vardhaman Mahaveer Open University",
    website: "http://www.vmou.ac.in/",
  },
  {
    value: "3452",
    label: "VBS Purvanchal University",
    website: "http://vbspu.ac.in/",
  },
  {
    value: "3453",
    label: "Veer Kunwar Singh University",
    website: "http://www.vksu-ara.org/",
  },
  {
    value: "3454",
    label: "Veer Surendra Sai University of Technology",
    website: "http://www.vssut.ac.in/",
  },
  {
    value: "3455",
    label: "Vellore Institute of Technology",
    website: "http://www.vit.ac.in/",
  },
  {
    value: "3456",
    label: "Vidyasagar University",
    website: "http://www.vidyasagar.ac.in/",
  },
  {
    value: "3457",
    label: "Vignan University",
    website: "http://www.vignanuniversity.org/",
  },
  {
    value: "3458",
    label: "Vikrama Simhapuri University",
    website: "http://www.simhapuriuniv.ac.in/",
  },
  {
    value: "3459",
    label: "Vikram University",
    website: "http://www.vikramuniversity.org/",
  },
  {
    value: "3460",
    label: "Vinayaka Mission's Research Foundation-Deemed University",
    website: "http://www.vinayakamissions.com/",
  },
  {
    value: "3461",
    label: "Vinoba Bhave University",
    website: "http://www.vbu.co.in/",
  },
  {
    value: "3462",
    label: "Visva-Bharati University",
    website: "http://www.visva-bharati.ac.in/",
  },
  {
    value: "3463",
    label: "Visvesvaraya National Institute of Technology",
    website: "http://www.vnitnagpur.ac.in/",
  },
  {
    value: "3464",
    label: "Visvesvaraya Technological University",
    website: "http://www.vtu.ac.in/",
  },
  {
    value: "3465",
    label: "West Bengal University of Animal and Fishery Sciences",
    website: "http://www.wbuafs.nic.in/",
  },
  {
    value: "3466",
    label: "West Bengal University of Technology",
    website: "http://www.wbut.net/",
  },
  {
    value: "3467",
    label: "Yashawantrao Chavan Maharashtra Open University",
    website: "http://www.ycmou.com/",
  },
  {
    value: "3468",
    label: "Ahlulbait International University",
    website: "http://www.ahlulbaitonline.com/",
  },
  {
    value: "3469",
    label: "Al Maarif University College",
    website: "http://www.auc-edu.org/",
  },
  {
    value: "3470",
    label: "Al Mamon University College",
    website: "http://www.almamonuc.org/",
  },
  {
    value: "3471",
    label: "Al Mansour University College",
    website: "http://www.muc.edu.iq/",
  },
  {
    value: "3472",
    label: "Al Muthanna University",
    website: "http://www.uoalmuthana.edu.iq/",
  },
  {
    value: "3473",
    label: "Al Nahrain University",
    website: "http://www.nahrainuniv.edu.iq/",
  },
  {
    value: "3474",
    label: "Al Rafidain University College",
    website: "http://www.coalrafidain.edu.iq/",
  },
  {
    value: "3475",
    label: "Al Rasheed University College",
    website: "http://www.alrasheed-uc.com/",
  },
  {
    value: "3476",
    label: "Alsadrain University",
    website: "http://www.alsadrain.org/",
  },
  {
    value: "3477",
    label: "Al Turath University College",
    website: "http://www.turathun.com/",
  },
  {
    value: "3478",
    label: "Al Yarmouk University College",
    website: "http://www.yu.edu.jo/",
  },
  {
    value: "3479",
    label: "American University of Iraq, Sulaimani (Kurdistan Region)",
    website: "http://www.auis.edu.iq/",
  },
  {
    value: "3480",
    label: "Baghdad College of Economic Sciences University",
    website: "http://www.baghdadcollege.edu.iq/",
  },
  {
    value: "3481",
    label: "Baghdad College of Pharmacy",
    website: "http://www.bpc.edu.iq/",
  },
  {
    value: "3482",
    label: "British Royal University",
    website: "http://broyalu.net/",
  },
  {
    value: "3483",
    label: "Cihan University",
    website: "http://www.cihanuniversity.edu.iq/",
  },
  {
    value: "3484",
    label: "College of Science, Baghdad University",
    website: "http://www.scbaghdad.edu.iq/",
  },
  {
    value: "3485",
    label: "Dijla University College",
    website: "http://www.duc.edu.iq/",
  },
  {
    value: "3486",
    label: "Diyala University",
    website: "http://www.uodiyala.edu.iq/",
  },
  {
    value: "3487",
    label: "Hawler Medical University",
    website: "http://www.hawlermu.org/",
  },
  {
    value: "3488",
    label: "Iraq University College",
    website: "http://www.iraquniversity.net/",
  },
  {
    value: "3489",
    label: "Ishik University",
    website: "http://www.iu.edu.iq/",
  },
  {
    value: "3490",
    label: "Kerbala University",
    website: "http://www.uokerbala.edu.iq/",
  },
  {
    value: "3491",
    label: "Komar University of Science and Technology",
    website: "http://www.komar.edu.iq/",
  },
  {
    value: "3492",
    label: "Koya University (Kurdistan Region)",
    website: "http://www.koyauniversity.org/",
  },
  {
    value: "3493",
    label: "Madenat Alelem University College",
    website: "http://www.madenatalelem.com/",
  },
  {
    value: "3494",
    label: "Misan University",
    website: "http://www.uomisan.edu.iq/",
  },
  {
    value: "3495",
    label: "Nawroz University",
    website: "http://www.nawrozuniversity.com/",
  },
  {
    value: "3496",
    label: "Salahddin University (Kurdistan Region)",
    website: "http://www.suh-edu.com/",
  },
  {
    value: "3497",
    label: "Soran University",
    website: "http://www.soran.edu.iq/",
  },
  {
    value: "3498",
    label: "Sulaimani Polytechnic University",
    website: "http://www.spu.edu.iq/",
  },
  {
    value: "3499",
    label: "Thi Qar University",
    website: "http://www.thiqaruni.org/",
  },
  {
    value: "3500",
    label: "Tikrit University",
    website: "http://www.tu.edu.iq/",
  },
  {
    value: "3501",
    label: "Uinversity of Babylon",
    website: "http://www.uobabylon.edu.iq/",
  },
  {
    value: "3502",
    label: "University of Al-Qadisiyah",
    website: "http://www.qu.edu.iq/",
  },
  {
    value: "3503",
    label: "University of Anbar",
    website: "http://www.uoanbar.edu.iq/",
  },
  {
    value: "3504",
    label: "University of Baghdad",
    website: "http://www.uobaghdad.edu.iq/",
  },
  {
    value: "3505",
    label: "University of Basrah",
    website: "http://www.uobasrah.edu.iq/",
  },
  {
    value: "3506",
    label: "University of Dohuk (Kurdistan Region)",
    website: "http://www.uod.ac/",
  },
  {
    value: "3507",
    label: "University of Human Development (Kurdistan Region)",
    website: "http://www.uhd.edu.iq/",
  },
  {
    value: "3508",
    label: "University of Kirkuk",
    website: "http://www.uokirkuk.edu.iq/",
  },
  {
    value: "3509",
    label: "University of Kufa",
    website: "http://www.uokufa.edu.iq/",
  },
  {
    value: "3510",
    label: "University of Kurdistan - Hawler",
    website: "http://www.ukh.ac/",
  },
  {
    value: "3511",
    label: "University of Mosul",
    website: "http://www.uomosul.edu.iq/",
  },
  {
    value: "3512",
    label: "University of Mustansiriyah",
    website: "http://www.uomustansiriyah.edu.iq/",
  },
  {
    value: "3513",
    label: "University of Sulaimania (Kurdistan Region)",
    website: "http://www.univsul.edu.iq/",
  },
  {
    value: "3514",
    label: "University of Technology - Iraq",
    website: "http://www.uotechnology.edu.iq/",
  },
  {
    value: "3515",
    label: "University of Wassit",
    website: "http://www.uowasit.edu.iq/",
  },
  {
    value: "3516",
    label: "Adiban Higher Education Institue",
    website: "http://www.adiban.ac.ir/",
  },
  {
    value: "3517",
    label: "Ahvaz University of Medical Sciences",
    website: "http://www.aums.ac.ir/",
  },
  {
    value: "3518",
    label: "Ahwaz Jondishapour University of Medical Sciences",
    website: "http://www.ajums.ac.ir/",
  },
  {
    value: "3519",
    label: "Allameh Tabatabaie University",
    website: "http://www.atu.ac.ir/",
  },
  {
    value: "3520",
    label: "Almustafa Open University",
    website: "http://www.almustafaou.ir/",
  },
  {
    value: "3521",
    label: "Amirkabir College of Managmant & Technology",
    website: "http://www.acmt.ac.ir/",
  },
  {
    value: "3522",
    label: "Amirkabir University of Technology",
    website: "http://www.aut.ac.ir/",
  },
  {
    value: "3523",
    label: "Arak University",
    website: "http://www.araku.ac.ir/",
  },
  {
    value: "3524",
    label: "Arak University of Medical Sciences",
    website: "http://www.arakmu.ac.ir/",
  },
  {
    value: "3525",
    label: "Arak University of Technology",
    website: "http://www.arakut.ac.ir/",
  },
  {
    value: "3526",
    label: "Ardabil University of Medical Sciences",
    website: "http://www.arums.ac.ir/",
  },
  {
    value: "3527",
    label: "Art University",
    website: "http://www.art.ac.ir/",
  },
  {
    value: "3528",
    label: "Azzahra University",
    website: "http://www.azzahra.ac.ir/",
  },
  {
    value: "3529",
    label: "Babol Noshirvani University of Technology",
    website: "http://www.nit.ac.ir/",
  },
  {
    value: "3530",
    label: "Babol University of Medical Sciences",
    website: "http://www.mubabol.ac.ir/",
  },
  {
    value: "3531",
    label: "Baghyatoolah Medical Sciences University",
    website: "http://www.bmsu.ac.ir/",
  },
  {
    value: "3532",
    label: "Baha'i Institute for Higher Education",
    website: "http://www.bihe.org/",
  },
  {
    value: "3533",
    label: "Birjand University",
    website: "http://www.birjand.ac.ir/",
  },
  {
    value: "3534",
    label: "Birjand University of Medical Sciences",
    website: "http://www.bums.ac.ir/",
  },
  {
    value: "3535",
    label: "Bu Ali Sina University",
    website: "http://www.basu.ac.ir/",
  },
  {
    value: "3536",
    label: "Bushehr University of Medical Sciences",
    website: "http://www.bpums.ac.ir/",
  },
  {
    value: "3537",
    label: "Damghan University of Basic Sciences",
    website: "http://www.dubs.ac.ir/",
  },
  {
    value: "3538",
    label: "Daneshestan Institute of Higher Education",
    website: "http://www.sdi.ac.ir/",
  },
  {
    value: "3539",
    label: "Delijan Payame Noor University",
    website: "http://www.delijanpnu.ac.ir/",
  },
  {
    value: "3540",
    label: "Deylaman Institute of Higher Education",
    website: "http://www.deylaman.ac.ir/",
  },
  {
    value: "3541",
    label: "Fasa Faculty of Medical Sciences",
    website: "http://www.fums.ac.ir/",
  },
  {
    value: "3542",
    label: "Ferdowsi University of Mashhad",
    website: "http://www.um.ac.ir/",
  },
  {
    value: "3543",
    label: "Golestan University of Medical Sciences",
    website: "http://www.goums.ac.ir/",
  },
  {
    value: "3544",
    label: "Gonabad University of Medical Sciences",
    website: "http://www.gmu.ac.ir/",
  },
  {
    value: "3545",
    label: "Gorgan University of Agricultural Sciences and Natural Resources",
    website: "http://www.gau.ac.ir/",
  },
  {
    value: "3546",
    label: "Guilan University",
    website: "http://www.guilan.ac.ir/",
  },
  {
    value: "3547",
    label: "Guilan University of Medical Sciences",
    website: "http://www.gums.ac.ir/",
  },
  {
    value: "3548",
    label: "Hamadan University of Medical Sciences",
    website: "http://www.umsha.ac.ir/",
  },
  {
    value: "3549",
    label: "Hormozgan University of Medical Sciences",
    website: "http://www.hums.ac.ir/",
  },
  {
    value: "3550",
    label: "Hyrcania Institute of Higher Education",
    website: "http://www.hyrcania.ac.ir/",
  },
  {
    value: "3551",
    label: "Ilam University",
    website: "http://www.ilam.ac.ir/",
  },
  {
    value: "3552",
    label: "Ilam University of Medical Sciences",
    website: "http://www.medilam.hbi.ir/",
  },
  {
    value: "3553",
    label: "Imam Khomeini International University",
    website: "http://www.ikiu.ac.ir/",
  },
  {
    value: "3554",
    label: "Imam Reza University",
    website: "http://www.imamreza.ac.ir/",
  },
  {
    value: "3555",
    label: "Imam Sadiq University",
    website: "http://www.isu.ac.ir/",
  },
  {
    value: "3556",
    label: "Iranian Academy of Persian Language and Literature",
    website: "http://www.persianacademy.ir/",
  },
  {
    value: "3557",
    label: "Iran University of Medical Sciences",
    website: "http://www.iums.ac.ir/",
  },
  {
    value: "3558",
    label: "Iran University of Science and Technology Tehran",
    website: "http://www.iust.ac.ir/",
  },
  {
    value: "3559",
    label: "Isfahan University",
    website: "http://www.ui.ac.ir/",
  },
  {
    value: "3560",
    label: "Isfahan University of Medical Sciences",
    website: "http://www.mui.ac.ir/",
  },
  {
    value: "3561",
    label: "Isfahan University of Technology",
    website: "http://www.iut.ac.ir/",
  },
  {
    value: "3562",
    label: "Islamic Azad University, Ahar",
    website: "http://www.iau-ahar.ac.ir/",
  },
  {
    value: "3563",
    label: "Islamic Azad University, Aliabad ",
    website: "http://www.aliabadiau.ac.ir/",
  },
  {
    value: "3564",
    label: "Islamic Azad University, Arak",
    website: "http://www.iau-arak.ac.ir/",
  },
  {
    value: "3565",
    label: "Islamic Azad University, Ardabil",
    website: "http://www.iauardabil.ac.ir/",
  },
  {
    value: "3566",
    label: "Islamic Azad University, Bandar Abbas",
    website: "http://www.iauba.ac.ir/",
  },
  {
    value: "3567",
    label: "Islamic Azad University, Bojnourd",
    website: "http://www.bojnourdiau.ac.ir/",
  },
  {
    value: "3568",
    label: "Islamic Azad University, Bostanabad",
    website: "http://www.iaubos.ac.ir/",
  },
  {
    value: "3569",
    label: "Islamic Azad University, Boukan",
    website: "http://www.iau-boukan.ac.ir",
  },
  {
    value: "3570",
    label: "Islamic Azad University, Central Tehran Branch",
    website: "http://www.iauctb.ac.ir/",
  },
  {
    value: "3571",
    label: "Islamic Azad University, Dehaghan",
    website: "http://www.dehaghan.ac.ir/",
  },
  {
    value: "3572",
    label: "Islamic Azad University, Falavarjan ",
    website: "http://www.falavarjan.ac.ir/",
  },
  {
    value: "3573",
    label: "Islamic Azad University, Fars Science & Research Branch",
    website: "http://www.fsriau.ac.ir/",
  },
  {
    value: "3574",
    label: "Islamic Azad University, Garmsar",
    website: "http://www.iau-garmsar.ac.ir/",
  },
  {
    value: "3575",
    label: "Islamic Azad University, Gorgan",
    website: "http://www.gorganiau.ir/",
  },
  {
    value: "3576",
    label: "Islamic Azad University, Hamedan",
    website: "http://www.iauh.ac.ir/",
  },
  {
    value: "3577",
    label: "Islamic Azad University, Janah",
    website: "http://www.janahiau.ac.ir/",
  },
  {
    value: "3578",
    label: "Islamic Azad University, Karaj",
    website: "http://www.iaukb.ir/",
  },
  {
    value: "3579",
    label: "Islamic Azad University, Kazeron",
    website: "http://www.kau.ac.ir/",
  },
  {
    value: "3580",
    label: "Islamic Azad University, Kerman",
    website: "http://www.iauk.ac.ir/",
  },
  {
    value: "3581",
    label: "Islamic Azad University, Khorasgan",
    website: "http://www.khuisf.ac.ir/",
  },
  {
    value: "3582",
    label: "Islamic Azad University, Lahijan",
    website: "http://www.iau-lahijan.ac.ir/",
  },
  {
    value: "3583",
    label: "Islamic Azad University, Mahshar",
    website: "http://www.mahshahriau.ac.ir/",
  },
  {
    value: "3584",
    label: "Islamic Azad University, Majlesi",
    website: "http://www.iaumajlesi.ac.ir/",
  },
  {
    value: "3585",
    label: "Islamic Azad University, Mashhad",
    website: "http://www.mshdiau.ac.ir/",
  },
  {
    value: "3586",
    label: "Islamic Azad University, Maybod",
    website: "http://www.maybod.ac.ir/",
  },
  {
    value: "3587",
    label: "Islamic Azad University, Mehriz",
    website: "http://www.iaumehriz.com/",
  },
  {
    value: "3588",
    label: "Islamic Azad University, Mobarakeh",
    website: "http://www.mau.ac.ir/",
  },
  {
    value: "3589",
    label: "Islamic Azad University, Najaf Abad",
    website: "http://www.iaun.ac.ir/",
  },
  {
    value: "3590",
    label: "Islamic Azad University, North Tehran",
    website: "http://www.iau-tnb.ac.ir/",
  },
  {
    value: "3591",
    label: "Islamic Azad University, Parand",
    website: "http://www.piau.ac.ir/",
  },
  {
    value: "3592",
    label: "Islamic Azad University, Qazvin",
    website: "http://www.qazviniau.ac.ir/",
  },
  {
    value: "3593",
    label: "Islamic Azad University, Qom",
    website: "http://www.qom-iau.ac.ir/",
  },
  {
    value: "3594",
    label: "Islamic Azad University, Quchan",
    website: "http://www.iauq.ac.ir/",
  },
  {
    value: "3595",
    label: "Islamic Azad University, Region One",
    website: "http://www.iauro.ac.ir/",
  },
  {
    value: "3596",
    label: "Islamic Azad University, Roodehen",
    website: "http://www.riau.ac.ir/",
  },
  {
    value: "3597",
    label: "Islamic Azad University, Sanandaj",
    website: "http://www.iausdj.ac.ir/",
  },
  {
    value: "3598",
    label: "Islamic Azad University, Saveh",
    website: "http://www.iau-saveh.ac.ir/",
  },
  {
    value: "3599",
    label: "Islamic Azad University, Semnan",
    website: "http://www.semnaniau.ac.ir/",
  },
  {
    value: "3600",
    label: "Islamic Azad University, Shabestar",
    website: "http://www.iaushab.ac.ir/",
  },
  {
    value: "3601",
    label: "Islamic Azad University, Shahr-e-rey Branch",
    website: "http://www.iausr.ac.ir/",
  },
  {
    value: "3602",
    label: "Islamic Azad University, Shahrood",
    website: "http://www.iau-shahrood.ac.ir/",
  },
  {
    value: "3603",
    label: "Islamic Azad University, Shahryar",
    website: "http://www.shahryariau.ac.ir/",
  },
  {
    value: "3604",
    label: "Islamic Azad University, Shiraz",
    website: "http://www.iaushiraz.ac.ir/",
  },
  {
    value: "3605",
    label: "Islamic Azad University, Shirvan",
    website: "http://www.iau-shirvan.ac.ir/",
  },
  {
    value: "3606",
    label: "Islamic Azad University, Sowmesara",
    website: "http://www.sowmesaraiau.ac.ir/",
  },
  {
    value: "3607",
    label: "Islamic Azad University, Tabriz",
    website: "http://www.iaut.ac.ir/",
  },
  {
    value: "3608",
    label: "Islamic Azad University, Tafresh",
    website: "http://www.iautb.ac.ir/",
  },
  {
    value: "3609",
    label: "Islamic Azad University, Tehran Medical",
    website: "http://www.iautmu.ac.ir/",
  },
  {
    value: "3610",
    label: "Islamic Azad University, Tehran Science & Research Branch",
    website: "http://www.srbiau.ac.ir/",
  },
  {
    value: "3611",
    label: "Islamic Azad University, Tehran South",
    website: "http://www.azad.ac.ir/",
  },
  {
    value: "3612",
    label: "Islamic Azad University, Varamin",
    website: "http://www.iauvaramin.ac.ir/",
  },
  {
    value: "3613",
    label: "Islamic Azad University, Yazd",
    website: "http://www.iauyazd.ac.ir/",
  },
  {
    value: "3614",
    label: "Jahrom University of Medical Sciences",
    website: "http://www.jums.ac.ir/",
  },
  {
    value: "3615",
    label: "Jami Institute of Technology",
    website: "http://www.jami.ac.ir/",
  },
  {
    value: "3616",
    label:
      "Kamalolmolk Art & Architecture Higher Education Institute of Noshahr",
    website: "http://www.kamalolmolk.ac.ir/",
  },
  {
    value: "3617",
    label: "Kar University",
    website: "http://www.karuniversity.com/",
  },
  {
    value: "3618",
    label: "Kashan University",
    website: "http://www.kashanu.ac.ir/",
  },
  {
    value: "3619",
    label: "Kashan University of Medical Sciences",
    website: "http://www.kaums.ac.ir/",
  },
  {
    value: "3620",
    label: "Kerman Graduate University of Technology",
    website: "http://www.kgut.ac.ir/",
  },
  {
    value: "3621",
    label: "Kermanshah University of Medical Sciences",
    website: "http://www.kums.ac.ir/",
  },
  {
    value: "3622",
    label: "Kermanshah University of Technology",
    website: "http://www.kut.ac.ir/",
  },
  {
    value: "3623",
    label: "Kerman University of Medical Sciences",
    website: "http://www.kmu.ac.ir/",
  },
  {
    value: "3624",
    label: "Khomeinishahr Payame Noor University",
    website: "http://km.isfpnu.ac.ir/",
  },
  {
    value: "3625",
    label: "K.N.Toosi University of Technology",
    website: "http://www.kntu.ac.ir/",
  },
  {
    value: "3626",
    label: "Kurdistan University of Medical Sciences",
    website: "http://www.muk.ac.ir/",
  },
  {
    value: "3627",
    label: "Lorestan University",
    website: "http://www.lu.ac.ir/",
  },
  {
    value: "3628",
    label: "Lorestan University of Medical Sciences",
    website: "http://www.lums.ac.ir/",
  },
  {
    value: "3629",
    label: "Malayer University",
    website: "http://www.malayeru.ac.ir/",
  },
  {
    value: "3630",
    label: "Malekan Payame Nour University",
    website: "http://www.malekanpnu.ac.ir/",
  },
  {
    value: "3631",
    label: "Mamasani University of Technology",
    website: "http://www.mamasaniiau.ac.ir/",
  },
  {
    value: "3632",
    label: "Mashhad University of Medical Sciences",
    website: "http://www.mums.ac.ir/",
  },
  {
    value: "3633",
    label: "Mazandaran University of Iran",
    website: "http://www.umz.ac.ir/",
  },
  {
    value: "3634",
    label: "Mazandaran University of Medical Science",
    website: "http://www.mazums.ac.ir/",
  },
  {
    value: "3635",
    label: "Mazandaran University of Science & Technology",
    website: "http://www.ustmb.ac.ir/",
  },
  {
    value: "3636",
    label: "Mofid University",
    website: "http://www.mofidu.ac.ir/",
  },
  {
    value: "3637",
    label: "Mustafa International University",
    website: "http://www.miu.ac.ir/",
  },
  {
    value: "3638",
    label: "Najaf Abad University of Applied Science and Technology",
    website: "http://www.najafabad-uast.ac.ir/",
  },
  {
    value: "3639",
    label: "Payame Noor University",
    website: "http://www.pnu.ac.ir/",
  },
  {
    value: "3640",
    label: "Payame Noor University, Tafresh",
    website: "http://www.pnut.ac.ir/",
  },
  {
    value: "3641",
    label: "Persian Gulf University",
    website: "http://www.pgu.ac.ir/",
  },
  {
    value: "3642",
    label: "Petroleum University of Technology",
    website: "http://www.put.ac.ir/",
  },
  {
    value: "3643",
    label: "Power and Water Institute of Technology",
    website: "http://www.pwit.ac.ir/",
  },
  {
    value: "3644",
    label: "Qazvin University of Medical Sciences",
    website: "http://www.qums.ac.ir/",
  },
  {
    value: "3645",
    label: "Qom University",
    website: "http://www.qom.ac.ir/",
  },
  {
    value: "3646",
    label: "Qom University of Medical Sciences",
    website: "http://www.muq.ac.ir/",
  },
  {
    value: "3647",
    label: "Qom University of Technology",
    website: "http://www.qut.ac.ir/",
  },
  {
    value: "3648",
    label: "Rafsanjan University of Medical Sciences",
    website: "http://www.rums.ac.ir/",
  },
  {
    value: "3649",
    label: "Raghebe Esfahani University",
    website: "http://www.raghebisf.ac.ir/",
  },
  {
    value: "3650",
    label: "Razi University",
    website: "http://www.razi.ac.ir/",
  },
  {
    value: "3651",
    label: "Sabzevar School of Medical Sciences",
    website: "http://www.medsab.ac.ir/",
  },
  {
    value: "3652",
    label: "Sabzevar Teacher Training University",
    website: "http://www.sttu.ac.ir/",
  },
  {
    value: "3653",
    label: "Sadjad Institute of Technology",
    website: "http://www.sadjad.ac.ir/",
  },
  {
    value: "3654",
    label: "Sadra University",
    website: "http://www.sadra.ac.ir/",
  },
  {
    value: "3655",
    label: "Sahand University of Technology",
    website: "http://www.sut.ac.ir/",
  },
  {
    value: "3656",
    label: "School of Economic Sciences",
    website: "http://www.ses.ac.ir/",
  },
  {
    value: "3657",
    label: "Semnan University",
    website: "http://www.semnan.ac.ir/",
  },
  {
    value: "3658",
    label: "Semnan University of Medical Sciences",
    website: "http://www.sem-ums.ac.ir/",
  },
  {
    value: "3659",
    label: "Shahed University",
    website: "http://www.shahed.ac.ir/",
  },
  {
    value: "3660",
    label: "Shaheed Beheshti University",
    website: "http://www.sbu.ac.ir/",
  },
  {
    value: "3661",
    label: "Shaheed Beheshti University of Medical Sciences",
    website: "http://www.sbmu.ac.ir/",
  },
  {
    value: "3662",
    label: "Shaheed Chamran University",
    website: "http://www.cua.ac.ir/",
  },
  {
    value: "3663",
    label: "Shaheed Rajaei Teacher Training University",
    website: "http://www.srttu.edu/",
  },
  {
    value: "3664",
    label: "Shahid Bahonar University of Kerman",
    website: "http://www.uk.ac.ir/",
  },
  {
    value: "3665",
    label: "Shahrekord University",
    website: "http://www.sku.ac.ir/",
  },
  {
    value: "3666",
    label: "Shahrekord University of Medical Sciences",
    website: "http://www.skums.ac.ir/",
  },
  {
    value: "3667",
    label: "Shahrood University of Medical Sciences",
    website: "http://www.shmu.ac.ir/",
  },
  {
    value: "3668",
    label: "Shahrood University of Technology",
    website: "http://www.shahroodut.ac.ir/",
  },
  {
    value: "3669",
    label: "Sharif University of Technology",
    website: "http://www.sharif.ac.ir/",
  },
  {
    value: "3670",
    label: "Sharif University of Technology, Kish Campus",
    website: "http://www.kish.ac.ir/",
  },
  {
    value: "3671",
    label: "Sheikhbahaee University",
    website: "http://www.shbu.ac.ir/",
  },
  {
    value: "3672",
    label: "Shiraz Payamnoor University",
    website: "http://www.spnu.ac.ir/",
  },
  {
    value: "3673",
    label: "Shiraz University",
    website: "http://www.shirazu.ac.ir/",
  },
  {
    value: "3674",
    label: "Shiraz University of Medical Sciences",
    website: "http://www.sums.ac.ir/",
  },
  {
    value: "3675",
    label: "Shiraz University of Technology",
    website: "http://www.sutech.ac.ir/",
  },
  {
    value: "3676",
    label: "Shomal University",
    website: "http://www.shomal.ac.ir/",
  },
  {
    value: "3677",
    label: "Sistan & Balouchestan University",
    website: "http://www.usb.ac.ir/",
  },
  {
    value: "3678",
    label: "Tabari Institute of Higher Education",
    website: "http://www.tabari.ac.ir/ ",
  },
  {
    value: "3679",
    label: "Tabriz College of Technology",
    website: "http://www.tct.ac.ir/",
  },
  {
    value: "3680",
    label: "Tabriz Islamic Art University",
    website: "http://www.tabriziau.ac.ir/",
  },
  {
    value: "3681",
    label: "Tabriz University",
    website: "http://www.tabrizu.ac.ir/",
  },
  {
    value: "3682",
    label: "Tabriz University of Medical Sciences",
    website: "http://www.tbzmed.ac.ir/",
  },
  {
    value: "3683",
    label: "Takestan Islamic Azad University",
    website: "http://www.tiau.ac.ir/",
  },
  {
    value: "3684",
    label: "Tarbiat Modares University",
    website: "http://www.modares.ac.ir/",
  },
  {
    value: "3685",
    label: "Tehran University of Medical Sciences",
    website: "http://www.tums.ac.ir/",
  },
  {
    value: "3686",
    label: "Tehran University of Teacher Training",
    website: "http://www.tmu.ac.ir/",
  },
  {
    value: "3687",
    label: "The Bahá'í Institute For Higher Education (BIHE)",
    website: "http://www.bihe.org/",
  },
  {
    value: "3688",
    label: "Torbat Heydarieh University of Medical Sciences",
    website: "http://www.thums.ac.ir/",
  },
  {
    value: "3689",
    label: "University College of Nabi Akram",
    website: "http://www.ucna.info/",
  },
  {
    value: "3690",
    label: "University of Applied Science and Technology",
    website: "http://www.uast.ac.ir/",
  },
  {
    value: "3691",
    label: "University of Hormozgan",
    website: "http://www.hormozgan.ac.ir/",
  },
  {
    value: "3692",
    label: "University of Kurdistan",
    website: "http://www.uok.ac.ir/",
  },
  {
    value: "3693",
    label: "University of Lavasanat Pnu",
    website: "http://www.lavasanpnu.ac.ir/",
  },
  {
    value: "3694",
    label: "University of Mashhad Pnu",
    website: "http://www.pnum.ac.ir/",
  },
  {
    value: "3695",
    label: "University of Mohaghegh",
    website: "http://www.uma.ac.ir/",
  },
  {
    value: "3696",
    label: "University of Science and Culture",
    website: "http://www.usc.ac.ir/",
  },
  {
    value: "3697",
    label: "University of Social Welfare and Rehabilitation Scinences",
    website: "http://www.uswr.ac.ir/",
  },
  {
    value: "3698",
    label: "University of Tehran",
    website: "http://www.ut.ac.ir/",
  },
  {
    value: "3699",
    label: "Urmia University",
    website: "http://www.urmia.ac.ir/",
  },
  {
    value: "3700",
    label: "Urmia University of Medical Sciences",
    website: "http://www.umsu.ac.ir/",
  },
  {
    value: "3701",
    label: "Urmia University of Technology",
    website: "http://www.uut.ac.ir/",
  },
  {
    value: "3702",
    label: "Vali-e-Asr University",
    website: "http://www.vru.ac.ir/",
  },
  {
    value: "3703",
    label: "Yasuj University",
    website: "http://www.yu.ac.ir/",
  },
  {
    value: "3704",
    label: "Yasuj University of Medical Sciences",
    website: "http://www.yums.ac.ir/",
  },
  {
    value: "3705",
    label: "Yazd Medical University",
    website: "http://www.ssu.ac.ir/",
  },
  {
    value: "3706",
    label: "Yazd University",
    website: "http://www.yazduni.ac.ir/",
  },
  {
    value: "3707",
    label: "Zabol University",
    website: "http://www.uoz.ac.ir/",
  },
  {
    value: "3708",
    label: "Zahedan University of Medical Sciences",
    website: "http://www.zdmu.ac.ir/",
  },
  {
    value: "3709",
    label: "Zanjan Institute for Advanced Studies in Basic Sciences",
    website: "http://www.iasbs.ac.ir/",
  },
  {
    value: "3710",
    label: "Zanjan University",
    website: "http://www.znu.ac.ir/",
  },
  {
    value: "3711",
    label: "Zanjan University of Medical Sciences",
    website: "http://www.zums.ac.ir/",
  },
  {
    value: "3712",
    label: "Bifrost School of Business",
    website: "http://www.bifrost.is/",
  },
  {
    value: "3713",
    label: "Holar University College",
    website: "http://www.holar.is/",
  },
  {
    value: "3714",
    label: "Hvanneyri Agricultural University",
    website: "http://www.hvanneyri.is/",
  },
  {
    value: "3715",
    label: "Iceland Academy of the Arts",
    website: "http://www.lhi.is/",
  },
  {
    value: "3716",
    label: "Iceland University of Education",
    website: "http://www.khi.is/",
  },
  {
    value: "3717",
    label: "Reykjavík University",
    website: "http://www.ru.is/",
  },
  {
    value: "3718",
    label: "Technological University of Iceland",
    website: "http://www.iti.is/",
  },
  {
    value: "3719",
    label: "University Centre of the Westfjords",
    website: "http://www.hsvest.is/",
  },
  {
    value: "3720",
    label: "University of Akureyri",
    website: "http://www.unak.is/",
  },
  {
    value: "3721",
    label: "University of Iceland",
    website: "http://www.hi.is/",
  },
  {
    value: "3722",
    label: "Abdus Salam international centre for theoretical physics",
    website: "http://www.ictp.trieste.it/",
  },
  {
    value: "3723",
    label: "Basilicata University Potenza",
    website: "http://www.unibas.it/",
  },
  {
    value: "3724",
    label: "Campus Bio-Medico University of Rome",
    website: "http://www.unicampus.it/",
  },
  {
    value: "3725",
    label: "Catholic University of the Sacred Heart",
    website: "http://www.unicatt.it/",
  },
  {
    value: "3726",
    label: "Dutch University Institute for Art History (DUIA)",
    website: "http://www.iuoart.org/",
  },
  {
    value: "3727",
    label: "European School of Economics",
    website: "http://www.uniese.it/",
  },
  {
    value: "3728",
    label: "European University Institute",
    website: "http://www.iue.it/",
  },
  {
    value: "3729",
    label: "Fondazione Sacro Cuore",
    website: "http://www.sacrocuore.org/",
  },
  {
    value: "3730",
    label: "Free International University of Social Studies",
    website: "http://www.luiss.it/",
  },
  {
    value: "3731",
    label: 'Free University Institute "Carlo Cattaneo"',
    website: "http://www.liuc.it/",
  },
  {
    value: "3732",
    label: 'Free University "Maria Santissima Assunta"',
    website: "http://www.lumsa.it/",
  },
  {
    value: "3733",
    label: "Free University of Bozen",
    website: "http://www.unibz.it/",
  },
  {
    value: "3734",
    label: "Higher School of University and Advanced Studies Pisa",
    website: "http://www.sssup.it/",
  },
  {
    value: "3735",
    label: "Institute for Advanced Studies Lucca",
    website: "http://www.imtlucca.it/",
  },
  {
    value: "3736",
    label: "International Higher School for Advanced Studies Trieste",
    website: "http://www.sissa.it/",
  },
  {
    value: "3737",
    label: "Istituto Universitario di Studi Superiori",
    website: "http://www.iusspavia.it/",
  },
  {
    value: "3738",
    label: "John Cabot University",
    website: "http://www.johncabot.edu/",
  },
  {
    value: "3739",
    label: "Johns Hopkins University, SAIS Bologna Center",
    website: "http://www.jhubc.it/",
  },
  {
    value: "3740",
    label: "Link Campus University of Malta",
    website: "http://www.unilink.it/",
  },
  {
    value: "3741",
    label: "NABA - Nuova Accademia di Belle Arti, Milan",
    website: "http://www.naba.it/",
  },
  {
    value: "3742",
    label: "Polytechnic Institute of Bari",
    website: "http://www.poliba.it/",
  },
  {
    value: "3743",
    label: "Polytechnic Institute of Milan",
    website: "http://www.polimi.it/",
  },
  {
    value: "3744",
    label: "Polytechnic Institute of Turin",
    website: "http://www.polito.it/",
  },
  {
    value: "3745",
    label: "Pontifica Università Gregoriana",
    website: "http://www.unigre.it/",
  },
  {
    value: "3746",
    label: "Pontificia Università della Santa Croce",
    website: "http://www.pusc.it/",
  },
  {
    value: "3747",
    label: "Pontificia Università Lateranense",
    website: "http://www.pul.it/",
  },
  {
    value: "3748",
    label: "Pontificia Università S. Tommaso",
    website: "http://www.angelicum.org/",
  },
  {
    value: "3749",
    label: "Pontificio Ateneo Antonianum",
    website: "http://www.antonianum.ofm.org/",
  },
  {
    value: "3750",
    label: "Pontificio Istituto Orientale",
    website: "http://www.pio.urbe.it/",
  },
  {
    value: "3751",
    label: "School of Education Pisa",
    website: "http://www.sns.it/",
  },
  {
    value: "3752",
    label: "Second University of Naples",
    website: "http://www.unina2.it/",
  },
  {
    value: "3753",
    label: "Third University of Rome",
    website: "http://www.uniroma3.it/",
  },
  {
    value: "3754",
    label: "Università Bocconi",
    website: "http://www.uni-bocconi.it/",
  },
  {
    value: "3755",
    label: "Università Pontificia Salesiana",
    website: "http://www.unisal.it/",
  },
  {
    value: "3756",
    label: "University Institute of Architecture Venice",
    website: "http://www.iuav.unive.it/",
  },
  {
    value: "3757",
    label: "University Institute of Modern Languages",
    website: "http://www.iulm.it/",
  },
  {
    value: "3758",
    label: "University Institute of Naval Studies",
    website: "http://www.uninav.it/",
  },
  {
    value: "3759",
    label: "University Institute of Oriental Studies",
    website: "http://www.iuo.it/",
  },
  {
    value: "3760",
    label: 'University Institute of Teacher Training "Suor Orsola Benincasa"',
    website: "http://www.unisob.na.it/",
  },
  {
    value: "3761",
    label: "University of Ancona",
    website: "http://www.unian.it/",
  },
  {
    value: "3762",
    label: "University of Aquila",
    website: "http://www.univaq.it/",
  },
  {
    value: "3763",
    label: "University of Bari",
    website: "http://www.uniba.it/",
  },
  {
    value: "3764",
    label: "University of Bergamo",
    website: "http://www.unibg.it/",
  },
  {
    value: "3765",
    label: "University of Bologna",
    website: "http://www.unibo.it/",
  },
  {
    value: "3766",
    label: "University of Brescia",
    website: "http://www.unibs.it/",
  },
  {
    value: "3767",
    label: "University of Cagliari",
    website: "http://www.unica.it/",
  },
  {
    value: "3768",
    label: "University of Calabria",
    website: "http://www.unical.it/",
  },
  {
    value: "3769",
    label: "University of Camerino",
    website: "http://www.unicam.it/",
  },
  {
    value: "3770",
    label: "University of Cassino",
    website: "http://www.unicas.it/",
  },
  {
    value: "3771",
    label: "University of Catania",
    website: "http://www.unict.it/",
  },
  {
    value: "3772",
    label: "University of Chieti",
    website: "http://www.unich.it/",
  },
  {
    value: "3773",
    label: "University of Commerce Luigi Bocconi",
    website: "http://www.uni-bocconi.it/",
  },
  {
    value: "3774",
    label: "University of Ferrara",
    website: "http://www.unife.it/",
  },
  {
    value: "3775",
    label: "University of Florence",
    website: "http://www.unifi.it/",
  },
  {
    value: "3776",
    label: "University of Foggia",
    website: "http://www.unifg.it/",
  },
  {
    value: "3777",
    label: "University of Gastronomic Sciences",
    website: "http://www.unisg.it/",
  },
  {
    value: "3778",
    label: "University of Genoa",
    website: "http://www.unige.it/",
  },
  {
    value: "3779",
    label: "University of Insubria",
    website: "http://www.uninsubria.it/",
  },
  {
    value: "3780",
    label: "University of Italian Studies for Foreigners of Perugia",
    website: "http://www.unistrapg.it/",
  },
  {
    value: "3781",
    label: "University of Italian Studies for Foreigners of Siena",
    website: "http://www.unistrasi.it/",
  },
  {
    value: "3782",
    label: "University of Lecce",
    website: "http://www.unile.it/",
  },
  {
    value: "3783",
    label: "University of Macerata",
    website: "http://www.unimc.it/",
  },
  {
    value: "3784",
    label: "University of Messina",
    website: "http://www.unime.it/",
  },
  {
    value: "3785",
    label: "University of Milan",
    website: "http://www.unimi.it/",
  },
  {
    value: "3786",
    label: "University of Milan - Bicocca",
    website: "http://www.unimib.it/",
  },
  {
    value: "3787",
    label: "University of Modena",
    website: "http://www.casa.unimo.it/",
  },
  {
    value: "3788",
    label: "University of Molise",
    website: "http://www.unimol.it/",
  },
  {
    value: "3789",
    label: "University of Naples Federico II",
    website: "http://www.unina.it/",
  },
  {
    value: "3790",
    label: "University of Padua",
    website: "http://www.unipd.it/",
  },
  {
    value: "3791",
    label: "University of Palermo",
    website: "http://www.unipa.it/",
  },
  {
    value: "3792",
    label: "University of Parma",
    website: "http://www.unipr.it/",
  },
  {
    value: "3793",
    label: "University of Pavia",
    website: "http://www.unipv.it/",
  },
  {
    value: "3794",
    label: "University of Perugia",
    website: "http://www.unipg.it/",
  },
  {
    value: "3795",
    label: "University of Pisa",
    website: "http://www.unipi.it/",
  },
  {
    value: "3796",
    label: "University of Reggio Calabria",
    website: "http://www.unirc.it/",
  },
  {
    value: "3797",
    label: 'University of Roma "La Sapienza"',
    website: "http://www.uniroma1.it/",
  },
  {
    value: "3798",
    label: 'University of Roma "Tor Vergata"',
    website: "http://www.uniroma2.it/",
  },
  {
    value: "3799",
    label: "University of Salerno",
    website: "http://www.unisa.it/",
  },
  {
    value: "3800",
    label: "University of Sannio",
    website: "http://www.unisannio.it/",
  },
  {
    value: "3801",
    label: "University of Sassari",
    website: "http://www.uniss.it/",
  },
  {
    value: "3802",
    label: "University of Siena",
    website: "http://www.unisi.it/",
  },
  {
    value: "3803",
    label: "University of Teramo",
    website: "http://www.unite.it/",
  },
  {
    value: "3804",
    label: "University of Trento",
    website: "http://www.unitn.it/",
  },
  {
    value: "3805",
    label: "University of Trieste",
    website: "http://www.univ.trieste.it/",
  },
  {
    value: "3806",
    label: "University of Turin",
    website: "http://www.unito.it/",
  },
  {
    value: "3807",
    label: "University of Udine",
    website: "http://www.uniud.it/",
  },
  {
    value: "3808",
    label: "University of Urbino",
    website: "http://www.uniurb.it/",
  },
  {
    value: "3809",
    label: "University of Venice",
    website: "http://www.unive.it/",
  },
  {
    value: "3810",
    label: "University of Verona",
    website: "http://www.univr.it/",
  },
  {
    value: "3811",
    label: "Viterbo State University",
    website: "http://www.unitus.it/",
  },
  {
    value: "3812",
    label: "Yorker International University, Milano",
    website: "http://www.nyuniversity.net/",
  },
  {
    value: "3813",
    label: "Northern Caribbean University",
    website: "http://www.ncu.edu.jm/",
  },
  {
    value: "3814",
    label: "University of Technology Jamaica",
    website: "http://www.utech.edu.jm/",
  },
  {
    value: "3815",
    label: "University of the West Indies, Mona",
    website: "http://www.uwimona.edu.jm/",
  },
  {
    value: "3816",
    label: "Al-Ahliyya Amman University",
    website: "http://www.ammanu.edu.jo/",
  },
  {
    value: "3817",
    label: "Al al-Bayt University",
    website: "http://www.aabu.edu.jo/",
  },
  {
    value: "3818",
    label: "Al-Balqa Applied University",
    website: "http://www.bau.edu.jo/",
  },
  {
    value: "3819",
    label: "Al Hussein Bin Talal University",
    website: "http://www.ahu.edu.jo/",
  },
  {
    value: "3820",
    label: "Al-Isra University",
    website: "http://www.isra.edu.jo/",
  },
  {
    value: "3821",
    label: "Al-Zaytoonah University",
    website: "http://www.alzaytoonah.edu.jo/",
  },
  {
    value: "3822",
    label: "Amman Arab University for Higher Studies",
    website: "http://www.aau.edu.jo/",
  },
  {
    value: "3823",
    label: "Amman University",
    website: "http://www.amman.edu/",
  },
  {
    value: "3824",
    label: "Applied Science University",
    website: "http://www.asu.edu.jo/",
  },
  {
    value: "3825",
    label: "Arab Academy for Banking and Financial Sciences",
    website: "http://www.aabfs.org/",
  },
  {
    value: "3826",
    label: "Arab Open University",
    website: "http://www.aou.edu.jo/",
  },
  {
    value: "3827",
    label: "Balqa Applied University",
    website: "http://www.bau.edu.jo/",
  },
  {
    value: "3828",
    label: "German Jordanian University",
    website: "http://www.gju.edu.jo/",
  },
  {
    value: "3829",
    label: "Hashemite University",
    website: "http://www.hu.edu.jo/",
  },
  {
    value: "3830",
    label: "Irbid National University",
    website: "http://www.inu.edu.jo/",
  },
  {
    value: "3831",
    label: "Jadara University",
    website: "http://www.jadara.edu.jo/",
  },
  {
    value: "3832",
    label: "Jerash Private University",
    website: "http://www.jerashun.edu.jo/",
  },
  {
    value: "3833",
    label: "Jordan Academy of Music / Higher Institute of Music",
    website: "http://www.jam.edu.jo/",
  },
  {
    value: "3834",
    label: "Jordan University of Science and Technology",
    website: "http://www.just.edu.jo/",
  },
  {
    value: "3835",
    label: "Middle East University",
    website: "http://www.meu.edu.jo/",
  },
  {
    value: "3836",
    label: "Mu' tah University",
    website: "http://www.mutah.edu.jo/",
  },
  {
    value: "3837",
    label: "New York Institute of Technology",
    website: "http://www.nyit.edu.jo/",
  },
  {
    value: "3838",
    label: "Philadelphia University",
    website: "http://www.philadelphia.edu.jo/",
  },
  {
    value: "3839",
    label: "Princess Sumaya University for Technology",
    website: "http://www.psut.edu.jo/",
  },
  {
    value: "3840",
    label: "Tafila Technical University ",
    website: "http://www.ttu.edu.jo/",
  },
  {
    value: "3841",
    label: "The World Islamic Sciences & Education University",
    website: "http://www.wise.edu.jo/",
  },
  {
    value: "3842",
    label: "University of Jordan",
    website: "http://www.ju.edu.jo/",
  },
  {
    value: "3843",
    label: "University of Petra",
    website: "http://www.uop.edu.jo/",
  },
  {
    value: "3844",
    label: "Yarmouk University",
    website: "http://www.yu.edu.jo/",
  },
  {
    value: "3845",
    label: "Zarka Private University",
    website: "http://www.zpu.edu.jo/",
  },
  {
    value: "3846",
    label: "Aichi Bunkyo University",
    website: "http://www.abu.ac.jp/",
  },
  {
    value: "3847",
    label: "Aichi Gakuin University",
    website: "http://www.aichi-gakuin.ac.jp/",
  },
  {
    value: "3848",
    label: "Aichi Gakusen University",
    website: "http://www.gakusen.ac.jp/",
  },
  {
    value: "3849",
    label: "Aichi Institute of Technology",
    website: "http://www.aitech.ac.jp/",
  },
  {
    value: "3850",
    label: "Aichi Medical University",
    website: "http://www.aichi-med-u.ac.jp/",
  },
  {
    value: "3851",
    label: "Aichi Prefectural University",
    website: "http://www.aichi-pu.ac.jp/",
  },
  {
    value: "3852",
    label: "Aichi Prefectural University of Fine Arts & Music",
    website: "http://www.aichi-fam-u.ac.jp/",
  },
  {
    value: "3853",
    label: "Aichi Sangyo University",
    website: "http://www.asu.ac.jp/",
  },
  {
    value: "3854",
    label: "Aichi Shukutoku University",
    website: "http://www.aasa.ac.jp/",
  },
  {
    value: "3855",
    label: "Aichi University",
    website: "http://www.aichi-u.ac.jp/",
  },
  {
    value: "3856",
    label: "Aichi University of Education",
    website: "http://www.aichi-edu.ac.jp/",
  },
  {
    value: "3857",
    label: "Aikoku Gakuen University",
    website: "http://www.aikoku-u.ac.jp/",
  },
  {
    value: "3858",
    label: "Akita University",
    website: "http://www.akita-u.ac.jp/",
  },
  {
    value: "3859",
    label: "Akita University of Economics and Law",
    website: "http://www.akeihou-u.ac.jp/",
  },
  {
    value: "3860",
    label: "American University Extension, Okinawa",
    website: "http://www.aue-okinawa.ac.jp/",
  },
  {
    value: "3861",
    label: "Aomori Chuoh Gakuin University",
    website: "http://www.aomoricgu.ac.jp/",
  },
  {
    value: "3862",
    label: "Aomori Public College",
    website: "http://www.nebuta.ac.jp/",
  },
  {
    value: "3863",
    label: "Aomori University",
    website: "http://www.aomori-u.ac.jp/",
  },
  {
    value: "3864",
    label: "Aomori University of Health and Welfare",
    website: "http://www.auhw.ac.jp/",
  },
  {
    value: "3865",
    label: "Aoyama Gakuin University",
    website: "http://www.aoyama.ac.jp/",
  },
  {
    value: "3866",
    label: "Asahikawa Medical College",
    website: "http://www.asahikawa-med.ac.jp/",
  },
  {
    value: "3867",
    label: "Asahikawa University",
    website: "http://www.asahikawa-u.ac.jp/",
  },
  {
    value: "3868",
    label: "Asahi University",
    website: "http://www.asahi-u.ac.jp/",
  },
  {
    value: "3869",
    label: "Ashikaga Institute of Technology",
    website: "http://www.ashitech.ac.jp/",
  },
  {
    value: "3870",
    label: "Ashiya University",
    website: "http://www.ashiya-u.ac.jp/",
  },
  {
    value: "3871",
    label: "Asia University",
    website: "http://www.asia-u.ac.jp/",
  },
  {
    value: "3872",
    label: "Atomi College",
    website: "http://www.atomi.ac.jp/",
  },
  {
    value: "3873",
    label: "Azabu University",
    website: "http://www.azabu-u.ac.jp/",
  },
  {
    value: "3874",
    label: "Baika Women's College",
    website: "http://www.baika.ac.jp/",
  },
  {
    value: "3875",
    label: "Baiko Women's College",
    website: "http://www.baiko.ac.jp/",
  },
  {
    value: "3876",
    label: "Beppu University",
    website: "http://www.beppu-u.ac.jp/",
  },
  {
    value: "3877",
    label: "Bukkyo University",
    website: "http://www.bukkyo-u.ac.jp/",
  },
  {
    value: "3878",
    label: "Bunka Women's University",
    website: "http://www.bunka-wu.ac.jp/",
  },
  {
    value: "3879",
    label: "Bunkyo University",
    website: "http://www.bunkyo.ac.jp/",
  },
  {
    value: "3880",
    label: "Chiba Institute of Technology",
    website: "http://www.it-chiba.ac.jp/",
  },
  {
    value: "3881",
    label: "Chiba Keizai University",
    website: "http://www.cku.ac.jp/",
  },
  {
    value: "3882",
    label: "Chiba University",
    website: "http://www.chiba-u.ac.jp/",
  },
  {
    value: "3883",
    label: "Chiba University of Commerce",
    website: "http://www.cuc.ac.jp/",
  },
  {
    value: "3884",
    label: "Chikushi Jogakuen University",
    website: "http://www.chikushi.ac.jp/",
  },
  {
    value: "3885",
    label: "Chubu Gakuin University & Chubu Women's College",
    website: "http://www.chubu-gu.ac.jp/",
  },
  {
    value: "3886",
    label: "Chubu University",
    website: "http://www.chubu.ac.jp/",
  },
  {
    value: "3887",
    label: "Chukyo Gakuin University",
    website: "http://www.chukyogakuin-u.ac.jp/",
  },
  {
    value: "3888",
    label: "Chukyo University",
    website: "http://www.chukyo-u.ac.jp/",
  },
  {
    value: "3889",
    label: "Chukyo Women's University",
    website: "http://www.chujo-u.ac.jp/",
  },
  {
    value: "3890",
    label: "Chuo Gakuin University",
    website: "http://www.cgu.ac.jp/",
  },
  {
    value: "3891",
    label: "Chuo University",
    website: "http://www.chuo-u.ac.jp/",
  },
  {
    value: "3892",
    label: "Daido Institute of Technology",
    website: "http://www.daido-it.ac.jp/",
  },
  {
    value: "3893",
    label: "Daiichi College of Pharmaceutical Sciences",
    website: "http://www.daiichi-cps.ac.jp/",
  },
  {
    value: "3894",
    label: "Dai Ichi University, College of Technology",
    website: "http://www.daiichi-koudai.ac.jp/",
  },
  {
    value: "3895",
    label: "Daiichi University of Economics",
    website: "http://www.daiichi-ue.ac.jp/",
  },
  {
    value: "3896",
    label: "Daito Bunka University",
    website: "http://www.daito.ac.jp/",
  },
  {
    value: "3897",
    label: "Doho University",
    website: "http://www.doho.ac.jp/",
  },
  {
    value: "3898",
    label: "Dohto University",
    website: "http://www.dohto.ac.jp/",
  },
  {
    value: "3899",
    label: "Dokkyo University",
    website: "http://www.dokkyo.ac.jp/",
  },
  {
    value: "3900",
    label: "Dokkyo University School of Medicine",
    website: "http://www.dokkyomed.ac.jp/",
  },
  {
    value: "3901",
    label: "Doshisha University",
    website: "http://www.doshisha.ac.jp/",
  },
  {
    value: "3902",
    label: "Doshisha Women's College of Liberal Arts",
    website: "http://www.dwc.doshisha.ac.jp/",
  },
  {
    value: "3903",
    label: "Edogawa University",
    website: "http://www.edogawa-u.ac.jp/",
  },
  {
    value: "3904",
    label: "Ehime University",
    website: "http://www.ehime-u.ac.jp/",
  },
  {
    value: "3905",
    label: "Eichi University",
    website: "http://www.eichi-u.ac.jp/",
  },
  {
    value: "3906",
    label: "Elisabeth University of Music",
    website: "http://www.eum.ac.jp/",
  },
  {
    value: "3907",
    label: "Ferris University",
    website: "http://www.ferris.ac.jp/",
  },
  {
    value: "3908",
    label: "Fujita Health University",
    website: "http://www.fujita-hu.ac.jp/",
  },
  {
    value: "3909",
    label: "Fuji University",
    website: "http://www.termnet.co.jp/fujidai/",
  },
  {
    value: "3910",
    label: "Fuji Women's College",
    website: "http://www.fujijoshi.ac.jp/",
  },
  {
    value: "3911",
    label: "Fukui Medical School",
    website: "http://www.fukui-med.ac.jp/",
  },
  {
    value: "3912",
    label: "Fukui Prefectural University",
    website: "http://www.fpu.ac.jp/",
  },
  {
    value: "3913",
    label: "Fukui University",
    website: "http://www.fukui-u.ac.jp/",
  },
  {
    value: "3914",
    label: "Fukui University of Technology",
    website: "http://www.fukui-ut.ac.jp/",
  },
  {
    value: "3915",
    label: "Fukuoka Dental College",
    website: "http://www.fdcnet.ac.jp/",
  },
  {
    value: "3916",
    label: "Fukuoka Institute of Technology",
    website: "http://www.fit.ac.jp/",
  },
  {
    value: "3917",
    label: "Fukuoka International University",
    website: "http://www.fukuoka-int-u.ac.jp/",
  },
  {
    value: "3918",
    label: "Fukuoka Prefectural University",
    website: "http://www.fukuoka-pu.ac.jp/",
  },
  {
    value: "3919",
    label: "Fukuoka University",
    website: "http://www.fukuoka-u.ac.jp/",
  },
  {
    value: "3920",
    label: "Fukuoka University of Education",
    website: "http://www.fukuoka-edu.ac.jp/",
  },
  {
    value: "3921",
    label: "Fukuoka Women's University",
    website: "http://www.fwu.ac.jp/",
  },
  {
    value: "3922",
    label: "Fukushima Medical College",
    website: "http://www.fmu.ac.jp/",
  },
  {
    value: "3923",
    label: "Fukushima University",
    website: "http://www.fukushima-u.ac.jp/",
  },
  {
    value: "3924",
    label: "Fukuyama Heisei University",
    website: "http://www.heisei-u.ac.jp/",
  },
  {
    value: "3925",
    label: "Fukuyama University",
    website: "http://www.fukuyama-u.ac.jp/",
  },
  {
    value: "3926",
    label: "Gakushuin University",
    website: "http://www.gakushuin.ac.jp/",
  },
  {
    value: "3927",
    label: "Gifu Keizai University",
    website: "http://www.gifu-keizai.ac.jp/",
  },
  {
    value: "3928",
    label: "Gifu Pharmaceutical University",
    website: "http://www.gifu-pu.ac.jp/",
  },
  {
    value: "3929",
    label: "Gifu Shotoku Gakuen University",
    website: "http://www.shotoku.ac.jp/",
  },
  {
    value: "3930",
    label: "Gifu University",
    website: "http://www.gifu-u.ac.jp/",
  },
  {
    value: "3931",
    label: "Gifu University for Education and Languages",
    website: "http://www.gifu-kyoiku.ac.jp/",
  },
  {
    value: "3932",
    label: "Gifu Women's University",
    website: "http://www.gijodai.ac.jp/",
  },
  {
    value: "3933",
    label: "Graduate University for Advanced Studies",
    website: "http://www.soken.ac.jp/",
  },
  {
    value: "3934",
    label: "Gunma Prefectural Women's University",
    website: "http://www.gpwu.ac.jp/",
  },
  {
    value: "3935",
    label: "Gunma University",
    website: "http://www.gunma-u.ac.jp/",
  },
  {
    value: "3936",
    label: "Hachinohe Institute of Technology",
    website: "http://www.hi-tech.ac.jp/",
  },
  {
    value: "3937",
    label: "Hachinohe University",
    website: "http://www.hachinohe-u.ac.jp/",
  },
  {
    value: "3938",
    label: "Hakodate University",
    website: "http://www.hakodate-u.ac.jp/",
  },
  {
    value: "3939",
    label: "Hakuoh University",
    website: "http://www.hakuoh.ac.jp/",
  },
  {
    value: "3940",
    label: "Hamamatsu University",
    website: "http://www.hamamatsu-u.ac.jp/",
  },
  {
    value: "3941",
    label: "Hamamatsu University School of Medicine",
    website: "http://www.hama-med.ac.jp/",
  },
  {
    value: "3942",
    label: "Hanazono University",
    website: "http://www.hanazono.ac.jp/",
  },
  {
    value: "3943",
    label: "Hannan University",
    website: "http://www.hannan-u.ac.jp/",
  },
  {
    value: "3944",
    label: "Heisei International University",
    website: "http://www.hiu.ac.jp/",
  },
  {
    value: "3945",
    label: "Higashi Nippon International University",
    website: "http://www.tonichi-kokusai-u.ac.jp/",
  },
  {
    value: "3946",
    label: "Hijiyama University",
    website: "http://www.hijiyama-u.ac.jp/",
  },
  {
    value: "3947",
    label: "Himeji Dokkyo University",
    website: "http://www.himeji-du.ac.jp/",
  },
  {
    value: "3948",
    label: "Himeji Institute of Technology",
    website: "http://www.himeji-tech.ac.jp/",
  },
  {
    value: "3949",
    label: "Hirosaki Gakuin University",
    website: "http://www.hirogaku-u.ac.jp/",
  },
  {
    value: "3950",
    label: "Hirosaki University",
    website: "http://www.hirosaki-u.ac.jp/",
  },
  {
    value: "3951",
    label: "Hiroshima Bunkyo Women's University",
    website: "http://www.h-bunkyo.ac.jp/",
  },
  {
    value: "3952",
    label: "Hiroshima City University",
    website: "http://www.hiroshima-cu.ac.jp/",
  },
  {
    value: "3953",
    label: "Hiroshima Institute of Technology",
    website: "http://www.it-hiroshima.ac.jp/",
  },
  {
    value: "3954",
    label: "Hiroshima International University",
    website: "http://www.hirokoku-u.ac.jp/",
  },
  {
    value: "3955",
    label: "Hiroshima Jogakuin University",
    website: "http://www.hju.ac.jp/",
  },
  {
    value: "3956",
    label: "Hiroshima Kokusai Gakuin University",
    website: "http://www.hkg.ac.jp/",
  },
  {
    value: "3957",
    label: "Hiroshima Prefectural University",
    website: "http://www.hiroshima-pu.ac.jp/",
  },
  {
    value: "3958",
    label: "Hiroshima Shudo University",
    website: "http://www.shudo-u.ac.jp/",
  },
  {
    value: "3959",
    label: "Hiroshima University",
    website: "http://www.hiroshima-u.ac.jp/",
  },
  {
    value: "3960",
    label: "Hiroshima University of Economics",
    website: "http://www.hue.ac.jp/",
  },
  {
    value: "3961",
    label: "Hiroshima Women's University",
    website: "http://www.hirojo-u.ac.jp/",
  },
  {
    value: "3962",
    label: "Hitotsubashi University",
    website: "http://www.hit-u.ac.jp/",
  },
  {
    value: "3963",
    label: "Hokkaido Information University",
    website: "http://www.do-johodai.ac.jp/",
  },
  {
    value: "3964",
    label: "Hokkaido Institute of Pharmaceutical Sciences",
    website: "http://www.hokuyakudai.ac.jp/",
  },
  {
    value: "3965",
    label: "Hokkaido Institute of Technology",
    website: "http://www.hit.ac.jp/",
  },
  {
    value: "3966",
    label: "Hokkaido Tokai University",
    website: "http://www.u-tokai.ac.jp/",
  },
  {
    value: "3967",
    label: "Hokkaido University",
    website: "http://www.hokudai.ac.jp/",
  },
  {
    value: "3968",
    label: "Hokkaido University of Education",
    website: "http://www.hokkyodai.ac.jp/",
  },
  {
    value: "3969",
    label: "Hokkaido University of Health Sciences",
    website: "http://www.hoku-iryo-u.ac.jp/",
  },
  {
    value: "3970",
    label: "Hokkaigakuen University",
    website: "http://www.hokkai-s-u.ac.jp/",
  },
  {
    value: "3971",
    label: "Hokkaigakuen University of Kitami",
    website: "http://www.hokkai-k-u.ac.jp/",
  },
  {
    value: "3972",
    label: "Hokuriku University",
    website: "http://www.hokuriku-u.ac.jp/",
  },
  {
    value: "3973",
    label: "Hokusei Gakuen University",
    website: "http://www.hokusei.ac.jp/",
  },
  {
    value: "3974",
    label: "Hosei University",
    website: "http://www.hosei.ac.jp/",
  },
  {
    value: "3975",
    label: "Hoshi University",
    website: "http://www.hoshi.ac.jp/",
  },
  {
    value: "3976",
    label: "Hyogo College of Medicine",
    website: "http://www.hyo-med.ac.jp/",
  },
  {
    value: "3977",
    label: "Hyogo University",
    website: "http://www.hyogo-dai.ac.jp/",
  },
  {
    value: "3978",
    label: "Hyogo University of Education",
    website: "http://www.hyogo-u.ac.jp/",
  },
  {
    value: "3979",
    label: "Ibaraki Christian College",
    website: "http://www.icc.ac.jp/",
  },
  {
    value: "3980",
    label: "Ibaraki Prefectural University of Health Sciences",
    website: "http://www.ipu.ac.jp/",
  },
  {
    value: "3981",
    label: "Ibaraki University",
    website: "http://www.ibaraki.ac.jp/",
  },
  {
    value: "3982",
    label: "International Buddhist University",
    website: "http://www.shitennoji.ac.jp/",
  },
  {
    value: "3983",
    label: "International Budo University",
    website: "http://www.budo-u.ac.jp/",
  },
  {
    value: "3984",
    label: "International Christian University",
    website: "http://www.icu.ac.jp/",
  },
  {
    value: "3985",
    label: "International University of Health and Welfare",
    website: "http://www.iuhw.ac.jp/",
  },
  {
    value: "3986",
    label: "International University of Japan",
    website: "http://www.iuj.ac.jp/",
  },
  {
    value: "3987",
    label: "International University of Kagoshima",
    website: "http://www.iuk.ac.jp/",
  },
  {
    value: "3988",
    label: "Ishinomaki Senshu University",
    website: "http://www.isenshu-u.ac.jp/",
  },
  {
    value: "3989",
    label: "Iwaki Meisei University",
    website: "http://www.iwakimu.ac.jp/",
  },
  {
    value: "3990",
    label: "Iwate Medical University",
    website: "http://www.iwate-med.ac.jp/",
  },
  {
    value: "3991",
    label: "Iwate Prefectural University",
    website: "http://www.iwate-pu.ac.jp/",
  },
  {
    value: "3992",
    label: "Iwate University",
    website: "http://www.iwate-u.ac.jp/",
  },
  {
    value: "3993",
    label: "Japan Advanced Institute of Science and Technology",
    website: "http://www.jaist.ac.jp/",
  },
  {
    value: "3994",
    label: "Japan College of Social Work",
    website: "http://www.shakaijigyo.ac.jp/",
  },
  {
    value: "3995",
    label: "Japanese Red Cross College of Nursing",
    website: "http://www.redcross.ac.jp/",
  },
  {
    value: "3996",
    label: "Japan Women's University",
    website: "http://www.jwu.ac.jp/",
  },
  {
    value: "3997",
    label: "Jichi Medical School",
    website: "http://www.jichi.ac.jp/",
  },
  {
    value: "3998",
    label: "Jikei University School of Medicine",
    website: "http://www.jikei.ac.jp/",
  },
  {
    value: "3999",
    label: "Jissen Women's University",
    website: "http://www.jissen.ac.jp/",
  },
  {
    value: "4000",
    label: "Jobu University",
    website: "http://www.jobu.ac.jp/",
  },
  {
    value: "4001",
    label: "Joetsu University of Education",
    website: "http://www.juen.ac.jp/",
  },
  {
    value: "4002",
    label: "Josai International University",
    website: "http://www.jiu.ac.jp/",
  },
  {
    value: "4003",
    label: "Josai University",
    website: "http://www.josai.ac.jp/",
  },
  {
    value: "4004",
    label: "Juntendo University",
    website: "http://www.juntendo.ac.jp/",
  },
  {
    value: "4005",
    label: "Kagawa Institute of Nutrition",
    website: "http://www.eiyo.ac.jp/",
  },
  {
    value: "4006",
    label: "Kagawa Medical School",
    website: "http://www.kms.ac.jp/",
  },
  {
    value: "4007",
    label: "Kagawa University",
    website: "http://www.kagawa-u.ac.jp/",
  },
  {
    value: "4008",
    label: "Kagoshima Immaculate Heart University",
    website: "http://www.juntan.k-junshin.ac.jp/jundai/",
  },
  {
    value: "4009",
    label: "Kagoshima University",
    website: "http://www.kagoshima-u.ac.jp/",
  },
  {
    value: "4010",
    label: "Kagoshima Women's College",
    website: "http://www.kwc-u.ac.jp/",
  },
  {
    value: "4011",
    label: "Kamakura Women's College",
    website: "http://www.kamakura-u.ac.jp/",
  },
  {
    value: "4012",
    label: "Kanagawa Dental College",
    website: "http://www.kdcnet.ac.jp/",
  },
  {
    value: "4013",
    label: "Kanagawa Institute of Technology",
    website: "http://www.kanagawa-it.ac.jp/",
  },
  {
    value: "4014",
    label: "Kanagawa University",
    website: "http://www.kanagawa-u.ac.jp/",
  },
  {
    value: "4015",
    label: "Kanazawa College of Art",
    website: "http://www.kanazawa-bidai.ac.jp/",
  },
  {
    value: "4016",
    label: "Kanazawa College of Economics",
    website: "http://www.kanazawa-eco.ac.jp/",
  },
  {
    value: "4017",
    label: "Kanazawa Gakuin University",
    website: "http://www.kanazawa-gu.ac.jp/",
  },
  {
    value: "4018",
    label: "Kanazawa Institute of Technology",
    website: "http://www.kanazawa-it.ac.jp/",
  },
  {
    value: "4019",
    label: "Kanazawa Medical University",
    website: "http://www.kanazawa-med.ac.jp/",
  },
  {
    value: "4020",
    label: "Kanazawa University",
    website: "http://www.kanazawa-u.ac.jp/",
  },
  {
    value: "4021",
    label: "Kanda University of International Studies",
    website: "http://www.kuis.ac.jp/",
  },
  {
    value: "4022",
    label: "Kansai Gaidai University",
    website: "http://www.kansai-gaidai-u.ac.jp/",
  },
  {
    value: "4023",
    label: "Kansai Medical University",
    website: "http://www.kmu.ac.jp/",
  },
  {
    value: "4024",
    label: "Kansai University",
    website: "http://www.kansai-u.ac.jp/",
  },
  {
    value: "4025",
    label: "Kansai University of International Studies",
    website: "http://www.kuins.ac.jp/",
  },
  {
    value: "4026",
    label: "Kansai University of Social Welfare",
    website: "http://www.kusw.ac.jp/",
  },
  {
    value: "4027",
    label: "Kanto Gakuen University",
    website: "http://www.kanto-gakuen.ac.jp/",
  },
  {
    value: "4028",
    label: "Kanto Gakuin University",
    website: "http://www.kanto-gakuin.ac.jp/",
  },
  {
    value: "4029",
    label: "Kawamura Gakuen Woman's University",
    website: "http://www.kgwu.ac.jp/",
  },
  {
    value: "4030",
    label: "Kawasaki Medical School",
    website: "http://www.kawasaki-m.ac.jp/",
  },
  {
    value: "4031",
    label: "Kawasaki University of Medical Care",
    website: "http://www.kawasaki-m.ac.jp/mw/",
  },
  {
    value: "4032",
    label: "Keiai University",
    website: "http://www.u-keiai.ac.jp/",
  },
  {
    value: "4033",
    label: "Keio University",
    website: "http://www.keio.ac.jp/",
  },
  {
    value: "4034",
    label: "Keisen Jogaku-en College",
    website: "http://www.keisen.ac.jp/",
  },
  {
    value: "4035",
    label: "Keiwa College",
    website: "http://www.keiwa-c.ac.jp/",
  },
  {
    value: "4036",
    label: "Kibi International University",
    website: "http://www.kiui.ac.jp/",
  },
  {
    value: "4037",
    label: "Kinjo Gakuin University",
    website: "http://www.kinjo-u.ac.jp/",
  },
  {
    value: "4038",
    label: "Kinki University",
    website: "http://www.kindai.ac.jp/",
  },
  {
    value: "4039",
    label: "Kitakyushu University",
    website: "http://www.kitakyu-u.ac.jp/",
  },
  {
    value: "4040",
    label: "Kitami Institute of Technology",
    website: "http://www.kitami-it.ac.jp/",
  },
  {
    value: "4041",
    label: "Kitasato University",
    website: "http://www.kitasato-u.ac.jp/",
  },
  {
    value: "4042",
    label: "Kobe City University of ForeignStudies",
    website: "http://www.kobe-cufs.ac.jp/",
  },
  {
    value: "4043",
    label: "Kobe Design University",
    website: "http://www.kobe-du.ac.jp/",
  },
  {
    value: "4044",
    label: "Kobe Gakuin University",
    website: "http://www.kobegakuin.ac.jp/",
  },
  {
    value: "4045",
    label: "Kobe International University",
    website: "http://www.kobe-kiu.ac.jp/",
  },
  {
    value: "4046",
    label: "Kobe Jogakuin University",
    website: "http://www.kobe-c.ac.jp/",
  },
  {
    value: "4047",
    label: "Kobe Pharmaceutical University",
    website: "http://www.kobepharma-u.ac.jp/",
  },
  {
    value: "4048",
    label: "Kobe Shinwa Women's University",
    website: "http://www.kobe-shinwa.ac.jp/",
  },
  {
    value: "4049",
    label: "Kobe Shoin Women's University",
    website: "http://www.shoin.ac.jp/",
  },
  {
    value: "4050",
    label: "Kobe University",
    website: "http://www.kobe-u.ac.jp/",
  },
  {
    value: "4051",
    label: "Kobe University of Mercantile Marine",
    website: "http://www.kshosen.ac.jp/",
  },
  {
    value: "4052",
    label: "Kobe Women's University",
    website: "http://www.suma.kobe-wu.ac.jp/",
  },
  {
    value: "4053",
    label: "Kochi Medical School",
    website: "http://www.kochi-ms.ac.jp/",
  },
  {
    value: "4054",
    label: "Kochi University",
    website: "http://www.kochi-u.ac.jp/",
  },
  {
    value: "4055",
    label: "Kochi University of Technology",
    website: "http://www.kochi-tech.ac.jp/",
  },
  {
    value: "4056",
    label: "Kochi Women's University",
    website: "http://www.kochi-wu.ac.jp/",
  },
  {
    value: "4057",
    label: "Kogakkan University",
    website: "http://www.kogakkan-u.ac.jp/",
  },
  {
    value: "4058",
    label: "Kogakuin University",
    website: "http://www.kogakuin.ac.jp/",
  },
  {
    value: "4059",
    label: "Koka Women's College",
    website: "http://www.koka.ac.jp/",
  },
  {
    value: "4060",
    label: "Kokugakuin University",
    website: "http://www.kokugakuin.ac.jp/",
  },
  {
    value: "4061",
    label: "Kokushikan University",
    website: "http://www.kokushikan.ac.jp/",
  },
  {
    value: "4062",
    label: "Komazawa University",
    website: "http://www.komazawa-u.ac.jp/",
  },
  {
    value: "4063",
    label: "Konan University",
    website: "http://www.konan-u.ac.jp/",
  },
  {
    value: "4064",
    label: "Konan Women's University",
    website: "http://www.konan-wu.ac.jp/",
  },
  {
    value: "4065",
    label: "Korea University",
    website: "http://www.korea-u.ac.jp/",
  },
  {
    value: "4066",
    label: "Koriyama Women's University and College",
    website: "http://www.koriyama-kgc.ac.jp/",
  },
  {
    value: "4067",
    label: "Koshien University",
    website: "http://www.koshien.ac.jp/",
  },
  {
    value: "4068",
    label: "Koyasan University",
    website: "http://www.koyasan-u.ac.jp/",
  },
  {
    value: "4069",
    label: "Kumamoto Gakuen University",
    website: "http://www.kumagaku.ac.jp/",
  },
  {
    value: "4070",
    label: "Kumamoto Institute of Technology",
    website: "http://www.knct.ac.jp/",
  },
  {
    value: "4071",
    label: "Kumamoto Prefectural University",
    website: "http://www.pu-kumamoto.ac.jp/",
  },
  {
    value: "4072",
    label: "Kumamoto University",
    website: "http://www.kumamoto-u.ac.jp/",
  },
  {
    value: "4073",
    label: "Kunitachi College of Music",
    website: "http://www.kunitachi.ac.jp/",
  },
  {
    value: "4074",
    label: "Kurashiki Sakuyo University",
    website: "http://www.ksu.ac.jp/",
  },
  {
    value: "4075",
    label: "Kurashiki University of Science and the Arts",
    website: "http://www.kusa.ac.jp/",
  },
  {
    value: "4076",
    label: "Kure University",
    website: "http://www.kure-u.ac.jp/",
  },
  {
    value: "4077",
    label: "Kurume Institute of Technology",
    website: "http://www.kurume-it.ac.jp/",
  },
  {
    value: "4078",
    label: "Kurume University",
    website: "http://www.kurume-u.ac.jp/",
  },
  {
    value: "4079",
    label: "Kushiro Public University of Economics",
    website: "http://www.kushiro-pu.ac.jp/",
  },
  {
    value: "4080",
    label: "Kwansei Gakuin University",
    website: "http://www.kwansei.ac.jp/",
  },
  {
    value: "4081",
    label: "Kwassui Women's College",
    website: "http://www.kwassui.ac.jp/",
  },
  {
    value: "4082",
    label: "Kyorin University",
    website: "http://www.kyorin-u.ac.jp/",
  },
  {
    value: "4083",
    label: "Kyoritsu Pharmaceutical University",
    website: "http://www.kyoritsu-ph.ac.jp/",
  },
  {
    value: "4084",
    label: "Kyoritsu Woman's University",
    website: "http://www.kyoritsu-wu.ac.jp/",
  },
  {
    value: "4085",
    label: "Kyoto Bunkyo University",
    website: "http://www.kbu.ac.jp/",
  },
  {
    value: "4086",
    label: "Kyoto City University of Arts",
    website: "http://www.kcua.ac.jp/",
  },
  {
    value: "4087",
    label: "Kyoto Gakuen University",
    website: "http://www.kyotogakuen-u.ac.jp/",
  },
  {
    value: "4088",
    label: "Kyoto Institute of Technology",
    website: "http://www.kit.ac.jp/",
  },
  {
    value: "4089",
    label: "Kyoto Pharmaceutical University",
    website: "http://www.kyoto-phu.ac.jp/",
  },
  {
    value: "4090",
    label: "Kyoto Prefectural University",
    website: "http://www.kpu.ac.jp/",
  },
  {
    value: "4091",
    label: "Kyoto Prefectural University of Medicine",
    website: "http://www.kpu-m.ac.jp/",
  },
  {
    value: "4092",
    label: "Kyoto Sangyo University",
    website: "http://www.kyoto-su.ac.jp/",
  },
  {
    value: "4093",
    label: "Kyoto Seika University",
    website: "http://www.kyoto-seika.ac.jp/",
  },
  {
    value: "4094",
    label: "Kyoto Tachibana Women's University",
    website: "http://www.tachibana-u.ac.jp/",
  },
  {
    value: "4095",
    label: "Kyoto University",
    website: "http://www.kyoto-u.ac.jp/",
  },
  {
    value: "4096",
    label: "Kyoto University of Art and Design",
    website: "http://www.kyoto-art.ac.jp/",
  },
  {
    value: "4097",
    label: "Kyoto University of Education",
    website: "http://www.kyokyo-u.ac.jp/",
  },
  {
    value: "4098",
    label: "Kyoto University of Foreign Studies",
    website: "http://www.kufs.ac.jp/",
  },
  {
    value: "4099",
    label: "Kyoto Women's University",
    website: "http://www.kyoto-wu.ac.jp/",
  },
  {
    value: "4100",
    label: "Kyushu Dental College",
    website: "http://www.kyu-dent.ac.jp/",
  },
  {
    value: "4101",
    label: "Kyushu Institute of Design",
    website: "http://www.kyushu-id.ac.jp/",
  },
  {
    value: "4102",
    label: "Kyushu Institute of Technology",
    website: "http://www.kyutech.ac.jp/",
  },
  {
    value: "4103",
    label: "Kyushu International University",
    website: "http://www.kiu.ac.jp/",
  },
  {
    value: "4104",
    label: "Kyushu Kyoritsu University",
    website: "http://www.kyukyo-u.ac.jp/",
  },
  {
    value: "4105",
    label: "Kyushu Sangyo University",
    website: "http://www.ip.kyusan-u.ac.jp/",
  },
  {
    value: "4106",
    label: "Kyushu Tokai University",
    website: "http://www.ktokai-u.ac.jp/",
  },
  {
    value: "4107",
    label: "Kyushu University",
    website: "http://www.kyushu-u.ac.jp/",
  },
  {
    value: "4108",
    label: "Kyushu University of Nursing and SocialWelfare",
    website: "http://www.kyushu-ns.ac.jp/",
  },
  {
    value: "4109",
    label: "Kyushu Women's University",
    website: "http://www.kwuc.ac.jp/",
  },
  {
    value: "4110",
    label: "Matsumoto Dental University",
    website: "http://www.mdu.ac.jp/",
  },
  {
    value: "4111",
    label: "Matsusaka University",
    website: "http://www.mie-chukyo-u.ac.jp/",
  },
  {
    value: "4112",
    label: "Matsuyama University",
    website: "http://www.matsuyama-u.ac.jp/",
  },
  {
    value: "4113",
    label: "Meiji College of Pharmacy",
    website: "http://www.my-pharm.ac.jp/",
  },
  {
    value: "4114",
    label: "Meiji Gakuin University",
    website: "http://www.meijigakuin.ac.jp/",
  },
  {
    value: "4115",
    label: "Meiji University",
    website: "http://www.meiji.ac.jp/",
  },
  {
    value: "4116",
    label: "Meiji University of Oriental Medicine",
    website: "http://www.meiji-u.ac.jp/",
  },
  {
    value: "4117",
    label: "Meijo University",
    website: "http://www.meijo-u.ac.jp/",
  },
  {
    value: "4118",
    label: "Meikai University",
    website: "http://www.meikai.ac.jp/",
  },
  {
    value: "4119",
    label: "Meio University",
    website: "http://www.meio-u.ac.jp/",
  },
  {
    value: "4120",
    label: "Meisei University",
    website: "http://www.meisei-u.ac.jp/",
  },
  {
    value: "4121",
    label: "Mejiro University",
    website: "http://www.mejiro.ac.jp/",
  },
  {
    value: "4122",
    label: "Mie University",
    website: "http://www.mie-u.ac.jp/",
  },
  {
    value: "4123",
    label: "Mimasaka Women's College",
    website: "http://www.mimasaka.ac.jp/",
  },
  {
    value: "4124",
    label: "Minamikyushu University",
    website: "http://www.nankyudai.ac.jp/",
  },
  {
    value: "4125",
    label: "Miyagi Gakuin Women's College",
    website: "http://www.mgu.ac.jp/",
  },
  {
    value: "4126",
    label: "Miyagi University",
    website: "http://www.myu.ac.jp/",
  },
  {
    value: "4127",
    label: "Miyagi University of Education",
    website: "http://www.miyakyo-u.ac.jp/",
  },
  {
    value: "4128",
    label: "Miyazaki Medical College",
    website: "http://www.miyazaki-med.ac.jp/",
  },
  {
    value: "4129",
    label: "Miyazaki Municipal University",
    website: "http://www.miyazaki-mu.ac.jp/",
  },
  {
    value: "4130",
    label: "Miyazaki Prefectural Nursing University",
    website: "http://www.mpu.ac.jp/",
  },
  {
    value: "4131",
    label: "Miyazaki University",
    website: "http://www.miyazaki-u.ac.jp/",
  },
  {
    value: "4132",
    label: "Morioka College",
    website: "http://www.morioka-u.ac.jp/",
  },
  {
    value: "4133",
    label: "Mukogawa Women's University",
    website: "http://www.mukogawa-u.ac.jp/",
  },
  {
    value: "4134",
    label: "Muroran Institute of Technology",
    website: "http://www.muroran-it.ac.jp/",
  },
  {
    value: "4135",
    label: "Musashi Institute of Technology",
    website: "http://www.musashi-tech.ac.jp/",
  },
  {
    value: "4136",
    label: "Musashino Academy of Music",
    website: "http://www.musashino-music.ac.jp/",
  },
  {
    value: "4137",
    label: "Musashino Art University",
    website: "http://www.musabi.ac.jp/",
  },
  {
    value: "4138",
    label: "Musashino Women's University",
    website: "http://www.musashino-wu.ac.jp/",
  },
  {
    value: "4139",
    label: "Musashi University",
    website: "http://www.musashi.ac.jp/",
  },
  {
    value: "4140",
    label: "Nagano University",
    website: "http://www.nagano.ac.jp/",
  },
  {
    value: "4141",
    label: "Nagaoka University of Technology",
    website: "http://www.nagaokaut.ac.jp/",
  },
  {
    value: "4142",
    label: "Nagasaki Institute of Applied Science",
    website: "http://www.nias.ac.jp/",
  },
  {
    value: "4143",
    label: "Nagasaki Prefectural University",
    website: "http://www.nagasakipu.ac.jp/",
  },
  {
    value: "4144",
    label: "Nagasaki University",
    website: "http://www.nagasaki-u.ac.jp/",
  },
  {
    value: "4145",
    label: "Nagoya City University",
    website: "http://www.nagoya-cu.ac.jp/",
  },
  {
    value: "4146",
    label: "Nagoya College of Music",
    website: "http://www.doho.ac.jp/~meion/",
  },
  {
    value: "4147",
    label: "Nagoya Economics University",
    website: "http://www.nagoya-ku.ac.jp/",
  },
  {
    value: "4148",
    label: "Nagoya Gakuin University",
    website: "http://www.ngu.ac.jp/",
  },
  {
    value: "4149",
    label: "Nagoya Institute of Technology",
    website: "http://www.nitech.ac.jp/",
  },
  {
    value: "4150",
    label: "Nagoya University",
    website: "http://www.nagoya-u.ac.jp/",
  },
  {
    value: "4151",
    label: "Nagoya University of Arts",
    website: "http://www.nua.ac.jp/",
  },
  {
    value: "4152",
    label: "Nagoya University of Commerce and Business Administration",
    website: "http://www.nucba.ac.jp/",
  },
  {
    value: "4153",
    label: "Nagoya University of Foreign Studies",
    website: "http://www.nufs.nakanishi.ac.jp/",
  },
  {
    value: "4154",
    label: "Nagoya Women's University",
    website: "http://www.nagoya-wu.ac.jp/",
  },
  {
    value: "4155",
    label: "Nakamura Gakuen University",
    website: "http://www.nakamura-u.ac.jp/",
  },
  {
    value: "4156",
    label: "Nanzan University",
    website: "http://www.nanzan-u.ac.jp/",
  },
  {
    value: "4157",
    label: "Nara Medical University",
    website: "http://www.naramed-u.ac.jp/",
  },
  {
    value: "4158",
    label: "Nara Sangyo University",
    website: "http://www.nara-su.ac.jp/",
  },
  {
    value: "4159",
    label: "Nara University",
    website: "http://www.nara-u.ac.jp/",
  },
  {
    value: "4160",
    label: "Nara University of Commerce",
    website: "http://www.narauc.ac.jp/",
  },
  {
    value: "4161",
    label: "Nara University of Education",
    website: "http://www.nara-edu.ac.jp/",
  },
  {
    value: "4162",
    label: "Nara Women's University",
    website: "http://www.nara-wu.ac.jp/",
  },
  {
    value: "4163",
    label: "Naruto University of Education",
    website: "http://www.naruto-u.ac.jp/",
  },
  {
    value: "4164",
    label: "National Defence Medical College",
    website: "http://www.ndmc.ac.jp/",
  },
  {
    value: "4165",
    label: "National Fisheries University",
    website: "http://www.fish-u.ac.jp/",
  },
  {
    value: "4166",
    label: "National Institute of Fitness and Sports Kanoya",
    website: "http://www.nifs-k.ac.jp/",
  },
  {
    value: "4167",
    label: "Nihon Fukushi University",
    website: "http://www.n-fukushi.ac.jp/",
  },
  {
    value: "4168",
    label: "Nihon University",
    website: "http://www.nihon-u.ac.jp/",
  },
  {
    value: "4169",
    label: "Niigata College of Pharmacy",
    website: "http://www.niigata-pharm.ac.jp/",
  },
  {
    value: "4170",
    label: "Niigata Sangyo University",
    website: "http://www.nsu.ac.jp/",
  },
  {
    value: "4171",
    label: "Niigata University",
    website: "http://www.niigata-u.ac.jp/",
  },
  {
    value: "4172",
    label: "Niigata University of International and Information Studies",
    website: "http://www.nuis.ac.jp/",
  },
  {
    value: "4173",
    label: "Niigata University of Management",
    website: "http://www.niigataum.ac.jp/",
  },
  {
    value: "4174",
    label: "Nippon Bunri University",
    website: "http://www.nbu.ac.jp/",
  },
  {
    value: "4175",
    label: "Nippon Dental University",
    website: "http://www.ndu.ac.jp/",
  },
  {
    value: "4176",
    label: "Nippon Institute of Technology",
    website: "http://www.nit.ac.jp/",
  },
  {
    value: "4177",
    label: "Nippon Medical School",
    website: "http://www.nms.ac.jp/",
  },
  {
    value: "4178",
    label: "Nippon Sport Science University",
    website: "http://www.nittai.ac.jp/",
  },
  {
    value: "4179",
    label: "Nippon Veterinary and Animalscience University",
    website: "http://www.nvau.ac.jp/",
  },
  {
    value: "4180",
    label: "Nishikyushu University",
    website: "http://www.nisikyu-u.ac.jp/",
  },
  {
    value: "4181",
    label: "Nishinippon Institute of Technology",
    website: "http://www.nishitech.ac.jp/",
  },
  {
    value: "4182",
    label: "Nisho Gakusha University",
    website: "http://www.nishogakusha-u.ac.jp/",
  },
  {
    value: "4183",
    label: "Nortre Dame Seishin University",
    website: "http://www.ndsu.ac.jp/",
  },
  {
    value: "4184",
    label: "Notre Dame Women's College",
    website: "http://www.notredame.ac.jp/",
  },
  {
    value: "4185",
    label: "Obihiro University of Agriculture and Veterinary Medicine",
    website: "http://www.obihiro.ac.jp/",
  },
  {
    value: "4186",
    label: "Obirin University",
    website: "http://www.obirin.ac.jp/",
  },
  {
    value: "4187",
    label: "Ochanomizu Women's University",
    website: "http://www.ocha.ac.jp/",
  },
  {
    value: "4188",
    label: "Ohka Gakuen University",
    website: "http://www.ohkagakuen-u.ac.jp/",
  },
  {
    value: "4189",
    label: "Ohtani Women's University",
    website: "http://www.ohtani-w.ac.jp/",
  },
  {
    value: "4190",
    label: "Ohu University",
    website: "http://www.ohu-u.ac.jp/",
  },
  {
    value: "4191",
    label: "Oita Medical University",
    website: "http://www.oita-med.ac.jp/",
  },
  {
    value: "4192",
    label: "Oita University",
    website: "http://www.oita-u.ac.jp/",
  },
  {
    value: "4193",
    label: "Oita University of Nursing and Health Sciences",
    website: "http://www.oita-nhs.ac.jp/",
  },
  {
    value: "4194",
    label: "Okayama Prefectural University",
    website: "http://www.oka-pu.ac.jp/",
  },
  {
    value: "4195",
    label: "Okayama Shoka University",
    website: "http://www.osu.ac.jp/",
  },
  {
    value: "4196",
    label: "Okayama University",
    website: "http://www.okayama-u.ac.jp/",
  },
  {
    value: "4197",
    label: "Okayama University of Science",
    website: "http://www.ous.ac.jp/",
  },
  {
    value: "4198",
    label: "Okinawa International University",
    website: "http://www.okiu.ac.jp/",
  },
  {
    value: "4199",
    label: "Okinawa Prefectural University of Fine Arts",
    website: "http://www.okigei.ac.jp/",
  },
  {
    value: "4200",
    label: "Okinawa University",
    website: "http://www.okinawa-u.ac.jp/",
  },
  {
    value: "4201",
    label: "Osaka City University",
    website: "http://www.osaka-cu.ac.jp/",
  },
  {
    value: "4202",
    label: "Osaka College of Music",
    website: "http://www.daion.ac.jp/",
  },
  {
    value: "4203",
    label: "Osaka Dental University",
    website: "http://www.osaka-dent.ac.jp/",
  },
  {
    value: "4204",
    label: "Osaka Electro-Communication University",
    website: "http://www.osakac.ac.jp/",
  },
  {
    value: "4205",
    label: "Osaka Gakuin University",
    website: "http://www.osaka-gu.ac.jp/",
  },
  {
    value: "4206",
    label: "Osaka Institute of Technology",
    website: "http://www.oit.ac.jp/",
  },
  {
    value: "4207",
    label: "Osaka International University",
    website: "http://www.oiu.oiu.ac.jp/",
  },
  {
    value: "4208",
    label: "Osaka International University for Women",
    website: "http://www.oiuw.oiu.ac.jp/",
  },
  {
    value: "4209",
    label: "Osaka Medical College",
    website: "http://www.osaka-med.ac.jp/",
  },
  {
    value: "4210",
    label: "Osaka Prefectural University",
    website: "http://www.osakafu-u.ac.jp/",
  },
  {
    value: "4211",
    label: "Osaka Sangyo University",
    website: "http://www.osaka-sandai.ac.jp/",
  },
  {
    value: "4212",
    label: "Osaka Shoin Women's College",
    website: "http://www.osaka-shoin.ac.jp/",
  },
  {
    value: "4213",
    label: "Osaka University",
    website: "http://www.osaka-u.ac.jp/",
  },
  {
    value: "4214",
    label: "Osaka University of Arts",
    website: "http://www.osaka-geidai.ac.jp/",
  },
  {
    value: "4215",
    label: "Osaka University of Commerce",
    website: "http://www.daishodai.ac.jp/",
  },
  {
    value: "4216",
    label: "Osaka University of Economics",
    website: "http://www.osaka-ue.ac.jp/",
  },
  {
    value: "4217",
    label: "Osaka University of Economics & Law",
    website: "http://www.keiho-u.ac.jp/",
  },
  {
    value: "4218",
    label: "Osaka University of Education",
    website: "http://www.osaka-kyoiku.ac.jp/",
  },
  {
    value: "4219",
    label: "Osaka University of Foreign Studies",
    website: "http://www.osaka-gaidai.ac.jp/",
  },
  {
    value: "4220",
    label: "Osaka University of Health and Sport Sciences",
    website: "http://www.ouhs.ac.jp/",
  },
  {
    value: "4221",
    label: "Osaka University of Pharmaceutical Sciences",
    website: "http://www.oups.ac.jp/",
  },
  {
    value: "4222",
    label: "Osaka Women's University",
    website: "http://www.osaka-wu.ac.jp/",
  },
  {
    value: "4223",
    label: "Otani University",
    website: "http://www.otani.ac.jp/",
  },
  {
    value: "4224",
    label: "Otaru University of Commerce",
    website: "http://www.otaru-uc.ac.jp/",
  },
  {
    value: "4225",
    label: "Otemae University",
    website: "http://www.otemae.ac.jp/",
  },
  {
    value: "4226",
    label: "Otemon Gakuin University",
    website: "http://www.otemon.ac.jp/",
  },
  {
    value: "4227",
    label: "Otsuma Women's University",
    website: "http://www.otsuma.ac.jp/",
  },
  {
    value: "4228",
    label: "Polytechnic University",
    website: "http://www.uitec.ac.jp/",
  },
  {
    value: "4229",
    label: "Poole Gakuin University",
    website: "http://www.poole.ac.jp/",
  },
  {
    value: "4230",
    label: "Rakuno Gakuen University",
    website: "http://www.rakuno.ac.jp/",
  },
  {
    value: "4231",
    label: "Reitaku University",
    website: "http://www.reitaku-u.ac.jp/",
  },
  {
    value: "4232",
    label: "Rikkyo University (St. Paul's University)",
    website: "http://www.rikkyo.ac.jp/",
  },
  {
    value: "4233",
    label: "Rissho University",
    website: "http://www.ris.ac.jp/",
  },
  {
    value: "4234",
    label: "Ritsumeikan Asia Pacific University",
    website: "http://www.apu.ac.jp/",
  },
  {
    value: "4235",
    label: "Ritsumeikan University",
    website: "http://www.ritsumei.ac.jp/",
  },
  {
    value: "4236",
    label: "Ryukoku University",
    website: "http://www.ryukoku.ac.jp/",
  },
  {
    value: "4237",
    label: "Ryutsu Keizai University",
    website: "http://www.rku.ac.jp/",
  },
  {
    value: "4238",
    label: "Saga Medical School",
    website: "http://www.saga-med.ac.jp/",
  },
  {
    value: "4239",
    label: "Sagami Women's University",
    website: "http://www.sagami-wu.ac.jp/",
  },
  {
    value: "4240",
    label: "Saga University",
    website: "http://www.saga-u.ac.jp/",
  },
  {
    value: "4241",
    label: "Saitama Institute of Technology",
    website: "http://www.sit.ac.jp/",
  },
  {
    value: "4242",
    label: "Saitama Medical School",
    website: "http://www.saitama-med.ac.jp/",
  },
  {
    value: "4243",
    label: "Saitama Prefectural University",
    website: "http://www.spu.ac.jp/",
  },
  {
    value: "4244",
    label: "Saitama University",
    website: "http://www.saitama-u.ac.jp/",
  },
  {
    value: "4245",
    label: "Sakushin Gakuin University",
    website: "http://www.sakushin-u.ac.jp/",
  },
  {
    value: "4246",
    label: "Sankei University",
    website: "http://www.miyasankei-u.ac.jp/",
  },
  {
    value: "4247",
    label: "Sanno University",
    website: "http://www.sanno.ac.jp/",
  },
  {
    value: "4248",
    label: "Sanyo Gakuen University",
    website: "http://www.sguc.ac.jp/",
  },
  {
    value: "4249",
    label: "Sapporo Gakuin University",
    website: "http://www.sgu.ac.jp/",
  },
  {
    value: "4250",
    label: "Sapporo International University",
    website: "http://www.siu.ac.jp/",
  },
  {
    value: "4251",
    label: "Sapporo Medical University",
    website: "http://www.sapmed.ac.jp/",
  },
  {
    value: "4252",
    label: "Sapporo University",
    website: "http://www.sapporo-u.ac.jp/",
  },
  {
    value: "4253",
    label: "Science University of Tokyo",
    website: "http://www.sut.ac.jp/",
  },
  {
    value: "4254",
    label: "Science University of Tokyo in Yamaguchi",
    website: "http://www.yama.sut.ac.jp/",
  },
  {
    value: "4255",
    label: "Seian University of Art & Design",
    website: "http://www.seian.ac.jp/",
  },
  {
    value: "4256",
    label: "Seigakuin University",
    website: "http://www.seigakuin-univ.ac.jp/",
  },
  {
    value: "4257",
    label: "Seijo University",
    website: "http://www.seijo.ac.jp/",
  },
  {
    value: "4258",
    label: "Seikei University",
    website: "http://www.seikei.ac.jp/",
  },
  {
    value: "4259",
    label: "Seinan Gakuin University",
    website: "http://www.seinan-gu.ac.jp/",
  },
  {
    value: "4260",
    label: "Seisen University",
    website: "http://www.seisen-u.ac.jp/",
  },
  {
    value: "4261",
    label: "Seiwa College",
    website: "http://www.seiwa-u.ac.jp/",
  },
  {
    value: "4262",
    label: "Sendai University",
    website: "http://www.sendaidaigaku.jp/",
  },
  {
    value: "4263",
    label: "Senshu University",
    website: "http://www.senshu-u.ac.jp/",
  },
  {
    value: "4264",
    label: "Senzoku Gakuen College",
    website: "http://www.senzoku.ac.jp/",
  },
  {
    value: "4265",
    label: "Setsunan University",
    website: "http://www.setsunan.ac.jp/",
  },
  {
    value: "4266",
    label: "Shibaura Institute of Technology",
    website: "http://www.shibaura-it.ac.jp/",
  },
  {
    value: "4267",
    label: "Shiga University",
    website: "http://www.shiga-u.ac.jp/",
  },
  {
    value: "4268",
    label: "Shiga University of Medical Science",
    website: "http://www.shiga-med.ac.jp/",
  },
  {
    value: "4269",
    label: "Shikoku Christian College",
    website: "http://www.sg-u.ac.jp/",
  },
  {
    value: "4270",
    label: "Shikoku University",
    website: "http://www.shikoku-u.ac.jp/",
  },
  {
    value: "4271",
    label: "Shimane University",
    website: "http://www.shimane-u.ac.jp/",
  },
  {
    value: "4272",
    label: "Shimane University, Faculty of Medicine",
    website: "http://www.shimane-med.ac.jp/",
  },
  {
    value: "4273",
    label: "Shimonoseki City University",
    website: "http://www.shimonoseki-cu.ac.jp/",
  },
  {
    value: "4274",
    label: "Shinshu University",
    website: "http://www.shinshu-u.ac.jp/",
  },
  {
    value: "4275",
    label: "Shirayuri Women's College",
    website: "http://www.shirayuri.ac.jp/",
  },
  {
    value: "4276",
    label: "Shizuoka Prefectural University",
    website: "http://www.u-shizuoka-ken.ac.jp/",
  },
  {
    value: "4277",
    label: "Shizuoka Sangyo University",
    website: "http://www.ssu.ac.jp/",
  },
  {
    value: "4278",
    label: "Shizuoka University",
    website: "http://www.shizuoka.ac.jp/",
  },
  {
    value: "4279",
    label: "Shokei College",
    website: "http://www.shokei-gakuen.ac.jp/shokeicol/",
  },
  {
    value: "4280",
    label: "Shonan Institute of Technology",
    website: "http://www.shonan-it.ac.jp/",
  },
  {
    value: "4281",
    label: "Showa College of Pharmaceutical Sciences",
    website: "http://www.shoyaku.ac.jp/",
  },
  {
    value: "4282",
    label: "Showa University",
    website: "http://www.showa-u.ac.jp/",
  },
  {
    value: "4283",
    label: "Showa Women's University",
    website: "http://www.swu.ac.jp/",
  },
  {
    value: "4284",
    label: "Shuchiin College",
    website: "http://www.shuchiin.ac.jp/",
  },
  {
    value: "4285",
    label: "Shujitsu Women's University",
    website: "http://www.shujitsu.ac.jp/",
  },
  {
    value: "4286",
    label: "Shukutoku University",
    website: "http://www.shukutoku.ac.jp/",
  },
  {
    value: "4287",
    label: "Shumei University",
    website: "http://www.shumei-u.ac.jp/",
  },
  {
    value: "4288",
    label: "Siebold University of Nagasaki",
    website: "http://www.sun.ac.jp/",
  },
  {
    value: "4289",
    label: "Soai University",
    website: "http://www.soai.ac.jp/",
  },
  {
    value: "4290",
    label: "Soka University",
    website: "http://www.soka.ac.jp/",
  },
  {
    value: "4291",
    label: "Sonoda Women's University",
    website: "http://www.sonoda-u.ac.jp/",
  },
  {
    value: "4292",
    label: "Sophia University",
    website: "http://www.sophia.ac.jp/",
  },
  {
    value: "4293",
    label: "St. Andrew's University",
    website: "http://www.andrew.ac.jp/",
  },
  {
    value: "4294",
    label: "St. Luke' s College of Nursing",
    website: "http://www.slcn.ac.jp/",
  },
  {
    value: "4295",
    label: "St. Marianna University School of Medicine",
    website: "http://www.marianna-u.ac.jp/",
  },
  {
    value: "4296",
    label: "Sugino Women's College",
    website: "http://www.sugino.ac.jp/daiindex.html",
  },
  {
    value: "4297",
    label: "Sugiyama Jogakuen University",
    website: "http://www.sugiyama-u.ac.jp/",
  },
  {
    value: "4298",
    label: "Surugadai University",
    website: "http://www.surugadai.ac.jp/",
  },
  {
    value: "4299",
    label: "Suzuka International University",
    website: "http://www.suzuka-iu.ac.jp/",
  },
  {
    value: "4300",
    label: "Suzuka University of Medical Science",
    website: "http://www.suzuka-u.ac.jp/",
  },
  {
    value: "4301",
    label: "Taisho University",
    website: "http://www.tais.ac.jp/",
  },
  {
    value: "4302",
    label: "Takachiho University",
    website: "http://www.takachiho.ac.jp/",
  },
  {
    value: "4303",
    label: "Takamatsu University",
    website: "http://www.takamatsu-u.ac.jp/",
  },
  {
    value: "4304",
    label: "Takarazuka University of Art and Design",
    website: "http://www.takara-univ.ac.jp/",
  },
  {
    value: "4305",
    label: "Takasaki City University of Economics",
    website: "http://www.tcue.ac.jp/",
  },
  {
    value: "4306",
    label: "Takushoku University",
    website: "http://www.takushoku-u.ac.jp/",
  },
  {
    value: "4307",
    label: "Tama Art University",
    website: "http://www.tamabi.ac.jp/",
  },
  {
    value: "4308",
    label: "Tamagawa University",
    website: "http://www.tamagawa.ac.jp/",
  },
  {
    value: "4309",
    label: "Tama Institute of Management and Information Sciences",
    website: "http://www.timis.ac.jp/",
  },
  {
    value: "4310",
    label: "Teikyo Heisei University",
    website: "http://www.teikyo-u.ac.jp/",
  },
  {
    value: "4311",
    label: "Teikyo University of Science and Technology",
    website: "http://www.ntu.ac.jp/",
  },
  {
    value: "4312",
    label: "Temple University Japan",
    website: "http://www.tuj.ac.jp/",
  },
  {
    value: "4313",
    label: "Tenri University",
    website: "http://www.tenri-u.ac.jp/",
  },
  {
    value: "4314",
    label: "Tezukayama Gakuin University",
    website: "http://www.tezuka-gu.ac.jp/",
  },
  {
    value: "4315",
    label: "Tezukayama University",
    website: "http://www.tezukayama-u.ac.jp/",
  },
  {
    value: "4316",
    label: "Toho College of Music",
    website: "http://www.toho-music.ac.jp/",
  },
  {
    value: "4317",
    label: "Toho Gakuen School of Music",
    website: "http://www.tohomusic.ac.jp/",
  },
  {
    value: "4318",
    label: "Tohoku Bunka Gakuen University",
    website: "http://www.tbgu.ac.jp/",
  },
  {
    value: "4319",
    label: "Tohoku College of Pharmacy",
    website: "http://www.tohoku-pharm.ac.jp/",
  },
  {
    value: "4320",
    label: "Tohoku Fukushi University",
    website: "http://www.tfu.ac.jp/",
  },
  {
    value: "4321",
    label: "Tohoku Gakuin University",
    website: "http://www.tohoku-gakuin.ac.jp/",
  },
  {
    value: "4322",
    label: "Tohoku Institute of Technology",
    website: "http://www.tohtech.ac.jp",
  },
  {
    value: "4323",
    label: "Tohoku University",
    website: "http://www.tohoku.ac.jp/",
  },
  {
    value: "4324",
    label: "Tohoku University of Art and Design",
    website: "http://www.tuad.ac.jp/",
  },
  {
    value: "4325",
    label: "Tohoku Women's College",
    website: "http://www.tojo.ac.jp/",
  },
  {
    value: "4326",
    label: "Toho University",
    website: "http://www.toho-u.ac.jp/",
  },
  {
    value: "4327",
    label: "Tohwa University",
    website: "http://www.tohwa-u.ac.jp/",
  },
  {
    value: "4328",
    label: "Toin University of Yokohama",
    website: "http://www.toin.ac.jp/",
  },
  {
    value: "4329",
    label: "Tokai Gakuen University",
    website: "http://www.tokaigakuen-u.ac.jp/",
  },
  {
    value: "4330",
    label: "Tokai University",
    website: "http://www.u-tokai.ac.jp/",
  },
  {
    value: "4331",
    label: "Tokai University Educational System",
    website: "http://www.tokai.ac.jp/",
  },
  {
    value: "4332",
    label: "Tokai Women's College",
    website: "http://www.tokaijoshi-u.ac.jp/",
  },
  {
    value: "4333",
    label: "Tokiwa University",
    website: "http://www.tokiwa.ac.jp/",
  },
  {
    value: "4334",
    label: "Tokoha Gakuen University",
    website: "http://www.tokoha-u.ac.jp/",
  },
  {
    value: "4335",
    label: "Tokushima Bunri University",
    website: "http://www.bunri-u.ac.jp/",
  },
  {
    value: "4336",
    label: "Tokushima University",
    website: "http://www.tokushima-u.ac.jp/",
  },
  {
    value: "4337",
    label: "Tokuyama University",
    website: "http://www.tokuyama-u.ac.jp/",
  },
  {
    value: "4338",
    label: "Tokyo College of Music",
    website: "http://www.tokyo-ondai.ac.jp/",
  },
  {
    value: "4339",
    label: "Tokyo Denki University",
    website: "http://www.dendai.ac.jp/",
  },
  {
    value: "4340",
    label: "Tokyo Dental College",
    website: "http://www.tdc.ac.jp/",
  },
  {
    value: "4341",
    label: "Tokyo Engineering University",
    website: "http://www.teu.ac.jp/",
  },
  {
    value: "4342",
    label: "Tokyo Gakugei University",
    website: "http://www.u-gakugei.ac.jp/",
  },
  {
    value: "4343",
    label: "Tokyo Institute of Polytechnics",
    website: "http://www.t-kougei.ac.jp/",
  },
  {
    value: "4344",
    label: "Tokyo Institute of Technology",
    website: "http://www.titech.ac.jp/",
  },
  {
    value: "4345",
    label: "Tokyo International University",
    website: "http://www.tiu.ac.jp/",
  },
  {
    value: "4346",
    label: "Tokyo Kasei University",
    website: "http://www.tokyo-kasei.ac.jp/",
  },
  {
    value: "4347",
    label: "Tokyo Keizai University",
    website: "http://www.tku.ac.jp/",
  },
  {
    value: "4348",
    label: "Tokyo Medical and Dental University",
    website: "http://www.tmd.ac.jp/",
  },
  {
    value: "4349",
    label: "Tokyo Medical College",
    website: "http://www.tokyo-med.ac.jp/",
  },
  {
    value: "4350",
    label: "Tokyo Metropolitan Institute of Technology",
    website: "http://www.tmit.ac.jp/",
  },
  {
    value: "4351",
    label: "Tokyo Metropolitan University",
    website: "http://www.metro-u.ac.jp/",
  },
  {
    value: "4352",
    label: "Tokyo Metropolitan University of Health Sciences",
    website: "http://www.metro-hs.ac.jp/",
  },
  {
    value: "4353",
    label: "Tokyo National University of Fine Arts and Music",
    website: "http://www.geidai.ac.jp/",
  },
  {
    value: "4354",
    label: "Tokyo University",
    website: "http://www.u-tokyo.ac.jp/",
  },
  {
    value: "4355",
    label: "Tokyo University of Agriculture",
    website: "http://www.nodai.ac.jp/",
  },
  {
    value: "4356",
    label: "Tokyo University of Agriculture and Technology",
    website: "http://www.tuat.ac.jp/",
  },
  {
    value: "4357",
    label: "Tokyo University of Art and Design",
    website: "http://www.zokei.ac.jp/",
  },
  {
    value: "4358",
    label: "Tokyo University of Fisheries",
    website: "http://www.tokyo-u-fish.ac.jp/",
  },
  {
    value: "4359",
    label: "Tokyo University of Foreign Studies",
    website: "http://www.tufs.ac.jp/",
  },
  {
    value: "4360",
    label: "Tokyo University of Information Sciences",
    website: "http://www.tuis.ac.jp/",
  },
  {
    value: "4361",
    label: "Tokyo University of Mercantile Marine",
    website: "http://www.tosho-u.ac.jp/",
  },
  {
    value: "4362",
    label: "Tokyo University of Pharmacy and Life Science",
    website: "http://www.toyaku.ac.jp/",
  },
  {
    value: "4363",
    label: "Tokyo Woman's Christian University",
    website: "http://www.twcu.ac.jp/",
  },
  {
    value: "4364",
    label: "Tokyo Women's College of Physical Education",
    website: "http://www.twcpe.ac.jp/",
  },
  {
    value: "4365",
    label: "Tokyo Women's Medial College",
    website: "http://www.twmu.ac.jp/",
  },
  {
    value: "4366",
    label: "Tomakomai Komazawa University",
    website: "http://www.t-komazawa.ac.jp/",
  },
  {
    value: "4367",
    label: "Tottori University",
    website: "http://www.tottori-u.ac.jp/",
  },
  {
    value: "4368",
    label: "Tottori University of Environmental Studies",
    website: "http://www.kankyo-u.ac.jp/",
  },
  {
    value: "4369",
    label: "Toyama Medical and Pharmaceutical University",
    website: "http://www.toyama-mpu.ac.jp/",
  },
  {
    value: "4370",
    label: "Toyama Prefectural University",
    website: "http://www.pu-toyama.ac.jp/",
  },
  {
    value: "4371",
    label: "Toyama University",
    website: "http://www.toyama-u.ac.jp/",
  },
  {
    value: "4372",
    label: "Toyama University of International Studies",
    website: "http://www.tuins.ac.jp/",
  },
  {
    value: "4373",
    label: "Toyo Eiwa Women's University",
    website: "http://www.toyoeiwa.ac.jp/",
  },
  {
    value: "4374",
    label: "Toyo Gakuen University",
    website: "http://www.toyogakuen-u.ac.jp/",
  },
  {
    value: "4375",
    label: "Toyohashi University of Technology",
    website: "http://www.tut.ac.jp/",
  },
  {
    value: "4376",
    label: "Toyota Technological Institute",
    website: "http://www.toyota-ti.ac.jp/",
  },
  {
    value: "4377",
    label: "Toyo University",
    website: "http://www.toyo.ac.jp/",
  },
  {
    value: "4378",
    label: "Tsuda College",
    website: "http://www.tsuda.ac.jp/",
  },
  {
    value: "4379",
    label: "Tsukuba University",
    website: "http://www.tsukuba.ac.jp/",
  },
  {
    value: "4380",
    label: "Tsurumi University",
    website: "http://www.tsurumi-u.ac.jp/",
  },
  {
    value: "4381",
    label: "Tsuru University",
    website: "http://www.tsuru.ac.jp/",
  },
  {
    value: "4382",
    label: "Ueno Gakuen College",
    website: "http://www.uenogakuen.ac.jp/",
  },
  {
    value: "4383",
    label: "United Nations University",
    website: "http://www.unu.edu/",
  },
  {
    value: "4384",
    label: "University of Aizu",
    website: "http://www.u-aizu.ac.jp/",
  },
  {
    value: "4385",
    label: "University of Bunkyo",
    website: "http://www.u-bunkyo.ac.jp/",
  },
  {
    value: "4386",
    label: "University of East Asia",
    website: "http://www.toua-u.ac.jp/",
  },
  {
    value: "4387",
    label: "University of Electro-Communications",
    website: "http://www.uec.ac.jp/",
  },
  {
    value: "4388",
    label: "University of Marketing and Distribution Sciences",
    website: "http://www.umds.ac.jp/",
  },
  {
    value: "4389",
    label: "University of Occupational and Environmental Health, Japan",
    website: "http://www.uoeh-u.ac.jp/",
  },
  {
    value: "4390",
    label: "University of Shiga Prefecture",
    website: "http://www.usp.ac.jp/",
  },
  {
    value: "4391",
    label: "University of the Air",
    website: "http://www.u-air.ac.jp/",
  },
  {
    value: "4392",
    label: "University of the Ryukyus",
    website: "http://www.u-ryukyu.ac.jp/",
  },
  {
    value: "4393",
    label: "University of the Sacred Heart Tokyo",
    website: "http://www.u-sacred-heart.ac.jp/",
  },
  {
    value: "4394",
    label: "Utsunomiya University",
    website: "http://www.utsunomiya-u.ac.jp/",
  },
  {
    value: "4395",
    label: "Wakayama Medical College",
    website: "http://www.wakayama-med.ac.jp/",
  },
  {
    value: "4396",
    label: "Wakayama University",
    website: "http://www.wakayama-u.ac.jp/",
  },
  {
    value: "4397",
    label: "Wakkanai Hokusei Gakuen University",
    website: "http://www.wakhok.ac.jp/",
  },
  {
    value: "4398",
    label: "Wako University",
    website: "http://www.wako.ac.jp/",
  },
  {
    value: "4399",
    label: "Waseda University",
    website: "http://www.waseda.ac.jp/",
  },
  {
    value: "4400",
    label: "Wayo Women's University",
    website: "http://www.wayo.ac.jp/",
  },
  {
    value: "4401",
    label: "Women's College of Fine Arts",
    website: "http://www.joshibi.ac.jp/",
  },
  {
    value: "4402",
    label: "Yamagata University",
    website: "http://www.yamagata-u.ac.jp/",
  },
  {
    value: "4403",
    label: "Yamaguchi Prefectural University",
    website: "http://www.yamaguchi-pu.ac.jp/",
  },
  {
    value: "4404",
    label: "Yamaguchi University",
    website: "http://www.yamaguchi-u.ac.jp/",
  },
  {
    value: "4405",
    label: "Yamanashi Gakuin University",
    website: "http://www.ygu.ac.jp/",
  },
  {
    value: "4406",
    label: "Yamanashi Medical University",
    website: "http://www.yamanashi-med.ac.jp/",
  },
  {
    value: "4407",
    label: "Yamanashi University",
    website: "http://www.yamanashi.ac.jp/",
  },
  {
    value: "4408",
    label: "Yasuda Women's University",
    website: "http://www.yasuda-u.ac.jp/",
  },
  {
    value: "4409",
    label: "Yokkaichi University",
    website: "http://www.yokkaichi-u.ac.jp/",
  },
  {
    value: "4410",
    label: "Yokohama City University",
    website: "http://www.yokohama-cu.ac.jp/",
  },
  {
    value: "4411",
    label: "Yokohama College of Commerce",
    website: "http://www.shodai.ac.jp/",
  },
  {
    value: "4412",
    label: "Yokohama National University",
    website: "http://www.ynu.ac.jp/",
  },
  {
    value: "4413",
    label: "Adventist University of Africa",
    website: "http://www.aua.ac.ke/",
  },
  {
    value: "4414",
    label: "Africa International University",
    website: "http://www.aiu.ac.ke/",
  },
  {
    value: "4415",
    label: "Africa Nazarene University",
    website: "http://www.anu.ac.ke/",
  },
  {
    value: "4416",
    label: "Aga Khan University",
    website: "http://www.aku.edu/",
  },
  {
    value: "4417",
    label: "Catholic University of Eastern Africa",
    website: "http://www.cuea.edu/",
  },
  {
    value: "4418",
    label: "Chuka University",
    website: "http://www.chuka.ac.ke/",
  },
  {
    value: "4419",
    label: "Daystar University",
    website: "http://www.daystar.ac.ke/",
  },
  {
    value: "4420",
    label: " Dedan Kimathi University of Technology",
    website: "http://www.dkut.ac.ke/",
  },
  {
    value: "4421",
    label: "Egerton University",
    website: "http://www.egerton.ac.ke/",
  },
  {
    value: "4422",
    label: "Great Lakes University of Kisumu",
    website: "http://www.gluk.ac.ke/",
  },
  {
    value: "4423",
    label: "Gretsa Universtiy",
    website: "http://www.gretsauniversity.ac.ke/",
  },
  {
    value: "4424",
    label: "Inoorero University",
    website: "http://www.iu.ac.ke/",
  },
  {
    value: "4425",
    label: "Jaramogi Oginga Odinga University of Science and Technology",
    website: "http://www.jooust.ac.ke/",
  },
  {
    value: "4426",
    label: "Jomo Kenyatta University of Agriculture and Technology",
    website: "http://www.jkuat.ac.ke/",
  },
  {
    value: "4427",
    label: "Kabarak University",
    website: "http://www.kabarak.ac.ke/",
  },
  {
    value: "4428",
    label: "Karatina University",
    website: "http://www.karatinauniversity.ac.ke/",
  },
  {
    value: "4429",
    label: "Kenya College of Accountancy",
    website: "http://www.kca.ac.ke/",
  },
  {
    value: "4430",
    label: "Kenya Medical Training College",
    website: "http://www.kmtc.ac.ke/",
  },
  {
    value: "4431",
    label: "Kenya Methodist University",
    website: "http://www.kemu.ac.ke/",
  },
  {
    value: "4432",
    label: "Kenyatta University",
    website: "http://www.ku.ac.ke/",
  },
  {
    value: "4433",
    label: "Kiriri Womens University of Science and Technology",
    website: "http://www.kwust.ac.ke/",
  },
  {
    value: "4434",
    label: "Kisii University",
    website: "http://www.kisiiuniversity.ac.ke/",
  },
  {
    value: "4435",
    label: "Laikipia University",
    website: "http://www.laikipia.ac.ke/",
  },
  {
    value: "4436",
    label: "Maasai Mara University",
    website: "http://www.mmarau.ac.ke/",
  },
  {
    value: "4437",
    label: "Maseno University",
    website: "http://www.maseno.ac.ke/",
  },
  {
    value: "4438",
    label: "Masinde Muliro University of Science and Technology",
    website: "http://www.mmust.ac.ke/",
  },
  {
    value: "4439",
    label: "Meru University of Science and Technology",
    website: "http://www.must.ac.ke/",
  },
  {
    value: "4440",
    label: "Moi University",
    website: "http://www.mu.ac.ke/",
  },
  {
    value: "4441",
    label: "Mount Kenya University",
    website: "http://www.mku.ac.ke/",
  },
  {
    value: "4442",
    label: "Multimedia University of Kenya",
    website: "http://www.mmu.ac.ke/",
  },
  {
    value: "4443",
    label: "Nairobi Institute of Business Studies ",
    website: "http://www.nibs.ac.ke/",
  },
  {
    value: "4444",
    label: "Nairobi Technical Training Institute ",
    website: "http://www.ntti.ac.ke/",
  },
  {
    value: "4445",
    label: "Pan Africa Christian University",
    website: "http://www.pacuniversity.ac.ke/",
  },
  {
    value: "4446",
    label: "Pioneer International University",
    website: "http://www.piu.ac.ke/",
  },
  {
    value: "4447",
    label: "Presbyterian University of East Africa",
    website: "http://www.puea.ac.ke/",
  },
  {
    value: "4448",
    label: "Pwani University",
    website: "http://www.pu.ac.ke/",
  },
  {
    value: "4449",
    label: "Riara University School of Business and Law",
    website: "http://www.riarauniversity.ac.ke/",
  },
  {
    value: "4450",
    label: "Scott Christian University",
    website: "http://www.scott.ac.ke/",
  },
  {
    value: "4451",
    label: "South Eastern Kenya University",
    website: "http://www.seku.ac.ke/",
  },
  {
    value: "4452",
    label: "St. Pauls University",
    website: "http://www.spu.ac.ke/",
  },
  {
    value: "4453",
    label: "Strathmore University",
    website: "http://www.strathmore.edu/",
  },
  {
    value: "4454",
    label: "Technical University of Kenya",
    website: "http://www.tukenya.ac.ke/",
  },
  {
    value: "4455",
    label: "Technical University of Mombasa",
    website: "http://www.tum.ac.ke/",
  },
  {
    value: "4456",
    label: "United States International University",
    website: "http://www.usiu.ac.ke/",
  },
  {
    value: "4457",
    label: "University of Eastern Africa, Baraton",
    website: "http://www.ueab.ac.ke/",
  },
  {
    value: "4458",
    label: "University of Eldoret",
    website: "http://www.uoeld.ac.ke/",
  },
  {
    value: "4459",
    label: "University of Kabianga",
    website: "http://www.kabianga.ac.ke/",
  },
  {
    value: "4460",
    label: "University of Nairobi",
    website: "http://www.uonbi.ac.ke/",
  },
  {
    value: "4461",
    label: "Uzima University College ",
    website: "http://www.uzimauniversity.ac.ke/",
  },
  {
    value: "4462",
    label: "Zetech College",
    website: "http://www.zetechcollege.com/",
  },
  {
    value: "4463",
    label: "American University of Central Asia",
    website: "http://www.auca.kg/",
  },
  {
    value: "4464",
    label: "Asian Medical Institute",
    website: "http://www.asmi.edu.kg/",
  },
  {
    value: "4465",
    label: "Bishkek Humanities University",
    website: "http://www.bhu.kg/",
  },
  {
    value: "4466",
    label: "International Ataturk Alatoo University",
    website: "http://www.iaau.edu.kg/",
  },
  {
    value: "4467",
    label: "International University of Kyrgyzstan",
    website: "http://www.iuk.kg/",
  },
  {
    value: "4468",
    label: "Jalalabad State University Kyrgyzstan",
    website: "http://www.jasu.edu.kg/",
  },
  {
    value: "4469",
    label: "Kyrgyz National Agrarian University",
    website: "http://www.knau.kg/",
  },
  {
    value: "4470",
    label: "Kyrgyz National University",
    website: "http://www.university.kg/",
  },
  {
    value: "4471",
    label: "Kyrgyz Russian Slavic University",
    website: "http://www.krsu.edu.kg/",
  },
  {
    value: "4472",
    label: "Kyrgyz State Technical University",
    website: "http://www.kstu.kg/",
  },
  {
    value: "4473",
    label:
      "Kyrgyz State University of Construction, Transportation and Architecture",
    website: "http://www.ksucta.kg/",
  },
  {
    value: "4474",
    label: "Kyrgyz Turkish  Manas University",
    website: "http://www.manas.kg/",
  },
  {
    value: "4475",
    label: "Medical Faculty, Osh State University",
    website: "http://www.oshmed.com/",
  },
  {
    value: "4476",
    label: "Angkor University",
    website: "http://www.angkor.edu.kh/",
  },
  {
    value: "4477",
    label: "Asia Europe University",
    website: "http://www.aeu.edu.kh/",
  },
  {
    value: "4478",
    label: "Build Bright University",
    website: "http://www.bbu.edu.kh/",
  },
  {
    value: "4479",
    label: "Cambodian Mekong University",
    website: "http://www.mekong.edu.kh/",
  },
  {
    value: "4480",
    label: "Cambodia University of Specialties",
    website: "http://www.cus.edu.kh/",
  },
  {
    value: "4481",
    label: "Chamreun University of Poly Technology",
    website: "http://www.cup.edu.kh/",
  },
  {
    value: "4482",
    label: "International University",
    website: "http://www.iu.edu.kh/",
  },
  {
    value: "4483",
    label: "National Institute of Education",
    website: "http://www.ine.edu.kh/",
  },
  {
    value: "4484",
    label: "National University of Management",
    website: "http://www.num.edu.kh/",
  },
  {
    value: "4485",
    label: "Norton University",
    website: "http://www.norton.edu.kh/",
  },
  {
    value: "4486",
    label: "Pannnasatra University of Cambodia",
    website: "http://www.puc.edu.kh/",
  },
  {
    value: "4487",
    label: "Royal University of Agriculture",
    website: "http://www.rua.edu.kh/",
  },
  {
    value: "4488",
    label: "Royal University of Fine Arts",
    website: "http://www.rufa.edu.kh/",
  },
  {
    value: "4489",
    label: "Royal University of Law and Economics",
    website: "http://www.rule.edu.kh/",
  },
  {
    value: "4490",
    label: "Royal University of Phnom Penh",
    website: "http://www.rupp.edu.kh/",
  },
  {
    value: "4491",
    label: "Svey Reong University",
    website: "http://www.sru.edu.kh/",
  },
  {
    value: "4492",
    label: "University of Cambodia",
    website: "http://www.uc.edu.kh/",
  },
  {
    value: "4493",
    label: "University of Health and Science",
    website: "http://www.univ-sante.edu.kh/",
  },
  {
    value: "4494",
    label: "University of Technology Phnom Penh",
    website: "http://www.utpp.edu.kh/",
  },
  {
    value: "4495",
    label: "Western University",
    website: "http://www.western.edu.kh/",
  },
  {
    value: "4496",
    label: "Zaman University",
    website: "http://www.zamanuniversity.edu.kh/",
  },
  {
    value: "4497",
    label: "International University for Graduate Studies",
    website: "http://www.iugrad.edu.kn/",
  },
  {
    value: "4498",
    label: "Ross University Caribbean School of Veterinary Medicine",
    website: "http://www.rossu.edu/veterinary-school/",
  },
  {
    value: "4499",
    label: "St Theresa's Medical University",
    website: "http://www.stmu.org/",
  },
  {
    value: "4500",
    label: "University of Silvaner",
    website: "http://www.unisilvaner.info/",
  },
  {
    value: "4501",
    label: "Windsor University School of Medicine",
    website: "http://www.windsor.edu/",
  },
  {
    value: "4502",
    label: "Pyongyang University of Science and Technology",
    website: "http://www.pust.net/",
  },
  {
    value: "4503",
    label: "Ajou University",
    website: "http://www.ajou.ac.kr/",
  },
  {
    value: "4504",
    label: "Andong National University",
    website: "http://www.andong.ac.kr/",
  },
  {
    value: "4505",
    label: "Catholic University of Korea",
    website: "http://www.cuk.ac.kr/",
  },
  {
    value: "4506",
    label: "Catholic University of Pusan",
    website: "http://www.cup.ac.kr/",
  },
  {
    value: "4507",
    label: "Catholic University of Taegu-Hyosung",
    website: "http://www.cataegu.ac.kr/",
  },
  {
    value: "4508",
    label: "Changwon National University",
    website: "http://www.changwon.ac.kr/",
  },
  {
    value: "4509",
    label: "Cheju National University",
    website: "http://www.cheju.ac.kr/",
  },
  {
    value: "4510",
    label: "Cheju National University of Education",
    website: "http://www.cheju-e.ac.kr/",
  },
  {
    value: "4511",
    label: "Chinju National University",
    website: "http://www.chinju.ac.kr/",
  },
  {
    value: "4512",
    label: "Chinju National University of Education",
    website: "http://www.chinju-e.ac.kr/",
  },
  {
    value: "4513",
    label: "Chodang University",
    website: "http://www.chodang.ac.kr/",
  },
  {
    value: "4514",
    label: "Chonbuk National University",
    website: "http://www.chonbuk.ac.kr/",
  },
  {
    value: "4515",
    label: "Chonbuk Sanup University of Technology (Howon University)",
    website: "http://www.howon.ac.kr/",
  },
  {
    value: "4516",
    label: "Chongju National University of Education",
    website: "http://www.chongju-e.ac.kr/",
  },
  {
    value: "4517",
    label: "Chongju University",
    website: "http://www.chongju.ac.kr/",
  },
  {
    value: "4518",
    label: "Chonju National University of Education",
    website: "http://www.chonju-e.ac.kr/",
  },
  {
    value: "4519",
    label: "Chonnam National University",
    website: "http://www.chonnam.ac.kr/",
  },
  {
    value: "4520",
    label: "Chosun University",
    website: "http://www.chosun.ac.kr/",
  },
  {
    value: "4521",
    label: "Chunchon National University of Education",
    website: "http://www.cnue-e.ac.kr/",
  },
  {
    value: "4522",
    label: "Chung-Ang University",
    website: "http://www.cau.ac.kr/",
  },
  {
    value: "4523",
    label: "Chungbuk National University",
    website: "http://www.chungbuk.ac.kr/",
  },
  {
    value: "4524",
    label: "Chung-Ju National University",
    website: "http://www.chungju.ac.kr/",
  },
  {
    value: "4525",
    label: "Chungnam National University",
    website: "http://www.chungnam.ac.kr/",
  },
  {
    value: "4526",
    label: "Daebul University",
    website: "http://www.daebul.ac.kr/",
  },
  {
    value: "4527",
    label: "Dankook University",
    website: "http://www.dankook.ac.kr/",
  },
  {
    value: "4528",
    label: "Dong-A University",
    website: "http://www.donga.ac.kr/",
  },
  {
    value: "4529",
    label: "Dongduk Women's University",
    website: "http://www.dongduk.ac.kr/",
  },
  {
    value: "4530",
    label: "Dong Eui University",
    website: "http://www.dongeui.ac.kr/",
  },
  {
    value: "4531",
    label: "Dongguk University",
    website: "http://www.dongguk.ac.kr/",
  },
  {
    value: "4532",
    label: "Dongseo University",
    website: "http://www.dongseo.ac.kr/",
  },
  {
    value: "4533",
    label: "Dongshin University",
    website: "http://www.dongshinu.ac.kr/",
  },
  {
    value: "4534",
    label: "Dong Yang University of Technology",
    website: "http://www.dytc.ac.kr/",
  },
  {
    value: "4535",
    label: "Duksung Women's University",
    website: "http://www.duksung.ac.kr/",
  },
  {
    value: "4536",
    label: "Ewha Women's University",
    website: "http://www.ewha.ac.kr/",
  },
  {
    value: "4537",
    label: "Gwangju Catholic College",
    website: "http://www.gjcatholic.ac.kr/",
  },
  {
    value: "4538",
    label: "Gyeongju University",
    website: "http://www.gyeongju.ac.kr/",
  },
  {
    value: "4539",
    label: "Gyeongsang National University",
    website: "http://www.gsnu.ac.kr/",
  },
  {
    value: "4540",
    label: "Halla University",
    website: "http://www.halla.ac.kr/",
  },
  {
    value: "4541",
    label: "Hallym University",
    website: "http://www.hallym.ac.kr/",
  },
  {
    value: "4542",
    label: "Hankuk Aviation University",
    website: "http://www.hangkong.ac.kr/",
  },
  {
    value: "4543",
    label: "Hankuk University of Foreign Studies",
    website: "http://www.hufs.ac.kr/",
  },
  {
    value: "4544",
    label: "Hankyong National University",
    website: "http://www.hknu.ac.kr/",
  },
  {
    value: "4545",
    label: "Hannam University",
    website: "http://www.hannam.ac.kr/",
  },
  {
    value: "4546",
    label: "Hansei University",
    website: "http://www.hansei.ac.kr/",
  },
  {
    value: "4547",
    label: "Hanseo University",
    website: "http://www.hanseo.ac.kr/",
  },
  {
    value: "4548",
    label: "Hanshin University",
    website: "http://www.hanshin.ac.kr/",
  },
  {
    value: "4549",
    label: "Hansung University Seoul",
    website: "http://www.hansung.ac.kr/",
  },
  {
    value: "4550",
    label: "Hanyang University",
    website: "http://www.hanyang.ac.kr/",
  },
  {
    value: "4551",
    label: "Honam University",
    website: "http://www.honam.ac.kr/",
  },
  {
    value: "4552",
    label: "Hongik University",
    website: "http://www.hongik.ac.kr/",
  },
  {
    value: "4553",
    label: "Hoseo University",
    website: "http://www.hoseo.ac.kr/",
  },
  {
    value: "4554",
    label: "Hyupsung University",
    website: "http://www.hyupsung.ac.kr/",
  },
  {
    value: "4555",
    label: "Inchon National University of Education",
    website: "http://www.inchon-e.ac.kr/",
  },
  {
    value: "4556",
    label: "Inchon University",
    website: "http://www.inchon.ac.kr/",
  },
  {
    value: "4557",
    label: "Information and Communications University",
    website: "http://www.icu.ac.kr/",
  },
  {
    value: "4558",
    label: "Inha University",
    website: "http://www.inha.ac.kr/",
  },
  {
    value: "4559",
    label: "Inje University",
    website: "http://www.inje.ac.kr/",
  },
  {
    value: "4560",
    label: "Jeonju University",
    website: "http://www.jeonju.ac.kr/",
  },
  {
    value: "4561",
    label: "Kangnam University",
    website: "http://www.kangnam.ac.kr/",
  },
  {
    value: "4562",
    label: "Kangnung National University",
    website: "http://www.kangnung.ac.kr/",
  },
  {
    value: "4563",
    label: "Kangwon National University",
    website: "http://www.kangwon.ac.kr/",
  },
  {
    value: "4564",
    label: "Kaya University",
    website: "http://www.kaya.ac.kr/",
  },
  {
    value: "4565",
    label: "KDI School of Public Policy and Management",
    website: "http://www.kdischool.ac.kr/",
  },
  {
    value: "4566",
    label: "Keimyung University",
    website: "http://www.keimyung.ac.kr/",
  },
  {
    value: "4567",
    label: "Kongju National University",
    website: "http://www.kyongju.ac.kr/",
  },
  {
    value: "4568",
    label: "Kongju National University of Education",
    website: "http://www.kongju-e.ac.kr/",
  },
  {
    value: "4569",
    label: "Konkuk University",
    website: "http://www.konkuk.ac.kr/",
  },
  {
    value: "4570",
    label: "Konyang University",
    website: "http://www.konyang.ac.kr/",
  },
  {
    value: "4571",
    label: "Kookmin University",
    website: "http://www.kookmin.ac.kr/",
  },
  {
    value: "4572",
    label: "Korea Advanced Institute of Science & Technology",
    website: "http://www.kaist.ac.kr/",
  },
  {
    value: "4573",
    label: "Korea Maritime University",
    website: "http://www.kmaritime.ac.kr/",
  },
  {
    value: "4574",
    label: "Korea National Open University",
    website: "http://www.knou.ac.kr/",
  },
  {
    value: "4575",
    label: "Korea National University of Arts",
    website: "http://www.knua.ac.kr/",
  },
  {
    value: "4576",
    label: "Korea National University of Education",
    website: "http://www.knue.ac.kr/",
  },
  {
    value: "4577",
    label: "Korea National University of Physical Education",
    website: "http://www.knupe.ac.kr/",
  },
  {
    value: "4578",
    label: "Korea University",
    website: "http://www.korea.ac.kr/",
  },
  {
    value: "4579",
    label: "Kosin University",
    website: "http://www.kosin.ac.kr/",
  },
  {
    value: "4580",
    label: "Kumoh National University of Technology",
    website: "http://www.kumoh.ac.kr/",
  },
  {
    value: "4581",
    label: "Kunsan National University",
    website: "http://www.kunsan.ac.kr/",
  },
  {
    value: "4582",
    label: "Kwan Dong University",
    website: "http://www.kwandong.ac.kr/",
  },
  {
    value: "4583",
    label: "Kwangju National University of Education",
    website: "http://www.kwangju-e.ac.kr/",
  },
  {
    value: "4584",
    label: "Kwangju University",
    website: "http://www.kwangju.ac.kr/",
  },
  {
    value: "4585",
    label: "Kwangwoon University",
    website: "http://www.kwangwoon.ac.kr/",
  },
  {
    value: "4586",
    label: "Kyonggi University",
    website: "http://www.kyonggi.ac.kr/",
  },
  {
    value: "4587",
    label: "Kyongju University",
    website: "http://www.kyongju.ac.kr/",
  },
  {
    value: "4588",
    label: "Kyunghee University",
    website: "http://www.kyunghee.ac.kr/",
  },
  {
    value: "4589",
    label: "Kyungil University",
    website: "http://www.kyungil.ac.kr/",
  },
  {
    value: "4590",
    label: "Kyungnam University",
    website: "http://www.kyungnam.ac.kr/",
  },
  {
    value: "4591",
    label: "Kyungpook National University",
    website: "http://www.kyungpook.ac.kr/",
  },
  {
    value: "4592",
    label: "Kyungsung University",
    website: "http://www.kyungsung.ac.kr/",
  },
  {
    value: "4593",
    label: "Kyungwon University",
    website: "http://www.kyungwon.ac.kr/",
  },
  {
    value: "4594",
    label: "Miryang National University",
    website: "http://www.miryang.ac.kr/",
  },
  {
    value: "4595",
    label: "Mokpo National Maritime University",
    website: "http://www.mmu.ac.kr/",
  },
  {
    value: "4596",
    label: "Mokpo National University",
    website: "http://www.mokpo.ac.kr/",
  },
  {
    value: "4597",
    label: "Mokwon University Taejon",
    website: "http://www.mokwon.ac.kr/",
  },
  {
    value: "4598",
    label: "Myongji University",
    website: "http://www.myongji.ac.kr/",
  },
  {
    value: "4599",
    label: "Paichai University",
    website: "http://www.paichai.ac.kr/",
  },
  {
    value: "4600",
    label: "Pohang University of Science and Technology",
    website: "http://www.postech.ac.kr/",
  },
  {
    value: "4601",
    label: "Pukyong National University",
    website: "http://www.pknu.ac.kr/",
  },
  {
    value: "4602",
    label: "Pusan National University",
    website: "http://www.pusan.ac.kr/",
  },
  {
    value: "4603",
    label: "Pusan National University of Education",
    website: "http://www.pusan-e.ac.kr/",
  },
  {
    value: "4604",
    label: "Pusan University of Foreign Studies",
    website: "http://www.pufs.ac.kr/",
  },
  {
    value: "4605",
    label: "Pusan Women's University",
    website: "http://www.pwc.ac.kr/",
  },
  {
    value: "4606",
    label: "Pyongtaek University ",
    website: "http://www.ptuniv.ac.kr/",
  },
  {
    value: "4607",
    label: "Sahmyook University",
    website: "http://www.syu.ac.kr/",
  },
  {
    value: "4608",
    label: "Samchok National University",
    website: "http://www.samchok.ac.kr/",
  },
  {
    value: "4609",
    label: "Sang Ji University",
    website: "http://www.sangji.ac.kr/",
  },
  {
    value: "4610",
    label: "Sang Myung University",
    website: "http://www.sangmyung.ac.kr/",
  },
  {
    value: "4611",
    label: "Sejong University",
    website: "http://www.sejong.ac.kr/",
  },
  {
    value: "4612",
    label: "Semyung University",
    website: "http://www.semyung.ac.kr/",
  },
  {
    value: "4613",
    label: "Seokyeong University",
    website: "http://www.skuniv.ac.kr/",
  },
  {
    value: "4614",
    label: "Seokyeong University",
    website: "http://www.seokyeong.ac.kr/",
  },
  {
    value: "4615",
    label: "Seonam University",
    website: "http://www.seonam.ac.kr/",
  },
  {
    value: "4616",
    label: "Seoul City University",
    website: "http://www.uos.ac.kr/",
  },
  {
    value: "4617",
    label: "Seoul National University",
    website: "http://www.snu.ac.kr/",
  },
  {
    value: "4618",
    label: "Seoul National University of Education",
    website: "http://www.seoul-e.ac.kr/",
  },
  {
    value: "4619",
    label: "Seoul National University of Technology",
    website: "http://www.snut.ac.kr/",
  },
  {
    value: "4620",
    label: "Seoul Teological University",
    website: "http://www.stu.ac.kr/",
  },
  {
    value: "4621",
    label: "Seoul Women's University",
    website: "http://www.swu.ac.kr/",
  },
  {
    value: "4622",
    label: "Seowon University",
    website: "http://www.seowon.ac.kr/",
  },
  {
    value: "4623",
    label: "Silla University",
    website: "http://www.silla.ac.kr/",
  },
  {
    value: "4624",
    label: "Sogang University",
    website: "http://www.sogang.ac.kr/",
  },
  {
    value: "4625",
    label: "Sookmyung Women's University",
    website: "http://www.sookmyung.ac.kr/",
  },
  {
    value: "4626",
    label: "Soonchunhyang University",
    website: "http://www.sch.ac.kr/",
  },
  {
    value: "4627",
    label: "Soongsil University",
    website: "http://www.soongsil.ac.kr/",
  },
  {
    value: "4628",
    label: "Sunchon National University",
    website: "http://www.sunchon.ac.kr/",
  },
  {
    value: "4629",
    label: "Sungkyul University",
    website: "http://www.sungkyul.ac.kr/",
  },
  {
    value: "4630",
    label: "Sung Kyun Kwan University",
    website: "http://www.skku.ac.kr/",
  },
  {
    value: "4631",
    label: "Sungshin Women's University",
    website: "http://www.sungshin.ac.kr/",
  },
  {
    value: "4632",
    label: "Sunmoon University",
    website: "http://www.sunmoon.ac.kr/",
  },
  {
    value: "4633",
    label: "Suwon Catholic University",
    website: "http://www.suwoncatholic.ac.kr/",
  },
  {
    value: "4634",
    label: "Suwon University",
    website: "http://www.suwon.ac.kr/",
  },
  {
    value: "4635",
    label: "Taegu National University of Education",
    website: "http://www.taegu-e.ac.kr/",
  },
  {
    value: "4636",
    label: "Taegu University",
    website: "http://www.taegu.ac.kr/",
  },
  {
    value: "4637",
    label: "Taejon National University of Technology",
    website: "http://www.tnut.ac.kr/",
  },
  {
    value: "4638",
    label: "Taejon University",
    website: "http://www.taejon.ac.kr/",
  },
  {
    value: "4639",
    label: "Taeshin Christian University",
    website: "http://www.taeshin.ac.kr/",
  },
  {
    value: "4640",
    label: "Tongmyung University of Information Technology",
    website: "http://www.tit.ac.kr/",
  },
  {
    value: "4641",
    label: "Ulsan University",
    website: "http://www.ulsan.ac.kr/",
  },
  {
    value: "4642",
    label: "University of Science and Technology",
    website: "http://www.ust.ac.kr/",
  },
  {
    value: "4643",
    label: "Wonkwang University",
    website: "http://www.wonkwang.ac.kr/",
  },
  {
    value: "4644",
    label: "Woosuk University",
    website: "http://www.woosuk.ac.kr/",
  },
  {
    value: "4645",
    label: "Yeungnam University",
    website: "http://www.yeungnam.ac.kr/",
  },
  {
    value: "4646",
    label: "Yonsei University",
    website: "http://www.yonsei.ac.kr/",
  },
  {
    value: "4647",
    label: "Yosu National University",
    website: "http://www.yosu.ac.kr/",
  },
  {
    value: "4648",
    label: "American University of Kuwait ",
    website: "http://www.auk.edu.kw/",
  },
  {
    value: "4649",
    label: "American University of Middle East",
    website: "http://www.aum.edu.kw/",
  },
  {
    value: "4650",
    label: "Arab Open University, Kuwait Branch",
    website: "http://www.aou.edu.kw/",
  },
  {
    value: "4651",
    label: "Australlian College of Kuwait ",
    website: "http://www.ackonline.com/",
  },
  {
    value: "4652",
    label: "Gulf University for Science and Technology",
    website: "http://www.gust.edu.kw/",
  },
  {
    value: "4653",
    label: "Kuwait International Law School",
    website: "http://www.kilaw.edu.kw/",
  },
  {
    value: "4654",
    label: "Kuwait Maastricht Business School",
    website: "http://www.kmbs.edu.kw/",
  },
  {
    value: "4655",
    label: "Kuwait University",
    website: "http://www.kuniv.edu.kw/",
  },
  {
    value: "4656",
    label: "International College of the Cayman Islands",
    website: "http://www.icci.edu.ky/",
  },
  {
    value: "4657",
    label: "St. Matthews University",
    website: "http://www.stmatthews.edu/",
  },
  {
    value: "4658",
    label: "Abai State University",
    website: "http://www.kaznpu.kz/",
  },
  {
    value: "4659",
    label: "Abylai Khan University",
    website: "http://www.ablaikhan.kz/",
  },
  {
    value: "4660",
    label: "Aktau State University",
    website: "http://www.aktsu.kz/",
  },
  {
    value: "4661",
    label: "Al-Farabi Kazakh National University",
    website: "http://www.kaznu.kz/",
  },
  {
    value: "4662",
    label: "Atyrau State University",
    website: "http://www.atgu.kz/",
  },
  {
    value: "4663",
    label: "East Kazakhstan State University",
    website: "http://www.vkgu.kz/",
  },
  {
    value: "4664",
    label: "East Kazakstan State Technical University",
    website: "http://www.ektu.kz/",
  },
  {
    value: "4665",
    label: "Eurasian Institute of market",
    website: "http://www.eurazir.kz/",
  },
  {
    value: "4666",
    label: "International Kazakh-Turkish University",
    website: "http://www.turkistan.kz/",
  },
  {
    value: "4667",
    label: "Karaganda State Buketov University",
    website: "http://www.ksu.kz/",
  },
  {
    value: "4668",
    label: "Karaganda State Technical University",
    website: "http://www.kstu.kz/",
  },
  {
    value: "4669",
    label: "Kazak American University",
    website: "http://www.kau.kz/",
  },
  {
    value: "4670",
    label: "Kazakh British Technical University",
    website: "http://www.kbtu.kz/",
  },
  {
    value: "4671",
    label:
      "Kazakhstan Institute of Management, Economics, and Strategic Research",
    website: "http://www.kimep.kz/",
  },
  {
    value: "4672",
    label: "Kazak National Technical University",
    website: "http://www.kazntu.kz/",
  },
  {
    value: "4673",
    label: "Kazak State University of Agriculture",
    website: "http://www.agriun.almaty.kz/",
  },
  {
    value: "4674",
    label: "Kostanai State University",
    website: "http://www.ksu.kst.kz/",
  },
  {
    value: "4675",
    label: "L.N. Gumilyov Eurasian National University",
    website: "http://www.enu.kz/",
  },
  {
    value: "4676",
    label: "North Kazakhstan State University",
    website: "http://www.nkzu.kz/",
  },
  {
    value: "4677",
    label: "Pavlodar University",
    website: "http://www.psu.kz/",
  },
  {
    value: "4678",
    label: "Semey State Medical University",
    website: "http://www.sgma.kz/",
  },
  {
    value: "4679",
    label: "Semey State University",
    website: "http://www.semgu.kz/",
  },
  {
    value: "4680",
    label: "Suleyman Demirel University",
    website: "http://www.sdu.edu.kz/",
  },
  {
    value: "4681",
    label: "Turar Ryskulov Kazakh Economic University",
    website: "http://www.kazeu.kz/",
  },
  {
    value: "4682",
    label: "University of International Business",
    website: "http://www.uib.kz/",
  },
  {
    value: "4683",
    label: "Western Kazakhstan Agricultural University",
    website: "http://www.wkau.kz/",
  },
  {
    value: "4684",
    label: "Zhetysu State University ",
    website: "http://www.zhgu.kz/",
  },
  {
    value: "4685",
    label: "Zhezkazgan Baikonurov University",
    website: "http://www.zhezu.kz/",
  },
  {
    value: "4686",
    label: "National University of Laos",
    website: "http://www.nuol.edu.la/",
  },
  {
    value: "4687",
    label: "Soutsaka Institute of management and Technology",
    website: "http://www.simt.edu.la/",
  },
  {
    value: "4688",
    label: "Al Imam Al-Ouzai University",
    website: "http://www.wakf.org/",
  },
  {
    value: "4689",
    label: "Al-Islah University",
    website: "http://www.islahonline.org/",
  },
  {
    value: "4690",
    label: "Al-Manar University",
    website: "http://www.mut.edu.lb/",
  },
  {
    value: "4691",
    label: "American University College of Technology",
    website: "http://www.aut.edu/",
  },
  {
    value: "4692",
    label: "American University of Beirut",
    website: "http://www.aub.edu.lb/",
  },
  {
    value: "4693",
    label: "American University of Science and Technology",
    website: "http://www.aust.edu.lb/",
  },
  {
    value: "4694",
    label: "Arab Open University",
    website: "http://www.arabou-lb.edu.lb/",
  },
  {
    value: "4695",
    label: "Arts, Sciences and Technology University",
    website: "http://www.aul.edu.lb/",
  },
  {
    value: "4696",
    label: "Balamand University",
    website: "http://www.balamand.edu.lb/",
  },
  {
    value: "4697",
    label: "Beirut Arab University",
    website: "http://www.bau.edu.lb/",
  },
  {
    value: "4698",
    label: "Business & Computer University College",
    website: "http://www.hu.edu.lb/",
  },
  {
    value: "4699",
    label: "Centre Universitaire de Technologie",
    website: "http://www.cut.edu.lb/",
  },
  {
    value: "4700",
    label: "Global University",
    website: "http://www.gu.edu.lb/",
  },
  {
    value: "4701",
    label: "Haigazian University",
    website: "http://www.haigazian.edu.lb/",
  },
  {
    value: "4702",
    label: "Hariri Canadian University",
    website: "http://www.hariricanadian.edu.lb/",
  },
  {
    value: "4703",
    label: "Holy Spirit University of Kaslik",
    website: "http://www.usek.edu.lb/",
  },
  {
    value: "4704",
    label: "Jinan University",
    website: "http://www.jinan.edu.lb/",
  },
  {
    value: "4705",
    label: "Lebanese American University",
    website: "http://www.lau.edu.lb/",
  },
  {
    value: "4706",
    label: "Lebanese International University",
    website: "http://www.liu.edu.lb/",
  },
  {
    value: "4707",
    label: "Lebanese University",
    website: "http://www.ul.edu.lb/",
  },
  {
    value: "4708",
    label: "Matn University",
    website: "http://www.matnu.edu.lb/",
  },
  {
    value: "4709",
    label: "Near East School of Theology",
    website: "http://www.theonest.edu.lb/",
  },
  {
    value: "4710",
    label: "Notre Dame University",
    website: "http://www.ndu.edu.lb/",
  },
  {
    value: "4711",
    label: "St. Joseph University Beirut",
    website: "http://www.usj.edu.lb/",
  },
  {
    value: "4712",
    label: "Spartan University of Health Sciences",
    website: "http://www.spartanmed.org/",
  },
  {
    value: "4713",
    label: "Internationle Akademie für Philosophie",
    website: "http://www.iap.li/",
  },
  {
    value: "4714",
    label: "Universität Liechtenstein",
    website: "http://www.uni.li/",
  },
  {
    value: "4715",
    label: "Arthur C Clarke Institute of Modern Technologies",
    website: "http://www.accimt.ac.lk/",
  },
  {
    value: "4716",
    label: "Buddhasravaka Bhikshu University",
    website: "http://www.bbu.ac.lk/",
  },
  {
    value: "4717",
    label: "Buddhist and Pali University of Sri Lanka",
    website: "http://www.bpu.ac.lk/",
  },
  {
    value: "4718",
    label: "Eastern University of Sri Lanka",
    website: "http://www.esn.ac.lk/",
  },
  {
    value: "4719",
    label: "Horizon College of Business and Technology",
    website: "http://www.horizoncampus.edu.lk/",
  },
  {
    value: "4720",
    label: "Kotelawala Defence University",
    website: "http://www.kdu.ac.lk/",
  },
  {
    value: "4721",
    label: "Open University of Sri Lanka",
    website: "http://www.ou.ac.lk/",
  },
  {
    value: "4722",
    label: "Postgraduate Institute of Agriculture (PGIA)",
    website: "http://www.pgia.ac.lk/",
  },
  {
    value: "4723",
    label:
      "Postgraduate Institute of Pali & Buddhist Studies (University of Kelaniya)",
    website: "http://www.kln.ac.lk/institutes/pgipbs/",
  },
  {
    value: "4724",
    label: "Rajarata University of Sri Lanka",
    website: "http://www.rjt.ac.lk/",
  },
  {
    value: "4725",
    label: "Sabaragamuwa University of Sri Lanka",
    website: "http://www.sab.ac.lk/",
  },
  {
    value: "4726",
    label: "South Asian Institute of Technology And Medicine (SAITM) ",
    website: "http://www.saitm.edu.lk/",
  },
  {
    value: "4727",
    label: "South Eastern University of Sri Lanka",
    website: "http://www.seu.ac.lk/",
  },
  {
    value: "4728",
    label: "Sri Lanka Institute of Advanced Technical Education",
    website: "http://www.sliate.net/",
  },
  {
    value: "4729",
    label: "Sri Lanka Institute of Information Technology (SLIT)",
    website: "http://www.sliit.lk/",
  },
  {
    value: "4730",
    label: "University of Colombo",
    website: "http://www.cmb.ac.lk/",
  },
  {
    value: "4731",
    label: "University of Jaffna",
    website: "http://www.jfn.ac.lk/",
  },
  {
    value: "4732",
    label: "University of Kelaniya",
    website: "http://www.kln.ac.lk/",
  },
  {
    value: "4733",
    label: "University of Moratuwa",
    website: "http://www.mrt.ac.lk/",
  },
  {
    value: "4734",
    label: "University of Peradeniya",
    website: "http://www.pdn.ac.lk/",
  },
  {
    value: "4735",
    label: "University of Ruhuna",
    website: "http://www.ruh.ac.lk/",
  },
  {
    value: "4736",
    label: "University of Sri Jayawardenapura",
    website: "http://www.sjp.ac.lk/",
  },
  {
    value: "4737",
    label: "University of the Visual & Performing Arts",
    website: "http://www.vpa.ac.lk/",
  },
  {
    value: "4738",
    label: "Uva Wellassa University",
    website: "http://www.uwu.ac.lk/",
  },
  {
    value: "4739",
    label: "Wayamba University of Sri Lanka ",
    website: "http://www.wyb.ac.lk/",
  },
  {
    value: "4740",
    label: "University of Liberia",
    website: "http://www.universityliberia.org/",
  },
  {
    value: "4741",
    label: "National University of Lesotho",
    website: "http://www.nul.ls/",
  },
  {
    value: "4742",
    label: "International School of Management",
    website: "http://www.ism.lt/",
  },
  {
    value: "4743",
    label: "Kaunas Medical Academy",
    website: "http://info.kma.lt/",
  },
  {
    value: "4744",
    label: "Kaunas University of Technology",
    website: "http://www.ktu.lt/",
  },
  {
    value: "4745",
    label: "Klaipeda University",
    website: "http://www.ku.lt/",
  },
  {
    value: "4746",
    label: "Lithuania Christian College",
    website: "http://www.lcc.lt/",
  },
  {
    value: "4747",
    label: "Lithunian Academy of Music and Theatre",
    website: "http://www.lmta.lt/",
  },
  {
    value: "4748",
    label: "Lithunian Institute of Physical Education",
    website: "http://www.lkka.lt/",
  },
  {
    value: "4749",
    label: "Lithunian University of Agriculture",
    website: "http://www.lzua.lt/",
  },
  {
    value: "4750",
    label: "Lithunian Veterinary Academy",
    website: "http://www.lva.lt/",
  },
  {
    value: "4751",
    label: "Military academy of Lithuania",
    website: "http://www.lka.lt/",
  },
  {
    value: "4752",
    label: "Mykolo Romerio university",
    website: "http://www.mruni.eu/",
  },
  {
    value: "4753",
    label: "Siauliai University",
    website: "http://www.su.lt/",
  },
  {
    value: "4754",
    label: "Vilnius Academy of Arts",
    website: "http://www.mch.mii.lt/more/vda/",
  },
  {
    value: "4755",
    label: "Vilnius Gediminas Technical University",
    website: "http://www.vgtu.lt/",
  },
  {
    value: "4756",
    label: "Vilnius Pedagogical University",
    website: "http://www.vpu.lt/",
  },
  {
    value: "4757",
    label: "Vilnius University",
    website: "http://www.vu.lt/",
  },
  {
    value: "4758",
    label: "Vytautas Magnus University",
    website: "http://www.vdu.lt/",
  },
  {
    value: "4759",
    label: "International University Institute of Luxembourg",
    website: "http://www.iuil.lu/",
  },
  {
    value: "4760",
    label: "University of Luxemburg",
    website: "http://www.uni.lu/",
  },
  {
    value: "4761",
    label: "Baltic International Academy",
    website: "http://www.bsa.edu.lv/",
  },
  {
    value: "4762",
    label: "Daugavpils University",
    website: "http://www.dau.lv/",
  },
  {
    value: "4763",
    label: "Higher School of Psychology",
    website: "http://www.psy.lv/",
  },
  {
    value: "4764",
    label: "Latvian Academy of Arts",
    website: "http://www.lma.lv/",
  },
  {
    value: "4765",
    label: "Latvian Academy of Culture",
    website: "http://www.lka.edu.lv/",
  },
  {
    value: "4766",
    label: "Latvian Academy of Music",
    website: "http://www.lmuza.lv/academy/",
  },
  {
    value: "4767",
    label: "Latvian Academy of Sports Education",
    website: "http://lspa.lanet.lv/",
  },
  {
    value: "4768",
    label: "Latvian Maritime Academy",
    website: "http://www.lama.lv/",
  },
  {
    value: "4769",
    label: "Latvian University of Agriculture",
    website: "http://www.llu.lv/",
  },
  {
    value: "4770",
    label: "Liepaja Pedagogical Higher School",
    website: "http://www.cs.lpu.lv/",
  },
  {
    value: "4771",
    label: "Medical Academy of Latvia",
    website: "http://www.aml.lv/",
  },
  {
    value: "4772",
    label: "National Defence Academy of Latvia",
    website: "http://www.mil.lv/",
  },
  {
    value: "4773",
    label: "Police Academy of Latvia",
    website: "http://www.polak.edu.lv/",
  },
  {
    value: "4774",
    label: "Rezekne Higher School",
    website: "http://www.ru.lv/",
  },
  {
    value: "4775",
    label: "Riga Aviation University",
    website: "http://www.rau.lv/",
  },
  {
    value: "4776",
    label: "Riga Teacher Training and Educational Management Academy",
    website: "http://www.rpiva.lv/",
  },
  {
    value: "4777",
    label: "Riga Technical University",
    website: "http://www.rtu.lv/",
  },
  {
    value: "4778",
    label: "School of Business and Finance",
    website: "http://www.ba.lv/",
  },
  {
    value: "4779",
    label: "Stockholm School of Economics in Riga",
    website: "http://www.sseriga.edu.lv/",
  },
  {
    value: "4780",
    label: "Transport and Telecommunication Institute",
    website: "http://www.tsi.lv/",
  },
  {
    value: "4781",
    label: "University of Latvia",
    website: "http://www.lu.lv/",
  },
  {
    value: "4782",
    label: "Vidzeme University College",
    website: "http://www.va.lv/",
  },
  {
    value: "4783",
    label: "Al-Asmarya University for Islamic Studies",
    website: "http://www.asmarya.edu.ly/",
  },
  {
    value: "4784",
    label: "Al-Jabal Al-Gharbi University",
    website: "http://www.jgu.edu.ly/",
  },
  {
    value: "4785",
    label: "Elmergib University",
    website: "http://www.elmergib.edu.ly/",
  },
  {
    value: "4786",
    label: "Libyan International Medical University (LIMU)",
    website: "http://www.limu.edu.ly/",
  },
  {
    value: "4787",
    label: "Libya Open University",
    website: "http://www.libopenuniv-edu.org/",
  },
  {
    value: "4788",
    label: "Omar Al-Mukhtar University",
    website: "http://www.omu.edu.ly/",
  },
  {
    value: "4789",
    label: "Sebha University",
    website: "http://www.sebhau.edu.ly/",
  },
  {
    value: "4790",
    label: "Sirte University",
    website: "http://www.su.edu.ly/",
  },
  {
    value: "4791",
    label: "University of Benghazi",
    website: "http://www.uob.edu.ly/",
  },
  {
    value: "4792",
    label: "University of Tripoli",
    website: "http://www.uot.edu.ly/",
  },
  {
    value: "4793",
    label: "Zawiya University",
    website: "http://www.zu.edu.ly/",
  },
  {
    value: "4794",
    label: "Al Akhawayn University",
    website: "http://www.aui.ma/",
  },
  {
    value: "4795",
    label: "Ecole des Hautes Etudes Commerciales MAROC",
    website: "http://www.hec.ac.ma/",
  },
  {
    value: "4796",
    label: "Ecole des Hautes Etudes en Gestion Informatique et Communication",
    website: "http://www.edhec.ac.ma/",
  },
  {
    value: "4797",
    label: "Ecole Mohammadia d'Ingénieurs",
    website: "http://www.emi.ac.ma/",
  },
  {
    value: "4798",
    label: "Ecole National d'Agriculture de Meknes",
    website: "http://www.enameknes.ac.ma/",
  },
  {
    value: "4799",
    label: "Ecole Nationale d'Architecture",
    website: "http://www.archi.ac.ma/",
  },
  {
    value: "4800",
    label: "Ecole Supérieure de Commerce et des Affaires",
    website: "http://www.esca.ac.ma/",
  },
  {
    value: "4801",
    label: "Ecole Superieure des Télécommunications",
    website: "http://www.suptelecom.net.ma/",
  },
  {
    value: "4802",
    label: "Ecole Supérieure d'Informatique et de Management",
    website: "http://www.hightech.edu/",
  },
  {
    value: "4803",
    label: "Institut des Hautes Etudes de Management",
    website: "http://www.hem.ac.ma/",
  },
  {
    value: "4804",
    label: "Institute of Management and Business Technology",
    website: "http://www.imbt.ma/",
  },
  {
    value: "4805",
    label: "Institut National des Postes et Télécommunications",
    website: "http://www.inpt.ac.ma/",
  },
  {
    value: "4806",
    label: "Institut Supérieur de Commerce et d'Administration des Entreprises",
    website: "http://www.groupeiscae.ma/",
  },
  {
    value: "4807",
    label: "Institut Supérieur de Management et de Technologie (MATCI)",
    website: "http://www.matci.ac.ma/",
  },
  {
    value: "4808",
    label: "International Institute for Higher Education",
    website: "http://www.iihem.ac.ma/",
  },
  {
    value: "4809",
    label: "ISFORT - Institut de Formation en Technologie Alimentaire",
    website: "http://www.isfort.ac.ma/",
  },
  {
    value: "4810",
    label: "Université Abdelmalek Essadi",
    website: "http://www.uae.ac.ma/",
  },
  {
    value: "4811",
    label: "Université Cadi Ayyad",
    website: "http://www.ucam.ac.ma/",
  },
  {
    value: "4812",
    label: "Université Chouaib Doukkali",
    website: "http://www.ucd.ac.ma/",
  },
  {
    value: "4813",
    label: "Université Hassan Ier - Settat",
    website: "http://www.uh1.ac.ma/",
  },
  {
    value: "4814",
    label: "Université Hassan II - Aïn Chock",
    website: "http://www.rectorat-uh2c.ac.ma/",
  },
  {
    value: "4815",
    label: "Université Hassan II - Mohammadia",
    website: "http://www.uh2m.ac.ma/",
  },
  {
    value: "4816",
    label: "Université Ibn Toufail",
    website: "http://www.univ-ibntofail.ac.ma/",
  },
  {
    value: "4817",
    label: "Université Ibn Zohr Agadir",
    website: "http://www.esta.ac.ma/",
  },
  {
    value: "4818",
    label: "Université Mohammed Ier",
    website: "http://www.univ-oujda.ac.ma/",
  },
  {
    value: "4819",
    label: "Université Mohammed V - Agdal",
    website: "http://www.um5a.ac.ma/",
  },
  {
    value: "4820",
    label: "Université Mohammed V - Souissi",
    website: "http://www.um5s.ac.ma/",
  },
  {
    value: "4821",
    label: "Université Moulay Ismail Meknès",
    website: "http://www.rumi.ac.ma/",
  },
  {
    value: "4822",
    label: "Université Sidi Mohammed Ben Abdellah",
    website: "http://www.usmba.ac.ma/",
  },
  {
    value: "4823",
    label: "University of Southern Europe (Monaco Business School)",
    website: "http://www.riviera.fr/usehome.htm",
  },
  {
    value: "4824",
    label: "Academy of Economic Studies of Moldova",
    website: "http://www.ase.md/",
  },
  {
    value: "4825",
    label: 'Balti State University "Alecu Russo"',
    website: "http://www.usb.md/",
  },
  {
    value: "4826",
    label: "Comrat State University",
    website: "http://www.kdu.md/",
  },
  {
    value: "4827",
    label: "Free International University of Moldova",
    website: "http://www.ulim.md/",
  },
  {
    value: "4828",
    label: "Moldova Cooperative Trade University",
    website: "http://www.uccm.md/",
  },
  {
    value: "4829",
    label: "Moldova State Agricultural University",
    website: "http://www.uasm.md/",
  },
  {
    value: "4830",
    label: "Moldova State University",
    website: "http://www.usm.md/",
  },
  {
    value: "4831",
    label:
      'Moldova State University of Medicine and Pharmacy "N. Testemitsanu"',
    website: "http://www.usmf.md/",
  },
  {
    value: "4832",
    label: "Moldova Technical University",
    website: "http://www.utm.md/",
  },
  {
    value: "4833",
    label: 'Pedagogical State University "Ion Creanga"',
    website: "http://upm.moldnet.md/",
  },
  {
    value: "4834",
    label: "Tiraspol State University",
    website: "http://www.ust.md/",
  },
  {
    value: "4835",
    label: "University of Applied Sciences of Moldova",
    website: "http://www.usam.md/",
  },
  {
    value: "4836",
    label: "University of Montenegro",
    website: "http://www.ucg.cg.ac.yu/",
  },
  {
    value: "4837",
    label: "Université d'Antananarivo",
    website: "http://www.univ-antananarivo.mg/",
  },
  {
    value: "4838",
    label: "Université d'Antsiranana",
    website: "http://www.univ-antsiranana.mg/",
  },
  {
    value: "4839",
    label: "Université de Fianarantsoa",
    website: "http://www.univ-fianar.mg/",
  },
  {
    value: "4840",
    label: "Université de Mahajanga",
    website: "http://www.univ-mahajanga.mg/",
  },
  {
    value: "4841",
    label: "Université de Toamasina",
    website: "http://www.univ-toamasina.mg/",
  },
  {
    value: "4842",
    label: "Université de Toliary",
    website: "http://www.univ-toliara.mg/",
  },
  {
    value: "4843",
    label: "FON University",
    website: "http://www.fon.edu.mk/",
  },
  {
    value: "4844",
    label: "South East European University",
    website: "http://www.seeu.edu.mk/",
  },
  {
    value: "4845",
    label: "State University of Tetova",
    website: "http://www.unite.edu.mk/",
  },
  {
    value: "4846",
    label: "St.Cyril and Methodius University",
    website: "http://www.ukim.edu.mk/",
  },
  {
    value: "4847",
    label: "St.Kliment Ohridski University",
    website: "http://www.uklo.edu.mk/",
  },
  {
    value: "4848",
    label:
      'University for Information Science and Technology  "St. Paul The Apostle" ',
    website: "http://www.uist.edu.mk/",
  },
  {
    value: "4849",
    label: "University Goce Delcev",
    website: "http://www.ugd.edu.mk/",
  },
  {
    value: "4850",
    label: "Université de Bamako",
    website: "http://www.ml.refer.org/u-bamako/",
  },
  {
    value: "4851",
    label: "International Theravada Buddhist Missionary University",
    website: "http://www.itbmu.org.mm/",
  },
  {
    value: "4852",
    label: "Mandalay Technological University",
    website: "http://www.most.gov.mm/mtu/",
  },
  {
    value: "4853",
    label: "Myanmar Aerospace Engineering University",
    website: "http://www.most.gov.mm/maeu/",
  },
  {
    value: "4854",
    label: "Technological University (Banmaw)",
    website: "http://www.most.gov.mm/banmawtu/",
  },
  {
    value: "4855",
    label: "Technological University (Dawei)",
    website: "http://www.most.gov.mm/daweitu/",
  },
  {
    value: "4856",
    label: "Technological University (Hinthada)",
    website: "http://www.most.gov.mm/hinthadatu/",
  },
  {
    value: "4857",
    label: "Technological University (Hmawbi)",
    website: "http://www.most.gov.mm/hmawbitu/",
  },
  {
    value: "4858",
    label: "Technological University (Hpaan)",
    website: "http://www.most.gov.mm/hpaantu/",
  },
  {
    value: "4859",
    label: "Technological University (Kalay)",
    website: "http://www.most.gov.mm/kalaytu/",
  },
  {
    value: "4860",
    label: "Technological University (Kyaing Tong)",
    website: "http://www.most.gov.mm/kyaingtongtu/",
  },
  {
    value: "4861",
    label: "Technological University (Kyaukse)",
    website: "http://www.most.gov.mm/kyauksetu/",
  },
  {
    value: "4862",
    label: "Technological University (Lashio)",
    website: "http://www.most.gov.mm/lashiotu/",
  },
  {
    value: "4863",
    label: "Technological University (Loikaw)",
    website: "http://www.most.gov.mm/loikawtu/",
  },
  {
    value: "4864",
    label: "Technological University (Magway)",
    website: "http://www.most.gov.mm/magwaytu/",
  },
  {
    value: "4865",
    label: "Technological University (Mandalay)",
    website: "http://www.most.gov.mm/mdytu/",
  },
  {
    value: "4866",
    label: "Technological University (Maubin)",
    website: "http://www.most.gov.mm/maubintu/",
  },
  {
    value: "4867",
    label: "Technological University (Mawlamyaing)",
    website: "http://www.most.gov.mm/mawlamyaingtu/",
  },
  {
    value: "4868",
    label: "Technological University (Meikhtila)",
    website: "http://www.most.gov.mm/meikhtilatu/",
  },
  {
    value: "4869",
    label: "Technological University (Monywa)",
    website: "http://www.most.gov.mm/monywatu/",
  },
  {
    value: "4870",
    label: "Technological University (Myeik)",
    website: "http://www.most.gov.mm/myeiktu/",
  },
  {
    value: "4871",
    label: "Technological University (Myitkyina) ",
    website: "http://www.most.gov.mm/myitkyinatu/",
  },
  {
    value: "4872",
    label: "Technological University (Pakokku)",
    website: "http://www.most.gov.mm/pakokkutu/",
  },
  {
    value: "4873",
    label: "Technological University (Pathein)",
    website: "http://www.most.gov.mm/patheintu/",
  },
  {
    value: "4874",
    label: "Technological University (Pinlon)",
    website: "http://www.most.gov.mm/pinlontu/",
  },
  {
    value: "4875",
    label: "Technological University (Pyay)",
    website: "http://www.most.gov.mm/ptu/",
  },
  {
    value: "4876",
    label: "Technological University (Sittwe)",
    website: "http://www.most.gov.mm/sittwetu/",
  },
  {
    value: "4877",
    label: "Technological University (Taunggyi)",
    website: "http://www.most.gov.mm/taunggyitu/",
  },
  {
    value: "4878",
    label: "Technological University (Taungoo)",
    website: "http://www.most.gov.mm/taungootu/",
  },
  {
    value: "4879",
    label: "Technological University (Thanlyin)",
    website: "http://www.most.gov.mm/thanlyintu/",
  },
  {
    value: "4880",
    label: "Technological University (West Yangon)",
    website: "http://www.most.gov.mm/wytu/",
  },
  {
    value: "4881",
    label: " Technological University (Yangon)",
    website: "http://www.most.gov.mm/ytu/",
  },
  {
    value: "4882",
    label: "University of Computer Studies, Yangon",
    website: "http://www.ucsy.edu.mm/",
  },
  {
    value: "4883",
    label: "University of Medicine 1, Yangon",
    website: "http://www.um1ygn.edu.mm/",
  },
  {
    value: "4884",
    label: "University of Medicine Magway",
    website: "http://www.ummg.edu.mm/",
  },
  {
    value: "4885",
    label: "Health sciences University of Mongolia",
    website: "http://www.hsum-ac.mn/",
  },
  {
    value: "4886",
    label: "Institute of Commerce and Business",
    website: "http://www.icb.edu.mn/",
  },
  {
    value: "4887",
    label: "Mongolian National University",
    website: "http://www.num.edu.mn/",
  },
  {
    value: "4888",
    label: "Mongolian State University of Agriculture",
    website: "http://www.msua.edu.mn/",
  },
  {
    value: "4889",
    label: "Mongolian State University of Arts and Culture",
    website: "http://www.msuac.edu.mn/",
  },
  {
    value: "4890",
    label: "Mongolian State University of Education",
    website: "http://www.msue.edu.mn/",
  },
  {
    value: "4891",
    label: "Mongolian University of Science and Technology",
    website: "http://www.must.edu.mn/",
  },
  {
    value: "4892",
    label: "Orkhon University",
    website: "http://www.orkhon.edu.mn/",
  },
  {
    value: "4893",
    label: "Ulaanbaatar University",
    website: "http://www.ulaanbaatar.edu.mn/",
  },
  {
    value: "4894",
    label: "University of the Humanities",
    website: "http://www.humanities.mn/",
  },
  {
    value: "4895",
    label: "Macau Polytechnic Institute",
    website: "http://www.ipm.edu.mo/",
  },
  {
    value: "4896",
    label: "University of Macau",
    website: "http://www.umac.mo/",
  },
  {
    value: "4897",
    label: "University of Saint Joseph",
    website: "http://www.usj.edu.mo/",
  },
  {
    value: "4898",
    label: "Université des Antilles et de la Guyane",
    website: "http://www.univ-ag.fr/",
  },
  {
    value: "4899",
    label: " Université de Nouakchott",
    website: "http://www.univ-nkc.mr/",
  },
  {
    value: "4900",
    label: "University of Science, Arts and Technology",
    website: "http://www.usat.ms/",
  },
  {
    value: "4901",
    label: "European Institute of Education",
    website: "http://www.eieonline.com/",
  },
  {
    value: "4902",
    label: "International Tourism Institute",
    website: "http://www.itismalta.com/",
  },
  {
    value: "4903",
    label: "University of Malta",
    website: "http://www.um.edu.mt/",
  },
  {
    value: "4904",
    label: "University of Mauritius",
    website: "http://www.uom.ac.mu/",
  },
  {
    value: "4905",
    label: "University of Technology",
    website: "http://www.utm.ac.mu/",
  },
  {
    value: "4906",
    label: "Cyryx College",
    website: "http://www.cyryxcollege.edu.mv/",
  },
  {
    value: "4907",
    label: "Maldives National University",
    website: "http://www.mnu.edu.mv/",
  },
  {
    value: "4908",
    label: "Mandhu College",
    website: "http://www.mandhu.com/edu/",
  },
  {
    value: "4909",
    label: "Catholic University of Malawi",
    website: "http://www.cunima.net/",
  },
  {
    value: "4910",
    label: "Mzuzu University",
    website: "http://www.mzuni.ac.mw/",
  },
  {
    value: "4911",
    label: "University of Livingstonia",
    website: "http://www.ulivingstonia.com/",
  },
  {
    value: "4912",
    label: "University of Malawi",
    website: "http://www.unima.mw/",
  },
  {
    value: "4913",
    label: "Benemerita Universidad Autónoma de Puebla",
    website: "http://www.buap.mx/",
  },
  {
    value: "4914",
    label: "Centro de Enseñanza Técnica Industrial",
    website: "http://www.ceti.mx/",
  },
  {
    value: "4915",
    label: "Centro de Estudios Universitarios Monterrey",
    website: "http://www.ceu.edu.mx/",
  },
  {
    value: "4916",
    label: "Centro de Estudios Universitarios Xochicalco",
    website: "http://www.ceux.mx/",
  },
  {
    value: "4917",
    label: "Centro Universitario Ixtlahuaca",
    website: "http://www.ceunix.com.mx/",
  },
  {
    value: "4918",
    label: "CETYS Universidad",
    website: "http://www.cetys.mx/",
  },
  {
    value: "4919",
    label: "Dirección General de Institutos Tecnológicos",
    website: "http://www.dgit.gob.mx/",
  },
  {
    value: "4920",
    label: "El Colegio de México",
    website: "http://www.colmex.mx/",
  },
  {
    value: "4921",
    label: "Escuela Bancaria y Comercial",
    website: "http://www.ebc.mx/",
  },
  {
    value: "4922",
    label: "Escuela Nacional de Estudios Superiores Unidad León",
    website: "http://www.enes.unam.mx/",
  },
  {
    value: "4923",
    label: "Instituto de Estudios Superiores de la Sierra",
    website: "http://www.ties.com.mx/",
  },
  {
    value: "4924",
    label: "Instituto Politécnico Nacional",
    website: "http://www.ipn.edu.mx/",
  },
  {
    value: "4925",
    label: "Instituto Politécnico Nacional, Campus Guanajuato",
    website: "http://www.upiig.ipn.mx/",
  },
  {
    value: "4926",
    label: "Instituto Tecnológico Autonómo de México",
    website: "http://www.itam.mx/",
  },
  {
    value: "4927",
    label: "Instituto Tecnológico de Aguascalientes",
    website: "http://www.ita.mx/",
  },
  {
    value: "4928",
    label: "Instituto Tecnológico de Celaya",
    website: "http://www.itc.mx/",
  },
  {
    value: "4929",
    label: "Instituto Tecnologico de Durango",
    website: "http://www.itdurango.edu.mx/",
  },
  {
    value: "4930",
    label: "Instituto Tecnológico de León",
    website: "http://www.itleon.edu.mx/",
  },
  {
    value: "4931",
    label: "Instituto Tecnologico de Minatitlan",
    website: "http://www.itmina.edu.mx/",
  },
  {
    value: "4932",
    label: "Instituto Tecnológico de Morelia",
    website: "http://www.itmorelia.edu.mx/",
  },
  {
    value: "4933",
    label: "Instituto Tecnológico de Querétaro",
    website: "http://www.itq.edu.mx/",
  },
  {
    value: "4934",
    label: "Instituto Tecnológico de San Luis Potosí",
    website: "http://www.itslp.edu.mx/",
  },
  {
    value: "4935",
    label: "Instituto Tecnológico de Sonora",
    website: "http://www.itson.mx/",
  },
  {
    value: "4936",
    label:
      "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)",
    website: "http://www.itesm.mx/",
  },
  {
    value: "4937",
    label: "Instituto Tecnológico y de Estudios Superiores de Occidente",
    website: "http://www.iteso.mx/",
  },
  {
    value: "4938",
    label: "Politécnico de Guanajuato",
    website: "http://www.poligto.edu.mx/",
  },
  {
    value: "4939",
    label: "Tecnologico de Baja California, Universidad",
    website: "http://www.tecbc.mx/",
  },
  {
    value: "4940",
    label: "Universidad Anáhuac",
    website: "http://www.anahuac.mx/",
  },
  {
    value: "4941",
    label: "Universidad Anáhuac del Sur",
    website: "http://www.uas.mx/",
  },
  {
    value: "4942",
    label: 'Universidad Autónoma Agraria "Antonio Narro"',
    website: "http://www.uaaan.mx/",
  },
  {
    value: "4943",
    label: 'Universidad Autónoma "Benito Juárez" de Oaxaca',
    website: "http://www.uabjo.mx/",
  },
  {
    value: "4944",
    label: "Universidad Autónoma de Aguascalientes",
    website: "http://www.uaa.mx:8001/",
  },
  {
    value: "4945",
    label: "Universidad Autónoma de Baja California",
    website: "http://www.uabc.mx/",
  },
  {
    value: "4946",
    label: "Universidad Autónoma de Baja California Sur",
    website: "http://www.uabcs.mx/",
  },
  {
    value: "4947",
    label: "Universidad Autónoma de Campeche",
    website: "http://www.uacam.mx/",
  },
  {
    value: "4948",
    label: "Universidad Autónoma de Chapingo",
    website: "http://www.chapingo.mx/",
  },
  {
    value: "4949",
    label: "Universidad Autónoma de Chiapas",
    website: "http://www.unach.mx/",
  },
  {
    value: "4950",
    label: "Universidad Autónoma de Chihuahua",
    website: "http://www.uach.mx/",
  },
  {
    value: "4951",
    label: "Universidad Autónoma de Ciudad Juárez",
    website: "http://www.uacj.mx/",
  },
  {
    value: "4952",
    label: "Universidad Autónoma de Coahuila",
    website: "http://www.uadec.mx/",
  },
  {
    value: "4953",
    label: "Universidad Autonoma  de Durango",
    website: "http://www.uad.edu.mx/",
  },
  {
    value: "4954",
    label: "Universidad Autónoma de Fresnillo",
    website: "http://www.uaf.mx/",
  },
  {
    value: "4955",
    label: "Universidad Autónoma de Guadalajara",
    website: "http://www.uag.mx/",
  },
  {
    value: "4956",
    label: "Universidad Autónoma de Guerrero",
    website: "http://www.uagro.mx/",
  },
  {
    value: "4957",
    label: "Universidad Autónoma de la Ciudad de México",
    website: "http://www.uacm.edu.mx/",
  },
  {
    value: "4958",
    label: "Universidad Autónoma de la Laguna",
    website: "http://www.ual.mx/",
  },
  {
    value: "4959",
    label: "Universidad Autónoma del Carmen",
    website: "http://www.unacar.mx/",
  },
  {
    value: "4960",
    label: "Universidad Autónoma del Estado de Hidalgo",
    website: "http://www.reduaeh.mx/",
  },
  {
    value: "4961",
    label: "Universidad Autónoma del Estado de México",
    website: "http://www.uaemex.mx/",
  },
  {
    value: "4962",
    label: "Universidad Autónoma del Estado de Morelos",
    website: "http://www.uaem.mx/",
  },
  {
    value: "4963",
    label: "Universidad Autónoma del Noreste",
    website: "http://www.uane.edu.mx/",
  },
  {
    value: "4964",
    label: "Universidad Autónoma de Nayarit",
    website: "http://www.uan.mx/",
  },
  {
    value: "4965",
    label: "Universidad Autónoma de Nuevo León",
    website: "http://www.uanl.mx/",
  },
  {
    value: "4966",
    label: "Universidad Autónoma de Querétaro",
    website: "http://www.uaq.mx/",
  },
  {
    value: "4967",
    label: "Universidad Autónoma de San Luis Potosí",
    website: "http://www.uaslp.mx/",
  },
  {
    value: "4968",
    label: "Universidad Autónoma de Sinaloa",
    website: "http://www.uasnet.mx/",
  },
  {
    value: "4969",
    label: "Universidad Autónoma de Tamaulipas",
    website: "http://www.uat.mx/",
  },
  {
    value: "4970",
    label: "Universidad Autónoma de Tlaxcala",
    website: "http://www.uatx.mx/",
  },
  {
    value: "4971",
    label: "Universidad Autónoma de Veracruz Villa Rica",
    website: "http://www.univillarica.mx/",
  },
  {
    value: "4972",
    label: "Universidad Autónoma de Yucatán",
    website: "http://www.uady.mx/",
  },
  {
    value: "4973",
    label: "Universidad Autónoma de Zacatecas",
    website: "http://www.reduaz.mx/",
  },
  {
    value: "4974",
    label: "Universidad Autonoma España de Durango",
    website: "http://www.uaed.edu.mx/",
  },
  {
    value: "4975",
    label: "Universidad Autónoma Metropolitana",
    website: "http://www.uam.mx/",
  },
  {
    value: "4976",
    label: "Universidad Autónoma Metropolitana - Azcapotzalco",
    website: "http://www-azc.uam.mx/",
  },
  {
    value: "4977",
    label: "Universidad Autónoma Metropolitana - Iztapalapa",
    website: "http://www.iztapalapa.uam.mx/",
  },
  {
    value: "4978",
    label: "Universidad Autónoma Metropolitana - Xochimilco",
    website: "http://cueyatl.uam.mx/",
  },
  {
    value: "4979",
    label: "Universidad Champagnat",
    website: "http://www.champagnat.edu.mx/",
  },
  {
    value: "4980",
    label: "Universidad Chapultepéc",
    website: "http://www.univchapultepec.edu.mx/",
  },
  {
    value: "4981",
    label: "Universidad Cientifica Latinoamericana de Hidalgo",
    website: "http://www.uclah.edu.mx/",
  },
  {
    value: "4982",
    label: "Universidad Cristóbal Colón",
    website: "http://www.ver.ucc.mx/",
  },
  {
    value: "4983",
    label: "Universidad Cuauhtémoc",
    website: "http://www.cuauhtemoc.edu.mx/",
  },
  {
    value: "4984",
    label: "Universidad de Celaya",
    website: "http://www.udec.edu.mx/",
  },
  {
    value: "4985",
    label: "Universidad de Ciencias y Artes de Chiapas",
    website: "http://www.unicach.edu.mx/",
  },
  {
    value: "4986",
    label: "Universidad de Colima",
    website: "http://www.ucol.mx/",
  },
  {
    value: "4987",
    label: "Universidad de Comunicación Avanzada",
    website: "http://www.unica.edu.mx/",
  },
  {
    value: "4988",
    label: "Universidad de Cuautitlan Izcalli",
    website: "http://www.udeci.edu.mx/",
  },
  {
    value: "4989",
    label: "Universidad de Guadalajara",
    website: "http://www.udg.mx/",
  },
  {
    value: "4990",
    label: "Universidad de Guanajuato",
    website: "http://www.ugto.mx/",
  },
  {
    value: "4991",
    label: "Universidad de Guanajuato, Campus León",
    website: "http://www.campusleon.ugto.mx/",
  },
  {
    value: "4992",
    label: "Universidad de Hermosillo",
    website: "http://www.udeh.edu.mx/",
  },
  {
    value: "4993",
    label: "Universidad de La Salle, Bajío",
    website: "http://bajio.delasalle.edu.mx/",
  },
  {
    value: "4994",
    label: "Universidad de las Américas",
    website: "http://www.udla.mx/",
  },
  {
    value: "4995",
    label: "Universidad de las Américas - Puebla",
    website: "http://www.udlap.mx/",
  },
  {
    value: "4996",
    label: "Universidad de la Sierra A.C.",
    website: "http://www.usac.edu.mx/",
  },
  {
    value: "4997",
    label: "Universidad del Bajío",
    website: "http://www.ubac.edu.mx/",
  },
  {
    value: "4998",
    label: "Universidad del Centro de México",
    website: "http://www.ucem.edu.mx/",
  },
  {
    value: "4999",
    label: "Universidad de León",
    website: "http://www.universidaddeleon.edu.mx/",
  },
  {
    value: "5000",
    label: "Universidad del Mar",
    website: "http://www.umar.mx/",
  },
  {
    value: "5001",
    label: "Universidad del Mayab",
    website: "http://www.unimayab.edu.mx/",
  },
  {
    value: "5002",
    label: "Universidad del Noreste",
    website: "http://www.une.edu.mx/",
  },
  {
    value: "5003",
    label: "Universidad del Noroeste",
    website: "http://www.uno.mx/",
  },
  {
    value: "5004",
    label: "Universidad del Norte",
    website: "http://www.un.edu.mx/",
  },
  {
    value: "5005",
    label: "Universidad del Tepeyac",
    website: "http://www.tepeyac.edu.mx/",
  },
  {
    value: "5006",
    label: "Universidad del Valle de Atemajac",
    website: "http://www.univa.mx/",
  },
  {
    value: "5007",
    label: "Universidad del Valle del Bravo",
    website: "http://www.uvb.edu.mx/",
  },
  {
    value: "5008",
    label: "Universidad del Valle de México",
    website: "http://www.uvmnet.edu/",
  },
  {
    value: "5009",
    label: "Universidad del Valle de Puebla",
    website: "http://members.rotfl.com/uvp/",
  },
  {
    value: "5010",
    label: "Universidad del Valle de Toluca",
    website: "http://www.uvt.edu.mx/",
  },
  {
    value: "5011",
    label: "Universidad de Montemorelos",
    website: "http://www.umontemorelos.edu.mx/",
  },
  {
    value: "5012",
    label: "Universidad de Monterrey",
    website: "http://www.udem.edu.mx/",
  },
  {
    value: "5013",
    label: "Universidad de Norteamerica",
    website: "http://www.un.mx/",
  },
  {
    value: "5014",
    label: "Universidad de Occidente",
    website: "http://www.udo.mx/",
  },
  {
    value: "5015",
    label: "Universidad de Quintana Roo",
    website: "http://www.uqroo.mx/",
  },
  {
    value: "5016",
    label: "Universidad de San Miguel",
    website: "http://www.udesanmiguel.edu.mx/",
  },
  {
    value: "5017",
    label: "Universidad de Sonora",
    website: "http://www.uson.mx/",
  },
  {
    value: "5018",
    label: "Universidad Don Vasco",
    website: "http://www.udv.edu.mx/",
  },
  {
    value: "5019",
    label: "Universidad España de Durango",
    website: "http://www.unes.edu.mx/",
  },
  {
    value: "5020",
    label: "Universidad Franciscana de México",
    website: "http://www.ufm.edu.mx/",
  },
  {
    value: "5021",
    label: "Universidad Franco-Mexicana",
    website: "http://www.ufram.edu.mx/",
  },
  {
    value: "5022",
    label: "Universidad Fray Luca Paccioli",
    website: "http://www.frayluca.edu.mx/",
  },
  {
    value: "5023",
    label: "Universidad Hispanoamericana",
    website: "http://hispano.edu.mx/",
  },
  {
    value: "5024",
    label: "Universidad Iberoamericana",
    website: "http://www.uia.mx/",
  },
  {
    value: "5025",
    label: "Universidad Iberoamericana, Campus León",
    website: "http://www.leon.uia.mx/",
  },
  {
    value: "5026",
    label: "Universidad Insurgentes Plantel León",
    website: "http://www.uileon.edu.mx/",
  },
  {
    value: "5027",
    label: "Universidad Intercontinental",
    website: "http://www.uic.edu.mx/",
  },
  {
    value: "5028",
    label: "Universidad Itaca",
    website: "http://www.i.edu.mx/",
  },
  {
    value: "5029",
    label: "Universidad Jóse Vasconcelos",
    website: "http://www.univas.edu.mx/",
  },
  {
    value: "5030",
    label: "Universidad Juárez Autónoma de Tabasco",
    website: "http://www.ujat.mx/",
  },
  {
    value: "5031",
    label: "Universidad Juárez del Estado de Durango",
    website: "http://www.ujed.mx/",
  },
  {
    value: "5032",
    label: "Universidad Justo Sierra",
    website: "http://www.ujsierra.mx/",
  },
  {
    value: "5033",
    label: "Universidad Kino",
    website: "http://www.unikino.mx/",
  },
  {
    value: "5034",
    label: "Universidad la Concordia",
    website: "http://www.universidadlaconcordia.edu.mx/",
  },
  {
    value: "5035",
    label: "Universidad La Salle",
    website: "http://www.ulsa.edu.mx/",
  },
  {
    value: "5036",
    label: "Universidad La Salle Cuernavaca",
    website: "http://www.ulsac.edu.mx/",
  },
  {
    value: "5037",
    label: "Universidad Lasallista Benavente",
    website: "http://www.ulsab.edu.mx/",
  },
  {
    value: "5038",
    label: "Universidad Latina",
    website: "http://www.unla.edu.mx/",
  },
  {
    value: "5039",
    label: "Universidad Latinoamericana",
    website: "http://www.ula.edu.mx/",
  },
  {
    value: "5040",
    label: "Universidad Madero",
    website: "http://www.umad.edu.mx/",
  },
  {
    value: "5041",
    label: "Universidad Mesoamericana de San Agustín",
    website: "http://www.umsa.edu.mx/",
  },
  {
    value: "5042",
    label: "Universidad Metropolitana de Monterrey",
    website: "http://www.umm.edu.mx/",
  },
  {
    value: "5043",
    label: "Universidad Metropolitana Latin Campus",
    website: "http://www.umla.edu.mx/",
  },
  {
    value: "5044",
    label: "Universidad Mexicana",
    website: "http://www.unimex.edu.mx/",
  },
  {
    value: "5045",
    label: "Universidad Mexicana del Noreste",
    website: "http://www.umne.edu.mx/",
  },
  {
    value: "5046",
    label: "Universidad México Americana del Norte",
    website: "http://www.uman.edu.mx/",
  },
  {
    value: "5047",
    label: "Universidad México Internacional",
    website: "http://www.umi.edu.mx/",
  },
  {
    value: "5048",
    label: "Universidad Michoacana de San Nicolás de Hidalgo",
    website: "http://www.umich.mx/",
  },
  {
    value: "5049",
    label: "Universidad Motolinía del Pedegral",
    website: "http://www.universidadmotolinia.edu.mx/",
  },
  {
    value: "5050",
    label: "Universidad Motolinía del Pedregal",
    website: "http://www.motolinia.com.mx/",
  },
  {
    value: "5051",
    label: "Universidad Nacional Autónoma de México",
    website: "http://www.unam.mx/",
  },
  {
    value: "5052",
    label: "Universidad Nuevo Mundo",
    website: "http://www.unum.edu.mx/",
  },
  {
    value: "5053",
    label: "Universidad Obrera de Mexico",
    website: "http://www.uom.edu.mx/",
  },
  {
    value: "5054",
    label: "Universidad Olmeca",
    website: "http://www.olmeca.edu.mx/",
  },
  {
    value: "5055",
    label: "Universidad Panamericana",
    website: "http://www.up.mx/",
  },
  {
    value: "5056",
    label: "Universidad Pedagógica Nacional",
    website: "http://www.upn.mx/",
  },
  {
    value: "5057",
    label: "Universidad Pedagógica Nacional, Unidad León",
    website: "http://www.lef.upn.mx/",
  },
  {
    value: "5058",
    label: "Universidad Pedagógica Veracruzana",
    website: "http://www.secupv.org/",
  },
  {
    value: "5059",
    label: "Universidad Popular Autonóma del Estado de Puebla",
    website: "http://www.upaep.mx/",
  },
  {
    value: "5060",
    label: "Universidad Regiomontana",
    website: "http://www.ur.mx/",
  },
  {
    value: "5061",
    label: "Universidad Regional del Sureste",
    website: "http://www.urse.edu.mx/",
  },
  {
    value: "5062",
    label: "Universidad Regional Miguel Hidalgo",
    website: "http://www.urmh.edu.mx/",
  },
  {
    value: "5063",
    label: "Universidad Salesiana",
    website: "http://www.universidadsalesiana.edu.mx/",
  },
  {
    value: "5064",
    label: "Universidad Santa Fe",
    website: "http://www.usf.com.mx/",
  },
  {
    value: "5065",
    label: "Universidad Simón Bolivar",
    website: "http://www.usb.edu.mx/",
  },
  {
    value: "5066",
    label: "Universidad Tecnologica de la Mixteca",
    website: "http://www.utm.mx/",
  },
  {
    value: "5067",
    label: "Universidad Tecnológica de México",
    website: "http://www.unitec.mx/",
  },
  {
    value: "5068",
    label: "Universidad Tecnologica de Nezahualcoyotl",
    website: "http://www.utneza.edu.mx/",
  },
  {
    value: "5069",
    label: "Universidad Valle del Grijalva",
    website: "http://www.uvg1.net/",
  },
  {
    value: "5070",
    label: "Universidad Veracruzana",
    website: "http://www.uv.mx/",
  },
  {
    value: "5071",
    label: "Universidad Xicotepetl",
    website: "http://www.uxac.edu.mx/",
  },
  {
    value: "5072",
    label: "Westhill University",
    website: "http://www.westhill.edu.mx/",
  },
  {
    value: "5073",
    label: "Advance Tertiary College",
    website: "http://www.atc2u.com/",
  },
  {
    value: "5074",
    label: "Aimst University",
    website: "http://www.aimst.edu.my/",
  },
  {
    value: "5075",
    label: "Al-Bukhari International University",
    website: "http://www.aiu.edu.my/",
  },
  {
    value: "5076",
    label: "Allianze College of Medical Sciences (ACMS)",
    website: "http://www.acms.edu.my/",
  },
  {
    value: "5077",
    label: "Al Madinah International University",
    website: "http://www.mediu.edu.my/",
  },
  {
    value: "5078",
    label: "Asia E University",
    website: "http://www.aeu.edu.my/",
  },
  {
    value: "5079",
    label: "Asia Pacific Institute of Information Technology (APIIT)",
    website: "http://www.apiit.edu.my/",
  },
  {
    value: "5080",
    label: "Baitulmal Management Institute (IPB)",
    website: "http://www.ipb.edu.my/",
  },
  {
    value: "5081",
    label: "Binary University College of Managemant & Entrepreneurship",
    website: "http://www.binary.edu.my/",
  },
  {
    value: "5082",
    label: "Brickfields Asia College",
    website: "http://www.bac.edu.my/",
  },
  {
    value: "5083",
    label: "British Malaysian Institute",
    website: "http://www.bmi.edu.my/",
  },
  {
    value: "5084",
    label: "City University College of Science and Technology",
    website: "http://www.city.edu.my/",
  },
  {
    value: "5085",
    label: "Curtin University of Technology, Sarawak Campus",
    website: "http://www.curtin.edu.my/",
  },
  {
    value: "5086",
    label: "Cyberjaya University College of Medical Science",
    website: "http://www.cybermed.edu.my/",
  },
  {
    value: "5087",
    label: "Darul Hikmah Islamic College",
    website: "http://www.hikmah.edu.my/",
  },
  {
    value: "5088",
    label: "Darul Naim College of Technology",
    website: "http://www.ktd.edu.my/",
  },
  {
    value: "5089",
    label: "Darul Quran Islamic College University",
    website: "http://kudqi.net.my/",
  },
  {
    value: "5090",
    label: "Darul Takzim Institute of Technology",
    website: "http://www.instedt.edu.my/",
  },
  {
    value: "5091",
    label: "Darul Ulum Islamic College",
    website: "http://kidu-darululum.blogspot.com/",
  },
  {
    value: "5092",
    label: "FTMS Global Academy",
    website: "http://www.ftmsglobal.com/",
  },
  {
    value: "5093",
    label: "Help University College",
    website: "http://www.help.edu.my/",
  },
  {
    value: "5094",
    label: "Iact College",
    website: "http://www.iact.edu.my/",
  },
  {
    value: "5095",
    label: "Institute of Teachers Education, Batu Lintang",
    website: "http://www.ipbl.edu.my/",
  },
  {
    value: "5096",
    label: "Institute of Teachers Education, Darul Aman",
    website: "http://www.ipda.edu.my/",
  },
  {
    value: "5097",
    label: "Institute of Teachers Education, Dato' Razali Ismail ",
    website: "http://www.ipgmkdri.edu.my/",
  },
  {
    value: "5098",
    label: "Institute of Teachers Education, Ilmu Khas",
    website: "http://www.ipik.edu.my/",
  },
  {
    value: "5099",
    label: "Institute of Teachers Education, Ipoh",
    website: "http://www.ipip.edu.my/",
  },
  {
    value: "5100",
    label: "Institute of Teachers Education, Islamic Education",
    website: "http://www.ipislam.edu.my/",
  },
  {
    value: "5101",
    label: "Institute of Teachers Education, Keningau",
    website: "http://www.ipks.edu.my/",
  },
  {
    value: "5102",
    label: "Institute of Teachers Education, Kent",
    website: "http://www.ipkent.edu.my/",
  },
  {
    value: "5103",
    label: "Institute of Teachers Education, Kota Bharu",
    website: "http://www.ipgkkb.edu.my/",
  },
  {
    value: "5104",
    label: "Institute of Teachers Education, Malay Language",
    website: "http://www.ipbmm.edu.my/",
  },
  {
    value: "5105",
    label: "Institute of Teachers Education, Melaka ",
    website: "http://www.ippm.edu.my/",
  },
  {
    value: "5106",
    label: "Institute of Teachers Education, Penang",
    website: "http://www.i4p.edu.my/",
  },
  {
    value: "5107",
    label: "Institute of Teachers Education, Perlis",
    website: "http://www.ipgperlis.edu.my/",
  },
  {
    value: "5108",
    label: "Institute of Teachers Education, Perlis",
    website: "http://www.ipgperlis.edu.my/",
  },
  {
    value: "5109",
    label: "Institute of Teachers Education, Raja Melewar",
    website: "http://www.iprm.edu.my/",
  },
  {
    value: "5110",
    label: "Institute of Teachers Education, Rajang",
    website: "http://www.ipgkrajang.edu.my/",
  },
  {
    value: "5111",
    label: "Institute of Teachers Education, Sarawak",
    website: "http://www.ipsmiri.edu.my/",
  },
  {
    value: "5112",
    label: "Institute of Teachers Education, Sultan Abdul Halim",
    website: "http://www.ipsah.edu.my/",
  },
  {
    value: "5113",
    label: "Institute of Teachers Education, Sultan Mizan",
    website: "http://www.ipgmksm.edu.my/",
  },
  {
    value: "5114",
    label: "Institute of Teachers Education, Tawau",
    website: "http://www.ipgmtawau.edu.my/",
  },
  {
    value: "5115",
    label: "Institute of Teachers Education, Technical Education ",
    website: "http://www.ipteknik.edu.my/",
  },
  {
    value: "5116",
    label: "Institute of Teachers Education, Temenggong Ibrahim",
    website: "http://www.ipgkti.edu.my/",
  },
  {
    value: "5117",
    label: "Institute of Teachers Education, Tengku Ampuan Afzan",
    website: "http://www.iptaa.edu.my/",
  },
  {
    value: "5118",
    label: "Institute of Teachers Education, Tuanku Bainun",
    website: "http://www.iptb.edu.my/",
  },
  {
    value: "5119",
    label: "Institute of Teachers Education, Tun Hussein Onn",
    website: "http://www.iptho.edu.my/",
  },
  {
    value: "5120",
    label: "Institut Prima Bestari - Pine Academy ",
    website: "http://www.pine.edu.my/",
  },
  {
    value: "5121",
    label: "International Islamic College",
    website: "http://www.iic.edu.my/",
  },
  {
    value: "5122",
    label: "International Islamic College of Penang",
    website: "http://www.kitab.edu.my/",
  },
  {
    value: "5123",
    label: "International Islamic University",
    website: "http://www.iiu.edu.my/",
  },
  {
    value: "5124",
    label: "International Medical University",
    website: "http://www.imu.edu.my/",
  },
  {
    value: "5125",
    label: "International University College of Nursing (IUCN)",
    website: "http://www.iucn.edu.my/",
  },
  {
    value: "5126",
    label: "International University College of Technology Twintech (IUCTT)",
    website: "http://www.iuctt.edu.my/",
  },
  {
    value: "5127",
    label: "Islamic College for Sciences and Technologies",
    website: "http://www.kist.edu.my/",
  },
  {
    value: "5128",
    label: "Johore Bharu Primeir Polytechnic",
    website: "http://www.polijb.edu.my/",
  },
  {
    value: "5129",
    label: "KBU International College",
    website: "http://www.kbu.edu.my/",
  },
  {
    value: "5130",
    label: "KDU College Sdn Bhd",
    website: "http://www.kdu.edu.my/",
  },
  {
    value: "5131",
    label: "Kolej Universiti Insaniah",
    website: "http://www.kuin.edu.my/",
  },
  {
    value: "5132",
    label: "Kota Bharu Polytechnic",
    website: "http://www.pkb.edu.my/",
  },
  {
    value: "5133",
    label: "Kota Kinabalu Polytechnic",
    website: "http://www.pkksabah.edu.my/",
  },
  {
    value: "5134",
    label: "Kuala Lumpur Infrastructure University College",
    website: "http://www.kliuc.edu.my/",
  },
  {
    value: "5135",
    label: "Kuala Lumpur Metropolitan University",
    website: "http://www.klmu.edu.my/",
  },
  {
    value: "5136",
    label: "Kuala Terengganu City Polytechnic",
    website: "http://www.pkkt.edu.my/",
  },
  {
    value: "5137",
    label: "Kuching Polytechnic",
    website: "http://www.poliku.edu.my/",
  },
  {
    value: "5138",
    label: "Limkokwing University College of Creative Technology",
    website: "http://www.limkokwing.edu.my/",
  },
  {
    value: "5139",
    label: "Linton University College",
    website: "http://www.linton.edu.my/",
  },
  {
    value: "5140",
    label: "Mahsa University College for Health and Medical Science",
    website: "http://www.mahsa.edu.my/",
  },
  {
    value: "5141",
    label: "Malaysia University of Science and Technology (MUST)",
    website: "http://www.must.edu.my/",
  },
  {
    value: "5142",
    label: "Management and Science University",
    website: "http://www.msu.edu.my/",
  },
  {
    value: "5143",
    label: "Mara Poly-Tech College",
    website: "http://www.kptm.edu.my/",
  },
  {
    value: "5144",
    label: "Melaka City Polytechnic",
    website: "http://www.polimelaka.edu.my/",
  },
  {
    value: "5145",
    label: "Melaka Islamic University College",
    website: "http://www.kuim.edu.my/",
  },
  {
    value: "5146",
    label: "Merlimau Polytechnic",
    website: "http://www.pmm.edu.my/",
  },
  {
    value: "5147",
    label: "Monash University, Malaysia Campus",
    website: "http://www.monash.edu.my/",
  },
  {
    value: "5148",
    label: "Muadzam Shah Polytechnic",
    website: "http://www.polimuadzam.edu.my/",
  },
  {
    value: "5149",
    label: "Multimedia University",
    website: "http://www.mmu.edu.my/",
  },
  {
    value: "5150",
    label: "Murni Nursing College",
    website: "http://www.murni.edu.my/",
  },
  {
    value: "5151",
    label: "Newcastle University, Medicine Malaysia ",
    website: "http://numed.ncl.ac.uk/",
  },
  {
    value: "5152",
    label: "Nilai University College",
    website: "http://www.nilai.edu.my/",
  },
  {
    value: "5153",
    label: "Olympia College",
    website: "http://www.olympia.edu.my/",
  },
  {
    value: "5154",
    label: "Open University Malaysia",
    website: "http://www.oum.edu.my/",
  },
  {
    value: "5155",
    label: "Penang International Dental College",
    website: "http://www.pidc.edu.my/",
  },
  {
    value: "5156",
    label: "Perak Islamic College",
    website: "http://www.kiperak.edu.my/",
  },
  {
    value: "5157",
    label: "Perdana University",
    website: "http://www.perdanauniversity.edu.my/",
  },
  {
    value: "5158",
    label: "Perlis Islamic Higher Learning Institute",
    website: "http://www.iptips.edu.my/",
  },
  {
    value: "5159",
    label: "Petronas Technology University",
    website: "http://www.utp.edu.my/",
  },
  {
    value: "5160",
    label: "Port Dickson Polytechnic",
    website: "http://www.polipd.edu.my/",
  },
  {
    value: "5161",
    label: "Primier International University Perak",
    website: "http://www.piup.edu.my/",
  },
  {
    value: "5162",
    label: "PTPL College",
    website: "http://www.ptpl.edu.my/",
  },
  {
    value: "5163",
    label: "PTPL College",
    website: "http://www.ptpl.edu.my/",
  },
  {
    value: "5164",
    label: "Raffles University",
    website: "http://www.raffles-university.edu.my/",
  },
  {
    value: "5165",
    label: "Saito College",
    website: "http://www.saito.edu.my/",
  },
  {
    value: "5166",
    label: "Seberang Perai Polytechnic",
    website: "http://www.psp.edu.my/",
  },
  {
    value: "5167",
    label: "Segi University College",
    website: "http://www.segi.edu.my/",
  },
  {
    value: "5168",
    label: "Selangor Islamic University College",
    website: "http://www.kuis.edu.my/",
  },
  {
    value: "5169",
    label: "Shahputra College",
    website: "http://www.kolejshahputra.edu.my/",
  },
  {
    value: "5170",
    label: "Sultan Abdul Halim Muadzam Shah Polytechnic",
    website: "http://www.polimas.edu.my/",
  },
  {
    value: "5171",
    label: "Sultanah Bahiyah Polytechnic",
    website: "http://www.ptsb.edu.my/",
  },
  {
    value: "5172",
    label: "Sultan Ahmad Shah Islamic College",
    website: "http://www.kipsas.edu.my/",
  },
  {
    value: "5173",
    label: "Sultan Azlan Shah Polytechnic ",
    website: "http://www.psas.edu.my/",
  },
  {
    value: "5174",
    label: "Sultan Haji Ahmad Shah Polytechnic",
    website: "http://www.polisas.edu.my/",
  },
  {
    value: "5175",
    label: "Sultan Idris Shah Polytechnic",
    website: "http://www.psis.edu.my/",
  },
  {
    value: "5176",
    label: "Sultan Ismail Petra International Islamic College",
    website: "http://www.kias.edu.my/",
  },
  {
    value: "5177",
    label: "Sultan Mizan Zainal Abidin Polytechnic",
    website: "http://www.psmza.edu.my/",
  },
  {
    value: "5178",
    label: "Sultan Salahuddin Abdul Aziz Shah Polytechnic",
    website: "http://www.psa.edu.my/",
  },
  {
    value: "5179",
    label: "Sunway University College",
    website: "http://www.sunway.edu.my/",
  },
  {
    value: "5180",
    label: "Swinburne University of Technology, Sarawak Campus",
    website: "http://www.swinburne.edu.my/",
  },
  {
    value: "5181",
    label: "Taj International College",
    website: "http://www.taj.edu.my/",
  },
  {
    value: "5182",
    label: "Taylor's University College",
    website: "http://www.taylors.edu.my/",
  },
  {
    value: "5183",
    label: "TPM College",
    website: "http://www.tpmcollege.edu.my/",
  },
  {
    value: "5184",
    label: "Tunku Abdul Rahman Chinese College",
    website: "http://www.tarc.edu.my/",
  },
  {
    value: "5185",
    label: "Tunku Abdul Rahman University (Chinese University)",
    website: "http://www.utar.edu.my/",
  },
  {
    value: "5186",
    label: "Tunku Syed Sirajuddin Polytechnic",
    website: "http://www.ptss.edu.my/",
  },
  {
    value: "5187",
    label: "UCSI University",
    website: "http://www.ucsi.edu.my/",
  },
  {
    value: "5188",
    label: "Ungku Omar Premier Polytechnic",
    website: "http://www.puo.edu.my/",
  },
  {
    value: "5189",
    label: "Universiti Darul Iman",
    website: "http://www.udm.edu.my/",
  },
  {
    value: "5190",
    label: "Universiti Industri Selangor",
    website: "http://www.unisel.edu.my/",
  },
  {
    value: "5191",
    label: "Universiti Kebangsaan Malaysia",
    website: "http://www.ukm.my/",
  },
  {
    value: "5192",
    label: "Universiti Kuala Lumpur",
    website: "http://www.unikl.edu.my/",
  },
  {
    value: "5193",
    label:
      "Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)",
    website: "http://miit.unikl.edu.my/",
  },
  {
    value: "5194",
    label: "Universiti Malaya",
    website: "http://www.um.edu.my/",
  },
  {
    value: "5195",
    label: "Universiti Malaysia Kelantan",
    website: "http://www.umk.edu.my/",
  },
  {
    value: "5196",
    label: "Universiti Malaysia Perlis",
    website: "http://www.unimap.edu.my/",
  },
  {
    value: "5197",
    label: "Universiti Malaysia Sabah",
    website: "http://www.ums.edu.my/",
  },
  {
    value: "5198",
    label: "Universiti Malaysia Sarawak",
    website: "http://www.unimas.my/",
  },
  {
    value: "5199",
    label: "Universiti Malaysia Terengganu",
    website: "http://www.umt.edu.my/",
  },
  {
    value: "5200",
    label: "Universiti Pendidikan Sultan Idris",
    website: "http://www.upsi.edu.my/",
  },
  {
    value: "5201",
    label: "Universiti Putra Malaysia",
    website: "http://www.upm.edu.my/",
  },
  {
    value: "5202",
    label: "Universiti Sains Malaysia",
    website: "http://www.usm.my/",
  },
  {
    value: "5203",
    label: "Universiti Sultan Zainal Abidin",
    website: "http://www.unisza.edu.my/",
  },
  {
    value: "5204",
    label: "Universiti Teknikal Malaysia Melaka",
    website: "http://www.utem.edu.my/",
  },
  {
    value: "5205",
    label: "Universiti Teknologi Malaysia",
    website: "http://www.utm.my/",
  },
  {
    value: "5206",
    label: "Universiti Teknologi Mara",
    website: "http://www.uitm.edu.my/",
  },
  {
    value: "5207",
    label: "Universiti Teknologi Petronas",
    website: "http://www.utp.edu.my/",
  },
  {
    value: "5208",
    label: "Universiti Tenaga Nasional",
    website: "http://www.uniten.edu.my/",
  },
  {
    value: "5209",
    label: "Universiti Tun Abdul Razak",
    website: "http://www.unitar.edu.my/",
  },
  {
    value: "5210",
    label: "Universiti Tun Hussein Onn Malaysia",
    website: "http://www.uthm.edu.my/",
  },
  {
    value: "5211",
    label: "Universiti Tunku Abdul Rahman",
    website: "http://www.utar.edu.my/",
  },
  {
    value: "5212",
    label: "Universiti Utara Malaysia",
    website: "http://www.uum.edu.my/",
  },
  {
    value: "5213",
    label: "University College of Technology & Innovation (UCTI)",
    website: "http://www.ucti.edu.my/",
  },
  {
    value: "5214",
    label: "University Malaysia Pahang",
    website: "http://www.ump.edu.my/",
  },
  {
    value: "5215",
    label: "University of Management and Technology ",
    website: "http://www.umtech.edu.my/",
  },
  {
    value: "5216",
    label: "University of Nottingham, Malaysia Campus",
    website: "http://www.nottingham.edu.my/",
  },
  {
    value: "5217",
    label: "University Tun Abdul Razak",
    website: "http://www.unirazak.edu.my/",
  },
  {
    value: "5218",
    label: "Wawasan Open University",
    website: "http://www.wou.edu.my/",
  },
  {
    value: "5219",
    label: "West Minster International College",
    website: "http://www.westminster.edu.my/",
  },
  {
    value: "5220",
    label: "YPC-iTWEB College",
    website: "http://www.kolejypc.edu.my/",
  },
  {
    value: "5221",
    label: "Instituto Superior de Ciênicas e Tecnologia de Moçambique",
    website: "http://www.isctem.ac.mz/",
  },
  {
    value: "5222",
    label: "Instituto Superior de Relações Internacionais (ISRI) ",
    website: "http://www.isri.ac.mz/",
  },
  {
    value: "5223",
    label: "Instituto Superior Politécnico e Universitário",
    website: "http://www.ispu.ac.mz/",
  },
  {
    value: "5224",
    label: "Universidade Católica de Moçambique",
    website: "http://www.ucm.ac.mz/",
  },
  {
    value: "5225",
    label: "Universidade Eduardo Mondlane",
    website: "http://www.uem.mz/",
  },
  {
    value: "5226",
    label: "Ilsa Independent College",
    website: "http://www.ilsacollege.edu.na/",
  },
  {
    value: "5227",
    label: "Institute of Information Technology",
    website: "http://www.iit.com.na/",
  },
  {
    value: "5228",
    label: "International University of Management",
    website: "http://www.ium.edu.na/",
  },
  {
    value: "5229",
    label: "Polytechnic of Namibia",
    website: "http://www.polytechnic.edu.na/",
  },
  {
    value: "5230",
    label: "University of Namibia",
    website: "http://www.unam.na/",
  },
  {
    value: "5231",
    label: "University of New Caledonia",
    website: "http://www.ufp.nc/",
  },
  {
    value: "5232",
    label: "Université Abdou Moumouni de Niamey",
    website: "http://uam.refer.ne/",
  },
  {
    value: "5233",
    label: "Abia State Polytechnic",
    website: "http://www.abiapoly.edu.ng/",
  },
  {
    value: "5234",
    label: "Abia State University",
    website: "http://www.absu.edu.ng/",
  },
  {
    value: "5235",
    label: "ABTI American University of Nigeria",
    website: "http://www.aun.edu.ng/",
  },
  {
    value: "5236",
    label: "Abubakar Tafawa Balewa University",
    website: "http://www.atbu.edu.ng/",
  },
  {
    value: "5237",
    label: "Adamawa State University",
    website: "http://www.adsu.edu.ng/",
  },
  {
    value: "5238",
    label: "Adekunle Ajasin University",
    website: "http://www.aaua.edu.ng/",
  },
  {
    value: "5239",
    label: "Adeleke University",
    website: "http://www.adelekeuniversity.edu.ng/",
  },
  {
    value: "5240",
    label: "Adeyemi College of Education",
    website: "http://www.aceondo-ng.com/",
  },
  {
    value: "5241",
    label: "Afe Babalola University",
    website: "http://www.abuad.edu.ng/",
  },
  {
    value: "5242",
    label: "African University of Science and Technology",
    website: "http://www.aust-abuja.org/",
  },
  {
    value: "5243",
    label: "Ahmadu Bello University",
    website: "http://www.abu.edu.ng/",
  },
  {
    value: "5244",
    label: "Ajayi Crowther University",
    website: "http://www.acu.edu.ng/",
  },
  {
    value: "5245",
    label: " Akanu Ibiam Federal Polytechnic, Unwana",
    website: "http://www.polyunwana.net/",
  },
  {
    value: "5246",
    label: "Akwa Ibom State University of Technology",
    website: "http://www.akutech.edu.ng/",
  },
  {
    value: "5247",
    label: "Al-hikmah University",
    website: "http://www.alhikmah.edu.ng/",
  },
  {
    value: "5248",
    label: "Ambrose Alli University",
    website: "http://www.aauekpoma.edu.ng/",
  },
  {
    value: "5249",
    label: "Anambra State University of Science and Technology",
    website: "http://www.ansu.edu.ng/",
  },
  {
    value: "5250",
    label: "Auchi Polytechnic",
    website: "http://www.auchipoly.edu.ng/",
  },
  {
    value: "5251",
    label: "Babcock University",
    website: "http://www.babcockuni.edu.ng/",
  },
  {
    value: "5252",
    label: "Bauchi State University, Gadau",
    website: "http://www.basug.edu.ng/",
  },
  {
    value: "5253",
    label: "Bayero University Kano",
    website: "http://www.buk.edu.ng/",
  },
  {
    value: "5254",
    label: "Baze University Abuja",
    website: "http://www.bazeuniversity.edu.ng",
  },
  {
    value: "5255",
    label: "Bells University of Technology",
    website: "http://www.bellsuniversity.org/",
  },
  {
    value: "5256",
    label: "Benson Idahosa University",
    website: "http://www.biu.edu.ng/",
  },
  {
    value: "5257",
    label: "Benue State University",
    website: "http://www.bsum.edu.ng/",
  },
  {
    value: "5258",
    label: "Bingham University",
    website: "http://www.binghamuni.edu.ng/",
  },
  {
    value: "5259",
    label: "Bowen University",
    website: "http://bowenuniversity-edu.org/",
  },
  {
    value: "5260",
    label: "Caleb University",
    website: "http://www.calebuniversity.edu.ng/",
  },
  {
    value: "5261",
    label: "Caritas University",
    website: "http://www.caritasuni.edu.ng/",
  },
  {
    value: "5262",
    label: "College of Education Ikere",
    website: "http://www.coeikere.edu.ng/",
  },
  {
    value: "5263",
    label: "College of Education Oju",
    website: "http://www.coeoju.com/",
  },
  {
    value: "5264",
    label: "Covenant University",
    website: "http://www.covenantuniversity.edu.ng/",
  },
  {
    value: "5265",
    label: "Crawford University ",
    website: "http://www.crawforduniversity.edu.ng/",
  },
  {
    value: "5266",
    label: "Crescent University",
    website: "http://www.crescent-university.edu.ng/",
  },
  {
    value: "5267",
    label: "Cross River University of Science and Technology",
    website: "http://www.crutech.edu.ng/",
  },
  {
    value: "5268",
    label: "Ebonyi State University",
    website: "http://www.ebsu-edu.net/",
  },
  {
    value: "5269",
    label: "Ekiti State University",
    website: "http://www.eksu.edu.ng/",
  },
  {
    value: "5270",
    label: "Enugu State University of Science and Technology",
    website: "http://www.esut.edu.ng/",
  },
  {
    value: "5271",
    label: "Federal College Of Education (Technical), Akoka",
    website: "http://www.fcetakoka-edu.net/",
  },
  {
    value: "5272",
    label: "Federal Polytechnic Bauchi, Nigeria",
    website: "http://fptb.edu.ng/",
  },
  {
    value: "5273",
    label: "Federal University of Petroleum Resources",
    website: "http://www.fupre.edu.ng/",
  },
  {
    value: "5274",
    label: "Federal University of Technology, Akure",
    website: "http://www.futa.edu.ng/",
  },
  {
    value: "5275",
    label: "Federal University of Technology, Minna",
    website: "http://www.futminna.edu.ng/",
  },
  {
    value: "5276",
    label: "Federal University of Technology, Owerri",
    website: "http://www.futo.edu.ng/",
  },
  {
    value: "5277",
    label: "Federal University of Technology, Yola",
    website: "http://www.futy.edu.ng/",
  },
  {
    value: "5278",
    label: "Fountain University",
    website: "http://www.fountainuniversity.edu.ng/",
  },
  {
    value: "5279",
    label: "Godfrey Okoye University",
    website: "http://www.go-uni-enugu.net/",
  },
  {
    value: "5280",
    label: "Godfrey Okoye University ",
    website: "http://www.gouni.edu.ng/",
  },
  {
    value: "5281",
    label: "Gombe State University",
    website: "http://www.gsu.edu.ng/",
  },
  {
    value: "5282",
    label: "Ibrahim Babangida University",
    website: "http://www.ibbu.edu.ng/",
  },
  {
    value: "5283",
    label: "Igbinedion University",
    website: "http://www.iuokada.edu.ng/",
  },
  {
    value: "5284",
    label: "Imo State University",
    website: "http://www.imsu.edu.ng/",
  },
  {
    value: "5285",
    label: "Joseph Ayo Babalola University",
    website: "http://www.jabu.edu.ng/",
  },
  {
    value: "5286",
    label: "Kaduna State University",
    website: "http://www.kasuportal.net/",
  },
  {
    value: "5287",
    label: "Kano State University of Technology Wudil",
    website: "http://www.kustwudil.edu.ng/",
  },
  {
    value: "5288",
    label: "Katsina University",
    website: "http://www.kuk.edu.ng/",
  },
  {
    value: "5289",
    label: "Kebbi State University of Science and Technology",
    website: "http://www.ksusta.edu.ng/",
  },
  {
    value: "5290",
    label: "Kogi State University",
    website: "http://www.ksuportal.edu.ng/",
  },
  {
    value: "5291",
    label: "Kwararafa University",
    website: "http://www.kwararafauniversity.edu.ng/",
  },
  {
    value: "5292",
    label: "Kwara State Polytecnic",
    website: "http://www.kwarapoly.edu.ng/",
  },
  {
    value: "5293",
    label: "Kwara State University ",
    website: "http://www.kwasu.edu.ng/",
  },
  {
    value: "5294",
    label: "Ladoke Akintola University of Technology",
    website: "http://www.lautech.edu.ng/",
  },
  {
    value: "5295",
    label: "Lagos City Polytechnic",
    website: "http://www.mylcp.net/",
  },
  {
    value: "5296",
    label: "Lagos State Polytechnic",
    website: "http://www.laspotech.net/",
  },
  {
    value: "5297",
    label: "Lagos State University",
    website: "http://www.lasunigeria.org/",
  },
  {
    value: "5298",
    label: "Landmark University",
    website: "http://www.lmu.edu.ng/",
  },
  {
    value: "5299",
    label: "Lead City University of Ibadan",
    website: "http://www.lcu.edu.ng/",
  },
  {
    value: "5300",
    label: "Madonna University",
    website: "http://www.madonnauniversity.edu.ng/",
  },
  {
    value: "5301",
    label: "Michael Okpara University of Agriculture",
    website: "http://www.mouau.edu.ng/",
  },
  {
    value: "5302",
    label: "Nasarawa State University Keffi",
    website: "http://www.nsuk.edu.ng/",
  },
  {
    value: "5303",
    label: "National Open University of Nigeria",
    website: "http://www.nou.edu.ng/",
  },
  {
    value: "5304",
    label: "Niger Delta University",
    website: "http://www.ndu.edu.ng/",
  },
  {
    value: "5305",
    label: "Nigerian Defence Academy",
    website: "http://www.nda.edu.ng/",
  },
  {
    value: "5306",
    label: "Nigerian Turkish Nile University",
    website: "http://www.ntnu.edu.ng/",
  },
  {
    value: "5307",
    label: "Nnamdi Azikiwe University",
    website: "http://www.unizik.edu.ng/",
  },
  {
    value: "5308",
    label: "Novena University",
    website: "http://www.novenauniversity.edu.ng/",
  },
  {
    value: "5309",
    label: "Obafemi Awolowo University Ile-Ife",
    website: "http://www.oauife.edu.ng/",
  },
  {
    value: "5310",
    label: "Obong University",
    website: "http://www.obonguniversity.net/",
  },
  {
    value: "5311",
    label: "Oduduwa University",
    website: "http://www.oduduwauniversity.edu.ng/",
  },
  {
    value: "5312",
    label: "Olabisi Onabanjo University ",
    website: "http://www.oouagoiwoye.edu.ng/",
  },
  {
    value: "5313",
    label: "Ondo State University of Science and Technology",
    website: "http://www.osustech.edu.ng/",
  },
  {
    value: "5314",
    label: "Osun State University",
    website: "http://www.uniosun.edu.ng/",
  },
  {
    value: "5315",
    label: "Pan-African University",
    website: "http://www.pau.edu.ng/",
  },
  {
    value: "5316",
    label: "Paul University",
    website: "http://www.pauluniversity.edu.ng/",
  },
  {
    value: "5317",
    label: "Polytechnic Ibadan",
    website: "http://www.polyibadan.edu.ng/",
  },
  {
    value: "5318",
    label: "Redeemers University",
    website: "http://www.run.edu.ng/",
  },
  {
    value: "5319",
    label: "Renaissance University",
    website: "http://www.rnu.edu.ng/",
  },
  {
    value: "5320",
    label: "Rivers State University of Science and Technology",
    website: "http://www.ust.edu.ng/",
  },
  {
    value: "5321",
    label: "Salem University",
    website: "http://www.salemuniversity.edu.ng/",
  },
  {
    value: "5322",
    label: "Sokoto State University ",
    website: "http://www.ssu.edu.ng/",
  },
  {
    value: "5323",
    label: "Tai Solarin University of Education",
    website: "http://www.tasued.edu.ng/",
  },
  {
    value: "5324",
    label: "Tansian University",
    website: "http://www.tansianuniversity.edu.ng/",
  },
  {
    value: "5325",
    label: "Taraba State University",
    website: "http://www.tsuniversity.edu.ng/",
  },
  {
    value: "5326",
    label: "The Achievers University",
    website: "http://www.achievers.edu.ng/",
  },
  {
    value: "5327",
    label: "The Federal Polytechnic Offa",
    website: "http://www.fedpoffa.edu.ng/",
  },
  {
    value: "5328",
    label: "Umaru Musa Yar'Adua University ",
    website: "http://www.umyu.edu.ng/",
  },
  {
    value: "5329",
    label: "University of Abuja",
    website: "http://www.unibuja.edu.ng/",
  },
  {
    value: "5330",
    label: "University of Agriculture Abeokuta",
    website: "http://www.unaab.edu.ng/",
  },
  {
    value: "5331",
    label: "University of Agriculture Makurdi",
    website: "http://www.uam.edu.ng/",
  },
  {
    value: "5332",
    label: "University of Benin",
    website: "http://www.uniben.edu/",
  },
  {
    value: "5333",
    label: "University of Calabar",
    website: "http://www.unical.edu.ng/",
  },
  {
    value: "5334",
    label: "University of Ibadan",
    website: "http://www.ui.edu.ng/",
  },
  {
    value: "5335",
    label: "University of Ilorin",
    website: "http://www.unilorin.edu.ng/",
  },
  {
    value: "5336",
    label: "University of Jos",
    website: "http://www.unijos.edu.ng/",
  },
  {
    value: "5337",
    label: "University of Lagos",
    website: "http://www.unilag.edu.ng/",
  },
  {
    value: "5338",
    label: "University of Maiduguri",
    website: "http://www.unimaid.edu.ng/",
  },
  {
    value: "5339",
    label: "University of Mkar",
    website: "http://www.unimkar.edu.ng/",
  },
  {
    value: "5340",
    label: "University of Nigeria",
    website: "http://www.unn.edu.ng/",
  },
  {
    value: "5341",
    label: "University of Portharcourt",
    website: "http://www.uniport.edu.ng/",
  },
  {
    value: "5342",
    label: "University of Uyo",
    website: "http://www.uniuyo.edu.ng/",
  },
  {
    value: "5343",
    label: "Usmanu Danfodiyo University Sokoto",
    website: "http://www.udusok.edu.ng/",
  },
  {
    value: "5344",
    label: "Veritas University",
    website: "http://www.veritas.edu.ng/",
  },
  {
    value: "5345",
    label: "Wellspring University",
    website: "http://www.wellspringuniversity.edu.ng/",
  },
  {
    value: "5346",
    label: "Wesley University of Science and Technology",
    website: "http://www.wustoportal.edu.ng/",
  },
  {
    value: "5347",
    label: "Western Delta University",
    website: "http://www.wdu.edu.ng/",
  },
  {
    value: "5348",
    label: "Yaba College of Technology",
    website: "http://www.yabatech.edu.ng/",
  },
  {
    value: "5349",
    label: "Universidad Americana",
    website: "http://www.uam.edu.ni/",
  },
  {
    value: "5350",
    label: 'Universidad Catolica "Redemptoris Mater"',
    website: "http://www.unica.edu.ni/",
  },
  {
    value: "5351",
    label: "Universidad Central de Nicaragua",
    website: "http://www.ucn.edu.ni/",
  },
  {
    value: "5352",
    label: "Universidad Centroamericana",
    website: "http://www.uca.edu.ni/",
  },
  {
    value: "5353",
    label: "Universidad Centroamericana de Ciencias Empresariales (UCEM)",
    website: "http://www.ucem.edu.ni/",
  },
  {
    value: "5354",
    label: "Universidad Ciencias Comerciales",
    website: "http://www.ucc.edu.ni/",
  },
  {
    value: "5355",
    label: "Universidad de las Américas (ULAM)",
    website: "http://www.ulam.edu.ni/",
  },
  {
    value: "5356",
    label:
      "Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense",
    website: "http://www.uraccan.edu.ni/",
  },
  {
    value: "5357",
    label: "Universidad de Managua (U de M)",
    website: "http://www.udem.edu.ni/",
  },
  {
    value: "5358",
    label: "Universidad Hispanoamericana",
    website: "http://www.uhispam.edu.ni/",
  },
  {
    value: "5359",
    label: "Universidad Iberoamericana de Ciencia y Tecnologia",
    website: "http://www.unicit.edu.ni/",
  },
  {
    value: "5360",
    label: "Universidad Internacional de Integración de América Latina",
    website: "http://www.unival.edu.ni/",
  },
  {
    value: "5361",
    label: "Universidad Nacional Agraria",
    website: "http://www.una.edu.ni/",
  },
  {
    value: "5362",
    label: "Universidad Nacional Autonoma de Nicaragua",
    website: "http://www.unan.edu.ni/",
  },
  {
    value: "5363",
    label: "Universidad Nacional de Ingenieria",
    website: "http://www.uni.edu.ni/",
  },
  {
    value: "5364",
    label: "Universidad Nicaragüense de Ciencia y Tecnológica",
    website: "http://www.ucyt.edu.ni/",
  },
  {
    value: "5365",
    label: "Universidad Politecnica de Nicaragua",
    website: "http://www.upoli.edu.ni/",
  },
  {
    value: "5366",
    label: "Universidad Popular de Nicaragua (UPONIC)",
    website: "http://www.uponic.edu.ni/",
  },
  {
    value: "5367",
    label: "Christelijke Hogeschool Windesheim",
    website: "http://www.windesheim.nl/",
  },
  {
    value: "5368",
    label: "De Haagse Hogeschool",
    website: "http://www.dehaagsehogeschool.nl/",
  },
  {
    value: "5369",
    label: "Delft University of Technology",
    website: "http://www.tudelft.nl/",
  },
  {
    value: "5370",
    label: "Dutch Delta University",
    website: "http://www.ddu.nl/",
  },
  {
    value: "5371",
    label: "Eindhoven University of Technology",
    website: "http://www.tue.nl/",
  },
  {
    value: "5372",
    label: "Erasmus University Rotterdam",
    website: "http://www.eur.nl/",
  },
  {
    value: "5373",
    label: "European Open University",
    website: "http://www.europeanopenuniversity.com/",
  },
  {
    value: "5374",
    label: "Fontys University of Applied Sciences",
    website: "http://www.fontys.edu/",
  },
  {
    value: "5375",
    label: "Foundation University",
    website: "http://www.foundationuniversity.com/",
  },
  {
    value: "5376",
    label: "Free University Amsterdam",
    website: "http://www.vu.nl/",
  },
  {
    value: "5377",
    label: "Hanzehogeschool Groningen",
    website: "http://www.hanze.nl/",
  },
  {
    value: "5378",
    label: "Hogeschool Inholland",
    website: "http://www.inholland.nl/",
  },
  {
    value: "5379",
    label: "Hogeschool Leiden",
    website: "http://www.hsleiden.nl/",
  },
  {
    value: "5380",
    label: "Hogeschool Rotterdam",
    website: "http://www.hro.nl/",
  },
  {
    value: "5381",
    label: "Hogeschool Utrecht",
    website: "http://www.hu.nl/",
  },
  {
    value: "5382",
    label: "Hogeschool van Amsterdam",
    website: "http://www.hva.nl/",
  },
  {
    value: "5383",
    label: "Hogeschool voor de Kunsten Utrecht (HKU)",
    website: "http://www.hku.nl/",
  },
  {
    value: "5384",
    label: "Hotelschool The Hague",
    website: "http://www.hotelschool.nl/",
  },
  {
    value: "5385",
    label: "Institute of Social Studies",
    website: "http://www.iss.nl/",
  },
  {
    value: "5386",
    label:
      "International Institute for Geo - Information Science and Earth Observation",
    website: "http://www.itc.nl/",
  },
  {
    value: "5387",
    label: "Islamic University of Rotterdam",
    website: "http://www.islamicuniversity.nl/",
  },
  {
    value: "5388",
    label: "Leiden University",
    website: "http://www.leidenuniv.nl/",
  },
  {
    value: "5389",
    label: "NHL University of Applied Sciences",
    website: "http://www.nhl.nl/",
  },
  {
    value: "5390",
    label: "NHTV Breda University of Professional Education",
    website: "http://www.nhtv.nl",
  },
  {
    value: "5391",
    label: "NTI University",
    website: "http://www.nti.nl/",
  },
  {
    value: "5392",
    label: "Open University of the Netherlands",
    website: "http://www.ou.nl/",
  },
  {
    value: "5393",
    label: "Radboud University of Nijmegen",
    website: "http://www.ru.nl/",
  },
  {
    value: "5394",
    label: "Roosevelt Academy University College",
    website: "http://www.roac.nl/",
  },
  {
    value: "5395",
    label: "Saxion Universities ",
    website: "http://saxion.edu/",
  },
  {
    value: "5396",
    label: "Stenden University",
    website: "http://www.stenden.com/",
  },
  {
    value: "5397",
    label: "Tilburg University",
    website: "http://www.tilburguniversity.nl/",
  },
  {
    value: "5398",
    label: "UNESCO-IHE Institute for Water Education",
    website: "http://www.unesco-ihe.org/",
  },
  {
    value: "5399",
    label: "Universiteit Doesburg (UNDO)",
    website: "http://www.universiteitdoesburg.nl/",
  },
  {
    value: "5400",
    label: "University for Humanistics (UH)",
    website: "http://www.uvh.nl/",
  },
  {
    value: "5401",
    label: "University of Amsterdam",
    website: "http://www.uva.nl/",
  },
  {
    value: "5402",
    label: "University of Groningen",
    website: "http://www.rug.nl/",
  },
  {
    value: "5403",
    label: "University of Maastricht",
    website: "http://www.unimaas.nl/",
  },
  {
    value: "5404",
    label: "University of Nijenrode",
    website: "http://www.nijenrode.nl/",
  },
  {
    value: "5405",
    label: "University of Twente",
    website: "http://www.utwente.nl/",
  },
  {
    value: "5406",
    label: "Utrecht University",
    website: "http://www.uu.nl/",
  },
  {
    value: "5407",
    label: "Wageningen University",
    website: "http://www.wageningenuniversity.nl/",
  },
  {
    value: "5408",
    label: "Wittenborg University",
    website: "http://www.wittenborg.eu/",
  },
  {
    value: "5409",
    label: "Zuyd University",
    website: "http://www.zuyd.nl/",
  },
  {
    value: "5410",
    label: "Agder University College",
    website: "http://www.hia.no/",
  },
  {
    value: "5411",
    label: "Agricultural University of Norway",
    website: "http://www.nlh.no/",
  },
  {
    value: "5412",
    label: "Bergen University College",
    website: "http://www.hib.no/",
  },
  {
    value: "5413",
    label: "Bodo Regional University",
    website: "http://www.hibo.no/",
  },
  {
    value: "5414",
    label: "Finnmark University College",
    website: "http://www.hifm.no/",
  },
  {
    value: "5415",
    label: "Lillehammer University College",
    website: "http://www.hil.no/",
  },
  {
    value: "5416",
    label: "Molde University College",
    website: "http://www.himolde.no/",
  },
  {
    value: "5417",
    label: "National Academy of Fine Arts",
    website: "http://ska.khio.no/main",
  },
  {
    value: "5418",
    label: "National College of Art and Design",
    website: "http://www.khio.no/shks/",
  },
  {
    value: "5419",
    label: "Norway's Information Technology University College",
    website: "http://www.nith.no/",
  },
  {
    value: "5420",
    label: "Norwegian College of Veterinary Medicine",
    website: "http://www.veths.no/",
  },
  {
    value: "5421",
    label: "Norwegian School of Economics and Business Administration",
    website: "http://www.nhh.no/",
  },
  {
    value: "5422",
    label: "Norwegian School of Management BI",
    website: "http://www.bi.no/",
  },
  {
    value: "5423",
    label: "Norwegian State Academy of Music",
    website: "http://www.nmh.no/",
  },
  {
    value: "5424",
    label: "Norwegian Teacher Academy for Studies in Religion and Education",
    website: "http://www.nla.no/",
  },
  {
    value: "5425",
    label: "Norwegian University of Life Sciences",
    website: "http://www.umb.no/",
  },
  {
    value: "5426",
    label: "Norwegian University of Science and Technology",
    website: "http://www.ntnu.no/",
  },
  {
    value: "5427",
    label: "Norwegian University of Sport and Physical Education",
    website: "http://www.nih.no/",
  },
  {
    value: "5428",
    label: "Oslo School of Architecture",
    website: "http://www.aho.no/",
  },
  {
    value: "5429",
    label: "University of Bergen",
    website: "http://www.uib.no/",
  },
  {
    value: "5430",
    label: "University of Oslo",
    website: "http://www.uio.no/",
  },
  {
    value: "5431",
    label: "University of Stavanger",
    website: "http://www.uis.no/",
  },
  {
    value: "5432",
    label: "University of Tromsø",
    website: "http://www.uit.no/",
  },
  {
    value: "5433",
    label: "Agriculture and Forestry University",
    website: "http://www.afu.edu.np/",
  },
  {
    value: "5434",
    label: "B.P.Koirala Institute of Health Sciences",
    website: "http://www.bpkihs.edu/",
  },
  {
    value: "5435",
    label: "Kathmandu University",
    website: "http://www.ku.edu.np/",
  },
  {
    value: "5436",
    label: "Lumbini Buddhist University",
    website: "http://www.lbu.edu.np/",
  },
  {
    value: "5437",
    label: "Nepal Sanskrit University",
    website: "http://www.nsu.edu.np/",
  },
  {
    value: "5438",
    label: "Pokhara University",
    website: "http://www.pu.edu.np/",
  },
  {
    value: "5439",
    label: "Purbanchal University",
    website: "http://www.purbuniv.edu.np/",
  },
  {
    value: "5440",
    label: "Tribhuvan University Kathmandu",
    website: "http://www.tribhuvan-university.edu.np/",
  },
  {
    value: "5441",
    label: "St Clements University - Higher Education School",
    website: "http://www.stclements.edu.nu/",
  },
  {
    value: "5442",
    label: "Auckland University of Technology",
    website: "http://www.aut.ac.nz/",
  },
  {
    value: "5443",
    label: "Christchurch Polytechnic Institute of Technology",
    website: "http://www.cpit.ac.nz/",
  },
  {
    value: "5444",
    label: "Lincoln University",
    website: "http://www.lincoln.ac.nz/",
  },
  {
    value: "5445",
    label: "Massey University",
    website: "http://www.massey.ac.nz/",
  },
  {
    value: "5446",
    label: "Open Polytechnic ",
    website: "http://www.openpolytechnic.ac.nz/",
  },
  {
    value: "5447",
    label: "University of Auckland",
    website: "http://www.auckland.ac.nz/",
  },
  {
    value: "5448",
    label: "University of Canterbury",
    website: "http://www.canterbury.ac.nz/",
  },
  {
    value: "5449",
    label: "University of Otago",
    website: "http://www.otago.ac.nz/",
  },
  {
    value: "5450",
    label: "University of Waikato",
    website: "http://www.waikato.ac.nz/",
  },
  {
    value: "5451",
    label: "Victoria University of Wellington",
    website: "http://www.vuw.ac.nz/",
  },
  {
    value: "5452",
    label: "Al-Buraimi University College",
    website: "http://www.buc.edu.om/",
  },
  {
    value: "5453",
    label: "Arab Open University",
    website: "http://www.aou.edu.om/",
  },
  {
    value: "5454",
    label: "Dhofar University",
    website: "http://www.du.edu.om/",
  },
  {
    value: "5455",
    label: "Ibra College of Technology",
    website: "http://www.ict.edu.om/",
  },
  {
    value: "5456",
    label: "Majan University College",
    website: "http://www.majancollege.edu.om/",
  },
  {
    value: "5457",
    label: "Muscat College",
    website: "http://www.muscatcollege.edu.om/",
  },
  {
    value: "5458",
    label: "Oman College Of Management & Technology",
    website: "http://www.omancollege.edu.om/",
  },
  {
    value: "5459",
    label: "Sohar University",
    website: "http://www.soharuni.edu.om/",
  },
  {
    value: "5460",
    label: "Sultan Qaboos University",
    website: "http://www.squ.edu.om/",
  },
  {
    value: "5461",
    label: "Sur University College",
    website: "http://www.suc.edu.om/",
  },
  {
    value: "5462",
    label: "University of Nizwa",
    website: "http://www.unizwa.edu.om/",
  },
  {
    value: "5463",
    label: "Columbus University",
    website: "http://www.columbus.edu/",
  },
  {
    value: "5464",
    label: "Floret Global University",
    website: "http://www.floret.edu.pa/",
  },
  {
    value: "5465",
    label: "Instituto de Enseñanza Superior Oteima",
    website: "http://www.institutoteima.ac.pa/",
  },
  {
    value: "5466",
    label: "Universidad Abierta y a Distancia de Panama",
    website: "http://www.unadp.ac.pa/",
  },
  {
    value: "5467",
    label: "Universidad Autonoma de Chiriqui",
    website: "http://www.unachi.ac.pa/",
  },
  {
    value: "5468",
    label: "Universidad Católica Santa María La Antigua",
    website: "http://www.usma.ac.pa/",
  },
  {
    value: "5469",
    label: "Universidad de Cartago",
    website: "http://www.ucapanama.org/",
  },
  {
    value: "5470",
    label: "Universidad del Istmo",
    website: "http://www.udi.edu/",
  },
  {
    value: "5471",
    label: "Universidad de Panamá",
    website: "http://www.up.ac.pa/",
  },
  {
    value: "5472",
    label: "Universidad Especializada de Las Americas",
    website: "http://www.udelas.ac.pa/",
  },
  {
    value: "5473",
    label: "Universidad Interamericana de Educacion a Distancia de Panama",
    website: "http://www.uniedpa.com/",
  },
  {
    value: "5474",
    label: "Universidad Latina de Panamá",
    website: "http://www.ulat.ac.pa/",
  },
  {
    value: "5475",
    label: "Universidad Latinoamericana de Ciencia y Tecnologia, Panamá",
    website: "http://www.ulacit.ac.pa/",
  },
  {
    value: "5476",
    label: "Universidad Tecnológica de Panamá",
    website: "http://www.utp.ac.pa/",
  },
  {
    value: "5477",
    label: "Universidad Tecnológica Oteima",
    website: "http://www.oteima.ac.pa/",
  },
  {
    value: "5478",
    label: "Victoria International University",
    website: "http://www.viu.edu.pa/",
  },
  {
    value: "5479",
    label: "West Coast University (WCU)",
    website: "http://www.westcoastuniversity-edu.com/",
  },
  {
    value: "5480",
    label: "Pontificia Universidad Católica del Perú",
    website: "http://www.pucp.edu.pe/",
  },
  {
    value: "5481",
    label: "Universidad Alas Peruanas",
    website: "http://www.uap.edu.pe/",
  },
  {
    value: "5482",
    label: "Universidad Andina del Cusco",
    website: "http://www.uandina.edu.pe/",
  },
  {
    value: "5483",
    label: "Universidad Andina Nestor Caceares Velasquez",
    website: "http://www.uancv.edu.pe/",
  },
  {
    value: "5484",
    label: "Universidad Católica de Santa María",
    website: "http://www.ucsm.edu.pe/",
  },
  {
    value: "5485",
    label: "Universidad Católica Santo Toribio de Mogrovejo",
    website: "http://www.usat.edu.pe/",
  },
  {
    value: "5486",
    label: "Universidad César Vallejo",
    website: "http://www.ucv.edu.pe/",
  },
  {
    value: "5487",
    label: "Universidad Cientifica del Sur",
    website: "http://www.ucsur.edu.pe/",
  },
  {
    value: "5488",
    label: "Universidad de Chiclayo",
    website: "http://www.udch.edu.pe/",
  },
  {
    value: "5489",
    label: "Universidad de Ciencias y Humanidades",
    website: "http://www.uch.edu.pe/",
  },
  {
    value: "5490",
    label: "Universidad de Huánuco",
    website: "http://www.udh.edu.pe/",
  },
  {
    value: "5491",
    label: "Universidad de Lambayeque",
    website: "http://www.udl.edu.pe/",
  },
  {
    value: "5492",
    label: "Universidad de Lima",
    website: "http://www.ulima.edu.pe/",
  },
  {
    value: "5493",
    label: "Universidad del Pacifico",
    website: "http://www.up.edu.pe/",
  },
  {
    value: "5494",
    label: "Universidad de Piura",
    website: "http://www.udep.edu.pe/",
  },
  {
    value: "5495",
    label: "Universidad Femenina del Sagrado Corazón",
    website: "http://www.unife.edu.pe/",
  },
  {
    value: "5496",
    label: "Universidad Los Angeles de Chimbote",
    website: "http://www.uladech.edu.pe/",
  },
  {
    value: "5497",
    label: "Universidad Marcelino Champagnat",
    website: "http://www.champagnat.edu.pe/",
  },
  {
    value: "5498",
    label: "Universidad Nacional Agraria de la Selva",
    website: "http://www.unas.edu.pe/",
  },
  {
    value: "5499",
    label: "Universidad Nacional Agraria La Molina",
    website: "http://www.lamolina.edu.pe/",
  },
  {
    value: "5500",
    label: "Universidad Nacional Agraria La Selva",
    website: "http://www.unas.edu.pe/",
  },
  {
    value: "5501",
    label: "Universidad Nacional Daniel Alcides Carrion",
    website: "http://www.undac.edu.pe/",
  },
  {
    value: "5502",
    label: "Universidad Nacional de Cajamarca",
    website: "http://www.unc.edu.pe/",
  },
  {
    value: "5503",
    label: "Universidad Nacional de Educación Enrique Guzmán y Valle",
    website: "http://www.une.edu.pe/",
  },
  {
    value: "5504",
    label: "Universidad Nacional de Ingeniería",
    website: "http://www.uni.edu.pe/",
  },
  {
    value: "5505",
    label: "Universidad Nacional de la Amazonía Peruana",
    website: "http://www.unapiquitos.edu.pe/",
  },
  {
    value: "5506",
    label: "Universidad Nacional de La Libertad, Trujillo",
    website: "http://www.unitru.edu.pe/",
  },
  {
    value: "5507",
    label: "Universidad Nacional del Altiplano",
    website: "http://www.unap.edu.pe/",
  },
  {
    value: "5508",
    label: "Universidad Nacional del Callao",
    website: "http://www.unac.edu.pe/",
  },
  {
    value: "5509",
    label: "Universidad Nacional del Centro del Perú",
    website: "http://www.uncp.edu.pe/",
  },
  {
    value: "5510",
    label: "Universidad Nacional de Piura",
    website: "http://www.unp.edu.pe/",
  },
  {
    value: "5511",
    label: "Universidad Nacional de San Agustin",
    website: "http://www.unsa.edu.pe/",
  },
  {
    value: "5512",
    label: "Universidad Nacional de San Antonio Abad",
    website: "http://www.unsaac.edu.pe/",
  },
  {
    value: "5513",
    label: "Universidad Nacional de San Cristóbal de Huamanga",
    website: "http://www.unsch.edu.pe/",
  },
  {
    value: "5514",
    label: "Universidad Nacional de San Martín",
    website: "http://www.unsm.edu.pe/",
  },
  {
    value: "5515",
    label: "Universidad Nacional de Santa - Chimbote",
    website: "http://www.uns.edu.pe/",
  },
  {
    value: "5516",
    label: "Universidad Nacional de Tumbes",
    website: "http://www.untumbes.edu.pe/",
  },
  {
    value: "5517",
    label: "Universidad Nacional de Ucayali",
    website: "http://www.unu.edu.pe/",
  },
  {
    value: "5518",
    label: "Universidad Nacional Federico Villarreal",
    website: "http://www.unfv.edu.pe/",
  },
  {
    value: "5519",
    label: "Universidad Nacional Hermilio Valdizan",
    website: "http://www.unheval.edu.pe/",
  },
  {
    value: "5520",
    label: "Universidad Nacional Jorge Basadre Grohmann",
    website: "http://www.unjbg.edu.pe/",
  },
  {
    value: "5521",
    label: "Universidad Nacional José Faustino Sánchez Carrión",
    website: "http://www.unjfsc.edu.pe/",
  },
  {
    value: "5522",
    label: "Universidad Nacional Mayor de San Marcos",
    website: "http://www.unmsm.edu.pe/",
  },
  {
    value: "5523",
    label: "Universidad Nacional Pedro Ruíz Gallo",
    website: "http://www.unprg.edu.pe/",
  },
  {
    value: "5524",
    label: "Universidad Nacional San Luis Gonzaga",
    website: "http://www.unica.edu.pe/",
  },
  {
    value: "5525",
    label: "Universidad Nacional Santiago Antúnez de Mayolo",
    website: "http://www.unasam.edu.pe/",
  },
  {
    value: "5526",
    label: "Universidad Norbert Wiener",
    website: "http://www.uwiener.edu.pe/",
  },
  {
    value: "5527",
    label: "Universidad Particular de Chiclayo",
    website: "http://www.udch.edu.pe/",
  },
  {
    value: "5528",
    label: "Universidad Particular Inca Garcilaso de la Vega",
    website: "http://www.uigv.edu.pe/",
  },
  {
    value: "5529",
    label: "Universidad Peruana Cayetano Heredia",
    website: "http://www.upch.edu.pe/",
  },
  {
    value: "5530",
    label: "Universidad Peruana de Ciencias Aplicadas",
    website: "http://www.upc.edu.pe/",
  },
  {
    value: "5531",
    label: "Universidad Peruana de Ciencias e Informática",
    website: "http://www.upci.edu.pe/",
  },
  {
    value: "5532",
    label: "Universidad Peruana Union",
    website: "http://www.upeu.edu.pe/",
  },
  {
    value: "5533",
    label: "Universidad Privada Antenor Orrego",
    website: "http://www.upao.edu.pe/",
  },
  {
    value: "5534",
    label: "Universidad Privada del Norte",
    website: "http://www.upnorte.edu.pe/",
  },
  {
    value: "5535",
    label: "Universidad Privada de Tacna",
    website: "http://www.upt.edu.pe/",
  },
  {
    value: "5536",
    label: "Universidad Privada Juan Mejía Baca",
    website: "http://www.umb.edu.pe/",
  },
  {
    value: "5537",
    label: "Universidad Privada Los Andes",
    website: "http://www.upla.edu.pe/",
  },
  {
    value: "5538",
    label: "Universidad Privada San Juan Bautista",
    website: "http://www.upsjb.edu.pe/",
  },
  {
    value: "5539",
    label: "Universidad Privada San Pedro",
    website: "http://www.usanpedro.edu.pe/",
  },
  {
    value: "5540",
    label: "Universidad Privada Sergio Bernales",
    website: "http://www.upsb.edu.pe/",
  },
  {
    value: "5541",
    label: "Universidad Ricardo Palma",
    website: "http://www.urp.edu.pe/",
  },
  {
    value: "5542",
    label: "Universidad San Ignacio de Loyola",
    website: "http://www.sil.edu.pe/",
  },
  {
    value: "5543",
    label: "Universidad San Martin de Porres",
    website: "http://www.usmp.edu.pe/",
  },
  {
    value: "5544",
    label: "Universidad San Pablo",
    website: "http://www.usp.edu.pe/",
  },
  {
    value: "5545",
    label: "Universidad Señor de Sipán",
    website: "http://www.uss.edu.pe/",
  },
  {
    value: "5546",
    label: "Universidad Tecnológica de los Andes de Apurímac",
    website: "http://www.utea.edu.pe/",
  },
  {
    value: "5547",
    label: "Universidad Tecnológica del Peru",
    website: "http://www.utp.edu.pe/",
  },
  {
    value: "5548",
    label: "Université de la Polynésie Française",
    website: "http://www.upf.pf/",
  },
  {
    value: "5549",
    label: "Divine Word University",
    website: "http://www.dwu.ac.pg/",
  },
  {
    value: "5550",
    label: "Pacific Adventist University",
    website: "http://www.pau.ac.pg/",
  },
  {
    value: "5551",
    label: "Papua New Guinea University of Technology",
    website: "http://www.unitech.ac.pg/",
  },
  {
    value: "5552",
    label: "University of Goroka",
    website: "http://www.uog.ac.pg/",
  },
  {
    value: "5553",
    label: "University of Papua New Guinea",
    website: "http://www.upng.ac.pg/",
  },
  {
    value: "5554",
    label: "Adamson University",
    website: "http://www.adamson.edu.ph/",
  },
  {
    value: "5555",
    label: "Adventist University of the Philippines",
    website: "http://www.aup.edu.ph/",
  },
  {
    value: "5556",
    label: "Angeles University",
    website: "http://www.auf.edu.ph/",
  },
  {
    value: "5557",
    label: "Aquinas University",
    website: "http://www.aquinas-university.edu/",
  },
  {
    value: "5558",
    label: "Araullo University",
    website: "http://www.aupen.edu.ph/",
  },
  {
    value: "5559",
    label: "Arellano University",
    website: "http://www.arellano.edu.ph/",
  },
  {
    value: "5560",
    label: "Ateneo de Davao University",
    website: "http://www.addu.edu.ph/",
  },
  {
    value: "5561",
    label: "Ateneo de Manila University",
    website: "http://www.admu.edu.ph/",
  },
  {
    value: "5562",
    label: "Ateneo de Naga University",
    website: "http://www.adnu.edu.ph/",
  },
  {
    value: "5563",
    label: "Ateneo de Zamboanga University",
    website: "http://www.adzu.edu.ph/",
  },
  {
    value: "5564",
    label: "Benguet State University",
    website: "http://www.bsu.edu.ph/",
  },
  {
    value: "5565",
    label: "Bicol University",
    website: "http://www.bicol-u.edu.ph/",
  },
  {
    value: "5566",
    label: "Bulacan State University",
    website: "http://www.bulsu.edu.ph/",
  },
  {
    value: "5567",
    label: "Cagayan State University",
    website: "http://www.csu.edu.ph/",
  },
  {
    value: "5568",
    label: "Capitol University",
    website: "http://www.cu-cdo.edu.ph/",
  },
  {
    value: "5569",
    label: "Central Luzon State University",
    website: "http://www2.mozcom.com/~clsu/",
  },
  {
    value: "5570",
    label: "Central Mindanao University",
    website: "http://www.cmu.edu.ph/",
  },
  {
    value: "5571",
    label: "Central Philippine University",
    website: "http://www.cpuic.edu/",
  },
  {
    value: "5572",
    label: "Centro Escolar University",
    website: "http://www.ceu.edu.ph/",
  },
  {
    value: "5573",
    label: "Colegio de San Juan de Letran",
    website: "http://www.letran.edu/",
  },
  {
    value: "5574",
    label: "College of the Holy Spirit",
    website: "http://www.holyspirit.edu.ph/",
  },
  {
    value: "5575",
    label: "Davao Doctors College",
    website: "http://www.davaodoctors.edu.ph/",
  },
  {
    value: "5576",
    label: "De La Salle University",
    website: "http://www.dlsu.edu.ph/",
  },
  {
    value: "5577",
    label: "De La Salle University, Araneta",
    website: "http://www.araneta.dlsu.edu.ph/",
  },
  {
    value: "5578",
    label: "Divine Word College of Legazpi",
    website: "http://www.dwc-legazpi.edu/",
  },
  {
    value: "5579",
    label: "Don Mariano Marcos Memorial State University",
    website: "http://www.dmmmsu.edu.ph/",
  },
  {
    value: "5580",
    label: "Far Eastern University",
    website: "http://www.feu.edu.ph/",
  },
  {
    value: "5581",
    label: "Feati University",
    website: "http://www.featiu.edu.ph/",
  },
  {
    value: "5582",
    label: "Foundation University",
    website: "http://www.foundationu.com/",
  },
  {
    value: "5583",
    label: "Holy Angel University",
    website: "http://www.hau.edu.ph/",
  },
  {
    value: "5584",
    label: "Information and Communications Technology Academy",
    website: "http://www.iacademy.ph/",
  },
  {
    value: "5585",
    label: "Irish University Business School, Cebu",
    website: "http://www.iubs.co.uk/",
  },
  {
    value: "5586",
    label: "Isabela State University",
    website: "http://www.isu.edu.ph/",
  },
  {
    value: "5587",
    label: "Leyte Normal University",
    website: "http://lnu.evis.net.ph/",
  },
  {
    value: "5588",
    label: "Leyte State University ",
    website: "http://www.lsu-visca.edu.ph/",
  },
  {
    value: "5589",
    label: "Liceo de Cagayan University",
    website: "http://www.ldcu.edu.ph/",
  },
  {
    value: "5590",
    label: "Lyceum of the Philippines University",
    website: "http://www.lpu.edu.ph/",
  },
  {
    value: "5591",
    label: "Manila Central University",
    website: "http://www.mcu.edu.ph/",
  },
  {
    value: "5592",
    label: "Manuel L. Quezon University",
    website: "http://www.mlqu.edu.ph/",
  },
  {
    value: "5593",
    label: "Manuel S. Enverga University Foundation",
    website: "http://www.mseuf.edu.ph/",
  },
  {
    value: "5594",
    label: "Mariano Marcos State University",
    website: "http://www.mmsu.edu.ph/",
  },
  {
    value: "5595",
    label: "Mindanao State University",
    website: "http://www.msumain.edu.ph/",
  },
  {
    value: "5596",
    label: "Mindanao State University - Iligan Institute of Technology",
    website: "http://www.msuiit.edu.ph/",
  },
  {
    value: "5597",
    label: "Misamis University",
    website: "http://www.mu.fapenet.org/",
  },
  {
    value: "5598",
    label: "Mountain View College",
    website: "http://www.mvc.edu.ph/",
  },
  {
    value: "5599",
    label: "National University",
    website: "http://www.nu.edu.ph/",
  },
  {
    value: "5600",
    label: "New Era University",
    website: "http://www.neu.edu.ph/",
  },
  {
    value: "5601",
    label: "Northwestern University of the Philippines",
    website: "http://www.nulaoag.com/",
  },
  {
    value: "5602",
    label: "Notre Dame of Marbel University",
    website: "http://www.ndmu.edu.ph/",
  },
  {
    value: "5603",
    label: "Notre Dame University",
    website: "http://www.ndu.fapenet.org/",
  },
  {
    value: "5604",
    label: "Our Lady of Fatima University",
    website: "http://www.fatima.edu.ph/",
  },
  {
    value: "5605",
    label: "Palawan State University",
    website: "http://www.psu-online.edu.ph/",
  },
  {
    value: "5606",
    label: "Pangasinan State University",
    website: "http://www.psu.edu.ph/",
  },
  {
    value: "5607",
    label: "Philippine Christian University",
    website: "http://www.pcu.edu.ph/",
  },
  {
    value: "5608",
    label: "Philippine Military Academy",
    website: "http://www.pma.ph/",
  },
  {
    value: "5609",
    label: "Philippine Normal University",
    website: "http://www.pnumanila.com.ph/",
  },
  {
    value: "5610",
    label: "Philippine Women's University",
    website: "http://www.pwu.edu/",
  },
  {
    value: "5611",
    label: "Polytechnic University of the Philippines",
    website: "http://www.pup.edu.ph/",
  },
  {
    value: "5612",
    label: "Rizal Technological University",
    website: "http://www.rtu.edu.ph/",
  },
  {
    value: "5613",
    label: "Saint Ferdinand College",
    website: "http://www.sfc.edu.ph/",
  },
  {
    value: "5614",
    label: "Saint Louis College",
    website: "http://www.slc-sflu.edu.ph/",
  },
  {
    value: "5615",
    label: "Saint Louis University",
    website: "http://www.slu.edu.ph/",
  },
  {
    value: "5616",
    label: "Saint Mary's University",
    website: "http://www.smu.edu.ph/",
  },
  {
    value: "5617",
    label: "Saint Paul University",
    website: "http://www.spu.edu.ph/",
  },
  {
    value: "5618",
    label: "Samar State University",
    website: "http://www.ssu.edu.ph/",
  },
  {
    value: "5619",
    label: "San Beda College",
    website: "http://www.sanbeda.edu.ph/",
  },
  {
    value: "5620",
    label: "Silliman University",
    website: "http://www.su.edu.ph/",
  },
  {
    value: "5621",
    label: "Southwestern University",
    website: "http://www.swu.edu.ph/",
  },
  {
    value: "5622",
    label: "Tarlac State University",
    website: "http://www2.mozcom.com/~tsu-ics/",
  },
  {
    value: "5623",
    label: "Technological University of the Philippines",
    website: "http://www.tup.edu.ph/",
  },
  {
    value: "5624",
    label: "University of Asia and the Pacific",
    website: "http://www.uap.edu.ph/",
  },
  {
    value: "5625",
    label: "University of Baguio",
    website: "http://www.ubaguio.edu/",
  },
  {
    value: "5626",
    label: "University of Batangas",
    website: "http://www.ub.edu.ph/",
  },
  {
    value: "5627",
    label: "University of Bohol",
    website: "http://www.universityofbohol.com/",
  },
  {
    value: "5628",
    label: "University of Cagayan Valley",
    website: "http://www.ucv.edu.ph/",
  },
  {
    value: "5629",
    label: "University of Cebu",
    website: "http://www.universityofcebu.edu.ph/",
  },
  {
    value: "5630",
    label: "University of Eastern Philippines",
    website: "http://www.uep.educ.ph/",
  },
  {
    value: "5631",
    label: "University of lloilo",
    website: "http://www.ui.phinma.edu.ph/",
  },
  {
    value: "5632",
    label: "University of Manila",
    website: "http://www.univman.edu.ph/",
  },
  {
    value: "5633",
    label: "University of Mindanao",
    website: "http://www.umindanao.edu.ph/",
  },
  {
    value: "5634",
    label: "University of Negros Occidental-Recoletos",
    website: "http://www.uno-r.edu.ph/",
  },
  {
    value: "5635",
    label: "University of Northeastern Philippines",
    website: "http://www.unep.edu.ph/",
  },
  {
    value: "5636",
    label: "University of Northern Philippines",
    website: "http://www.unp.edu.ph/",
  },
  {
    value: "5637",
    label: "University of Nueva Caceres",
    website: "http://www.unc.edu.ph/",
  },
  {
    value: "5638",
    label: "University of Pangasinan",
    website: "http://www.upang.edu.ph/",
  },
  {
    value: "5639",
    label: "University of Perpetual Help",
    website: "http://www.uphr.edu.ph/",
  },
  {
    value: "5640",
    label: "University of Perpetual Help, System Dalta",
    website: "http://www.perpetualdalta.edu.ph/",
  },
  {
    value: "5641",
    label: "University of Regina Carmeli",
    website: "http://www.urc.edu.ph/",
  },
  {
    value: "5642",
    label: "University of Saint La Salle",
    website: "http://www.usls.edu/",
  },
  {
    value: "5643",
    label: "University of San Agustin",
    website: "http://www.usa.edu.ph/",
  },
  {
    value: "5644",
    label: "University of San Carlos",
    website: "http://www.usc.edu.ph/",
  },
  {
    value: "5645",
    label: "University of San Jose Recoletos",
    website: "http://www.usjr.edu.ph/",
  },
  {
    value: "5646",
    label: "University of Santo Tomas",
    website: "http://www.ust.edu.ph/",
  },
  {
    value: "5647",
    label: "University of Southeastern Philippines",
    website: "http://www.usep.edu.ph/",
  },
  {
    value: "5648",
    label: "University of Southern Mindanao",
    website: "http://www.usm.edu.ph/",
  },
  {
    value: "5649",
    label: "University of Southern Philippines Foundation",
    website: "http://www.usp.ph/",
  },
  {
    value: "5650",
    label: "University of the Assumption",
    website: "http://www.ua.edu.ph/",
  },
  {
    value: "5651",
    label: "University of the City of Manila",
    website: "http://www.plm.edu.ph/",
  },
  {
    value: "5652",
    label: "University of the Cordilleras",
    website: "http://www.uc-bcf.edu.ph/",
  },
  {
    value: "5653",
    label: "University of the East, Coloocan",
    website: "http://www.ue.edu.ph/caloocan/",
  },
  {
    value: "5654",
    label: "University of the East, Manila",
    website: "http://www.ue.edu.ph/manila/",
  },
  {
    value: "5655",
    label: "University of the East, Ramon Magsaysay Memorial Medical Center",
    website: "http://www.uerm.edu.ph/",
  },
  {
    value: "5656",
    label: "University of the Immaculate Conception",
    website: "http://uic.globe.com.ph/",
  },
  {
    value: "5657",
    label: "University of the Philippines Baguio",
    website: "http://www.upb.edu.ph/",
  },
  {
    value: "5658",
    label: "University of the Philippines Diliman",
    website: "http://www.upd.edu.ph/",
  },
  {
    value: "5659",
    label: "University of the Philippines Los Banos",
    website: "http://www.uplb.edu.ph/",
  },
  {
    value: "5660",
    label: "University of the Philippines Manila",
    website: "http://www.upm.edu.ph/",
  },
  {
    value: "5661",
    label: "University of the Philippines Mindanao",
    website: "http://www.upmin.edu.ph/",
  },
  {
    value: "5662",
    label: "University of the Philippines Open University",
    website: "http://www.upou.org/",
  },
  {
    value: "5663",
    label: "University of the Philippines Visayas",
    website: "http://www.upv.edu.ph/",
  },
  {
    value: "5664",
    label: "University of the Philippines Visayas - Cebu High School",
    website: "http://www.uphighschoolcebu.edu.ph/",
  },
  {
    value: "5665",
    label: "University of the Visayas",
    website: "http://www.uv.edu.ph/",
  },
  {
    value: "5666",
    label: "Virgen Milagrosa University Foundation",
    website: "http://www.vmuf.edu.ph/",
  },
  {
    value: "5667",
    label: "Wesleyan University Philippines",
    website: "http://www.wesleyan.edu.ph/",
  },
  {
    value: "5668",
    label: "Western Mindanao State University",
    website: "http://www.wmsu.edu.ph/",
  },
  {
    value: "5669",
    label: "West Visayas State University",
    website: "http://www.wvsu.edu.ph/",
  },
  {
    value: "5670",
    label: "Xavier University",
    website: "http://www.xu.edu.ph/",
  },
  {
    value: "5671",
    label: "Abasyn University Peshawar",
    website: "http://www.abasyn.edu.pk/",
  },
  {
    value: "5672",
    label: "Aga Khan University",
    website: "http://www.aku.edu/",
  },
  {
    value: "5673",
    label: "Air University",
    website: "http://www.au.edu.pk/",
  },
  {
    value: "5674",
    label: "Alhamd Islamic University",
    website: "http://www.aiu.edu.pk/",
  },
  {
    value: "5675",
    label: "Al-Khair University",
    website: "http://www.alkhair.edu.pk/",
  },
  {
    value: "5676",
    label: "Allama Iqbal Open University",
    website: "http://www.aiou.edu.pk/",
  },
  {
    value: "5677",
    label: "Alpha Omega University",
    website: "http://www.alphaomega.edu.pk/",
  },
  {
    value: "5678",
    label: "American University of Hawaii, Punjab Campus",
    website: "http://www.auh.edu.pk/",
  },
  {
    value: "5679",
    label: "Arid Agriculture University",
    website: "http://www.uaar.edu.pk/",
  },
  {
    value: "5680",
    label: "Asian Management Institute",
    website: "http://www.ami.edu.pk/",
  },
  {
    value: "5681",
    label:
      "Asia Pacific Institute of Information Technology Pakistan (APIIT PAK)",
    website: "http://www.apiit.edu.pk/",
  },
  {
    value: "5682",
    label: "Azad Jammu and Kashmir University",
    website: "http://www.ajku.edu.pk/",
  },
  {
    value: "5683",
    label: "Bahauddin Zakariya University, Multan",
    website: "http://www.bzu.edu.pk/",
  },
  {
    value: "5684",
    label: "Bahria University",
    website: "http://www.bahria.edu.pk/",
  },
  {
    value: "5685",
    label: "Balochistan University of Engineering and Technology Khuzdar",
    website: "http://www.buetk.edu.pk/",
  },
  {
    value: "5686",
    label:
      "Balochistan University of Information Technology & Management Sciences ",
    website: "http://www.buitms.edu.pk/",
  },
  {
    value: "5687",
    label: "Baqai Medical University",
    website: "http://baqai.freeyellow.com/",
  },
  {
    value: "5688",
    label: "Cecos Univeristy of Information Technology",
    website: "http://www.cecos.edu.pk/",
  },
  {
    value: "5689",
    label: "College of Business Management ( CBM )",
    website: "http://www.cbm.edu.pk/",
  },
  {
    value: "5690",
    label: "COMSATS Institute of Information Technology",
    website: "http://www.ciit.edu.pk/",
  },
  {
    value: "5691",
    label: "COMSATS Institute of Information Technology, Abbottabad",
    website: "http://www.ciit-atd.edu.pk/",
  },
  {
    value: "5692",
    label: "COMSATS Institute of Information Technology, Attock",
    website: "http://www.ciit-attock.edu.pk/",
  },
  {
    value: "5693",
    label: "COMSATS Institute of Information Technology, Lahore",
    website: "http://www.ciitlahore.edu.pk/",
  },
  {
    value: "5694",
    label: "COMSATS Institute of Information Technology, Wah",
    website: "http://www.ciit-wah.edu.pk/",
  },
  {
    value: "5695",
    label: "COSMIQ Institute of Technology",
    website: "http://www.cosmiq.edu.pk/",
  },
  {
    value: "5696",
    label: "Dadabhoy Institute of Higher Education",
    website: "http://www.dadabhoy.edu.pk/",
  },
  {
    value: "5697",
    label: "Dawood College of Engineering and Technology",
    website: "http://www.dcet.edu.pk/",
  },
  {
    value: "5698",
    label: "Dow University of Health Sciences",
    website: "http://www.duhs.edu.pk/",
  },
  {
    value: "5699",
    label: "Faisalabad Institute of Textile and Fashion Design",
    website: "http://www.fitfd.edu.pk/",
  },
  {
    value: "5700",
    label:
      "FAST - National University of Computer and Emerging Sciences (NUCES)",
    website: "http://www.nu.edu.pk/",
  },
  {
    value: "5701",
    label: "Federal Urdu University of Arts,Science and Technology",
    website: "http://www.fuuast.edu.pk/",
  },
  {
    value: "5702",
    label: "Gandhara Institute of Medical Sciences",
    website: "http://www.gandhara.edu.pk/",
  },
  {
    value: "5703",
    label: "Gangdara Institute Of Science & Technology",
    website: "http://www.gist.edu.pk/",
  },
  {
    value: "5704",
    label: "GC University",
    website: "http://www.gcu.edu.pk/",
  },
  {
    value: "5705",
    label: "Ghulam Ishaq Khan Institute of Science & Technology",
    website: "http://www.giki.edu.pk/",
  },
  {
    value: "5706",
    label: "Gift University",
    website: "http://www.gift.edu.pk/",
  },
  {
    value: "5707",
    label: "Gomal University",
    website: "http://www.gu.edu.pk/",
  },
  {
    value: "5708",
    label: "Government College University Faisalabad",
    website: "http://www.gcuf.edu.pk/",
  },
  {
    value: "5709",
    label: "Government College University Lahore",
    website: "http://www.gcu.edu.pk/",
  },
  {
    value: "5710",
    label: "Greenwich University",
    website: "http://www.guic.edu.pk/",
  },
  {
    value: "5711",
    label: "Hahnamann Honoeopathic Medical College",
    website: "http://www.pakistanhomoeopathy.com/",
  },
  {
    value: "5712",
    label: "Hajvery University Lahore for Women",
    website: "http://www.hajvery.edu.pk/",
  },
  {
    value: "5713",
    label: "Hamdard University",
    website: "http://www.hamdard.edu.pk/",
  },
  {
    value: "5714",
    label: "Hazara University",
    website: "http://www.hu.edu.pk/",
  },
  {
    value: "5715",
    label: "Hitec University",
    website: "http://www.hitecuni.edu.pk/",
  },
  {
    value: "5716",
    label: "Indus Institute of Higher Education",
    website: "http://www.indus.edu.pk/",
  },
  {
    value: "5717",
    label: "Institute of Business Administration (IBA)",
    website: "http://www.iba.edu.pk/",
  },
  {
    value: "5718",
    label: "Institute of Business Administration Sukkur",
    website: "http://www.iba-suk.edu.pk/",
  },
  {
    value: "5719",
    label: "Institute of Business Management ",
    website: "http://www.iobm.edu.pk/",
  },
  {
    value: "5720",
    label: "Institute of Business & Technology",
    website: "http://www.biztek.edu.pk/",
  },
  {
    value: "5721",
    label: "Institute of Industrial Electronics Engineering",
    website: "http://www.iiee.edu.pk/",
  },
  {
    value: "5722",
    label: "Institute of Management Sciences, Lahore (IMS)",
    website: "http://www.pakaims.edu.pk/",
  },
  {
    value: "5723",
    label: "Institute of Management Sciences, Peshawar",
    website: "http://www.imsciences.edu.pk/",
  },
  {
    value: "5724",
    label: "Institute of Space Technology",
    website: "http://www.ist.edu.pk/",
  },
  {
    value: "5725",
    label: "International Islamic University, Islamabad",
    website: "http://www.iiu.edu.pk/",
  },
  {
    value: "5726",
    label: "Iqra University",
    website: "http://www.iqra.edu.pk/",
  },
  {
    value: "5727",
    label: "Islamia University of Bahawalpur",
    website: "http://www.iub.edu.pk/",
  },
  {
    value: "5728",
    label: "Isra University",
    website: "http://www.isra.edu.pk/",
  },
  {
    value: "5729",
    label: "Jinnah University for Women",
    website: "http://www.juw.edu.pk/",
  },
  {
    value: "5730",
    label: "Karachi Institute Of Economics And Technology",
    website: "http://www.pafkiet.edu.pk/",
  },
  {
    value: "5731",
    label: "Karachi Institute of Information Technology",
    website: "http://www.kiit.edu.pk/",
  },
  {
    value: "5732",
    label: "Karachi Medical and Dental College",
    website: "http://www.kmdc.edu.pk/",
  },
  {
    value: "5733",
    label: "Karachi School of Art",
    website: "http://www.ksa.edu.pk/",
  },
  {
    value: "5734",
    label: "Karakoram International University",
    website: "http://www.kiu.edu.pk/",
  },
  {
    value: "5735",
    label: "KASB Institute of Technology",
    website: "http://www.kasbit.edu.pk/",
  },
  {
    value: "5736",
    label: "Kohat University of Science and Technology  (KUST)",
    website: "http://www.kust.edu.pk/",
  },
  {
    value: "5737",
    label: "Lahore College for Women University",
    website: "http://www.lcwu.edu.pk/",
  },
  {
    value: "5738",
    label: "Lahore University of Management Sciences",
    website: "http://www.lums.edu.pk/",
  },
  {
    value: "5739",
    label: "Liaquat University of Medical & Health Sciences Jamshoro",
    website: "http://www.lumhs.edu.pk/",
  },
  {
    value: "5740",
    label: "Mehran University of Engineering & Technology",
    website: "http://www.muet.edu.pk/",
  },
  {
    value: "5741",
    label: "Minhaj University Lahore",
    website: "http://www.mul.edu.pk/",
  },
  {
    value: "5742",
    label: "Mohammad Ali Jinnah University",
    website: "http://www.jinnah.edu.pk/",
  },
  {
    value: "5743",
    label: "Mohammad Ali Jinnah University, Karachi",
    website: "http://www.jinnah.edu/",
  },
  {
    value: "5744",
    label: "Mohi-ud-Din Islamic University",
    website: "http://www.miu.edu.pk/",
  },
  {
    value: "5745",
    label: "Mooreland University",
    website: "http://www.moorelanduniversity.com/",
  },
  {
    value: "5746",
    label: "National College of Arts",
    website: "http://www.nca.edu.pk/",
  },
  {
    value: "5747",
    label: "National University of Computer & Emerging Sciences",
    website: "http://www.nu.edu.pk/",
  },
  {
    value: "5748",
    label: "National University of Modern Languages",
    website: "http://www.numl.edu.pk/",
  },
  {
    value: "5749",
    label: "National University of Science and Technology",
    website: "http://www.nust.edu.pk/",
  },
  {
    value: "5750",
    label: "NED University of Engineering and Technology Karachi",
    website: "http://www.neduet.edu.pk/",
  },
  {
    value: "5751",
    label: "Newports Institute of Communication & Economics",
    website: "http://www.newports.edu.pk/",
  },
  {
    value: "5752",
    label: "North West Frontier Province Agricultural University",
    website: "http://www.aup.edu.pk/",
  },
  {
    value: "5753",
    label: "Nuclear Institute for Agriculture and Biology (NIAB)",
    website: "http://www.niab.org.pk/",
  },
  {
    value: "5754",
    label: "Pak-AIMS (American Institute of Management Sciences)",
    website: "http://www.pakaims.edu/",
  },
  {
    value: "5755",
    label: "Pakistan Institute of Engineering and Applied Sciences (PIEAS)",
    website: "http://www.pieas.edu.pk/",
  },
  {
    value: "5756",
    label: "Pakistan Virtual University",
    website: "http://www.vu.edu.pk/",
  },
  {
    value: "5757",
    label: "Planwel University",
    website: "http://www.planwel.edu/",
  },
  {
    value: "5758",
    label: "Preston Institute of Management Sciences and Technology (PIMSAT)",
    website: "http://www.pimsat-khi.edu.pk/",
  },
  {
    value: "5759",
    label: "Preston University, Pakistan Campus",
    website: "http://www.prestonpak.edu.pk/",
  },
  {
    value: "5760",
    label: "Punjab Institute of Computer science",
    website: "http://www.pics.edu.pk/",
  },
  {
    value: "5761",
    label: "Punjab University College of Information Tecnology",
    website: "http://www.pucit.edu.pk/",
  },
  {
    value: "5762",
    label: "Pyramid Education Center",
    website: "http://www.pedu.edu.pk/",
  },
  {
    value: "5763",
    label: "Qauid-e-Awam University of Engineering Sciences & Technology",
    website: "http://www.panasia.org.sg/tcdc/pakistan/qauid_sindh/index.html",
  },
  {
    value: "5764",
    label: "Quaid-i-Azam University",
    website: "http://www.qau.edu.pk/",
  },
  {
    value: "5765",
    label: "Qurtuba University of Science and Infromation Technology",
    website: "http://www.qurtuba.edu.pk/",
  },
  {
    value: "5766",
    label: "Riphah International Univeristy",
    website: "http://www.riphah.edu.pk/",
  },
  {
    value: "5767",
    label: "Sarhad University of Science & Information Technology, Peshawar ",
    website: "http://www.suit.edu.pk/",
  },
  {
    value: "5768",
    label: "Shah Abdul Latif University Khairpur",
    website: "http://www.salu.edu.pk/",
  },
  {
    value: "5769",
    label: "Shaheed Benazir Bhutto Women University ",
    website: "http://www.fwu.edu.pk/",
  },
  {
    value: "5770",
    label: "Shaheed Zulifkar Ali Bhutto Institute of Science and Technology",
    website: "http://www.szabist.edu.pk/",
  },
  {
    value: "5771",
    label: "Sindh Agricultural University",
    website: "http://www.sau.edu.pk/",
  },
  {
    value: "5772",
    label: "Sindh Madressatul Islam University",
    website: "http://www.smiu.edu.pk/",
  },
  {
    value: "5773",
    label: "Sir Syed Institute Of Technology Islamabad",
    website: "http://www.ssms.edu.pk/",
  },
  {
    value: "5774",
    label: "Sir Syed University of Engineering and Technology",
    website: "http://www.ssuet.edu.pk/",
  },
  {
    value: "5775",
    label: "Textile Institute of Pakistan",
    website: "http://www.tip.edu.pk/",
  },
  {
    value: "5776",
    label: "The Global College Lahore",
    website: "http://www.global.edu.pk/",
  },
  {
    value: "5777",
    label: "The Government Sadiq College Women University Bahawalpur",
    website: "http://www.gscwu.edu.pk/",
  },
  {
    value: "5778",
    label: "The Superior College ",
    website: "http://www.superior.edu.pk/",
  },
  {
    value: "5779",
    label: "University of Agriculture Faisalabad",
    website: "http://www.uaf.edu.pk/",
  },
  {
    value: "5780",
    label: "University of Balochistan",
    website: "http://www.uob.edu.pk/",
  },
  {
    value: "5781",
    label: "University of Central Punjab",
    website: "http://www.ucp.edu.pk/",
  },
  {
    value: "5782",
    label: "University of East",
    website: "http://www.uoe.edu.pk/",
  },
  {
    value: "5783",
    label: "University of Education",
    website: "http://www.ue.edu.pk/",
  },
  {
    value: "5784",
    label: "University of Engineering and Technology Lahore",
    website: "http://www.uet.edu/",
  },
  {
    value: "5785",
    label: "University of Engineering and Technology Peshawar",
    website: "http://www.nwfpuet.edu.pk/",
  },
  {
    value: "5786",
    label: "University of Engineering and Technology Taxila",
    website: "http://www.uettaxila.edu.pk/",
  },
  {
    value: "5787",
    label: "University of Faisalabad",
    website: "http://www.tuf.edu.pk/",
  },
  {
    value: "5788",
    label: "University of Gujrat",
    website: "http://www.uog.edu.pk/",
  },
  {
    value: "5789",
    label: "University of Islamic Studies",
    website: "http://www.aldirasat.edu.pk/",
  },
  {
    value: "5790",
    label: "University of Karachi",
    website: "http://www.uok.edu.pk/",
  },
  {
    value: "5791",
    label: "University of Lahore",
    website: "http://www.uol.edu.pk/",
  },
  {
    value: "5792",
    label: "University of Malakand",
    website: "http://www.uom.edu.pk/",
  },
  {
    value: "5793",
    label: "University of Management and Technology",
    website: "http://www.umt.edu.pk/",
  },
  {
    value: "5794",
    label: "University of Munawwar-ul-Islam",
    website: "http://www.umi.edu.pk/",
  },
  {
    value: "5795",
    label: "University of NorthWest",
    website: "http://www.unw.edu.pk/",
  },
  {
    value: "5796",
    label: "University of Peshawar",
    website: "http://www.upesh.edu/",
  },
  {
    value: "5797",
    label: "University of Sargodha",
    website: "http://www.uos.edu.pk/",
  },
  {
    value: "5798",
    label: "University of Science & Technology Bannu",
    website: "http://www.ustb.edu.pk/",
  },
  {
    value: "5799",
    label: "University of Sindh",
    website: "http://www.usindh.edu.pk/",
  },
  {
    value: "5800",
    label: "University of South Asia",
    website: "http://www.usa.edu.pk/",
  },
  {
    value: "5801",
    label: "University of the Punjab",
    website: "http://www.pu.edu.pk/",
  },
  {
    value: "5802",
    label: "University of the Punjab, Gujranwala Campus",
    website: "http://www.pugc.edu.pk/",
  },
  {
    value: "5803",
    label: "University of Veterinary & Animal Science",
    website: "http://www.uvas.edu.pk/",
  },
  {
    value: "5804",
    label: "University of Wah",
    website: "http://www.uw.edu.pk/",
  },
  {
    value: "5805",
    label: "Virtual University of Pakistan",
    website: "http://www.vu.edu.pk/",
  },
  {
    value: "5806",
    label: "Wah Medical College",
    website: "http://www.wahmedicalcollege.edu.pk/",
  },
  {
    value: "5807",
    label: "Zawiya Academy",
    website: "http://www.zawiya.edu.pk/",
  },
  {
    value: "5808",
    label: "Zia-ud-Din Medical University",
    website: "http://www.geocities.com/CollegePark/7329/",
  },
  {
    value: "5809",
    label: "Academy of Economics in Cracow",
    website: "http://www.ae.krakow.pl/",
  },
  {
    value: "5810",
    label: "Academy of Economics in Katowice",
    website: "http://www.ae.katowice.pl/",
  },
  {
    value: "5811",
    label: "Academy of Economics in Poznan",
    website: "http://www.ae.poznan.pl/",
  },
  {
    value: "5812",
    label: "Academy of Economics in Wroclaw",
    website: "http://www.ae.wroc.pl/",
  },
  {
    value: "5813",
    label: "Academy of Humanities and Economics in Lodz",
    website: "http://www.ahe.edu.pl/",
  },
  {
    value: "5814",
    label: "Academy of International Economic and Political Relations, Gdynia",
    website: "http://www.wsms.edu.pl/",
  },
  {
    value: "5815",
    label: "Adam Mickiewicz University of Poznan",
    website: "http://www.amu.edu.pl/",
  },
  {
    value: "5816",
    label: "AGH University of Science and Technology",
    website: "http://www.agh.edu.pl/",
  },
  {
    value: "5817",
    label: "Agricultural-Technical Academy in Olsztyn",
    website: "http://www.art.olsztyn.pl/",
  },
  {
    value: "5818",
    label: "Agricultural University of Cracow",
    website: "http://www.ar.krakow.pl/",
  },
  {
    value: "5819",
    label: "Agricultural University of Lublin",
    website: "http://www.ar.lublin.pl/",
  },
  {
    value: "5820",
    label: "Agricultural University of Poznan",
    website: "http://www.au.poznan.pl/",
  },
  {
    value: "5821",
    label: "Agricultural University of Szczecin",
    website: "http://www.ar.szczecin.pl/",
  },
  {
    value: "5822",
    label: "Agricultural University of Warsaw",
    website: "http://www.sggw.waw.pl/",
  },
  {
    value: "5823",
    label: "Agricultural University of Wroclaw",
    website: "http://www.ar.wroc.pl/",
  },
  {
    value: "5824",
    label: "Akademia Podlaska",
    website: "http://www.ap.siedlce.pl/",
  },
  {
    value: "5825",
    label: "Aleksander Gieysztor School of Humanities in Pultusk",
    website: "http://www.wsh.edu.pl/",
  },
  {
    value: "5826",
    label: "Aleksander Zelwerowicz State Theatre Academy",
    website: "http://www.at.edu.pl/",
  },
  {
    value: "5827",
    label: "Andrzej Frycz Modrzewski Cracow College",
    website: "http://www.ksw.edu.pl/",
  },
  {
    value: "5828",
    label: "Cardinal Stefan Wyszynski University in Warsaw",
    website: "http://www.uksw.edu.pl/",
  },
  {
    value: "5829",
    label: "Catholic University of Lublin",
    website: "http://www.kul.lublin.pl/",
  },
  {
    value: "5830",
    label: "Christian Theological Academy in Warszaw",
    website: "http://www.qdnet.pl/ChAT/",
  },
  {
    value: "5831",
    label: "College of Computer Science in Lodz",
    website: "http://www.wsinf.edu.pl/",
  },
  {
    value: "5832",
    label: "Collegium Civitas",
    website: "http://www.collegium.edu.pl/",
  },
  {
    value: "5833",
    label: "European Academy of Arts in Warsaw",
    website: "http://www.eas.edu.pl/",
  },
  {
    value: "5834",
    label: "Fine Arts Academy in Gdansk",
    website: "http://www.asp.gda.pl/",
  },
  {
    value: "5835",
    label: "Fine Arts Academy in Katowice",
    website: "http://www.aspkat.edu.pl/",
  },
  {
    value: "5836",
    label: "Fine Arts Academy in Poznan",
    website: "http://www.asp.poznan.pl/",
  },
  {
    value: "5837",
    label: "Fine Arts Academy in Warsaw",
    website: "http://www.asp.waw.pl/",
  },
  {
    value: "5838",
    label: "Fine Arts Academy in Wroclaw",
    website: "http://www.asp.wroc.pl/",
  },
  {
    value: "5839",
    label: 'Fine Arts Academy "Jan Matejko" in Cracow',
    website: "http://149.156.19.10/",
  },
  {
    value: "5840",
    label: "Fine Arts Academy Wladyslaw Strzeminski in Lodz",
    website: "http://www.asp.lodz.pl/",
  },
  {
    value: "5841",
    label: "French Institute of Management",
    website: "http://www.ifg.com.pl/",
  },
  {
    value: "5842",
    label: "Gdansk Management College",
    website: "http://www.wsz.pl/",
  },
  {
    value: "5843",
    label: "Gdynia Maritime Academy",
    website: "http://www.wsm.gdynia.pl/",
  },
  {
    value: "5844",
    label: "Higher School o Business in Tarnow",
    website: "http://wsb.toi.tarnow.pl/",
  },
  {
    value: "5845",
    label:
      "Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz",
    website: "http://www.wsb-nlu.edu.pl/",
  },
  {
    value: "5846",
    label: "Jagiellonian University Cracow",
    website: "http://www.uj.edu.pl/",
  },
  {
    value: "5847",
    label:
      "Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala",
    website: "http://www.tyszkiewicz.edu.pl/",
  },
  {
    value: "5848",
    label:
      "Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala",
    website: "http://www.tyszkiewicz.edu.pl/",
  },
  {
    value: "5849",
    label: "Katowice School of Economics",
    website: "http://www.gwsh.pl/",
  },
  {
    value: "5850",
    label: "Lazarski School of Commerce and Law",
    website: "http://www.lazarski.pl/",
  },
  {
    value: "5851",
    label: "Leon Kozminski Academy of Entrepreneurship and Mangement",
    website: "http://www.wspiz.edu.pl/",
  },
  {
    value: "5852",
    label: "Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw",
    website: "http://www.wspiz.edu.pl/",
  },
  {
    value: "5853",
    label: "Ludwik Solski State Academy of Theatre in Cracow",
    website: "http://www.pwst.krakow.pl/",
  },
  {
    value: "5854",
    label: "Maria Curie-Sklodowska University Lublin",
    website: "http://www.umcs.lublin.pl/",
  },
  {
    value: "5855",
    label: "Maria Grzegorzewska College for Special Education",
    website: "http://www.wsps.waw.pl/",
  },
  {
    value: "5856",
    label: "Maritime University in Szczecin",
    website: "http://www.wsm.szczecin.pl/",
  },
  {
    value: "5857",
    label: "Medical Academy in Bialystok",
    website: "http://www.amb.edu.pl/",
  },
  {
    value: "5858",
    label: "Medical Academy in Gdansk",
    website: "http://www.amg.gda.pl/",
  },
  {
    value: "5859",
    label: "Medical Academy in Lodz",
    website: "http://www.am.lodz.pl/",
  },
  {
    value: "5860",
    label: "Medical Academy in Lublin",
    website: "http://www.am.lublin.pl/",
  },
  {
    value: "5861",
    label: "Medical Academy in Warszawa",
    website: "http://www.amwaw.edu.pl/",
  },
  {
    value: "5862",
    label: "Medical Academy in Wroclaw",
    website: "http://www.am.wroc.pl/",
  },
  {
    value: "5863",
    label: "Medical Academy Karol Marcinkowski in Poznan",
    website: "http://www.usoms.poznan.pl/",
  },
  {
    value: "5864",
    label: "Medical Academy Ludwik Rydygier in Bydgoszcz",
    website: "http://www.amb.bydgoszcz.pl/",
  },
  {
    value: "5865",
    label: 'Music Academy "Felix Nowowiejski" in Bydgoszcz',
    website: "http://www.amuz.bydgoszcz.pl/",
  },
  {
    value: "5866",
    label: 'Music Academy "Fryderyk Chopin" in Warszaw',
    website: "http://www.chopin.edu.pl/",
  },
  {
    value: "5867",
    label: 'Music Academy "Ignacy Jana Paderewski" in Poznan',
    website: "http://www.amuz.poznan.pl/",
  },
  {
    value: "5868",
    label: "Music Academy in Cracow",
    website: "http://www.amuz.krakow.pl/",
  },
  {
    value: "5869",
    label: "Music Academy in Lodz",
    website: "http://www.amuz.lodz.pl/",
  },
  {
    value: "5870",
    label: 'Music Academy "Karol Lipinski" in Wroclaw',
    website: "http://www.amuz.wroc.pl/",
  },
  {
    value: "5871",
    label: 'Music Academy "Karol Szymanowski" in Katowice',
    website: "http://www.am.katowice.pl/",
  },
  {
    value: "5872",
    label: 'Music Academy "Stanislaw Moniuszko" in Gdansk',
    website: "http://www.amuz.gda.pl/",
  },
  {
    value: "5873",
    label: "Nicolaus Copernicus University of Torun",
    website: "http://www.uni.torun.pl/",
  },
  {
    value: "5874",
    label: "Pawel Wlodkowic University College in Plock",
    website: "http://www.wlodkowic.pl/",
  },
  {
    value: "5875",
    label: "Pedagogical University of Bydgoszcz",
    website: "http://www.wsp.bydgoszcz.pl/",
  },
  {
    value: "5876",
    label: "Pedagogical University of Czestochowa",
    website: "http://www.wsp.czest.pl/",
  },
  {
    value: "5877",
    label: "Pedagogical University of Kielce",
    website: "http://www.pu.kielce.pl/",
  },
  {
    value: "5878",
    label: "Pedagogical University of Krakow",
    website: "http://www.wsp.krakow.pl/",
  },
  {
    value: "5879",
    label: "Pedagogical University of Rzeszow",
    website: "http://www.univ.rzeszow.pl/",
  },
  {
    value: "5880",
    label: "Pedagogical University of Slupsk",
    website: "http://www.wsp.slupsk.pl/",
  },
  {
    value: "5881",
    label:
      "Pedagogical University of the Polish Association for Adult Education in Warsaw",
    website: "http://www.wsptwpwaw.edu.pl/",
  },
  {
    value: "5882",
    label: "Pedagogical University of Zielona Gora",
    website: "http://www.wsp.zgora.pl/",
  },
  {
    value: "5883",
    label: 'Physical Education Academy "Eugeniusz Piasecki" in Poznan',
    website: "http://www.awf.poznan.pl/",
  },
  {
    value: "5884",
    label: "Physical Education Academy in Katowice",
    website: "http://www.awf.katowice.pl/",
  },
  {
    value: "5885",
    label: "Physical Education Academy in Wroclaw",
    website: "http://www.awf.wroc.pl/",
  },
  {
    value: "5886",
    label: 'Physical Education Academy "Jedrzej Sniadecki" in Gdansk',
    website: "http://www.awf.gda.pl/",
  },
  {
    value: "5887",
    label: 'Physical Education Academy "Jozef Pilsudski" in Warsaw',
    website: "http://www.awf.edu.pl/",
  },
  {
    value: "5888",
    label: "Polish-Japanese Institute of Information Technology in Warsaw",
    website: "http://www.pjwstk.edu.pl/",
  },
  {
    value: "5889",
    label: "Polish Open University in Warsaw (WSZ/POU)",
    website: "http://www.wsz-pou.edu.pl/",
  },
  {
    value: "5890",
    label: "Polonia University in Czêstochowa",
    website: "http://www.ap.edu.pl/",
  },
  {
    value: "5891",
    label: "Pomeranian Academy of Medicine in Szczecin",
    website: "http://www.pam.szczecin.pl/",
  },
  {
    value: "5892",
    label: "Poznan School of Banking",
    website: "http://www.wsb.poznan.pl/",
  },
  {
    value: "5893",
    label: "Poznan University of Life Sciences",
    website: "http://www.puls.edu.pl/",
  },
  {
    value: "5894",
    label: "Ryszard Lazarski University of Commerce and Law in Warsaw",
    website: "http://www.wship.edu.pl/",
  },
  {
    value: "5895",
    label: "School of Banking and Management in Cracow",
    website: "http://www.wszib.krakow.pl/",
  },
  {
    value: "5896",
    label: "Silesian Academy of Medicine in Katowice",
    website: "http://www.slam.katowice.pl/",
  },
  {
    value: "5897",
    label: "Silesian School of Economics and Languages",
    website: "http://www.gallus.pl/",
  },
  {
    value: "5898",
    label: "Silesian Technical University of Gliwice",
    website: "http://www.polsl.gliwice.pl/",
  },
  {
    value: "5899",
    label: "Staszic Pila College",
    website: "http://www.pwsz.pila.pl/",
  },
  {
    value: "5900",
    label:
      "State Film, Television and Theatre Higher School Leon Schiller in Lodz",
    website: "http://www.filmowka.lodz.pl/",
  },
  {
    value: "5901",
    label:
      "Teacher Training College of Revalidation, Rehabilitation and Physical Education",
    website: "http://www.nkrriwf.pl/",
  },
  {
    value: "5902",
    label: "Technical University of Bialystok",
    website: "http://www.pb.bialystok.pl/",
  },
  {
    value: "5903",
    label: "Technical University of Cracow",
    website: "http://www.pk.edu.pl/",
  },
  {
    value: "5904",
    label: "Technical University of Czestochowa",
    website: "http://www.pcz.czest.pl/",
  },
  {
    value: "5905",
    label: "Technical University of Gdansk",
    website: "http://www.pg.gda.pl/",
  },
  {
    value: "5906",
    label: "Technical University of Kielce",
    website: "http://www.tu.kielce.pl/",
  },
  {
    value: "5907",
    label: "Technical University of Koszalin",
    website: "http://www.tu.koszalin.pl/",
  },
  {
    value: "5908",
    label: "Technical University of Lodz",
    website: "http://www.p.lodz.pl/",
  },
  {
    value: "5909",
    label: "Technical University of Lublin",
    website: "http://www.pol.lublin.pl/",
  },
  {
    value: "5910",
    label: "Technical University of Opole",
    website: "http://www.po.opole.pl/",
  },
  {
    value: "5911",
    label: "Technical University of Poznan",
    website: "http://www.put.poznan.pl/",
  },
  {
    value: "5912",
    label: "Technical University of Radom",
    website: "http://www.man.radom.pl/",
  },
  {
    value: "5913",
    label: "Technical University of Rzeszow",
    website: "http://www.prz.rzeszow.pl/",
  },
  {
    value: "5914",
    label: "Technical University of Szczecin",
    website: "http://www.tuniv.szczecin.pl/",
  },
  {
    value: "5915",
    label: "Technical University of Warsaw",
    website: "http://www.pw.edu.pl/",
  },
  {
    value: "5916",
    label: "Technical University of Wroclaw",
    website: "http://www.pwr.wroc.pl/",
  },
  {
    value: "5917",
    label: "Technical University of Zielona Gora",
    website: "http://www.pz.zgora.pl/",
  },
  {
    value: "5918",
    label: "University of Bialystok",
    website: "http://www.ub.edu.pl/",
  },
  {
    value: "5919",
    label: "University of Finance and Management in Bialystok",
    website: "http://www.wsfiz.edu.pl/",
  },
  {
    value: "5920",
    label: "University of Gdansk",
    website: "http://www.univ.gda.pl/",
  },
  {
    value: "5921",
    label: "University of Information Technology and Management in Rzeszow",
    website: "http://www.wsiz.rzeszow.pl/",
  },
  {
    value: "5922",
    label: "University of Insurance and Banking in Warsaw",
    website: "http://www.wsub.waw.pl/",
  },
  {
    value: "5923",
    label: "University of Lodz",
    website: "http://www.uni.lodz.pl/",
  },
  {
    value: "5924",
    label:
      "University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.",
    website: "http://www.dswe.wroc.pl/",
  },
  {
    value: "5925",
    label: "University of Management and Marketing",
    website: "http://www.wszim.edu.pl/",
  },
  {
    value: "5926",
    label: "University of Opole",
    website: "http://www.uni.opole.pl/",
  },
  {
    value: "5927",
    label: "University of Silesia",
    website: "http://www.us.edu.pl/",
  },
  {
    value: "5928",
    label: "University of Szczecin",
    website: "http://www.univ.szczecin.pl/",
  },
  {
    value: "5929",
    label: "University of Technology and Life Sciences",
    website: "http://www.utp.edu.pl/",
  },
  {
    value: "5930",
    label: "University of Wamia and Masuria in Olsztyn",
    website: "http://www.uwm.edu.pl/",
  },
  {
    value: "5931",
    label: "University of Warsaw",
    website: "http://www.uw.edu.pl/",
  },
  {
    value: "5932",
    label: "University of Wroclaw",
    website: "http://www.uni.wroc.pl/",
  },
  {
    value: "5933",
    label: "University School of Physical Education",
    website: "http://www.awf.krakow.pl/",
  },
  {
    value: "5934",
    label: "Walcz College",
    website: "http://www.pwsz.eu/",
  },
  {
    value: "5935",
    label: "Warsaw School of Economics",
    website: "http://www.sgh.waw.pl/",
  },
  {
    value: "5936",
    label: "Warsaw School of Information Technology",
    website: "http://www.wsisiz.edu.pl/",
  },
  {
    value: "5937",
    label: "Warsaw School of Social Psychology",
    website: "http://www.swps.edu.pl/",
  },
  {
    value: "5938",
    label: "West Pomeranian Business School in Szczecin",
    website: "http://www.zpsb.szczecin.pl/",
  },
  {
    value: "5939",
    label: "Zachodniopomorska School of Science and Engineering",
    website: " http://www.zut.edu.pl/",
  },
  {
    value: "5940",
    label: "Caribbean University",
    website: "http://www.caribbean.edu/",
  },
  {
    value: "5941",
    label: "Centro de Estudios Avanzados de Puerto Rico y el Caribe",
    website: "http://www.ceaprc.org/",
  },
  {
    value: "5942",
    label: "Pontificia Universidad Catolica de Puerto Rico",
    website: "http://www.pucpr.edu/",
  },
  {
    value: "5943",
    label: "San Juan Bautista School of Medicine",
    website: "http://www.sanjuanbautista.edu/",
  },
  {
    value: "5944",
    label: "Sistema Universitario Ana G. Méndez",
    website: "http://www.suagm.edu/",
  },
  {
    value: "5945",
    label: "Universidad Central de Bayamón",
    website: "http://www.ucb.edu.pr/",
  },
  {
    value: "5946",
    label: "Universidad Central del Caribe",
    website: "http://www.uccaribe.edu/",
  },
  {
    value: "5947",
    label: "Universidad del Sagrado Corazon",
    website: "http://www.sagrado.edu/",
  },
  {
    value: "5948",
    label: "Universidad del Turabo",
    website: "http://www.ut.pr/",
  },
  {
    value: "5949",
    label: "Universidad de Puerto Rico",
    website: "http://www.upr.clu.edu/",
  },
  {
    value: "5950",
    label: "Universidad de Puerto Rico, Aguadilla",
    website: "http://www.uprag.edu/",
  },
  {
    value: "5951",
    label: "Universidad de Puerto Rico, Arecibo",
    website: "http://www.upra.edu/",
  },
  {
    value: "5952",
    label: "Universidad de Puerto Rico, Bayamon",
    website: "http://www.uprb.edu/",
  },
  {
    value: "5953",
    label: "Universidad de Puerto Rico, Carolina",
    website: "http://www.uprc.edu/",
  },
  {
    value: "5954",
    label: "Universidad de Puerto Rico, Cayey",
    website: "http://www.cayey.upr.edu/",
  },
  {
    value: "5955",
    label: "Universidad de Puerto Rico, Ciencias Medicas",
    website: "http://www.rcm.upr.edu/",
  },
  {
    value: "5956",
    label: "Universidad de Puerto Rico, Humacao",
    website: "http://cuhwww.upr.clu.edu/",
  },
  {
    value: "5957",
    label: "Universidad de Puerto Rico, Mayaguez",
    website: "http://www.uprm.edu/",
  },
  {
    value: "5958",
    label: "Universidad de Puerto Rico, Ponce",
    website: "http://upr-ponce.upr.edu/",
  },
  {
    value: "5959",
    label: "Universidad de Puerto Rico, Rio Pedras",
    website: "http://www.uprrp.edu/",
  },
  {
    value: "5960",
    label: "Universidad de Puerto Rico, Utuado",
    website: "http://www.uprutuado.edu/",
  },
  {
    value: "5961",
    label: "Universidad Interamericana de Puerto Rico",
    website: "http://www.inter.edu/",
  },
  {
    value: "5962",
    label: "Universidad Interamericana de Puerto Rico, Bayamón",
    website: "http://www.bc.inter.edu/",
  },
  {
    value: "5963",
    label: "Universidad Interamericana de Puerto Rico, San Germán",
    website: "http://www.sg.inter.edu/",
  },
  {
    value: "5964",
    label: "Universidad Metropolitana",
    website: "http://www.suagm.edu/suagm/umet/",
  },
  {
    value: "5965",
    label: "Universidad Politecnica de Puerto Rico",
    website: "http://www.pupr.edu/",
  },
  {
    value: "5966",
    label: "Al-Aqsa University",
    website: "http://www.alaqsa.edu.ps/",
  },
  {
    value: "5967",
    label: "Al-Azhar University of Gaza",
    website: "http://www.alazhar.edu.ps/",
  },
  {
    value: "5968",
    label: "Al-Quds Open University",
    website: "http://www.qou.edu/",
  },
  {
    value: "5969",
    label: "Al-Quds University - The Arab University in Jerusalem",
    website: "http://www.alquds.edu/",
  },
  {
    value: "5970",
    label: "An-Najah National University",
    website: "http://www.najah.edu/",
  },
  {
    value: "5971",
    label: "Arab American University - Jenin",
    website: "http://www.aauj.edu/",
  },
  {
    value: "5972",
    label: "Bethlehem University",
    website: "http://www.bethlehem.edu/",
  },
  {
    value: "5973",
    label: "Birzeit University",
    website: "http://www.birzeit.edu/",
  },
  {
    value: "5974",
    label: "Hebron University",
    website: "http://www.hebron.edu/",
  },
  {
    value: "5975",
    label: "Islamic University of Gaza",
    website: "http://www.iugaza.edu.ps/",
  },
  {
    value: "5976",
    label: "Palestine Polytechnic University",
    website: "http://www.ppu.edu/",
  },
  {
    value: "5977",
    label: "Palestine Technical College-Dier Elbalah",
    website: "http://www.ptcdb.edu.ps/",
  },
  {
    value: "5978",
    label: "Palestine Technical University - Kadoorie",
    website: "http://www.ptuk.edu.ps/",
  },
  {
    value: "5979",
    label: "Ummah University",
    website: "http://www.uou.edu.ps/",
  },
  {
    value: "5980",
    label: "University College of Applied Sciences",
    website: "http://www.ucas.edu.ps/",
  },
  {
    value: "5981",
    label: "University of Palestine",
    website: "http://www.up.edu.ps/",
  },
  {
    value: "5982",
    label: "Academia Nacional Superior de Orquesta",
    website: "http://www.oml.pt/anso/",
  },
  {
    value: "5983",
    label: "Escola Nautica Infante D. Henrique",
    website: "http://www.enautica.pt/",
  },
  {
    value: "5984",
    label: "Escola Superior de Artes e Design",
    website: "http://www.esad.pt/",
  },
  {
    value: "5985",
    label: "Escola Superior de Hotelaria e Turismo do Estoril",
    website: "http://www.eshte.pt/",
  },
  {
    value: "5986",
    label: "European University Portugal",
    website: "http://www.europeanuniversity.pt/",
  },
  {
    value: "5987",
    label: "Instituto de Artes Visuais, Design e Marketing - IADE",
    website: "http://www.iade.pt/",
  },
  {
    value: "5988",
    label: "Instituto Piaget",
    website: "http://www.ipiaget.org/",
  },
  {
    value: "5989",
    label: "Instituto Politécnico da Guarda",
    website: "http://www.ipg.pt/",
  },
  {
    value: "5990",
    label: "Instituto Politécnico de Beja",
    website: "http://www.ipbeja.pt/",
  },
  {
    value: "5991",
    label: "Instituto Politécnico de Bragança",
    website: "http://www.ipb.pt/",
  },
  {
    value: "5992",
    label: "Instituto Politécnico de Castelo Branco",
    website: "http://www.ipcb.pt/",
  },
  {
    value: "5993",
    label: "Instituto Politécnico de Coimbra",
    website: "http://www.ipc.pt/",
  },
  {
    value: "5994",
    label: "Instituto Politécnico de Leiria",
    website: "http://www.iplei.pt/",
  },
  {
    value: "5995",
    label: "Instituto Politécnico de Lisboa",
    website: "http://www.ipl.pt/",
  },
  {
    value: "5996",
    label: "Instituto Politécnico de Portalegre",
    website: "http://www.ipportalegre.pt/",
  },
  {
    value: "5997",
    label: "Instituto Politécnico de Santarém",
    website: "http://www.ipsantarem.pt/",
  },
  {
    value: "5998",
    label: "Instituto Politécnico de Setúbal",
    website: "http://www.ips.pt/",
  },
  {
    value: "5999",
    label: "Instituto Politécnico de Tomar",
    website: "http://www.ipt.pt/",
  },
  {
    value: "6000",
    label: "Instituto Politécnico de Viana do Castelo",
    website: "http://www.ipvc.pt/",
  },
  {
    value: "6001",
    label: "Instituto Politécnico do Cávado e do Ave",
    website: "http://www.ipca.pt/",
  },
  {
    value: "6002",
    label: "Instituto Politecnico do Porto",
    website: "http://www.ipp.pt/",
  },
  {
    value: "6003",
    label: "Instituto Superior Bissaya Barreto",
    website: "http://www.isbb.pt/",
  },
  {
    value: "6004",
    label: "Instituto Superior D. Afonso III - INUAF",
    website: "http://www.inuaf-studia.pt/",
  },
  {
    value: "6005",
    label: "Instituto Superior de Assistentes e Intérpretes",
    website: "http://www.isai.pt/",
  },
  {
    value: "6006",
    label: "Instituto Superior de Ciências do Trabalho e da Empresa",
    website: "http://www.iscte.pt/",
  },
  {
    value: "6007",
    label: "Instituto Superior de Ciências Educativas",
    website: "http://www.pedago.pt/isce.htm",
  },
  {
    value: "6008",
    label: "Instituto Superior de Ciências Empresariais e de Turismo",
    website: "http://www.iscet.pt/",
  },
  {
    value: "6009",
    label: "Instituto Superior de Entre Douro e Vouga",
    website: "http://www.isvouga.com/",
  },
  {
    value: "6010",
    label: "Instituto Superior de Gestão - ISG",
    website: "http://www.isg.pt/",
  },
  {
    value: "6011",
    label: "Instituto Superior de Informática e Gestão",
    website: "http://www.cocite.pt/",
  },
  {
    value: "6012",
    label: "Instituto Superior de Línguas e Administração",
    website: "http://www.isla.pt/",
  },
  {
    value: "6013",
    label: "Instituto Superior de Paços de Brandão",
    website: "http://www.ispab.pt/",
  },
  {
    value: "6014",
    label: "Instituto Superior de Psicologia Aplicada",
    website: "http://www.ispa.pt/",
  },
  {
    value: "6015",
    label: "Instituto Superior de Saúde do Alto Ave",
    website: "http://www.isave.edu.pt/",
  },
  {
    value: "6016",
    label: "Instituto Superior de Tecnologias Avançadas - ISTEC",
    website: "http://www.istec.pt/",
  },
  {
    value: "6017",
    label: "Instituto Superior de Transportes e Comunicações",
    website: "http://www.istp.pt/",
  },
  {
    value: "6018",
    label: "Instituto Superior Novas Profissões - INP",
    website: "http://www.inp.pt/",
  },
  {
    value: "6019",
    label: "Instituto Superior Politécnico Gaya",
    website: "http://www.ispgaya.pt/",
  },
  {
    value: "6020",
    label: "Universidade Aberta Lisboa",
    website: "http://www.univ-ab.pt/",
  },
  {
    value: "6021",
    label: "Universidade Atlântica",
    website: "http://www.uatla.pt/",
  },
  {
    value: "6022",
    label: "Universidade Autónoma de Lisboa Luís de Camoes",
    website: "http://www.universidade-autonoma.pt/",
  },
  {
    value: "6023",
    label: "Universidade Catolica Portuguesa",
    website: "http://www.ucp.pt/",
  },
  {
    value: "6024",
    label: "Universidade da Beira Interior",
    website: "http://www.ubi.pt/",
  },
  {
    value: "6025",
    label: "Universidade da Madeira",
    website: "http://www.uma.pt/",
  },
  {
    value: "6026",
    label: "Universidade de Aveiro",
    website: "http://www.ua.pt/",
  },
  {
    value: "6027",
    label: "Universidade de Coimbra",
    website: "http://www.uc.pt/",
  },
  {
    value: "6028",
    label: "Universidade de Evora",
    website: "http://www.uevora.pt/",
  },
  {
    value: "6029",
    label: "Universidade de Lisboa",
    website: "http://www.ul.pt/",
  },
  {
    value: "6030",
    label: "Universidade de Tras-os-Montes e Alto Douro",
    website: "http://www.utad.pt/",
  },
  {
    value: "6031",
    label: "Universidade do Algarve",
    website: "http://www.ualg.pt/",
  },
  {
    value: "6032",
    label: "Universidade do Minho",
    website: "http://www.uminho.pt/",
  },
  {
    value: "6033",
    label: "Universidade do Porto",
    website: "http://www.up.pt/",
  },
  {
    value: "6034",
    label: "Universidade dos Acores",
    website: "http://www.uac.pt/",
  },
  {
    value: "6035",
    label: "Universidade Fernando Pessoa",
    website: "http://www.ufp.pt/",
  },
  {
    value: "6036",
    label: "Universidade Independente",
    website: "http://www.uni.pt/",
  },
  {
    value: "6037",
    label: "Universidade Internacional Lisboa",
    website: "http://www.uinternacional.pt/",
  },
  {
    value: "6038",
    label: "Universidade Lusiada",
    website: "http://www.ulusiada.pt/",
  },
  {
    value: "6039",
    label: "Universidade Lusófona de Humanidades e Technologias",
    website: "http://www.ulusofona.pt/",
  },
  {
    value: "6040",
    label: "Universidade Moderna de Lisboa",
    website: "http://www.umoderna.pt/",
  },
  {
    value: "6041",
    label: "Universidade Nova de Lisboa",
    website: "http://www.unl.pt/",
  },
  {
    value: "6042",
    label: "Universidade Portucalense Infante D. Henrique",
    website: "http://www.uportu.pt/",
  },
  {
    value: "6043",
    label: "Universidade Tecnica de Lisboa",
    website: "http://www.utl.pt/",
  },
  {
    value: "6044",
    label: "Universidad Americana",
    website: "http://www.uamericana.edu.py/",
  },
  {
    value: "6045",
    label: "Universidad Autónoma de Asunción",
    website: "http://www.uaa.edu.py/",
  },
  {
    value: "6046",
    label: "Universidad Autónoma del Paraguay",
    website: "http://www.uap.edu.py/",
  },
  {
    value: "6047",
    label: "Universidad Católica Nuestra Senora de la Asunción",
    website: "http://www.uc.edu.py/",
  },
  {
    value: "6048",
    label: "Universidad Columbia del Paraguay",
    website: "http://www.columbia.edu.py/",
  },
  {
    value: "6049",
    label: "Universidad Comunera",
    website: "http://www.ucom.edu.py/",
  },
  {
    value: "6050",
    label: "Universidad de la Integración de las Americas (UNIDAD)",
    website: "http://www.unida.edu.py/",
  },
  {
    value: "6051",
    label: "Universidad del Cono Sur de las Américas",
    website: "http://www.ucsa.edu.py/",
  },
  {
    value: "6052",
    label: "Universidad del Norte",
    website: "http://www.uninorte.edu.py/",
  },
  {
    value: "6053",
    label: "Universidad Metropolitana de Asunción",
    website: "http://www.uma.edu.py/",
  },
  {
    value: "6054",
    label: "Universidad Nacional de Asunción",
    website: "http://www.una.py/",
  },
  {
    value: "6055",
    label: "Universidad Nacional del Este",
    website: "http://www.une.edu.py/",
  },
  {
    value: "6056",
    label: "Universidad Privada del Este",
    website: "http://www.upe.edu.py/",
  },
  {
    value: "6057",
    label: "Northwestern University In Qatar",
    website: "http://www.qatar.northwestern.edu/",
  },
  {
    value: "6058",
    label: "University of Qatar",
    website: "http://www.qu.edu.qa/",
  },
  {
    value: "6059",
    label: "Université de la Reunion",
    website: "http://www.univ-reunion.fr/",
  },
  {
    value: "6060",
    label: "1 December University of Alba Iulia",
    website: "http://www.uab.ro/",
  },
  {
    value: "6061",
    label: "Academia de Studii Economice din Bucuresti",
    website: "http://www.ase.ro/",
  },
  {
    value: "6062",
    label: "Academia Tehnica Militara",
    website: "http://www.mta.ro/",
  },
  {
    value: "6063",
    label: 'Academy of Arts "George Enescu" Iasi',
    website: "http://www.arteiasi.ro/",
  },
  {
    value: "6064",
    label: 'Academy of Music "Georghe Dima" Cluj-Napoca',
    website: "http://www.amgd.eu/",
  },
  {
    value: "6065",
    label: "AISTEDA",
    website: "http://www.aistedaab.ro/",
  },
  {
    value: "6066",
    label: "Babes-Bolyai University of Cluj-Napoca",
    website: "http://www.ubbcluj.ro/",
  },
  {
    value: "6067",
    label: "Banat's University of Agricultural Sciences",
    website: "http://www.usab-tm.ro/",
  },
  {
    value: "6068",
    label: "Constantin Brancoveanu University Pitesti",
    website: "http://www.univcb.ro/",
  },
  {
    value: "6069",
    label: "Emanuel University",
    website: "http://www.emanuel.ro/",
  },
  {
    value: "6070",
    label: 'Institute of Architecture "Ion Mincu" Bucharest',
    website: "http://www.iaim.ro/",
  },
  {
    value: "6071",
    label: "Maritime University Constanta",
    website: "http://www.universitateamaritima.ro/",
  },
  {
    value: "6072",
    label: "National Academy for Physical Education and Sports Bucharest",
    website: "http://www.anefs-edu.ro/",
  },
  {
    value: "6073",
    label: "National School of Political and Administrative Studies Bucharest",
    website: "http://www.snspa.ro/",
  },
  {
    value: "6074",
    label: "National University of Arts",
    website: "http://www.unarte.ro/",
  },
  {
    value: "6075",
    label: "National University of Music",
    website: "http://www.unmb.ro/",
  },
  {
    value: "6076",
    label: "National University of Theater and Film Arts",
    website: "http://www.unatc.ro/",
  },
  {
    value: "6077",
    label: "North University of Baia Mare",
    website: "http://www.ubm.ro/",
  },
  {
    value: "6078",
    label: "Oradea University",
    website: "http://www.oradeauniversity.com",
  },
  {
    value: "6079",
    label: "Petru Maior University of Targu Mures",
    website: "http://www.upm.ro/",
  },
  {
    value: "6080",
    label: "Romanian-American University",
    website: "http://www.rau.ro/",
  },
  {
    value: "6081",
    label: "Spiru Haret University",
    website: "http://www.spiruharet.ro/",
  },
  {
    value: "6082",
    label: "Targu-Mures University of Theatre",
    website: "http://www.uat.ro/",
  },
  {
    value: "6083",
    label: "Technical University of Civil Engineering Bucharest",
    website: "http://www.utcb.ro/",
  },
  {
    value: "6084",
    label: "Technical University of Cluj-Napoca",
    website: "http://www.utcluj.ro/",
  },
  {
    value: "6085",
    label: "Technical University of Iasi",
    website: "http://www.tuiasi.ro/",
  },
  {
    value: "6086",
    label: "Technical University of Timisoara",
    website: "http://www.utt.ro/",
  },
  {
    value: "6087",
    label: 'Universitatea de Vest "Vasile Goldi&#351;" ',
    website: "http://www.uvvg.ro/",
  },
  {
    value: "6088",
    label: 'University "Aurel Vlaicu" Arad',
    website: "http://www.uav.ro/",
  },
  {
    value: "6089",
    label: "University Lucian Blaga of Sibiu",
    website: "http://www.ulbsibiu.ro/",
  },
  {
    value: "6090",
    label: "University of Agriculture and Veterinary Medicine Bucharest",
    website: "http://www.usab.ro/",
  },
  {
    value: "6091",
    label: "University of Agriculture and Veterinary Medicine Cluj-Napoca",
    website: "http://www.usamvcluj.ro/",
  },
  {
    value: "6092",
    label: "University of Agriculture and Veterinary Medicine Iasi",
    website: "http://www.univagro-iasi.ro/",
  },
  {
    value: "6093",
    label: "University of Agriculture and Veterinary Medicine Timisoara",
    website: "http://www.usab-tm.ro/",
  },
  {
    value: "6094",
    label: "University of Art and Design Cluj-Napoca",
    website: "http://www.uartdcluj.ro/",
  },
  {
    value: "6095",
    label: "University of Bacau",
    website: "http://www.ub.ro/",
  },
  {
    value: "6096",
    label: "University of Bucharest",
    website: "http://www.unibuc.ro/",
  },
  {
    value: "6097",
    label: "University of Constanta",
    website: "http://www.univ-ovidius.ro/",
  },
  {
    value: "6098",
    label: "University of Constanta Medical School",
    website: "http://www.ovidunivconstanta-edu.org/",
  },
  {
    value: "6099",
    label: "University of Craiova",
    website: "http://www.central.ucv.ro/",
  },
  {
    value: "6100",
    label: "University of Galatzi",
    website: "http://www.ugal.ro/",
  },
  {
    value: "6101",
    label: "University of Iasi",
    website: "http://www.uaic.ro/",
  },
  {
    value: "6102",
    label: "University of Medicine and Pharmacology of Oradea",
    website: "http://www.oradeauniversity.com/",
  },
  {
    value: "6103",
    label: "University of Medicine and Pharmacy of Bucharest",
    website: "http://www.univermed-cdgm.ro/",
  },
  {
    value: "6104",
    label: "University of Medicine and Pharmacy of Cluj-Napoca",
    website: "http://www.umfcluj.ro/",
  },
  {
    value: "6105",
    label: "University of Medicine and Pharmacy of Iasi",
    website: "http://www.umfiasi.ro/",
  },
  {
    value: "6106",
    label: "University of Medicine and Pharmacy of Targu Mures",
    website: "http://www.umftgm.ro/",
  },
  {
    value: "6107",
    label: "University of Medicine and Pharmacy of Timisoara",
    website: "http://www.umft.ro/",
  },
  {
    value: "6108",
    label: "University of Oradea",
    website: "http://www.uoradea.ro/",
  },
  {
    value: "6109",
    label: "University of Petrosani",
    website: "http://www.upet.ro/",
  },
  {
    value: "6110",
    label: "University of Pitesti",
    website: "http://www.upit.ro/",
  },
  {
    value: "6111",
    label: "University of Resita",
    website: "http://www.uem.ro/",
  },
  {
    value: "6112",
    label: "University of Sibiu",
    website: "http://www.sibiu.ro/",
  },
  {
    value: "6113",
    label: "University of Suceava",
    website: "http://www.usv.ro/",
  },
  {
    value: "6114",
    label: "University of Targu Jiu",
    website: "http://www.utgjiu.ro/",
  },
  {
    value: "6115",
    label: "University Oil- Gas Ploiesti",
    website: "http://www.upg-ploiesti.ro/",
  },
  {
    value: "6116",
    label: 'University "Petre Andrei" Iasi',
    website: "http://www.upa.ro/",
  },
  {
    value: "6117",
    label: "University Politehnica of Bucharest",
    website: "http://www.pub.ro/",
  },
  {
    value: "6118",
    label: 'University "Titu Maiorescu"',
    website: "http://www.utm.ro/",
  },
  {
    value: "6119",
    label: 'University "Transilvany" of Brasov',
    website: "http://www.unitbv.ro/",
  },
  {
    value: "6120",
    label: "Valahia University of Targoviste",
    website: "http://www.valahia.ro/",
  },
  {
    value: "6121",
    label: "West University of Timisoara",
    website: "http://www.uvt.ro/",
  },
  {
    value: "6122",
    label: "Drzavni Univerzitet u Novom Pazaru",
    website: "http://www.np.ac.rs/",
  },
  {
    value: "6123",
    label: "European University",
    website: "http://www.eu.ac.rs/",
  },
  {
    value: "6124",
    label: "International University of Novi Pazar",
    website: "http://www.uninp.edu.rs/",
  },
  {
    value: "6125",
    label: "Megatrend University of Applied Sciences",
    website: "http://www.megatrend-edu.net/",
  },
  {
    value: "6126",
    label: "Metropolitan University",
    website: "http://www.metropolitan.edu.rs/",
  },
  {
    value: "6127",
    label: "Privredna Akademija (Business Academy)",
    website: "http://www.privrednaakademija.edu.rs/",
  },
  {
    value: "6128",
    label: "Singidunum University",
    website: "http://www.singidunum.edu.rs/",
  },
  {
    value: "6129",
    label: "University of Belgrade",
    website: "http://www.bg.ac.rs/",
  },
  {
    value: "6130",
    label: "University of Fine Arts Belgrade",
    website: "http://www.arts.bg.ac.rs/",
  },
  {
    value: "6131",
    label: "University of Kragujevac",
    website: "http://www.kg.ac.rs/",
  },
  {
    value: "6132",
    label: "University of Nis",
    website: "http://www.ni.ac.rs/",
  },
  {
    value: "6133",
    label: "University of Novi Sad",
    website: "http://www.uns.ac.rs/",
  },
  {
    value: "6134",
    label: "University of Priština",
    website: "http://www.pr.ac.rs/",
  },
  {
    value: "6135",
    label: "Academy of Management and Entrepreneurship",
    website: "http://www.ame.ru/",
  },
  {
    value: "6136",
    label: "Adygeja State University",
    website: "http://www.adygnet.ru/",
  },
  {
    value: "6137",
    label: "Altai State Medical University",
    website: "http://www.asmu.ru/",
  },
  {
    value: "6138",
    label: "Altai State Technical University",
    website: "http://www.astu.secna.ru/",
  },
  {
    value: "6139",
    label: "Altai State University",
    website: "http://www.asu.ru/",
  },
  {
    value: "6140",
    label: "Amur State University",
    website: "http://www.amursu.ru/",
  },
  {
    value: "6141",
    label: "Arhangelsk State Technical University",
    website: "http://www.agtu.ru/",
  },
  {
    value: "6142",
    label: "Astrahan State Medical Academy",
    website: "http://agma.astranet.ru/",
  },
  {
    value: "6143",
    label: "Astrahan State Technical University",
    website: "http://www.astu.org/",
  },
  {
    value: "6144",
    label: "Astrakhan State University",
    website: "http://www.aspu.ru/",
  },
  {
    value: "6145",
    label: "Baikal National University of Economics and Law",
    website: "http://www.isea.ru/",
  },
  {
    value: "6146",
    label: "Baltic State Technical University",
    website: "http://www.voenmeh.ru/",
  },
  {
    value: "6147",
    label: "Bashkir State Agrarian University",
    website: "http://www.bashedu.ru/vuz/bgau_e.htm",
  },
  {
    value: "6148",
    label: "Bashkir State Medical University",
    website: "http://www.bashedu.ru/vuz/bgmu_e.htm",
  },
  {
    value: "6149",
    label: "Bashkir State Pedagogical University named After M. Akmullah",
    website: "http://www.bspu.ru/",
  },
  {
    value: "6150",
    label: "Bashkir State University",
    website: "http://www.bashedu.ru/",
  },
  {
    value: "6151",
    label: "Bauman Moscow State Technical University",
    website: "http://www.bmstu.ru/",
  },
  {
    value: "6152",
    label: "Belgorod State Agricultural Academy",
    website: "http://www.bsaa.ru/",
  },
  {
    value: "6153",
    label: "Belgorod State Technical University",
    website: "http://www.bstu.ru/",
  },
  {
    value: "6154",
    label: "Belgorod State University",
    website: "http://www.bsu.edu.ru/",
  },
  {
    value: "6155",
    label: "Birsk State Pedagogical Institute",
    website: "http://www.bashedu.ru/vuz/birskgpi_e.htm",
  },
  {
    value: "6156",
    label: "Biysk State Padagogical University after V.M. Shukshin ",
    website: "http://www.bigpi.biysk.ru/wwwsite/",
  },
  {
    value: "6157",
    label: "Bratsk State Technical University",
    website: "http://www.brstu.ru/",
  },
  {
    value: "6158",
    label: "Brjansk State Technical University",
    website: "http://www.tu-bryansk.ru/",
  },
  {
    value: "6159",
    label: "Burjat State University",
    website: "http://www.bsu.burnet.ru/",
  },
  {
    value: "6160",
    label: "Cheljabinsk State Institute of Teacher Training",
    website: "http://cspi.urc.ac.ru/",
  },
  {
    value: "6161",
    label: "Cheljabinsk State University",
    website: "http://www.csu.ac.ru/",
  },
  {
    value: "6162",
    label: "Cheljabinsk University of Agricultural Engineering",
    website: "http://www.urc.ac.ru/Universities/AGRO/",
  },
  {
    value: "6163",
    label: "Chuvash State University",
    website: "http://www.chuvsu.ru/",
  },
  {
    value: "6164",
    label: "Dagestan State University",
    website: "http://www.dgu.ru/",
  },
  {
    value: "6165",
    label: "Don State Agrarian University",
    website: "http://www.dongau.ru/",
  },
  {
    value: "6166",
    label: "Don State Technical University",
    website: "http://www.dstu.edu.ru/",
  },
  {
    value: "6167",
    label: "Dubna International University for Nature, Society and Man",
    website: "http://www.uni-dubna.ru/",
  },
  {
    value: "6168",
    label: "East-Siberian State Institute of Culture",
    website: "http://www.vsgaki.burnet.ru/",
  },
  {
    value: "6169",
    label: "East-Siberian State University",
    website: "http://www.vsgtu.eastsib.ru/",
  },
  {
    value: "6170",
    label: "East-Siberian State University of Technology",
    website: "http://www.esstu.ru/",
  },
  {
    value: "6171",
    label: "European University at St.Petersburg",
    website: "http://www.eu.spb.ru/",
  },
  {
    value: "6172",
    label: "Far Easten State University of Humanities",
    website: "http://www.khspu.ru/",
  },
  {
    value: "6173",
    label: "Far Eastern State Technical Fisheries University",
    website: "http://www.dalrybvtuz.ru/",
  },
  {
    value: "6174",
    label: "Far Eastern State Technical University",
    website: "http://www.festu.ru/",
  },
  {
    value: "6175",
    label: "Far Eastern State University",
    website: "http://www.dvgu.ru/",
  },
  {
    value: "6176",
    label: "Far East State Transport University",
    website: "http://www.dvgups.ru/",
  },
  {
    value: "6177",
    label: "Finance Academy",
    website: "http://www.fa.ru/",
  },
  {
    value: "6178",
    label: "Gnesins Russian Academy of Music",
    website: "http://www.gnesin-academy.ru/",
  },
  {
    value: "6179",
    label: "Gorno-Altaisk State University",
    website: "http://www.gasu.ru/",
  },
  {
    value: "6180",
    label: "Gubkin Russian State University of Oil and Gas",
    website: "http://www.gubkin.ru/",
  },
  {
    value: "6181",
    label: "Herzen State Pedagogical University of Russia",
    website: "http://www.herzen.spb.ru/",
  },
  {
    value: "6182",
    label: "Higher School of Economics",
    website: "http://www.hse.ru/",
  },
  {
    value: "6183",
    label: "Immanuel Kant State University of Russia",
    website: "http://www.kantiana.ru/",
  },
  {
    value: "6184",
    label: "I.M. Sechenov Moscow Medical Academy",
    website: "http://www.mma.ru/",
  },
  {
    value: "6185",
    label: "Institute for the Economy in Transition",
    website: "http://www.iet.ru/",
  },
  {
    value: "6186",
    label: "Institute of Business and Politics",
    website: "http://www.ibp-moscow.ru/",
  },
  {
    value: "6187",
    label: "Institute of Management and Economy",
    website: "http://www.ime.ru/",
  },
  {
    value: "6188",
    label: "International Banking Institute, St. Petersburg",
    website: "http://www.ibi.spb.ru/",
  },
  {
    value: "6189",
    label: "International East-European University",
    website: "http://www.ieeu.udm.ru/",
  },
  {
    value: "6190",
    label: "International University of Engineering, Moscow",
    website: "http://www.miu.ru/",
  },
  {
    value: "6191",
    label: "International University of Fundamental Studies, St. Petersburg",
    website: "http://www.iufs.edu/",
  },
  {
    value: "6192",
    label: "Irkutsk State Academy of Economics",
    website: "http://www.isea.irk.ru/",
  },
  {
    value: "6193",
    label: "Irkutsk State Technical University",
    website: "http://www.istu.irk.ru/",
  },
  {
    value: "6194",
    label: "Irkutsk State University",
    website: "http://www.isu.ru/",
  },
  {
    value: "6195",
    label: "Ivanovo State Academy of Medicine",
    website: "http://isma.indi.ru/",
  },
  {
    value: "6196",
    label: "Ivanovo State Power University",
    website: "http://www.ispu.ru/",
  },
  {
    value: "6197",
    label: "Ivanovo State Textile Academy",
    website: "http://education.ivanovo.ru:8101/IGTA/",
  },
  {
    value: "6198",
    label: "Ivanovo State University",
    website: "http://www.ivanovo.ac.ru/",
  },
  {
    value: "6199",
    label: "Ivanovo State University of Chemistry and Technology",
    website: "http://www.isuct.ru/",
  },
  {
    value: "6200",
    label: "Izhevsk State Technical University",
    website: "http://www.istu.ru/",
  },
  {
    value: "6201",
    label: "Jewish University in Moscow",
    website: "http://www.jum.ru/",
  },
  {
    value: "6202",
    label: "Kabardino-Balkarian State University",
    website: "http://www.kbsu.ru/",
  },
  {
    value: "6203",
    label: "Kaliningrad State Technical University",
    website: "http://www.klgtu.ru/",
  },
  {
    value: "6204",
    label: "Kalmyk State University",
    website: "http://www.kalmsu.ru/",
  },
  {
    value: "6205",
    label: "Kaluga State Pedagogical University",
    website: "http://kspu.kaluga.ru/",
  },
  {
    value: "6206",
    label: "Kamchatka State University Vitus Bering",
    website: "http://www.kamgu.ru/",
  },
  {
    value: "6207",
    label: "Karelian State Pedagogical University",
    website: "http://kspu.ptz.ru/",
  },
  {
    value: "6208",
    label: "Kazan State Academy of Architecture and Civil Engineering",
    website: "http://www.ksaba.ru/",
  },
  {
    value: "6209",
    label: "Kazan State Finance and Economics Institute ",
    website: "http://www.ksfei.ru/",
  },
  {
    value: "6210",
    label: "Kazan State Music Conservatory",
    website: "http://www.kazanconservatory.ru/",
  },
  {
    value: "6211",
    label: "Kazan State Pedagogical University",
    website: "http://www.kgpu.ru/",
  },
  {
    value: "6212",
    label: "Kazan State University",
    website: "http://www.ksu.ru/",
  },
  {
    value: "6213",
    label: "Kazan State University of Medicine",
    website: "http://www.kgmu.kcn.ru/",
  },
  {
    value: "6214",
    label: "Kazan State University of Technology",
    website: "http://www.kai.ru/",
  },
  {
    value: "6215",
    label: "Kemerovo State University",
    website: "http://www.kemsu.ru/",
  },
  {
    value: "6216",
    label: "Khabarovsk State Academy of Economics and Law",
    website: "http://www.ael.ru/",
  },
  {
    value: "6217",
    label: "Khabarovsk State University of Technology",
    website: "http://www.khstu.ru/",
  },
  {
    value: "6218",
    label: "Komsomolsk-on-Amur State Technical University",
    website: "http://www.knastu.ru/",
  },
  {
    value: "6219",
    label: "Kostroma State Technological University",
    website: "http://www.kstu.edu.ru/",
  },
  {
    value: "6220",
    label: "Krasnoyarsk State Technical University",
    website: "http://www.kgtu.runnet.ru/",
  },
  {
    value: "6221",
    label: "Kuban State University",
    website: "http://www.kubsu.ru/",
  },
  {
    value: "6222",
    label: "Kuban State University of Agriculture",
    website: "http://www.kubagro.ru/",
  },
  {
    value: "6223",
    label: "Kuban State University of Technology",
    website: "http://www.kubstu.ru/",
  },
  {
    value: "6224",
    label: "Kurgan International University",
    website: "http://www.kiu.ru/",
  },
  {
    value: "6225",
    label: "Kursk State Medical University",
    website: "http://www.ksmu.kursknet.ru/",
  },
  {
    value: "6226",
    label: "Kursk State Technical University",
    website: "http://www.kstu.kursk.ru/",
  },
  {
    value: "6227",
    label: "Kursk State University",
    website: "http://www.kursk-uni.ru/",
  },
  {
    value: "6228",
    label: "Kuzbass State Technical University",
    website: "http://www.kuzstu.ac.ru/",
  },
  {
    value: "6229",
    label: "Linguistic University of Nizhny Novgorod",
    website: "http://www.lunn.sci-nnov.ru/",
  },
  {
    value: "6230",
    label: "Lipeck State Technical University",
    website: "http://www.stu.lipetsk.ru/",
  },
  {
    value: "6231",
    label: "Magnitogorsk State University",
    website: "http://www.masu.ru/",
  },
  {
    value: "6232",
    label: "Marij State Technical University",
    website: "http://www.marstu.mari.ru/",
  },
  {
    value: "6233",
    label: "Marij State University",
    website: "http://margu.mari.ru/",
  },
  {
    value: "6234",
    label: "Maxim Gorky Institute of Literature",
    website: "http://filine.centro.ru/Gorky/",
  },
  {
    value: "6235",
    label: "Mendeleev Russian University of Chemical Technology",
    website: "http://www.muctr.edu.ru/",
  },
  {
    value: "6236",
    label: "Modern Institute of Managament",
    website: "http://miom.org/",
  },
  {
    value: "6237",
    label: "Modern University for the Humanities",
    website: "http://www.muh.ru/",
  },
  {
    value: "6238",
    label: "Mordovian State University",
    website: "http://www.mrsu.ru/",
  },
  {
    value: "6239",
    label: "Moscow External University of the Humanities",
    website: "http://www.chat.ru/~megu/",
  },
  {
    value: "6240",
    label: "Moscow Institute of Physics and Technology",
    website: "http://www.mipt.ru/",
  },
  {
    value: "6241",
    label: "Moscow International Higher Business School (MIRBIS)",
    website: "http://www.mirbis.ru/",
  },
  {
    value: "6242",
    label: "Moscow P. I. Tchaikovsky Conservatory",
    website: "http://www.mosconsv.ru/",
  },
  {
    value: "6243",
    label: "Moscow Power Engineering Institute (Technical University)",
    website: "http://www.mpei.ac.ru/",
  },
  {
    value: "6244",
    label: "Moscow State Academy of Applied Biotechnology",
    website: "http://www.msaab.ru/",
  },
  {
    value: "6245",
    label: "Moscow State Automobile and Road Technical University",
    website: "http://www.madi.ru/",
  },
  {
    value: "6246",
    label: "Moscow State Industrial University",
    website: "http://www.msiu.ru/",
  },
  {
    value: "6247",
    label:
      "Moscow State Institute of International Relations MFA Russia (MGIMO-University)",
    website: "http://www.mgimo.ru/",
  },
  {
    value: "6248",
    label:
      "Moscow State Institute of Radiotechnics, Electronics and Automation (Technical University)",
    website: "http://www.mirea.ru/",
  },
  {
    value: "6249",
    label: "Moscow State Institute of Steel and Alloys",
    website: "http://www.misis.ru/",
  },
  {
    value: "6250",
    label: "Moscow State Technical University of Civil Aviation",
    website: "http://www.mstuca.ru/",
  },
  {
    value: "6251",
    label: "Moscow State Textile University A.N. Kosygin",
    website: "http://www.msta.ac.ru/",
  },
  {
    value: "6252",
    label: "Moscow State University",
    website: "http://www.msu.ru/",
  },
  {
    value: "6253",
    label: "Moscow State University M.V. Lomonosov",
    website: "http://www.msu.ru/",
  },
  {
    value: "6254",
    label: "Moscow State University of Civil Engineering",
    website: "http://mgsu.norna.ru/",
  },
  {
    value: "6255",
    label: "Moscow State University of Commerce",
    website: "http://www.rsute.ru/",
  },
  {
    value: "6256",
    label: "Moscow State University of Culture",
    website: "http://www.msuc.edu.ru/",
  },
  {
    value: "6257",
    label: "Moscow State University of Ecological Engineering",
    website: "http://www.msuie.ru/",
  },
  {
    value: "6258",
    label: "Moscow State University of Economics, Statistics and Informatics",
    website: "http://www.mesi.ru/",
  },
  {
    value: "6259",
    label: "Moscow State University of Environmental Engineering",
    website: "http://www.msuee.ru/",
  },
  {
    value: "6260",
    label: "Moscow State University of Forestry Engineering",
    website: "http://www.mgul.ac.ru/",
  },
  {
    value: "6261",
    label: "Moscow State University of Geodesy and Cartography",
    website: "http://www.miigaik.ru/",
  },
  {
    value: "6262",
    label: "Moscow State University of Railway Transport",
    website: "http://www.miit.ru/",
  },
  {
    value: "6263",
    label: "Moscow State University of Technology (Stankin)",
    website: "http://www.stankin.ru/",
  },
  {
    value: "6264",
    label: "Moscow Technical University of Informatics and Communication",
    website: "http://www.mtuci.ru/",
  },
  {
    value: "6265",
    label: "Moscow University Touro",
    website: "http://www.touro.ru/",
  },
  {
    value: "6266",
    label: "Murmansk State Technical University",
    website: "http://www.mstu.edu.ru/",
  },
  {
    value: "6267",
    label: "National Research University of Electronic Technology",
    website: "http://www.miet.ru/",
  },
  {
    value: "6268",
    label: "Nayanova University",
    website: "http://www.nayanova.edu/",
  },
  {
    value: "6269",
    label: "Nevsky Institute of Language and Culture",
    website: "http://www.nilc.spb.ru/",
  },
  {
    value: "6270",
    label: "New Economic School",
    website: "http://www.nes.ru/",
  },
  {
    value: "6271",
    label: "NGO Vladikavkaz Institute of Economics, Management, and Law",
    website: "http://www.vieup.ru/",
  },
  {
    value: "6272",
    label: "Nizhny Novgorod State Academy of Medicine",
    website: "http://www.n-nov.mednet.com/",
  },
  {
    value: "6273",
    label: "Nizhny Novgorod State Architectural - Building University",
    website: "http://www.ngasu.sci-nnov.ru/",
  },
  {
    value: "6274",
    label: "Nizhny Novgorod State Technical University",
    website: "http://www.nntu.sci-nnov.ru/",
  },
  {
    value: "6275",
    label: "Nizhny Novgorod State University",
    website: "http://www.unn.ac.ru/",
  },
  {
    value: "6276",
    label: "Northern State Medical University",
    website: "http://www.nsmu.ru/",
  },
  {
    value: "6277",
    label: "North Ossetian State University",
    website: "http://www.nosu.ru/",
  },
  {
    value: "6278",
    label: "North-West State Technical University",
    website: "http://www.nwpi.ru/",
  },
  {
    value: "6279",
    label: "Novgorod State University",
    website: "http://www.novsu.ac.ru/",
  },
  {
    value: "6280",
    label: "Novosibirsk State Academy of Economics and Mangement",
    website: "http://cit.drbit.com.ru/",
  },
  {
    value: "6281",
    label: "Novosibirsk State Agricultural University",
    website: "http://www.nsau.edu.ru/",
  },
  {
    value: "6282",
    label: "Novosibirsk State Medical Academy",
    website: "http://www.medin.nsc.ru/",
  },
  {
    value: "6283",
    label: "Novosibirsk State Music Academy M. Glinka",
    website: "http://www.conservatoire.ru/",
  },
  {
    value: "6284",
    label: "Novosibirsk State Technical University",
    website: "http://www.nstu.ru/",
  },
  {
    value: "6285",
    label: "Novosibirsk State University",
    website: "http://www.nsu.ru/",
  },
  {
    value: "6286",
    label: "Obninsk State Technical University for Nuclear Power Engineering",
    website: "http://www.iate.obninsk.ru/",
  },
  {
    value: "6287",
    label: "Omsk State Agrarian University",
    website: "http://www.omgau.ru/",
  },
  {
    value: "6288",
    label: "Omsk State Medicical Academy",
    website: "http://www.omsk-osma.ru/",
  },
  {
    value: "6289",
    label: "Omsk State Pedagogical University",
    website: "http://www.omsk.edu/",
  },
  {
    value: "6290",
    label: "Omsk State Technical University",
    website: "http://www.omgtu.ru/",
  },
  {
    value: "6291",
    label: "Omsk State Transport University",
    website: "http://www.omgups.ru/",
  },
  {
    value: "6292",
    label: "Omsk State University",
    website: "http://www.univer.omsk.su/",
  },
  {
    value: "6293",
    label: "Orenburg State University",
    website: "http://www.osu.ru/",
  },
  {
    value: "6294",
    label: "Oriental University",
    website: "http://www.orun.ru/",
  },
  {
    value: "6295",
    label: "Penza State University",
    website: "http://www.pnzgu.ru/",
  },
  {
    value: "6296",
    label: "People' s Friendship University of Russia",
    website: "http://www.pfu.edu.ru/",
  },
  {
    value: "6297",
    label: "Perm State Academy of Agriculture",
    website: "http://www.pstu.ac.ru/",
  },
  {
    value: "6298",
    label: "Perm State Medical Academy",
    website: "http://www.psma.ru/",
  },
  {
    value: "6299",
    label: "Perm State Pedagogical University",
    website: "http://www.pspu.ac.ru/",
  },
  {
    value: "6300",
    label: "Perm State Pharmaceutical Academy",
    website: "http://www.psfa.ru/",
  },
  {
    value: "6301",
    label: "Perm State Technical University",
    website: "http://www.pstu.ac.ru/",
  },
  {
    value: "6302",
    label: "Perm State University",
    website: "http://www.psu.ru/",
  },
  {
    value: "6303",
    label: "Petrozavodsk State University",
    website: "http://www.karelia.ru/psu/",
  },
  {
    value: "6304",
    label: "Pjatigorsk State Linguistic University",
    website: "http://www.pglu.ru/",
  },
  {
    value: "6305",
    label: "Plekhanov Russian Academy of Economics",
    website: "http://www.rea.ru/",
  },
  {
    value: "6306",
    label: "Pomorsk State University",
    website: "http://www.pomorsu.ru/",
  },
  {
    value: "6307",
    label: "Pomor State University",
    website: "http://www.pomorsu.ru/",
  },
  {
    value: "6308",
    label: "Rostov State Medical University",
    website: "http://www.rsmu.da.ru/",
  },
  {
    value: "6309",
    label: "Rostov State University",
    website: "http://www.rsu.ru/",
  },
  {
    value: "6310",
    label: "Russian Academy of Arts",
    website: "http://www.rah.ru/",
  },
  {
    value: "6311",
    label: "Russian Academy of Theatre Arts",
    website: "http://www.gitis.net/",
  },
  {
    value: "6312",
    label: "Russian Customs Academy, Vladivostok Branch",
    website: "http://www.vfrta.ru/",
  },
  {
    value: "6313",
    label: "Russian State Geological Prospecting University ",
    website: "http://www.mgri-rggru.ru/",
  },
  {
    value: "6314",
    label: "Russian State Hydrometeorological University",
    website: "http://www.rshu.ru/",
  },
  {
    value: "6315",
    label: "Russian State Medical University",
    website: "http://www.rsmu.ru/",
  },
  {
    value: "6316",
    label: "Russian State University for the Humanities",
    website: "http://www.rsuh.ru/",
  },
  {
    value: "6317",
    label: "Ryazan State Pedagogical University",
    website: "http://www.ttc.ryazan.ru/",
  },
  {
    value: "6318",
    label: "Rybinsk State Academy of Aviational Technology",
    website: "http://www.rgata.yaroslavl.ru/",
  },
  {
    value: "6319",
    label: "Sakhalin State University",
    website: "http://www.sakhgu.sakhalin.ru/",
  },
  {
    value: "6320",
    label: "Samara State Academy for Railway Transportation",
    website: "http://www.samiit.ru/",
  },
  {
    value: "6321",
    label: "Samara State Academy of Architecture and Civil Engineering",
    website: "http://www.ssaba.smr.ru/",
  },
  {
    value: "6322",
    label: "Samara State Aerospace University",
    website: "http://www.ssau.ru/",
  },
  {
    value: "6323",
    label: "Samara State Agricultural Academy",
    website: "http://www.ssaa.ru/",
  },
  {
    value: "6324",
    label: "Samara State Medical University",
    website: "http://www.samsmu.ru/",
  },
  {
    value: "6325",
    label: "Samara State Technical University",
    website: "http://www.sstu.samara.ru/",
  },
  {
    value: "6326",
    label: "Samara State University",
    website: "http://www.ssu.samara.ru/",
  },
  {
    value: "6327",
    label: "Samara State University of Economics",
    website: "http://www.sseu.ru/",
  },
  {
    value: "6328",
    label: "Samara State University of Teacher Training",
    website: "http://www.ssttu.samara.ru/",
  },
  {
    value: "6329",
    label: "Saratov State Academy of Law",
    website: "http://www.sgap.ru/",
  },
  {
    value: "6330",
    label: "Saratov State Agrarian University",
    website: "http://www.sgau.ru/",
  },
  {
    value: "6331",
    label: "Saratov State Medical University",
    website: "http://www.med.sgu.ru/",
  },
  {
    value: "6332",
    label: "Saratov State Socio-Economic University",
    website: "http://www.ssea.runnet.ru/",
  },
  {
    value: "6333",
    label: "Saratov State Technical University",
    website: "http://www.sstu-edu.ru/",
  },
  {
    value: "6334",
    label: "Saratov State University",
    website: "http://www.sgu.ru/",
  },
  {
    value: "6335",
    label: "Siberian Academy of Public Services",
    website: "http://www.sapanet.ru/",
  },
  {
    value: "6336",
    label: "Siberian State Industrial University",
    website: "http://sibsiu.kemerovo.su/",
  },
  {
    value: "6337",
    label: "Siberian State Medical University",
    website: "http://www.ssmu.ru/",
  },
  {
    value: "6338",
    label: "Siberian State Transport University",
    website: "http://www.stu.ru/",
  },
  {
    value: "6339",
    label: "Siberian State University of Telecommunications and Informatics",
    website: "http://www.neic.nsk.su/",
  },
  {
    value: "6340",
    label:
      "Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology",
    website: "http://www.mgavm.ru/",
  },
  {
    value: "6341",
    label: "Smolensk Humanitarian University",
    website: "http://shu.smolensk.su/",
  },
  {
    value: "6342",
    label: "Smolensk State Medical Academy",
    website: "http://www.sgma.info/",
  },
  {
    value: "6343",
    label: "Smolny University",
    website: "http://www.smolny-un.spb.ru/",
  },
  {
    value: "6344",
    label: "Southern Ural State University",
    website: "http://www.tu-chel.ac.ru/",
  },
  {
    value: "6345",
    label: "State Maritine Academy",
    website: "http://www.gma.ru/",
  },
  {
    value: "6346",
    label: "State University of Aerospace Technologies",
    website: "http://www.intstudy.mai.ru/",
  },
  {
    value: "6347",
    label: "State University of Management",
    website: "http://www.guu.ru/",
  },
  {
    value: "6348",
    label: "Stavropol State Technical University",
    website: "http://www.ncstu.ru/",
  },
  {
    value: "6349",
    label: "Stavropol State University",
    website: "http://www.stavsu.ru/",
  },
  {
    value: "6350",
    label: "Sterlitamak State Pedagogical Institute",
    website: "http://www.bashedu.ru/vuz/sgpi_e.htm",
  },
  {
    value: "6351",
    label: "St. Petersburg Institute of Arts and Restoration",
    website: "http://www.spbiir.ru/",
  },
  {
    value: "6352",
    label: "St. Petersburg Institute of Sales Management and Economics",
    website: "http://www.spbtei.ru/",
  },
  {
    value: "6353",
    label:
      "St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture",
    website: "http://artacademy.spb.ru/",
  },
  {
    value: "6354",
    label: "St. Petersburg State Academy for Engineering Economics (ENGECON)",
    website: "http://www.engec.ru/",
  },
  {
    value: "6355",
    label: "St. Petersburg State Agrarian University",
    website: "http://www.spbgau.spb.ru/",
  },
  {
    value: "6356",
    label: "St. Petersburg State Chemical Pharmaceutical Academy",
    website: "http://www.spcpa.ru/",
  },
  {
    value: "6357",
    label: "St. Petersburg State Cinema and TV University",
    website: "http://www.gukit.ru/",
  },
  {
    value: "6358",
    label: "St. Petersburg State Conservatory",
    website: "http://www.conservatory.ru/",
  },
  {
    value: "6359",
    label: "St. Petersburg State Electrotechnical University",
    website: "http://www.eltech.ru/",
  },
  {
    value: "6360",
    label: "St. Petersburg State Forest-Technical Academy",
    website: "http://www.ftacademy.ru/",
  },
  {
    value: "6361",
    label:
      "St. Petersburg State Institute of Technology (Technological University)",
    website: "http://www.gti.spb.ru/",
  },
  {
    value: "6362",
    label: "St. Petersburg State Marine Technical University",
    website: "http://www.smtu.ru/",
  },
  {
    value: "6363",
    label: "St. Petersburg StateMechnikov  Medical Academy",
    website: "http://www.spsmma.com/",
  },
  {
    value: "6364",
    label: "St. Petersburg State Medical Academy",
    website: "http://www.mechnik.spb.ru/",
  },
  {
    value: "6365",
    label: "St. Petersburg State Mining Institute (Technical University)",
    website: "http://www.spmi.edu.ru/",
  },
  {
    value: "6366",
    label: "St. Petersburg State Mountain Institut",
    website: "http://www.gorny-ins.ru/",
  },
  {
    value: "6367",
    label: "St. Petersburg State Pediatric Medical Academy",
    website: "http://www.gpma.ru/",
  },
  {
    value: "6368",
    label: "St. Petersburg State Polytechnical University",
    website: "http://www.spbstu.ru/",
  },
  {
    value: "6369",
    label: "St. Petersburg State Technical University",
    website: "http://www.unilib.neva.ru/",
  },
  {
    value: "6370",
    label: "St. Petersburg State Technological University of Plant Polymers",
    website: "http://www.gturp.spb.ru/",
  },
  {
    value: "6371",
    label: "St. Petersburg State Theatre Arts Academy",
    website: "http://www.tart.spb.ru/",
  },
  {
    value: "6372",
    label: "St. Petersburg State University",
    website: "http://www.spbu.ru/",
  },
  {
    value: "6373",
    label: "St. Petersburg State University of Aerospace Instrumentation",
    website: "http://www.suai.ru/",
  },
  {
    value: "6374",
    label:
      "St. Petersburg State University of Architecture and Civil Engineering",
    website: "http://www.spbgasu.ru/",
  },
  {
    value: "6375",
    label: "St. Petersburg State University of Civil Aviation",
    website: "http://www.academiaga.ru/",
  },
  {
    value: "6376",
    label: "St. Petersburg State University of Culture and Arts",
    website: "http://www.spbguki.ru/",
  },
  {
    value: "6377",
    label: "St. Petersburg State University of Culture and Arts",
    website: "http://www.spbguki.ru/",
  },
  {
    value: "6378",
    label: "St. Petersburg State University of Economics and Finance",
    website: "http://www.uef.ru/",
  },
  {
    value: "6379",
    label: "St. Petersburg State University of Engineering and Economics",
    website: "http://www.engec.ru/",
  },
  {
    value: "6380",
    label:
      "St. Petersburg State University of Information Technologies, Mechanics and Optics",
    website: "http://www.ifmo.ru/",
  },
  {
    value: "6381",
    label: "St. Petersburg State University of Technology and Design",
    website: "http://www.sutd.ru/",
  },
  {
    value: "6382",
    label: "St. Petersburg State University of Telecommunication",
    website: "http://www.sut.ru/",
  },
  {
    value: "6383",
    label: "St. Petersburg State University of Waterway Communications",
    website: "http://www.spbuwc.ru/",
  },
  {
    value: "6384",
    label: "Surgut State University",
    website: "http://www.surgu.wsnet.ru/",
  },
  {
    value: "6385",
    label: "Syktyvkar State University",
    website: "http://ssu.komi.com:8101/",
  },
  {
    value: "6386",
    label: "Taganrog State Pedagogical Institute",
    website: "http://www.tgpi.ttn.ru/",
  },
  {
    value: "6387",
    label: "Taganrog State University of Radioengineering",
    website: "http://www.tsure.ru/",
  },
  {
    value: "6388",
    label: "Tambov State Technical University",
    website: "http://www.tstu.ru/",
  },
  {
    value: "6389",
    label: "Timirjazev Moscow Academy of Agricultutre",
    website: "http://www.timacad.ru/",
  },
  {
    value: "6390",
    label: "Togliatti State University",
    website: "http://www.tltsu.ru/",
  },
  {
    value: "6391",
    label: "Tomsk Polytechnic University",
    website: "http://www.tpu.ru/",
  },
  {
    value: "6392",
    label: "Tomsk State Pedagogical University",
    website: "http://www.tspu.edu.ru/",
  },
  {
    value: "6393",
    label: "Tomsk State University",
    website: "http://www.tsu.ru/",
  },
  {
    value: "6394",
    label: "Tomsk State University of Architecture and Building",
    website: "http://www.tsuab.ru/",
  },
  {
    value: "6395",
    label: "Tomsk State University of Control Systems and Radioelectronics",
    website: "http://www.tusur.ru/",
  },
  {
    value: "6396",
    label: "Tula State University",
    website: "http://www.tsu.tula.ru/",
  },
  {
    value: "6397",
    label: "Tuva State University",
    website: "http://tuvsu.ru/",
  },
  {
    value: "6398",
    label: "Tver State Medical Academy",
    website: "http://www.tsma.ru/",
  },
  {
    value: "6399",
    label: "Tver State Technical University",
    website: "http://www.tstu.tver.ru/",
  },
  {
    value: "6400",
    label: "Tver State University",
    website: "http://university.tversu.ru/",
  },
  {
    value: "6401",
    label: "Tyumen State Agricultural Academy",
    website: "http://www.tgsha.ru/",
  },
  {
    value: "6402",
    label: "Tyumen State University",
    website: "http://www.tsu.tmn.ru/",
  },
  {
    value: "6403",
    label: "Tyumen State University of Gas and Oil",
    website: "http://www.tsogu.ru/",
  },
  {
    value: "6404",
    label: "Udmurt State University",
    website: "http://www.uni.udm.ru/",
  },
  {
    value: "6405",
    label: "Ufa State Aviation Technical University",
    website: "http://www.ugatu.ac.ru/",
  },
  {
    value: "6406",
    label: "Ufa State Institute of Arts",
    website: "http://www.bashedu.ru/vuz/ugii_e.htm",
  },
  {
    value: "6407",
    label: "Ufa State Petroleum Technological University",
    website: "http://www.rusoil.net/",
  },
  {
    value: "6408",
    label: "Ukhta State Technical University",
    website: "http://uii.sever.ru/",
  },
  {
    value: "6409",
    label: "Ulyanovsk Higher School of Civil Aviation",
    website: "http://www.uvauga.ru/",
  },
  {
    value: "6410",
    label: "Ulyanovsk State Agricultural Academy",
    website: "http://www.ugsha.ru/",
  },
  {
    value: "6411",
    label: "Ulyanovsk State Pedagogical University",
    website: "http://www.ulspu.ru/",
  },
  {
    value: "6412",
    label: "Ulyanovsk State Technical University",
    website: "http://www.ulstu.ru/",
  },
  {
    value: "6413",
    label: "Ulyanovsk State University",
    website: "http://www.ulsu.ru/",
  },
  {
    value: "6414",
    label: "Ural Academy of Public Administration",
    website: "http://www.uapa.ru/",
  },
  {
    value: "6415",
    label: "Ural Gorkij State University",
    website: "http://www.usu.ru/",
  },
  {
    value: "6416",
    label: "Ural State Academy of Architecture and Arts",
    website: "http://www.usaaa.ru/",
  },
  {
    value: "6417",
    label: "Ural State Academy of Law",
    website: "http://www.usla.ru/",
  },
  {
    value: "6418",
    label: "Ural State Academy of Mining and Geology",
    website: "http://www.usmga.ru/",
  },
  {
    value: "6419",
    label: "Ural State Academy of Railway Transport",
    website: "http://www.usart.ru/",
  },
  {
    value: "6420",
    label: "Ural State Conservatory",
    website: "http://www.uscon.ru/",
  },
  {
    value: "6421",
    label: "Ural State Forestry Technical Academy",
    website: "http://www.usfea.ru/",
  },
  {
    value: "6422",
    label: "Ural State Technical University",
    website: "http://www.ustu.ru/",
  },
  {
    value: "6423",
    label: "Ural State University of Economics",
    website: "http://www.usue.ru/",
  },
  {
    value: "6424",
    label: "Vaganova Academy of Russian Ballet",
    website: "http://www.kirovballet.com/vaganova.html",
  },
  {
    value: "6425",
    label: "Vladimir State University",
    website: "http://www.vlsu.ru/",
  },
  {
    value: "6426",
    label: "Vladivostock State University of Economics",
    website: "http://www.vvsu.ru/",
  },
  {
    value: "6427",
    label: "Volga State Academy of Water Transport",
    website: "http://www.aqua.sci-nnov.ru/",
  },
  {
    value: "6428",
    label: "Volgograd Medical Academy",
    website: "http://www.avtlg.ru/~vlgmed/",
  },
  {
    value: "6429",
    label: "Volgograd State Academy of Physical Education",
    website: "http://www.vgafk.ru/",
  },
  {
    value: "6430",
    label: "Volgograd State Pedagogical University",
    website: "http://www.vspu.ru/",
  },
  {
    value: "6431",
    label: "Volgograd State Technical University",
    website: "http://www.vstu.ru/",
  },
  {
    value: "6432",
    label: "Volgograd State University",
    website: "http://www.volsu.ru/",
  },
  {
    value: "6433",
    label:
      "Volgograd State University of Architecture and Civil Engineering (VolgGASU)",
    website: "http://www.vgasa.ru/",
  },
  {
    value: "6434",
    label: "Vologda State Pedagogical University",
    website: "http://www.uni-vologda.ac.ru/",
  },
  {
    value: "6435",
    label: "Voronezh State Academy of Technology",
    website: "http://www.vgta.vrn.ru/",
  },
  {
    value: "6436",
    label: "Voronezh State Agricultural University",
    website: "http://www.vsau.ru/",
  },
  {
    value: "6437",
    label: "Voronezh State Medical Academy",
    website: "http://www.vsma.info/",
  },
  {
    value: "6438",
    label: "Voronezh State Pedagogical University",
    website: "http://www.vspu.ac.ru/",
  },
  {
    value: "6439",
    label: "Voronezh State Technical University",
    website: "http://www.vorstu.ac.ru/",
  },
  {
    value: "6440",
    label: "Voronezh State University",
    website: "http://www.vsu.ru/",
  },
  {
    value: "6441",
    label: "Vyatka State Pedagogical University",
    website: "http://www.vspu.kirov.ru/",
  },
  {
    value: "6442",
    label: "Yakutsk State University",
    website: "http://www.ysu.ru/",
  },
  {
    value: "6443",
    label:
      "Yaroslavl International University of Business and New Technologies",
    website: "http://www.iubnt.yar.ru/",
  },
  {
    value: "6444",
    label: "Yaroslavl State Medical Academy",
    website: "http://www.yma.ac.ru/",
  },
  {
    value: "6445",
    label: "Yaroslavl State Pedagogical University",
    website: "http://www.yspu.yar.ru/",
  },
  {
    value: "6446",
    label: "Yaroslavl State Technical University",
    website: "http://www.ystu.yar.ru/",
  },
  {
    value: "6447",
    label: "Yaroslavl State Theatre Institute",
    website: "http://www.theatrins-yar.ru/",
  },
  {
    value: "6448",
    label: "Yaroslavl State University",
    website: "http://www.uniyar.ac.ru/",
  },
  {
    value: "6449",
    label: "Yelets State University",
    website: "http://www.elsu.ru/",
  },
  {
    value: "6450",
    label: "Yugra State University",
    website: "http://www.ugrasu.ru/",
  },
  {
    value: "6451",
    label: "Adventist University of Central Africa",
    website: "http://www.auca.ac.rw/",
  },
  {
    value: "6452",
    label: "Higher Institute of Agriculture and Animal Husbandry",
    website: "http://www.isae.ac.rw/",
  },
  {
    value: "6453",
    label: "Institut d'Agriculture, de Technologie et d'Education de Kibungo",
    website: "http://www.inatek.ac.rw/",
  },
  {
    value: "6454",
    label: "Kigali Health Institute",
    website: "http://www.khi.ac.rw/",
  },
  {
    value: "6455",
    label: "Kigali Independent University",
    website: "http://www.ulk.ac.rw/",
  },
  {
    value: "6456",
    label: "Kigali Institute of Education",
    website: "http://www.kie.ac.rw/",
  },
  {
    value: "6457",
    label: "Kigali Institute of Science & Technology",
    website: "http://www.kist.ac.rw/",
  },
  {
    value: "6458",
    label: "National University of Rwanda",
    website: "http://www.nur.ac.rw/",
  },
  {
    value: "6459",
    label: "School of Finance and Banking",
    website: "http://www.sfb.ac.rw/",
  },
  {
    value: "6460",
    label: "Umutara Polytechnic",
    website: "http://www.umutarapolytech.ac.rw/",
  },
  {
    value: "6461",
    label: "Université Catholique de Kabgayi",
    website: "http://www.uck.ac.rw/",
  },
  {
    value: "6462",
    label: "Al-baha University",
    website: "http://www.bu.edu.sa/",
  },
  {
    value: "6463",
    label: "Al-Batterjee Medical College",
    website: "http://www.bmcmedcollege.com/",
  },
  {
    value: "6464",
    label: "Alfaisal University",
    website: "http://www.alfaisal.edu/",
  },
  {
    value: "6465",
    label: "Al-Imam Mohamed Ibn Saud Islamic University",
    website: "http://www.imamu.edu.sa/",
  },
  {
    value: "6466",
    label: "Aljouf University",
    website: "http://www.ju.edu.sa/",
  },
  {
    value: "6467",
    label: "Alkharj University",
    website: "http://www.ku.edu.sa/",
  },
  {
    value: "6468",
    label: "Al-Yamamah College",
    website: "http://www.alyamamah.edu.sa/",
  },
  {
    value: "6469",
    label: "Arab Open University",
    website: "http://www.arabou.org.sa/",
  },
  {
    value: "6470",
    label: "Batterjee Medical College",
    website: "http://www.bmc.edu.sa/",
  },
  {
    value: "6471",
    label: "Buraydah College for Applied Medical Sciences",
    website: "http://www.bpc.edu.sa/",
  },
  {
    value: "6472",
    label: "College of Busniess Admnistration",
    website: "http://www.cba.edu.sa/",
  },
  {
    value: "6473",
    label: "College of Nursing and Allied Health Scinces",
    website: "http://www.ngha.med.sa/",
  },
  {
    value: "6474",
    label: "College of Technology at Abha",
    website: "http://www.act.gotevot.edu.sa/",
  },
  {
    value: "6475",
    label: "College of Technology at Dammam",
    website: "http://www.dct.gotevot.edu.sa/",
  },
  {
    value: "6476",
    label: "College of Technology at Jazan",
    website: "http://www.tnmeyah.net/jct/",
  },
  {
    value: "6477",
    label: "College of Technology at Jeddah",
    website: "http://www.jct.edu.sa/",
  },
  {
    value: "6478",
    label: "College of Technology at Kharj",
    website: "http://kct.gotevot.edu.sa/",
  },
  {
    value: "6479",
    label: "College of Technology at Riyadh",
    website: "http://www.rct.edu.sa/",
  },
  {
    value: "6480",
    label: "College of Telecommunication & Information ",
    website: "http://www.cti.edu.sa/",
  },
  {
    value: "6481",
    label: "Dammam Community College",
    website: "http://www.dcc.edu.sa/",
  },
  {
    value: "6482",
    label: "Dar al Hekma College",
    website: "http://www.daralhekma.edu.sa/",
  },
  {
    value: "6483",
    label: "Dar Al-Uloom Colleges",
    website: "http://www.dau.edu.sa/",
  },
  {
    value: "6484",
    label: "Effat College",
    website: "http://www.effatcollege.edu.sa/",
  },
  {
    value: "6485",
    label: "Fahad Bin Sultan University",
    website: "http://www.fbsc.edu.sa/",
  },
  {
    value: "6486",
    label: "Ibn Sina National College for Medical Studies ",
    website: "http://www.ibnsina.edu.sa/",
  },
  {
    value: "6487",
    label: "Institute of Public Administration",
    website: "http://www.ipa.edu.sa/",
  },
  {
    value: "6488",
    label: "Islamic University of Medinah",
    website: "http://www.iu.edu.sa/",
  },
  {
    value: "6489",
    label: "Jazan University",
    website: "http://www.jazanu.edu.sa/",
  },
  {
    value: "6490",
    label: "Jubail Industrial College",
    website: "http://www.jic.edu.sa/",
  },
  {
    value: "6491",
    label: "Jubail University College",
    website: "http://www.ucj.edu.sa/",
  },
  {
    value: "6492",
    label: "King Abdul Aziz University",
    website: "http://www.kau.edu.sa/",
  },
  {
    value: "6493",
    label: "King Abdullah University of Science and Technology ",
    website: "http://www.kaust.edu.sa/",
  },
  {
    value: "6494",
    label: "King Fahad University of Petroleum and Minerals",
    website: "http://www.kfupm.edu.sa/",
  },
  {
    value: "6495",
    label: "King Fahd Security College",
    website: "http://www.kfsc.edu.sa/",
  },
  {
    value: "6496",
    label: "King Faisal University",
    website: "http://www.kfu.edu.sa/",
  },
  {
    value: "6497",
    label: "King Khaled University",
    website: "http://www.kku.edu.sa/",
  },
  {
    value: "6498",
    label: "King Saud bin Abdulaziz University for Health Sciences ",
    website: "http://www.ksau-hs.edu.sa/",
  },
  {
    value: "6499",
    label: "King Saud University",
    website: "http://www.ksu.edu.sa/",
  },
  {
    value: "6500",
    label: "Knowledge College for Science and Technology",
    website: "http://www.mcst.edu.sa/",
  },
  {
    value: "6501",
    label: "Knowledge International University",
    website: "http://www.almaarifah.com/",
  },
  {
    value: "6502",
    label: "Majmaah University",
    website: "http://www.mu.edu.sa/",
  },
  {
    value: "6503",
    label: "Naif Arab University for Security Sciences",
    website: "http://www.nauss.edu.sa/",
  },
  {
    value: "6504",
    label: "Najran University",
    website: "http://www.nu.edu.sa/",
  },
  {
    value: "6505",
    label: "Northern Border University",
    website: "http://www.nbu.edu.sa/",
  },
  {
    value: "6506",
    label: "Prince Mohammad Bin Fahd University",
    website: "http://www.pmu.edu.sa/",
  },
  {
    value: "6507",
    label: "Princess Nora Bint Abdulrahman University",
    website: "http://www.rug.edu.sa/",
  },
  {
    value: "6508",
    label: "Prince Sultan College for Tourism and Hotel Scinces",
    website: "http://www.pscabha.edu.sa/",
  },
  {
    value: "6509",
    label: "Prince Sultan University",
    website: "http://www.psu.edu.sa/",
  },
  {
    value: "6510",
    label: "Private College of Dentistry and Pharmacy",
    website: "http://www.pcdp.edu.sa/",
  },
  {
    value: "6511",
    label: "Qassim Private College",
    website: "http://www.qc.edu.sa/",
  },
  {
    value: "6512",
    label: "Qassim University",
    website: "http://www.qu.edu.sa/",
  },
  {
    value: "6513",
    label: "Riyadh College of Dentistry and Pharmacy",
    website: "http://www.riyadh.edu.sa/",
  },
  {
    value: "6514",
    label: "Saad College of Nursing and Allied Health Sciences",
    website: "http://www.saadcollege.com/",
  },
  {
    value: "6515",
    label: "Saudi Electronic University",
    website: "http://www.seu.edu.sa/",
  },
  {
    value: "6516",
    label: "Shaqra University",
    website: "http://www.su.edu.sa/",
  },
  {
    value: "6517",
    label: "Taibah University",
    website: "http://www.taibahu.edu.sa/",
  },
  {
    value: "6518",
    label: "Taif University",
    website: "http://www.tu.edu.sa/",
  },
  {
    value: "6519",
    label: "Umm Al-Qura University",
    website: "http://www.uqu.edu.sa/",
  },
  {
    value: "6520",
    label: "University of Dammam",
    website: "http://www.ud.edu.sa/",
  },
  {
    value: "6521",
    label: "University of Hail",
    website: "http://www.uoh.edu.sa/",
  },
  {
    value: "6522",
    label: "University of Tabuk",
    website: "http://www.ut.edu.sa/",
  },
  {
    value: "6523",
    label: "Yanbu Industrial College",
    website: "http://www.yic.edu.sa/",
  },
  {
    value: "6524",
    label: "Yanbu Technical Institute",
    website: "http://www.yti.edu.sa/",
  },
  {
    value: "6525",
    label: "Yanbu University College",
    website: "http://www.yuc.edu.sa/",
  },
  {
    value: "6526",
    label: "University of the South Pacific",
    website: "http://www.usp.ac.fj/",
  },
  {
    value: "6527",
    label: "University of Seychelles",
    website: "http://www.unisey.ac.sc/",
  },
  {
    value: "6528",
    label: "University of Seychelles - American Institute of Medicine",
    website: "http://www.usaim.edu/",
  },
  {
    value: "6529",
    label: "Ahfad University for Women",
    website: "http://www.ahfad.edu.sd/",
  },
  {
    value: "6530",
    label: "Al Fashir University",
    website: "http://www.fashir.edu.sd/",
  },
  {
    value: "6531",
    label: "Al-Zaiem Al-Azhari University",
    website: "http://www.aau.edu.sd/",
  },
  {
    value: "6532",
    label: "Bakht Er-Ruda University",
    website: "http://www.bakhtalruda.edu.sd/",
  },
  {
    value: "6533",
    label: "Bayan College for Science & Technology",
    website: "http://www.bayantech.edu/",
  },
  {
    value: "6534",
    label: "Blue Nile University",
    website: "http://www.bnu.edu.sd/",
  },
  {
    value: "6535",
    label: "Canadian Sudanese College",
    website: "http://www.ccs.edu.sd/",
  },
  {
    value: "6536",
    label: "Dalanj University",
    website: "http://www.dalanjuniversity.edu.sd/",
  },
  {
    value: "6537",
    label: "Elrazi College Of Medical & Technological Sciences",
    website: "http://www.elrazicollege.net/",
  },
  {
    value: "6538",
    label: "Gezira College of Technology",
    website: "http://www.geziracollege.edu.sd/",
  },
  {
    value: "6539",
    label: "High Institute for Banking & Financial Studies",
    website: "http://www.hibfs.edu.sd/",
  },
  {
    value: "6540",
    label: "International University of Africa (IUA)",
    website: "http://www.iua.edu.sd/",
  },
  {
    value: "6541",
    label: "Kassala University",
    website: "http://www.kassalauni.edu.sd/",
  },
  {
    value: "6542",
    label: "National College for Medical and Technical Studies",
    website: "http://www.nc.edu.sd/",
  },
  {
    value: "6543",
    label: "National Ribat University",
    website: "http://www.ribat.edu.sd/",
  },
  {
    value: "6544",
    label: "Neelain University",
    website: "http://www.neelain.edu.sd/",
  },
  {
    value: "6545",
    label: "Nile Valley University",
    website: "http://www.nilevalley.edu.sd/",
  },
  {
    value: "6546",
    label: "Omdurman Ahlia University",
    website: "http://www.oau.edu.sd/",
  },
  {
    value: "6547",
    label: "Omdurman Islamic University",
    website: "http://www.oiu.edu.sd/",
  },
  {
    value: "6548",
    label: "Open University of Sudan (OUS)",
    website: "http://www.ous.edu.sd/",
  },
  {
    value: "6549",
    label: "Red Sea University",
    website: "http://www.rsu.edu.sd/",
  },
  {
    value: "6550",
    label: "Shendi University",
    website: "http://www.ush.sd/",
  },
  {
    value: "6551",
    label: "Sinnar University",
    website: "http://www.sinnaruniv.edu.sd/",
  },
  {
    value: "6552",
    label: "Sudan Academy of Sciences",
    website: "http://www.sas-sd.net/",
  },
  {
    value: "6553",
    label: "Sudan University for Science and Technology",
    website: "http://www.sustech.edu/",
  },
  {
    value: "6554",
    label: "University of Dongola",
    website: "http://www.uofd.edu.sd/",
  },
  {
    value: "6555",
    label: "University of El Imam El Mahdi University",
    website: "http://www.elmahdi.edu.sd/",
  },
  {
    value: "6556",
    label: "University of Gadarif",
    website: "http://www.gaduniv.edu.sd/",
  },
  {
    value: "6557",
    label: "University of Gezira",
    website: "http://www.uofg.edu.sd/",
  },
  {
    value: "6558",
    label: "University of Holy Quran and Islamic Siences",
    website: "http://www.quran-unv.edu.sd/",
  },
  {
    value: "6559",
    label: "University of Khartoum",
    website: "http://www.uofk.edu/",
  },
  {
    value: "6560",
    label: "University of Kordofan",
    website: "http://www.kordofan.edu.sd/",
  },
  {
    value: "6561",
    label: "University Of Medical Sciences & Technology (UMST)",
    website: "http://www.umst-edu.com/",
  },
  {
    value: "6562",
    label: "University of Science and Technology",
    website: "http://www.ust.edu.sd/",
  },
  {
    value: "6563",
    label: "Upper Nile University",
    website: "http://www.unu.edu.sd/",
  },
  {
    value: "6564",
    label: "Blekinge Institute of Technology",
    website: "http://www.bth.se/",
  },
  {
    value: "6565",
    label: "Chalmers University of Technology",
    website: "http://www.chalmers.se/",
  },
  {
    value: "6566",
    label: "Dalarna University College",
    website: "http://www.du.se/",
  },
  {
    value: "6567",
    label: "Free University Stockholm",
    website: "http://www.stockholm-fu.com/",
  },
  {
    value: "6568",
    label: "Göteborg University",
    website: "http://www.gu.se/",
  },
  {
    value: "6569",
    label: "Gotland University College",
    website: "http://www.hgo.se/",
  },
  {
    value: "6570",
    label: "Halmstad University College",
    website: "http://www.hh.se/",
  },
  {
    value: "6571",
    label: "Jönköping University College",
    website: "http://www.hj.se/",
  },
  {
    value: "6572",
    label: "Jönköping University College of Health Sciences",
    website: "http://www.hj.se/hhj/",
  },
  {
    value: "6573",
    label: "Kalmar University College",
    website: "http://www.hik.se/",
  },
  {
    value: "6574",
    label: "Karlstad University",
    website: "http://www.kau.se/",
  },
  {
    value: "6575",
    label: "Karolinska Institute Stockholm",
    website: "http://www.ki.se/",
  },
  {
    value: "6576",
    label: "Linköping University",
    website: "http://www.liu.se/",
  },
  {
    value: "6577",
    label: "Lulea University of Technology",
    website: "http://www.luth.se/",
  },
  {
    value: "6578",
    label: "Lund University",
    website: "http://www.lu.se/",
  },
  {
    value: "6579",
    label: "Mälardalen University",
    website: "http://www.mdh.se/",
  },
  {
    value: "6580",
    label: "Malmö University College",
    website: "http://www.mah.se/",
  },
  {
    value: "6581",
    label: "Mid-Sweden University",
    website: "http://www.miun.se/",
  },
  {
    value: "6582",
    label: "Nordic School of Public Health",
    website: "http://www.nhv.se/",
  },
  {
    value: "6583",
    label: "Örebro University",
    website: "http://www.oru.se/",
  },
  {
    value: "6584",
    label: "Royal lnstitute of Technology",
    website: "http://www.kth.se/",
  },
  {
    value: "6585",
    label: "School of Design and Crafts",
    website: "http://www.hdk.gu.se/",
  },
  {
    value: "6586",
    label: "Södertörn University College",
    website: "http://www.sh.se/",
  },
  {
    value: "6587",
    label: "Stockholm Institute of Education",
    website: "http://www.lhs.se/",
  },
  {
    value: "6588",
    label: "Stockholm School of Economics",
    website: "http://www.hhs.se/",
  },
  {
    value: "6589",
    label: "Stockholm University",
    website: "http://www.su.se/",
  },
  {
    value: "6590",
    label: "Swedish University of Agricultural Sciences",
    website: "http://www.slu.se/",
  },
  {
    value: "6591",
    label: "Umea University",
    website: "http://www.umu.se/",
  },
  {
    value: "6592",
    label: "University College of Arts, Crafts and Design",
    website: "http://www.konstfack.se/",
  },
  {
    value: "6593",
    label: "University College of Borås",
    website: "http://www.hb.se/",
  },
  {
    value: "6594",
    label: "University College of Gävle",
    website: "http://www.hig.se/",
  },
  {
    value: "6595",
    label: "University College of Kristianstad",
    website: "http://www.hkr.se/",
  },
  {
    value: "6596",
    label: "University College of Skövde",
    website: "http://www.his.se/",
  },
  {
    value: "6597",
    label: "University College of Trollhättan/Uddevalla",
    website: "http://www.htu.se/",
  },
  {
    value: "6598",
    label: "Uppsala University",
    website: "http://www.uu.se/",
  },
  {
    value: "6599",
    label: "Växjö University",
    website: "http://www.vxu.se/",
  },
  {
    value: "6600",
    label: "World Maritime University",
    website: "http://www.wmu.se/",
  },
  {
    value: "6601",
    label: "Aventis School of Management",
    website: "http://www.aventis.edu.sg/",
  },
  {
    value: "6602",
    label: "Nanyang Technological University",
    website: "http://www.ntu.edu.sg/",
  },
  {
    value: "6603",
    label: "National University of Singapore",
    website: "http://www.nus.edu.sg/",
  },
  {
    value: "6604",
    label: "Singapore Institute of Management (SIM)",
    website: "http://www.sim.edu.sg/",
  },
  {
    value: "6605",
    label: "Singapore Management University",
    website: "http://www.smu.edu.sg/",
  },
  {
    value: "6606",
    label: "Taoist College Singapore",
    website: "http://www.taoistcollege.org.sg/",
  },
  {
    value: "6607",
    label: "University of Ljubljana",
    website: "http://www.uni-lj.si/",
  },
  {
    value: "6608",
    label: "University of Maribor",
    website: "http://www.uni-mb.si/",
  },
  {
    value: "6609",
    label: "University of Nova Gorica",
    website: "http://www.ung.si/",
  },
  {
    value: "6610",
    label: "University of Primorska",
    website: "http://www.upr.si/",
  },
  {
    value: "6611",
    label: "Academy of Arts in Banská Bystrica",
    website: "http://www.aku.sk/",
  },
  {
    value: "6612",
    label: "Academy of Fine Arts and Design in Bratislava",
    website: "http://www.vsvu.sk/",
  },
  {
    value: "6613",
    label: "Armed Forces Academy of General Milan Rastislav Štefánik",
    website: "http://www.aos.sk/",
  },
  {
    value: "6614",
    label: "Bratislava International School of Liberal Arts",
    website: "http://www.bisla.sk/",
  },
  {
    value: "6615",
    label: "Catholic University in Ruzomberok",
    website: "http://www.ku.sk/",
  },
  {
    value: "6616",
    label: "Catholic University in Ružomberok",
    website: "http://www.ku.sk/",
  },
  {
    value: "6617",
    label: "College in Sládkovičovo",
    website: "http://www.vssladkovicovo.sk/",
  },
  {
    value: "6618",
    label: "Comenius University in Bratislava",
    website: "http://www.uniba.sk/",
  },
  {
    value: "6619",
    label: "International School of Management in Prešov",
    website: "http://www.ismpo.sk/",
  },
  {
    value: "6620",
    label: "Janos Selye University",
    website: "http://www.selyeuni.sk/",
  },
  {
    value: "6621",
    label: "Law University of Bratislava",
    website: "http://www.uninova.sk/",
  },
  {
    value: "6622",
    label: "Matej Bel University in Banská Bystrica",
    website: "http://www.umb.sk/",
  },
  {
    value: "6623",
    label: "Pavol Jozef Safarik University in Kosice",
    website: "http://www.upjs.sk/",
  },
  {
    value: "6624",
    label: "Police Academy in Bratislava",
    website: "http://www.akademiapz.sk/",
  },
  {
    value: "6625",
    label: "School of Management",
    website: "http://www.vsm.sk/",
  },
  {
    value: "6626",
    label: "Slovak Medical University",
    website: "http:///www.szu.sk/",
  },
  {
    value: "6627",
    label: "Slovak University of Agriculture in Nitra",
    website: "http://www.uniag.sk/",
  },
  {
    value: "6628",
    label: "Slovak University of Technology in Bratislava",
    website: "http://www.stuba.sk/",
  },
  {
    value: "6629",
    label:
      "St. Elizabeth’s College of Health and Social Sciences in Bratislava",
    website: "http://www.vssvalzbety.sk/",
  },
  {
    value: "6630",
    label: "Technical Institute of Dubnica in Dubnica nad Váhom",
    website: "http://www.dti.sk/",
  },
  {
    value: "6631",
    label: "Technical University in Kosice",
    website: "http://www.tuke.sk/",
  },
  {
    value: "6632",
    label: "Technical University in Zvolen",
    website: "http://www.tuzvo.sk/",
  },
  {
    value: "6633",
    label: "University Konstantina Filozov in Nitra",
    website: "http://www.ukf.sk/",
  },
  {
    value: "6634",
    label: "University of Alexandra Dubček in Trenčín",
    website: "http://www.tnuni.sk/",
  },
  {
    value: "6635",
    label: "University of Central Europe in Skalica",
    website: "http://www.sevs.sk/",
  },
  {
    value: "6636",
    label: "University of Economics in Bratislava",
    website: "http://www.euba.sk/",
  },
  {
    value: "6637",
    label: "University of Performing Arts in Bratislava",
    website: "http://www.vsmu.sk/",
  },
  {
    value: "6638",
    label: "University of Presov",
    website: "http://www.unipo.sk/",
  },
  {
    value: "6639",
    label: "University of St. Cyril and Methodius in Trnava",
    website: "http://www.ucm.sk/",
  },
  {
    value: "6640",
    label: "University of SV Cyril and Methodius in Trnava",
    website: "http://www.ucm.sk/",
  },
  {
    value: "6641",
    label: "University of Trnava",
    website: "http://www.truni.sk/",
  },
  {
    value: "6642",
    label: "University of Veterinary Medicine in Kosice",
    website: "http://www.uvm.sk/",
  },
  {
    value: "6643",
    label: "University of Zilinska",
    website: "http://www.uniza.sk/",
  },
  {
    value: "6644",
    label: "Fourah Bay College, University of Sierra Leone",
    website: "http://fbcusl.8k.com/",
  },
  {
    value: "6645",
    label: "LICCSAL Business College",
    website: "http://www.liccsalsl.org/",
  },
  {
    value: "6646",
    label: "Njala University",
    website: "http://www.nu-online.com/",
  },
  {
    value: "6647",
    label: "University of Sierra Leone",
    website: "http://www.tusol.org/",
  },
  {
    value: "6648",
    label: "University of San Marino",
    website: "http://www.unirsm.sm/",
  },
  {
    value: "6649",
    label: "L'Université de l'Entreprise",
    website: "http://www.afi-ue.sn/",
  },
  {
    value: "6650",
    label: "Université Alioune Diop de Bambey",
    website: "http://www.bambey.univ.sn/",
  },
  {
    value: "6651",
    label: "Universite Amadou Hampaté Ba",
    website: "http://www.uahb.sn/",
  },
  {
    value: "6652",
    label: "Université Cheikh Anta Diop",
    website: "http://www.ucad.sn/",
  },
  {
    value: "6653",
    label: "Université Dakar Bourguiba",
    website: "http://www.udb.sn/",
  },
  {
    value: "6654",
    label: "Université de Thiès",
    website: "http://www.univ-thies.sn/",
  },
  {
    value: "6655",
    label: "Université de Ziguinchor",
    website: "http://www.univ-zig.sn/",
  },
  {
    value: "6656",
    label: "Université du Sahel",
    website: "http://www.unis.sn/",
  },
  {
    value: "6657",
    label: "Université Euro-Afrique",
    website: "http://www.uea.edu.sn/",
  },
  {
    value: "6658",
    label: "Université Gaston Berger Saint-Louis",
    website: "http://www.ugb.sn/",
  },
  {
    value: "6659",
    label: "Amoud University",
    website: "http://www.amouduniversity.org/",
  },
  {
    value: "6660",
    label: "Benadir University",
    website: "http://www.benadiruniversity.net/",
  },
  {
    value: "6661",
    label: "Bosaso College",
    website: "http://www.bosasocollege.com/",
  },
  {
    value: "6662",
    label: "East Africa University Bosaso",
    website: "http://www.eastafricauniversity.net/",
  },
  {
    value: "6663",
    label: "East Somalia University",
    website: "http://www.eastsomaliauniversity.com/",
  },
  {
    value: "6664",
    label: "Eelo American University",
    website: "http://www.eelouniversity.org/",
  },
  {
    value: "6665",
    label: "Gollis University",
    website: "http://www.gollisuniversity.com/",
  },
  {
    value: "6666",
    label: "Hiiraan University",
    website: "http://www.hiiraanuniversity.info/",
  },
  {
    value: "6667",
    label: "Maakhir University",
    website: "http://maakhiruniversity.net/",
  },
  {
    value: "6668",
    label: "Mogadishu University",
    website: "http://www.mogadishuuniversity.com/",
  },
  {
    value: "6669",
    label: "Nugaal University",
    website: "http://www.nugaaluniversity.com/",
  },
  {
    value: "6670",
    label: "Puntland State University",
    website: "http://www.puntlandstateuniversity.com/",
  },
  {
    value: "6671",
    label: "Sanaag University of Science and Technology",
    website: "http://www.sanaaguniversity.com/",
  },
  {
    value: "6672",
    label: "Somaliland University of Technology",
    website: "http://www.somtech.org/",
  },
  {
    value: "6673",
    label: "University of Burao",
    website: "http://www.buraouniversity.com/",
  },
  {
    value: "6674",
    label: "University of Hargeisa",
    website: "http://www.hargeisauniversity.net/",
  },
  {
    value: "6675",
    label: "University of Somalia",
    website: "http://www.universityofsomalia.net/",
  },
  {
    value: "6676",
    label: "Anton de Kom University",
    website: "http://www.uvs.edu/",
  },
  {
    value: "6677",
    label: "University of Juba",
    website: "http://www.juba.edu.sd/",
  },
  {
    value: "6678",
    label: "University of Northern Bahr El-Ghazal",
    website: "http://www.unbeg.edu.sd/",
  },
  {
    value: "6679",
    label: "Universidad Albert Einstein",
    website: "http://www.uae.edu.sv/",
  },
  {
    value: "6680",
    label: "Universidad Autónoma de Santa Ana",
    website: "http://www.unasa.edu.sv/",
  },
  {
    value: "6681",
    label: "Universidad Capitain General Gerardo Barrios",
    website: "http://www.ugb.edu.sv/",
  },
  {
    value: "6682",
    label: "Universidad Católica de Occidente",
    website: "http://www.unico.edu.sv/",
  },
  {
    value: "6683",
    label: 'Universidad Centroamericana "José Simeón Canas"',
    website: "http://www.uca.edu.sv/",
  },
  {
    value: "6684",
    label: "Universidad Cristiana de las Asambleas de Dios",
    website: "http://www.ucad.edu.sv/",
  },
  {
    value: "6685",
    label: "Universidad de El Salvador",
    website: "http://www.ues.edu.sv/",
  },
  {
    value: "6686",
    label: "Universidad de Oriente",
    website: "http://www.univo.edu.sv/",
  },
  {
    value: "6687",
    label: "Universidad de Sonsonate",
    website: "http://www.usonsonate.edu.sv/",
  },
  {
    value: "6688",
    label: "Universidad Don Bosco",
    website: "http://www.cdb.edu.sv/",
  },
  {
    value: "6689",
    label: "Universidad Dr. Andreas Bello",
    website: "http://www.unab.edu.sv/",
  },
  {
    value: "6690",
    label: "Universidad Dr. Jose Matias Delgado",
    website: "http://www.ujmd.edu.sv/",
  },
  {
    value: "6691",
    label: "Universidad Evangélica de El Salvador",
    website: "http://www.uees.edu.sv/",
  },
  {
    value: "6692",
    label: "Universidad Francisco Gavidia",
    website: "http://www.ufg.edu.sv/",
  },
  {
    value: "6693",
    label: "Universidad Isaac Newton",
    website: "http://www.usin.edu.sv/",
  },
  {
    value: "6694",
    label: "Universidad Luterana Salvadorena",
    website: "http://www.netcomsa.com/uls/",
  },
  {
    value: "6695",
    label: "Universidad Modular Abierta",
    website: "http://www.uma.edu.sv/",
  },
  {
    value: "6696",
    label: "Universidad Monsenor Oscar Arnulfo Romeo",
    website: "http://www.umoar.edu.sv/",
  },
  {
    value: "6697",
    label: "Universidad Nueva San Salvador",
    website: "http://www.unssa.edu.sv/",
  },
  {
    value: "6698",
    label: "Universidad Panamericana de San Salvador",
    website: "http://www.upan.edu.sv/",
  },
  {
    value: "6699",
    label: "Universidad Pedagógica de El Salvador",
    website: "http://www.universidadpedagogica.com/",
  },
  {
    value: "6700",
    label: "Universidad Politécnica de El Salvador",
    website: "http://www.upes.edu.sv/",
  },
  {
    value: "6701",
    label: 'Universidad Salvadorena "Alberto Masferrer"',
    website: "http://www.usam.edu.sv/",
  },
  {
    value: "6702",
    label: "Universidad Técnica Latinoamericana",
    website: "http://www.utla.edu.sv/",
  },
  {
    value: "6703",
    label: "Universidad Tecnológica de El Salvador",
    website: "http://www.utec.edu.sv/",
  },
  {
    value: "6704",
    label: "Al-Baath University",
    website: "http://www.albaath-univ.edu.sy/",
  },
  {
    value: "6705",
    label: "Al-Wataniya Private University",
    website: "http://www.wpu.edu.sy/",
  },
  {
    value: "6706",
    label: "Arab European University",
    website: "http://www.aiu.edu.sy/",
  },
  {
    value: "6707",
    label: "Damascus University",
    website: "http://www.damascusuniversity.edu.sy/",
  },
  {
    value: "6708",
    label: "Higher Institute of Business Administration",
    website: "http://www.hiba.edu.sy/",
  },
  {
    value: "6709",
    label: "International University for Science & Technology ",
    website: "http://www.iust.edu.sy/",
  },
  {
    value: "6710",
    label: "Ittihad Private University",
    website: "http://www.ipu.edu.sy/",
  },
  {
    value: "6711",
    label: "Mamoun Private University for Science and Technology",
    website: "http://www.must.edu.sy/",
  },
  {
    value: "6712",
    label: "Syrian International Private University for Science & Technology",
    website: "http://www.siust.edu.sy/",
  },
  {
    value: "6713",
    label: "Syrian Virtual University",
    website: "http://www.svuonline.org/",
  },
  {
    value: "6714",
    label: "Tishreen University",
    website: "http://www.tishreen.edu.sy/",
  },
  {
    value: "6715",
    label: "University of Aleppo",
    website: "http://www.alepuniv.edu.sy/",
  },
  {
    value: "6716",
    label: "University of Kalamoon",
    website: "http://www.uok.edu.sy/",
  },
  {
    value: "6717",
    label: "Wadi International University",
    website: "http://www.wiu.edu.sy/",
  },
  {
    value: "6718",
    label: "Yarmouk Private University ",
    website: "http://www.ypu.edu.sy/",
  },
  {
    value: "6719",
    label: "University of Swaziland",
    website: "http://www.uniswa.sz/",
  },
  {
    value: "6720",
    label: "Charisma University",
    website: "http://www.charismauniversity.org/",
  },
  {
    value: "6721",
    label: "Université de N'Djamena",
    website: "http://www.univ-ndjamena.org/",
  },
  {
    value: "6722",
    label: "Université de Lomé",
    website: "http://www.univ-lome.tg/",
  },
  {
    value: "6723",
    label: "Asian Institute of Technology",
    website: "http://www.ait.ac.th/",
  },
  {
    value: "6724",
    label: "Asian University of Science and Technology",
    website: "http://www.asianust.ac.th/",
  },
  {
    value: "6725",
    label: "Assumption University of Thailand",
    website: "http://www.au.edu/",
  },
  {
    value: "6726",
    label: "Bangkok University",
    website: "http://www.bu.ac.th/",
  },
  {
    value: "6727",
    label: "Burapha University",
    website: "http://www.buu.ac.th/",
  },
  {
    value: "6728",
    label: "Chaopraya University ",
    website: "http://www.cpu.ac.th/",
  },
  {
    value: "6729",
    label: "Chiang Mai Rajabhat University",
    website: "http://www.cmru.ac.th/",
  },
  {
    value: "6730",
    label: "Chiang Mai University",
    website: "http://www.chiangmai.ac.th/",
  },
  {
    value: "6731",
    label: "Chiang Mai Vocational College",
    website: "http://www.cmvc.ac.th/",
  },
  {
    value: "6732",
    label: "Chulalongkorn University",
    website: "http://www.chula.ac.th/",
  },
  {
    value: "6733",
    label: "Dhurakijpundit University",
    website: "http://www.dpu.ac.th/",
  },
  {
    value: "6734",
    label: "Eastern Asia University",
    website: "http://www.eau.ac.th/",
  },
  {
    value: "6735",
    label: "Engineering Faculty of Asian University",
    website: "http://www.efau.org/",
  },
  {
    value: "6736",
    label: "Hatyai University",
    website: "http://www.hu.ac.th/",
  },
  {
    value: "6737",
    label: "Huachiew Chalermprakiet University",
    website: "http://www.hcu.ac.th/",
  },
  {
    value: "6738",
    label: "International Buddhist College",
    website: "http://www.ibc.ac.th/",
  },
  {
    value: "6739",
    label: "Kasem Bundit University",
    website: "http://www.kbu.ac.th/",
  },
  {
    value: "6740",
    label: "Kasetsart University",
    website: "http://www.ku.ac.th/",
  },
  {
    value: "6741",
    label: "Khon Kaen University",
    website: "http://www.kku.ac.th/",
  },
  {
    value: "6742",
    label: "King Mongkut's University of Technology Ladkrabang",
    website: "http://www.kmitl.ac.th/",
  },
  {
    value: "6743",
    label: "King Mongkut's University of Technology North Bangkok",
    website: "http://www.kmutnb.ac.th/",
  },
  {
    value: "6744",
    label: "King Mongkut's University of Technology Thonburi",
    website: "http://www.kmutt.ac.th/",
  },
  {
    value: "6745",
    label: "Krirk University",
    website: "http://www.krirk.ac.th/",
  },
  {
    value: "6746",
    label: "Lampang College of Commerce and Technology",
    website: "http://www.lcct.ac.th/",
  },
  {
    value: "6747",
    label: "Lampang Rajabhat University",
    website: "http://www.lpru.ac.th/",
  },
  {
    value: "6748",
    label: "Lampang Vocational College   ",
    website: "http://www.lampangvc.ac.th/",
  },
  {
    value: "6749",
    label: "Lamphun College of Agriculture and Technology",
    website: "http://www.lcat.ac.th/",
  },
  {
    value: "6750",
    label: "Mae Fah Luang University ",
    website: "http://www.mfu.ac.th/",
  },
  {
    value: "6751",
    label: "Maejo University",
    website: "http://www.mju.ac.th/",
  },
  {
    value: "6752",
    label: "Mahachulalongkorn Buddhist University",
    website: "http://www.mcu.ac.th/",
  },
  {
    value: "6753",
    label: "Mahamakut Buddhist University",
    website: "http://www.mbu.ac.th/",
  },
  {
    value: "6754",
    label: "Mahanakorn University of Technology",
    website: "http://www.mut.ac.th/",
  },
  {
    value: "6755",
    label: "Mahasarakham University",
    website: "http://www.msu.ac.th/",
  },
  {
    value: "6756",
    label: "Mahidol University",
    website: "http://www.mahidol.ac.th/",
  },
  {
    value: "6757",
    label: "Mahidol University International College",
    website: "http://www.muic.mahidol.ac.th/",
  },
  {
    value: "6758",
    label: "Naresuan University",
    website: "http://www.nu.ac.th/",
  },
  {
    value: "6759",
    label: "National Institute of Development Administration",
    website: "http://www.nida.ac.th/",
  },
  {
    value: "6760",
    label: "Nivadhana University",
    website: "http://www.nivadhana.ac.th/",
  },
  {
    value: "6761",
    label: "Pathumthani University",
    website: "http://www.ptu.ac.th/",
  },
  {
    value: "6762",
    label: "Payap University Chaiang Mai",
    website: "http://www.payap.ac.th/",
  },
  {
    value: "6763",
    label: "Phranakhon Si Ayutthaya Rajabhat University",
    website: "http://www.aru.ac.th/",
  },
  {
    value: "6764",
    label: "Prince of Songkla University",
    website: "http://www.psu.ac.th/",
  },
  {
    value: "6765",
    label: "Rajamangala University of Technology, Lanna Chiang Rai",
    website: "http://chiangraicampus.rmutl.ac.th/",
  },
  {
    value: "6766",
    label: "Rajamangala University of Technology, Lanna Nan",
    website: "http://www.nan.rmutl.ac.th/",
  },
  {
    value: "6767",
    label: "Rajamangala University of Technology, Phra Nakhon",
    website: "http://www.rmutp.ac.th/",
  },
  {
    value: "6768",
    label: "Ramkhamhaeng University",
    website: "http://www.ru.ac.th/",
  },
  {
    value: "6769",
    label: "Rangsit University",
    website: "http://www.rsu.ac.th/",
  },
  {
    value: "6770",
    label: "Shinawatra University",
    website: "http://www.shinawatra.ac.th/",
  },
  {
    value: "6771",
    label: "Siam Technology College",
    website: "http://www.siamtechu.net/",
  },
  {
    value: "6772",
    label: "Siam University",
    website: "http://www.siam.edu/",
  },
  {
    value: "6773",
    label: "Silpakorn University",
    website: "http://www.su.ac.th/",
  },
  {
    value: "6774",
    label: "South-East Asia University",
    website: "http://www.sau.ac.th/",
  },
  {
    value: "6775",
    label: "Srinakharinwirot University",
    website: "http://www.swu.ac.th/",
  },
  {
    value: "6776",
    label: "Sripatum University",
    website: "http://www.spu.ac.th/",
  },
  {
    value: "6777",
    label: "Stamford International University",
    website: "http://www.stamford.edu/",
  },
  {
    value: "6778",
    label: "St. John's University",
    website: "http://www.stjohn.ac.th/",
  },
  {
    value: "6779",
    label: "Suan Dusit Rajabhat University",
    website: "http://www.dusit.ac.th/",
  },
  {
    value: "6780",
    label: "Sukhothai Thammathirat Open University",
    website: "http://www.stou.ac.th/",
  },
  {
    value: "6781",
    label: "Suranaree University of Technology",
    website: "http://www.sut.ac.th/",
  },
  {
    value: "6782",
    label: "Thaksin University",
    website: "http://www.tsu.ac.th/",
  },
  {
    value: "6783",
    label: "Thammasat University",
    website: "http://www.tu.ac.th/",
  },
  {
    value: "6784",
    label: "Ubonratchathani University",
    website: "http://www.ubu.ac.th/",
  },
  {
    value: "6785",
    label: "University of the Thai Chamber of Commerce",
    website: "http://www.utcc.ac.th/",
  },
  {
    value: "6786",
    label: "Vongchavalitkul University",
    website: "http://www.vu.ac.th/",
  },
  {
    value: "6787",
    label: "Walailak University",
    website: "http://www.wu.ac.th/",
  },
  {
    value: "6788",
    label: "Webster University, Thailand",
    website: "http://www.webster.ac.th/",
  },
  {
    value: "6789",
    label: "Yonok University",
    website: "http://www.yonok.ac.th/",
  },
  {
    value: "6790",
    label: "Tajik Agrarian University",
    website: "http://www.tajagroun.tj/",
  },
  {
    value: "6791",
    label: "Tajikistan International University",
    website: "http://www.tiu.tj/",
  },
  {
    value: "6792",
    label: "Tajik National University",
    website: "http://tgnu.tarena.tj/",
  },
  {
    value: "6793",
    label: "International Turkmen Turkish University",
    website: "http://www.ittu.edu.tm/",
  },
  {
    value: "6794",
    label: "Instituts Supérieurs de Formation des Maîtres",
    website: "http://www.disfm.rnu.tn/",
  },
  {
    value: "6795",
    label: "Instituts Supérieurs des Etudes Technologiques",
    website: "http://www.isetr.rnu.tn/",
  },
  {
    value: "6796",
    label: "Institut Supérieur de Gestion de Tunis",
    website: "http://www.isg.rnu.tn/",
  },
  {
    value: "6797",
    label: "Université Centrale",
    website: "http://www.ucaat.com/",
  },
  {
    value: "6798",
    label: "Université de 7 Novembre à Carthage",
    website: "http://u7nc.uvt.rnu.tn/",
  },
  {
    value: "6799",
    label: "Université de Gabès",
    website: "http://www.univgb.rnu.tn/",
  },
  {
    value: "6800",
    label: "Université de Gafsa",
    website: "http://www.ugaf.rnu.tn/",
  },
  {
    value: "6801",
    label: "Université de Jendouba",
    website: "http://www.uj.rnu.tn/",
  },
  {
    value: "6802",
    label: "Université de Kairouan",
    website: "http://www.univ-k.rnu.tn/",
  },
  {
    value: "6803",
    label: "Université de la Manouba",
    website: "http://www.uma.rnu.tn/",
  },
  {
    value: "6804",
    label: "Université de Monastir",
    website: "http://www.um.rnu.tn/",
  },
  {
    value: "6805",
    label: "Université de Sfax",
    website: "http://www.uss.rnu.tn/",
  },
  {
    value: "6806",
    label: "Université de Tunis",
    website: "http://www.utunis.rnu.tn/",
  },
  {
    value: "6807",
    label: "Université de Tunis El Manar",
    website: "http://www.utm.rnu.tn/",
  },
  {
    value: "6808",
    label: "Université du Centre, Sousse",
    website: "http://www.uc.rnu.tn/",
  },
  {
    value: "6809",
    label: "Université Ez-Zitouna",
    website: "http://www.uz.rnu.tn/",
  },
  {
    value: "6810",
    label: "Université Libre de Tunis",
    website: "http://www.ult.ens.tn/",
  },
  {
    value: "6811",
    label: "Université Virtuelle de Tunis",
    website: "http://www.uvt.rnu.tn/",
  },
  {
    value: "6812",
    label: "University of Aviation and Technology",
    website: "http://www.unat.ens.tn/",
  },
  {
    value: "6813",
    label: "University of the South Pacific Centre",
    website: "http://www.usp.ac.fj/",
  },
  {
    value: "6814",
    label: "Abant Izzet Baysal University",
    website: "http://www.ibu.edu.tr/",
  },
  {
    value: "6815",
    label: "Adnan Menderes University",
    website: "http://www.adu.edu.tr/",
  },
  {
    value: "6816",
    label: "Afyon Kocatepe University",
    website: "http://www.aku.edu.tr/",
  },
  {
    value: "6817",
    label: "Ahi Evran University",
    website: "http://www.ahievran.edu.tr/",
  },
  {
    value: "6818",
    label: "Akdeniz University",
    website: "http://www.akdeniz.edu.tr/",
  },
  {
    value: "6819",
    label: "Anadolu University",
    website: "http://www.anadolu.edu.tr/",
  },
  {
    value: "6820",
    label: "Ankara University",
    website: "http://www.ankara.edu.tr/",
  },
  {
    value: "6821",
    label: "Atatürk University",
    website: "http://www.atauni.edu.tr/",
  },
  {
    value: "6822",
    label: "Atilim University",
    website: "http://www.atilim.edu.tr/",
  },
  {
    value: "6823",
    label: "Bahcesehir University",
    website: "http://www.bahcesehir.edu.tr/",
  },
  {
    value: "6824",
    label: "Balikesir University",
    website: "http://www.balikesir.edu.tr/",
  },
  {
    value: "6825",
    label: "Baskent University",
    website: "http://www.baskent.edu.tr/",
  },
  {
    value: "6826",
    label: "Beykent University",
    website: "http://www.beykent.edu.tr/",
  },
  {
    value: "6827",
    label: "Bilecik University",
    website: "http://www.bilecik.edu.tr/",
  },
  {
    value: "6828",
    label: "Bilkent University",
    website: "http://www.bilkent.edu.tr/",
  },
  {
    value: "6829",
    label: "Bogazici University",
    website: "http://www.boun.edu.tr/",
  },
  {
    value: "6830",
    label: "Cag University",
    website: "http://www.cag.edu.tr/",
  },
  {
    value: "6831",
    label: "Canakkale (18th March) University",
    website: "http://www.comu.edu.tr/",
  },
  {
    value: "6832",
    label: "Cankaya University",
    website: "http://www.cankaya.edu.tr/",
  },
  {
    value: "6833",
    label: "Celal Bayar University",
    website: "http://www.bayar.edu.tr/",
  },
  {
    value: "6834",
    label: "Cukurova University",
    website: "http://www.cu.edu.tr/",
  },
  {
    value: "6835",
    label: "Cumhuriyet (Republik) University",
    website: "http://www.cumhuriyet.edu.tr/",
  },
  {
    value: "6836",
    label: "Dicle (Tirgris) University",
    website: "http://www.dicle.edu.tr/",
  },
  {
    value: "6837",
    label: "Dogus University",
    website: "http://www.dogus.edu.tr/",
  },
  {
    value: "6838",
    label: "Dokuz Eylül University",
    website: "http://www.deu.edu.tr/",
  },
  {
    value: "6839",
    label: "Dumlupinar University",
    website: "http://www.dumlupinar.edu.tr/",
  },
  {
    value: "6840",
    label: "Ege University",
    website: "http://www.ege.edu.tr/",
  },
  {
    value: "6841",
    label: "Erciyes University",
    website: "http://www.erciyes.edu.tr/",
  },
  {
    value: "6842",
    label: "Fatih University",
    website: "http://www.fatih.edu.tr/",
  },
  {
    value: "6843",
    label: "Firat (Euphrates) University",
    website: "http://www.firat.edu.tr/",
  },
  {
    value: "6844",
    label: "Galatasaray University",
    website: "http://www.gsu.edu.tr/",
  },
  {
    value: "6845",
    label: "Gaziantep University",
    website: "http://www.gantep.edu.tr/",
  },
  {
    value: "6846",
    label: "Gaziosmanpasa University",
    website: "http://www.gop.edu.tr/",
  },
  {
    value: "6847",
    label: "Gazi University Ankara",
    website: "http://www.gazi.edu.tr/",
  },
  {
    value: "6848",
    label: "Gebze Institute of Technology",
    website: "http://www.gyte.edu.tr/",
  },
  {
    value: "6849",
    label: "Gediz University",
    website: "http://www.gediz.edu.tr/",
  },
  {
    value: "6850",
    label: "Gulhane Military Medical Academy",
    website: "http://www.gata.edu.tr/",
  },
  {
    value: "6851",
    label: "Hacettepe University",
    website: "http://www.hun.edu.tr/",
  },
  {
    value: "6852",
    label: "Halic University",
    website: "http://www.halic.edu.tr/",
  },
  {
    value: "6853",
    label: "Harran University",
    website: "http://www.harran.edu.tr/",
  },
  {
    value: "6854",
    label: "Iasar University",
    website: "http://www.yasar.edu.tr/",
  },
  {
    value: "6855",
    label: "Inönü University",
    website: "http://www.inonu.edu.tr/",
  },
  {
    value: "6856",
    label: "Isik University",
    website: "http://www.isikun.edu.tr/",
  },
  {
    value: "6857",
    label: "Istanbul Arel University",
    website: "http://www.arel.edu.tr/",
  },
  {
    value: "6858",
    label: "Istanbul Bilgi University",
    website: "http://www.ibun.edu.tr/",
  },
  {
    value: "6859",
    label: "Istanbul Kultur University",
    website: "http://www.iku.edu.tr/",
  },
  {
    value: "6860",
    label: "İstanbul Şehir University",
    website: "http://www.sehir.edu.tr/",
  },
  {
    value: "6861",
    label: "Istanbul Technical University",
    website: "http://www.itu.edu.tr/",
  },
  {
    value: "6862",
    label: "Istanbul Ticaret University",
    website: "http://www.iticu.edu.tr/",
  },
  {
    value: "6863",
    label: "Istanbul University",
    website: "http://www.istanbul.edu.tr/",
  },
  {
    value: "6864",
    label: "Izmir Economic University",
    website: "http://www.izmirekonomi.edu.tr/",
  },
  {
    value: "6865",
    label: "Izmir Institute of Technology",
    website: "http://www.iyte.edu.tr/",
  },
  {
    value: "6866",
    label: "Kadir Has University",
    website: "http://www.khas.edu.tr/",
  },
  {
    value: "6867",
    label: "Kafkas University",
    website: "http://www.kafkas.edu.tr/",
  },
  {
    value: "6868",
    label: "Kahramanmaras Sütcü Imam University",
    website: "http://www.ksu.edu.tr/",
  },
  {
    value: "6869",
    label: "Karadeniz Technical University",
    website: "http://www.ktu.edu.tr/",
  },
  {
    value: "6870",
    label: "Kilis 7 Aralık University",
    website: "http://www.kilis.edu.tr/",
  },
  {
    value: "6871",
    label: "Kirikkale University",
    website: "http://www.kku.edu.tr/",
  },
  {
    value: "6872",
    label: "Kocaeli University",
    website: "http://www.kou.edu.tr/",
  },
  {
    value: "6873",
    label: "Koc University",
    website: "http://www.ku.edu.tr/",
  },
  {
    value: "6874",
    label: "Maltepe University",
    website: "http://www.maltepe.edu.tr/",
  },
  {
    value: "6875",
    label: "Marmara University",
    website: "http://www.marmara.edu.tr/",
  },
  {
    value: "6876",
    label: "Mersin University",
    website: "http://www.mersin.edu.tr/",
  },
  {
    value: "6877",
    label: "Middle East Technical University",
    website: "http://www.odtu.edu.tr/",
  },
  {
    value: "6878",
    label: "Mimar Sinan University",
    website: "http://www.msu.edu.tr/",
  },
  {
    value: "6879",
    label: "Mugla University",
    website: "http://www.mu.edu.tr/",
  },
  {
    value: "6880",
    label: "Mustafa Kemal University",
    website: "http://www.mku.edu.tr/",
  },
  {
    value: "6881",
    label: "Namik Kemal University",
    website: "http://www.nku.edu.tr/",
  },
  {
    value: "6882",
    label: "Necmettin Erbakan University",
    website: "http://www.konya.edu.tr/",
  },
  {
    value: "6883",
    label: "Nigde University",
    website: "http://www.nigde.edu.tr/",
  },
  {
    value: "6884",
    label: "Ondokuz Mayis University Samsun",
    website: "http://www.omu.edu.tr/",
  },
  {
    value: "6885",
    label: "Osmangazi University",
    website: "http://www.ogu.edu.tr/",
  },
  {
    value: "6886",
    label: "Ozyegin University",
    website: "http://www.ozyegin.edu.tr/",
  },
  {
    value: "6887",
    label: "Pamukkale University",
    website: "http://www.pamukkale.edu.tr/",
  },
  {
    value: "6888",
    label: "Sabanci University",
    website: "http://www.sabanciuniv.edu.tr/",
  },
  {
    value: "6889",
    label: "Sakarya University",
    website: "http://www.sau.edu.tr/",
  },
  {
    value: "6890",
    label: "Selcuk University",
    website: "http://www.selcuk.edu.tr/",
  },
  {
    value: "6891",
    label: "Suleyman Demirel University",
    website: "http://www.sdu.edu.tr/",
  },
  {
    value: "6892",
    label: "Tobb Economics and Technology University",
    website: "http://www.etu.edu.tr/",
  },
  {
    value: "6893",
    label: "Trakya University",
    website: "http://www.trakya.edu.tr/",
  },
  {
    value: "6894",
    label: "Türkisch-Deutsche Universität",
    website: "http://www.tau.edu.tr/",
  },
  {
    value: "6895",
    label: "Turkish Military Academy",
    website: "http://www.kho.edu.tr/",
  },
  {
    value: "6896",
    label: "Turkish Naval Academy",
    website: "http://www.dho.edu.tr/",
  },
  {
    value: "6897",
    label: "Ufuk University",
    website: "http://www.ufuk.edu.tr/",
  },
  {
    value: "6898",
    label: "Uludag University",
    website: "http://www.uludag.edu.tr/",
  },
  {
    value: "6899",
    label: "Yalova University",
    website: "http://www.yalova.edu.tr/",
  },
  {
    value: "6900",
    label: "Yasar University",
    website: "http://www.yasar.edu.tr/",
  },
  {
    value: "6901",
    label: "Yeditepe University",
    website: "http://www.yeditepe.edu.tr/",
  },
  {
    value: "6902",
    label: "Yildirim Beyazit University",
    website: "http://www.ybu.edu.tr/",
  },
  {
    value: "6903",
    label: "Yildiz Technical University",
    website: "http://www.yildiz.edu.tr/",
  },
  {
    value: "6904",
    label: "Yüzüncü Yil (Centennial) University",
    website: "http://www.yyu.edu.tr/",
  },
  {
    value: "6905",
    label: "Zonguldak Karaelmas University",
    website: "http://www.karaelmas.edu.tr/",
  },
  {
    value: "6906",
    label: "University of the Southern Caribbean",
    website: "http://www.usc.edu.tt/",
  },
  {
    value: "6907",
    label: "University of the West Indies St. Augustine",
    website: "http://www.uwi.tt/",
  },
  {
    value: "6908",
    label: "University of Trinidad and Tobago",
    website: "http://www.utt.edu.tt/",
  },
  {
    value: "6909",
    label: "Aletheia University",
    website: "http://www.au.edu.tw/",
  },
  {
    value: "6910",
    label: "Central Police University",
    website: "http://www.cpu.edu.tw/",
  },
  {
    value: "6911",
    label: "Chang Gung University",
    website: "http://www.cgu.edu.tw/",
  },
  {
    value: "6912",
    label: "Chang Jung University",
    website: "http://www.cju.edu.tw/",
  },
  {
    value: "6913",
    label: "Chao Yang University of Science and Technology",
    website: "http://www.cyut.edu.tw/",
  },
  {
    value: "6914",
    label: "Chia Nana College of Pharmacy and Science",
    website: "http://www.chna.edu.tw/",
  },
  {
    value: "6915",
    label: "China Medical College",
    website: "http://www.cmc.edu.tw/",
  },
  {
    value: "6916",
    label: "Chinese Culture University",
    website: "http://www.pccu.edu.tw/",
  },
  {
    value: "6917",
    label: "Ching Kuo Institue of Management & Health",
    website: "http://www.cku.edu.tw/",
  },
  {
    value: "6918",
    label: "Chung Hua University",
    website: "http://www.chu.edu.tw/",
  },
  {
    value: "6919",
    label: "Chung Shan Medical and Dental College",
    website: "http://www.csmc.edu.tw/",
  },
  {
    value: "6920",
    label: "Chung Yuan Christian University",
    website: "http://www.cycu.edu.tw/",
  },
  {
    value: "6921",
    label: "Da-Yeh University",
    website: "http://www.dyu.edu.tw/",
  },
  {
    value: "6922",
    label: "Feng Chia University",
    website: "http://www.fcu.edu.tw/",
  },
  {
    value: "6923",
    label: "Fu Jen Catholic University",
    website: "http://www.fju.edu.tw/",
  },
  {
    value: "6924",
    label: "Hsuan Chuang University",
    website: "http://www.hcu.edu.tw/",
  },
  {
    value: "6925",
    label: "Huafan University",
    website: "http://www.hfu.edu.tw/",
  },
  {
    value: "6926",
    label: "I-Shou University",
    website: "http://www.isu.edu.tw/",
  },
  {
    value: "6927",
    label: "Kaohsiung Medical College",
    website: "http://www.kmc.edu.tw/",
  },
  {
    value: "6928",
    label: "Kaohsuing Open University",
    website: "http://www.ouk.edu.tw/",
  },
  {
    value: "6929",
    label: "Kung Shan Institute of Technology",
    website: "http://www.ksit.edu.tw/",
  },
  {
    value: "6930",
    label: "Kun Shan University",
    website: "http://www.ksu.edu.tw/",
  },
  {
    value: "6931",
    label: "Ling Tung University",
    website: "http://www.ltc.edu.tw/",
  },
  {
    value: "6932",
    label: "LungHwa University of Science and Technology",
    website: "http://www.lhu.edu.tw/",
  },
  {
    value: "6933",
    label: "Ming Chuan University",
    website: "http://www.mcu.edu.tw/",
  },
  {
    value: "6934",
    label: "Nanhua University",
    website: "http://www.nhu.edu.tw/",
  },
  {
    value: "6935",
    label: "National Central University",
    website: "http://www.ncu.edu.tw/",
  },
  {
    value: "6936",
    label: "National Changhua University of Education",
    website: "http://www.ncue.edu.tw/",
  },
  {
    value: "6937",
    label: "National Chengchi University",
    website: "http://www.nccu.edu.tw/",
  },
  {
    value: "6938",
    label: "National Cheng Kung University",
    website: "http://english.web.ncku.edu.tw/",
  },
  {
    value: "6939",
    label: "National Chiao Tung University",
    website: "http://www.nctu.edu.tw/",
  },
  {
    value: "6940",
    label: "National Chiayi University",
    website: "http://www.ncyu.edu.tw/",
  },
  {
    value: "6941",
    label: "National Chi Nan University",
    website: "http://www.ncnu.edu.tw/",
  },
  {
    value: "6942",
    label: "National Chung Cheng University",
    website: "http://www.ccu.edu.tw/",
  },
  {
    value: "6943",
    label: "National Chung Hsing University, Taichung",
    website: "http://www.nchu.edu.tw/",
  },
  {
    value: "6944",
    label: "National Chung Hsing University, Taipei",
    website: "http://www.nchulc.edu.tw/",
  },
  {
    value: "6945",
    label: "National College of Physical Education and Sports",
    website: "http://www.ncpes.edu.tw/",
  },
  {
    value: "6946",
    label: "National Dong Hwa University",
    website: "http://www.ndhu.edu.tw/",
  },
  {
    value: "6947",
    label: "National Formosa University",
    website: "http://www.nfu.edu.tw/",
  },
  {
    value: "6948",
    label: "National Hsin-Chu Teachers College",
    website: "http://www.nhctc.edu.tw/",
  },
  {
    value: "6949",
    label: "National Hualien Teachers College",
    website: "http://www.nhltc.edu.tw/",
  },
  {
    value: "6950",
    label: "National Ilan University",
    website: "http://www.niu.edu.tw/",
  },
  {
    value: "6951",
    label: "National Institute of the Arts",
    website: "http://www.nia.edu.tw/",
  },
  {
    value: "6952",
    label: "National Kaohsiung First University of Science and Technology",
    website: "http://www.nkfu.edu.tw/",
  },
  {
    value: "6953",
    label: "National Kaohsiung Normal University",
    website: "http://www.nknu.edu.tw/",
  },
  {
    value: "6954",
    label: "National Kaohsiung University of Applied Sciences",
    website: "http://www.kuas.edu.tw/",
  },
  {
    value: "6955",
    label: "National Open University",
    website: "http://www.nou.edu.tw/",
  },
  {
    value: "6956",
    label: "National Pingtung Teachers College",
    website: "http://www.npttc.edu.tw/",
  },
  {
    value: "6957",
    label: "National Pingtung University of Science and Technology",
    website: "http://www.npust.edu.tw/",
  },
  {
    value: "6958",
    label: "National Sun Yat-Sen University",
    website: "http://www.nsysu.edu.tw/",
  },
  {
    value: "6959",
    label: "National Taichung Teachers College",
    website: "http://www.ntctc.edu.tw/",
  },
  {
    value: "6960",
    label: "National Tainan Teachers College",
    website: "http://www.ntntc.edu.tw/",
  },
  {
    value: "6961",
    label: "National Taipei College of Nursing",
    website: "http://www.ntcn.edu.tw/",
  },
  {
    value: "6962",
    label: "National Taipei Teachers College",
    website: "http://www.ntptc.edu.tw/",
  },
  {
    value: "6963",
    label: "National Taipei University",
    website: "http://www.ntpu.edu.tw/",
  },
  {
    value: "6964",
    label: "National Taipei University of Technology",
    website: "http://www.ntut.edu.tw/",
  },
  {
    value: "6965",
    label: "National Taitung Teachers College",
    website: "http://www.ntttc.edu.tw/",
  },
  {
    value: "6966",
    label: "National Taiwan College of Arts",
    website: "http://www.ntca.edu.tw/",
  },
  {
    value: "6967",
    label: "National Taiwan College of Physical Education and Sports",
    website: "http://www.ntcpe.edu.tw/",
  },
  {
    value: "6968",
    label: "National Taiwan College of the Arts",
    website: "http://www.ntca.edu.tw/",
  },
  {
    value: "6969",
    label: "National Taiwan Normal University",
    website: "http://www.ntnu.edu.tw/",
  },
  {
    value: "6970",
    label: "National Taiwan Ocean University",
    website: "http://www.ntou.edu.tw/",
  },
  {
    value: "6971",
    label: "National Taiwan University",
    website: "http://www.ntu.edu.tw/",
  },
  {
    value: "6972",
    label: "National Taiwan University of Science and Technology",
    website: "http://www.ntust.edu.tw/",
  },
  {
    value: "6973",
    label: "National Tsinghua University",
    website: "http://www.nthu.edu.tw/",
  },
  {
    value: "6974",
    label: "National United University",
    website: "http://www.nuu.edu.tw/",
  },
  {
    value: "6975",
    label: "National University of Kaohsiung",
    website: "http://www.nuk.edu.tw/",
  },
  {
    value: "6976",
    label: "National Yang Ming Medical College",
    website: "http://www.ym.edu.tw/",
  },
  {
    value: "6977",
    label: "National Yunlin University of Science and Technology",
    website: "http://www.yuntech.edu.tw/",
  },
  {
    value: "6978",
    label: "Providence University",
    website: "http://www.pu.edu.tw/",
  },
  {
    value: "6979",
    label: "Shih Chien University",
    website: "http://www.scc.edu.tw/",
  },
  {
    value: "6980",
    label: "Shih Hsin University",
    website: "http://www.shu.edu.tw/",
  },
  {
    value: "6981",
    label: "Soochow University",
    website: "http://www.scu.edu.tw/",
  },
  {
    value: "6982",
    label: "Southern Taiwan University of Technology",
    website: "http://www.stut.edu.tw/",
  },
  {
    value: "6983",
    label: "St. John's & St. Mary's Institute of Technology",
    website: "http://www.sjsmit.edu.tw/",
  },
  {
    value: "6984",
    label: "TaiChung Healthcare and Management University",
    website: "http://www.thmu.edu.tw/",
  },
  {
    value: "6985",
    label: "Tainan National College of the Arts",
    website: "http://www.tnca.edu.tw/",
  },
  {
    value: "6986",
    label: "Taipei Medical College",
    website: "http://www.tmc.edu.tw/",
  },
  {
    value: "6987",
    label: "Taipei Municipal Teachers College",
    website: "http://www.tmtc.edu.tw/",
  },
  {
    value: "6988",
    label: "Taipei Physical Education College",
    website: "http://www.tpec.edu.tw/",
  },
  {
    value: "6989",
    label: "Tamkang University",
    website: "http://www.tku.edu.tw/",
  },
  {
    value: "6990",
    label: "Tatung Institute of Technology",
    website: "http://www.ttit.edu.tw/",
  },
  {
    value: "6991",
    label: "TaTung University ",
    website: "http://www.ttu.edu.tw/",
  },
  {
    value: "6992",
    label: "Tunghai University",
    website: "http://www.thu.edu.tw/",
  },
  {
    value: "6993",
    label: "Tzu Chi College of Medicine and Humanities",
    website: "http://www.tcu.edu.tw/",
  },
  {
    value: "6994",
    label: "Yuan Ze University",
    website: "http://www.yzu.edu.tw/",
  },
  {
    value: "6995",
    label: "African Virtual University",
    website: "http://www.avu.org/",
  },
  {
    value: "6996",
    label: "Aga Khan University",
    website: "http://www.aku.edu/",
  },
  {
    value: "6997",
    label: "Dares Salaam Institute of Technology",
    website: "http://www.dit.ac.tz/",
  },
  {
    value: "6998",
    label: "Hubert Kairuki Memorial University",
    website: "http://www.hkmu.ac.tz/",
  },
  {
    value: "6999",
    label: "Institute of Accountancy Arusha",
    website: "http://www.iaa.ac.tz/",
  },
  {
    value: "7000",
    label: "Institute of Finance Management",
    website: "http://www.ifm.ac.tz/",
  },
  {
    value: "7001",
    label: "International Medical & Technological University",
    website: "http://www.imtu.edu/index.htm",
  },
  {
    value: "7002",
    label: "Kilimanjaro Christian Medical College",
    website: "http://www.kcmc.ac.tz/",
  },
  {
    value: "7003",
    label: "Moshi University College of Cooperative and Business Studies",
    website: "http://www.muccobs.ac.tz/",
  },
  {
    value: "7004",
    label: "Mount Meru University",
    website: "http://www.mmu.ac.tz/",
  },
  {
    value: "7005",
    label: "Muhimbili University of Health and Allied Sciences (MUHAS)",
    website: "http://www.muchs.ac.tz/",
  },
  {
    value: "7006",
    label: "Mwalimu Nyerere Memorial Academy",
    website: "http://www.mnma.ac.tz/",
  },
  {
    value: "7007",
    label: "Mzumbe University (Chuo Kikuu Mzumbe)",
    website: "http://www.mzumbe.ac.tz/",
  },
  {
    value: "7008",
    label: "Open University of Tanzania",
    website: "http://www.out.ac.tz/",
  },
  {
    value: "7009",
    label: "Sebatian Kolowa University College",
    website: "http://www.sekuco.org/",
  },
  {
    value: "7010",
    label: "Sokoine University of Agriculture",
    website: "http://www.suanet.ac.tz/",
  },
  {
    value: "7011",
    label: "St. Augustine University of Tanzania",
    website: "http://www.saut.ac.tz/",
  },
  {
    value: "7012",
    label: "St. Joseph Group of Institutes",
    website: "http://www.stjosephtanzania.com/sjcet/index.html",
  },
  {
    value: "7013",
    label: "Tumaini University - Iringa University College",
    website: "http://www.tumaini.ac.tz/",
  },
  {
    value: "7014",
    label: "University of Dar Es Salaam",
    website: "http://www.udsm.ac.tz/",
  },
  {
    value: "7015",
    label: "University of Dodoma",
    website: "http://www.udom.ac.tz/",
  },
  {
    value: "7016",
    label: "Alfred Nobel University of Economics and Law ",
    website: "http://www.duep.edu/",
  },
  {
    value: "7017",
    label: "Bila Cerkva State Agrarian University",
    website: "http://www.btsau.kiev.ua/",
  },
  {
    value: "7018",
    label: "Chernivci National University",
    website: "http://www.chnu.cv.ua/",
  },
  {
    value: "7019",
    label: "Crimea State Medical University",
    website: "http://www.crsmu.com/",
  },
  {
    value: "7020",
    label: "Dneprodzerzhinsk State Technical University",
    website: "http://www.dstu.dp.ua/",
  },
  {
    value: "7021",
    label: "Dnepropetrovsk National University",
    website: "http://www.dnu.dp.ua/",
  },
  {
    value: "7022",
    label: "Dnepropetrovsk National University of Railway Transport",
    website: "http://www.diit.edu.ua/",
  },
  {
    value: "7023",
    label: "Donetsk National Technical University",
    website: "http://www.donntu.edu.ua/",
  },
  {
    value: "7024",
    label: "Donetsk National University",
    website: "http://www.donnu.edu.ua/",
  },
  {
    value: "7025",
    label: "Donetsk State Medical University",
    website: "http://www.donmeduni.com/",
  },
  {
    value: "7026",
    label: "East Ukrainian National University",
    website: "http://www.snu.edu.ua/",
  },
  {
    value: "7027",
    label: "Ivano-Frankivsk National University of Oli and Gas",
    website: "http://www.ifdtung.if.ua/",
  },
  {
    value: "7028",
    label: "Kharkiv National University",
    website: "http://www.univer.kharkov.ua/",
  },
  {
    value: "7029",
    label: "Kharkiv National University of Economics",
    website: "http://www.hneu.edu.ua/",
  },
  {
    value: "7030",
    label: "Kharkiv National University of Radio Electronics",
    website: "http://www.kture.kharkov.ua/",
  },
  {
    value: "7031",
    label: "Kharkiv State Medical University",
    website: "http://www.ksmu.kharkov.ua/",
  },
  {
    value: "7032",
    label:
      "Kharkiv State Technical University of Construction and Architecture",
    website: "http://www.kstuca.kharkov.ua/",
  },
  {
    value: "7033",
    label: "Kharkiv State Transport Technical University",
    website: "http://www.khadi.kharkov.ua/",
  },
  {
    value: "7034",
    label: "Kherson State University",
    website: "http://www.university.kherson.ua/",
  },
  {
    value: "7035",
    label: "Khmelnitsky National University",
    website: "http://www.tup.km.ua/",
  },
  {
    value: "7036",
    label: "Kiev National Economic University Named after Vadym Hetman",
    website: "http://www.kneu.edu.ua/",
  },
  {
    value: "7037",
    label: "Kiev National University of Construction and Architecture",
    website: "http://www.knuba.edu.ua/",
  },
  {
    value: "7038",
    label: "Kiev School Of Economics",
    website: "http://www.kse.org.ua/",
  },
  {
    value: "7039",
    label: "Kiev Slavonic University",
    website: "http://www.ksu.edu.ua/",
  },
  {
    value: "7040",
    label: "KROK Economics and Law University",
    website: "http://www.krok.edu.ua/",
  },
  {
    value: "7041",
    label: "Luhansk State Medical University",
    website: "http://www.lsmu.com/",
  },
  {
    value: "7042",
    label: "Luhansk Taras Shevchenko National Pedagogical University",
    website: "http://www.lgpu.lg.ua/",
  },
  {
    value: "7043",
    label: "Lviv Medical University",
    website: "http://www.meduniv.lviv.ua/",
  },
  {
    value: "7044",
    label: "Lviv National Medical Univeristy",
    website: "http://www.meduniv.lviv.ua/",
  },
  {
    value: "7045",
    label: "Lviv National University Ivan Franko",
    website: "http://www.franko.lviv.ua/",
  },
  {
    value: "7046",
    label: "Lviv Polytechnic National University",
    website: "http://www.lp.edu.ua/",
  },
  {
    value: "7047",
    label: "National Aerospace University Kharkov Aviation Institute",
    website: "http://www.khai.edu/",
  },
  {
    value: "7048",
    label: "National Agricultural University",
    website: "http://www.nauu.kiev.ua/",
  },
  {
    value: "7049",
    label: "National Aviation Univesity",
    website: "http://www.nau.edu.ua/",
  },
  {
    value: "7050",
    label: "National Medical University O.O. Bogomolets",
    website: "http://www.nmu.edu.ua/",
  },
  {
    value: "7051",
    label: "National Metallurgical Academy of Ukraine",
    website: "http://www.nmetau.edu.ua/",
  },
  {
    value: "7052",
    label: "National Mining University",
    website: "http://www.nmu.org.ua/",
  },
  {
    value: "7053",
    label: "National Pedagogical University M. Dragomanov ",
    website: "http://www.npu.edu.ua/",
  },
  {
    value: "7054",
    label: "National Pirogov Memorial Medical University",
    website: "http://www.vnmu.vn.ua/",
  },
  {
    value: "7055",
    label: "National Taras Shevchenko University of Kiev",
    website: "http://www.univ.kiev.ua/",
  },
  {
    value: "7056",
    label: "National Technical University (Kharkiv Polytechnical Institute)",
    website: "http://www.kpi.kharkov.ua/",
  },
  {
    value: "7057",
    label: "National Technical University (Kiev Politechnical Institute)",
    website: "http://www.kpi.ua/",
  },
  {
    value: "7058",
    label: "National Transport University",
    website: "http://www.ntu.kar.net/",
  },
  {
    value: "7059",
    label: "National University of Food Technologies",
    website: "http://www.usuft.kiev.ua/",
  },
  {
    value: "7060",
    label: "National University of Internal Affairs",
    website: "http://www.univd.edu.ua/",
  },
  {
    value: "7061",
    label: "National University of Kiev-Mohyla Academy",
    website: "http://www.ukma.kiev.ua/",
  },
  {
    value: "7062",
    label: "National University of Ostroh Academy",
    website: "http://www.uosa.uar.net/",
  },
  {
    value: "7063",
    label: "National University of Shipbuilding",
    website: "http://www.usmtu.edu.ua/",
  },
  {
    value: "7064",
    label: "Odessa National Academy of Food Technologies",
    website: "http://www.osaft.odessa.ua/",
  },
  {
    value: "7065",
    label: "Odessa National I.I. Mecnikov University",
    website: "http://www.onu.edu.ua/",
  },
  {
    value: "7066",
    label: "Odessa National Marine University",
    website: "http://www.osmu.odessa.ua/",
  },
  {
    value: "7067",
    label: "Odessa National Maritime Academy",
    website: "http://www.ma.odessa.ua/",
  },
  {
    value: "7068",
    label: "Odessa National Polytechnic University",
    website: "http://www.opu.odessa.ua/",
  },
  {
    value: "7069",
    label: "Odessa State Academy of Construction and Architecture",
    website: "http://www.ogasa.odessa.ua/",
  },
  {
    value: "7070",
    label: "Odessa State Medical University",
    website: "http://www.arosmu.org/",
  },
  {
    value: "7071",
    label: "Petro Mohyla Black Sea State University",
    website: "http://www.kma.mk.ua/",
  },
  {
    value: "7072",
    label: "Poltava National Technical University",
    website: "http://www.pntu.edu.ua/",
  },
  {
    value: "7073",
    label: "Poltava University of Consumer Cooperatives in Ukraine",
    website: "http://www.pusku.edu.ua/",
  },
  {
    value: "7074",
    label: "Precarpathian University",
    website: "http://www.pu.if.ua/",
  },
  {
    value: "7075",
    label: "Sevastopol National Technical University",
    website: "http://sevntu.com.ua/",
  },
  {
    value: "7076",
    label: "State Pedagogical University in Kryvyi Rih",
    website: "http://www.kdpu.edu.ua/",
  },
  {
    value: "7077",
    label: "Sumy National Agrarian University",
    website: "http://www.sau.sumy.ua/",
  },
  {
    value: "7078",
    label: "Sumy State University",
    website: "http://www.sumdu.edu.ua/",
  },
  {
    value: "7079",
    label: "Taurida National V.I.Vernadsky University",
    website: "http://www.tnu.crimea.ua/",
  },
  {
    value: "7080",
    label: "Technological University of Podillya",
    website: "http://www.tup.km.ua/",
  },
  {
    value: "7081",
    label: "Ternopil Academy of National Economy",
    website: "http://www.tane.edu.ua/",
  },
  {
    value: "7082",
    label: "Ternopil State Ivan Pul'uj Technical University",
    website: "http://www.tu.edu.te.ua/",
  },
  {
    value: "7083",
    label: "Ukrainian Academy of Banking",
    website: "http://www.academy.sumy.ua/",
  },
  {
    value: "7084",
    label: "Ukrainian Academy of Pharmacy",
    website: "http://www.ukrfa.kharkov.ua/",
  },
  {
    value: "7085",
    label: "Ukrainian Medical Stomatological Academy",
    website: "http://www.uamsa.net/",
  },
  {
    value: "7086",
    label: "Ukrainian National Forestry University",
    website: "http://www.forest.lviv.ua/",
  },
  {
    value: "7087",
    label: "Uzhgorod National University",
    website: "http://www.univ.uzhgorod.ua/",
  },
  {
    value: "7088",
    label: "Vinnica National Technical University",
    website: "http://www.vstu.vinnica.ua/",
  },
  {
    value: "7089",
    label: "Volyn National University Lesja Ukrainka",
    website: "http://www.vdu.edu.ua/",
  },
  {
    value: "7090",
    label: "Zaporizhzhe National Technical University",
    website: "http://www.zntu.edu.ua/",
  },
  {
    value: "7091",
    label: "Zaporizhzhe National University",
    website: "http://www.znu.edu.ua/",
  },
  {
    value: "7092",
    label: "Aga Khan University",
    website: "http://www.aku.edu/",
  },
  {
    value: "7093",
    label: "Bugema University",
    website: "http://www.bugemauniv.ac.ug/",
  },
  {
    value: "7094",
    label: "Busitema University",
    website: "http://www.busitema.ac.ug/",
  },
  {
    value: "7095",
    label: "Busoga University",
    website: "http://www.busogauniversity.ac.ug/",
  },
  {
    value: "7096",
    label: "Central Buganda University",
    website: "http://www.cbu2000.com/",
  },
  {
    value: "7097",
    label: "Gulu University",
    website: "http://www.gu.ac.ug/",
  },
  {
    value: "7098",
    label: "Islamic University in Uganda",
    website: "http://www.iuiu.ac.ug/",
  },
  {
    value: "7099",
    label: "Kampala International University",
    website: "http://www.kiu.ac.ug/",
  },
  {
    value: "7100",
    label: "Kampala University",
    website: "http://www.ku.ac.ug/",
  },
  {
    value: "7101",
    label: "Kyambogo University",
    website: "http://www.kyu.ac.ug/",
  },
  {
    value: "7102",
    label: "Makerere University",
    website: "http://www.mak.ac.ug/",
  },
  {
    value: "7103",
    label: "Mbarara University of Science and Technology",
    website: "http://www.must.ac.ug/",
  },
  {
    value: "7104",
    label: "Mountains of the Moon University",
    website: "http://www.mmu.ac.ug/",
  },
  {
    value: "7105",
    label: "Mutesa 1 Royal University",
    website: "http://www.mru.ac.ug/",
  },
  {
    value: "7106",
    label: "Ndejje University",
    website: "http://www.ndejjeuniversity.ac.ug/",
  },
  {
    value: "7107",
    label: "Nkumba University",
    website: "http://www.nkumbauniversity.ac.ug/",
  },
  {
    value: "7108",
    label: "St. Augustine International University",
    website: "http://www.saiu.ac.ug/",
  },
  {
    value: "7109",
    label: "Uganda Christian University",
    website: "http://www.ucu.ac.ug/",
  },
  {
    value: "7110",
    label: "Uganda Martyr's University",
    website: "http://www.umu.ac.ug/",
  },
  {
    value: "7111",
    label: "Abilene Christian University",
    website: "http://www.acu.edu/",
  },
  {
    value: "7112",
    label: "Academy of Art College",
    website: "http://www.academyart.edu/",
  },
  {
    value: "7113",
    label: "Adams State College",
    website: "http://www.adams.edu/",
  },
  {
    value: "7114",
    label: "Adelphi University",
    website: "http://www.adelphi.edu/",
  },
  {
    value: "7115",
    label: "Adler School of Professional Psychology",
    website: "http://www.adler.edu/",
  },
  {
    value: "7116",
    label: "Adrian College",
    website: "http://www.adrian.edu/",
  },
  {
    value: "7117",
    label: "Agnes Scott College",
    website: "http://www.scottlan.edu/",
  },
  {
    value: "7118",
    label: "Air Force Institute of Technology",
    website: "http://www.afit.af.mil/",
  },
  {
    value: "7119",
    label: "Alabama Agricultural and Mechanical University",
    website: "http://www.aamu.edu/",
  },
  {
    value: "7120",
    label: "Alabama State University",
    website: "http://www.alasu.edu/",
  },
  {
    value: "7121",
    label: "Alaska Bible College",
    website: "http://www.akbible.edu/",
  },
  {
    value: "7122",
    label: "Alaska Pacific University",
    website: "http://www.alaskapacific.edu/",
  },
  {
    value: "7123",
    label: "Albany College of Pharmacy",
    website: "http://www.acp.edu/",
  },
  {
    value: "7124",
    label: "Albany Law School",
    website: "http://www.albanylaw.edu/",
  },
  {
    value: "7125",
    label: "Albany Medical Center",
    website: "http://www.amc.edu/",
  },
  {
    value: "7126",
    label: "Albany State University",
    website: "http://www.asurams.edu/",
  },
  {
    value: "7127",
    label: "Albertus Magnus College",
    website: "http://www.albertus.edu/",
  },
  {
    value: "7128",
    label: "Albion College",
    website: "http://www.albion.edu/",
  },
  {
    value: "7129",
    label: "Albright College",
    website: "http://www.albright.edu/",
  },
  {
    value: "7130",
    label: "Alcorn State University",
    website: "http://www.alcorn.edu/",
  },
  {
    value: "7131",
    label: "Alderson Broaddus College",
    website: "http://www.ab.edu/",
  },
  {
    value: "7132",
    label: "Alfred Adler Graduate School",
    website: "http://www.alfredadler.edu/",
  },
  {
    value: "7133",
    label: "Alfred University",
    website: "http://www.alfred.edu/",
  },
  {
    value: "7134",
    label: "Alice Lloyd College",
    website: "http://www.alc.edu/",
  },
  {
    value: "7135",
    label: "Allegheny College",
    website: "http://www.alleg.edu/",
  },
  {
    value: "7136",
    label: "Allen University",
    website: "http://www.scicu.org/allen/",
  },
  {
    value: "7137",
    label: "Alma College",
    website: "http://www.alma.edu/",
  },
  {
    value: "7138",
    label: "Alvernia College",
    website: "http://www.alvernia.edu/",
  },
  {
    value: "7139",
    label: "Alverno College",
    website: "http://www.alverno.edu/",
  },
  {
    value: "7140",
    label: "Ambassador University",
    website: "http://www.ambassador.edu/",
  },
  {
    value: "7141",
    label: "Amber University",
    website: "http://www.amberu.edu/",
  },
  {
    value: "7142",
    label: "American Academy of Nutrition",
    website: "http://www.nutritioneducation.com/",
  },
  {
    value: "7143",
    label: "American Business & Technology University",
    website: "http://www.abtu.edu/",
  },
  {
    value: "7144",
    label: "American Conservatory of Music",
    website: "http://members.aol.com/amerconsmu/",
  },
  {
    value: "7145",
    label: "American Conservatory Theater",
    website: "http://www.act-sfbay.org/",
  },
  {
    value: "7146",
    label: "American-European School of Management ",
    website: "http://www.aesom.com/",
  },
  {
    value: "7147",
    label:
      "American Film Institute Center for Advanced Film and Television Studies",
    website: "http://www.afionline.org/cafts/cafts.home.html",
  },
  {
    value: "7148",
    label: "American Indian College",
    website: "http://www.aicag.edu/",
  },
  {
    value: "7149",
    label: "American InterContinental University - Atlanta",
    website: "http://www.aiuniv.edu/",
  },
  {
    value: "7150",
    label: "American InterContinental University - Ft. Lauderdale",
    website: "http://www.aiuniv.edu/",
  },
  {
    value: "7151",
    label: "American InterContinental University - Georgia",
    website: "http://www.aiuniv.edu/",
  },
  {
    value: "7152",
    label: "American InterContinental University Online",
    website: "http://www.aiu-online.com/",
  },
  {
    value: "7153",
    label: "American International College",
    website: "http://www.aic.edu/",
  },
  {
    value: "7154",
    label: "American Jewish University",
    website: "http://www.ajula.edu/",
  },
  {
    value: "7155",
    label: "American Military University",
    website: "http://www.amunet.edu/",
  },
  {
    value: "7156",
    label: "American Public University",
    website: "http://www.apus.edu/",
  },
  {
    value: "7157",
    label: "American University",
    website: "http://www.american.edu/",
  },
  {
    value: "7158",
    label: "American World University",
    website: "http://www.awu.edu/",
  },
  {
    value: "7159",
    label: "Amherst College",
    website: "http://www.amherst.edu/",
  },
  {
    value: "7160",
    label: "Anderson College",
    website: "http://www.anderson-college.edu/",
  },
  {
    value: "7161",
    label: "Anderson University",
    website: "http://www.anderson.edu/",
  },
  {
    value: "7162",
    label: "Andon College - Modesto",
    website: "http://www.andoncollege.com/",
  },
  {
    value: "7163",
    label: "Andon College - Stockton",
    website: "http://www.andoncollege.org/",
  },
  {
    value: "7164",
    label: "Andrew Jackson University",
    website: "http://www.aju.edu/",
  },
  {
    value: "7165",
    label: "Andrews University",
    website: "http://www.andrews.edu/",
  },
  {
    value: "7166",
    label: "Angelo State University",
    website: "http://www.angelo.edu/",
  },
  {
    value: "7167",
    label: "Anna Maria College",
    website: "http://www.anna-maria.edu/",
  },
  {
    value: "7168",
    label: "Antioch New England Graduate School",
    website: "http://www.antiochne.edu/",
  },
  {
    value: "7169",
    label: "Antioch University",
    website: "http://www.antioch.edu/",
  },
  {
    value: "7170",
    label: "Antioch University Los Angeles",
    website: "http://www.antiochla.edu/",
  },
  {
    value: "7171",
    label: "Antioch University Santa Barbara",
    website: "http://www.antiochsb.edu/",
  },
  {
    value: "7172",
    label: "Antioch University Seattle",
    website: "http://www.antiochsea.edu/",
  },
  {
    value: "7173",
    label: "Appalachian Bible College",
    website: "http://www.appbibco.edu/",
  },
  {
    value: "7174",
    label: "Appalachian State University",
    website: "http://www.appstate.edu/",
  },
  {
    value: "7175",
    label: "Aquinas College",
    website: "http://www.aquinas.edu/",
  },
  {
    value: "7176",
    label: "Arcadia University",
    website: "http://www.arcadia.edu/",
  },
  {
    value: "7177",
    label: "Argosy University",
    website: "http://www.argosy.edu/",
  },
  {
    value: "7178",
    label: "Argosy University - Hawaii",
    website: "http://www.argosyu.edu/",
  },
  {
    value: "7179",
    label: "Arizona Christian University",
    website: "http://www.arizonachristian.edu",
  },
  {
    value: "7180",
    label: "Arizona State University",
    website: "http://www.asu.edu/",
  },
  {
    value: "7181",
    label: "Arizona State University, Downtown Phoenix Campus",
    website: "https://campus.asu.edu/downtown",
  },
  {
    value: "7182",
    label: "Arizona State University, Polytechnic Campus",
    website: "https://campus.asu.edu/polytechnic",
  },
  {
    value: "7183",
    label: "Arizona State University, Tempe Campus",
    website: "https://campus.asu.edu/tempe",
  },
  {
    value: "7184",
    label: "Arizona State University, West Campus",
    website: "https://campus.asu.edu/west",
  },
  {
    value: "7185",
    label: "Arkansas State University",
    website: "http://www.astate.edu/",
  },
  {
    value: "7186",
    label: "Arkansas State University, Beebe",
    website: "http://www.asub.edu/",
  },
  {
    value: "7187",
    label: "Arkansas State University, Mountain Home",
    website: "http://www.asumh.edu/",
  },
  {
    value: "7188",
    label: "Arkansas State University, Newport",
    website: "http://www.asun.edu/",
  },
  {
    value: "7189",
    label: "Arkansas Tech University",
    website: "http://www.atu.edu/",
  },
  {
    value: "7190",
    label: "Arlington Baptist College",
    website: "http://www.abconline.edu/",
  },
  {
    value: "7191",
    label: "Armstrong Atlantic State University",
    website: "http://www.armstrong.edu/",
  },
  {
    value: "7192",
    label: "Armstrong University",
    website: "http://www.armstrong-u.edu/",
  },
  {
    value: "7193",
    label: "Art Academy of Cincinnati",
    website: "http://www.artacademy.edu/",
  },
  {
    value: "7194",
    label: "Art Center College of Design",
    website: "http://www.artcenter.edu/",
  },
  {
    value: "7195",
    label: "Arthur D. Little Management Education Institute",
    website: "http://www.adlsom.edu/",
  },
  {
    value: "7196",
    label: "Art Institute of Charlotte",
    website: "http://www.aich.artinstitutes.edu/",
  },
  {
    value: "7197",
    label: "Art Institute of Southern California",
    website: "http://www.aisc.edu/",
  },
  {
    value: "7198",
    label: "Asbury College",
    website: "http://www.asbury.edu/",
  },
  {
    value: "7199",
    label: "Ashland University",
    website: "http://www.ashland.edu/",
  },
  {
    value: "7200",
    label: "Assumption College",
    website: "http://www.assumption.edu/",
  },
  {
    value: "7201",
    label: "Athenaeum of Ohio",
    website: "http://www.mtsm.org/",
  },
  {
    value: "7202",
    label: "Athens State College",
    website: "http://www.athens.edu/",
  },
  {
    value: "7203",
    label: "Atlanta Christian College",
    website: "http://www.acc.edu/",
  },
  {
    value: "7204",
    label: "Atlanta College of Art",
    website: "http://www.aca.edu/",
  },
  {
    value: "7205",
    label: "Atlantic International University",
    website: "http://www.aiu.edu/",
  },
  {
    value: "7206",
    label: "Atlantic Union College",
    website: "http://www.atlanticuc.edu/",
  },
  {
    value: "7207",
    label: "Atlantic University",
    website: "http://www.atlanticuniv.edu/",
  },
  {
    value: "7208",
    label: "Auburn University",
    website: "http://www.auburn.edu/",
  },
  {
    value: "7209",
    label: "Auburn University at Montgomery",
    website: "http://www.aum.edu/",
  },
  {
    value: "7210",
    label: "Audrey Cohen College",
    website: "http://www.audrey-cohen.edu/",
  },
  {
    value: "7211",
    label: "Augsburg College",
    website: "http://www.augsburg.edu/",
  },
  {
    value: "7212",
    label: "Augustana College",
    website: "http://www.augustana.edu/",
  },
  {
    value: "7213",
    label: "Augustana College",
    website: "http://www.augie.edu/",
  },
  {
    value: "7214",
    label: "Augusta State University",
    website: "http://www.aug.edu/",
  },
  {
    value: "7215",
    label: "Aurora University",
    website: "http://www.aurora.edu/",
  },
  {
    value: "7216",
    label: "Austin College",
    website: "http://www.austinc.edu/",
  },
  {
    value: "7217",
    label: "Austin Community College",
    website: "http://www.austin.cc.tx.us/",
  },
  {
    value: "7218",
    label: "Austin Peay State University",
    website: "http://www.apsu.edu/",
  },
  {
    value: "7219",
    label: "Ave Maria University",
    website: "http://www.avemaria.edu/",
  },
  {
    value: "7220",
    label: "Averett College",
    website: "http://www.averett.edu/",
  },
  {
    value: "7221",
    label: "Avila College",
    website: "http://www.avila.edu/",
  },
  {
    value: "7222",
    label: "Azusa Pacific University",
    website: "http://www.apu.edu/",
  },
  {
    value: "7223",
    label: "Babson College",
    website: "http://www.babson.edu/",
  },
  {
    value: "7224",
    label: "Baker College of Auburn Hills",
    website: "http://www.baker.edu/visit/auburn.html",
  },
  {
    value: "7225",
    label: "Baker College of Cadillac",
    website: "http://www.baker.edu/visit/cadillac.html",
  },
  {
    value: "7226",
    label: "Baker College of Flint",
    website: "http://www.baker.edu/visit/flint.html",
  },
  {
    value: "7227",
    label: "Baker College of Mount Clemens",
    website: "http://www.baker.edu/visit/mtclemens.html",
  },
  {
    value: "7228",
    label: "Baker College of Muskegon",
    website: "http://www.baker.edu/visit/muskegon.html",
  },
  {
    value: "7229",
    label: "Baker College of Owosso",
    website: "http://www.baker.edu/visit/owosso.html",
  },
  {
    value: "7230",
    label: "Baker College of Port Huron",
    website: "http://www.baker.edu/visit/porthuron.html",
  },
  {
    value: "7231",
    label: "Baker University",
    website: "http://www.bakeru.edu/",
  },
  {
    value: "7232",
    label: "Baldwin-Wallace College",
    website: "http://www.baldwinw.edu/",
  },
  {
    value: "7233",
    label: "Ball State University",
    website: "http://www.bsu.edu/",
  },
  {
    value: "7234",
    label: "Baltimore Hebrew University",
    website: "http://www.bhu.edu/",
  },
  {
    value: "7235",
    label: "Baltimore International College",
    website: "http://www.bic.edu/",
  },
  {
    value: "7236",
    label: "Bank Street College of Education",
    website: "http://www.bnkst.edu/",
  },
  {
    value: "7237",
    label: "Baptist Bible College of Missouri",
    website: "http://www.bbcnet.edu/",
  },
  {
    value: "7238",
    label: "Baptist Bible College of Pennsylvania",
    website: "http://www.bbc.edu/",
  },
  {
    value: "7239",
    label: "Barat College",
    website: "http://www.barat.edu/",
  },
  {
    value: "7240",
    label: "Barber-Scotia College",
    website: "http://www.b-sc.edu/",
  },
  {
    value: "7241",
    label: "Barclay College",
    website: "http://www.barclaycollege.edu/",
  },
  {
    value: "7242",
    label: "Bard College",
    website: "http://www.bard.edu/",
  },
  {
    value: "7243",
    label: "Bard Graduate Center for Studies in the Decorative Arts",
    website: "http://www.bard.edu/graduate/BGC/intro.html",
  },
  {
    value: "7244",
    label: "Barnard College - Columbia University",
    website: "http://www.barnard.edu/",
  },
  {
    value: "7245",
    label: "Barry University",
    website: "http://www.barry.edu/",
  },
  {
    value: "7246",
    label: "Bartlesville Wesleyan College",
    website: "http://www.bwc.edu/",
  },
  {
    value: "7247",
    label: "Barton College",
    website: "http://www.barton.edu/",
  },
  {
    value: "7248",
    label: "Bastyr University",
    website: "http://www.bastyr.edu/",
  },
  {
    value: "7249",
    label: "Bates College",
    website: "http://www.bates.edu/",
  },
  {
    value: "7250",
    label: "Bauder College",
    website: "http://www.bauder.edu/",
  },
  {
    value: "7251",
    label: "Baylor College of Dentistry",
    website: "http://www.tambcd.edu/",
  },
  {
    value: "7252",
    label: "Baylor College of Medicine",
    website: "http://www.bcm.tmc.edu/",
  },
  {
    value: "7253",
    label: "Baylor University",
    website: "http://www.baylor.edu/",
  },
  {
    value: "7254",
    label: "Belhaven College",
    website: "http://www.belhaven.edu/",
  },
  {
    value: "7255",
    label: "Bellarmine College",
    website: "http://www.bellarmine.edu/",
  },
  {
    value: "7256",
    label: "Bellevue University",
    website: "http://www.bellevue.edu/",
  },
  {
    value: "7257",
    label: "Bellin College of Nursing",
    website: "http://www.bellin.org/bcn/",
  },
  {
    value: "7258",
    label: "Belmont Abbey College",
    website: "http://www.bac.edu/",
  },
  {
    value: "7259",
    label: "Belmont University",
    website: "http://www.belmont.edu/",
  },
  {
    value: "7260",
    label: "Beloit College",
    website: "http://www.beloit.edu/",
  },
  {
    value: "7261",
    label: "Bemidji State Univeristy",
    website: "http://www.bemidji.msus.edu/",
  },
  {
    value: "7262",
    label: "Benedict College",
    website: "http://www.benedict.edu/",
  },
  {
    value: "7263",
    label: "Benedictine College",
    website: "http://www.benedictine.edu/",
  },
  {
    value: "7264",
    label: "Benedictine University",
    website: "http://www.ben.edu/",
  },
  {
    value: "7265",
    label: "Benedictine University, Springfield College in Illinois",
    website: "http://www.sci.edu/",
  },
  {
    value: "7266",
    label: "Bennett College",
    website: "http://www.bennett.edu/",
  },
  {
    value: "7267",
    label: "Bennington College",
    website: "http://www.bennington.edu/",
  },
  {
    value: "7268",
    label: "Bentley College",
    website: "http://www.bentley.edu/",
  },
  {
    value: "7269",
    label: "Berea College",
    website: "http://www.berea.edu/",
  },
  {
    value: "7270",
    label: "Berean University of the Assemblies of God",
    website: "http://www.berean.edu/",
  },
  {
    value: "7271",
    label: "Berklee College of Music",
    website: "http://www.berklee.edu/",
  },
  {
    value: "7272",
    label: "Berne University",
    website: "http://www.berne.edu/",
  },
  {
    value: "7273",
    label: "Berry College",
    website: "http://www.berry.edu/",
  },
  {
    value: "7274",
    label: "Bethany College California",
    website: "http://www.bethany.edu/",
  },
  {
    value: "7275",
    label: "Bethany College Kansas",
    website: "http://www.bethanylb.edu/",
  },
  {
    value: "7276",
    label: "Bethany College West Virginia",
    website: "http://www.bethany.wvnet.edu/",
  },
  {
    value: "7277",
    label: "Bethel College McKenzie",
    website: "http://www.bethel-college.edu/",
  },
  {
    value: "7278",
    label: "Bethel College Mishawaka",
    website: "http://www.bethel-in.edu/",
  },
  {
    value: "7279",
    label: "Bethel College Newton",
    website: "http://www.bethelks.edu/",
  },
  {
    value: "7280",
    label: "Beth-El College of Nursing and Health Sciences",
    website: "http://www.uccs.edu/~bethel/",
  },
  {
    value: "7281",
    label: "Bethel College St. Paul",
    website: "http://www.bethel.edu/",
  },
  {
    value: "7282",
    label: "Bethune-Cookman College",
    website: "http://www.bethune.cookman.edu/",
  },
  {
    value: "7283",
    label: "Biola University",
    website: "http://www.biola.edu/",
  },
  {
    value: "7284",
    label: "Birmingham-Southern College",
    website: "http://www.bsc.edu/",
  },
  {
    value: "7285",
    label: "Blackburn College",
    website: "http://www.blackburn.edu/",
  },
  {
    value: "7286",
    label: "Black Hawk College",
    website: "http://www.bhc.edu/",
  },
  {
    value: "7287",
    label: "Black Hills State University",
    website: "http://www.bhsu.edu/",
  },
  {
    value: "7288",
    label: "Blessing-Rieman College of Nursing",
    website: "http://www.brcn.edu/",
  },
  {
    value: "7289",
    label: "Bloomfield College",
    website: "http://www.bloomfield.edu/",
  },
  {
    value: "7290",
    label: "Bloomsburg University of Pennsylvania",
    website: "http://www.bloomu.edu/",
  },
  {
    value: "7291",
    label: "Bluefield College",
    website: "http://www.bluefield.edu/",
  },
  {
    value: "7292",
    label: "Bluefield State College",
    website: "http://www.bluefield.wvnet.edu/",
  },
  {
    value: "7293",
    label: "Blue Mountain College",
    website: "http://www.bmc.edu/",
  },
  {
    value: "7294",
    label: "Bluffton College",
    website: "http://www.bluffton.edu/",
  },
  {
    value: "7295",
    label: "Bob Jones University",
    website: "http://www.bju.edu/",
  },
  {
    value: "7296",
    label: "Boise Bible College",
    website: "http://www.boisebible.edu/",
  },
  {
    value: "7297",
    label: "Boise State University",
    website: "http://www.boisestate.edu/",
  },
  {
    value: "7298",
    label: "Boricua College",
    website: "http://www.boricuacollege.edu/",
  },
  {
    value: "7299",
    label: "Boston Architectural Center",
    website: "http://www.the-bac.edu/",
  },
  {
    value: "7300",
    label: "Boston College",
    website: "http://www.bc.edu/",
  },
  {
    value: "7301",
    label: "Boston University",
    website: "http://www.bu.edu/",
  },
  {
    value: "7302",
    label: "Bowdoin College",
    website: "http://www.bowdoin.edu/",
  },
  {
    value: "7303",
    label: "Bowie State University",
    website: "http://www.bowiestate.edu/",
  },
  {
    value: "7304",
    label: "Bowling Green State University",
    website: "http://www.bgsu.edu/",
  },
  {
    value: "7305",
    label: "Bowling Green State University, Firelands",
    website: "http://www.firelands.bgsu.edu/",
  },
  {
    value: "7306",
    label: "Bradley University",
    website: "http://www.bradley.edu/",
  },
  {
    value: "7307",
    label: "Brandeis University",
    website: "http://www.brandeis.edu/",
  },
  {
    value: "7308",
    label: "Brandman University",
    website: "http://www.brandman.edu/",
  },
  {
    value: "7309",
    label: "Brenau University",
    website: "http://www.brenau.edu/",
  },
  {
    value: "7310",
    label: "Brescia University",
    website: "http://www.brescia.edu/",
  },
  {
    value: "7311",
    label: "Brevard College",
    website: "http://www.brevard.edu/",
  },
  {
    value: "7312",
    label: "Brewton-Parker College",
    website: "http://www.bpc.edu/",
  },
  {
    value: "7313",
    label: "Breyer State University (Virtual University)",
    website: "http://www.breyerstate.com/",
  },
  {
    value: "7314",
    label: "Briar Cliff College",
    website: "http://www.briar-cliff.edu/",
  },
  {
    value: "7315",
    label: "Bridgewater College",
    website: "http://www.bridgewater.edu/",
  },
  {
    value: "7316",
    label: "Bridgewater State College",
    website: "http://www.bridgew.edu/",
  },
  {
    value: "7317",
    label: "Brigham Young University",
    website: "http://www.byui.edu/",
  },
  {
    value: "7318",
    label: "Brigham Young University",
    website: "http://www.byu.edu/",
  },
  {
    value: "7319",
    label: "Brigham Young University Hawaii",
    website: "http://www.byuh.edu/",
  },
  {
    value: "7320",
    label: "Brooklyn Law School",
    website: "http://www.brooklaw.edu/",
  },
  {
    value: "7321",
    label: "Brooks Institute of Photography",
    website: "http://www.brooks.edu/",
  },
  {
    value: "7322",
    label: "Brown University",
    website: "http://www.brown.edu/",
  },
  {
    value: "7323",
    label: "Brunswick Community College",
    website: "http://www.brunswickcc.edu/",
  },
  {
    value: "7324",
    label: "Bryan College",
    website: "http://www.bryan.edu/",
  },
  {
    value: "7325",
    label: "Bryant and Stratton College",
    website: "http://www.bryantstratton.edu/",
  },
  {
    value: "7326",
    label: "Bryant University",
    website: "http://www.bryant.edu/",
  },
  {
    value: "7327",
    label: "Bryn Athyn College of the New Church",
    website: "http://www.newchurch.edu/college/",
  },
  {
    value: "7328",
    label: "Bryn Mawr College",
    website: "http://www.brynmawr.edu/",
  },
  {
    value: "7329",
    label: "Bucknell University",
    website: "http://www.bucknell.edu/",
  },
  {
    value: "7330",
    label: "Buena Vista University",
    website: "http://www.bvu.edu/",
  },
  {
    value: "7331",
    label: "Burlington College",
    website: "http://www.burlcol.edu/",
  },
  {
    value: "7332",
    label: "Butler University",
    website: "http://www.butler.edu/",
  },
  {
    value: "7333",
    label: "Cabrini College",
    website: "http://www.cabrini.edu/",
  },
  {
    value: "7334",
    label: "Caldwell College",
    website: "http://www.caldwell.edu/",
  },
  {
    value: "7335",
    label: "California Baptist College",
    website: "http://www.calbaptist.edu/",
  },
  {
    value: "7336",
    label: "California Coast University",
    website: "http://www.calcoast.edu/",
  },
  {
    value: "7337",
    label: "California College for Health Sciences",
    website: "http://www.cchs.edu/",
  },
  {
    value: "7338",
    label: "California College of Arts and Crafts",
    website: "http://www.ccac-art.edu/",
  },
  {
    value: "7339",
    label: "California College of Podiatric Medicine",
    website: "http://www.ccpm.edu/",
  },
  {
    value: "7340",
    label: "California College San Diego",
    website: "http://www.cc-sd.edu/",
  },
  {
    value: "7341",
    label: "California Institute of Integral Studies",
    website: "http://www.ciis.edu/",
  },
  {
    value: "7342",
    label: "California Institute of Technology",
    website: "http://www.caltech.edu/",
  },
  {
    value: "7343",
    label: "California Institute of the Arts",
    website: "http://www.calarts.edu/",
  },
  {
    value: "7344",
    label: "California Lutheran University",
    website: "http://www.callutheran.edu/",
  },
  {
    value: "7345",
    label: "California Maritime Academy",
    website: "http://www.csum.edu/",
  },
  {
    value: "7346",
    label: "California Polytechnic State University - San Luis Obispo",
    website: "http://www.calpoly.edu/",
  },
  {
    value: "7347",
    label: "California School of Professional Psychology - Berkley/Alameda",
    website: "http://www.cspp.edu/",
  },
  {
    value: "7348",
    label: "California School of Professional Psychology - Fresno",
    website: "http://www.cspp.edu/catalog/8.htm",
  },
  {
    value: "7349",
    label: "California School of Professional Psychology - Los Angels",
    website: "http://www.cspp.edu/catalog/9.htm",
  },
  {
    value: "7350",
    label: "California School of Professional Psychology - San Diego",
    website: "http://www.cspp.edu/catalog/10.htm",
  },
  {
    value: "7351",
    label: "California State Polytechnic University - Pomona",
    website: "http://www.csupomona.edu/",
  },
  {
    value: "7352",
    label: "California State University, Bakersfield",
    website: "http://www.csubak.edu/",
  },
  {
    value: "7353",
    label: "California State University, Channel Islands",
    website: "http://www.csuci.edu/",
  },
  {
    value: "7354",
    label: "California State University, Chico",
    website: "http://www.csuchico.edu/",
  },
  {
    value: "7355",
    label: "California State University, Dominguez Hills",
    website: "http://www.csudh.edu/",
  },
  {
    value: "7356",
    label: "California State University, Fresno",
    website: "http://www.csufresno.edu/",
  },
  {
    value: "7357",
    label: "California State University, Fullerton",
    website: "http://www.fullerton.edu/",
  },
  {
    value: "7358",
    label: "California State University, Hayward",
    website: "http://www.csuhayward.edu/",
  },
  {
    value: "7359",
    label: "California State University, Long Beach",
    website: "http://www.csulb.edu/",
  },
  {
    value: "7360",
    label: "California State University, Los Angeles",
    website: "http://www.calstatela.edu/",
  },
  {
    value: "7361",
    label: "California State University, Monterey Bay",
    website: "http://www.monterey.edu/",
  },
  {
    value: "7362",
    label: "California State University, Northridge",
    website: "http://www.csun.edu/",
  },
  {
    value: "7363",
    label: "California State University, Sacramento",
    website: "http://www.csus.edu/",
  },
  {
    value: "7364",
    label: "California State University, San Bernadino",
    website: "http://www.csusb.edu/",
  },
  {
    value: "7365",
    label: "California State University, San Marcos",
    website: "http://www.csusm.edu/",
  },
  {
    value: "7366",
    label: "California State University, Stanislaus",
    website: "http://www.csustan.edu/",
  },
  {
    value: "7367",
    label: "California University of Management and Sciences",
    website: "http://cauniversity.edu.cufce.org/",
  },
  {
    value: "7368",
    label: "California University of Pennsylvania",
    website: "http://www.cup.edu/",
  },
  {
    value: "7369",
    label: "California Western School of Law",
    website: "http://www.cwsl.edu/",
  },
  {
    value: "7370",
    label: "Calumet College of St. Joseph",
    website: "http://www.ccsj.edu/",
  },
  {
    value: "7371",
    label: "Calvary Bible College",
    website: "http://www.calvary.edu/",
  },
  {
    value: "7372",
    label: "Calvin College",
    website: "http://www.calvin.edu/",
  },
  {
    value: "7373",
    label: "Cambridge College",
    website: "http://www.cambridge.edu/",
  },
  {
    value: "7374",
    label: "Cameron University",
    website: "http://www.cameron.edu/",
  },
  {
    value: "7375",
    label: "Campbellsville College",
    website: "http://www.campbellsvil.edu/",
  },
  {
    value: "7376",
    label: "Campbell University",
    website: "http://www.campbell.edu/",
  },
  {
    value: "7377",
    label: "Canisius College",
    website: "http://www.canisius.edu/",
  },
  {
    value: "7378",
    label: "Capella University",
    website: "http://www.capella.edu/",
  },
  {
    value: "7379",
    label: "Capital University",
    website: "http://www.capital.edu/",
  },
  {
    value: "7380",
    label: "Capital University Law School",
    website: "http://www.law.capital.edu/",
  },
  {
    value: "7381",
    label: "Capitol College",
    website: "http://www.capitol-college.edu/",
  },
  {
    value: "7382",
    label: "Cardinal Stritch University",
    website: "http://www.stritch.edu/",
  },
  {
    value: "7383",
    label: "Carleton College",
    website: "http://www.carleton.edu/",
  },
  {
    value: "7384",
    label: "Carlos Albizu University",
    website: "http://www.albizu.edu/",
  },
  {
    value: "7385",
    label: "Carlow College",
    website: "http://www.carlow.edu/",
  },
  {
    value: "7386",
    label: "Carnegie Mellon University",
    website: "http://www.cmu.edu/",
  },
  {
    value: "7387",
    label: "Carroll College Helena",
    website: "http://www.carroll.edu/",
  },
  {
    value: "7388",
    label: "Carroll College Waukesha",
    website: "http://www.cc.edu/",
  },
  {
    value: "7389",
    label: "Carson-Newman College",
    website: "http://www.cn.edu/",
  },
  {
    value: "7390",
    label: "Carthage College",
    website: "http://www.carthage.edu/",
  },
  {
    value: "7391",
    label: "Case Western Reserve University",
    website: "http://www.cwru.edu/",
  },
  {
    value: "7392",
    label: "Castleton State College",
    website: "http://www.csc.vsc.edu/",
  },
  {
    value: "7393",
    label: "Catawba College",
    website: "http://www.catawba.edu/",
  },
  {
    value: "7394",
    label: "Catholic Theological Union",
    website: "http://www.ctu.edu/",
  },
  {
    value: "7395",
    label: "Cedar Crest College",
    website: "http://www.cedarcrest.edu/",
  },
  {
    value: "7396",
    label: "Cedarville College",
    website: "http://www.cedarville.edu/",
  },
  {
    value: "7397",
    label: "Centenary College",
    website: "http://www.centenarycollege.edu/",
  },
  {
    value: "7398",
    label: "Centenary College of Louisiana",
    website: "http://www.centenary.edu/",
  },
  {
    value: "7399",
    label: "Center for Humanistic Studies",
    website: "http://www.humanpsych.edu/",
  },
  {
    value: "7400",
    label: "Central Baptist College",
    website: "http://www.cbc.edu/",
  },
  {
    value: "7401",
    label: "Central Bible College",
    website: "http://www.cbcag.edu/",
  },
  {
    value: "7402",
    label: "Central Christian College of the Bible",
    website: "http://www.cccb.edu/",
  },
  {
    value: "7403",
    label: "Central College",
    website: "http://www.central.edu/",
  },
  {
    value: "7404",
    label: "Central Connecticut State University",
    website: "http://www.ccsu.edu/",
  },
  {
    value: "7405",
    label: "Central Methodist College",
    website: "http://www.cmc.edu/",
  },
  {
    value: "7406",
    label: "Central Michigan University",
    website: "http://www.cmich.edu/",
  },
  {
    value: "7407",
    label: "Central State University",
    website: "http://www.centralstate.edu/",
  },
  {
    value: "7408",
    label: "Central Washington University",
    website: "http://www.cwu.edu/",
  },
  {
    value: "7409",
    label: "Centre College",
    website: "http://www.centre.edu/",
  },
  {
    value: "7410",
    label: "Chadron State College",
    website: "http://www.csc.edu/",
  },
  {
    value: "7411",
    label: "Chaminade University of Honolulu",
    website: "http://www.chaminade.edu/",
  },
  {
    value: "7412",
    label: "Champlain College",
    website: "http://www.champlain.edu/",
  },
  {
    value: "7413",
    label: "Chapman University",
    website: "http://www.chapman.edu/",
  },
  {
    value: "7414",
    label: "Charles R. Drew University of Medicine and Science",
    website: "http://www.cdrewu.edu/",
  },
  {
    value: "7415",
    label: "Charleston Southern University",
    website: "http://www.csuniv.edu/",
  },
  {
    value: "7416",
    label: "Charter Oak State College",
    website: "http://www.cosc.edu/",
  },
  {
    value: "7417",
    label: "Chatham College",
    website: "http://www.chatham.edu/",
  },
  {
    value: "7418",
    label: "Chestnut Hill College",
    website: "http://www.chc.edu/",
  },
  {
    value: "7419",
    label: "Cheyney University of Pennsylvania",
    website: "http://www.cheyney.edu/",
  },
  {
    value: "7420",
    label: "Chicago State University",
    website: "http://www.csu.edu/",
  },
  {
    value: "7421",
    label: "Chowan College",
    website: "http://www.chowan.edu/",
  },
  {
    value: "7422",
    label: "Christendom College",
    website: "http://www.christendom.edu/",
  },
  {
    value: "7423",
    label: "Christian Brothers University",
    website: "http://www.cbu.edu/",
  },
  {
    value: "7424",
    label: "Christian Heritage College",
    website: "http://www.christianheritage.edu/",
  },
  {
    value: "7425",
    label: "Christopher Newport University",
    website: "http://www.cnu.edu/",
  },
  {
    value: "7426",
    label: "Circleville Bible College",
    website: "http://www.biblecollege.edu/",
  },
  {
    value: "7427",
    label: "City University",
    website: "http://www.cityu.edu/",
  },
  {
    value: "7428",
    label: "City University of New York, Bernard M. Baruch College",
    website: "http://www.baruch.cuny.edu/",
  },
  {
    value: "7429",
    label: "City University of New York, Brooklyn College",
    website: "http://www.brooklyn.cuny.edu/",
  },
  {
    value: "7430",
    label: "City University of New York, City College",
    website: "http://www.ccny.cuny.edu/",
  },
  {
    value: "7431",
    label: "City University of New York, College of Staten Island",
    website: "http://www.csi.cuny.edu/",
  },
  {
    value: "7432",
    label: "City University of New York (CUNY) System",
    website: "http://www.cuny.edu/",
  },
  {
    value: "7433",
    label: "City University of New York, Graduate School and University Center",
    website: "http://www.gc.cuny.edu/",
  },
  {
    value: "7434",
    label: "City University of New York, Hunter College",
    website: "http://www.hunter.cuny.edu/",
  },
  {
    value: "7435",
    label: "City University of New York, John Jay College of Criminal Justice",
    website: "http://www.jjay.cuny.edu/",
  },
  {
    value: "7436",
    label: "City University of New York, Lehman College",
    website: "http://www.lehman.cuny.edu/",
  },
  {
    value: "7437",
    label: "City University of New York, Medgar Evers College",
    website: "http://www.mec.cuny.edu/",
  },
  {
    value: "7438",
    label:
      "City University of New York Medical School / Sophie Davis School of Biomedical Education",
    website: "http://med.cuny.edu/",
  },
  {
    value: "7439",
    label: "City University of New York, New York City Technical College",
    website: "http://www.nyctc.cuny.edu/",
  },
  {
    value: "7440",
    label: "City University of New York, Queens College",
    website: "http://www.qc.edu/",
  },
  {
    value: "7441",
    label: "City University of New York, School of Law at Queens College",
    website: "http://www.law.cuny.edu/",
  },
  {
    value: "7442",
    label: "City University of New York, York College",
    website: "http://www.york.cuny.edu/",
  },
  {
    value: "7443",
    label: "Claflin College",
    website: "http://www.scicu.org/claflin/",
  },
  {
    value: "7444",
    label: "Claremont Graduate University",
    website: "http://www.cgu.edu/",
  },
  {
    value: "7445",
    label: "Claremont Lincoln University",
    website: "http://www.claremontlincoln.org/",
  },
  {
    value: "7446",
    label: "Claremont McKenna College",
    website: "http://www.mckenna.edu/",
  },
  {
    value: "7447",
    label: "Clarion University",
    website: "http://www.clarion.edu/",
  },
  {
    value: "7448",
    label: "Clark Atlanta University",
    website: "http://www.cau.edu/",
  },
  {
    value: "7449",
    label: "Clarke College",
    website: "http://www.clarke.edu/",
  },
  {
    value: "7450",
    label: "Clarkson College",
    website: "http://www.clarksoncollege.edu/",
  },
  {
    value: "7451",
    label: "Clarkson University",
    website: "http://www.clarkson.edu/",
  },
  {
    value: "7452",
    label: "Clark University",
    website: "http://www.clarku.edu/",
  },
  {
    value: "7453",
    label: "Clayton College & State University",
    website: "http://www.clayton.edu/",
  },
  {
    value: "7454",
    label: "Clearwater Christian College",
    website: "http://www.clearwater.edu/",
  },
  {
    value: "7455",
    label: "Cleary College",
    website: "http://www.cleary.edu/",
  },
  {
    value: "7456",
    label: "Clemson University",
    website: "http://www.clemson.edu/",
  },
  {
    value: "7457",
    label: "Cleveland Chiropractic College, Kansas City",
    website: "http://www.clevelandchiropractic.edu/",
  },
  {
    value: "7458",
    label: "Cleveland Chiropractic College, Los Angeles",
    website: "http://www.clevelandchiropractic.edu/",
  },
  {
    value: "7459",
    label: "Cleveland Institute of Art",
    website: "http://www.cia.edu/",
  },
  {
    value: "7460",
    label: "Cleveland Institute of Music",
    website: "http://www.cim.edu/",
  },
  {
    value: "7461",
    label: "Cleveland State University",
    website: "http://www.csuohio.edu/",
  },
  {
    value: "7462",
    label: "Coastal Carolina University",
    website: "http://www.coastal.edu/",
  },
  {
    value: "7463",
    label: "Coe College",
    website: "http://www.coe.edu/",
  },
  {
    value: "7464",
    label: "Cogswell Polytechnical College",
    website: "http://www.cogswell.edu/",
  },
  {
    value: "7465",
    label: "Coker College",
    website: "http://www.coker.edu/",
  },
  {
    value: "7466",
    label: "Colby College",
    website: "http://www.colby.edu/",
  },
  {
    value: "7467",
    label: "Colby-Sawyer College",
    website: "http://www.colby-sawyer.edu/",
  },
  {
    value: "7468",
    label: "Coleman College",
    website: "http://www.coleman.edu/",
  },
  {
    value: "7469",
    label: "Colgate University",
    website: "http://www.colgate.edu/",
  },
  {
    value: "7470",
    label: "CollegeAmerica, Denver",
    website: "http://www.collegeamerica.edu/",
  },
  {
    value: "7471",
    label: "CollegeAmerica, Phoenix",
    website: "http://www.collegeamerica.edu/",
  },
  {
    value: "7472",
    label: "College for Creative Studies",
    website: "http://www.ccscad.edu/",
  },
  {
    value: "7473",
    label: "College for Financial Planning",
    website: "http://www.fp.edu/",
  },
  {
    value: "7474",
    label: "College for Lifelong Learning",
    website: "http://www.cll.edu/",
  },
  {
    value: "7475",
    label: "College Misericordia",
    website: "http://www.miseri.edu/",
  },
  {
    value: "7476",
    label: "College of Aeronautics",
    website: "http://www.aero.edu/",
  },
  {
    value: "7477",
    label: "College of Charleston",
    website: "http://www.cofc.edu/",
  },
  {
    value: "7478",
    label: "College of DuPage",
    website: "http://www.cod.edu/",
  },
  {
    value: "7479",
    label: "College of Idaho",
    website: "http://www.collegeofidaho.edu/",
  },
  {
    value: "7480",
    label: "College of Mount St. Joseph",
    website: "http://www.msj.edu/",
  },
  {
    value: "7481",
    label: "College of Mount St. Vincent",
    website: "http://www.cmsv.edu/",
  },
  {
    value: "7482",
    label: "College of New Rochelle",
    website: "http://www.cnr.edu/",
  },
  {
    value: "7483",
    label: "College of Notre Dame",
    website: "http://www.cnd.edu/",
  },
  {
    value: "7484",
    label: "College of Notre Dame of Maryland",
    website: "http://www.ndm.edu/",
  },
  {
    value: "7485",
    label:
      "College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)",
    website: "http://www.westernu.edu/comp.html",
  },
  {
    value: "7486",
    label: "College of St. Benedict and St. John's University",
    website: "http://www.csbsju.edu/",
  },
  {
    value: "7487",
    label: "College of St. Catherine",
    website: "http://www.stkate.edu/",
  },
  {
    value: "7488",
    label: "College of St. Elizabeth",
    website: "http://www.st-elizabeth.edu/",
  },
  {
    value: "7489",
    label: "College of St. Joseph",
    website: "http://www.csj.edu/",
  },
  {
    value: "7490",
    label: "College of St. Mary",
    website: "http://www.csm.edu/",
  },
  {
    value: "7491",
    label: "College of St. Rose",
    website: "http://www.strose.edu/",
  },
  {
    value: "7492",
    label: "College of St. Scholastica",
    website: "http://www.css.edu/",
  },
  {
    value: "7493",
    label: "College of the Atlantic",
    website: "http://www.coa.edu/",
  },
  {
    value: "7494",
    label: "College of the Holy Cross",
    website: "http://www.holycross.edu/",
  },
  {
    value: "7495",
    label: "College of the Ozarks",
    website: "http://www.cofo.edu/",
  },
  {
    value: "7496",
    label: "College of Visual Arts",
    website: "http://www.cva.edu/",
  },
  {
    value: "7497",
    label: "College of William and Mary",
    website: "http://www.wm.edu/",
  },
  {
    value: "7498",
    label: "Colorado Christian University",
    website: "http://www.ccu.edu/",
  },
  {
    value: "7499",
    label: "Colorado College",
    website: "http://www.cc.colorado.edu/",
  },
  {
    value: "7500",
    label: "Colorado School of Mines",
    website: "http://www.mines.edu/",
  },
  {
    value: "7501",
    label: "Colorado State University",
    website: "http://www.colostate.edu/",
  },
  {
    value: "7502",
    label: "Colorado State University-Pueblo",
    website: "http://www.colostate-pueblo.edu/",
  },
  {
    value: "7503",
    label: "Colorado Technical University",
    website: "http://www.colotechu.edu/",
  },
  {
    value: "7504",
    label: "Columbia College Chicago",
    website: "http://www.colum.edu/",
  },
  {
    value: "7505",
    label: "Columbia College Hollywood",
    website: "http://www.columbiacollege.edu/",
  },
  {
    value: "7506",
    label: "Columbia College of Missouri",
    website: "http://www.ccis.edu/",
  },
  {
    value: "7507",
    label: "Columbia College South Carolina",
    website: "http://www.colacoll.edu/",
  },
  {
    value: "7508",
    label: "Columbia Commonwealth University",
    website: "http://www.ccwu.edu/",
  },
  {
    value: "7509",
    label: "Columbia International University",
    website: "http://www.ciu.edu/",
  },
  {
    value: "7510",
    label: "Columbia Southern University",
    website: "http://www.columbiasouthern.edu/",
  },
  {
    value: "7511",
    label: "Columbia Union College",
    website: "http://www.cuc.edu/",
  },
  {
    value: "7512",
    label: "Columbia University",
    website: "http://www.columbia.edu/",
  },
  {
    value: "7513",
    label: "Columbus College of Art and Design",
    website: "http://www.ccad.edu/",
  },
  {
    value: "7514",
    label: "Columbus State University",
    website: "http://www.colstate.edu/",
  },
  {
    value: "7515",
    label: "Columbus University",
    website: "http://www.columbusu.com/",
  },
  {
    value: "7516",
    label: "Community College of Denver",
    website: "http://ccd.rightchoice.org/",
  },
  {
    value: "7517",
    label: "Concord College",
    website: "http://www.concord.edu/",
  },
  {
    value: "7518",
    label: "Concordia College, Ann Arbor",
    website: "http://www.ccaa.edu/",
  },
  {
    value: "7519",
    label: "Concordia College, Bronxville",
    website: "http://www.concordia-ny.edu/",
  },
  {
    value: "7520",
    label: "Concordia College, Moorhead",
    website: "http://www.cord.edu/",
  },
  {
    value: "7521",
    label: "Concordia College, Selma",
    website: "http://higher-ed.lcms.org/selma.htm",
  },
  {
    value: "7522",
    label: "Concordia College, Seward",
    website: "http://www.cune.edu/",
  },
  {
    value: "7523",
    label: "Concordia College, St. Paul",
    website: "http://www.csp.edu/",
  },
  {
    value: "7524",
    label: "Concordia University, Austin",
    website: "http://www.concordia.edu/",
  },
  {
    value: "7525",
    label: "Concordia University, Irvine",
    website: "http://www.cui.edu/",
  },
  {
    value: "7526",
    label: "Concordia University, Mequon",
    website: "http://www.cuw.edu/",
  },
  {
    value: "7527",
    label: "Concordia University, Portland",
    website: "http://www.cu-portland.edu/",
  },
  {
    value: "7528",
    label: "Concordia University, River Forest",
    website: "http://www.curf.edu/",
  },
  {
    value: "7529",
    label: "Connecticut College",
    website: "http://www.conncoll.edu/",
  },
  {
    value: "7530",
    label: "Converse College",
    website: "http://www.converse.edu/",
  },
  {
    value: "7531",
    label: "Conway School of Landscape Design",
    website: "http://www.csld.edu/",
  },
  {
    value: "7532",
    label: "Coppin State College",
    website: "http://www.coppin.edu/",
  },
  {
    value: "7533",
    label: "Cornell College",
    website: "http://www.cornell-iowa.edu/",
  },
  {
    value: "7534",
    label: "Cornell University",
    website: "http://www.cornell.edu/",
  },
  {
    value: "7535",
    label: "Cornish College of the Arts",
    website: "http://www.cornish.edu/",
  },
  {
    value: "7536",
    label: "Cosmopolitan University",
    website: "http://www.cosmoedu.net/",
  },
  {
    value: "7537",
    label: "Covenant College",
    website: "http://www.covenant.edu/",
  },
  {
    value: "7538",
    label: "Cranbrook Academy of Art",
    website: "http://www.cranbrookart.edu/",
  },
  {
    value: "7539",
    label: "Creighton University",
    website: "http://www.creighton.edu/",
  },
  {
    value: "7540",
    label: "Crichton College",
    website: "http://www.crichton.edu/",
  },
  {
    value: "7541",
    label: "Crown College",
    website: "http://www.crown.edu/",
  },
  {
    value: "7542",
    label: "Culver-Stockton College",
    website: "http://www.culver.edu/",
  },
  {
    value: "7543",
    label: "Cumberland College",
    website: "http://www.cumber.edu/",
  },
  {
    value: "7544",
    label: "Cumberland University",
    website: "http://www.cumberland.edu/",
  },
  {
    value: "7545",
    label: "Curry College",
    website: "http://www.curry.edu:8080/",
  },
  {
    value: "7546",
    label: "Daemen College",
    website: "http://www.daemen.edu/",
  },
  {
    value: "7547",
    label: "Dakota State University",
    website: "http://www.dsu.edu/",
  },
  {
    value: "7548",
    label: "Dakota Wesleyan University",
    website: "http://www.dwu.edu/",
  },
  {
    value: "7549",
    label: "Dallas Baptist University",
    website: "http://www.dbu.edu/",
  },
  {
    value: "7550",
    label: "Dallas Christian College",
    website: "http://www.dallas.edu/",
  },
  {
    value: "7551",
    label: "Dana College",
    website: "http://www.dana.edu/",
  },
  {
    value: "7552",
    label: "Daniel Webster College",
    website: "http://www.dwc.edu/",
  },
  {
    value: "7553",
    label: "Danville Area Community College",
    website: "http://www.dacc.cc.il.us/",
  },
  {
    value: "7554",
    label: "Dartmouth College",
    website: "http://www.dartmouth.edu/",
  },
  {
    value: "7555",
    label: "Darton College",
    website: "http://www.darton.edu/",
  },
  {
    value: "7556",
    label: "Davenport College of Business, Grand Rapids",
    website: "http://www.davenport.edu/grandrapids/",
  },
  {
    value: "7557",
    label: "Davenport College of Business, Kalamazoo",
    website: "http://www.davenport.edu/kalamazoo/",
  },
  {
    value: "7558",
    label: "Davenport College of Business, Lansing",
    website: "http://www.davenport.edu/lansing/",
  },
  {
    value: "7559",
    label: "Davidson College",
    website: "http://www.davidson.edu/",
  },
  {
    value: "7560",
    label: "Davis and Elkins College",
    website: "http://www.dne.edu/",
  },
  {
    value: "7561",
    label: "Deaconess College of Nursing",
    website: "http://www.deaconess.edu/",
  },
  {
    value: "7562",
    label: "Delaware State University",
    website: "http://www.dsc.edu/",
  },
  {
    value: "7563",
    label: "Delaware Valley College",
    website: "http://www.devalcol.edu/",
  },
  {
    value: "7564",
    label: "Delta International University",
    website: "http://www.delta-university.org/",
  },
  {
    value: "7565",
    label: "Delta State University",
    website: "http://www.deltast.edu/",
  },
  {
    value: "7566",
    label: "Denison University",
    website: "http://www.denison.edu/",
  },
  {
    value: "7567",
    label: "Denver Paralegal Institute",
    website: "http://www.paralegal-education.com/campuses/cosprings/",
  },
  {
    value: "7568",
    label: "Denver Technical College",
    website: "http://www.dtc.edu/",
  },
  {
    value: "7569",
    label: "DePaul University",
    website: "http://www.depaul.edu/",
  },
  {
    value: "7570",
    label: "DePauw University",
    website: "http://www.depauw.edu/",
  },
  {
    value: "7571",
    label: "DeSales University",
    website: "http://www.desales.edu/",
  },
  {
    value: "7572",
    label: "Design Institute of San Diego",
    website: "http://www.disd.edu/",
  },
  {
    value: "7573",
    label: "Detroit College of Business",
    website: "http://www.dcb.edu/",
  },
  {
    value: "7574",
    label: "Detroit College of Business - Flint",
    website: "http://www.davenport.edu/e3front.dll?durki=1283",
  },
  {
    value: "7575",
    label: "Detroit College of Business - Warren",
    website: "http://www.davenport.edu/e3front.dll?durki=108",
  },
  {
    value: "7576",
    label: "Detroit College of Law",
    website: "http://www.dcl.edu/",
  },
  {
    value: "7577",
    label: "DeVry Institute of Technology, Chicago",
    website: "http://www.chi.devry.edu/",
  },
  {
    value: "7578",
    label: "DeVry Institute of Technology, Columbus",
    website: "http://www.devrycols.edu/",
  },
  {
    value: "7579",
    label: "DeVry Institute of Technology, Decatur",
    website: "http://www.atl.devry.edu/",
  },
  {
    value: "7580",
    label: "DeVry Institute of Technology, DuPage",
    website: "http://www.dpg.devry.edu/",
  },
  {
    value: "7581",
    label: "DeVry Institute of Technology, Irving",
    website: "http://www.dal.devry.edu/",
  },
  {
    value: "7582",
    label: "DeVry Institute of Technology, Kansas City",
    website: "http://www.kc.devry.edu/",
  },
  {
    value: "7583",
    label: "DeVry Institute of Technology, Phoenix",
    website: "http://www.phx.devry.edu/",
  },
  {
    value: "7584",
    label: "DeVry Institute of Technology, Pomona",
    website: "http://www.pom.devry.edu/",
  },
  {
    value: "7585",
    label: "Dickinson College",
    website: "http://www.dickinson.edu/",
  },
  {
    value: "7586",
    label: "Dickinson State University",
    website: "http://www.dsu.nodak.edu/",
  },
  {
    value: "7587",
    label: "Dillard University",
    website: "http://www.dillard.edu/",
  },
  {
    value: "7588",
    label: "Divine Word College",
    website: "http://www.svd.org",
  },
  {
    value: "7589",
    label: "Dixie College",
    website: "http://www.dixie.edu/",
  },
  {
    value: "7590",
    label: "Doane College",
    website: "http://www.doane.edu/",
  },
  {
    value: "7591",
    label: "Dominican College",
    website: "http://www.dc.edu/",
  },
  {
    value: "7592",
    label: "Dominican College of San Rafael",
    website: "http://www.dominican.edu/",
  },
  {
    value: "7593",
    label: "Dominican School of Philosophy and Theology",
    website: "http://www.dspt.edu/",
  },
  {
    value: "7594",
    label: "Dominican University",
    website: "http://www.dom.edu/",
  },
  {
    value: "7595",
    label: "Dominion College",
    website: "http://www.dominion.edu/",
  },
  {
    value: "7596",
    label: "Dordt College",
    website: "http://www.dordt.edu/",
  },
  {
    value: "7597",
    label: "Dowling College",
    website: "http://www.dowling.edu/",
  },
  {
    value: "7598",
    label: "Drake University",
    website: "http://www.drake.edu/",
  },
  {
    value: "7599",
    label: "Drew University",
    website: "http://www.drew.edu/",
  },
  {
    value: "7600",
    label: "Drexel University",
    website: "http://www.drexel.edu/",
  },
  {
    value: "7601",
    label: "Drury College",
    website: "http://www.drury.edu/",
  },
  {
    value: "7602",
    label: "Duke University",
    website: "http://www.duke.edu/",
  },
  {
    value: "7603",
    label: "Duluth Business University",
    website: "http://www.dbumn.edu/",
  },
  {
    value: "7604",
    label: "Duquesne University",
    website: "http://www.duq.edu/",
  },
  {
    value: "7605",
    label: "D'Youville College",
    website: "http://www.dyc.edu/",
  },
  {
    value: "7606",
    label: "Earlham College",
    website: "http://www.earlham.edu/",
  },
  {
    value: "7607",
    label: "EarthNet Institute",
    website: "http://www.eni.edu/",
  },
  {
    value: "7608",
    label: "East Carolina University",
    website: "http://www.ecu.edu/",
  },
  {
    value: "7609",
    label: "East Central University",
    website: "http://www.ecok.edu/",
  },
  {
    value: "7610",
    label: "Eastern College",
    website: "http://www.eastern.edu/",
  },
  {
    value: "7611",
    label: "Eastern Connecticut State University",
    website: "http://www.ecsu.ctstateu.edu/",
  },
  {
    value: "7612",
    label: "Eastern Conservatory of Music",
    website: "http://easternconservatory-music.org/",
  },
  {
    value: "7613",
    label: "Eastern Illinois University",
    website: "http://www.eiu.edu/",
  },
  {
    value: "7614",
    label: "Eastern Kentucky University",
    website: "http://www.eku.edu/",
  },
  {
    value: "7615",
    label: "Eastern Mennonite University",
    website: "http://www.emu.edu/",
  },
  {
    value: "7616",
    label: "Eastern Michigan University",
    website: "http://www.emich.edu/",
  },
  {
    value: "7617",
    label: "Eastern Nazarene College",
    website: "http://www.enc.edu/",
  },
  {
    value: "7618",
    label: "Eastern New Mexico University",
    website: "http://www.enmu.edu/",
  },
  {
    value: "7619",
    label: "Eastern Oregon University",
    website: "http://www.eou.edu/",
  },
  {
    value: "7620",
    label: "Eastern Virginia Medical School",
    website: "http://www.evms.edu/",
  },
  {
    value: "7621",
    label: "Eastern Washington University",
    website: "http://www.ewu.edu/",
  },
  {
    value: "7622",
    label: "East Stroudsburg State University",
    website: "http://www.esu.edu/",
  },
  {
    value: "7623",
    label: "East Tennessee State University",
    website: "http://www.etsu.edu/",
  },
  {
    value: "7624",
    label: "East Texas Baptist University",
    website: "http://www.etbu.edu/",
  },
  {
    value: "7625",
    label: "East-West University",
    website: "http://www.eastwest.edu/",
  },
  {
    value: "7626",
    label: "Eckerd College",
    website: "http://www.eckerd.edu/",
  },
  {
    value: "7627",
    label: "Edgewood College",
    website: "http://www.edgewood.edu/",
  },
  {
    value: "7628",
    label: "Edinboro University of Pennsylvania",
    website: "http://www.edinboro.edu/",
  },
  {
    value: "7629",
    label: "Edison Community College",
    website: "http://www.edison.edu/",
  },
  {
    value: "7630",
    label: "Edward Waters College",
    website: "http://www.ewc.edu/",
  },
  {
    value: "7631",
    label: "Elizabeth City State University",
    website: "http://www.ecsu.edu/",
  },
  {
    value: "7632",
    label: "Elizabethtown College",
    website: "http://www.etown.edu/",
  },
  {
    value: "7633",
    label: "Elmhurst College",
    website: "http://www.elmhurst.edu/",
  },
  {
    value: "7634",
    label: "Elmira College",
    website: "http://www.elmira.edu/",
  },
  {
    value: "7635",
    label: "Elms College",
    website: "http://www.elms.edu/",
  },
  {
    value: "7636",
    label: "Elon College",
    website: "http://www.elon.edu/",
  },
  {
    value: "7637",
    label: "Embry-Riddle Aeronautical University",
    website: "http://www.embryriddle.edu/",
  },
  {
    value: "7638",
    label: "Embry Riddle Aeronautical University, Prescott",
    website: "http://www.prescott.erau.edu/",
  },
  {
    value: "7639",
    label: "Emerson College",
    website: "http://www.emerson.edu/",
  },
  {
    value: "7640",
    label: "Emmanuel College",
    website: "http://www.emmanuel.edu/",
  },
  {
    value: "7641",
    label: "Emmanuel College Georgia",
    website: "http://www.emmanuel-college.edu/",
  },
  {
    value: "7642",
    label: "Emmaus Bible College",
    website: "http://www.emmaus.edu/",
  },
  {
    value: "7643",
    label: "Emory & Henry College",
    website: "http://www.ehc.edu/",
  },
  {
    value: "7644",
    label: "Emory University",
    website: "http://www.emory.edu/",
  },
  {
    value: "7645",
    label: "Emporia State University",
    website: "http://www.emporia.edu/",
  },
  {
    value: "7646",
    label: "Erskine College",
    website: "http://www.erskine.edu/",
  },
  {
    value: "7647",
    label: "Eugene Bible College",
    website: "http://www.ebc.edu/",
  },
  {
    value: "7648",
    label: "Eureka College",
    website: "http://www.eureka.edu/",
  },
  {
    value: "7649",
    label: "Evangel University",
    website: "http://www.evangel.edu/",
  },
  {
    value: "7650",
    label: "Evergreen State College",
    website: "http://www.evergreen.edu/",
  },
  {
    value: "7651",
    label: "Excel College",
    website: "http://www.excelcollege.org/",
  },
  {
    value: "7652",
    label: "Excelsior College",
    website: "http://www.regents.edu/",
  },
  {
    value: "7653",
    label: "Fairfield University",
    website: "http://www.fairfield.edu/",
  },
  {
    value: "7654",
    label: "Fairleigh Dickinson University, Florham-Madison Campus",
    website: "http://www.fdu.edu/visitorcenter/fmwelcome.html",
  },
  {
    value: "7655",
    label: "Fairleigh Dickinson University, Teaneck-Hackensack Campus",
    website: "http://www.fdu.edu/visitorcenter/thwelcome.html",
  },
  {
    value: "7656",
    label: "Fairmont State College",
    website: "http://www.fscwv.edu/",
  },
  {
    value: "7657",
    label: "Fashion Institute of New York",
    website: "http://www.fitnyc.suny.edu/",
  },
  {
    value: "7658",
    label: "Faulkner University",
    website: "http://www.faulkner.edu/",
  },
  {
    value: "7659",
    label: "Fayetteville State University",
    website: "http://www.uncfsu.edu/",
  },
  {
    value: "7660",
    label: "Felician College",
    website: "http://www.felician.edu/",
  },
  {
    value: "7661",
    label: "Ferris State University",
    website: "http://www.ferris.edu/",
  },
  {
    value: "7662",
    label: "Ferrum College",
    website: "http://www.ferrum.edu/",
  },
  {
    value: "7663",
    label: "Fielding Institute",
    website: "http://www.fielding.edu/",
  },
  {
    value: "7664",
    label: "Finch University of Health Sciences - The Chicago Medical School",
    website: "http://www.finchcms.edu/",
  },
  {
    value: "7665",
    label: "Finlandia University",
    website: "http://www.finlandia.edu/",
  },
  {
    value: "7666",
    label: "Fisher College",
    website: "http://www.fisher.edu/",
  },
  {
    value: "7667",
    label: "Fisk University",
    website: "http://www.fisk.edu/",
  },
  {
    value: "7668",
    label: "Fitchburg State College",
    website: "http://www.fsc.edu/",
  },
  {
    value: "7669",
    label: "Flagler College",
    website: "http://www.flagler.edu/",
  },
  {
    value: "7670",
    label: "Florida Agricultural and Mechanical University",
    website: "http://www.famu.edu/",
  },
  {
    value: "7671",
    label: "Florida Atlantic University",
    website: "http://www.fau.edu/",
  },
  {
    value: "7672",
    label: "Florida Christian College",
    website: "http://www.fcc.edu/",
  },
  {
    value: "7673",
    label: "Florida Community College at Jacksonville",
    website: "http://www.fccj.org/",
  },
  {
    value: "7674",
    label: "Florida Gulf Coast University",
    website: "http://www.fgcu.edu/",
  },
  {
    value: "7675",
    label: "Florida Institute of Technology",
    website: "http://www.fit.edu/",
  },
  {
    value: "7676",
    label: "Florida International University",
    website: "http://www.fiu.edu/",
  },
  {
    value: "7677",
    label: "Florida Memorial College",
    website: "http://www.fmc.edu/",
  },
  {
    value: "7678",
    label: "Florida Metropolitan University, Fort Lauderdale College",
    website: "http://www.fmu.edu/784/f-784.htm",
  },
  {
    value: "7679",
    label: "Florida Metropolitan University, Tampa College",
    website: "http://www.fmu.edu/762/f-762.htm",
  },
  {
    value: "7680",
    label: "Florida Metropolitan University, Tampa College Brandon",
    website: "http://www.fmu.edu/764/f-764.htm",
  },
  {
    value: "7681",
    label: "Florida Metropolitan University, Tampa College Lakeland",
    website: "http://www.fmu.edu/765/f-765.htm",
  },
  {
    value: "7682",
    label: "Florida Southern College",
    website: "http://www.flsouthern.edu/",
  },
  {
    value: "7683",
    label: "Florida State University",
    website: "http://www.fsu.edu/",
  },
  {
    value: "7684",
    label: "Florida University of Medicine",
    website: "http://www.fuom.us/",
  },
  {
    value: "7685",
    label: "Fontbonne College",
    website: "http://www.fontbonne.edu/",
  },
  {
    value: "7686",
    label: "Foothill-De Anza Community College District",
    website: "http://www.fhda.edu/",
  },
  {
    value: "7687",
    label: "Fordham University",
    website: "http://www.fordham.edu/",
  },
  {
    value: "7688",
    label: "Forest Institute of Professional Psychology",
    website: "http://www.forestinstitute.org/",
  },
  {
    value: "7689",
    label: "Fort Hays State University",
    website: "http://www.fhsu.edu/",
  },
  {
    value: "7690",
    label: "Fort Lewis College",
    website: "http://www.fortlewis.edu/",
  },
  {
    value: "7691",
    label: "Fort Valley State College",
    website: "http://www.fvsc.peachnet.edu/",
  },
  {
    value: "7692",
    label: "Framingham State College",
    website: "http://www.framingham.edu/",
  },
  {
    value: "7693",
    label: "Franciscan School of Theology",
    website: "http://www.fst.edu/",
  },
  {
    value: "7694",
    label: "Franciscan University of Steubenville",
    website: "http://www.franuniv.edu/",
  },
  {
    value: "7695",
    label: "Francis Marion University",
    website: "http://www.fmarion.edu/",
  },
  {
    value: "7696",
    label: "Franklin and Marshall College",
    website: "http://www.fandm.edu/",
  },
  {
    value: "7697",
    label: "Franklin College",
    website: "http://www.franklincoll.edu/",
  },
  {
    value: "7698",
    label: "Franklin Pierce College",
    website: "http://www.fpc.edu/",
  },
  {
    value: "7699",
    label: "Franklin Pierce Law Center",
    website: "http://www.fplc.edu/",
  },
  {
    value: "7700",
    label: "Franklin University",
    website: "http://www.franklin.edu/",
  },
  {
    value: "7701",
    label: "Franklin W. Olin College of Engineering",
    website: "http://www.olin.edu/",
  },
  {
    value: "7702",
    label: "Frank Lloyd Wright School of Architecture",
    website: "http://www.taliesin.edu/",
  },
  {
    value: "7703",
    label: "Fred Hutchinson Cancer Research Center",
    website: "http://www.fhcrc.org/",
  },
  {
    value: "7704",
    label: "Freed-Hardeman University",
    website: "http://www.fhu.edu/",
  },
  {
    value: "7705",
    label: "Free University (Virginia Theological University)",
    website: "http://www.vtu.edu/",
  },
  {
    value: "7706",
    label: "Free Will Baptist Bible College",
    website: "http://www.fwbbc.edu/",
  },
  {
    value: "7707",
    label: "Fresno City College",
    website: "http://www.fresnocitycollege.edu/",
  },
  {
    value: "7708",
    label: "Fresno Pacific University",
    website: "http://www.fresno.edu/",
  },
  {
    value: "7709",
    label: "Friends University",
    website: "http://www.friends.edu/",
  },
  {
    value: "7710",
    label: "Frostburg State University",
    website: "http://www.fsu.umd.edu/",
  },
  {
    value: "7711",
    label: "Full Sail University",
    website: "http://www.fullsail.com/",
  },
  {
    value: "7712",
    label: "Furman University",
    website: "http://www.furman.edu/",
  },
  {
    value: "7713",
    label: "Gallaudet University",
    website: "http://www.gallaudet.edu/",
  },
  {
    value: "7714",
    label: "Gannon University",
    website: "http://www.gannon.edu/",
  },
  {
    value: "7715",
    label: "Gardner Webb University",
    website: "http://www.gardner-webb.edu/",
  },
  {
    value: "7716",
    label: "Gemological Institute of America",
    website: "http://www.gia.edu/",
  },
  {
    value: "7717",
    label: "Geneva College",
    website: "http://www.geneva.edu/",
  },
  {
    value: "7718",
    label: "George Fox University",
    website: "http://www.georgefox.edu/",
  },
  {
    value: "7719",
    label: "George Mason University",
    website: "http://www.gmu.edu/",
  },
  {
    value: "7720",
    label: "Georgetown College",
    website: "http://www.georgetowncollege.edu/",
  },
  {
    value: "7721",
    label: "Georgetown University",
    website: "http://www.georgetown.edu/",
  },
  {
    value: "7722",
    label: "George Washington University",
    website: "http://www.gwu.edu/",
  },
  {
    value: "7723",
    label: "George Wythe College",
    website: "http://www.gwc.edu/",
  },
  {
    value: "7724",
    label: "Georgia Baptist College of Nursing",
    website: "http://www.gbcn.edu/",
  },
  {
    value: "7725",
    label: "Georgia College & State University",
    website: "http://www.gcsu.edu/",
  },
  {
    value: "7726",
    label: "Georgia Health Sciences University",
    website: "http://www.georgiahealth.edu/",
  },
  {
    value: "7727",
    label: "Georgia Institute of Technology",
    website: "http://www.gatech.edu/",
  },
  {
    value: "7728",
    label: "Georgian Court College",
    website: "http://www.georgian.edu/",
  },
  {
    value: "7729",
    label: "Georgia School of Professional Psychology",
    website: "http://www.aspp.edu/ga.html",
  },
  {
    value: "7730",
    label: "Georgia Southern University",
    website: "http://www.georgiasouthern.edu/",
  },
  {
    value: "7731",
    label: "Georgia Southwestern University",
    website: "http://www.gsw.edu/",
  },
  {
    value: "7732",
    label: "Georgia State University",
    website: "http://www.gsu.edu/",
  },
  {
    value: "7733",
    label: "Gettysburg College",
    website: "http://www.gettysburg.edu/",
  },
  {
    value: "7734",
    label: "Glendale University College of Law",
    website: "http://www.glendalelaw.edu/",
  },
  {
    value: "7735",
    label: "Glenville State College",
    website: "http://www.glenville.wvnet.edu/",
  },
  {
    value: "7736",
    label: "Goddard College",
    website: "http://www.goddard.edu/",
  },
  {
    value: "7737",
    label: "God's Bible School and College",
    website: "http://www.gbs.edu/",
  },
  {
    value: "7738",
    label: "Golden Gate University",
    website: "http://www.ggu.edu/",
  },
  {
    value: "7739",
    label: "Goldey-Beacom College",
    website: "http://goldey.gbc.edu/",
  },
  {
    value: "7740",
    label: "Gonzaga University",
    website: "http://www.gonzaga.edu/",
  },
  {
    value: "7741",
    label: "Gordon College",
    website: "http://www.gordon.edu/",
  },
  {
    value: "7742",
    label: "Gordon Conwell Theological Seminary",
    website: "http://www.gcts.edu/",
  },
  {
    value: "7743",
    label: "Goshen College",
    website: "http://www.goshen.edu/",
  },
  {
    value: "7744",
    label: "Goucher College",
    website: "http://www.goucher.edu/",
  },
  {
    value: "7745",
    label: "Governors State University",
    website: "http://www.govst.edu/",
  },
  {
    value: "7746",
    label: "Grace Bible College",
    website: "http://www.gbcol.edu/",
  },
  {
    value: "7747",
    label: "Grace College",
    website: "http://www.grace.edu/",
  },
  {
    value: "7748",
    label: "Graceland College",
    website: "http://www.graceland.edu/",
  },
  {
    value: "7749",
    label: "Grace University",
    website: "http://www.graceu.edu/",
  },
  {
    value: "7750",
    label: "Graduate Theological Union",
    website: "http://www.gtu.edu/",
  },
  {
    value: "7751",
    label: "Grambling State University",
    website: "http://www.gram.edu/",
  },
  {
    value: "7752",
    label: "Grand Canyon University",
    website: "http://www.gcu.edu/",
  },
  {
    value: "7753",
    label: "Grand Valley State University",
    website: "http://www.gvsu.edu/",
  },
  {
    value: "7754",
    label: "Grand View College",
    website: "http://www.gvc.edu/",
  },
  {
    value: "7755",
    label: "Grantham University",
    website: "http://www.grantham.edu/",
  },
  {
    value: "7756",
    label: "Gratz College",
    website: "http://www.gratzcollege.edu/",
  },
  {
    value: "7757",
    label: "Great Lakes Christian College",
    website: "http://www.glcc.edu/",
  },
  {
    value: "7758",
    label: "Green Mountain College",
    website: "http://www.greenmtn.edu/",
  },
  {
    value: "7759",
    label: "Greensboro College",
    website: "http://www.gborocollege.edu/",
  },
  {
    value: "7760",
    label: "Greenville College",
    website: "http://www.greenville.edu/",
  },
  {
    value: "7761",
    label: "Grinnell College",
    website: "http://www.grinnell.edu/",
  },
  {
    value: "7762",
    label: "Grove City College",
    website: "http://www.gcc.edu/",
  },
  {
    value: "7763",
    label: "Guilford College",
    website: "http://www.guilford.edu/",
  },
  {
    value: "7764",
    label: "Gustavus Adolphus College",
    website: "http://www.gac.edu/",
  },
  {
    value: "7765",
    label: "Gwynedd-Mercy College",
    website: "http://www.gmc.edu/",
  },
  {
    value: "7766",
    label: "Hagerstown Community College",
    website: "http://www.hcc.cc.md.us/",
  },
  {
    value: "7767",
    label: "Hamilton College",
    website: "http://www.hamilton.edu/",
  },
  {
    value: "7768",
    label: "Hamilton Technical College",
    website: "http://www.vca1.com/hamiltontech/",
  },
  {
    value: "7769",
    label: "Hamline University",
    website: "http://www.hamline.edu/",
  },
  {
    value: "7770",
    label: "Hampden-Sydney College",
    website: "http://www.hsc.edu/",
  },
  {
    value: "7771",
    label: "Hampshire College",
    website: "http://www.hampshire.edu/",
  },
  {
    value: "7772",
    label: "Hampton College",
    website: "http://www.hamptoncollege.org/",
  },
  {
    value: "7773",
    label: "Hampton University",
    website: "http://www.hamptonu.edu/",
  },
  {
    value: "7774",
    label: "Hannibal-Lagrange College",
    website: "http://www.hlg.edu/",
  },
  {
    value: "7775",
    label: "Hanover College",
    website: "http://www.hanover.edu/",
  },
  {
    value: "7776",
    label: "Harding University",
    website: "http://www.harding.edu/",
  },
  {
    value: "7777",
    label: "Harding University Graduate School of Religion",
    website: "http://www.hugsr.edu/",
  },
  {
    value: "7778",
    label: "Hardin-Simmons University",
    website: "http://www.hsutx.edu/",
  },
  {
    value: "7779",
    label: "Harrington Institute of Interior Design",
    website: "http://www.interiordesign.edu/",
  },
  {
    value: "7780",
    label: "Harris-Stowe State University",
    website: "http://www.hssu.edu/",
  },
  {
    value: "7781",
    label: "Hartford College for Women",
    website: "http://www.hartford.edu/SchoolsColleges/HCW/HCW.html",
  },
  {
    value: "7782",
    label: "Hartford Graduate Center (Rensselaer at Hartford)",
    website: "http://www.hgc.edu/",
  },
  {
    value: "7783",
    label: "Hartwick College",
    website: "http://www.hartwick.edu/",
  },
  {
    value: "7784",
    label: "Harvard University",
    website: "http://www.harvard.edu/",
  },
  {
    value: "7785",
    label: "Harvey Mudd College",
    website: "http://www.hmc.edu/",
  },
  {
    value: "7786",
    label: "Hastings College",
    website: "http://www.hastings.edu/",
  },
  {
    value: "7787",
    label: "Haverford College",
    website: "http://www.haverford.edu/",
  },
  {
    value: "7788",
    label: "Hawaii Pacific University",
    website: "http://www.hpu.edu/",
  },
  {
    value: "7789",
    label: "Heartland Baptist Bible College",
    website: "http://www.hbbc.edu/",
  },
  {
    value: "7790",
    label: "Hebrew College",
    website: "http://www.hebrewcollege.edu/",
  },
  {
    value: "7791",
    label: "Heidelberg College",
    website: "http://www.heidelberg.edu/",
  },
  {
    value: "7792",
    label: "Henderson State Univerisity",
    website: "http://www.hsu.edu/",
  },
  {
    value: "7793",
    label: "Hendrix College",
    website: "http://www.hendrix.edu/",
  },
  {
    value: "7794",
    label: "Heritage College",
    website: "http://www.heritage.edu/",
  },
  {
    value: "7795",
    label: "Hesser College",
    website: "http://www.hesser.edu/",
  },
  {
    value: "7796",
    label: "High Point University",
    website: "http://www.highpoint.edu/",
  },
  {
    value: "7797",
    label: "Hilbert College",
    website: "http://www.hilbert.edu/",
  },
  {
    value: "7798",
    label: "Hillsdale College",
    website: "http://www.hillsdale.edu/",
  },
  {
    value: "7799",
    label: "Hiram College",
    website: "http://www.hiram.edu/",
  },
  {
    value: "7800",
    label: "Hobart and William Smith Colleges",
    website: "http://www.hws.edu/",
  },
  {
    value: "7801",
    label: "Hobe Sound Bible College",
    website: "http://www.hsbc.edu/",
  },
  {
    value: "7802",
    label: "Hodges University",
    website: "http://www.hodges.edu/",
  },
  {
    value: "7803",
    label: "Hofstra University",
    website: "http://www.hofstra.edu/",
  },
  {
    value: "7804",
    label: "Hollins University",
    website: "http://www.hollins.edu/",
  },
  {
    value: "7805",
    label: "Holy Apostles College",
    website: "http://www.holy-apostles.org/",
  },
  {
    value: "7806",
    label: "Holy Cross College",
    website: "http://www.hcc-nd.edu/",
  },
  {
    value: "7807",
    label: "Holy Family College",
    website: "http://www.hfc.edu/",
  },
  {
    value: "7808",
    label: "Holy Names College",
    website: "http://www.hnc.edu/",
  },
  {
    value: "7809",
    label: "Hood College",
    website: "http://www.hood.edu/",
  },
  {
    value: "7810",
    label: "Hope College",
    website: "http://www.hope.edu/",
  },
  {
    value: "7811",
    label: "Hope International University",
    website: "http://www.hiu.edu/",
  },
  {
    value: "7812",
    label: "Houghton College",
    website: "http://www.houghton.edu/",
  },
  {
    value: "7813",
    label: "Houston Baptist University",
    website: "http://www.hbu.edu/",
  },
  {
    value: "7814",
    label: "Howard Payne University",
    website: "http://www.hputx.edu/",
  },
  {
    value: "7815",
    label: "Howard University",
    website: "http://www.howard.edu/",
  },
  {
    value: "7816",
    label: "Humboldt State University",
    website: "http://www.humboldt.edu/",
  },
  {
    value: "7817",
    label: "Humphreys College",
    website: "http://www.humphreys.edu/",
  },
  {
    value: "7818",
    label: "Huntington College",
    website: "http://www.huntcol.edu/",
  },
  {
    value: "7819",
    label: "Huron University",
    website: "http://www.huron.edu/",
  },
  {
    value: "7820",
    label: "Husson College",
    website: "http://www.husson.edu/",
  },
  {
    value: "7821",
    label: "Huston-Tillotson College",
    website: "http://www.htc.edu/",
  },
  {
    value: "7822",
    label: "ICI University",
    website: "http://www.ici.edu/",
  },
  {
    value: "7823",
    label: "ICT College",
    website: "http://www.ictcollege.edu/",
  },
  {
    value: "7824",
    label: "Idaho State University",
    website: "http://www.isu.edu/",
  },
  {
    value: "7825",
    label: "Iglobal University",
    website: "http://www.iglobal.edu/",
  },
  {
    value: "7826",
    label: "Illinois Benedictine University",
    website: "http://www.ben.edu/",
  },
  {
    value: "7827",
    label: "Illinois College",
    website: "http://www.ic.edu/",
  },
  {
    value: "7828",
    label: "Illinois College of Optometry",
    website: "http://www.ico.edu/",
  },
  {
    value: "7829",
    label: "Illinois Institute of Technology",
    website: "http://www.iit.edu/",
  },
  {
    value: "7830",
    label: "Illinois School of Professional Psychology - Chicago Campus",
    website: "http://www.aspp.edu/ilch.html",
  },
  {
    value: "7831",
    label: "Illinois School of Professional Psychology - Meadows Campus",
    website: "http://www.aspp.edu/ilrm.html",
  },
  {
    value: "7832",
    label: "Illinois State University",
    website: "http://www.ilstu.edu/",
  },
  {
    value: "7833",
    label: "Illinois Valley Community College",
    website: "http://www.ivcc.edu/",
  },
  {
    value: "7834",
    label: "Illinois Wesleyan University",
    website: "http://www.iwu.edu/",
  },
  {
    value: "7835",
    label: "Immaculata University",
    website: "http://www.immaculata.edu/",
  },
  {
    value: "7836",
    label: "IMPAC University",
    website: "http://www.impacu.edu/",
  },
  {
    value: "7837",
    label: "Indiana Institute of Technologyy",
    website: "http://www.indtech.edu/",
  },
  {
    value: "7838",
    label: "Indiana State University",
    website: "http://www.indstate.edu/",
  },
  {
    value: "7839",
    label: "Indiana University at Bloomington",
    website: "http://www.iub.edu/",
  },
  {
    value: "7840",
    label: "Indiana University at Kokomo",
    website: "http://www.iuk.edu/",
  },
  {
    value: "7841",
    label: "Indiana University at South Bend",
    website: "http://www.iusb.edu/",
  },
  {
    value: "7842",
    label: "Indiana University - East",
    website: "http://www.iue.indiana.edu/",
  },
  {
    value: "7843",
    label: "Indiana University - Northwest",
    website: "http://www.iun.indiana.edu/",
  },
  {
    value: "7844",
    label: "Indiana University of Pennsylvania",
    website: "http://www.iup.edu/",
  },
  {
    value: "7845",
    label: "Indiana University-Purdue University at Columbus",
    website: "http://www.columbus.iupui.edu/",
  },
  {
    value: "7846",
    label: "Indiana University-Purdue University at Fort Wayne",
    website: "http://www.ipfw.edu/",
  },
  {
    value: "7847",
    label: "Indiana University-Purdue University at Indianapolis",
    website: "http://www.iupui.edu/",
  },
  {
    value: "7848",
    label: "Indiana University - Southeast",
    website: "http://www.ius.indiana.edu/",
  },
  {
    value: "7849",
    label: "Indiana University (System)",
    website: "http://www.indiana.edu/",
  },
  {
    value: "7850",
    label: "Indiana Wesleyan University",
    website: "http://www.indwes.edu/",
  },
  {
    value: "7851",
    label: "Institute of Clinical Social Work",
    website: "http://www.icsw.com/",
  },
  {
    value: "7852",
    label: "Institute of Paper Science and Technology",
    website: "http://www.ipst.edu/",
  },
  {
    value: "7853",
    label: "Institute of Textile Technology",
    website: "http://www.itt.edu/",
  },
  {
    value: "7854",
    label: "Institute of Transpersonal Psychology",
    website: "http://www.itp.edu/",
  },
  {
    value: "7855",
    label: "IntelliTec College - Grand Junction",
    website: "http://www.intelliteccollege.edu/",
  },
  {
    value: "7856",
    label: "International Academy of  Merchandising and Design Chicago",
    website: "http://www.iamd.edu/",
  },
  {
    value: "7857",
    label: "International Academy of  Merchandising and Design Tampa",
    website: "http://www.academy.edu/",
  },
  {
    value: "7858",
    label: "International Bible College",
    website: "http://www.i-b-c.edu/",
  },
  {
    value: "7859",
    label: "International College",
    website: "http://www.internationalcollege.edu/",
  },
  {
    value: "7860",
    label: "Iona College",
    website: "http://www.iona.edu/",
  },
  {
    value: "7861",
    label: "Iowa State University of Science and Technology",
    website: "http://www.iastate.edu/",
  },
  {
    value: "7862",
    label: "Iowa Wesleyan College",
    website: "http://www.iwc.edu/",
  },
  {
    value: "7863",
    label: "Ithaca College",
    website: "http://www.ithaca.edu/",
  },
  {
    value: "7864",
    label: "ITT Technical Institute Fort Wayne",
    website: "http://www.itttech.edu/campus/",
  },
  {
    value: "7865",
    label: "ITT Technical Institute Indianapolis",
    website: "http://www.itttech.edu/campus/",
  },
  {
    value: "7866",
    label: "ITT Technical Institute Maitland",
    website: "http://www.itttech.edu/campus/",
  },
  {
    value: "7867",
    label: "ITT Technical Institute Portland",
    website: "http://www.itttech.edu/campus/",
  },
  {
    value: "7868",
    label: "ITT Technical Institute West Covina",
    website: "http://www.itttech.edu/campus/",
  },
  {
    value: "7869",
    label: "Jackson State University",
    website: "http://www.jsums.edu/",
  },
  {
    value: "7870",
    label: "Jacksonville State University",
    website: "http://www.jsu.edu/",
  },
  {
    value: "7871",
    label: "Jacksonville University",
    website: "http://www.ju.edu/",
  },
  {
    value: "7872",
    label: "James Madison University",
    website: "http://www.jmu.edu/",
  },
  {
    value: "7873",
    label: "Jamestown College",
    website: "http://www.jc.edu/",
  },
  {
    value: "7874",
    label: "Jarvis Christian College",
    website: "http://www.jarvis.edu/",
  },
  {
    value: "7875",
    label: "John Brown University",
    website: "http://www.jbu.edu/",
  },
  {
    value: "7876",
    label: "John Carroll University",
    website: "http://www.jcu.edu/",
  },
  {
    value: "7877",
    label: "John F. Kennedy University",
    website: "http://www.jfku.edu/",
  },
  {
    value: "7878",
    label: "John Marshall Law School",
    website: "http://www.jmls.edu/",
  },
  {
    value: "7879",
    label: "John Paul the Great Catholic University",
    website: "http://www.jpcatholic.com/",
  },
  {
    value: "7880",
    label: "Johns Hopkins University",
    website: "http://www.jhu.edu/",
  },
  {
    value: "7881",
    label: "Johnson Bible College",
    website: "http://www.jbc.edu/",
  },
  {
    value: "7882",
    label: "Johnson County Community College",
    website: "http://www.jccc.edu/",
  },
  {
    value: "7883",
    label: "Johnson C. Smith University",
    website: "http://www.jcsu.edu/",
  },
  {
    value: "7884",
    label: "Johnson State College",
    website: "http://www.jsc.vsc.edu/",
  },
  {
    value: "7885",
    label: "Johnson & Wales University",
    website: "http://www.jwu.edu/",
  },
  {
    value: "7886",
    label: "Johnson & Wales University, Charleston",
    website: "http://www.jwu.edu/charles/camp_charles.htm",
  },
  {
    value: "7887",
    label: "John Wesley College",
    website: "http://www.johnwesley.edu/",
  },
  {
    value: "7888",
    label: "Jones College",
    website: "http://www.jones.edu/",
  },
  {
    value: "7889",
    label: "Jones International University",
    website: "http://www.jonesinternational.edu/",
  },
  {
    value: "7890",
    label: "Judson College Elgin",
    website: "http://www.judson-il.edu/",
  },
  {
    value: "7891",
    label: "Judson College Marion",
    website: "http://home.judson.edu/",
  },
  {
    value: "7892",
    label: "Juniata College",
    website: "http://www.juniata.edu/",
  },
  {
    value: "7893",
    label: "Kalamazoo College",
    website: "http://www.kzoo.edu/",
  },
  {
    value: "7894",
    label: "Kankakee Community College",
    website: "http://www.kankakeecc.org/",
  },
  {
    value: "7895",
    label: "Kansas City Art Institute",
    website: "http://www.kcai.edu/",
  },
  {
    value: "7896",
    label: "Kansas State University",
    website: "http://www.ksu.edu/",
  },
  {
    value: "7897",
    label: "Kansas Wesleyan University",
    website: "http://www.kwu.edu/",
  },
  {
    value: "7898",
    label: "Kaplan University",
    website: "http://www.kaplan.edu/",
  },
  {
    value: "7899",
    label: "Katharine Gibbs School",
    website: "http://www.kgibbs.com/",
  },
  {
    value: "7900",
    label: "Kean University of New Jersey",
    website: "http://www.kean.edu/",
  },
  {
    value: "7901",
    label: "Keck Graduate Institute of Applied Life Sciences",
    website: "http://www.kgi.edu/",
  },
  {
    value: "7902",
    label: "Keene State College",
    website: "http://www.keene.edu/",
  },
  {
    value: "7903",
    label: "Keller Graduate School of Management",
    website: "http://www.keller.edu/",
  },
  {
    value: "7904",
    label: "Kendall College",
    website: "http://www.kendall.edu/",
  },
  {
    value: "7905",
    label: "Kendall College of Art and Design",
    website: "http://www.kcad.edu/",
  },
  {
    value: "7906",
    label: "Kennesaw State University",
    website: "http://www.kennesaw.edu/",
  },
  {
    value: "7907",
    label: "Kent State University",
    website: "http://www.kent.edu/",
  },
  {
    value: "7908",
    label: "Kent State University - Ashtabula",
    website: "http://www.ashtabula.kent.edu/",
  },
  {
    value: "7909",
    label: "Kent State University - East Liverpool",
    website: "http://www.kenteliv.kent.edu/",
  },
  {
    value: "7910",
    label: "Kent State University - Salem",
    website: "http://www.salem.kent.edu/",
  },
  {
    value: "7911",
    label: "Kent State University - Stark",
    website: "http://www.stark.kent.edu/",
  },
  {
    value: "7912",
    label: "Kent State University - Trumbull",
    website: "http://www.trumbull.kent.edu/",
  },
  {
    value: "7913",
    label: "Kent State University - Tuscarawas",
    website: "http://www.tusc.kent.edu/",
  },
  {
    value: "7914",
    label: "Kentucky Christian College",
    website: "http://www.kcc.edu/",
  },
  {
    value: "7915",
    label: "Kentucky State University",
    website: "http://www.kysu.edu/",
  },
  {
    value: "7916",
    label: "Kentucky Wesleyan College",
    website: "http://www.kwc.edu/",
  },
  {
    value: "7917",
    label: "Kenyon College",
    website: "http://www.kenyon.edu/",
  },
  {
    value: "7918",
    label: "Kettering University (GMI)",
    website: "http://www.kettering.edu/",
  },
  {
    value: "7919",
    label: "Keuka College",
    website: "http://www.keuka.edu/",
  },
  {
    value: "7920",
    label: "King College",
    website: "http://www.king.edu/",
  },
  {
    value: "7921",
    label: "King's College",
    website: "http://www.kings.edu/",
  },
  {
    value: "7922",
    label: "Kirksville College of Osteopathic Medicine",
    website: "http://www.kcom.edu/",
  },
  {
    value: "7923",
    label: "Kirkwood Community College",
    website: "http://www.kirkwood.edu/",
  },
  {
    value: "7924",
    label: "Knox College",
    website: "http://www.knox.edu/",
  },
  {
    value: "7925",
    label: "Knoxville College",
    website: "http://www.knoxvillecollege.edu/",
  },
  {
    value: "7926",
    label: "Kutztown University of Pennsylvania",
    website: "http://www.kutztown.edu/",
  },
  {
    value: "7927",
    label: "Laboratory Institute of Merchandising",
    website: "http://www.limcollege.edu/",
  },
  {
    value: "7928",
    label: "Lafayette College",
    website: "http://www.lafayette.edu/",
  },
  {
    value: "7929",
    label: "Lagrange College",
    website: "http://www.lgc.edu/",
  },
  {
    value: "7930",
    label: "Lake Erie College",
    website: "http://www.lakeerie.edu/",
  },
  {
    value: "7931",
    label: "Lake Forest College",
    website: "http://www.lfc.edu/",
  },
  {
    value: "7932",
    label: "Lake Forest Graduate School of Management",
    website: "http://www.lfgsm.edu/",
  },
  {
    value: "7933",
    label: "Lakeland College",
    website: "http://www.lakeland.edu/",
  },
  {
    value: "7934",
    label: "Lake Superior State University",
    website: "http://www.lssu.edu/",
  },
  {
    value: "7935",
    label: "Lakeview College of Nursing",
    website: "http://www.lakeviewcol.edu/",
  },
  {
    value: "7936",
    label: "Lamar University",
    website: "http://www.lamar.edu/",
  },
  {
    value: "7937",
    label: "Lamar University - Port Arthur",
    website: "http://www.pa.lamar.edu/",
  },
  {
    value: "7938",
    label: "Lambuth University",
    website: "http://www.lambuth.edu/",
  },
  {
    value: "7939",
    label: "Lancaster Bible College",
    website: "http://www.lbc.edu/",
  },
  {
    value: "7940",
    label: "Lander University",
    website: "http://www.lander.edu/",
  },
  {
    value: "7941",
    label: "Lane College",
    website: "http://www.lanecollege.edu/",
  },
  {
    value: "7942",
    label: "Langston University",
    website: "http://www.lunet.edu/",
  },
  {
    value: "7943",
    label: "La Roche College",
    website: "http://www.laroche.edu/",
  },
  {
    value: "7944",
    label: "La Salle University",
    website: "http://www.lasalle.edu/",
  },
  {
    value: "7945",
    label: "Lasell College",
    website: "http://www.lasell.edu/",
  },
  {
    value: "7946",
    label: "La Sierra University",
    website: "http://www.lasierra.edu/",
  },
  {
    value: "7947",
    label: "Laurus Technical Institute",
    website: "http://www.laurus.edu/",
  },
  {
    value: "7948",
    label: "Lawrence Technological University",
    website: "http://www.ltu.edu/",
  },
  {
    value: "7949",
    label: "Lawrence University",
    website: "http://www.lawrence.edu/",
  },
  {
    value: "7950",
    label: "Lebanon Valley College",
    website: "http://www.lvc.edu/",
  },
  {
    value: "7951",
    label: "Lees-McRae College",
    website: "http://www.lmc.edu/",
  },
  {
    value: "7952",
    label: "Lee University",
    website: "http://www.leeuniversity.edu/",
  },
  {
    value: "7953",
    label: "Lehigh Univervsity",
    website: "http://www.lehigh.edu/",
  },
  {
    value: "7954",
    label: "Le Moyne College",
    website: "http://www.lemoyne.edu/",
  },
  {
    value: "7955",
    label: "Le Moyne-Owen College",
    website: "http://www.lemoyne-owen.edu/",
  },
  {
    value: "7956",
    label: "Lenoir-Rhyne College",
    website: "http://www.lrc.edu/",
  },
  {
    value: "7957",
    label: "Lesley University",
    website: "http://www.lesley.edu/",
  },
  {
    value: "7958",
    label: "Letourneau University",
    website: "http://www.letu.edu/",
  },
  {
    value: "7959",
    label: "Lewis and Clark College",
    website: "http://www.lclark.edu/",
  },
  {
    value: "7960",
    label: "Lewis & Clark Community College",
    website: "http://www.lc.cc.il.us/",
  },
  {
    value: "7961",
    label: "Lewis-Clark State College",
    website: "http://www.lcsc.edu/",
  },
  {
    value: "7962",
    label: "Lewis University",
    website: "http://www.lewisu.edu/",
  },
  {
    value: "7963",
    label: "Liberty University",
    website: "http://www.liberty.edu/",
  },
  {
    value: "7964",
    label: "Life Chiropractic College West",
    website: "http://www.lifewest.edu/",
  },
  {
    value: "7965",
    label: "Life University",
    website: "http://www.life.edu/",
  },
  {
    value: "7966",
    label: "Limestone College",
    website: "http://www.limestone.edu/",
  },
  {
    value: "7967",
    label: "Lincoln Memorial University",
    website: "http://www.lmunet.edu/",
  },
  {
    value: "7968",
    label: "Lincoln University Missouri",
    website: "http://www.lincolnu.edu/",
  },
  {
    value: "7969",
    label: "Lincoln University Pennsylvania",
    website: "http://www.lincoln.edu/",
  },
  {
    value: "7970",
    label: "Lincoln University San Francisco",
    website: "http://www.lincolnuca.edu/",
  },
  {
    value: "7971",
    label: "Lindenwood University",
    website: "http://www.lindenwood.edu/",
  },
  {
    value: "7972",
    label: "Lindsey Wilson College",
    website: "http://www.lindsey.edu/",
  },
  {
    value: "7973",
    label: "Linfield College",
    website: "http://www.linfield.edu/",
  },
  {
    value: "7974",
    label: "Lipscomb University",
    website: "http://www.lipscomb.edu/",
  },
  {
    value: "7975",
    label: "Livingstone College",
    website: "http://www.livingstone.edu/",
  },
  {
    value: "7976",
    label: "Lock Haven University of Pennsylvania",
    website: "http://www.lhup.edu/",
  },
  {
    value: "7977",
    label: "Logan College of Chiropractic",
    website: "http://www.logan.edu/",
  },
  {
    value: "7978",
    label: "Loma Linda University",
    website: "http://www.llu.edu/",
  },
  {
    value: "7979",
    label: "Long Island University",
    website: "http://www.liunet.edu/",
  },
  {
    value: "7980",
    label: "Long Island University, C.W. Post Campus",
    website: "http://www.cwpost.liunet.edu/cwis/cwp/post.html",
  },
  {
    value: "7981",
    label: "Long Island University, Southampton College",
    website: "http://www.southampton.liunet.edu/",
  },
  {
    value: "7982",
    label: "Longwood College",
    website: "http://www.lwc.edu/",
  },
  {
    value: "7983",
    label: "Loras College",
    website: "http://www.loras.edu/",
  },
  {
    value: "7984",
    label: "Los Angeles College of Chiropractic",
    website: "http://www.lacc.edu/",
  },
  {
    value: "7985",
    label: "Louisiana Baptist University",
    website: "http://www.lbu.edu/",
  },
  {
    value: "7986",
    label: "Louisiana College",
    website: "http://www.lacollege.edu/",
  },
  {
    value: "7987",
    label: "Louisiana State University and Agricultural and Mechanical College",
    website: "http://www.lsu.edu/",
  },
  {
    value: "7988",
    label: "Louisiana State University at Alexandria",
    website: "http://www.lsua.edu/",
  },
  {
    value: "7989",
    label: "Louisiana State University at Eunice",
    website: "http://www.lsue.edu/",
  },
  {
    value: "7990",
    label: "Louisiana State University Health Sciences Center New Orleans",
    website: "http://www.lsuhsc.edu/",
  },
  {
    value: "7991",
    label: "Louisiana State University in Shreveport",
    website: "http://www.lsus.edu/",
  },
  {
    value: "7992",
    label: "Louisiana Tech University",
    website: "http://www.latech.edu/",
  },
  {
    value: "7993",
    label: "Lourdes College",
    website: "http://www.lourdes.edu/",
  },
  {
    value: "7994",
    label: "Loyola College in Maryland",
    website: "http://www.loyola.edu/",
  },
  {
    value: "7995",
    label: "Loyola Marymount University",
    website: "http://www.lmu.edu/",
  },
  {
    value: "7996",
    label: "Loyola University New Orleans",
    website: "http://www.loyno.edu/",
  },
  {
    value: "7997",
    label: "Loyola University of Chicago",
    website: "http://www.luc.edu/",
  },
  {
    value: "7998",
    label: "Lubbock Christian University",
    website: "http://www.lcu.edu/",
  },
  {
    value: "7999",
    label: "Lutheran Bible Institute of Seattle",
    website: "http://www.lbi.edu/",
  },
  {
    value: "8000",
    label: "Luther College",
    website: "http://www.luther.edu/",
  },
  {
    value: "8001",
    label: "Lycoming College",
    website: "http://www.lycoming.edu/",
  },
  {
    value: "8002",
    label: "Lynchburg College",
    website: "http://www.lynchburg.edu/",
  },
  {
    value: "8003",
    label: "Lyndon State College",
    website: "http://www.lsc.vsc.edu/",
  },
  {
    value: "8004",
    label: "Lynn University",
    website: "http://www.lynn.edu/",
  },
  {
    value: "8005",
    label: "Lyon College",
    website: "http://www.lyon.edu/",
  },
  {
    value: "8006",
    label: "Macalester College",
    website: "http://www.macalstr.edu/",
  },
  {
    value: "8007",
    label: "MacMurray College",
    website: "http://www.mac.edu/",
  },
  {
    value: "8008",
    label: "Macon State College",
    website: "http://www.maconstate.edu/",
  },
  {
    value: "8009",
    label: "Madison University (Distance Education)",
    website: "http://www.madisonu.com/",
  },
  {
    value: "8010",
    label: "Madonna University",
    website: "http://madonna2.siteobjects.com/pages/index.cfm",
  },
  {
    value: "8011",
    label: "Maharishi University of Management",
    website: "http://www.mum.edu/",
  },
  {
    value: "8012",
    label: "Maine College of Art",
    website: "http://www.meca.edu/",
  },
  {
    value: "8013",
    label: "Maine Maritime Academy",
    website: "http://www.mainemaritime.edu/",
  },
  {
    value: "8014",
    label: "Malone College",
    website: "http://www.malone.edu/",
  },
  {
    value: "8015",
    label: "Manchester College",
    website: "http://www.manchester.edu/",
  },
  {
    value: "8016",
    label: "Manhattan Christian College",
    website: "http://www.mccks.edu/",
  },
  {
    value: "8017",
    label: "Manhattan College",
    website: "http://www.mancol.edu/",
  },
  {
    value: "8018",
    label: "Manhattan School of Music",
    website: "http://www.msmnyc.edu/",
  },
  {
    value: "8019",
    label: "Manhattanville College",
    website: "http://www.mville.edu/",
  },
  {
    value: "8020",
    label: "Mankato State University",
    website: "http://www.mankato.msus.edu/",
  },
  {
    value: "8021",
    label: "Mansfield University of Pennsylvania",
    website: "http://www.mnsfld.edu/",
  },
  {
    value: "8022",
    label: "Maranatha Baptist Bible College",
    website: "http://www.mbbc.edu/",
  },
  {
    value: "8023",
    label: "Marian College",
    website: "http://www.marian.edu/",
  },
  {
    value: "8024",
    label: "Marian College of Fond Du Lac",
    website: "http://www.mariancoll.edu/",
  },
  {
    value: "8025",
    label: "Marietta College",
    website: "http://www.marietta.edu/",
  },
  {
    value: "8026",
    label: "Marist College",
    website: "http://www.marist.edu/",
  },
  {
    value: "8027",
    label: "Marlboro College",
    website: "http://www.marlboro.edu/",
  },
  {
    value: "8028",
    label: "Marquette University",
    website: "http://www.mu.edu/",
  },
  {
    value: "8029",
    label: "Marshall University",
    website: "http://www.marshall.edu/",
  },
  {
    value: "8030",
    label: "Mars Hill College",
    website: "http://www.mhc.edu/",
  },
  {
    value: "8031",
    label: "Martin Luther College",
    website: "http://www.mlc-wels.edu/",
  },
  {
    value: "8032",
    label: "Martin Methodist College",
    website: "http://www.martinmethodist.edu/",
  },
  {
    value: "8033",
    label: "Martin University",
    website: "http://www.martin.edu/",
  },
  {
    value: "8034",
    label: "Mary Baldwin College",
    website: "http://www.mbc.edu/",
  },
  {
    value: "8035",
    label: "Marycrest International University",
    website: "http://www.mcrest.edu/",
  },
  {
    value: "8036",
    label: "Marygrove College",
    website: "http://www.marygrove.edu/",
  },
  {
    value: "8037",
    label: "Marylhurst University",
    website: "http://www.marylhurst.edu/",
  },
  {
    value: "8038",
    label: "Marymount College New York",
    website: "http://www.marymt.edu/",
  },
  {
    value: "8039",
    label: "Marymount Manhattan College",
    website: "http://marymount.mmm.edu/",
  },
  {
    value: "8040",
    label: "Marymount University",
    website: "http://www.marymount.edu/",
  },
  {
    value: "8041",
    label: "Maryville College",
    website: "http://www.maryvillecollege.edu/",
  },
  {
    value: "8042",
    label: "Maryville University of St. Louis",
    website: "http://www.maryvillestl.edu/",
  },
  {
    value: "8043",
    label: "Mary Washington College",
    website: "http://www.mwc.edu/",
  },
  {
    value: "8044",
    label: "Marywood University",
    website: "http://www.marywood.edu/",
  },
  {
    value: "8045",
    label: "Massachusetts College of Art",
    website: "http://www.massart.edu/",
  },
  {
    value: "8046",
    label: "Massachusetts College of Liberal Arts",
    website: "http://www.mcla.mass.edu/",
  },
  {
    value: "8047",
    label: "Massachusetts College of Pharmacy and Allied Health Sciences",
    website: "http://www.mcp.edu/",
  },
  {
    value: "8048",
    label: "Massachusetts Institute of Technology",
    website: "http://www.mit.edu/",
  },
  {
    value: "8049",
    label: "Massachusetts Maritime Academy",
    website: "http://www.mma.mass.edu/",
  },
  {
    value: "8050",
    label: "Massachusetts School of Professional Psychology",
    website: "http://www.mspp.edu/",
  },
  {
    value: "8051",
    label: "Mayo Graduate School",
    website: "http://www.mayo.edu/mgs/gs.html",
  },
  {
    value: "8052",
    label: "Mayo Medical School",
    website: "http://www.mayo.edu/education/mms/",
  },
  {
    value: "8053",
    label: "Mayville State University",
    website: "http://www.masu.nodak.edu/",
  },
  {
    value: "8054",
    label: "McKendree College",
    website: "http://www.mckendree.edu/",
  },
  {
    value: "8055",
    label: "McMurry University",
    website: "http://www.mcm.edu/",
  },
  {
    value: "8056",
    label: "McNeese State University",
    website: "http://www.mcneese.edu/",
  },
  {
    value: "8057",
    label: "McPherson College",
    website: "http://www.mcpherson.edu/",
  },
  {
    value: "8058",
    label: "Medaille College",
    website: "http://www.medaille.edu/",
  },
  {
    value: "8059",
    label: "Medcenter One College of Nursing",
    website: "http://www.medcenterone.com/nursing/nursing.htm",
  },
  {
    value: "8060",
    label: "Medical College of Georgia",
    website: "http://www.mcg.edu/",
  },
  {
    value: "8061",
    label: "Medical College of Ohio",
    website: "http://www.mco.edu/",
  },
  {
    value: "8062",
    label: "Medical College of Pennsylvania and Hahnemann University",
    website: "http://www.mcphu.edu/",
  },
  {
    value: "8063",
    label: "Medical College of Wisconsin",
    website: "http://www.mcw.edu/",
  },
  {
    value: "8064",
    label: "Medical University of South Carolina",
    website: "http://www.musc.edu/",
  },
  {
    value: "8065",
    label: "Meharry Medical College",
    website: "http://www.mmc.edu/",
  },
  {
    value: "8066",
    label: "Memphis College of Art",
    website: "http://www.mca.edu/",
  },
  {
    value: "8067",
    label: "Menlo College",
    website: "http://www.menlo.edu/",
  },
  {
    value: "8068",
    label: "Mennonite College of Nursing",
    website: "http://www.mcn.ilstu.edu/",
  },
  {
    value: "8069",
    label: "Mercer University",
    website: "http://www.mercer.edu/",
  },
  {
    value: "8070",
    label: "Mercer University, Cecil B. Day Campus",
    website: "http://www.mercer.edu/cbd/",
  },
  {
    value: "8071",
    label: "Mercy College",
    website: "http://www.mercynet.edu/",
  },
  {
    value: "8072",
    label: "Mercyhurst College",
    website: "http://www.mercyhurst.edu/",
  },
  {
    value: "8073",
    label: "Meredith College",
    website: "http://www.meredith.edu/",
  },
  {
    value: "8074",
    label: "Merrimack College",
    website: "http://www.merrimack.edu/",
  },
  {
    value: "8075",
    label: "Mesa State College",
    website: "http://www.mesastate.edu/",
  },
  {
    value: "8076",
    label: "Messiah College",
    website: "http://www.messiah.edu/",
  },
  {
    value: "8077",
    label: "Methodist College",
    website: "http://www.methodist.edu/",
  },
  {
    value: "8078",
    label: "Metropolitan State College of Denver",
    website: "http://www.mscd.edu/",
  },
  {
    value: "8079",
    label: "Metropolitan State University",
    website: "http://www.metro.msus.edu/",
  },
  {
    value: "8080",
    label: "MGH Institute of Health Professions",
    website: "http://www.mghihp.edu/",
  },
  {
    value: "8081",
    label: "Miami Dade College",
    website: "http://www.mdc.edu/",
  },
  {
    value: "8082",
    label: "Miami University of Ohio",
    website: "http://www.muohio.edu/",
  },
  {
    value: "8083",
    label: "Miami University of Ohio - Hamilton",
    website: "http://www.ham.muohio.edu/",
  },
  {
    value: "8084",
    label: "Miami University of Ohio - Middletown",
    website: "http://www.mid.muohio.edu/",
  },
  {
    value: "8085",
    label: "Michigan School of Professional Psychology",
    website: "http://www.mispp.edu/",
  },
  {
    value: "8086",
    label: "Michigan State University",
    website: "http://www.msu.edu/",
  },
  {
    value: "8087",
    label: "Michigan Technological University",
    website: "http://www.mtu.edu/",
  },
  {
    value: "8088",
    label: "Mid-America Nazarene University",
    website: "http://www.mnu.edu/",
  },
  {
    value: "8089",
    label: "Mid-American Bible College",
    website: "http://www.concentric.net/~rkriesel/MBC/MBC.shtml",
  },
  {
    value: "8090",
    label: "Mid-Continent Baptist Bible College",
    website: "http://www.mcbc.edu/",
  },
  {
    value: "8091",
    label: "Middlebury College",
    website: "http://www.middlebury.edu/",
  },
  {
    value: "8092",
    label: "Middle Tennessee State University",
    website: "http://www.mtsu.edu/",
  },
  {
    value: "8093",
    label: "Midland Lutheran College",
    website: "http://www.mlc.edu/",
  },
  {
    value: "8094",
    label: "Midway College",
    website: "http://www.midway.edu/",
  },
  {
    value: "8095",
    label: "Midwestern State University",
    website: "http://www.mwsu.edu/",
  },
  {
    value: "8096",
    label: "Midwestern University",
    website: "http://www.midwestern.edu/",
  },
  {
    value: "8097",
    label: "Miles College",
    website: "http://www.miles.edu/",
  },
  {
    value: "8098",
    label: "Millennia Atlantic University",
    website: "http://www.maufl.edu/",
  },
  {
    value: "8099",
    label: "Millersville University of Pennsylvania",
    website: "http://www.millersv.edu/",
  },
  {
    value: "8100",
    label: "Milligan College",
    website: "http://www.milligan.edu/",
  },
  {
    value: "8101",
    label: "Millikin University",
    website: "http://www.millikin.edu/",
  },
  {
    value: "8102",
    label: "Millsaps College",
    website: "http://www.millsaps.edu/",
  },
  {
    value: "8103",
    label: "Mills College",
    website: "http://www.mills.edu/",
  },
  {
    value: "8104",
    label: "Mills Grae University",
    website: "http://www.mgu.edu/",
  },
  {
    value: "8105",
    label: "Milwaukee Institute of Art and Design",
    website: "http://www.miad.edu/",
  },
  {
    value: "8106",
    label: "Milwaukee School of Engineering",
    website: "http://www.msoe.edu/",
  },
  {
    value: "8107",
    label: "Minneapolis College of Art and Design",
    website: "http://www.mcad.edu/",
  },
  {
    value: "8108",
    label: "Minnesota Bible College",
    website: "http://www.mnbc.edu/",
  },
  {
    value: "8109",
    label: "Minnesota School of Professional Psychology",
    website: "http://www.aspp.edu/mn.html",
  },
  {
    value: "8110",
    label: "Minot State University",
    website: "http://www.misu.nodak.edu/",
  },
  {
    value: "8111",
    label: "Mississippi College",
    website: "http://www.mc.edu/",
  },
  {
    value: "8112",
    label: "Mississippi State University",
    website: "http://www.msstate.edu/",
  },
  {
    value: "8113",
    label: "Mississippi University for Women",
    website: "http://www.muw.edu/",
  },
  {
    value: "8114",
    label: "Mississippi Valley State University",
    website: "http://www.mvsu.edu/",
  },
  {
    value: "8115",
    label: "Missouri Baptist College",
    website: "http://www.mobap.edu/",
  },
  {
    value: "8116",
    label: "Missouri Southern State College",
    website: "http://www.mssc.edu/",
  },
  {
    value: "8117",
    label: "Missouri Tech",
    website: "http://www.motech.edu/",
  },
  {
    value: "8118",
    label: "Missouri University of Science and Technology",
    website: "http://www.mst.edu/",
  },
  {
    value: "8119",
    label: "Missouri Valley College",
    website: "http://www.murlin.com/~webfx/mvc/",
  },
  {
    value: "8120",
    label: "Missouri Western State College",
    website: "http://www.mwsc.edu/",
  },
  {
    value: "8121",
    label: "Molloy College",
    website: "http://www.molloy.edu/",
  },
  {
    value: "8122",
    label: "Monmouth University",
    website: "http://www.monmouth.edu/",
  },
  {
    value: "8123",
    label: "Montana State University",
    website: "http://www.montana.edu/",
  },
  {
    value: "8124",
    label: "Montana State University - Billings",
    website: "http://www.msubillings.edu/",
  },
  {
    value: "8125",
    label: "Montana State University - Northern",
    website: "http://www.msun.edu/",
  },
  {
    value: "8126",
    label: "Montana Tech",
    website: "http://www.mtech.edu/",
  },
  {
    value: "8127",
    label: "Montclair State University",
    website: "http://www.montclair.edu/",
  },
  {
    value: "8128",
    label: "Monterey Institute of International Studies",
    website: "http://www.miis.edu/",
  },
  {
    value: "8129",
    label: "Montreat College",
    website: "http://www.montreat.edu/",
  },
  {
    value: "8130",
    label: "Montserrat College of Art",
    website: "http://www.montserrat.edu/",
  },
  {
    value: "8131",
    label: "Moody Bible Institute",
    website: "http://www.moody.edu/",
  },
  {
    value: "8132",
    label: "Moore College of Art and Design",
    website: "http://www.moore.edu/",
  },
  {
    value: "8133",
    label: "Moorhead State University",
    website: "http://www.moorhead.msus.edu/",
  },
  {
    value: "8134",
    label: "Moraine Valley Community College",
    website: "http://www.morainevalley.edu/",
  },
  {
    value: "8135",
    label: "Moravian College",
    website: "http://www.moravian.edu/",
  },
  {
    value: "8136",
    label: "Morehead State University",
    website: "http://www.morehead-st.edu/",
  },
  {
    value: "8137",
    label: "Morehouse College",
    website: "http://www.morehouse.edu/",
  },
  {
    value: "8138",
    label: "Morehouse School of Medicine",
    website: "http://www.msm.edu/",
  },
  {
    value: "8139",
    label: "Morgan State University",
    website: "http://www.morgan.edu/",
  },
  {
    value: "8140",
    label: "Morningside College",
    website: "http://www.morningside.edu/",
  },
  {
    value: "8141",
    label: "Morris Brown College",
    website: "http://www.morrisbrown.edu/",
  },
  {
    value: "8142",
    label: "Morris College",
    website: "http://www.scicu.org/morris/",
  },
  {
    value: "8143",
    label: "Morrison College",
    website: "http://www.morrison.neumont.edu/",
  },
  {
    value: "8144",
    label: "Mountain State University",
    website: "http://www.mountainstate.edu/",
  },
  {
    value: "8145",
    label: "Mount Aloysius College",
    website: "http://www.mtaloy.edu/",
  },
  {
    value: "8146",
    label: "Mount Carmel College of Nursing",
    website: "http://www.mccn.edu/",
  },
  {
    value: "8147",
    label: "Mount Holyoke College",
    website: "http://www.mtholyoke.edu/",
  },
  {
    value: "8148",
    label: "Mount Ida College",
    website: "http://www.mountida.edu/",
  },
  {
    value: "8149",
    label: "Mount Marty College",
    website: "http://www.mtmc.edu/",
  },
  {
    value: "8150",
    label: "Mount Mary College",
    website: "http://www.mtmary.edu/",
  },
  {
    value: "8151",
    label: "Mount Mercy College",
    website: "http://www.mtmercy.edu/",
  },
  {
    value: "8152",
    label: "Mount Olive College",
    website: "http://www.mountolivecollege.edu/",
  },
  {
    value: "8153",
    label: "Mount Senario College",
    website: "http://www.mscfs.edu/",
  },
  {
    value: "8154",
    label: "Mount Sinai School of Medicine",
    website: "http://www.mssm.edu/",
  },
  {
    value: "8155",
    label: "Mount St. Clare College",
    website: "http://www.clare.edu/",
  },
  {
    value: "8156",
    label: "Mount St. Mary College",
    website: "http://www.msmc.edu/",
  },
  {
    value: "8157",
    label: "Mount St. Mary's College California",
    website: "http://www.msmc.la.edu/",
  },
  {
    value: "8158",
    label: "Mount St. Mary's College Maryland",
    website: "http://www.msmary.edu/",
  },
  {
    value: "8159",
    label: "Mount Union College",
    website: "http://www.mountunion.edu/",
  },
  {
    value: "8160",
    label: "Mount Vernon College",
    website: "http://www.gwu.edu/~mvcgw/",
  },
  {
    value: "8161",
    label: "Mount Vernon Nazarene College",
    website: "http://www.mvnc.edu/",
  },
  {
    value: "8162",
    label: "Muhlenberg College",
    website: "http://www.muhlberg.edu/",
  },
  {
    value: "8163",
    label: "Multnomah Bible College",
    website: "http://www.multnomah.edu/",
  },
  {
    value: "8164",
    label: "Murray State University",
    website: "http://www.mursuky.edu/",
  },
  {
    value: "8165",
    label: "Muskingum College",
    website: "http://www.muskingum.edu/",
  },
  {
    value: "8166",
    label: "NAES College",
    website: "http://www.naes.indian.com/",
  },
  {
    value: "8167",
    label: "National American University",
    website: "http://www.national.edu/",
  },
  {
    value: "8168",
    label: "National American University, Albuquerque",
    website: "http://www.national.edu/albuquerque.html",
  },
  {
    value: "8169",
    label: "National American University, Colorado Springs",
    website: "http://www.national.edu/col_springs.html",
  },
  {
    value: "8170",
    label: "National American University, Denver",
    website: "http://www.national.edu/denver.html",
  },
  {
    value: "8171",
    label: "National American University, Kansas City",
    website: "http://www.national.edu/kansas_city.html",
  },
  {
    value: "8172",
    label: "National American University, Roseville",
    website: "http://www.national.edu/roseville.html",
  },
  {
    value: "8173",
    label: "National American University, Sioux Falls",
    website: "http://www.national.edu/sioux_falls.html",
  },
  {
    value: "8174",
    label: "National College of Chiropractic",
    website: "http://www.national.chiropractic.edu/",
  },
  {
    value: "8175",
    label: "National College of Naturopathic Medicine",
    website: "http://www.ncnm.edu/",
  },
  {
    value: "8176",
    label: "National Defense University",
    website: "http://www.ndu.edu/",
  },
  {
    value: "8177",
    label: "National Hispanic University",
    website: "http://www.nhu.edu/",
  },
  {
    value: "8178",
    label: "National-Louis University",
    website: "http://www.nl.edu/",
  },
  {
    value: "8179",
    label: "National Technological University",
    website: "http://www.ntu.edu/",
  },
  {
    value: "8180",
    label: "National Theatre Conservatory",
    website: "http://www.denvercenter.org/education/ed_ntc.htm",
  },
  {
    value: "8181",
    label: "National University",
    website: "http://www.nu.edu/",
  },
  {
    value: "8182",
    label: "Naval Postgraduate School",
    website: "http://www.nps.navy.mil/",
  },
  {
    value: "8183",
    label: "Nazarene Bible College",
    website: "http://www.nbc.edu/",
  },
  {
    value: "8184",
    label: "Nazareth College",
    website: "http://www.naz.edu/",
  },
  {
    value: "8185",
    label: "Nebraska Christian College",
    website: "http://www.nechristian.edu/",
  },
  {
    value: "8186",
    label: "Nebraska Methodist College of Nursing and Allied Health",
    website: "http://www.methodistcollege.edu/nurseover.html",
  },
  {
    value: "8187",
    label: "Nebraska Wesleyan University",
    website: "http://www.nebrwesleyan.edu/",
  },
  {
    value: "8188",
    label: "Neumann College",
    website: "http://www.neumann.edu/",
  },
  {
    value: "8189",
    label: "Newberry College",
    website: "http://www.newberry.edu/",
  },
  {
    value: "8190",
    label: "New College of California",
    website: "http://www.newcollege.edu/",
  },
  {
    value: "8191",
    label: "New College of Florida",
    website: "http://www.ncf.edu/",
  },
  {
    value: "8192",
    label: "New England College",
    website: "http://www.nec.edu/",
  },
  {
    value: "8193",
    label: "New England College of Optometry",
    website: "http://www.ne-optometry.edu/",
  },
  {
    value: "8194",
    label: "New England Conservatory of Music",
    website: "http://www.newenglandconservatory.edu/",
  },
  {
    value: "8195",
    label: "New England Institute of Technology",
    website: "http://www.neit.edu/",
  },
  {
    value: "8196",
    label: "New England School of Art and Design",
    website: "http://www.suffolk.edu/nesad/",
  },
  {
    value: "8197",
    label: "New England School of Communications",
    website: "http://www.nescom.edu/",
  },
  {
    value: "8198",
    label: "New England School of Law",
    website: "http://www.nesl.edu/",
  },
  {
    value: "8199",
    label: "New Hampshire College",
    website: "http://www.nhc.edu/",
  },
  {
    value: "8200",
    label: "New Jersey City University",
    website: "http://www.njcu.edu/",
  },
  {
    value: "8201",
    label: "New Jersey Institute of Technology",
    website: "http://www.njit.edu/",
  },
  {
    value: "8202",
    label: "Newman University",
    website: "http://www.ksnewman.edu/",
  },
  {
    value: "8203",
    label: "New Mexico Highlands University",
    website: "http://www.nmhu.edu/",
  },
  {
    value: "8204",
    label: "New Mexico Institute of Mining and Technology",
    website: "http://www.nmt.edu/",
  },
  {
    value: "8205",
    label: "New Mexico State University",
    website: "http://www.nmsu.edu/",
  },
  {
    value: "8206",
    label: "Newschool of Architecture and Design",
    website: "http://www.newschoolarch.edu/",
  },
  {
    value: "8207",
    label: "New York Academy of Art, Graduate School of Figurative Art",
    website: "http://www.nyaa.edu/",
  },
  {
    value: "8208",
    label: "New York Chiropractic College",
    website: "http://www.nycc.edu/",
  },
  {
    value: "8209",
    label: "New York College of Podiatric Medicine",
    website: "http://www.nycpm.edu/",
  },
  {
    value: "8210",
    label: "New York Film Academy",
    website: "http://www.nyfa.edu/",
  },
  {
    value: "8211",
    label: "New York Institute of Technology",
    website: "http://www.nyit.edu/",
  },
  {
    value: "8212",
    label: "New York Law School",
    website: "http://www.nyls.edu/",
  },
  {
    value: "8213",
    label: "New York Medical College",
    website: "http://www.nymc.edu/",
  },
  {
    value: "8214",
    label: "New York School of Interior Design",
    website: "http://www.nysid.edu/",
  },
  {
    value: "8215",
    label: "New York University",
    website: "http://www.nyu.edu/",
  },
  {
    value: "8216",
    label: "Niagara University",
    website: "http://www.niagara.edu/",
  },
  {
    value: "8217",
    label: "Nicholls State University",
    website: "http://www.nicholls.edu/",
  },
  {
    value: "8218",
    label: "Nichols College",
    website: "http://www.nichols.edu/",
  },
  {
    value: "8219",
    label: "Norfolk State University",
    website: "http://www.nsu.edu/",
  },
  {
    value: "8220",
    label: "North Carolina Agricultural and Technical State University",
    website: "http://www.ncat.edu/",
  },
  {
    value: "8221",
    label: "North Carolina Central University",
    website: "http://www.nccu.edu/",
  },
  {
    value: "8222",
    label: "North Carolina School of the Arts",
    website: "http://www.ncarts.edu/",
  },
  {
    value: "8223",
    label: "North Carolina State University",
    website: "http://www.ncsu.edu/",
  },
  {
    value: "8224",
    label: "North Carolina Wesleyan College",
    website: "http://www.ncwc.edu/",
  },
  {
    value: "8225",
    label: "North Central Bible College",
    website: "http://www.ncbc.edu/",
  },
  {
    value: "8226",
    label: "North Central College",
    website: "http://www.noctrl.edu/",
  },
  {
    value: "8227",
    label: "Northcentral University",
    website: "http://www.ncu.edu/",
  },
  {
    value: "8228",
    label: "North Dakota State University",
    website: "http://www.ndsu.nodak.edu/",
  },
  {
    value: "8229",
    label: "Northeastern Illinois University",
    website: "http://www.neiu.edu/",
  },
  {
    value: "8230",
    label: "Northeastern Ohio University College of Medicine",
    website: "http://www.neoucom.edu/",
  },
  {
    value: "8231",
    label: "Northeastern State University",
    website: "http://www.nsuok.edu/",
  },
  {
    value: "8232",
    label: "Northeastern University",
    website: "http://www.neu.edu/",
  },
  {
    value: "8233",
    label: "Northern Arizona University",
    website: "http://www.nau.edu/",
  },
  {
    value: "8234",
    label: "Northern Illinois University",
    website: "http://www.niu.edu/",
  },
  {
    value: "8235",
    label: "Northern Kentucky University",
    website: "http://www.nku.edu/",
  },
  {
    value: "8236",
    label: "Northern Michigan University",
    website: "http://www.nmu.edu/",
  },
  {
    value: "8237",
    label: "Northern State University",
    website: "http://www.northern.edu/",
  },
  {
    value: "8238",
    label: "Northern Virginia Community College",
    website: "http://www.nv.cc.va.us/",
  },
  {
    value: "8239",
    label: "Northface University",
    website: "http://www.northface.edu/",
  },
  {
    value: "8240",
    label: "North Georgia College",
    website: "http://www.ngc.peachnet.edu/",
  },
  {
    value: "8241",
    label: "North Greenville College",
    website: "http://www.ngc.edu/",
  },
  {
    value: "8242",
    label: "Northland College",
    website: "http://www.northland.edu/",
  },
  {
    value: "8243",
    label: "North Park University",
    website: "http://www.northpark.edu/",
  },
  {
    value: "8244",
    label: "Northwest Christian College",
    website: "http://www.nwcc.edu/",
  },
  {
    value: "8245",
    label: "Northwest College of Art",
    website: "http://www.nca.edu/",
  },
  {
    value: "8246",
    label: "Northwestern College Iowa",
    website: "http://www.nwciowa.edu/",
  },
  {
    value: "8247",
    label: "Northwestern College of Chiropractic",
    website: "http://www.nwchiro.edu/",
  },
  {
    value: "8248",
    label: "Northwestern College St. Paul",
    website: "http://www.nwc.edu/",
  },
  {
    value: "8249",
    label: "Northwestern Oklahoma State University",
    website: "http://www.nwalva.edu/",
  },
  {
    value: "8250",
    label: "Northwestern State University of Louisiana",
    website: "http://www.nsula.edu/",
  },
  {
    value: "8251",
    label: "Northwestern University",
    website: "http://www.nwu.edu/",
  },
  {
    value: "8252",
    label: "Northwest Missouri State University",
    website: "http://www.nwmissouri.edu/",
  },
  {
    value: "8253",
    label: "Northwest Nazarene University",
    website: "http://www.nnu.edu/",
  },
  {
    value: "8254",
    label: "Northwest University",
    website: "http://www.northwestu.edu/",
  },
  {
    value: "8255",
    label: "Northwood University",
    website: "http://www.northwood.edu/",
  },
  {
    value: "8256",
    label: "Northwood University, Florida Campus",
    website: "http://www.northwood.edu/campuses/florida/",
  },
  {
    value: "8257",
    label: "Northwood University, Texas Campus",
    website: "http://www.northwood.edu/campuses/texas/",
  },
  {
    value: "8258",
    label: "Norwich University",
    website: "http://www.norwich.edu/",
  },
  {
    value: "8259",
    label: "Notre Dame College",
    website: "http://www.notredame.edu/",
  },
  {
    value: "8260",
    label: "Notre Dame College of Ohio",
    website: "http://www.ndc.edu/",
  },
  {
    value: "8261",
    label: "Notre Dame de Namur University",
    website: "http://www.ndnu.edu/",
  },
  {
    value: "8262",
    label: "Nova Southeastern University",
    website: "http://www.nova.edu/",
  },
  {
    value: "8263",
    label: "Nyack College",
    website: "http://www.nyackcollege.edu/",
  },
  {
    value: "8264",
    label: "Oakland City University",
    website: "http://www.oak.edu/",
  },
  {
    value: "8265",
    label: "Oakland University",
    website: "http://www.oakland.edu/",
  },
  {
    value: "8266",
    label: "Oakton Community College",
    website: "http://www.oakton.edu/",
  },
  {
    value: "8267",
    label: "Oakwood College",
    website: "http://www.oakwood.edu/",
  },
  {
    value: "8268",
    label: "Oberlin College",
    website: "http://www.oberlin.edu/",
  },
  {
    value: "8269",
    label: "Occidental College",
    website: "http://www.oxy.edu/",
  },
  {
    value: "8270",
    label: "Oglala Lakota College",
    website: "http://www.olc.edu/",
  },
  {
    value: "8271",
    label: "Oglethorpe University",
    website: "http://www.oglethorpe.edu/",
  },
  {
    value: "8272",
    label: "Ohio College of Podiatric Medicine",
    website: "http://www.ocpm.edu/",
  },
  {
    value: "8273",
    label: "Ohio Dominican College",
    website: "http://www.odc.edu/",
  },
  {
    value: "8274",
    label: "Ohio Northern University",
    website: "http://www.onu.edu/",
  },
  {
    value: "8275",
    label: "Ohio State University",
    website: "http://www.ohio-state.edu/",
  },
  {
    value: "8276",
    label: "Ohio State University - Lima",
    website: "http://www.lima.ohio-state.edu/",
  },
  {
    value: "8277",
    label: "Ohio State University - Mansfield",
    website: "http://www.mansfield.ohio-state.edu/",
  },
  {
    value: "8278",
    label: "Ohio State University - Marion",
    website: "http://www.marion.ohio-state.edu/",
  },
  {
    value: "8279",
    label: "Ohio State University - Newark",
    website: "http://www.newark.ohio-state.edu/",
  },
  {
    value: "8280",
    label: "Ohio University",
    website: "http://www.ohiou.edu/",
  },
  {
    value: "8281",
    label: "Ohio University - Chillicothe",
    website: "http://www.ohiou.edu/chillicothe/",
  },
  {
    value: "8282",
    label: "Ohio University - Eastern",
    website: "http://www.eastern.ohiou.edu/",
  },
  {
    value: "8283",
    label: "Ohio University - Lancaster",
    website: "http://www.lancaster.ohiou.edu/",
  },
  {
    value: "8284",
    label: "Ohio University - Southern",
    website: "http://www.ohiou.edu/southern/",
  },
  {
    value: "8285",
    label: "Ohio University - Zanesville",
    website: "http://www.zanesville.ohiou.edu/",
  },
  {
    value: "8286",
    label: "Ohio Valley College",
    website: "http://www.ovc.edu/",
  },
  {
    value: "8287",
    label: "Ohio Wesleyan University",
    website: "http://www.owu.edu/",
  },
  {
    value: "8288",
    label: "Oklahoma Baptist University",
    website: "http://www.okbu.edu/",
  },
  {
    value: "8289",
    label: "Oklahoma Christian University",
    website: "http://www.oc.edu/",
  },
  {
    value: "8290",
    label: "Oklahoma City University",
    website: "http://www.okcu.edu/",
  },
  {
    value: "8291",
    label: "Oklahoma Panhandle State University",
    website: "http://www.opsu.edu/",
  },
  {
    value: "8292",
    label: "Oklahoma State University",
    website: "http://www.okstate.edu/",
  },
  {
    value: "8293",
    label: "Oklahoma State University Center for Health Sciences",
    website: "http://osu.com.okstate.edu/",
  },
  {
    value: "8294",
    label: "Oklahoma State University - Institute of Technology",
    website: "http://www.osuit.edu/",
  },
  {
    value: "8295",
    label: "Oklahoma State University - Oklahoma City",
    website: "http://www.osuokc.edu/",
  },
  {
    value: "8296",
    label: "Oklahoma State University - Tulsa",
    website: "http://www.osu-tulsa.okstate.edu/",
  },
  {
    value: "8297",
    label: "Old Dominion University",
    website: "http://www.odu.edu/",
  },
  {
    value: "8298",
    label: "Olive-Harvey College",
    website: "http://www.ccc.edu/oliveharvey/",
  },
  {
    value: "8299",
    label: "Olivet College",
    website: "http://www.olivetcollege.edu/",
  },
  {
    value: "8300",
    label: "Olivet Nazarene University",
    website: "http://www.olivet.edu/",
  },
  {
    value: "8301",
    label: "O'More College of Design",
    website: "http://www.omorecollege.edu/",
  },
  {
    value: "8302",
    label: "Oral Roberts University",
    website: "http://www.oru.edu/",
  },
  {
    value: "8303",
    label: "Oregon College of Arts and Crafts",
    website: "http://www.ocac.edu/",
  },
  {
    value: "8304",
    label: "Oregon Graduate Institute of Science and Technology",
    website: "http://www.ogi.edu/",
  },
  {
    value: "8305",
    label: "Oregon Health Sciences University",
    website: "http://www.ohsu.edu/",
  },
  {
    value: "8306",
    label: "Oregon Institute of Technology",
    website: "http://www.oit.edu/",
  },
  {
    value: "8307",
    label: "Oregon State University",
    website: "http://www.orst.edu/",
  },
  {
    value: "8308",
    label: "Otis College of Art & Design",
    website: "http://www.otisart.edu/",
  },
  {
    value: "8309",
    label: "Ottawa University",
    website: "http://www.ottawa.edu/",
  },
  {
    value: "8310",
    label: "Otterbein College",
    website: "http://www.otterbein.edu/",
  },
  {
    value: "8311",
    label: "Ouachita Baptist University",
    website: "http://www.obu.edu/",
  },
  {
    value: "8312",
    label: "Our Lady of Holy Cross College",
    website: "http://www.olhcc.edu/",
  },
  {
    value: "8313",
    label: "Our Lady of the Lake University",
    website: "http://www.ollusa.edu/",
  },
  {
    value: "8314",
    label: "Ozark Christian College",
    website: "http://www.occ.edu/",
  },
  {
    value: "8315",
    label: "Pace University",
    website: "http://www.pace.edu/",
  },
  {
    value: "8316",
    label: "Pace University  Pleasantville/Briarcliff",
    website: "http://www.pace.edu/campus/pville.html",
  },
  {
    value: "8317",
    label: "Pacifica Graduate Institute",
    website: "http://www.pacifica.edu/",
  },
  {
    value: "8318",
    label: "Pacific College of Oriental Medicine",
    website: "http://www.ormed.edu/",
  },
  {
    value: "8319",
    label: "Pacific Graduate School of Psychology",
    website: "http://www.pgsp.edu/",
  },
  {
    value: "8320",
    label: "Pacific Lutheran University",
    website: "http://www.plu.edu/",
  },
  {
    value: "8321",
    label: "Pacific Northwest College of Art",
    website: "http://www.pnca.edu/",
  },
  {
    value: "8322",
    label: "Pacific Oaks College",
    website: "http://www.pacificoaks.edu/",
  },
  {
    value: "8323",
    label: "Pacific Union College",
    website: "http://www.puc.edu/",
  },
  {
    value: "8324",
    label: "Pacific University",
    website: "http://www.pacificu.edu/",
  },
  {
    value: "8325",
    label: "Paier College of Art",
    website: "http://www.paierart.com/",
  },
  {
    value: "8326",
    label: "Paine College",
    website: "http://www.paine.edu/",
  },
  {
    value: "8327",
    label: "Palm Beach Atlantic University",
    website: "http://www.pba.edu/",
  },
  {
    value: "8328",
    label: "Palm Beach State College",
    website: "http://www.palmbeachstate.edu/",
  },
  {
    value: "8329",
    label: "Palmer College of Chiropractic",
    website: "http://www.palmer.edu/",
  },
  {
    value: "8330",
    label: "Palmer College of Chiropractic West",
    website: "http://www.palmer.edu/PCCW/pcwmain.htm",
  },
  {
    value: "8331",
    label: "Park College",
    website: "http://www.park.edu/",
  },
  {
    value: "8332",
    label: "Parsons School of Design",
    website: "http://www.parsons.edu/",
  },
  {
    value: "8333",
    label: "Paul Quinn College",
    website: "http://www.pqc.edu/",
  },
  {
    value: "8334",
    label: "Peace College",
    website: "http://www.peace.edu/",
  },
  {
    value: "8335",
    label: "Pebble Hills University",
    website: "http://www.pebblehills.edu/",
  },
  {
    value: "8336",
    label: "Pennsylvania Academy of the Fine Arts",
    website: "http://www.pafa.org/",
  },
  {
    value: "8337",
    label: "Pennsylvania College of Optometry",
    website: "http://www.pco.edu/",
  },
  {
    value: "8338",
    label: "Pennsylvania Institute of Technology",
    website: "http://www.pit.edu/",
  },
  {
    value: "8339",
    label: "Pennsylvania State University",
    website: "http://www.psu.edu/",
  },
  {
    value: "8340",
    label: "Pennsylvania State University - Abington",
    website: "http://www.abington.psu.edu/",
  },
  {
    value: "8341",
    label: "Pennsylvania State University - Altoona",
    website: "http://www.aa.psu.edu/",
  },
  {
    value: "8342",
    label: "Pennsylvania State University at Erie - Behrend College",
    website: "http://www.pserie.psu.edu/",
  },
  {
    value: "8343",
    label: "Pennsylvania State University at Harrisburg - The Capital College",
    website: "http://www.hbg.psu.edu/",
  },
  {
    value: "8344",
    label: "Pennsylvania State University - Berks-Lehigh Valley College",
    website: "http://www.bk.psu.edu/",
  },
  {
    value: "8345",
    label: "Pennsylvania State University Delaware County",
    website: "http://www.de.psu.edu/",
  },
  {
    value: "8346",
    label: "Pennsylvania State University Great Valley",
    website: "http://www.gv.psu.edu/",
  },
  {
    value: "8347",
    label: "Pennsylvania State University - Lehigh Valley",
    website: "http://www.an.psu.edu/",
  },
  {
    value: "8348",
    label: "Pennsylvania State University - Milton S.Hershey Medical Center",
    website: "http://www.collmed.psu.edu/",
  },
  {
    value: "8349",
    label: "Pennsylvania State University - Schuylkill",
    website: "http://www.sl.psu.edu/",
  },
  {
    value: "8350",
    label: "Pepperdine University",
    website: "http://www.pepperdine.edu/",
  },
  {
    value: "8351",
    label: "Peru State College",
    website: "http://www.peru.edu/",
  },
  {
    value: "8352",
    label: "Pfeiffer University",
    website: "http://www.pfeiffer.edu/",
  },
  {
    value: "8353",
    label: "Philadelphia College of Bible",
    website: "http://www.pcb.edu/",
  },
  {
    value: "8354",
    label: "Philadelphia College of Osteopathic Medicine",
    website: "http://www.pcom.edu/",
  },
  {
    value: "8355",
    label: "Philadelphia University",
    website: "http://www.philau.edu/",
  },
  {
    value: "8356",
    label: "Philander Smith College",
    website: "http://www.philander.edu/",
  },
  {
    value: "8357",
    label: "Phillips Graduate Institute",
    website: "http://www.pgi.edu/",
  },
  {
    value: "8358",
    label: "Phillips University",
    website: "http://www.phillips.edu/",
  },
  {
    value: "8359",
    label: "Piedmont Baptist College",
    website: "http://www.pbc.edu/",
  },
  {
    value: "8360",
    label: "Piedmont College",
    website: "http://www.piedmont.edu/",
  },
  {
    value: "8361",
    label: "Pikeville College",
    website: "http://www.pc.edu/",
  },
  {
    value: "8362",
    label: "Pillsbury Baptist Bible College",
    website: "http://www.pillsbury.edu/",
  },
  {
    value: "8363",
    label: "Pittsburg State University",
    website: "http://www.pittstate.edu/",
  },
  {
    value: "8364",
    label: "Pitzer College",
    website: "http://www.pitzer.edu/",
  },
  {
    value: "8365",
    label: "Plymouth State College",
    website: "http://www.plymouth.edu/",
  },
  {
    value: "8366",
    label: "Point Loma Nazarene College",
    website: "http://www.ptloma.edu/",
  },
  {
    value: "8367",
    label: "Point Park College",
    website: "http://www.ppc.edu/",
  },
  {
    value: "8368",
    label: "Polytechnic University",
    website: "http://www.poly.edu/",
  },
  {
    value: "8369",
    label: "Polytechnic University, Long Island Campus",
    website: "http://www.poly.edu/li/",
  },
  {
    value: "8370",
    label: "Polytechnic University, Westchester Graduate Center",
    website: "http://www.poly.edu/west/",
  },
  {
    value: "8371",
    label: "Pomona College",
    website: "http://www.pomona.edu/",
  },
  {
    value: "8372",
    label: "Portland Community College",
    website: "http://www.pcc.edu/",
  },
  {
    value: "8373",
    label: "Portland State University",
    website: "http://www.pdx.edu/",
  },
  {
    value: "8374",
    label: "Post University of Waterbury",
    website: "http://www.post.edu/",
  },
  {
    value: "8375",
    label: "Prairie View Agricultural and Mechanical University",
    website: "http://www.pvamu.edu/",
  },
  {
    value: "8376",
    label: "Pratt Institute",
    website: "http://www.pratt.edu/",
  },
  {
    value: "8377",
    label: "Presbyterian College",
    website: "http://www.presby.edu/",
  },
  {
    value: "8378",
    label: "Prescott College",
    website: "http://www.prescott.edu/",
  },
  {
    value: "8379",
    label: "Preston University",
    website: "http://www.preston.edu/",
  },
  {
    value: "8380",
    label: "Princeton University",
    website: "http://www.princeton.edu/",
  },
  {
    value: "8381",
    label: "Principia College",
    website: "http://www.prin.edu/",
  },
  {
    value: "8382",
    label: "Providence College",
    website: "http://www.providence.edu/",
  },
  {
    value: "8383",
    label: "Puget Sound Christian College",
    website: "http://members.aa.net/~bluvase/pscchome.html",
  },
  {
    value: "8384",
    label: "Purdue University",
    website: "http://www.purdue.edu/",
  },
  {
    value: "8385",
    label: "Purdue University Calumet",
    website: "http://www.calumet.purdue.edu/",
  },
  {
    value: "8386",
    label: "Purdue University North Central",
    website: "http://www.pnc.edu/",
  },
  {
    value: "8387",
    label: "Quantum-Veritas International University",
    website: "http://www.qvius.edu/",
  },
  {
    value: "8388",
    label: "Queens College",
    website: "http://www.queens.edu/",
  },
  {
    value: "8389",
    label: "Quincy University",
    website: "http://www.quincy.edu/",
  },
  {
    value: "8390",
    label: "Quinnipiac College",
    website: "http://www.quinnipiac.edu/",
  },
  {
    value: "8391",
    label: "Radford University",
    website: "http://www.runet.edu/",
  },
  {
    value: "8392",
    label: "Ramapo College of New Jersey",
    website: "http://www.ramapo.edu/",
  },
  {
    value: "8393",
    label: "Rand Graduate School of Policy Studies",
    website: "http://www.rgs.edu/",
  },
  {
    value: "8394",
    label: "Randolph-Macon College",
    website: "http://www.rmc.edu/",
  },
  {
    value: "8395",
    label: "Randolph-Macon Woman's College",
    website: "http://www.rmwc.edu/",
  },
  {
    value: "8396",
    label: "Rasmussen College",
    website: "http://www.rasmussen.edu/",
  },
  {
    value: "8397",
    label: "Rasmussen College, Florida Campuses",
    website: "http://www.rasmussen.edu/locations/florida/",
  },
  {
    value: "8398",
    label: "Rasmussen College, Illinois Campuses",
    website: "http://www.rasmussen.edu/locations/illinois/",
  },
  {
    value: "8399",
    label: "Rasmussen College, Minnesota Campuses",
    website: "http://www.rasmussen.edu/locations/minnesota/",
  },
  {
    value: "8400",
    label: "Rasmussen College, North Dakota Campuses",
    website: "http://www.rasmussen.edu/locations/north-dakota/",
  },
  {
    value: "8401",
    label: "Rasmussen College, Wisconsin Campuses",
    website: "http://www.rasmussen.edu/locations/wisconsin/",
  },
  {
    value: "8402",
    label: "Reed College",
    website: "http://www.reed.edu/",
  },
  {
    value: "8403",
    label: "Reformed Bible College",
    website: "http://www.reformed.edu/",
  },
  {
    value: "8404",
    label: "Regent International University",
    website: "http://www.regentinternational.net/",
  },
  {
    value: "8405",
    label: "Regent University",
    website: "http://www.regent.edu/",
  },
  {
    value: "8406",
    label: "Regis College",
    website: "http://www.regiscollege.edu/",
  },
  {
    value: "8407",
    label: "Regis University",
    website: "http://www.regis.edu/",
  },
  {
    value: "8408",
    label: "Reinhardt College",
    website: "http://www.reinhardt.edu/",
  },
  {
    value: "8409",
    label: "Rensselaer Polytechnic Institute",
    website: "http://www.rpi.edu/",
  },
  {
    value: "8410",
    label: "Research College of Nursing - Rockhurst University",
    website:
      "http://www.rockhurst.edu/3.0/academic_programs/nursing/admis3.html",
  },
  {
    value: "8411",
    label: "Rhode Island College",
    website: "http://www.ric.edu/",
  },
  {
    value: "8412",
    label: "Rhode Island School of Design",
    website: "http://www.risd.edu/",
  },
  {
    value: "8413",
    label: "Rhodes College",
    website: "http://www.rhodes.edu/",
  },
  {
    value: "8414",
    label: "Rice University",
    website: "http://www.rice.edu/",
  },
  {
    value: "8415",
    label: "Richard Stockton College of New Jersey",
    website: "http://www.stockton.edu/",
  },
  {
    value: "8416",
    label: "Rider University",
    website: "http://www.rider.edu/",
  },
  {
    value: "8417",
    label: "Ringling College of Art and Design",
    website: "http://www.ringling.edu/",
  },
  {
    value: "8418",
    label: "Ripon College",
    website: "http://www.ripon.edu/",
  },
  {
    value: "8419",
    label: "Rivier College",
    website: "http://www.rivier.edu/",
  },
  {
    value: "8420",
    label: "Roanoke Bible College",
    website: "http://www.roanokebible.edu/",
  },
  {
    value: "8421",
    label: "Roanoke College",
    website: "http://www.roanoke.edu/",
  },
  {
    value: "8422",
    label: "Robert Morris College",
    website: "http://www.robert-morris.edu/",
  },
  {
    value: "8423",
    label: "Robert Morris College of Chicago",
    website: "http://www.rmcil.edu/",
  },
  {
    value: "8424",
    label: "Roberts Wesleyan College",
    website: "http://www.rwc.edu/",
  },
  {
    value: "8425",
    label: "Rochester College",
    website: "http://www.rc.edu/",
  },
  {
    value: "8426",
    label: "Rochester Institute of Technology",
    website: "http://www.rit.edu/",
  },
  {
    value: "8427",
    label: "Rockford College",
    website: "http://www.rockford.edu/",
  },
  {
    value: "8428",
    label: "Rockhurst College",
    website: "http://www.rockhurst.edu/",
  },
  {
    value: "8429",
    label: "Rock Valley College",
    website: "http://www.rvc.cc.il.us/",
  },
  {
    value: "8430",
    label: "Rocky Mountain College",
    website: "http://www.rocky.edu/",
  },
  {
    value: "8431",
    label: "Rocky Mountain College of Art and Design",
    website: "http://www.rmcad.edu/",
  },
  {
    value: "8432",
    label: "Rogers State University",
    website: "http://www.rsu.edu/",
  },
  {
    value: "8433",
    label: "Roger Williams University",
    website: "http://www.rwu.edu/",
  },
  {
    value: "8434",
    label: "Rollins College",
    website: "http://www.rollins.edu/",
  },
  {
    value: "8435",
    label: "Roosevelt University",
    website: "http://www.roosevelt.edu/",
  },
  {
    value: "8436",
    label: "Rose-Hulman Institute of Technology",
    website: "http://www.rose-hulman.edu/",
  },
  {
    value: "8437",
    label: "Rosemont College",
    website: "http://www.rosemont.edu/",
  },
  {
    value: "8438",
    label: "Ross University, School of Medicine",
    website: "http://www.rossmed.edu/",
  },
  {
    value: "8439",
    label: "Rowan University",
    website: "http://www.rowan.edu/",
  },
  {
    value: "8440",
    label: "Rush University",
    website: "http://www.rushu.rush.edu/",
  },
  {
    value: "8441",
    label: "Russel Sage College",
    website: "http://www.sage.edu/RSC/",
  },
  {
    value: "8442",
    label: "Rust College",
    website: "http://www.rustcollege.edu/",
  },
  {
    value: "8443",
    label: "Rutgers, The State University of New Jersey",
    website: "http://www.rutgers.edu/",
  },
  {
    value: "8444",
    label: "Rutgers, The State University of New Jersey - Camden",
    website: "http://camden-www.rutgers.edu/",
  },
  {
    value: "8445",
    label: "Rutgers, The State University of New Jersey - Newark",
    website: "http://rutgers-newark.rutgers.edu/",
  },
  {
    value: "8446",
    label: "Sacred Heart University",
    website: "http://www.sacredheart.edu/",
  },
  {
    value: "8447",
    label: "Sage Graduate School",
    website: "http://www.sage.edu/SGS/",
  },
  {
    value: "8448",
    label: "Saginaw Valley State University",
    website: "http://www.svsu.edu/",
  },
  {
    value: "8449",
    label: "Salem College",
    website: "http://www.salem.edu/",
  },
  {
    value: "8450",
    label: "Salem International University",
    website: "http://www.salemiu.edu/",
  },
  {
    value: "8451",
    label: "Salem State College",
    website: "http://www.salem.mass.edu/",
  },
  {
    value: "8452",
    label: "Salem Teikyo University",
    website: "http://www.salem-teikyo.wvnet.edu/",
  },
  {
    value: "8453",
    label: "Salisbury State University",
    website: "http://www.ssu.edu/",
  },
  {
    value: "8454",
    label: "Salve Regina University",
    website: "http://www.salve.edu/",
  },
  {
    value: "8455",
    label: "Samford University",
    website: "http://www.samford.edu/",
  },
  {
    value: "8456",
    label: "Sam Houston State University",
    website: "http://www.shsu.edu/",
  },
  {
    value: "8457",
    label: "Samuel Merritt College",
    website: "http://www.samuelmerritt.edu/",
  },
  {
    value: "8458",
    label: "San Diego State University",
    website: "http://www.sdsu.edu/",
  },
  {
    value: "8459",
    label: "San Diego University for Integrative Studies",
    website: "http://www.sduis.edu/",
  },
  {
    value: "8460",
    label: "Sanford-Brown Institute",
    website: "http://www.sbjacksonville.com/",
  },
  {
    value: "8461",
    label: "San Francisco Art Institute",
    website: "http://www.sfai.edu/",
  },
  {
    value: "8462",
    label: "San Francisco Conservatory of Music",
    website: "http://www.sfcm.edu/",
  },
  {
    value: "8463",
    label: "San Francisco State University",
    website: "http://www.sfsu.edu/",
  },
  {
    value: "8464",
    label: "San Joaquin College of Law",
    website: "http://www.sjcl.org/",
  },
  {
    value: "8465",
    label: "San Jose Christian College",
    website: "http://www.sjchristiancol.edu/",
  },
  {
    value: "8466",
    label: "San Jose State University",
    website: "http://www.sjsu.edu/",
  },
  {
    value: "8467",
    label: "Santa Clara University",
    website: "http://www.scu.edu/",
  },
  {
    value: "8468",
    label: "Sarah Lawrence College",
    website: "http://www.slc.edu/",
  },
  {
    value: "8469",
    label: "Savannah College of Art and Design",
    website: "http://www.scad.edu/",
  },
  {
    value: "8470",
    label: "Savannah State University",
    website: "http://www.savstate.edu/",
  },
  {
    value: "8471",
    label: "Saybrook Institute",
    website: "http://www.saybrook.org/",
  },
  {
    value: "8472",
    label: "Schiller International University",
    website: "http://www.schiller.edu/",
  },
  {
    value: "8473",
    label: "Scholl College of Podiatric Medicine",
    website: "http://www.scholl.edu/",
  },
  {
    value: "8474",
    label: "School for International Training",
    website: "http://www.sit.edu/",
  },
  {
    value: "8475",
    label: "School of the Museum of Fine Arts",
    website: "http://www.smfa.edu/",
  },
  {
    value: "8476",
    label: "School of the Visual Arts",
    website: "http://www.schoolofvisualarts.edu/",
  },
  {
    value: "8477",
    label: "Schreiner College",
    website: "http://www.schreiner.edu/",
  },
  {
    value: "8478",
    label: "Scripps College",
    website: "http://www.scrippscol.edu/",
  },
  {
    value: "8479",
    label: "Seattle Pacific University",
    website: "http://www.spu.edu/",
  },
  {
    value: "8480",
    label: "Seattle University",
    website: "http://www.seattleu.edu/",
  },
  {
    value: "8481",
    label: "Seton Hall University",
    website: "http://www.shu.edu/",
  },
  {
    value: "8482",
    label: "Seton Hill College",
    website: "http://www.setonhill.edu/",
  },
  {
    value: "8483",
    label: "Shawnee Community College",
    website: "http://www.shawnee.cc.il.us/",
  },
  {
    value: "8484",
    label: "Shawnee State University",
    website: "http://www.shawnee.edu/",
  },
  {
    value: "8485",
    label: "Shaw University",
    website: "http://www.shawuniversity.edu/",
  },
  {
    value: "8486",
    label: "Sheldon Jackson College",
    website: "http://www.sheldonjackson.edu/",
  },
  {
    value: "8487",
    label: "Shenandoah University",
    website: "http://www.su.edu/",
  },
  {
    value: "8488",
    label: "Shepherd College",
    website: "http://www.shepherd.edu/",
  },
  {
    value: "8489",
    label: "Sherman College of Straight Chiropractic",
    website: "http://www.sherman.edu/",
  },
  {
    value: "8490",
    label: "Shimer College",
    website: "http://www.shimer.edu/",
  },
  {
    value: "8491",
    label: "Shippensburg University of Pennsylvania",
    website: "http://www.ship.edu/",
  },
  {
    value: "8492",
    label: "Shoreline Community College",
    website: "http://www.shoreline.edu/",
  },
  {
    value: "8493",
    label: "Shorter College",
    website: "http://www.shorter.edu/",
  },
  {
    value: "8494",
    label: "Siena College",
    website: "http://www.siena.edu/",
  },
  {
    value: "8495",
    label: "Siena Heights University",
    website: "http://www.sienahts.edu/",
  },
  {
    value: "8496",
    label: "Sierra Nevada College",
    website: "http://www.sierranevada.edu/",
  },
  {
    value: "8497",
    label: "Silver Lake College",
    website: "http://www.sl.edu/",
  },
  {
    value: "8498",
    label: "Simmons College",
    website: "http://www.simmons.edu/",
  },
  {
    value: "8499",
    label: "Simon's Rock College",
    website: "http://www.simons-rock.edu/",
  },
  {
    value: "8500",
    label: "Simpson College",
    website: "http://www.simpsonca.edu/",
  },
  {
    value: "8501",
    label: "Simpson College Iowa",
    website: "http://www.simpson.edu/",
  },
  {
    value: "8502",
    label: "Sinte Gleska University",
    website: "http://sinte.indian.com/",
  },
  {
    value: "8503",
    label: "Skadron College",
    website: "http://skadron.com/",
  },
  {
    value: "8504",
    label: "Skidmore College",
    website: "http://www.skidmore.edu/",
  },
  {
    value: "8505",
    label: "Slippery Rock University",
    website: "http://www.sru.edu/",
  },
  {
    value: "8506",
    label: "Smith College",
    website: "http://www.smith.edu/",
  },
  {
    value: "8507",
    label: "Sojourner-Douglass College",
    website: "http://www.sdc.edu/",
  },
  {
    value: "8508",
    label: "Soka University of America",
    website: "http://www.soka.edu",
  },
  {
    value: "8509",
    label: "Sonoma State University",
    website: "http://www.sonoma.edu/",
  },
  {
    value: "8510",
    label: "South Carolina State University",
    website: "http://www.scsu.edu/",
  },
  {
    value: "8511",
    label: "South Dakota School of Mines and Technology",
    website: "http://www.sdsmt.edu/",
  },
  {
    value: "8512",
    label: "South Dakota State University",
    website: "http://www.sdstate.edu/",
  },
  {
    value: "8513",
    label: "Southeastern Baptist College",
    website: "http://www.southeasternbaptist.edu/",
  },
  {
    value: "8514",
    label: "Southeastern Bible College",
    website: "http://www.sebc.edu/",
  },
  {
    value: "8515",
    label: "Southeastern College of the Assemblies of God",
    website: "http://www.secollege.edu/",
  },
  {
    value: "8516",
    label: "Southeastern Louisiana University",
    website: "http://www.southeastern.edu/",
  },
  {
    value: "8517",
    label: "Southeastern Oklahoma State University",
    website: "http://www.sosu.edu/",
  },
  {
    value: "8518",
    label: "Southeastern University",
    website: "http://www.seu.edu/",
  },
  {
    value: "8519",
    label: "Southeast Missouri State University",
    website: "http://www.semo.edu/",
  },
  {
    value: "8520",
    label: "Southern Adventist University",
    website: "http://www.southern.edu/",
  },
  {
    value: "8521",
    label: "Southern Arkansas University",
    website: "http://www.saumag.edu/",
  },
  {
    value: "8522",
    label: "Southern California College",
    website: "http://www.sccu.edu/",
  },
  {
    value: "8523",
    label: "Southern California College of Optometry",
    website: "http://www.scco.edu/",
  },
  {
    value: "8524",
    label: "Southern California Institute of Architecture",
    website: "http://www.sciarc.edu/",
  },
  {
    value: "8525",
    label: "Southern College of Optometry",
    website: "http://www.sco.edu/",
  },
  {
    value: "8526",
    label: "Southern Connecticut State University",
    website: "http://www.scsu.ctstateu.edu/",
  },
  {
    value: "8527",
    label: "Southern Illinois University at Carbondale",
    website: "http://www.siu.edu/",
  },
  {
    value: "8528",
    label: "Southern Illinois University at Edwardsville",
    website: "http://www.siue.edu/",
  },
  {
    value: "8529",
    label: "Southern Methodist University",
    website: "http://www.smu.edu/",
  },
  {
    value: "8530",
    label: "Southern Nazarene University",
    website: "http://www.snu.edu/",
  },
  {
    value: "8531",
    label: "Southern New Hampshire University",
    website: "http://www.snhu.edu/",
  },
  {
    value: "8532",
    label: "Southern Oregon University",
    website: "http://www.sou.edu/",
  },
  {
    value: "8533",
    label: "Southern Polytechnic State Univerisity",
    website: "http://www.spsu.edu/",
  },
  {
    value: "8534",
    label: "Southern University - Baton Rouge",
    website: "http://www.subr.edu/",
  },
  {
    value: "8535",
    label: "Southern University - New Orleans",
    website: "http://www.suno.edu/",
  },
  {
    value: "8536",
    label: "Southern University - Shreveport",
    website: "http://www.susla.edu/",
  },
  {
    value: "8537",
    label: "Southern Utah University",
    website: "http://www.suu.edu/",
  },
  {
    value: "8538",
    label: "Southern Vermont College",
    website: "http://www.svc.edu/",
  },
  {
    value: "8539",
    label: "Southern Wesleyan University",
    website: "http://www.swu.edu/",
  },
  {
    value: "8540",
    label: "South Florida Bible College & Theological Seminary",
    website: "http://www.sfbc.edu/",
  },
  {
    value: "8541",
    label: "South Texas College of Law",
    website: "http://www.stcl.edu/",
  },
  {
    value: "8542",
    label: "Southwest Baptist University",
    website: "http://www.sbuniv.edu/",
  },
  {
    value: "8543",
    label: "Southwestern Adventist University",
    website: "http://www.swau.edu/",
  },
  {
    value: "8544",
    label: "Southwestern Assemblies of God University",
    website: "http://www.sagu.edu/",
  },
  {
    value: "8545",
    label: "Southwestern Baptist Theological Seminary",
    website: "http://www.swbts.edu/",
  },
  {
    value: "8546",
    label: "Southwestern Christian College",
    website: "http://www.soulsociety.com/swcc.html",
  },
  {
    value: "8547",
    label: "Southwestern Christian University",
    website: "http://www.swcu.edu/",
  },
  {
    value: "8548",
    label: "Southwestern College Kansas",
    website: "http://www.sckans.edu/",
  },
  {
    value: "8549",
    label: "Southwestern College Santa Fe",
    website: "http://www.swc.edu/",
  },
  {
    value: "8550",
    label: "Southwestern Oklahoma State University",
    website: "http://www.swosu.edu/",
  },
  {
    value: "8551",
    label: "Southwestern University",
    website: "http://www.southwestern.edu/",
  },
  {
    value: "8552",
    label: "Southwestern University School of Law",
    website: "http://www.swlaw.edu/",
  },
  {
    value: "8553",
    label: "Southwest Missouri State University",
    website: "http://www.smsu.edu/",
  },
  {
    value: "8554",
    label: "Southwest Missouri State University - West Plains",
    website: "http://www.wp.smsu.edu/",
  },
  {
    value: "8555",
    label: "Southwest State University",
    website: "http://www.southwest.msus.edu/",
  },
  {
    value: "8556",
    label: "Southwest Texas State University",
    website: "http://www.swt.edu/",
  },
  {
    value: "8557",
    label: "Southwest University",
    website: "http://www.southwest.edu/",
  },
  {
    value: "8558",
    label: "Spalding University",
    website: "http://www.spalding.edu/",
  },
  {
    value: "8559",
    label: "Spelman College",
    website: "http://www.spelman.edu/",
  },
  {
    value: "8560",
    label: "Spertus Institute of Jewish Studies",
    website: "http://www.spertus.edu/",
  },
  {
    value: "8561",
    label: "Spring Arbor College",
    website: "http://www.arbor.edu/",
  },
  {
    value: "8562",
    label: "Springfield College",
    website: "http://www.spfldcol.edu/",
  },
  {
    value: "8563",
    label: "Spring Hill College",
    website: "http://www.shc.edu/",
  },
  {
    value: "8564",
    label: "St. Ambrose University",
    website: "http://www.sau.edu/",
  },
  {
    value: "8565",
    label: "Standford Online University",
    website: "http://www.standford-university.cjb.net/",
  },
  {
    value: "8566",
    label: "Standford University",
    website: "http://standford-university.edu.tf/",
  },
  {
    value: "8567",
    label: "St. Andrews Presbyterian College",
    website: "http://www.sapc.edu/",
  },
  {
    value: "8568",
    label: "Stanford University",
    website: "http://www.stanford.edu/",
  },
  {
    value: "8569",
    label: "St. Anselm College",
    website: "http://www.anselm.edu/",
  },
  {
    value: "8570",
    label: "St. Anthony College of Nursing",
    website: "http://www.sacn.edu/",
  },
  {
    value: "8571",
    label: "State University of New York at Albany",
    website: "http://www.albany.edu/",
  },
  {
    value: "8572",
    label: "State University of New York at Binghamton",
    website: "http://www.binghamton.edu/",
  },
  {
    value: "8573",
    label: "State University of New York at Buffalo",
    website: "http://www.buffalo.edu/",
  },
  {
    value: "8574",
    label: "State University of New York at New Paltz",
    website: "http://www.newpaltz.edu/",
  },
  {
    value: "8575",
    label: "State University of New York at Oswego",
    website: "http://www.oswego.edu/",
  },
  {
    value: "8576",
    label: "State University of New York at Stony Brook",
    website: "http://www.sunysb.edu/",
  },
  {
    value: "8577",
    label: "State University of New York College at Brockport",
    website: "http://www.brockport.edu/",
  },
  {
    value: "8578",
    label: "State University of New York College at Cortland",
    website: "http://www.cortland.edu/",
  },
  {
    value: "8579",
    label: "State University of New York College at Fredonia",
    website: "http://www.fredonia.edu/",
  },
  {
    value: "8580",
    label: "State University of New York College at Geneseo",
    website: "http://www.geneseo.edu/",
  },
  {
    value: "8581",
    label: "State University of New York College at Old Westbury",
    website: "http://www.oldwestbury.edu/",
  },
  {
    value: "8582",
    label: "State University of New York College at Oneonta",
    website: "http://www.oneonta.edu/",
  },
  {
    value: "8583",
    label: "State University of New York College at Plattsburgh",
    website: "http://www.plattsburgh.edu/",
  },
  {
    value: "8584",
    label: "State University of New York College at Potsdam",
    website: "http://www.potsdam.edu/",
  },
  {
    value: "8585",
    label: "State University of New York College at Purchase",
    website: "http://www.purchase.edu/",
  },
  {
    value: "8586",
    label:
      "State University of New York College of Agriculture and Technology at Cobleskill",
    website: "http://www.cobleskill.edu/",
  },
  {
    value: "8587",
    label:
      "State University of New York College of Environmental Science and Forestry",
    website: "http://www.esf.edu/",
  },
  {
    value: "8588",
    label: "State University of New York College of Optometry",
    website: "http://www.sunyopt.edu/",
  },
  {
    value: "8589",
    label: "State University of New York College of Technology at Alfred",
    website: "http://www.alfredtech.edu/",
  },
  {
    value: "8590",
    label: "State University of New York College of Technology at Farmingdale",
    website: "http://www.farmingdale.edu/",
  },
  {
    value: "8591",
    label: "State University of New York Downstate Medical Center",
    website: "http://www.hscbklyn.edu/",
  },
  {
    value: "8592",
    label: "State University of New York Empire State College",
    website: "http://www.esc.edu/",
  },
  {
    value: "8593",
    label: "State University of New York Health Sience Centre Syracuse",
    website: "http://www.hscsyr.edu/",
  },
  {
    value: "8594",
    label: "State University of New York Institute of Technology at Utica/Rome",
    website: "http://www.sunyit.edu/",
  },
  {
    value: "8595",
    label: "State University of New York Maritime College",
    website: "http://www.sunymaritime.edu/",
  },
  {
    value: "8596",
    label:
      "State University of New York School of Engineering and Applied Sciences",
    website: "http://www.eng.buffalo.edu/",
  },
  {
    value: "8597",
    label: "State University of New York (SUNY)",
    website: "http://www.sunycentral.edu/",
  },
  {
    value: "8598",
    label: "State University of New York Upstate Medical University ",
    website: "http://www.upstate.edu/",
  },
  {
    value: "8599",
    label: "State University of West Georgia",
    website: "http://www.westga.edu/",
  },
  {
    value: "8600",
    label: "St. Augustine's College North Carolina",
    website: "http://www.st-aug.edu/",
  },
  {
    value: "8601",
    label: "St. Bernard's Institute",
    website: "http://www.sbi.edu/",
  },
  {
    value: "8602",
    label: "St. Bonaventure University",
    website: "http://www.sbu.edu/",
  },
  {
    value: "8603",
    label: "St. Cloud State University",
    website: "http://www.stcloudstate.edu/",
  },
  {
    value: "8604",
    label: "St. Edwards University",
    website: "http://www.stedwards.edu/",
  },
  {
    value: "8605",
    label: "Stefan University",
    website: "http://www.stefan-university.edu/",
  },
  {
    value: "8606",
    label: "Stephen F. Austin State University",
    website: "http://www.sfasu.edu/",
  },
  {
    value: "8607",
    label: "Stephens College",
    website: "http://www.stephens.edu/",
  },
  {
    value: "8608",
    label: "Sterling College",
    website: "http://www.sterling.edu/",
  },
  {
    value: "8609",
    label: "Stetson University",
    website: "http://www.stetson.edu/",
  },
  {
    value: "8610",
    label: "Stevens Institute of Technology",
    website: "http://www.stevens-tech.edu/",
  },
  {
    value: "8611",
    label: "St. Francis College, Brooklyn Heights",
    website: "http://www.stfranciscollege.edu/",
  },
  {
    value: "8612",
    label: "St. Francis College, Fort Wayne",
    website: "http://www.sfc.edu/",
  },
  {
    value: "8613",
    label: "St. Francis College, Loretto",
    website: "http://www.sfcpa.edu/",
  },
  {
    value: "8614",
    label: "St. Francis Medical Center College of Nursing",
    website: "http://www.osfsaintfrancis.org/",
  },
  {
    value: "8615",
    label: "St. George's University",
    website: "http://www.sgu.edu/",
  },
  {
    value: "8616",
    label: "Stillman College",
    website: "http://www.stillman.edu/",
  },
  {
    value: "8617",
    label: "St. John Fisher College",
    website: "http://www.sjfc.edu/",
  },
  {
    value: "8618",
    label: "St. John's College Maryland",
    website: "http://www.sjca.edu/",
  },
  {
    value: "8619",
    label: "St. John's College New Mexico",
    website: "http://www.sjcsf.edu/",
  },
  {
    value: "8620",
    label: "St. John's Seminary",
    website: "http://www.stjohnsem.edu/",
  },
  {
    value: "8621",
    label: "St. John's University",
    website: "http://www.stjohns.edu/",
  },
  {
    value: "8622",
    label: "St. Joseph College",
    website: "http://www.sjc.edu/",
  },
  {
    value: "8623",
    label: "St. Joseph College of Nursing",
    website: "http://www.stfrancis.edu/sjcn/sjcnhome.htm",
  },
  {
    value: "8624",
    label: "St. Joseph's College",
    website: "http://www.saintjoe.edu/",
  },
  {
    value: "8625",
    label: "St. Joseph's College New York",
    website: "http://www.sjcny.edu/",
  },
  {
    value: "8626",
    label: "St. Joseph's College New York, Suffolk Campus",
    website: "http://www.sjcny.edu/patchogue/",
  },
  {
    value: "8627",
    label: "St. Joseph's College of Maine",
    website: "http://www.sjcme.edu/",
  },
  {
    value: "8628",
    label: "St. Joseph's University",
    website: "http://www.sju.edu/",
  },
  {
    value: "8629",
    label: "St. Lawrence University",
    website: "http://www.stlawu.edu/",
  },
  {
    value: "8630",
    label: "St. Leo College",
    website: "http://www.saintleo.edu/",
  },
  {
    value: "8631",
    label: "St. Louis Christian College",
    website: "http://www.slcc4ministry.edu/",
  },
  {
    value: "8632",
    label: "St. Louis College of Pharmacy",
    website: "http://www.stlcop.edu/",
  },
  {
    value: "8633",
    label: "St. Louis University",
    website: "http://www.slu.edu/",
  },
  {
    value: "8634",
    label: "St. Luke's College",
    website: "http://www.saint-lukes.org/about/slc/",
  },
  {
    value: "8635",
    label: "St. Martin's College",
    website: "http://www.stmartin.edu/",
  },
  {
    value: "8636",
    label: "St. Mary College",
    website: "http://www.smcks.edu/",
  },
  {
    value: "8637",
    label: "St. Mary-of-the-Woods College",
    website: "http://www.smwc.edu/",
  },
  {
    value: "8638",
    label: "St. Mary's College Indiana",
    website: "http://www.saintmarys.edu/",
  },
  {
    value: "8639",
    label: "St. Mary's College of California",
    website: "http://www.stmarys-ca.edu/",
  },
  {
    value: "8640",
    label: "St. Mary's College of Maryland",
    website: "http://www.smcm.edu/",
  },
  {
    value: "8641",
    label: "St. Mary's University of Minnesota",
    website: "http://www.smumn.edu/",
  },
  {
    value: "8642",
    label: "St. Mary's University of San Antonio",
    website: "http://www.stmarytx.edu/",
  },
  {
    value: "8643",
    label: "St. Meinrad College",
    website: "http://www.saintmeinrad.edu/",
  },
  {
    value: "8644",
    label: "St. Michael's College",
    website: "http://www.smcvt.edu/",
  },
  {
    value: "8645",
    label: "St. Norbert College",
    website: "http://www.snc.edu/",
  },
  {
    value: "8646",
    label: "St. Olaf College",
    website: "http://www.stolaf.edu/",
  },
  {
    value: "8647",
    label: "Stonehill College",
    website: "http://www.stonehill.edu/",
  },
  {
    value: "8648",
    label: "St. Paul's College",
    website: "http://www.saintpauls.edu/",
  },
  {
    value: "8649",
    label: "St. Petersburg College",
    website: "http://www.spcollege.edu/",
  },
  {
    value: "8650",
    label: "St. Peter's College",
    website: "http://www.spc.edu/",
  },
  {
    value: "8651",
    label: "Strayer University",
    website: "http://www.strayer.edu/",
  },
  {
    value: "8652",
    label: "St. Thomas Aquinas College",
    website: "http://www.stac.edu/",
  },
  {
    value: "8653",
    label: "St. Thomas University",
    website: "http://www.stu.edu/",
  },
  {
    value: "8654",
    label: "St. Vincent College",
    website: "http://www.stvincent.edu/",
  },
  {
    value: "8655",
    label: "St. Xavier University",
    website: "http://www.sxu.edu/",
  },
  {
    value: "8656",
    label: "Suffolk University",
    website: "http://www.suffolk.edu/",
  },
  {
    value: "8657",
    label: "Sullivan College",
    website: "http://www.sullivan.edu/",
  },
  {
    value: "8658",
    label: "Sul Ross State University",
    website: "http://www.sulross.edu/",
  },
  {
    value: "8659",
    label: "Susquehanna University",
    website: "http://www.susqu.edu/",
  },
  {
    value: "8660",
    label: "Swarthmore College",
    website: "http://www.swarthmore.edu/",
  },
  {
    value: "8661",
    label: "Sweet Briar College",
    website: "http://www.sbc.edu/",
  },
  {
    value: "8662",
    label: "Syracuse University",
    website: "http://www.syr.edu/",
  },
  {
    value: "8663",
    label: "Tabor College",
    website: "http://www.tabor.edu/",
  },
  {
    value: "8664",
    label: "Talladega College",
    website: "http://www.talladega.edu/",
  },
  {
    value: "8665",
    label: "Tarleton State University",
    website: "http://www.tarleton.edu/",
  },
  {
    value: "8666",
    label: "Taylor University",
    website: "http://www.tayloru.edu/",
  },
  {
    value: "8667",
    label: "Taylor University, Fort Wayne Campus",
    website: "http://www.tayloru.edu/fw/",
  },
  {
    value: "8668",
    label: "Teachers College, Columbia University",
    website: "http://www.tc.columbia.edu/",
  },
  {
    value: "8669",
    label: "Temple University",
    website: "http://www.temple.edu/",
  },
  {
    value: "8670",
    label: "Temple University School of Podiatric Medicine",
    website: "http://www.pcpm.edu/",
  },
  {
    value: "8671",
    label: "Tennessee State University",
    website: "http://www.tnstate.edu/",
  },
  {
    value: "8672",
    label: "Tennessee Technological University",
    website: "http://www.tntech.edu/",
  },
  {
    value: "8673",
    label: "Tennessee Temple University",
    website: "http://www.tntemple.edu/",
  },
  {
    value: "8674",
    label: "Tennessee Wesleyan College",
    website: "http://www.twcnet.edu/",
  },
  {
    value: "8675",
    label: "Texas A&M International University",
    website: "http://www.tamiu.edu/",
  },
  {
    value: "8676",
    label: "Texas A&M University",
    website: "http://www.tamu.edu/",
  },
  {
    value: "8677",
    label: "Texas A&M University - Commerce",
    website: "http://www.tamu-commerce.edu/",
  },
  {
    value: "8678",
    label: "Texas A&M University - Corpus Christi",
    website: "http://www.tamucc.edu/",
  },
  {
    value: "8679",
    label: "Texas A&M University - Galveston",
    website: "http://www.tamug.tamu.edu/",
  },
  {
    value: "8680",
    label: "Texas A&M University - Kingsville",
    website: "http://www.tamuk.edu/",
  },
  {
    value: "8681",
    label: "Texas A&M University - Texarkana",
    website: "http://www.tamut.edu/",
  },
  {
    value: "8682",
    label: "Texas Chiropractic College",
    website: "http://www.txchiro.edu/",
  },
  {
    value: "8683",
    label: "Texas Christian University",
    website: "http://www.tcu.edu/",
  },
  {
    value: "8684",
    label: "Texas College",
    website: "http://www.texascollege.edu/",
  },
  {
    value: "8685",
    label: "Texas College of Osteopathic Medicine",
    website: "http://www.hsc.unt.edu/education/tcom/",
  },
  {
    value: "8686",
    label: "Texas Lutheran University",
    website: "http://www.txlutheran.edu/",
  },
  {
    value: "8687",
    label: "Texas Southern University",
    website: "http://www.tsu.edu/",
  },
  {
    value: "8688",
    label: "Texas Tech University",
    website: "http://www.ttu.edu/",
  },
  {
    value: "8689",
    label: "Texas Tech University Health Science Center",
    website: "http://www.ttuhsc.edu/",
  },
  {
    value: "8690",
    label: "Texas Wesleyan University",
    website: "http://www.txwesleyan.edu/",
  },
  {
    value: "8691",
    label: "Texas Woman's University",
    website: "http://www.twu.edu/",
  },
  {
    value: "8692",
    label: "The American College",
    website: "http://www.amercoll.edu/",
  },
  {
    value: "8693",
    label: "The Art Institute of Boston",
    website: "http://www.aiboston.edu/",
  },
  {
    value: "8694",
    label: "The Art Institutes International Portland",
    website: "http://www.aipd.aii.edu/",
  },
  {
    value: "8695",
    label: "The Art Institutes International San Francisco",
    website: "http://www.aisf.aii.edu/",
  },
  {
    value: "8696",
    label: "The Boston Conservatory",
    website: "http://www.bostonconservatory.edu/",
  },
  {
    value: "8697",
    label: "The Catholic University of America",
    website: "http://www.cua.edu/",
  },
  {
    value: "8698",
    label: "The Chicago School of Professional Psychology",
    website: "http://www.thechicagoschool.edu/",
  },
  {
    value: "8699",
    label: "The College of Insurance",
    website: "http://www.tci.edu/",
  },
  {
    value: "8700",
    label: "The College of New Jersey",
    website: "http://www.tcnj.edu/",
  },
  {
    value: "8701",
    label: "The College of Santa Fe",
    website: "http://www.csf.edu/",
  },
  {
    value: "8702",
    label: "The College of St. Scholastica",
    website: "http://www.css.edu/",
  },
  {
    value: "8703",
    label: "The College of Westchester",
    website: "http://www.cw.edu/",
  },
  {
    value: "8704",
    label: "The College of Wooster",
    website: "http://www.wooster.edu/",
  },
  {
    value: "8705",
    label: "The Cooper Union for the Advancement of Science and Art",
    website: "http://www.cooper.edu/",
  },
  {
    value: "8706",
    label: "The Corcoran College of Art",
    website: "http://www.corcoran.edu/college/",
  },
  {
    value: "8707",
    label: "The Curtis Institute of Music",
    website: "http://www.curtis.edu/",
  },
  {
    value: "8708",
    label: "The Defiance College",
    website: "http://www.defiance.edu/",
  },
  {
    value: "8709",
    label: "The Dickinson School of Law",
    website: "http://www.dsl.edu/",
  },
  {
    value: "8710",
    label: "The Illinois Institute of Art-Chicago",
    website: "http://www.ilic.artinstitutes.edu/",
  },
  {
    value: "8711",
    label: "The Johns Hopkins University",
    website: "http://www.jhu.edu/",
  },
  {
    value: "8712",
    label: "The Juilliard School",
    website: "http://www.juilliard.edu/",
  },
  {
    value: "8713",
    label: "The Leadership Institute of Seattle",
    website: "http://www.lios.org/",
  },
  {
    value: "8714",
    label: "The Maryland Institute, College of Art",
    website: "http://www.mica.edu/",
  },
  {
    value: "8715",
    label: "The Master's College",
    website: "http://www.masters.edu/",
  },
  {
    value: "8716",
    label: "The McGregor School of Antioch University",
    website: "http://www.mcgregor.edu/",
  },
  {
    value: "8717",
    label: "The Naropa Institute",
    website: "http://www.naropa.edu/",
  },
  {
    value: "8718",
    label: "The New School",
    website: "http://www.newschool.edu/",
  },
  {
    value: "8719",
    label: "The Rockefeller University",
    website: "http://www.rockefeller.edu/",
  },
  {
    value: "8720",
    label: "The School of the Art Institute of Chicago",
    website: "http://www.artic.edu/",
  },
  {
    value: "8721",
    label: "The Scripps Research Institute",
    website: "http://www.scripps.edu/",
  },
  {
    value: "8722",
    label: "The Southern Christian University",
    website: "http://www.southernchristian.edu/",
  },
  {
    value: "8723",
    label: "The Tulane University of New Orleans",
    website: "http://www.tulane.edu/",
  },
  {
    value: "8724",
    label: "The Union Institute",
    website: "http://www.tui.edu/",
  },
  {
    value: "8725",
    label: "Thiel College",
    website: "http://www.thiel.edu/",
  },
  {
    value: "8726",
    label: "Thomas A. Edison State College",
    website: "http://www.tesc.edu/",
  },
  {
    value: "8727",
    label: "Thomas Aquinas College",
    website: "http://www.thomasaquinas.edu/",
  },
  {
    value: "8728",
    label: "Thomas College Maine",
    website: "http://www.thomas.edu/",
  },
  {
    value: "8729",
    label: "Thomas Jefferson University",
    website: "http://www.tju.edu/",
  },
  {
    value: "8730",
    label: "Thomas More College",
    website: "http://www.thomasmore.edu/",
  },
  {
    value: "8731",
    label: "Thomas More College of Liberal Arts",
    website: "http://www.thomasmorecollege.edu/",
  },
  {
    value: "8732",
    label: "Thomas University",
    website: "http://www.thomasu.edu/",
  },
  {
    value: "8733",
    label: "Thunderbird School of Global Management",
    website: "http://www.thunderbird.edu/",
  },
  {
    value: "8734",
    label: "Tiffin University",
    website: "http://www.tiffin.edu/",
  },
  {
    value: "8735",
    label: "Toccoa Falls College",
    website: "http://www.toccoafalls.edu/",
  },
  {
    value: "8736",
    label: "Tomball College",
    website: "http://wwwtc.nhmccd.edu/",
  },
  {
    value: "8737",
    label: "Tougaloo College",
    website: "http://www.tougaloo.edu/",
  },
  {
    value: "8738",
    label: "Touro College",
    website: "http://www.touro.edu/",
  },
  {
    value: "8739",
    label: "Touro University",
    website: "http://www.tu.edu/",
  },
  {
    value: "8740",
    label: "Towson University",
    website: "http://www.towson.edu/",
  },
  {
    value: "8741",
    label: "Transylvania University",
    website: "http://www.transy.edu/",
  },
  {
    value: "8742",
    label: "Trevecca Nazarene University",
    website: "http://www.trevecca.edu/",
  },
  {
    value: "8743",
    label: "Tri-College University",
    website: "http://www.ndsu.nodak.edu/tricollege/",
  },
  {
    value: "8744",
    label: "Trident University",
    website: "http://www.trident.edu/",
  },
  {
    value: "8745",
    label: "Trinity Bible College",
    website: "http://www.tbc2day.edu/",
  },
  {
    value: "8746",
    label: "Trinity Christian College",
    website: "http://www.trnty.edu/",
  },
  {
    value: "8747",
    label: "Trinity College Connecticut",
    website: "http://www.trincoll.edu/",
  },
  {
    value: "8748",
    label: "Trinity College of Florida",
    website: "http://www.trinitycollege.edu/",
  },
  {
    value: "8749",
    label: "Trinity College of Vermont",
    website: "http://www.trinityvt.edu/",
  },
  {
    value: "8750",
    label: "Trinity International University",
    website: "http://www.trin.edu/",
  },
  {
    value: "8751",
    label: "Trinity International University (Excel), Miami",
    website: "http://www.tiu.edu/excel/index.html",
  },
  {
    value: "8752",
    label: "Trinity University",
    website: "http://www.trinitydc.edu/",
  },
  {
    value: "8753",
    label: "Trinity University",
    website: "http://www.trinity.edu/",
  },
  {
    value: "8754",
    label: "Tri-State University",
    website: "http://www.tristate.edu/",
  },
  {
    value: "8755",
    label: "Triton College",
    website: "http://www.triton.cc.il.us/",
  },
  {
    value: "8756",
    label: "Troy University",
    website: "http://www.troy.edu/",
  },
  {
    value: "8757",
    label: "Troy University, Dothan",
    website: "http://dothan.troy.edu/",
  },
  {
    value: "8758",
    label: "Troy University, Montgomery",
    website: "http://montgomery.troy.edu/",
  },
  {
    value: "8759",
    label: "Troy University, Phenix City",
    website: "http://phenix.troy.edu/",
  },
  {
    value: "8760",
    label: "Troy University, Troy",
    website: "http://troy.troy.edu/",
  },
  {
    value: "8761",
    label: "Truman College",
    website: "http://www.trumancollege.net/",
  },
  {
    value: "8762",
    label: "Truman State University",
    website: "http://www.truman.edu/",
  },
  {
    value: "8763",
    label: "Tufts University",
    website: "http://www.tufts.edu/",
  },
  {
    value: "8764",
    label: "Tui Online University",
    website: "http://www.tuiu.edu/",
  },
  {
    value: "8765",
    label: "Tusculum College",
    website: "http://www.tusculum.edu/",
  },
  {
    value: "8766",
    label: "Tuskegee University",
    website: "http://www.tusk.edu/",
  },
  {
    value: "8767",
    label: "Uniformed Services Universty of the Health Sciences",
    website: "http://www.usuhs.mil/",
  },
  {
    value: "8768",
    label: "Union College",
    website: "http://www.union.edu/",
  },
  {
    value: "8769",
    label: "Union College Kentucky",
    website: "http://www.unionky.edu/",
  },
  {
    value: "8770",
    label: "Union College Nebraska",
    website: "http://www.ucollege.edu/",
  },
  {
    value: "8771",
    label: "Union Theological Seminary (UTS)",
    website: "http://www.union-psce.edu/",
  },
  {
    value: "8772",
    label: "Union University",
    website: "http://www.uu.edu/",
  },
  {
    value: "8773",
    label: "United States Air Force Academy",
    website: "http://www.usafa.af.mil/",
  },
  {
    value: "8774",
    label: "United States Coast Guard Academy",
    website: "http://www.cga.edu/",
  },
  {
    value: "8775",
    label: "United States International University",
    website: "http://www.usiu.edu/",
  },
  {
    value: "8776",
    label: "United States Merchant Marine Academy",
    website: "http://www.usmma.edu/",
  },
  {
    value: "8777",
    label: "United States Military Academy",
    website: "http://www.usma.edu/",
  },
  {
    value: "8778",
    label: "United States Naval Academy",
    website: "http://www.usna.edu/",
  },
  {
    value: "8779",
    label: "United States Sports Academy",
    website: "http://www.sport.ussa.edu/",
  },
  {
    value: "8780",
    label: "Unity College",
    website: "http://www.unity.edu/",
  },
  {
    value: "8781",
    label: "University of Advancing Technology (UAT)",
    website: "http://www.uat.edu/",
  },
  {
    value: "8782",
    label: "University of Akron",
    website: "http://www.uakron.edu/",
  },
  {
    value: "8783",
    label: "University of Alabama - Birmingham",
    website: "http://www.uab.edu/",
  },
  {
    value: "8784",
    label: "University of Alabama - Huntsville",
    website: "http://www.uah.edu/",
  },
  {
    value: "8785",
    label: "University of Alabama - Tuscaloosa",
    website: "http://www.ua.edu/",
  },
  {
    value: "8786",
    label: "University of Alanta",
    website: "http://www.uofa.edu/",
  },
  {
    value: "8787",
    label: "University of Alaska - Anchorage",
    website: "http://www.uaa.alaska.edu/",
  },
  {
    value: "8788",
    label: "University of Alaska - Fairbanks",
    website: "http://www.uaf.edu/",
  },
  {
    value: "8789",
    label: "University of Alaska - Southeast",
    website: "http://www.uas.alaska.edu/",
  },
  {
    value: "8790",
    label: "University of Alaska (System)",
    website: "http://www.alaska.edu/",
  },
  {
    value: "8791",
    label: "University of Arizona",
    website: "http://www.arizona.edu/",
  },
  {
    value: "8792",
    label: "University of Arkansas at Fayetteville",
    website: "http://www.uark.edu/",
  },
  {
    value: "8793",
    label: "University of Arkansas at Little Rock",
    website: "http://www.ualr.edu/",
  },
  {
    value: "8794",
    label: "University of Arkansas at Monticello",
    website: "http://www.uamont.edu/",
  },
  {
    value: "8795",
    label: "University of Arkansas at Pine Bluff",
    website: "http://www.uapb.edu/",
  },
  {
    value: "8796",
    label: "University of Arkansas for Medical Sciences",
    website: "http://www.uams.edu/",
  },
  {
    value: "8797",
    label: "University of Arkansas (System)",
    website: "http://www.uasys.edu/",
  },
  {
    value: "8798",
    label: "University of Baltimore",
    website: "http://www.ubalt.edu/",
  },
  {
    value: "8799",
    label: "University of Bridgeport",
    website: "http://www.bridgeport.edu/",
  },
  {
    value: "8800",
    label: "University of California, Berkeley",
    website: "http://www.berkeley.edu/",
  },
  {
    value: "8801",
    label: "University of California, Davis",
    website: "http://www.ucdavis.edu/",
  },
  {
    value: "8802",
    label: "University of California, Hastings College of Law",
    website: "http://www.uchastings.edu/",
  },
  {
    value: "8803",
    label: "University of California, Irvine",
    website: "http://www.uci.edu/",
  },
  {
    value: "8804",
    label: "University of California, Los Angeles",
    website: "http://www.ucla.edu/",
  },
  {
    value: "8805",
    label: "University of California, Merced",
    website: "http://www.ucmerced.edu/",
  },
  {
    value: "8806",
    label: "University of California, Oakland",
    website: "http://www.ucop.edu/",
  },
  {
    value: "8807",
    label: "University of California, Riverside",
    website: "http://www.ucr.edu/",
  },
  {
    value: "8808",
    label: "University of California, San Diego",
    website: "http://www.ucsd.edu/",
  },
  {
    value: "8809",
    label: "University of California, San Francisco",
    website: "http://www.ucsf.edu/",
  },
  {
    value: "8810",
    label: "University of California, Santa Barbara",
    website: "http://www.ucsb.edu/",
  },
  {
    value: "8811",
    label: "University of California, Santa Cruz",
    website: "http://www.ucsc.edu/",
  },
  {
    value: "8812",
    label: "University of California System",
    website: "http://www.universityofcalifornia.edu/",
  },
  {
    value: "8813",
    label: "University of Central Arkansas",
    website: "http://www.uca.edu/",
  },
  {
    value: "8814",
    label: "University of Central Florida",
    website: "http://www.ucf.edu/",
  },
  {
    value: "8815",
    label: "University of Central Missouri",
    website: "http://www.ucmo.edu/",
  },
  {
    value: "8816",
    label: "University of Central Oklahoma",
    website: "http://www.ucok.edu/",
  },
  {
    value: "8817",
    label: "University of Central Texas",
    website: "http://www.vvm.com/uct/",
  },
  {
    value: "8818",
    label: "University of Charleston",
    website: "http://www.uchaswv.edu/",
  },
  {
    value: "8819",
    label: "University of Charleston South Carolina",
    website: "http://univchas.cofc.edu/",
  },
  {
    value: "8820",
    label: "University of Chicago",
    website: "http://www.uchicago.edu/",
  },
  {
    value: "8821",
    label: "University of Cincinnati",
    website: "http://www.uc.edu/",
  },
  {
    value: "8822",
    label: "University of Colorado at Boulder",
    website: "http://www.colorado.edu/",
  },
  {
    value: "8823",
    label: "University of Colorado at Colorado Springs",
    website: "http://www.uccs.edu/",
  },
  {
    value: "8824",
    label: "University of Colorado at Denver",
    website: "http://www.cudenver.edu/",
  },
  {
    value: "8825",
    label: "University of Colorado Health Sciences Center",
    website: "http://www.uchsc.edu/",
  },
  {
    value: "8826",
    label: "University of Connecticut",
    website: "http://www.uconn.edu/",
  },
  {
    value: "8827",
    label: "University of Connecticut at Avery Point",
    website: "http://www.averypoint.uconn.edu/",
  },
  {
    value: "8828",
    label: "University of Connecticut at Hartford",
    website: "http://vm.uconn.edu/~wwwhtfd/ugrad/",
  },
  {
    value: "8829",
    label: "University of Connecticut at Stamford",
    website: "http://www.stamford.uconn.edu/",
  },
  {
    value: "8830",
    label: "University of Connecticut at Waterbury",
    website: "http://www.waterbury.uconn.edu/",
  },
  {
    value: "8831",
    label: "University of Connecticut Health Center",
    website: "http://www.uchc.edu/",
  },
  {
    value: "8832",
    label: "University of Dallas",
    website: "http://www.udallas.edu/",
  },
  {
    value: "8833",
    label: "University of Dayton",
    website: "http://www.udayton.edu/",
  },
  {
    value: "8834",
    label: "University of Delaware",
    website: "http://www.udel.edu/",
  },
  {
    value: "8835",
    label: "University of Denver",
    website: "http://www.du.edu/",
  },
  {
    value: "8836",
    label: "University of Detroit Mercy",
    website: "http://www.udmercy.edu/",
  },
  {
    value: "8837",
    label: "University of Dubuque",
    website: "http://www.dbq.edu/",
  },
  {
    value: "8838",
    label: "University of Evansville",
    website: "http://www.evansville.edu/",
  },
  {
    value: "8839",
    label: "University of Findlay",
    website: "http://www.findlay.edu/",
  },
  {
    value: "8840",
    label: "University of Florida",
    website: "http://www.ufl.edu/",
  },
  {
    value: "8841",
    label: "University of Georgia",
    website: "http://www.uga.edu/",
  },
  {
    value: "8842",
    label: "University of Great Falls",
    website: "http://www.ugf.edu/",
  },
  {
    value: "8843",
    label: "University of Hartford",
    website: "http://www.hartford.edu/",
  },
  {
    value: "8844",
    label: "University of Hawaii - Hilo",
    website: "http://www.uhh.hawaii.edu/",
  },
  {
    value: "8845",
    label: "University of Hawaii - Manoa",
    website: "http://www.uhm.hawaii.edu/",
  },
  {
    value: "8846",
    label: "University Of Hawaii - System",
    website: "http://www.hawaii.edu/",
  },
  {
    value: "8847",
    label: "University of Hawaii - West Oahu",
    website: "http://www.uhwo.hawaii.edu/",
  },
  {
    value: "8848",
    label: "University of Health Sciences",
    website: "http://www.uhs.edu/",
  },
  {
    value: "8849",
    label: "University of Houston",
    website: "http://www.uh.edu/",
  },
  {
    value: "8850",
    label: "University of Houston, Clear Lake",
    website: "http://www.cl.uh.edu/",
  },
  {
    value: "8851",
    label: "University of Houston, Downtown",
    website: "http://www.dt.uh.edu/",
  },
  {
    value: "8852",
    label: "University of Houston, Victoria",
    website: "http://www.vic.uh.edu/",
  },
  {
    value: "8853",
    label: "University of Idaho",
    website: "http://www.uidaho.edu/",
  },
  {
    value: "8854",
    label: "University of Illinois",
    website: "http://www.uillinois.edu/",
  },
  {
    value: "8855",
    label: "University of Illinois at Chicago",
    website: "http://www.uic.edu/",
  },
  {
    value: "8856",
    label: "University of Illinois at Springfield",
    website: "http://www.uis.edu/",
  },
  {
    value: "8857",
    label: "University of Illinois at Urbana-Champaign",
    website: "http://www.uiuc.edu/",
  },
  {
    value: "8858",
    label: "University of Indianapolis",
    website: "http://www.uindy.edu/",
  },
  {
    value: "8859",
    label: "University of Iowa",
    website: "http://www.uiowa.edu/",
  },
  {
    value: "8860",
    label: "University of Kansas",
    website: "http://www.ku.edu/",
  },
  {
    value: "8861",
    label: "University of Kentucky",
    website: "http://www.uky.edu/",
  },
  {
    value: "8862",
    label: "University of La Verne",
    website: "http://www.ulaverne.edu/",
  },
  {
    value: "8863",
    label: "University of Louisiana at Lafayette",
    website: "http://www.louisiana.edu/",
  },
  {
    value: "8864",
    label: "University of Louisiana at Monroe",
    website: "http://www.ulm.edu/",
  },
  {
    value: "8865",
    label: "University of Louisville",
    website: "http://www.louisville.edu/",
  },
  {
    value: "8866",
    label: "University of Maine, Augusta",
    website: "http://www.uma.maine.edu/",
  },
  {
    value: "8867",
    label: "University of Maine, Farmington",
    website: "http://www.umf.maine.edu/",
  },
  {
    value: "8868",
    label: "University of Maine, Fort Kent",
    website: "http://www.umfk.maine.edu/",
  },
  {
    value: "8869",
    label: "University of Maine, Machias",
    website: "http://www.umm.maine.edu/",
  },
  {
    value: "8870",
    label: "University of Maine, Orono",
    website: "http://www.umaine.edu/",
  },
  {
    value: "8871",
    label: "University of Maine, Presque Isle",
    website: "http://www.umpi.maine.edu/",
  },
  {
    value: "8872",
    label: "University of Maine (System)",
    website: "http://www.maine.edu/",
  },
  {
    value: "8873",
    label: "University of Management & Technology",
    website: "http://www.umtweb.edu/",
  },
  {
    value: "8874",
    label: "University of Mary",
    website: "http://www.umary.edu/",
  },
  {
    value: "8875",
    label: "University of Mary Hardin-Baylor",
    website: "http://www.umhb.edu/",
  },
  {
    value: "8876",
    label: "University of Maryland at Baltimore",
    website: "http://www.umbc.edu/",
  },
  {
    value: "8877",
    label: "University of Maryland at College Park",
    website: "http://www.umd.edu/",
  },
  {
    value: "8878",
    label: "University of Maryland Baltimore County",
    website: "http://www.umbc.edu/",
  },
  {
    value: "8879",
    label: "University of Maryland Eastern Shore",
    website: "http://www.umes.edu/",
  },
  {
    value: "8880",
    label: "University of Maryland Medicine",
    website: "http://www.umm.edu/",
  },
  {
    value: "8881",
    label: "University of Maryland (System)",
    website: "http://www.ums.edu/",
  },
  {
    value: "8882",
    label: "University of Maryland University College",
    website: "http://www.umuc.edu/",
  },
  {
    value: "8883",
    label: "University of Massachusetts at Amherst",
    website: "http://www.umass.edu/",
  },
  {
    value: "8884",
    label: "University of Massachusetts at Boston",
    website: "http://www.umb.edu/",
  },
  {
    value: "8885",
    label: "University of Massachusetts at Dartmouth",
    website: "http://www.umassd.edu/",
  },
  {
    value: "8886",
    label: "University of Massachusetts at Lowell",
    website: "http://www.uml.edu/",
  },
  {
    value: "8887",
    label: "University of Massachusetts Medical Center at Worcester",
    website: "http://www.ummed.edu/",
  },
  {
    value: "8888",
    label: "University of Massachusetts (System)",
    website: "http://www.massachusetts.edu/",
  },
  {
    value: "8889",
    label: "University of Medicine and Dentistry of New Jersey",
    website: "http://www.umdnj.edu/",
  },
  {
    value: "8890",
    label: "University of Memphis",
    website: "http://www.memphis.edu/",
  },
  {
    value: "8891",
    label: "University of Miami",
    website: "http://www.miami.edu/",
  },
  {
    value: "8892",
    label: "University of Michigan - Ann Arbor",
    website: "http://www.umich.edu/",
  },
  {
    value: "8893",
    label: "University of Michigan - Dearborn",
    website: "http://www.umd.umich.edu/",
  },
  {
    value: "8894",
    label: "University of Michigan - Flint",
    website: "http://www.flint.umich.edu/",
  },
  {
    value: "8895",
    label: "University of Minnesota - Crookston",
    website: "http://www.crk.umn.edu/",
  },
  {
    value: "8896",
    label: "University of Minnesota - Duluth",
    website: "http://www.d.umn.edu/",
  },
  {
    value: "8897",
    label: "University of Minnesota - Morris",
    website: "http://www.mrs.umn.edu/",
  },
  {
    value: "8898",
    label: "University of Minnesota - Twin Cities Campus",
    website: "http://www1.umn.edu/twincities/",
  },
  {
    value: "8899",
    label: "University of Mississippi",
    website: "http://www.olemiss.edu/",
  },
  {
    value: "8900",
    label: "University of Mississippi Medical Center",
    website: "http://www.umc.edu/",
  },
  {
    value: "8901",
    label: "University of Missouri - Columbia",
    website: "http://www.missouri.edu/",
  },
  {
    value: "8902",
    label: "University of Missouri - Kansas City",
    website: "http://www.umkc.edu/",
  },
  {
    value: "8903",
    label: "University of Missouri - Saint Louis",
    website: "http://www.umsl.edu/",
  },
  {
    value: "8904",
    label: "University of Mobile",
    website: "http://www.umobile.edu/",
  },
  {
    value: "8905",
    label: "University of Montana",
    website: "http://www.umt.edu/",
  },
  {
    value: "8906",
    label: "University of Montana Western",
    website: "http://www.umwestern.edu/",
  },
  {
    value: "8907",
    label: "University of Montevallo",
    website: "http://www.montevallo.edu/",
  },
  {
    value: "8908",
    label: "University of Nebraska - Kearney",
    website: "http://www.unk.edu/",
  },
  {
    value: "8909",
    label: "University of Nebraska - Lincoln",
    website: "http://www.unl.edu/",
  },
  {
    value: "8910",
    label: "University of Nebraska Medical Center",
    website: "http://www.unmc.edu/",
  },
  {
    value: "8911",
    label: "University of Nebraska - Omaha",
    website: "http://www.unomaha.edu/",
  },
  {
    value: "8912",
    label: "University of Nebraska (System)",
    website: "http://www.nebraska.edu/",
  },
  {
    value: "8913",
    label: "University of Nevada - Las Vegas",
    website: "http://www.unlv.edu/",
  },
  {
    value: "8914",
    label: "University of Nevada - Reno",
    website: "http://www.unr.edu/",
  },
  {
    value: "8915",
    label: "University of New England",
    website: "http://www.une.edu/",
  },
  {
    value: "8916",
    label: "University of New England, Westbrook College Campus",
    website: "http://www.une.edu/wcdirctn.html",
  },
  {
    value: "8917",
    label: "University of New Hampshire",
    website: "http://www.unh.edu/",
  },
  {
    value: "8918",
    label: "University of New Hampshire - Manchester",
    website: "http://www.unh.edu/unhm/",
  },
  {
    value: "8919",
    label: "University of New Haven",
    website: "http://www.newhaven.edu/",
  },
  {
    value: "8920",
    label: "University of New Mexico",
    website: "http://www.unm.edu/",
  },
  {
    value: "8921",
    label: "University of New Orleans",
    website: "http://www.uno.edu/",
  },
  {
    value: "8922",
    label: "University of North Alabama",
    website: "http://www.una.edu/",
  },
  {
    value: "8923",
    label: "University of North America",
    website: "http://www.universityofnorthamerica.org/",
  },
  {
    value: "8924",
    label: "University of North Carolina at Asheville",
    website: "http://www.unca.edu/",
  },
  {
    value: "8925",
    label: "University of North Carolina at Chapel Hill",
    website: "http://www.unc.edu/",
  },
  {
    value: "8926",
    label: "University of North Carolina at Charlotte",
    website: "http://www.uncc.edu/",
  },
  {
    value: "8927",
    label: "University of North Carolina at Greensboro",
    website: "http://www.uncg.edu/",
  },
  {
    value: "8928",
    label: "University of North Carolina at Pembroke",
    website: "http://www.uncp.edu/",
  },
  {
    value: "8929",
    label: "University of North Carolina at Wilmington",
    website: "http://www.uncwil.edu/",
  },
  {
    value: "8930",
    label: "University of North Dakota",
    website: "http://www.und.nodak.edu/",
  },
  {
    value: "8931",
    label: "University of Northern Colorado",
    website: "http://www.univnorthco.edu/",
  },
  {
    value: "8932",
    label: "University of Northern Iowa",
    website: "http://www.uni.edu/",
  },
  {
    value: "8933",
    label: "University of Northern Virginia",
    website: "http://www.unva.edu/",
  },
  {
    value: "8934",
    label: "University of Northern Washington",
    website: "http://www.unw.edu/",
  },
  {
    value: "8935",
    label: "University of North Florida",
    website: "http://www.unf.edu/",
  },
  {
    value: "8936",
    label: "University of North Texas",
    website: "http://www.unt.edu/",
  },
  {
    value: "8937",
    label: "University of North Texas Health Science Center at Fort Worth",
    website: "http://www.hsc.unt.edu/",
  },
  {
    value: "8938",
    label: "University of NorthWest",
    website: "http://www.unw.ac/",
  },
  {
    value: "8939",
    label: "University of Notre Dame",
    website: "http://www.nd.edu/",
  },
  {
    value: "8940",
    label: "University of Oklahoma",
    website: "http://www.ou.edu/",
  },
  {
    value: "8941",
    label: "University of Oklahoma Health Sciences Center",
    website: "http://www.ouhsc.edu/",
  },
  {
    value: "8942",
    label: "University of Oregon",
    website: "http://www.uoregon.edu/",
  },
  {
    value: "8943",
    label: "University of Osteopathic Medicine and Health Science",
    website: "http://www.uomhs.edu/",
  },
  {
    value: "8944",
    label: "University of Pennsylvania",
    website: "http://www.upenn.edu/",
  },
  {
    value: "8945",
    label: "University of Phoenix",
    website: "http://www.phoenix.edu/",
  },
  {
    value: "8946",
    label: "University of Pittsburgh",
    website: "http://www.pitt.edu/",
  },
  {
    value: "8947",
    label: "University of Pittsburgh at Bradford",
    website: "http://www.upb.pitt.edu/",
  },
  {
    value: "8948",
    label: "University of Pittsburgh at Greensburg",
    website: "http://www.pitt.edu/~upg/",
  },
  {
    value: "8949",
    label: "University of Pittsburgh at Johnstown",
    website: "http://www.pitt.edu/~upjweb/",
  },
  {
    value: "8950",
    label: "University of Portland",
    website: "http://www.uofport.edu/",
  },
  {
    value: "8951",
    label: "University of Puget Sound",
    website: "http://www.ups.edu/",
  },
  {
    value: "8952",
    label: "University of Redlands",
    website: "http://www.redlands.edu/",
  },
  {
    value: "8953",
    label: "University of Rhode Island",
    website: "http://www.uri.edu/",
  },
  {
    value: "8954",
    label: "University of Richmond",
    website: "http://www.urich.edu/",
  },
  {
    value: "8955",
    label: "University of Rio Grande",
    website: "http://www.urgrgcc.edu/",
  },
  {
    value: "8956",
    label: "University of Rochester",
    website: "http://www.rochester.edu/",
  },
  {
    value: "8957",
    label: "University of San Diego",
    website: "http://www.sandiego.edu/",
  },
  {
    value: "8958",
    label: "University of San Francisco",
    website: "http://www.usfca.edu/",
  },
  {
    value: "8959",
    label: "University of Science and Arts of Oklahoma",
    website: "http://www.usao.edu/",
  },
  {
    value: "8960",
    label: "University of Scranton",
    website: "http://www.uofs.edu/",
  },
  {
    value: "8961",
    label: "University of Sioux Falls",
    website: "http://www.thecoo.edu/",
  },
  {
    value: "8962",
    label: "University of South Alabama",
    website: "http://www.usouthal.edu/",
  },
  {
    value: "8963",
    label: "University of South Carolina",
    website: "http://www.sc.edu/",
  },
  {
    value: "8964",
    label: "University of South Carolina - Aiken",
    website: "http://www.usca.sc.edu/",
  },
  {
    value: "8965",
    label: "University of South Carolina - Beaufort",
    website: "http://www.sc.edu/beaufort/",
  },
  {
    value: "8966",
    label: "University of South Carolina - Lancaster",
    website: "http://www.sc.edu/lancaster/",
  },
  {
    value: "8967",
    label: "University of South Carolina - Salkehatchie",
    website: "http://www.rcce.sc.edu/salkehatchie/",
  },
  {
    value: "8968",
    label: "University of South Carolina - Spartanburg",
    website: "http://www.uscs.edu/",
  },
  {
    value: "8969",
    label: "University of South Carolina - Sumter",
    website: "http://www.uscsumter.edu/",
  },
  {
    value: "8970",
    label: "University of South Carolina - Union",
    website: "http://www.sc.edu/union/",
  },
  {
    value: "8971",
    label: "University of South Dakota",
    website: "http://www.usd.edu/",
  },
  {
    value: "8972",
    label: "University of Southern California",
    website: "http://www.usc.edu/",
  },
  {
    value: "8973",
    label: "University of Southern Indiana",
    website: "http://www.usi.edu/",
  },
  {
    value: "8974",
    label: "University of Southern Maine",
    website: "http://www.usm.maine.edu/",
  },
  {
    value: "8975",
    label: "University of Southern Mississippi",
    website: "http://www.usm.edu/",
  },
  {
    value: "8976",
    label: "University of South Florida",
    website: "http://www.usf.edu/",
  },
  {
    value: "8977",
    label: "University of St. Francis",
    website: "http://www.stfrancis.edu/",
  },
  {
    value: "8978",
    label: "University of St. Thomas, Houston",
    website: "http://www.stthom.edu/",
  },
  {
    value: "8979",
    label: "University of St. Thomas, St. Paul",
    website: "http://www.stthomas.edu/",
  },
  {
    value: "8980",
    label: "University of Tampa",
    website: "http://www.utampa.edu/",
  },
  {
    value: "8981",
    label: "University of Tennessee - Chattanooga",
    website: "http://www.utc.edu/",
  },
  {
    value: "8982",
    label: "University of Tennessee - Knoxville",
    website: "http://www.utk.edu/",
  },
  {
    value: "8983",
    label: "University of Tennessee - Martin",
    website: "http://www.utm.edu/",
  },
  {
    value: "8984",
    label: "University of Tennessee - Memphis",
    website: "http://www.utmem.edu/",
  },
  {
    value: "8985",
    label: "University of Tennessee Space Institute",
    website: "http://www.utsi.edu/",
  },
  {
    value: "8986",
    label: "University of Texas",
    website: "http://www.utsystem.edu/",
  },
  {
    value: "8987",
    label: "University of Texas at Arlington",
    website: "http://www.uta.edu/",
  },
  {
    value: "8988",
    label: "University of Texas at Austin",
    website: "http://www.utexas.edu/",
  },
  {
    value: "8989",
    label: "University of Texas at Brownsville",
    website: "http://www.utb.edu/",
  },
  {
    value: "8990",
    label: "University of Texas at Dallas",
    website: "http://www.utdallas.edu/",
  },
  {
    value: "8991",
    label: "University of Texas at El Paso",
    website: "http://www.utep.edu/",
  },
  {
    value: "8992",
    label: "University of Texas at San Antonio",
    website: "http://www.utsa.edu/",
  },
  {
    value: "8993",
    label: "University of Texas at Tyler",
    website: "http://www.uttyl.edu/",
  },
  {
    value: "8994",
    label: "University of Texas Health Center at Houston",
    website: "http://www.uthouston.edu/",
  },
  {
    value: "8995",
    label: "University of Texas Health Center at Tyler",
    website: "http://www.uthct.edu/",
  },
  {
    value: "8996",
    label: "University of Texas Health Science Center at San Antonio",
    website: "http://www.uthscsa.edu/",
  },
  {
    value: "8997",
    label: "University of Texas M.D. Anderson Cancer Center",
    website: "http://www.mdanderson.org/",
  },
  {
    value: "8998",
    label: "University of Texas Medical Branch Galveston",
    website: "http://www.utmb.edu/",
  },
  {
    value: "8999",
    label: "University of Texas of the Permian Basin",
    website: "http://www.utpb.edu/",
  },
  {
    value: "9000",
    label: "University of Texas Pan American",
    website: "http://www.panam.edu/",
  },
  {
    value: "9001",
    label: "University of Texas Southwestern Medical Center at Dallas",
    website: "http://www.utsouthwestern.edu/",
  },
  {
    value: "9002",
    label: "University of the Arts",
    website: "http://www.uarts.edu/",
  },
  {
    value: "9003",
    label: "University of the District of Columbia",
    website: "http://www.udc.edu/",
  },
  {
    value: "9004",
    label: "University of the Incarnate World",
    website: "http://www.uiw.edu/",
  },
  {
    value: "9005",
    label: "University of the Ozarks",
    website: "http://www.ozarks.edu/",
  },
  {
    value: "9006",
    label: "University of the Pacific",
    website: "http://www.pacific.edu/",
  },
  {
    value: "9007",
    label: "University of the Sciences in Philadelphia",
    website: "http://www.usip.edu/",
  },
  {
    value: "9008",
    label: "University of the South",
    website: "http://www.sewanee.edu/",
  },
  {
    value: "9009",
    label: "University of the Southwest",
    website: "http://www.usw.edu/",
  },
  {
    value: "9010",
    label: "University of Toledo",
    website: "http://www.utoledo.edu/",
  },
  {
    value: "9011",
    label: "University of Tulsa",
    website: "http://www.utulsa.edu/",
  },
  {
    value: "9012",
    label: "University of Utah",
    website: "http://www.utah.edu/",
  },
  {
    value: "9013",
    label: "University of Vermont",
    website: "http://www.uvm.edu/",
  },
  {
    value: "9014",
    label: "University of Virginia",
    website: "http://www.virginia.edu/",
  },
  {
    value: "9015",
    label: "University of Virginia, College at Wise",
    website: "http://www.wise.virginia.edu/",
  },
  {
    value: "9016",
    label: "University of Washington",
    website: "http://www.washington.edu/",
  },
  {
    value: "9017",
    label: "University of Washington, Tacoma",
    website: "http://www.tacoma.washington.edu/",
  },
  {
    value: "9018",
    label: "University of West Alabama",
    website: "http://www.uwa.edu/",
  },
  {
    value: "9019",
    label: "University of West Florida",
    website: "http://www.uwf.edu/",
  },
  {
    value: "9020",
    label: "University of West Los Angeles",
    website: "http://www.uwla.edu/",
  },
  {
    value: "9021",
    label: "University of Wisconsin - Eau Claire",
    website: "http://www.uwec.edu/",
  },
  {
    value: "9022",
    label: "University of Wisconsin - Green Bay",
    website: "http://www.uwgb.edu/",
  },
  {
    value: "9023",
    label: "University of Wisconsin - La Crosse",
    website: "http://www.uwlax.edu/",
  },
  {
    value: "9024",
    label: "University of Wisconsin - Madison",
    website: "http://www.wisc.edu/",
  },
  {
    value: "9025",
    label: "University of Wisconsin - Milwaukee",
    website: "http://www.uwm.edu/",
  },
  {
    value: "9026",
    label: "University of Wisconsin - Oshkosh",
    website: "http://www.uwosh.edu/",
  },
  {
    value: "9027",
    label: "University of Wisconsin - Parkside",
    website: "http://www.uwp.edu/",
  },
  {
    value: "9028",
    label: "University of Wisconsin - Platteville",
    website: "http://www.uwplatt.edu/",
  },
  {
    value: "9029",
    label: "University of Wisconsin - River Falls",
    website: "http://www.uwrf.edu/",
  },
  {
    value: "9030",
    label: "University of Wisconsin - Stevens Point",
    website: "http://www.uwsp.edu/",
  },
  {
    value: "9031",
    label: "University of Wisconsin - Stout",
    website: "http://www.uwstout.edu/",
  },
  {
    value: "9032",
    label: "University of Wisconsin - Superior",
    website: "http://www.uwsuper.edu/",
  },
  {
    value: "9033",
    label: "University of Wisconsin - Whitewater",
    website: "http://www.uww.edu/",
  },
  {
    value: "9034",
    label: "University of Wyoming",
    website: "http://www.uwyo.edu/",
  },
  {
    value: "9035",
    label: "Upper Iowa University",
    website: "http://www.uiu.edu/",
  },
  {
    value: "9036",
    label: "Urbana University",
    website: "http://www.urbana.edu/",
  },
  {
    value: "9037",
    label: "Ursinus College",
    website: "http://www.ursinus.edu/",
  },
  {
    value: "9038",
    label: "Ursuline College",
    website: "http://www.ursuline.edu/",
  },
  {
    value: "9039",
    label: "Utah State University",
    website: "http://www.usu.edu/",
  },
  {
    value: "9040",
    label: "Utah Valley State College",
    website: "http://www.uvsc.edu/",
  },
  {
    value: "9041",
    label: "Utica College",
    website: "http://www.ucsu.edu/",
  },
  {
    value: "9042",
    label: "Valdosta State University",
    website: "http://www.valdosta.edu/",
  },
  {
    value: "9043",
    label: "Valley City State University",
    website: "http://www.vcsu.nodak.edu/",
  },
  {
    value: "9044",
    label: "Valley Forge Christian College",
    website: "http://www.vfcc.edu/",
  },
  {
    value: "9045",
    label: "Valparaiso University",
    website: "http://www.valpo.edu/",
  },
  {
    value: "9046",
    label: "Vanderbilt University",
    website: "http://www.vanderbilt.edu/",
  },
  {
    value: "9047",
    label: "VanderCook College of Music",
    website: "http://www.vandercook.edu/",
  },
  {
    value: "9048",
    label: "Vanguard University of Southern California",
    website: "http://www.vanguard.edu/",
  },
  {
    value: "9049",
    label: "Vassar College",
    website: "http://www.vassar.edu/",
  },
  {
    value: "9050",
    label: "Vennard College",
    website: "http://www.vennard.edu/",
  },
  {
    value: "9051",
    label: "Vermont Law School",
    website: "http://www.vermontlaw.edu/",
  },
  {
    value: "9052",
    label: "Vermont Technical College",
    website: "http://www.vtc.vsc.edu/",
  },
  {
    value: "9053",
    label: "Villa Julie College",
    website: "http://www.vjc.edu/",
  },
  {
    value: "9054",
    label: "Villanova University",
    website: "http://www.villanova.edu/",
  },
  {
    value: "9055",
    label: "Virginia College",
    website: "http://www.vc.edu/",
  },
  {
    value: "9056",
    label: "Virginia Commonwealth University",
    website: "http://www.vcu.edu/",
  },
  {
    value: "9057",
    label: "Virginia Intermont College",
    website: "http://www.vic.edu/",
  },
  {
    value: "9058",
    label: "Virginia International University",
    website: "http://www.viu.edu/",
  },
  {
    value: "9059",
    label: "Virginia Military Institute",
    website: "http://www.vmi.edu/",
  },
  {
    value: "9060",
    label:
      "Virginia Polytechnic Institute and State University (Virginia Tech)",
    website: "http://www.vt.edu/",
  },
  {
    value: "9061",
    label: "Virginia State University",
    website: "http://www.vsu.edu/",
  },
  {
    value: "9062",
    label: "Virginia Union University",
    website: "http://www.vuu.edu/",
  },
  {
    value: "9063",
    label: "Virginia Wesleyan College",
    website: "http://www.vwc.edu/",
  },
  {
    value: "9064",
    label: "Viterbo College",
    website: "http://www.viterbo.edu/",
  },
  {
    value: "9065",
    label: "Voorhees College",
    website: "http://www.voorhees.edu/",
  },
  {
    value: "9066",
    label: "Wabash College",
    website: "http://www.wabash.edu/",
  },
  {
    value: "9067",
    label: "Wagner College",
    website: "http://www.wagner.edu/",
  },
  {
    value: "9068",
    label: "Wake Forest University",
    website: "http://www.wfu.edu/",
  },
  {
    value: "9069",
    label: "Walden University",
    website: "http://www.waldenu.edu/",
  },
  {
    value: "9070",
    label: "Walla Walla College",
    website: "http://www.wwc.edu/",
  },
  {
    value: "9071",
    label: "Walsh College of Accountancy and Business Administration",
    website: "http://www.walshcol.edu/",
  },
  {
    value: "9072",
    label: "Walsh University",
    website: "http://www.walsh.edu/",
  },
  {
    value: "9073",
    label: "Warner Pacific College",
    website: "http://www.warnerpacific.edu/",
  },
  {
    value: "9074",
    label: "Warner Southern College",
    website: "http://www.warner.edu/",
  },
  {
    value: "9075",
    label: "Warren Wilson College",
    website: "http://www.warren-wilson.edu/",
  },
  {
    value: "9076",
    label: "Wartburg College",
    website: "http://www.wartburg.edu/",
  },
  {
    value: "9077",
    label: "Washburn University",
    website: "http://www.washburn.edu/",
  },
  {
    value: "9078",
    label: "Washington and Lee University",
    website: "http://www.wlu.edu/",
  },
  {
    value: "9079",
    label: "Washington Bible College",
    website: "http://www.bible.edu/",
  },
  {
    value: "9080",
    label: "Washington College",
    website: "http://www.washcoll.edu/",
  },
  {
    value: "9081",
    label: "Washington State University",
    website: "http://www.wsu.edu/",
  },
  {
    value: "9082",
    label: "Washington State University, Spokane",
    website: "http://www.spokane.wsu.edu/",
  },
  {
    value: "9083",
    label: "Washington State University, Tri-Cities",
    website: "http://www.tricity.wsu.edu/",
  },
  {
    value: "9084",
    label: "Washington State University, Vancouver",
    website: "http://www.vancouver.wsu.edu/",
  },
  {
    value: "9085",
    label: "Washington University in St. Louis",
    website: "http://www.wustl.edu/",
  },
  {
    value: "9086",
    label: "Wayland Baptist University",
    website: "http://www.wbu.edu/",
  },
  {
    value: "9087",
    label: "Waynesburg College",
    website: "http://www.waynesburg.edu/",
  },
  {
    value: "9088",
    label: "Wayne State College",
    website: "http://www.wsc.edu/",
  },
  {
    value: "9089",
    label: "Wayne State University",
    website: "http://www.wayne.edu/",
  },
  {
    value: "9090",
    label: "Webber College",
    website: "http://www.webber.edu/",
  },
  {
    value: "9091",
    label: "Webb Institute",
    website: "http://www.webb-institute.edu/",
  },
  {
    value: "9092",
    label: "Weber State University",
    website: "http://www.weber.edu/",
  },
  {
    value: "9093",
    label: "Webster University",
    website: "http://www.webster.edu/",
  },
  {
    value: "9094",
    label: "Webster University North Florida",
    website: "http://www.webster.edu/jack/",
  },
  {
    value: "9095",
    label: "Weill Medical College of Cornell University",
    website: "http://www.med.cornell.edu/",
  },
  {
    value: "9096",
    label: "Wellesley College",
    website: "http://www.wellesley.edu/",
  },
  {
    value: "9097",
    label: "Wells College",
    website: "http://www.wells.edu/",
  },
  {
    value: "9098",
    label: "Wentworth Institute of Technology",
    website: "http://www.wit.edu/",
  },
  {
    value: "9099",
    label: "Wesleyan College",
    website: "http://www.wesleyan-college.edu/",
  },
  {
    value: "9100",
    label: "Wesleyan University",
    website: "http://www.wesleyan.edu/",
  },
  {
    value: "9101",
    label: "Wesley College",
    website: "http://www.wesley.edu/",
  },
  {
    value: "9102",
    label: "Wesley College Mississippi",
    website: "http://www.wesleycollege.com/",
  },
  {
    value: "9103",
    label: "Westbrook University ",
    website: "http://www.westbrooku.edu/",
  },
  {
    value: "9104",
    label: "West Chester University of Pennsylvania",
    website: "http://www.wcupa.edu/",
  },
  {
    value: "9105",
    label: "West Coast University",
    website: "http://www.westcoastuniversity.com/",
  },
  {
    value: "9106",
    label: "Western Baptist College",
    website: "http://www.wbc.edu/",
  },
  {
    value: "9107",
    label: "Western Bible College",
    website: "http://www.westernbible.edu/",
  },
  {
    value: "9108",
    label: "Western Carolina University",
    website: "http://www.wcu.edu/",
  },
  {
    value: "9109",
    label: "Western Connecticut State University",
    website: "http://www.wcsu.ctstateu.edu/",
  },
  {
    value: "9110",
    label: "Western Governors University",
    website: "http://www.wgu.edu/",
  },
  {
    value: "9111",
    label: "Western Illinois University",
    website: "http://www.wiu.edu/",
  },
  {
    value: "9112",
    label: "Western International University",
    website: "http://www.west.edu/",
  },
  {
    value: "9113",
    label: "Western Kentucky University",
    website: "http://www.wku.edu/",
  },
  {
    value: "9114",
    label: "Western Maryland College",
    website: "http://www.wmdc.edu/",
  },
  {
    value: "9115",
    label: "Western Michigan University",
    website: "http://www.wmich.edu/",
  },
  {
    value: "9116",
    label: "Western New England College",
    website: "http://www.wnec.edu/",
  },
  {
    value: "9117",
    label: "Western New Mexico University",
    website: "http://www.wnmu.edu/",
  },
  {
    value: "9118",
    label: "Western Oregon University",
    website: "http://www.wou.edu/",
  },
  {
    value: "9119",
    label: "Western State College",
    website: "http://www.western.edu/",
  },
  {
    value: "9120",
    label: "Western States Chiropractic College",
    website: "http://www.wschiro.edu/",
  },
  {
    value: "9121",
    label: "Western State University College of Law",
    website: "http://www.wsulaw.edu/",
  },
  {
    value: "9122",
    label: "Western State University College of Law - Orange County",
    website: "http://www.wsulaw.edu/",
  },
  {
    value: "9123",
    label: "Western Washington University",
    website: "http://www.wwu.edu/",
  },
  {
    value: "9124",
    label: "Westfield State College",
    website: "http://www.wsc.mass.edu/",
  },
  {
    value: "9125",
    label: "West Liberty State College",
    website: "http://www.wlsc.wvnet.edu/",
  },
  {
    value: "9126",
    label: "Westminster College Fulton",
    website: "http://www.wcmo.edu/",
  },
  {
    value: "9127",
    label: "Westminster College New Wilmington",
    website: "http://www.westminster.edu/",
  },
  {
    value: "9128",
    label: "Westminster College of Salt Lake City",
    website: "http://www.wcslc.edu/",
  },
  {
    value: "9129",
    label: "Westmont College",
    website: "http://www.westmont.edu/",
  },
  {
    value: "9130",
    label: "West Suburban College of Nursing",
    website: "http://www.curf.edu/~wscasseyp/wscn.htm",
  },
  {
    value: "9131",
    label: "West Texas A&M University",
    website: "http://www.wtamu.edu/",
  },
  {
    value: "9132",
    label: "West Virginia School of Osteopathic Medicine",
    website: "http://www.wvsom.edu/",
  },
  {
    value: "9133",
    label: "West Virginia State College",
    website: "http://www.wvsc.edu/",
  },
  {
    value: "9134",
    label: "West Virginia University",
    website: "http://www.wvu.edu/",
  },
  {
    value: "9135",
    label: "West Virginia University Institute of Technology",
    website: "http://wvit.wvnet.edu/",
  },
  {
    value: "9136",
    label: "West Virginia Wesleyan College",
    website: "http://www.wvwc.edu/",
  },
  {
    value: "9137",
    label: "Westwood College",
    website: "http://www.westwood.edu/",
  },
  {
    value: "9138",
    label: "Wheaton College Massachusetts",
    website: "http://www.wheatonma.edu/",
  },
  {
    value: "9139",
    label: "Wheeling Jesuit University",
    website: "http://www.wju.edu/",
  },
  {
    value: "9140",
    label: "Wheelock College",
    website: "http://www.wheelock.edu/",
  },
  {
    value: "9141",
    label: "Whitman College",
    website: "http://www.whitman.edu/",
  },
  {
    value: "9142",
    label: "Whittier College",
    website: "http://www.whittier.edu/",
  },
  {
    value: "9143",
    label: "Whitworth College",
    website: "http://www.whitworth.edu/",
  },
  {
    value: "9144",
    label: "Wichita State University",
    website: "http://www.twsu.edu/",
  },
  {
    value: "9145",
    label: "Widener University",
    website: "http://www.widener.edu/",
  },
  {
    value: "9146",
    label: "Wilberforce University",
    website: "http://www.wilberforce.edu/",
  },
  {
    value: "9147",
    label: "Wilbur Wright College",
    website: "http://www.ccc.edu/wright/",
  },
  {
    value: "9148",
    label: "Wiley College",
    website: "http://www.wileyc.edu/",
  },
  {
    value: "9149",
    label: "Wilkes University",
    website: "http://www.wilkes.edu/",
  },
  {
    value: "9150",
    label: "Willamette University",
    website: "http://www.willamette.edu/",
  },
  {
    value: "9151",
    label: "William Carey College",
    website: "http://www.wmcarey.edu/",
  },
  {
    value: "9152",
    label: "William Jewell College",
    website: "http://www.jewell.edu/",
  },
  {
    value: "9153",
    label: "William Mitchell College of Law",
    website: "http://www.wmitchell.edu/",
  },
  {
    value: "9154",
    label: "William Paterson University",
    website: "http://www.wpunj.edu/",
  },
  {
    value: "9155",
    label: "William Penn College",
    website: "http://www.wmpenn.edu/",
  },
  {
    value: "9156",
    label: "Williams Baptist College",
    website: "http://www.wbcoll.edu/",
  },
  {
    value: "9157",
    label: "Williams College",
    website: "http://www.williams.edu/",
  },
  {
    value: "9158",
    label: "William Tyndale College",
    website: "http://www.williamtyndale.edu/",
  },
  {
    value: "9159",
    label: "William Woods University",
    website: "http://www.wmwoods.edu/",
  },
  {
    value: "9160",
    label: "Wilmington College",
    website: "http://www.wilmcoll.edu/",
  },
  {
    value: "9161",
    label: "Wilmington College",
    website: "http://www.wilmington.edu/",
  },
  {
    value: "9162",
    label: "Wilson College",
    website: "http://www.wilson.edu/",
  },
  {
    value: "9163",
    label: "Wingate University",
    website: "http://www.wingate.edu/",
  },
  {
    value: "9164",
    label: "Winona State University",
    website: "http://www.winona.msus.edu/",
  },
  {
    value: "9165",
    label: "Winston-Salem State University",
    website: "http://www.wssu.edu/",
  },
  {
    value: "9166",
    label: "Winthrop University",
    website: "http://www.winthrop.edu/",
  },
  {
    value: "9167",
    label: "Wisconsin Lutheran College",
    website: "http://www.wlc.edu/",
  },
  {
    value: "9168",
    label: "Wisconsin School of Professional Psychology",
    website: "http://www.execpc.com/~wspp/",
  },
  {
    value: "9169",
    label: "Wittenberg University",
    website: "http://www.wittenberg.edu/",
  },
  {
    value: "9170",
    label: "Wofford College",
    website: "http://www.wofford.edu/",
  },
  {
    value: "9171",
    label: "Woodbury University",
    website: "http://www.woodburyu.edu/",
  },
  {
    value: "9172",
    label: "Worcester Polytechnic Institute",
    website: "http://www.wpi.edu/",
  },
  {
    value: "9173",
    label: "Worcester State College",
    website: "http://www.worc.mass.edu/",
  },
  {
    value: "9174",
    label: "Wright Institute",
    website: "http://www.wrightinst.edu/",
  },
  {
    value: "9175",
    label: "Wright State University",
    website: "http://www.wright.edu/",
  },
  {
    value: "9176",
    label: "Xavier University",
    website: "http://www.xu.edu/",
  },
  {
    value: "9177",
    label: "Xavier University of Louisiana",
    website: "http://www.xula.edu/",
  },
  {
    value: "9178",
    label: "Yale University",
    website: "http://www.yale.edu/",
  },
  {
    value: "9179",
    label: "Yeshiva University",
    website: "http://www.yu.edu/",
  },
  {
    value: "9180",
    label: "York College Nebraska",
    website: "http://www.york.edu/",
  },
  {
    value: "9181",
    label: "York College of Pennsylvania",
    website: "http://www.yorkcol.edu/",
  },
  {
    value: "9182",
    label: "Yorker International University",
    website: "http://www.nyuniversity.net/",
  },
  {
    value: "9183",
    label: "York University",
    website: "http://www.yorkuniversity.us/",
  },
  {
    value: "9184",
    label: "Youngstown State University",
    website: "http://www.ysu.edu/",
  },
  {
    value: "9185",
    label:
      "Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh",
    website: "http://www.claeh.edu.uy/",
  },
  {
    value: "9186",
    label: "Universidad Católica del Uruguay",
    website: "http://www.ucu.edu.uy/",
  },
  {
    value: "9187",
    label: "Universidad de la Empresa (UDE)",
    website: "http://www.ude.edu.uy/",
  },
  {
    value: "9188",
    label: "Universidad de la República",
    website: "http://www.universidad.edu.uy/",
  },
  {
    value: "9189",
    label: "Universidad de Montevideo",
    website: "http://www.um.edu.uy/",
  },
  {
    value: "9190",
    label: "Universidad ORT Uruguay",
    website: "http://www.ort.edu.uy/",
  },
  {
    value: "9191",
    label: "Ferghana Politechnical Institute",
    website: "http://ferpi.dem.ru/",
  },
  {
    value: "9192",
    label: "International Business School Kelajak ILMI",
    website: "http://www.ibs.uz/",
  },
  {
    value: "9193",
    label: "Kokand State Pedagogical Institute",
    website: "http://www.qdpi.uz/",
  },
  {
    value: "9194",
    label: "Management Development Institute of Singapore, Tashkent",
    website: "http://www.mdis.uz/",
  },
  {
    value: "9195",
    label: "National University of Uzbekistan",
    website: "http://www.nuu.uz/",
  },
  {
    value: "9196",
    label: "Nukus State Teachers Training Institute",
    website: "http://www.ndpi.uz/",
  },
  {
    value: "9197",
    label: "Samarkand State University",
    website: "http://www.samdu.uz/",
  },
  {
    value: "9198",
    label: "Tashkent Automobile and Roads Institute",
    website: "http://www.tayi.uz/",
  },
  {
    value: "9199",
    label: "Tashkent Institute of Irrigation and Melioration",
    website: "http://www.tiim.uz/",
  },
  {
    value: "9200",
    label: "Tashkent Islam University",
    website: "http://www.tiu.uz/",
  },
  {
    value: "9201",
    label: "Tashkent Medical Academy",
    website: "http://www.tma.uz/",
  },
  {
    value: "9202",
    label: "Tashkent Pharmaceutical Institute",
    website: "http://www.pharmi.uz/",
  },
  {
    value: "9203",
    label: "Tashkent School of Finance",
    website: "http://www.tfi.uz/",
  },
  {
    value: "9204",
    label: "Tashkent State Agrarian University",
    website: "http://www.agrar.uz/",
  },
  {
    value: "9205",
    label: "Tashkent State Technical University",
    website: "http://www.tdtu.uz/",
  },
  {
    value: "9206",
    label: "Tashkent State University of Economics",
    website: "http://www.tdiu.uz/",
  },
  {
    value: "9207",
    label: "Tashkent State University of Oriental Studies ",
    website: "http://www.tashgiv.uz/",
  },
  {
    value: "9208",
    label: "Tashkent University of Information Technologies",
    website: "http://www.tuit.uz/",
  },
  {
    value: "9209",
    label: "Turin Polytechnic University In Tashkent",
    website: "http://www.polito.uz/",
  },
  {
    value: "9210",
    label: "University of World Economy and Diplomacy",
    website: "http://www.uwed.uz/",
  },
  {
    value: "9211",
    label: "Uzbek State World Languages University",
    website: "http://www.uzswlu.uz/",
  },
  {
    value: "9212",
    label: "Westminster International University in Tashkent",
    website: "http://www.wiut.uz/",
  },
  {
    value: "9213",
    label: "Athenaeum Pontificium Regina Apostolorum",
    website: "http://www.upra.org/",
  },
  {
    value: "9214",
    label: "Pontifcia Università Gregoriana",
    website: "http://www.unigre.urbe.it/",
  },
  {
    value: "9215",
    label: "Pontifcia Universitas a S.Thomas Aquinate in Urbe",
    website: "http://www.pust.urbe.it/",
  },
  {
    value: "9216",
    label: "Pontifcia Universitas Lateranensis",
    website: "http://www.pul.it/",
  },
  {
    value: "9217",
    label: "Pontifcia Università Urbaniana",
    website: "http://www.urbaniana.edu/",
  },
  {
    value: "9218",
    label: "Università Pontifcia Salesiana",
    website: "http://www.ups.urbe.it/",
  },
  {
    value: "9219",
    label: "Trinity University School of Medicine",
    website: "http://www.tusom.org/",
  },
  {
    value: "9220",
    label: "Universidad Bicentenaria de Aragua",
    website: "http://www.uba.edu.ve/",
  },
  {
    value: "9221",
    label: "Universidad Bolivariana de Venezuela",
    website: "http://www.ubv.edu.ve/",
  },
  {
    value: "9222",
    label: "Universidad Católica Andres Bello",
    website: "http://www.ucab.edu.ve/",
  },
  {
    value: "9223",
    label: "Universidad Católica Cecilio Acosta",
    website: "http://www.unica.edu.ve/",
  },
  {
    value: "9224",
    label: "Universidad Católica del Táchira",
    website: "http://www.ucat.edu.ve/",
  },
  {
    value: "9225",
    label: "Universidad Central de Venezuela",
    website: "http://www.ucv.ve/",
  },
  {
    value: "9226",
    label: "Universidad Centro Occidental Lisandro Alvarado",
    website: "http://www.ucla.edu.ve/",
  },
  {
    value: "9227",
    label: "Universidad de Carabobo",
    website: "http://www.uc.edu.ve/",
  },
  {
    value: "9228",
    label: "Universidad de Los Andes",
    website: "http://www.ula.ve/",
  },
  {
    value: "9229",
    label: "Universidad del Zulia",
    website: "http://www.luz.ve/index2.html",
  },
  {
    value: "9230",
    label: "Universidad de Oriente",
    website: "http://www.udo.edu.ve/",
  },
  {
    value: "9231",
    label: "Universidad Dr. Rafael Belloso Chacín",
    website: "http://www.urbe.edu/",
  },
  {
    value: "9232",
    label: "Universidad Fermin Toro",
    website: "http://www.uft.edu.ve/",
  },
  {
    value: "9233",
    label: "Universidad Gran Mariscal de Ayacucho",
    website: "http://www.ugma.edu.ve/",
  },
  {
    value: "9234",
    label: "Universidad José Antonio Páez",
    website: "http://www.ujap.edu.ve/",
  },
  {
    value: "9235",
    label: "Universidad José Maria Vargas",
    website: "http://www.ujmv.edu/",
  },
  {
    value: "9236",
    label: "Universidad Metropolitana",
    website: "http://www.unimet.edu.ve/",
  },
  {
    value: "9237",
    label: "Universidad Monteávila",
    website: "http://www.universidad-monteavila.edu.ve/",
  },
  {
    value: "9238",
    label: "Universidad Nacional Abierta",
    website: "http://www.una.edu.ve/",
  },
  {
    value: "9239",
    label: "Universidad Nacional Experimental de Guayana",
    website: "http://www.uneg.edu.ve/",
  },
  {
    value: "9240",
    label: "Universidad Nacional Experimental de la Fuerza Armada",
    website: "http://www.unefa.edu.ve/",
  },
  {
    value: "9241",
    label:
      'Universidad Nacional Experimental de los Llanos Occidentales "Ezequiel Zamora"',
    website: "http://www.unellez.edu.ve/",
  },
  {
    value: "9242",
    label: "Universidad Nacional Experimental del Táchira",
    website: "http://www.unet.edu.ve/",
  },
  {
    value: "9243",
    label: 'Universidad Nacional Experimental "Francisco de Miranda"',
    website: "http://www.unefm.edu.ve/",
  },
  {
    value: "9244",
    label:
      'Universidad Nacional Experimental Politécnica "Antonio José de Sucre"',
    website: "http://www.unexpo.edu.ve/",
  },
  {
    value: "9245",
    label: 'Universidad Nacional Experimental "Rafael Maria Baralt"',
    website: "http://www.unermb.edu.ve/",
  },
  {
    value: "9246",
    label: "Universidad Nacional Experimental Romulo Gallegos",
    website: "http://www.unerg.edu.ve/",
  },
  {
    value: "9247",
    label: 'Universidad Nacional Experimental "Simón Rodriguez"',
    website: "http://www.unesr.edu.ve/",
  },
  {
    value: "9248",
    label:
      'Universidad Nacional Experimental Sur del Lago "Jesús Maria Semprum"',
    website: "http://www.unesur.edu.ve/",
  },
  {
    value: "9249",
    label: "Universidad Nueva Esparta",
    website: "http://www.une.edu.ve/",
  },
  {
    value: "9250",
    label: "Universidad Pedagógica Experimental Libertador",
    website: "http://www.upel.edu.ve/",
  },
  {
    value: "9251",
    label: "Universidad Rafael Urdaneta",
    website: "http://www.uru.edu/",
  },
  {
    value: "9252",
    label: "Universidad Santa Maria Caracas",
    website: "http://usm.trompo.com/",
  },
  {
    value: "9253",
    label: "Universidad Simón Bolivar",
    website: "http://www.usb.ve/",
  },
  {
    value: "9254",
    label: "Universidad Tecnológica del Centro",
    website: "http://www.unitec.edu.ve/",
  },
  {
    value: "9255",
    label: "Universidad Valle del Momboy",
    website: "http://www.uvm.edu.ve/",
  },
  {
    value: "9256",
    label: "Universidad Yacambu",
    website: "http://www.yacambu.edu.ve/",
  },
  {
    value: "9257",
    label: "University of the Virgin Islands",
    website: "http://www.uvi.edu/",
  },
  {
    value: "9258",
    label: "Banking University of Ho Chi Minh City",
    website: "http://www.buh.edu.vn/",
  },
  {
    value: "9259",
    label: "Binh Duong University",
    website: "http://www.bdu.edu.vn/",
  },
  {
    value: "9260",
    label: "Can-Tho University",
    website: "http://www.ctu.edu.vn/",
  },
  {
    value: "9261",
    label: "Danang College Of Technology",
    website: "http://www.dct.udn.vn/",
  },
  {
    value: "9262",
    label: "Foreign Trade University",
    website: "http://www.ftu.edu.vn/",
  },
  {
    value: "9263",
    label: "FPT University",
    website: "http://www.fpt.edu.vn/",
  },
  {
    value: "9264",
    label: "Hai Duong University",
    website: "http://www.uhd.edu.vn/",
  },
  {
    value: "9265",
    label: "Hanoi Medical University",
    website: "http://www.hmu.edu.vn/",
  },
  {
    value: "9266",
    label: "Hanoi National Economics University",
    website: "http://www.neu.edu.vn/",
  },
  {
    value: "9267",
    label: "Hanoi Open University",
    website: "http://www.hou.edu.vn/",
  },
  {
    value: "9268",
    label: "Hanoi University of Architecture",
    website: "http://www.hau.edu.vn/",
  },
  {
    value: "9269",
    label: "Hanoi University of Civil Engineering",
    website: "http://www.dhxd.edu.vn/",
  },
  {
    value: "9270",
    label: "Hanoi University of Mining and Geology",
    website: "http://www.humg.edu.vn/",
  },
  {
    value: "9271",
    label: "Hanoi University of Science",
    website: "http://www.hus.edu.vn/",
  },
  {
    value: "9272",
    label: "Hanoi University of Science & Technology",
    website: "http://www.hust.edu.vn/",
  },
  {
    value: "9273",
    label: "Ho Chi Minh City Open University",
    website: "http://www.ou.edu.vn/",
  },
  {
    value: "9274",
    label: "Ho Chi Minh City University of Agriculture and Forestry",
    website: "http://www.hcmuaf.edu.vn/",
  },
  {
    value: "9275",
    label: "Ho Chi Minh City University of Architecture",
    website: "http://www.uah.edu.vn/",
  },
  {
    value: "9276",
    label: "Ho Chi Minh City University of Economics",
    website: "http://www.ueh.edu.vn/",
  },
  {
    value: "9277",
    label:
      "Ho Chi Minh City University of Foreign Languages and Information Technology",
    website: "http://www.huflit.edu.vn/",
  },
  {
    value: "9278",
    label: "Ho Chi Minh City University of Law",
    website: "http://www.hcmulaw.edu.vn/",
  },
  {
    value: "9279",
    label: "Ho Chi Minh City University of Medicine and Pharmacy",
    website: "http://www.yds.edu.vn/",
  },
  {
    value: "9280",
    label: "Ho Chi Minh City University of Natural Sciences",
    website: "http://www.hcmuns.edu.vn/",
  },
  {
    value: "9281",
    label: "Ho Chi Minh City University of Pedagogics",
    website: "http://www.hcmup.edu.vn/",
  },
  {
    value: "9282",
    label: "Ho Chi Minh City University of Social Sciences and Humanities",
    website: "http://www.hcmussh.edu.vn/",
  },
  {
    value: "9283",
    label: "Ho Chi Minh City University of Technology",
    website: "http://www.hcmut.edu.vn/",
  },
  {
    value: "9284",
    label: "Ho Chi Minh City University of Transport",
    website: "http://www.hcmutrans.edu.vn/",
  },
  {
    value: "9285",
    label: "Hong Bang University International",
    website: "http://www.hbu.edu.vn/",
  },
  {
    value: "9286",
    label: "Hue University",
    website: "http://www.hueuni.edu.vn/",
  },
  {
    value: "9287",
    label: "Hue University of Agriculture and Forestry ",
    website: "http://www.huaf.edu.vn/",
  },
  {
    value: "9288",
    label: "Institute of Finance",
    website: "http://www.hvtc.edu.vn/",
  },
  {
    value: "9289",
    label: "Posts & Telecommunications Institute of Technology",
    website: "http://www.ptit.edu.vn/",
  },
  {
    value: "9290",
    label: "RMIT International University Vietnam",
    website: "http://www.rmit.edu.vn/",
  },
  {
    value: "9291",
    label: "Saigon University",
    website: "http://www.sgu.edu.vn/",
  },
  {
    value: "9292",
    label: "Tay Nguyen University",
    website: "http://www.taynguyenuni.edu.vn/",
  },
  {
    value: "9293",
    label: "Thainguyen University of Agriculture and Forestry",
    website: "http://www.tuaf.edu.vn/",
  },
  {
    value: "9294",
    label: "University of Da Lat",
    website: "http://www.dlu.edu.vn/",
  },
  {
    value: "9295",
    label: "University of Da Nang",
    website: "http://www.udn.vn/",
  },
  {
    value: "9296",
    label: "University of Technical Education Ho Chi Minh City",
    website: "http://www.hcmute.edu.vn/",
  },
  {
    value: "9297",
    label: "University of Transport and Communications",
    website: "http://www.utc.edu.vn/",
  },
  {
    value: "9298",
    label: "Vietnam Maritime University",
    website: "http://www.vimaru.edu.vn/",
  },
  {
    value: "9299",
    label: "Vietnam National University Hanoi",
    website: "http://www.vnu.edu.vn/",
  },
  {
    value: "9300",
    label: "Vietnam National University Ho Chi Minh City",
    website: "http://www.vnuhcm.edu.vn/",
  },
  {
    value: "9301",
    label: "Vietnam National University of Agriculture",
    website: "http://www.vnua.edu.vn/",
  },
  {
    value: "9302",
    label: "Water Resources University",
    website: "http://www.hwru.edu.vn/",
  },
  {
    value: "9303",
    label: "National University of Samoa",
    website: "http://www.nus.edu.ws/",
  },
  {
    value: "9304",
    label: "University of the South Pacific School of Agriculture",
    website: "http://www.usp.ac.fj/wwwsoa/",
  },
  {
    value: "9305",
    label: "Alahgaff University",
    website: "http://www.ahgaff.edu/",
  },
  {
    value: "9306",
    label: "Al-Eman University",
    website: "http://www.jameataleman.org/",
  },
  {
    value: "9307",
    label: "Al-Nasser University",
    website: "http://www.al-edu.com/",
  },
  {
    value: "9308",
    label: "Hadhramout University of Science and Technology ",
    website: "http://www.hust.edu.ye/",
  },
  {
    value: "9309",
    label: "Hodeidah University",
    website: "http://www.hoduniv.net.ye/",
  },
  {
    value: "9310",
    label: "Queen Arwa University",
    website: "http://www.arwauniversity.org/",
  },
  {
    value: "9311",
    label: "Saba University",
    website: "http://www.sabauni.net/",
  },
  {
    value: "9312",
    label: "Sana'a University",
    website: "http://www.su.edu.ye/",
  },
  {
    value: "9313",
    label: "Taiz University",
    website: "http://www.taiz.edu.ye/",
  },
  {
    value: "9314",
    label: "Thamar University",
    website: "http://www.thuniv.net/",
  },
  {
    value: "9315",
    label: "University of Aden",
    website: "http://www.aden-univ.net/",
  },
  {
    value: "9316",
    label: "University of Modern Sciences",
    website: "http://www.ums-edu.com/",
  },
  {
    value: "9317",
    label: "University of Science and Technology Sana'a",
    website: "http://www.ust.edu/",
  },
  {
    value: "9318",
    label: "Yemenia University",
    website: "http://www.yuniv.net/",
  },
  {
    value: "9319",
    label: "Cape Peninsula University of Technology",
    website: "http://www.cput.ac.za/",
  },
  {
    value: "9320",
    label: "Central University of Technology, Free State",
    website: "http://www.cut.ac.za/",
  },
  {
    value: "9321",
    label: "Durban Institute of Technology",
    website: "http://www.dit.ac.za/",
  },
  {
    value: "9322",
    label: "Nelson Mandela Metropolitan University",
    website: "http://www.nmmu.ac.za/",
  },
  {
    value: "9323",
    label: "Rhodes University",
    website: "http://www.ru.ac.za/",
  },
  {
    value: "9324",
    label: "Tshwane University of Technology",
    website: "http://www.tut.ac.za/",
  },
  {
    value: "9325",
    label: "University of Cape Town",
    website: "http://www.uct.ac.za/",
  },
  {
    value: "9326",
    label: "University of Fort Hare",
    website: "http://www.ufh.ac.za/",
  },
  {
    value: "9327",
    label: "University of Johannesburg",
    website: "http://www.uj.ac.za/",
  },
  {
    value: "9328",
    label: "University of KwaZulu-Natal",
    website: "http://www.ukzn.ac.za/",
  },
  {
    value: "9329",
    label: "University of Limpopo",
    website: "http://www.ul.ac.za/",
  },
  {
    value: "9330",
    label: "University of North West",
    website: "http://www.uniwest.ac.za/",
  },
  {
    value: "9331",
    label: "University of Pretoria",
    website: "http://www.up.ac.za/",
  },
  {
    value: "9332",
    label: "University of South Africa",
    website: "http://www.unisa.ac.za/",
  },
  {
    value: "9333",
    label: "University of Stellenbosch",
    website: "http://www.sun.ac.za/",
  },
  {
    value: "9334",
    label: "University of the Free State",
    website: "http://www.uovs.ac.za/",
  },
  {
    value: "9335",
    label: "University of the Western Cape",
    website: "http://www.uwc.ac.za/",
  },
  {
    value: "9336",
    label: "University of Venda",
    website: "http://www.univen.ac.za/",
  },
  {
    value: "9337",
    label: "University of Witwatersrand",
    website: "http://www.wits.ac.za/",
  },
  {
    value: "9338",
    label: "University of Zululand",
    website: "http://www.uzulu.ac.za/",
  },
  {
    value: "9339",
    label: "Vaal University of Technology",
    website: "http://www.vut.ac.za/",
  },
  {
    value: "9340",
    label: "Walter Sisulu University for Technology and Science",
    website: "http://www.wsu.ac.za/",
  },
  {
    value: "9341",
    label: "Cavendish University",
    website: "http://www.cavendishza.org/",
  },
  {
    value: "9342",
    label: "Copperbelt University",
    website: "http://www.cbu.edu.zm/",
  },
  {
    value: "9343",
    label: "Mulungushi University",
    website: "http://www.mu.ac.zm/",
  },
  {
    value: "9344",
    label: "Northrise University",
    website: "http://www.northrise.org/",
  },
  {
    value: "9345",
    label: "Rusangu University",
    website: "http://www.rusangu-university.edu.zm/",
  },
  {
    value: "9346",
    label: "University of Lusaka",
    website: "http://www.unilus.ac.zm/",
  },
  {
    value: "9347",
    label: "University of Zambia",
    website: "http://www.unza.zm/",
  },
  {
    value: "9348",
    label: "Zambian Open University",
    website: "http://www.zaou.ac.zm/",
  },
  {
    value: "9349",
    label: "Africa University",
    website: "http://www.africau.edu/",
  },
  {
    value: "9350",
    label: "Bindura University of Science Education",
    website: "http://www.buse.ac.zw/",
  },
  {
    value: "9351",
    label: "Catholic University in Zimbabwe",
    website: "http://www.cuz.ac.zw/",
  },
  {
    value: "9352",
    label: "Chinhoyi University of Technology",
    website: "http://www.cut.ac.zw/",
  },
  {
    value: "9353",
    label: "Great Zimbabwe University",
    website: "http://www.gzu.ac.zw/",
  },
  {
    value: "9354",
    label: "Harare Institute of Technology",
    website: "http://www.hit.ac.zw/",
  },
  {
    value: "9355",
    label: "Lupane State University",
    website: "http://www.lsu.ac.zw/",
  },
  {
    value: "9356",
    label: "Midlands State University",
    website: "http://www.msu.ac.zw/",
  },
  {
    value: "9357",
    label: "National University of Science and Technology Bulawayo",
    website: "http://www.nust.ac.zw/",
  },
  {
    value: "9358",
    label: "Reformed Church University",
    website: "http://www.rcu.ac.zw/",
  },
  {
    value: "9359",
    label: "Solusi University",
    website: "http://www.solusi.ac.zw/",
  },
  {
    value: "9360",
    label: "University of Zimbabwe",
    website: "http://www.uz.ac.zw/",
  },
  {
    value: "9361",
    label: "Women's University in Africa",
    website: "http://www.wua.ac.zw/",
  },
  {
    value: "9362",
    label: "Zimbabwe Ezekiel Guti University",
    website: "http://www.zegu.ac.zw/",
  },
  {
    value: "9363",
    label: "Zimbabwe Open University",
    website: "http://www.zou.ac.zw/",
  },
];

export default Colleges;
