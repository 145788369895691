const OlympiadsTypes11_12th = [
  { label: "Coding", value: "1" },
  { label: "Physics", value: "2" },
  { label: "Finance", value: "3" },
  { label: "AI", value: "4" },
  { label: "Mathematics", value: "5" },
  { label: "Astronomy", value: "6" },
  { label: "Biology", value: "7" },
  { label: "Website development", value: "8" },
  { label: "App development", value: "9" },
  { label: "English Literature", value: "10" },
  { label: "Hindi Literature", value: "11" },
  { label: "Art", value: "12" },
  { label: "History", value: "13" },
  { label: "Political science / Civics", value: "14" },
];

export default OlympiadsTypes11_12th;
