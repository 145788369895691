import React from "react";
import { useLocation } from "react-router-dom";
import OpportunityImg from "../../../../assets/OpportunityImg.png";
import "./index.css";
const OpportunityDetail = () => {
  const location = useLocation();
  const title = location.state?.title || "";
  const loc = location.state?.loc || "";
  const desc = location.state?.desc || "";
  return (
    <div className="oppsDetailsWrapper">
      <div className="oppsDetailsHeading">Opportunity Details</div>
      <div style={{ width: "50%" }}>
        <img style={{ width: "50%" }} src={OpportunityImg} alt="/" />
      </div>
      <div className="oppDetailRow">
        <div className="oppField">Title : </div>
        <div className="fieldInfo">{title}</div>
      </div>
      <div className="oppDetail">
        <div className="oppField">Location :</div>
        <div className="fieldInfo">{loc}</div>
      </div>
      <div className="oppDetailRow">
        <div className="oppField">Description :</div>
        <div className="fieldInfo">{desc}</div>
      </div>
      <div className="oppDetailRow">
        <div className="oppField">Benifits :</div>
        <div className="fieldInfo">
          • Fresh Perspectives: Interns bring new ideas and creative solutions
          to projects.
          <br />
          • Tech-Savviness: Interns offer valuable digital skills and insights.
          <br /> • Eagerness to Learn: Interns are motivated to grow and adapt
          in their roles.
          <br />• Flexibility and Adaptability: Interns can tackle a variety of
          tasks with enthusiasm.
          <br /> • Support for Special Projects: Interns provide extra manpower
          for key initiatives.
          <br /> • Diverse Perspectives: Interns contribute diverse backgrounds
          and viewpoints.
          <br /> • Succession Planning: Internship programs build a talent
          pipeline for future hires.
          <br /> • Community Engagement: Hosting interns enhances the
          organization's reputation and fosters community relationships.
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetail;
