import React from "react";
import Navbar from "../Navbar";
import Landing from "../Landing";

const Blogs = () => {
  return (
    <div className="homeWrapper">
      <div className="homeNavbar">
        <Navbar></Navbar>
      </div>
      <div className="homeLanding">
        <Landing
          heading1="Find Informative Blogs about opportunities and upskilling on our domain"
          heading2="Coming Soon"
          // buttonName="View Blogs"
          // buttonNav="View Blogs"
        ></Landing>
      </div>
    </div>
  );
};

export default Blogs;
