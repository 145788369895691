import React, { useState } from "react";
import Header from "../../Headers/HeaderForms";
import Footer from "../../Footer/Footer.js";
import Dropdown from "../../Usables/Dropdown/Dropdown.js";
import "./ninetenth.css";
import CurrentClass from "../../options/CurrentClass910.js";
import SchoolBoard from "../../options/schoolBoard.js";
import Olympiads from "../../options/Olympiads.js";
import Streams from "../../options/Streams.js";
import Opportunities from "../../options/OpportunitiesTypes8th.js";
import Subjects from "../../options/InterestedSubjects8th.js";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth.js";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../api/axios.js";
import MultiSelectDropdown from "../../Usables/MultiSelectDropdown/index.js";

const Class9Form = () => {
  // API VARIABLES
  const navigate = useNavigate();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";

  // variables
  const [currentclass, setClass] = useState(null);
  const [schoolBoardLabel, setBoardLabel] = useState(null);
  const [schoolBoardValue, setBoardValue] = useState(null);
  const [streamLabel, setStreamLabel] = useState(null);
  const [streamValue, setStreamValue] = useState(null);
  const [olympiads, setOlympiads] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const handleClass = (e) => {
    setClass(e.value);
  };

  const handleBoard = (e) => {
    let ind = parseInt(e.value);
    console.log(SchoolBoard[ind - 1].label);
    setBoardLabel(SchoolBoard[ind - 1].label);
    setBoardValue(e.value);
  };

  const handleStream = (e) => {
    let ind = parseInt(e.value);
    console.log(Streams[ind - 1].label);
    setStreamLabel(Streams[ind - 1].label);
    setStreamValue(e.value);
  };

  const handleOlympiads = (options) => {
    setOlympiads(options);
  };

  const handleOpportunities = (options) => {
    setOpportunities(options);
  };

  const handleSubjects = (options) => {
    setSubjects(options);
  };

  const getLabelsAndValues = (arr) => {
    let labels = [];
    let values = [];
    for (let i = 0; i < arr.length; i++) {
      labels.push(arr[i].label);
      values.push(arr[i].value);
    }
    return { labels, values };
  };

  const { labels: OlympiadLabels, values: OlympiadValues } =
    getLabelsAndValues(olympiads);

  const { labels: SubjectLabels, values: SubjectValues } =
    getLabelsAndValues(subjects);

  const { labels: OpportunityLabels, values: OpportunityValues } =
    getLabelsAndValues(opportunities);

  const handleClick = async () => {
    try {
      const userDetail = await axios.get("/api/getuserdata", {
        params: {
          userID: auth.userID,
        },
      });
      const userData = userDetail.data;
      console.log("userDetail is ", userData);
      const data = {
        userID: JSON.stringify(auth.userID),
        currentclass: JSON.stringify(currentclass),
        schoolboard: JSON.stringify(schoolBoardValue),
        olympiads: JSON.stringify(OlympiadValues),
        opportunities: JSON.stringify(OpportunityValues),
        subjects: JSON.stringify(SubjectValues),
        futurestream: JSON.stringify(streamValue),
      };
      console.log("all data is", data);
      //const response = await axios.post("/api/bachelors", data);
      // Handle the response as needed
      const controller = new AbortController();
      const response1 = await axiosPrivate.post("/api/ninetenth", data, {
        signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      });

      if (response1.ok) {
        const responseData = await response1.json();
        console.log(
          "Class 9-10th user details saved successfully:",
          responseData
        );
      } else {
        console.log("The current data state is :", response1.statusText);
      }
      await axiosPrivate.post(
        SAVE_LOGIN_ROUTE,
        {
          userID: auth.userID,
          loginroute: "profile",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );
      fetchOpportunities(userData);
      navigate("/profile");
      controller.abort();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOpportunities = async (userData) => {
    try {
      const gender = userData.gender;
      const country = userData.country;
      const response = await axios.post(
        "https://opgururag.azurewebsites.net/search",
        {
          query: `Find ten different opportunities that best match the profile of a student who is currently in School. The student is a ${gender}, studying in class ${currentclass} from the ${schoolBoardLabel} board. The student is from ${country} and their fields of interest for future opportunities include ${JSON.stringify(
            OpportunityLabels
          )}. For high/senior secondary education, student will opt future stream as ${streamLabel}. In the past, the student has participated in the following Olympiads: ${JSON.stringify(
            OlympiadLabels
          )} and is interested in studying the following subjects ${JSON.stringify(
            SubjectLabels
          )} Please find opportunities including competetions, olympiads, scholarships, fellowships for the student according to his profile. Provide maximum information for each opportunity, including the title, application deadline, eligibility criteria (such as age limits, % marks, eligible regions ), location/country, description, and type of opportunity. Return the results in a structured format for easy parsing and integration.`,
          userID: auth.userID,
        }
      );
      console.log("RESPONSE DATA AFTER RAG IS  =>", response.data);
      const finalData = response.data;
      console.log("finalData is ", finalData);
    } catch (error) {
      console.error("Error fetching Opportunities:", error);
    }
  };

  return (
    <div className="userFormWrapper">
      <Header></Header>
      <div className="studentFormWrapper">
        <div className="formHeading">
          Registration Form for Upto Class 9th-10th
        </div>
        <div className="formMessage">
          Enter all Your personal details mention below
        </div>
        <form style={{ width: "100%" }}>
          <div className="inputForm">
            <Dropdown
              name="class"
              value={currentclass}
              options={CurrentClass}
              placeHolder="Current Class"
              onChange={handleClass}
            ></Dropdown>
          </div>
          <div className="inputForm Board">
            <Dropdown
              name="schoolboard"
              value={schoolBoardLabel}
              options={SchoolBoard}
              placeHolder="School Board"
              onChange={handleBoard}
            ></Dropdown>
          </div>
          <div className="inputForm Streams">
            <Dropdown
              name="streams"
              value={streamLabel}
              options={Streams}
              placeHolder="Preffered Future Stream"
              onChange={handleStream}
            ></Dropdown>
          </div>
          <div className="inputForm Olympiads">
            <MultiSelectDropdown
              options={Olympiads}
              onSelect={handleOlympiads}
              placeholderDropdown="Olympiads Participated"
              placeholderInput="Search for olympiads"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
          </div>
          <div className="inputForm Olympiads">
            <MultiSelectDropdown
              options={Opportunities}
              onSelect={handleOpportunities}
              placeholderDropdown="Opportunities Types Interested In"
              placeholderInput="Search for interested fields"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
          </div>
          <div className="inputForm Olympiads">
            <MultiSelectDropdown
              options={Subjects}
              onSelect={handleSubjects}
              placeholderDropdown="Subjects Preference"
              placeholderInput="Search for interested subjects"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
          </div>
        </form>
        <button onClick={handleClick} className="yellowBtn">
          Save and Continue
        </button>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Class9Form;
