const Olympiads = [
  { label: "International Mathematical Olympiad (IMO)", value: "1" },
  { label: "International Physics Olympiad (IPhO)", value: "2" },
  { label: "International Chemistry Olympiad (IChO)", value: "3" },
  { label: "International Biology Olympiad (IBO)", value: "4" },
  { label: "International Olympiad in Informatics (IOI)", value: "5" },
  {
    label: "International Astronomy and Astrophysics Olympiad (IAAO)",
    value: "6",
  },
  { label: "International Geography Olympiad (iGeo)", value: "7" },
  { label: "International Junior Science Olympiad (IJSO)", value: "8" },
  { label: "International Earth Science Olympiad (IESO)", value: "9" },
  { label: "International Linguistics Olympiad (IOL)", value: "10" },
  { label: "International Economics Olympiad (IEO)", value: "11" },
  {
    label: "International Olympiad on Astronomy and Astrophysics (IOAA)",
    value: "12",
  },
  { label: "European Girls' Mathematical Olympiad (EGMO)", value: "13" },
  { label: "Asian Pacific Mathematics Olympiad (APMO)", value: "14" },
  { label: "Asian Physics Olympiad (APhO)", value: "15" },
  { label: "Asian Chemistry Olympiad (ACHO)", value: "16" },
  { label: "Asian Biology Olympiad (ABO)", value: "17" },
  { label: "International Mathematical Kangaroo (IMK)", value: "18" },
  { label: "International Philosophy Olympiad (IPO)", value: "19" },
  { label: "International Brain Bee (IBB)", value: "20" },
  { label: "International Science and Engineering Fair (ISEF)", value: "21" },
  { label: "Science Olympiad", value: "22" },
  { label: "National Geographic Bee", value: "23" },
  { label: "First Robotics Competition (FRC)", value: "24" },
  { label: "World Robotics Olympiad (WRO)", value: "25" },
  {
    label: "Intel International Science and Engineering Fair (ISEF)",
    value: "26",
  },
  { label: "Google Science Fair", value: "27" },
  { label: "Odyssey of the Mind", value: "28" },
  { label: "National Science Bowl", value: "29" },
  { label: "National Mathcounts", value: "30" },
  { label: "USA Physics Olympiad (USAPhO)", value: "31" },
  { label: "USA Chemistry Olympiad (USACO)", value: "32" },
  { label: "USA Biology Olympiad (USABO)", value: "33" },
  { label: "USA Mathematical Talent Search (USAMTS)", value: "34" },
  { label: "American Mathematics Competitions (AMC)", value: "35" },
  {
    label:
      "Mathematics Association of America (MAA) American Invitational Mathematics Examination (AIME)",
    value: "36",
  },
  {
    label: "International Mathematical Olympiad for Girls (IMOG)",
    value: "37",
  },
  { label: "International Junior Science Olympiad (IJSO)", value: "38" },
  { label: "International Physics Olympiad for Juniors (IPOJ)", value: "39" },
  { label: "National Chemistry Olympiad (NChO)", value: "40" },
  { label: "International Computer Science Olympiad (ICSO)", value: "41" },
  { label: "National Physics Olympiad (NPhO)", value: "42" },
  { label: "Junior Science and Humanities Symposium (JSHS)", value: "43" },
  { label: "Future City Competition", value: "44" },
  { label: "National History Bee and Bowl", value: "45" },
  { label: "World Mathematics Team Championship (WMTC)", value: "46" },
  { label: "International Olympiad in Informatics (IOI)", value: "47" },
  { label: "International Robotics Olympiad (IRO)", value: "48" },
  { label: "International Junior Mathematical Olympiad (IJMO)", value: "49" },
  { label: "International Mathematical Talent Search (IMTS)", value: "50" },
  { label: "International Chemistry Olympiad for Juniors (ICJ)", value: "51" },
  {
    label: "Junior Achievement Company Program (JA Company Program)",
    value: "52",
  },
  { label: "International Biology Olympiad for Juniors (IBO-J)", value: "53" },
  {
    label: "International Earth Science Olympiad for Juniors (IESO-J)",
    value: "54",
  },
  {
    label: "International Economics Olympiad for Juniors (IEO-J)",
    value: "55",
  },
];

export default Olympiads;
