import React from "react";
import Opportunity from "../Opportunity";
import OpportunityList from "../OpportunitiesList";
const Recommendations = ({ oppArr }) => {
  return (
    <div style={{ height: "100%" }}>
      {oppArr.map((opp, key) => {
        return (
          <Opportunity
            name={opp.title}
            info={opp.description}
            stipend={opp.stipend}
            location={opp.location}
            typeofopportunity={opp.typeofopportunity}
            eligiblity={opp.eligiblity}
          ></Opportunity>
        );
      })}
    </div>
  );
};

export default Recommendations;
