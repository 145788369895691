import React from "react";
import Robot from "../../../../assets/Robot.png";
import "./index.css";

const LatestOppurtunity = ({ name, views, time }) => {
  return (
    <div className="latestOppWrapper">
      <div className="latestOppImgCon">
        <img className="latestOppImg" src={Robot} alt="/" />
      </div>
      <div className="latestOppContainer">
        <div className="latestOppInfo">
          <div className="latestOppFirmName">{name}</div>
          <div className="latestOppDayandTime">
            <div className="latestOppDetail Day">{time} days ago</div>
            <div className="latestOppDetail Dot">.</div>
            <div className="latestOppDetail Time">{views} views</div>
          </div>
        </div>
        <div className="latestOppBtn">></div>
      </div>
    </div>
  );
};

export default LatestOppurtunity;
