const Olympiads = [
  { label: "KVPY", value: "1" },
  { label: "NTSE", value: "2" },
  { label: "JSTSE", value: "3" },
  { label: "RMO", value: "4" },
  { label: "PRMO", value: "5" },
  { label: "INMO", value: "6" },
  { label: "NSEJS", value: "7" },
];

export default Olympiads;
