import React from "react";
import "./index.css";
import MentorCard from "./MentorCard/index.js";
import Mentor1 from "../../../../assets/mentor1.jpeg";
import Mentor2 from "../../../../assets/mentor2.jpeg";
import Mentor3 from "../../../../assets/mentor3.jpeg";

const MentorArr = [
  {
    profileimg: Mentor1,
    name: "Amber Srivastava",
    description:
      "Government Official 🇮🇳 | Ex-Tech Lead at Zoom | 30 Thousand LinkedIn Followers | Ex-PayU, Nissan | Top 1% Mentor",
    profession:
      "Manager(Java Specialist) at Bank of Maharashtra (Indian Government & Finance Ministry)",
    location: "Pune, Maharashtra, India",
    students_mentored: "32",
    review_stars: "4.5",
  },
  {
    profileimg: Mentor2,
    name: "Devang Raja",
    description:
      "Angel investors, VC, PE and Private funding - An entrepreneur creating entrepreneurs",
    profession: "Founder at Venture Wolf",
    location: "Mumbai, Maharashtra, India",
    students_mentored: "16",
    review_stars: "4.2",
  },
  {
    profileimg: Mentor3,
    name: "Raj Kunkolienkar",
    description: "Building Stoa, India’s best MBA alternative",
    profession: "Founder at Stoa",
    location: "Goa, India",
    students_mentored: "22",
    review_stars: "4.1",
  },
];
const Mentors = () => {
  return (
    <div className="mentorsWrapper">
      <div className="mentorsHeading">Recommended Mentors</div>
      <div className="mentorsDescription">
        AI generated best mentors for you.
      </div>
      <div className="allMentors">
        {MentorArr.map((mentor) => {
          return (
            <MentorCard
              name={mentor.name}
              profileImg={mentor.profileimg}
              description={mentor.description}
              profession={mentor.profession}
              location={mentor.location}
              students_mentored={mentor.students_mentored}
              review_stars={mentor.review_stars}
            ></MentorCard>
          );
        })}
      </div>
    </div>
  );
};

export default Mentors;
