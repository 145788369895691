import MastersImg from "../../assets/Masters.png";
import BachelorsImg from "../../assets/Bachelors.png";
import SchoolImg from "../../assets/School.png";
import PhDImg from "../../assets/PhD.png";

const CurrentEducation = {
  activeOption: null,
  objects: [
    {
      id: 1,
      type: "School",
      image: SchoolImg,
      selected: true,
    },
    {
      id: 2,
      type: "Bachelors",
      image: BachelorsImg,
      selected: false,
    },
    {
      id: 3,
      type: "Masters",
      image: MastersImg,
      selected: false,
    },
    {
      id: 4,
      type: "PhD",
      image: PhDImg,
      selected: false,
    },
  ],
};

export default CurrentEducation;
