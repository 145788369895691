import Sarthak from "../../../assets/Sarthak.png";
import Himanshu from "../../../assets/Himanshu.png";

const Founders = [
  {
    id: 1,
    name: "Sarthak Ray",
    role: "Co-Founder (CTO)",
    url: Sarthak,
    info: "Sarthak, a distinguished alumnus of IIT Bombay’s 2019 cohort, is currently based in Dubai with extensive experience in Systems and Software Engineering and a deep understanding of DevOps. His illustrious career is underpinned by groundbreaking work in IIoT and Industry 4.0, developed during his tenure in Germany. His comprehensive education encompasses a dual degree in Electrical Engineering, with projects that span an array of diverse topics, from Neuromorphic Engineering to Microprocessor Design.",
    linkedin: "https://www.linkedin.com/in/sarthak-ray96/",
  },
  {
    id: 2,
    name: "Himanshu Upadhyay",
    role: "Co-Founder (CEO)",
    url: Himanshu,
    info: "Himanshu Upadhay, an IIT Delhi graduate is a tech innovator focused on using advanced technologies to solve business challenges. He's contributed to research at Khalifa University on the Future of Work, participated in healthcare start-ups, and is currently involved in the UAE National Program for Artificial Intelligence with Facebook. With experience at Citi, he's adept at integrating futuristic tech into diverse business domains, believing strongly in their positive impact on society..",
    linkedin: "https://www.linkedin.com/in/himanshu-upadhyay-3a28ab34/",
  },
];

export default Founders;
