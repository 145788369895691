import React from "react";
import Sidebar from "../Usables/Sidebar";
import Header from "../Headers/HeaderProfile";
import AllOppurtunities from "./Oppurtunities";
import "./index.css";

const Dashboard = () => {
  return (
    <div className="dashboardWrapper">
      <div className="dashboardHeader">
        <Header></Header>
      </div>
      <div className="dashboardInfo">
        <div className="dashboardSidebar">
          <Sidebar></Sidebar>
        </div>
        <div className="dashboardOppurtunities">
          <div className="dashboardOpp">
            <AllOppurtunities></AllOppurtunities>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
