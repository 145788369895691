import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

const Layout = () => {
  return (
    <main className="App">
      <Outlet />
      {/* <ScrollRestoration /> */}
    </main>
  );
};

export default Layout;
