const WorldCountries = [
  {
    id: 1,
    label: "Select All ",
    Region: "All Regions",
    check: false,
  },
  {
    id: 2,
    label: "Afghanistan ",
    Region: "Asia",
    check: false,
  },
  {
    id: 3,
    label: "Albania ",
    Region: "Europe",
    check: false,
  },
  {
    id: 4,
    label: "Algeria ",
    Region: "Africa",
    check: false,
  },
  {
    id: 5,
    label: "American Samoa ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 6,
    label: "Andorra ",
    Region: "Europe",
    check: false,
  },
  {
    id: 7,
    label: "Angola ",
    Region: "Africa",
    check: false,
  },
  {
    id: 8,
    label: "Anguilla ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 9,
    label: "Antigua & Barbuda ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 10,
    label: "Argentina ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 11,
    label: "Armenia ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 12,
    label: "Aruba ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 13,
    label: "Australia ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 14,
    label: "Austria ",
    Region: "Europe",
    check: false,
  },
  {
    id: 15,
    label: "Azerbaijan ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 16,
    label: "Bahamas, The ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 17,
    label: "Bahrain ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 18,
    label: "Bangladesh ",
    Region: "Asia",
    check: false,
  },
  {
    id: 19,
    label: "Barbados ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 20,
    label: "Belarus ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 21,
    label: "Belgium ",
    Region: "Europe",
    check: false,
  },
  {
    id: 22,
    label: "Belize ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 23,
    label: "Benin ",
    Region: "Africa",
    check: false,
  },
  {
    id: 24,
    label: "Bermuda ",
    Region: "Northern America",
    check: false,
  },
  {
    id: 25,
    label: "Bhutan ",
    Region: "Asia",
    check: false,
  },
  {
    id: 26,
    label: "Bolivia ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 27,
    label: "Bosnia & Herzegovina ",
    Region: "Europe",
    check: false,
  },
  {
    id: 28,
    label: "Botswana ",
    Region: "Africa",
    check: false,
  },
  {
    id: 29,
    label: "Brazil ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 30,
    label: "British Virgin Is. ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 31,
    label: "Brunei ",
    Region: "Asia",
    check: false,
  },
  {
    id: 32,
    label: "Bulgaria ",
    Region: "Europe",
    check: false,
  },
  {
    id: 33,
    label: "Burkina Faso ",
    Region: "Africa",
    check: false,
  },
  {
    id: 34,
    label: "Burma ",
    Region: "Asia",
    check: false,
  },
  {
    id: 35,
    label: "Burundi ",
    Region: "Africa",
    check: false,
  },
  {
    id: 36,
    label: "Cambodia ",
    Region: "Asia",
    check: false,
  },
  {
    id: 37,
    label: "Cameroon ",
    Region: "Africa",
    check: false,
  },
  {
    id: 38,
    label: "Canada ",
    Region: "Northern America",
    check: false,
  },
  {
    id: 39,
    label: "Cape Verde ",
    Region: "Africa",
    check: false,
  },
  {
    id: 40,
    label: "Cayman Islands ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 41,
    label: "Central African Rep. ",
    Region: "Africa",
    check: false,
  },
  {
    id: 42,
    label: "Chad ",
    Region: "Africa",
    check: false,
  },
  {
    id: 43,
    label: "Chile ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 44,
    label: "China ",
    Region: "Asia",
    check: false,
  },
  {
    id: 45,
    label: "Colombia ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 46,
    label: "Comoros ",
    Region: "Africa",
    check: false,
  },
  {
    id: 47,
    label: "Congo, Dem. Rep. ",
    Region: "Africa",
    check: false,
  },
  {
    id: 48,
    label: "Congo, Repub. of the ",
    Region: "Africa",
    check: false,
  },
  {
    id: 49,
    label: "Cook Islands ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 50,
    label: "Costa Rica ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 51,
    label: "Cote d'Ivoire ",
    Region: "Africa",
    check: false,
  },
  {
    id: 52,
    label: "Croatia ",
    Region: "Europe",
    check: false,
  },
  {
    id: 53,
    label: "Cuba ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 54,
    label: "Cyprus ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 55,
    label: "Czech Republic ",
    Region: "Europe",
    check: false,
  },
  {
    id: 56,
    label: "Denmark ",
    Region: "Europe",
    check: false,
  },
  {
    id: 57,
    label: "Djibouti ",
    Region: "Africa",
    check: false,
  },
  {
    id: 58,
    label: "Dominica ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 59,
    label: "Dominican Republic ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 60,
    label: "East Timor ",
    Region: "Asia",
    check: false,
  },
  {
    id: 61,
    label: "Ecuador ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 62,
    label: "Egypt ",
    Region: "Africa",
    check: false,
  },
  {
    id: 63,
    label: "El Salvador ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 64,
    label: "Equatorial Guinea ",
    Region: "Africa",
    check: false,
  },
  {
    id: 65,
    label: "Eritrea ",
    Region: "Africa",
    check: false,
  },
  {
    id: 66,
    label: "Estonia ",
    Region: "Baltics",
    check: false,
  },
  {
    id: 67,
    label: "Ethiopia ",
    Region: "Africa",
    check: false,
  },
  {
    id: 68,
    label: "Faroe Islands ",
    Region: "Europe",
    check: false,
  },
  {
    id: 69,
    label: "Fiji ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 70,
    label: "Finland ",
    Region: "Europe",
    check: false,
  },
  {
    id: 71,
    label: "France ",
    Region: "Europe",
    check: false,
  },
  {
    id: 72,
    label: "French Guiana ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 73,
    label: "French Polynesia ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 74,
    label: "Gabon ",
    Region: "Africa",
    check: false,
  },
  {
    id: 75,
    label: "Gambia, The ",
    Region: "Africa",
    check: false,
  },
  {
    id: 76,
    label: "Gaza Strip ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 77,
    label: "Georgia ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 78,
    label: "Germany ",
    Region: "Europe",
    check: false,
  },
  {
    id: 79,
    label: "Ghana ",
    Region: "Africa",
    check: false,
  },
  {
    id: 80,
    label: "Gibraltar ",
    Region: "Europe",
    check: false,
  },
  {
    id: 81,
    label: "Greece ",
    Region: "Europe",
    check: false,
  },
  {
    id: 82,
    label: "Greenland ",
    Region: "Northern America",
    check: false,
  },
  {
    id: 83,
    label: "Grenada ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 84,
    label: "Guadeloupe ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 85,
    label: "Guam ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 86,
    label: "Guatemala ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 87,
    label: "Guernsey ",
    Region: "Europe",
    check: false,
  },
  {
    id: 88,
    label: "Guinea ",
    Region: "Africa",
    check: false,
  },
  {
    id: 89,
    label: "Guinea-Bissau ",
    Region: "Africa",
    check: false,
  },
  {
    id: 90,
    label: "Guyana ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 91,
    label: "Haiti ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 92,
    label: "Honduras ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 93,
    label: "Hong Kong ",
    Region: "Asia",
    check: false,
  },
  {
    id: 94,
    label: "Hungary ",
    Region: "Europe",
    check: false,
  },
  {
    id: 95,
    label: "Iceland ",
    Region: "Europe",
    check: false,
  },
  {
    id: 96,
    label: "India ",
    Region: "Asia",
    check: false,
  },
  {
    id: 97,
    label: "Indonesia ",
    Region: "Asia",
    check: false,
  },
  {
    id: 98,
    label: "Iran ",
    Region: "Asia",
    check: false,
  },
  {
    id: 99,
    label: "Iraq ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 100,
    label: "Ireland ",
    Region: "Europe",
    check: false,
  },
  {
    id: 101,
    label: "Isle of Man ",
    Region: "Europe",
    check: false,
  },
  {
    id: 102,
    label: "Israel ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 103,
    label: "Italy ",
    Region: "Europe",
    check: false,
  },
  {
    id: 104,
    label: "Jamaica ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 105,
    label: "Japan ",
    Region: "Asia",
    check: false,
  },
  {
    id: 106,
    label: "Jersey ",
    Region: "Europe",
    check: false,
  },
  {
    id: 107,
    label: "Jordan ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 108,
    label: "Kazakhstan ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 109,
    label: "Kenya ",
    Region: "Africa",
    check: false,
  },
  {
    id: 110,
    label: "Kiribati ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 111,
    label: "Korea, North ",
    Region: "Asia",
    check: false,
  },
  {
    id: 112,
    label: "Korea, South ",
    Region: "Asia",
    check: false,
  },
  {
    id: 113,
    label: "Kuwait ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 114,
    label: "Kyrgyzstan ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 115,
    label: "Laos ",
    Region: "Asia",
    check: false,
  },
  {
    id: 116,
    label: "Latvia ",
    Region: "Baltics",
    check: false,
  },
  {
    id: 117,
    label: "Lebanon ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 118,
    label: "Lesotho ",
    Region: "Africa",
    check: false,
  },
  {
    id: 119,
    label: "Liberia ",
    Region: "Africa",
    check: false,
  },
  {
    id: 120,
    label: "Libya ",
    Region: "Africa",
    check: false,
  },
  {
    id: 121,
    label: "Liechtenstein ",
    Region: "Europe",
    check: false,
  },
  {
    id: 122,
    label: "Lithuania ",
    Region: "Baltics",
    check: false,
  },
  {
    id: 123,
    label: "Luxembourg ",
    Region: "Europe",
    check: false,
  },
  {
    id: 124,
    label: "Macau ",
    Region: "Asia",
    check: false,
  },
  {
    id: 125,
    label: "Macedonia ",
    Region: "Europe",
    check: false,
  },
  {
    id: 126,
    label: "Madagascar ",
    Region: "Africa",
    check: false,
  },
  {
    id: 127,
    label: "Malawi ",
    Region: "Africa",
    check: false,
  },
  {
    id: 128,
    label: "Malaysia ",
    Region: "Asia",
    check: false,
  },
  {
    id: 129,
    label: "Maldives ",
    Region: "Asia",
    check: false,
  },
  {
    id: 130,
    label: "Mali ",
    Region: "Africa",
    check: false,
  },
  {
    id: 131,
    label: "Malta ",
    Region: "Europe",
    check: false,
  },
  {
    id: 132,
    label: "Marshall Islands ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 133,
    label: "Martinique ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 134,
    label: "Mauritania ",
    Region: "Africa",
    check: false,
  },
  {
    id: 135,
    label: "Mauritius ",
    Region: "Africa",
    check: false,
  },
  {
    id: 136,
    label: "Mayotte ",
    Region: "Africa",
    check: false,
  },
  {
    id: 137,
    label: "Mexico ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 138,
    label: "Micronesia, Fed. St. ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 139,
    label: "Moldova ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 140,
    label: "Monaco ",
    Region: "Europe",
    check: false,
  },
  {
    id: 141,
    label: "Mongolia ",
    Region: "Asia",
    check: false,
  },
  {
    id: 142,
    label: "Montserrat ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 143,
    label: "Morocco ",
    Region: "Africa",
    check: false,
  },
  {
    id: 144,
    label: "Mozambique ",
    Region: "Africa",
    check: false,
  },
  {
    id: 145,
    label: "Namibia ",
    Region: "Africa",
    check: false,
  },
  {
    id: 146,
    label: "Nauru ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 147,
    label: "Nepal ",
    Region: "Asia",
    check: false,
  },
  {
    id: 148,
    label: "Netherlands ",
    Region: "Europe",
    check: false,
  },
  {
    id: 149,
    label: "Netherlands Antilles ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 150,
    label: "New Caledonia ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 151,
    label: "New Zealand ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 152,
    label: "Nicaragua ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 153,
    label: "Niger ",
    Region: "Africa",
    check: false,
  },
  {
    id: 154,
    label: "Nigeria ",
    Region: "Africa",
    check: false,
  },
  {
    id: 155,
    label: "N. Mariana Islands ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 156,
    label: "Norway ",
    Region: "Europe",
    check: false,
  },
  {
    id: 157,
    label: "Oman ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 158,
    label: "Pakistan ",
    Region: "Asia",
    check: false,
  },
  {
    id: 159,
    label: "Palau ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 160,
    label: "Panama ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 161,
    label: "Papua New Guinea ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 162,
    label: "Paraguay ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 163,
    label: "Peru ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 164,
    label: "Philippines ",
    Region: "Asia",
    check: false,
  },
  {
    id: 165,
    label: "Poland ",
    Region: "Europe",
    check: false,
  },
  {
    id: 166,
    label: "Portugal ",
    Region: "Europe",
    check: false,
  },
  {
    id: 167,
    label: "Puerto Rico ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 168,
    label: "Qatar ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 169,
    label: "Reunion ",
    Region: "Africa",
    check: false,
  },
  {
    id: 170,
    label: "Romania ",
    Region: "Europe",
    check: false,
  },
  {
    id: 171,
    label: "Russia ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 172,
    label: "Rwanda ",
    Region: "Africa",
    check: false,
  },
  {
    id: 173,
    label: "Saint Helena ",
    Region: "Africa",
    check: false,
  },
  {
    id: 174,
    label: "Saint Kitts & Nevis ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 175,
    label: "Saint Lucia ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 176,
    label: "St Pierre & Miquelon ",
    Region: "Northern America",
    check: false,
  },
  {
    id: 177,
    label: "Saint Vincent and the Grenadines ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 178,
    label: "Samoa ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 179,
    label: "San Marino ",
    Region: "Europe",
    check: false,
  },
  {
    id: 180,
    label: "Sao Tome & Principe ",
    Region: "Africa",
    check: false,
  },
  {
    id: 181,
    label: "Saudi Arabia ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 182,
    label: "Senegal ",
    Region: "Africa",
    check: false,
  },
  {
    id: 183,
    label: "Serbia ",
    Region: "Europe",
    check: false,
  },
  {
    id: 184,
    label: "Seychelles ",
    Region: "Africa",
    check: false,
  },
  {
    id: 185,
    label: "Sierra Leone ",
    Region: "Africa",
    check: false,
  },
  {
    id: 186,
    label: "Singapore ",
    Region: "Asia",
    check: false,
  },
  {
    id: 187,
    label: "Slovakia ",
    Region: "Europe",
    check: false,
  },
  {
    id: 188,
    label: "Slovenia ",
    Region: "Europe",
    check: false,
  },
  {
    id: 189,
    label: "Solomon Islands ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 190,
    label: "Somalia ",
    Region: "Africa",
    check: false,
  },
  {
    id: 191,
    label: "South Africa ",
    Region: "Africa",
    check: false,
  },
  {
    id: 192,
    label: "Spain ",
    Region: "Europe",
    check: false,
  },
  {
    id: 193,
    label: "Sri Lanka ",
    Region: "Asia",
    check: false,
  },
  {
    id: 194,
    label: "Sudan ",
    Region: "Africa",
    check: false,
  },
  {
    id: 195,
    label: "Suriname ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 196,
    label: "Swaziland ",
    Region: "Africa",
    check: false,
  },
  {
    id: 197,
    label: "Sweden ",
    Region: "Europe",
    check: false,
  },
  {
    id: 198,
    label: "Switzerland ",
    Region: "Europe",
    check: false,
  },
  {
    id: 199,
    label: "Syria ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 200,
    label: "Taiwan ",
    Region: "Asia",
    check: false,
  },
  {
    id: 201,
    label: "Tajikistan ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 202,
    label: "Tanzania ",
    Region: "Africa",
    check: false,
  },
  {
    id: 203,
    label: "Thailand ",
    Region: "Asia",
    check: false,
  },
  {
    id: 204,
    label: "Togo ",
    Region: "Africa",
    check: false,
  },
  {
    id: 205,
    label: "Tonga ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 206,
    label: "Trinidad & Tobago ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 207,
    label: "Tunisia ",
    Region: "Africa",
    check: false,
  },
  {
    id: 208,
    label: "Turkey ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 209,
    label: "Turkmenistan ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 210,
    label: "Turks & Caicos Is ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 211,
    label: "Tuvalu ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 212,
    label: "Uganda ",
    Region: "Africa",
    check: false,
  },
  {
    id: 213,
    label: "Ukraine ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 214,
    label: "United Arab Emirates ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 215,
    label: "United Kingdom ",
    Region: "Europe",
    check: false,
  },
  {
    id: 216,
    label: "United States ",
    Region: "Northern America",
    check: false,
  },
  {
    id: 217,
    label: "Uruguay ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 218,
    label: "Uzbekistan ",
    Region: "C.W. OF IND. STATES ",
    check: false,
  },
  {
    id: 219,
    label: "Vanuatu ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 220,
    label: "Venezuela ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 221,
    label: "Vietnam ",
    Region: "Asia",
    check: false,
  },
  {
    id: 222,
    label: "Virgin Islands ",
    Region: "The Carribean and Latin America",
    check: false,
  },
  {
    id: 223,
    label: "Wallis and Futuna ",
    Region: "Oceania",
    check: false,
  },
  {
    id: 224,
    label: "West Bank ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 225,
    label: "Western Sahara ",
    Region: "Africa",
    check: false,
  },
  {
    id: 226,
    label: "Yemen ",
    Region: "Middle East",
    check: false,
  },
  {
    id: 227,
    label: "Zambia ",
    Region: "Africa",
    check: false,
  },
  {
    id: 228,
    label: "Zimbabwe ",
    Region: "Africa",
    check: false,
  },
];

export default WorldCountries;
