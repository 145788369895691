import React, { useState } from "react";
import Header from "../../Headers/HeaderForms";
import Footer from "../../Footer/Footer.js";
import Dropdown from "../../Usables/Dropdown/Dropdown.js";
import "./personalDetails.css";
import Gender from "../../options/Gender.js";
import YellowButton from "../../Usables/Buttons/YellowButton";
import { useNavigate, useLocation } from "react-router-dom";
import InputField from "../../Usables/InputField";
import axios from "../../../api/axios.js";
import useAuth from "../../../hooks/useAuth.js";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useLoader } from "../../../context/LoaderContext.js";

const PersonalDetailsForm = () => {
  const { setIsLoading } = useLoader();
  console.log("setisloading function is ", setIsLoading);
  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";
  const SAVE_PERSONALDETAILS_ROUTE = "/api/savePersonalDetails";

  const { auth } = useAuth();
  const accessToken = auth.accessToken;
  const axiosPrivate = useAxiosPrivate();

  const location = useLocation();
  const usertype = location.state?.usertype;
  console.log("usertype in personal details is ", usertype);

  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    country: "",
    city: "",
    homestate: "",
    pin: "",
  });

  const { firstname, lastname, country, city, homestate, pin } = inputValue;
  const [gender, setGender] = useState(null);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChange = (e) => {
    setGender(e.value);
  };

  const handleBack = async () => {
    const response = await axiosPrivate.post(
      SAVE_LOGIN_ROUTE,
      {
        userID: auth.userID,
        loginroute: "userType",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,
      }
    );
    console.log("response from saving route api is ", response);
    // If everything is successful, then only we will navigate
    navigate("/userType");
  };

  const handleClick = async () => {
    try {
      setIsLoading(true);
      // Save the  in the database
      let nextRoute = "";
      if (usertype === "Students") nextRoute = "selectEducation";
      else if (usertype === "Parents") nextRoute = "selectEducation";
      else if (usertype === "Employees") nextRoute = "employees";
      else if (usertype === "Institutons") nextRoute = "institutions";
      console.log("nextRoute after click is ", nextRoute);
      const data = {
        firstname: firstname,
        lastname: lastname,
        country: country,
        city: city,
        homestate: homestate,
        pin: pin,
        gender: gender,
        userID: auth.userID,
      };
      const response1 = await axiosPrivate.post(
        SAVE_PERSONALDETAILS_ROUTE,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );
      console.log("response from saving userType api is ", response1);

      // Here you need to save the next route ie personal details into your db
      const response2 = await axiosPrivate.post(
        SAVE_LOGIN_ROUTE,
        {
          userID: auth.userID,
          loginroute: nextRoute,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );
      console.log("response from saving route api is ", response2);
      // If everything is successful, then only we will navigate
      navigate(`/${nextRoute}`, { state: { usertype: usertype } });
    } catch (err) {
      console.log("err is ", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="personalDetailsWrapper">
      <Header></Header>
      <div className="studentFormWrapper">
        <div className="formHeading">Personal Details</div>
        <div className="formMessage">
          Enter all Your personal details mention below
        </div>
        <form>
          <div className="nameWrapper">
            <div className="inputForm firstName">
              <InputField
                type="text"
                value={firstname}
                placeholder="First Name"
                name="firstname"
                onChange={handleChange}
              />
            </div>
            <div className="inputForm lastName">
              <InputField
                type="text"
                value={lastname}
                placeholder="Last Name"
                name="lastname"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="inputForm Gender">
            <Dropdown
              options={Gender}
              placeHolder="Select Gender"
              onChange={onChange}
            ></Dropdown>
          </div>
          <div className="inputForm Country">
            <InputField
              type="text"
              value={country}
              placeholder="Country"
              name="country"
              onChange={handleChange}
            />
          </div>
          <div className="locationWrapper">
            <div className="inputForm City">
              <InputField
                type="text"
                value={city}
                placeholder="City"
                name="city"
                onChange={handleChange}
              />
            </div>
            <div className="inputForm State">
              <InputField
                type="text"
                value={homestate}
                placeholder="State"
                name="homestate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="inputForm Pin">
            <InputField
              type="text"
              value={pin}
              placeholder="Pin"
              name="pin"
              onChange={handleChange}
            />
          </div>
        </form>
        <div className="personalDetailsBtns">
          <button className="yellowBtn Btn1" onClick={handleBack}>
            Back
          </button>
          <button className="yellowBtn Btn1" onClick={handleClick}>
            Next
          </button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PersonalDetailsForm;
