// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAnHTq-fUyP6hXM4C4eMTt5sBe8KOMpi6M",
  authDomain: "opgurunewest.firebaseapp.com",
  projectId: "opgurunewest",
  storageBucket: "opgurunewest.appspot.com",
  messagingSenderId: "214717060903",
  appId: "1:214717060903:web:223401c9b777dce920fb02",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
