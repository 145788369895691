import React, { useState, useEffect } from "react";
import "./index.css";
import CrossBtn from "../../../assets/cross-button.svg"
import MyButton from '../../Usables/Buttons/Button';

function Modal({ isOpen, onClose, onSave, initialContent }) {
  const [editedContent, setEditedContent] = useState(initialContent);

  const handleSaveClick = () => {
    onSave(editedContent);
  };
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content-profile">
        <button className="close-button" onClick={onClose}>
          <img className="crossBtnImg" src={CrossBtn} alt="/"/>
        </button>
        <h2 className = "bold-text"style={{ marginBottom: '2px' }}>About</h2>
        <p className="about-description">Describe yourself !</p>
        <textarea className="aboutTextArea" placeholder="Write something about yourself!" value={editedContent} onChange={(e) => setEditedContent(e.target.value)} />
        <MyButton buttonOnClick={handleSaveClick} buttonFontSize="2rem" buttonWidth="50%" borderRadius="10px"  title="Save and Continue" paddingV="1rem" paddingH="20px"></MyButton>
      </div>
    </div>
  );
}

export default Modal;

