import React from "react";
import "./index.css";
import Linkedin from "../../../../assets/linkedin.png";
import Twitter from "../../../../assets/twitter.png";
const Founder = (props) => {
  return (
    <div className="founderWrapper">
      <div className="founderImgWrapper">
        <img className="founderImg" src={props.memberImg} alt="/" />
      </div>
      <div className="founderInformation">
        <div className="founderName">{props.name}</div>
        <div className="founderRole">{props.role}</div>
        <div className="founderInfo">{props.info}</div>
        <div className="founderHandles">
          <div className="founderHandle">
            <a href={props.linkedin} target="_blank" rel="noopener noreferrer">
              <img src={Linkedin} alt="/" />
            </a>
          </div>
          {/* <div className="memberHandle">
            <img src={Twitter} alt="/" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Founder;
