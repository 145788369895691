import React from "react";
import Navbar from "../Navbar";
import Landing from "../Landing";
import Brands from "./Brands";

const Collabs = () => {
  return (
    <div className="collabWrapper">
      <div className="collabNavbar">
        <Navbar></Navbar>
      </div>
      <div className="collabLanding">
        <Landing
          heading1="Find Oppurtunities in our collaborative network"
          heading2="Explore synergistic partnerships for mutual growth and impact."
          buttonName="Apply Now"
          buttonNav="Apply Now"
        ></Landing>
      </div>
      {/* <div className="collabSection">
        <Brands></Brands>
      </div> */}
    </div>
  );
};

export default Collabs;
