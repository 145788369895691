import totalMatches from "../../../assets/Dashboard/totalMatches.png";
import totalApplied from "../../../assets/Dashboard/totalApplied.png";
import totalJobsAdded from "../../../assets/Dashboard/totalJobsAdded.png";
import recentlyViewed from "../../../assets/Dashboard/recentlyViewed.png";
import totalSaved from "../../../assets/Dashboard/totalSaved.png";
import totalCompanies from "../../../assets/Dashboard/totalCompanies.png";
import totalFunded from "../../../assets/Dashboard/totalFunded.png";
import totalSalaries from "../../../assets/Dashboard/totalSalaries.png";
import totalIgnored from "../../../assets/Dashboard/totalIgnored.png";
import totalBlocked from "../../../assets/Dashboard/totalMedia.png";
import totalMostViewed from "../../../assets/Dashboard/totalMostViewed.png";

const Oppurtunities = [
  {
    type: "All your Matches",
    count: "5113",
    Img: totalMatches,
  },
  {
    type: "Applied",
    count: "3594",
    Img: totalApplied,
  },
  {
    type: "Jobs Added this week",
    count: "1978",
    Img: totalJobsAdded,
  },
  {
    type: "Recently Viewed",
    count: "1.4k",
    Img: recentlyViewed,
  },
  {
    type: "Marked as save",
    count: "598",
    Img: totalSaved,
  },
  {
    type: "Total Companies",
    count: "225",
    Img: totalCompanies,
  },
  {
    type: "Recently funded",
    count: "792",
    Img: totalFunded,
  },
  {
    type: "Jobs with salaries",
    count: "1227",
    Img: totalSalaries,
  },
  {
    type: "Ignored Oppurtunities",
    count: "369",
    Img: totalIgnored,
  },
  {
    type: "Most viewed",
    count: "1450",
    Img: totalMostViewed,
  },
  {
    type: "Blocked",
    count: "1450MB",
    Img: totalBlocked,
  },
];

export default Oppurtunities;
