const Olympiads = [
  { label: "Coding", value: "1" },
  { label: "Physics", value: "2" },
  { label: "English", value: "3" },
  { label: "Hindi", value: "4" },
  { label: "Mathematics", value: "5" },
  { label: "Astronomy", value: "6" },
  { label: "Biology", value: "7" },
  { label: "Art", value: "12" },
  { label: "History", value: "13" },
  { label: "Political science / Civics", value: "14" },
  { label: "Psychology", value: "15" },
];

export default Olympiads;
