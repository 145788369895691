import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
function SkillsCard({ skills, onEditClick }) {
  const iconStyle = {
    color: "black", // Set the background color to white
  };
  return (
    <div className="card">
      <button className="edit-button" onClick={onEditClick}>
        <FontAwesomeIcon icon={faPencilAlt} style={iconStyle} />
      </button>
      <h2 className="card-title">My Skills</h2>
      <div className="flex flex-wrap">
        {skills.map((skill) => (
          <p className="pill" key={skill}>
            {skill}
          </p>
        ))}
      </div>
    </div>
  );
}

export default SkillsCard;
