import { combineReducers } from "redux";
import phonenumReducer from "./phonenumReducer";
import nameReducer from "./nameReducer";

const reducers = combineReducers({
  phonenum: phonenumReducer,
  nameofuser: nameReducer,
});

export default reducers;
