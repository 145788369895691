import React from "react";
import "./index.css";
import Team from "./Team.js";
import Founders from "./Founders.js";
import Founder from "./Founder/index.js";
import Member from "./Member/index.js";
import Founder1Card from "./Founder1Card";
import Himanshu from "../../../assets/Himanshu.png";
import { FaLinkedin } from "react-icons/fa";

const OurTeam = () => {
  return (
    <div className="teamWrapper">
      <div
        className="homeHeading1"
        style={{ marginTop: "2rem", marginBottom: "0" }}
      >
        <span className="homeHeadingSpan" style={{ color: "#DFA114" }}>
          LEADERSHIP
        </span>{" "}
        TEAM
      </div>
      {/* <Founder1Card
        imageSrc={Himanshu}
        name="Himanshu Upadhyay"
        position="CEO & Co-founder"
        linkedinIconSrc={FaLinkedin}
        missionStatement="Our mission is to connect passionate individuals with the right opportunities, using technology to transform aspirations into achievements."
      /> */}
      <div className="foundersSection">
        <div className="foundingMembers">
          {Founders.map((team) => {
            return (
              <div className="eachFounder">
                <Founder
                  name={team.name}
                  info={team.info}
                  memberImg={team.url}
                  role={team.role}
                  linkedin={team.linkedin}
                ></Founder>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div
        className="homeHeading1"
        style={{ marginTop: "2rem", marginBottom: "0" }}
      >
        MEET
        <span className="homeHeadingSpan" style={{ color: "#DFA114" }}>
          OUR OTHER
        </span>
        EXPERTS
      </div>
      <div className="teamSection">
        <div className="teamMembers">
          {Team.map((team) => {
            return (
              <div className="eachMember">
                <Member
                  name={team.name}
                  info={team.info}
                  memberImg={team.url}
                  role={team.role}
                  linkedin={team.linkedin}
                ></Member>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

export default OurTeam;
