import React from "react";
import MissionImg from "../../../assets/MissionImg.png";
import "./index.css";

const Mission = () => {
  return (
    <div className="missionWrapper">
      <div className="mvImgWrapper">
        <img className="mvImg" src={MissionImg} alt="/" />
      </div>
      <div className="homeHeading1">
        Our{" "}
        <span className="homeHeadingSpan" style={{ color: "#DFA114" }}>
          Mission
        </span>
      </div>
      <div className="missionInfo">
        OpGuru is driven by a bold mission: to empower and support students in
        navigating their educational pathways with confidence and purpose. We
        pledge to serve as trusted mentors and advocates, equipping at least
        5,000 students annually with the knowledge, resources, and guidance
        needed to make informed and strategic career decisions. Through
        personalized attention, innovative solutions, and unwavering dedication,
        we strive to ensure that every student can pursue a path that honors
        their unique talents, passions, and aspirations. Together, we aim to
        redefine educational success and foster a world where every individual
        can thrive and contribute meaningfully to society.
      </div>
    </div>
  );
};

export default Mission;
