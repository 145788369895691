import React, { useState, useEffect } from "react";
import Dropdown from "../../../Dropdown/Dropdown.js";
import SearchableDropdown from "../../../SearchableDropdown/index.js";
import { Country, State, City } from "country-state-city";
import WorldRegions from "./WorldRegions.js";
import WorldCountries from "./WorldCountries.js";
import "./index.css";

const AddedRegion = ({
  regionName,
  id,
  arr,
  agreement,
  handleChangeName,
  handleChangeSubRegion,
}) => {
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(arr[id].subRegions);
  const handleSelectedSingle = (e) => {
    const newRegion = e.label;
    handleChangeName(e, id);
  };
  const handleSelectedMulti = (e) => {
    let temp = [];
    Object.keys(e).forEach(function (key) {
      // console.log(e[key].value);
      temp = [...temp, e[key]];
    });
    temp.sort();
    handleChangeSubRegion(temp, id);
    setSelected(temp);
  };

  Object.keys(WorldCountries).forEach(function (key) {
    WorldCountries[key] = { ...WorldCountries[key], check: false };
  });

  return (
    <div className="addedRegionWrapper">
      <div className="regionName">
        <Dropdown
          name="countries"
          value={countries}
          fieldName="Select Region"
          options={WorldRegions}
          placeHolder={regionName}
          onChange={handleSelectedSingle}
          agreement={agreement}
          arr={arr}
        ></Dropdown>
      </div>
      <div className="regionName">
        <SearchableDropdown
          name="countries"
          isMulti
          selected={selected}
          fieldName="Select Countries"
          options={WorldCountries}
          worldRegion={regionName}
          placeHolder="Type to find countries"
          onChange={handleSelectedMulti}
          agreement={agreement}
          arr={arr[id].subRegions}
        ></SearchableDropdown>
      </div>
    </div>
  );
};

export default AddedRegion;
