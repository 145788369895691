const FutureDegree = [
  { label: "Management MBA/BBA", value: "1" },
  { label: "Engineering B.Tech and B.Arch, M.Tech, ME, BE", value: "2" },
  { label: "Computer Application-BCA/MCA", value: "3" },
  { label: "Designing - Fashion/Interior/Web", value: "4" },
  { label: "Mass-communication/Journalism BJMC", value: "5" },
  { label: "Hospitality (Hotel) - Hotel Management", value: "6" },
  { label: "Medical-BDS and MBBS", value: "7" },
  { label: "Finance -B.Com/CA/CFA", value: "8" },
  { label: "Arts Psychology and Sociology", value: "9" },
  { label: "Law B.ALLB/LLB", value: "10" },
  { label: "Pharmacy B.Pharma/M.Pharma", value: "11" },
  { label: "Tourism management - B.Sc.", value: "12" },
  { label: "Fine Arts B.F.A", value: "13" },
  { label: "Nursing B.Sc. and M.Sc. in Nursing", value: "14" },
  { label: "Dental- BDS", value: "15" },
];

export default FutureDegree;
