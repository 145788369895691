import React, { useState } from "react";
import "./ForgotPassPhno.css";
import Message from "../../LeftBanner";
import Lock from "../../../assets/Lock.png";
import { Form, Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useUserAuth } from "../../../context/UserAuthContext.js";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../.././State/index";

const ForgotPassPhno = (e) => {
  const [phno, setPhno] = useState("");
  const [error, setError] = useState("");
  const [result, setResult] = useState("");
  const [otp, setOtp] = useState("");
  const [flag, setFlag] = useState(false);

  const { setUpRecaptha } = useUserAuth();
  const navigate = useNavigate();

  const emailForgotHandle = () => {
    navigate("/forgotpassword/email");
  };

  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);

  const getOtp = async (e) => {
    e.preventDefault();
    console.log("ForgotForm form submitted");
    setError("");
    if (phno === "" || phno === undefined)
      return setError("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptha(phno);
      setResult(response);
      console.log("response is", response);
      setFlag(true);
      // using redux
      actions.updatePhno(phno);
    } catch (err) {
      setError(err.message);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;
    try {
      await result.confirm(otp);
      navigate("/forgotpassword/phno/resetpassword");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="forgotPassWrapper">
      <div className="messageLogin">
        <Message></Message>
      </div>
      <div
        className="forgotPassForm"
        style={{ display: !flag ? "flex" : "none" }}
      >
        <div className="midWrapForgotPass">
          <div className="Lock">
            <img src={Lock} alt="/" className="OtpImg" />
          </div>
          <div className="pageName">Forgot Password?</div>
          <div className="dontWorryMsg">
            Don’t worry! it happens. Please enter the Phone Number associated
            with your account we’ll send you an OTP to verity it’s you.
          </div>
          <form onSubmit={getOtp} id="forgotPassForm">
            <div className="phonenumWrap">
              <PhoneInput
                defaultCountry="IN"
                value={phno}
                onChange={setPhno}
                placeholder="Enter Phone Number"
                required
              />
              <div id="recaptcha-container"></div>
            </div>
          </form>
          <button
            type="submit"
            className="yellowBtn"
            form="forgotPassForm"
            value="Submit"
          >
            Next
          </button>
          <div className="methodChoice">Or</div>
          <div className="userIDForgot">
            <Button
              className="userIDForgotBtn"
              onClick={emailForgotHandle}
              className="whiteBtn"
            >
              Forgot Via Email or User ID
            </Button>
          </div>
        </div>
      </div>
      <div className="verifyOTP" style={{ display: flag ? "flex" : "none" }}>
        <div className="midWrapperRegister">
          <div className="OtpImgCon">
            <img src={Lock} alt="/" className="OtpImg" />
          </div>
          <div className="otpMessage">OTP Verification</div>
          <div className="OtpSent">
            We have send you a 6 digit OTP Verification code to{" "}
            <span className="OtpSent" style={{ fontWeight: "700" }}>
              {phno}
            </span>
          </div>
          <Form onSubmit={verifyOtp} className="otpForm">
            <Form.Group controlId="formBasicOtp">
              <Form.Control
                type="otp"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
                className="form-control"
                style={{ marginBottom: "2rem" }}
              />
            </Form.Group>
            <div className="otpSubmitButton">
              <Button type="submit" variant="primary" className="yellowBtn">
                Verify
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassPhno;
