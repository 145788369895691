import React, { useState, useRef, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import "./index.css";

const MultiSelectDropdown = ({
  options = [],
  onSelect,
  placeholderDropdown = "Select options",
  placeholderInput = "Search...",
  showSelectAll = true, // Flag to show or hide the Select All button
  showSearchBar = true, // Flag to show or hide the Search Bar
}) => {
  const DropdownIcon = () => {
    return (
      <svg height="20" width="20" viewBox="0 0 20 20">
        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
      </svg>
    );
  };

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    const isAlreadySelected = selectedOptions.some(
      (selected) => selected.value === option.value
    );

    if (isAlreadySelected) {
      const newSelections = selectedOptions.filter(
        (selected) => selected.value !== option.value
      );
      setSelectedOptions(newSelections);
      setSelectAll(false); // Reset select all if an individual item is deselected
      if (onSelect) {
        onSelect(newSelections);
      }
    } else {
      const newSelections = [...selectedOptions, option];
      setSelectedOptions(newSelections);
      if (onSelect) {
        onSelect(newSelections);
      }
    }

    setSearchTerm("");
  };

  const handleSelectAll = (event) => {
    event.preventDefault();
    if (selectAll) {
      setSelectedOptions([]);
      setSelectAll(false);
    } else {
      setSelectedOptions(options);
      setSelectAll(true);
    }
    if (onSelect) {
      onSelect(selectAll ? [] : options);
    }
    // Close dropdown after selecting all
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Sort the options so that selected ones appear at the top
  const sortedOptions = options.sort((a, b) => {
    const isASelected = selectedOptions.some(
      (selected) => selected.value === a.value
    );
    const isBSelected = selectedOptions.some(
      (selected) => selected.value === b.value
    );
    if (isASelected && !isBSelected) return -1;
    if (!isASelected && isBSelected) return 1;
    return 0;
  });

  const filteredOptions = sortedOptions.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const maxVisibleOptions = 2;
  const visibleOptions = selectedOptions.slice(0, maxVisibleOptions);
  const additionalCount = selectedOptions.length - maxVisibleOptions;

  return (
    <div className="multiSelectDropdownWrapper" ref={dropdownRef}>
      <div
        className="multiSelectDropdownToggle"
        onClick={() => setIsOpen(!isOpen)}
        role="button"
      >
        {selectedOptions.length !== 0 && (
          <span className="searchDropdownLabel">{placeholderDropdown}</span>
        )}
        <div className="selectedMultiOptions">
          {selectedOptions.length > 0 ? (
            <>
              {visibleOptions.map((option) => (
                <span key={option.value} className="selectedOption">
                  {option.label}
                </span>
              ))}
              {additionalCount > 0 && (
                <span className="moreOptions">+{additionalCount}</span>
              )}
            </>
          ) : (
            placeholderDropdown
          )}
        </div>
        <div>
          <DropdownIcon />
        </div>
      </div>

      {isOpen && (
        <div className="multiSelectDropdownMenu">
          <div className="searchAndSelectAll">
            <div
              className="searchBarMultiDropdown"
              style={{ display: showSearchBar ? "flex" : "none" }}
            >
              {showSearchBar && (
                <input
                  type="text"
                  placeholder={placeholderInput}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="dropdownSearchInput"
                />
              )}
            </div>
            <div className="selectAllMultiDropdown">
              {showSelectAll && (
                <button className="selectAllButton" onClick={handleSelectAll}>
                  {selectAll ? "Deselect All" : "Select All"}
                </button>
              )}
            </div>
          </div>
          <ul>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  className={
                    selectedOptions.some(
                      (selected) => selected.value === option.value
                    )
                      ? "selected"
                      : ""
                  }
                >
                  {selectedOptions.some(
                    (selected) => selected.value === option.value
                  ) && <FaCheck className="tickIcon" />}
                  {option.label}
                </li>
              ))
            ) : (
              <li>No options found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
