const Agg = [
    { label: "90%-100%", value: "1" },
    { label: "80%-90%", value: "2" },
    { label: "70%-80%", value: "3" },
    { label: "60%-70%", value: "4" },
    { label: "50%-60%", value: "5" },
    { label: "40%-50%", value: "6" },
    { label: "30%-40%", value: "7" },
    { label: "20%-30%", value: "8" },
    { label: "10%-20%", value: "9" },
    { label: "0-10%", value: "10" },
  ];
  
  export default Agg;