import React from "react";
import PersonCard from "../PersonCard";
import "./index.css";
import Slider from "react-slick";
import companyImg1 from "../../../../assets/company1.png";
const TestimonialsArr = [
  {
    name: "Dr. Min Park",
    job: "Advisor",
    testimonial:
      "It’s been exciting to see OpGuru’s growth.  It is clear that they are dedicated to encouraging young talent, and I am excited to accompany them on their journey as they grow and continue to influence career development going forward.",
    companyImg: companyImg1,
  },
  {
    name: "Kushal Jhanwar",
    job: "Mentor",
    testimonial:
      "I think OpGuru's approach to career counselling is excellent, and I'm thrilled to have the chance to help out as a mentor. The platform's emphasis on individualised assistance and practical insights is exactly what I'm passionate about—assisting others in achieving their goals.",
    companyImg: companyImg1,
  },
  {
    name: "Sagar Naskar",
    job: "Student",
    testimonial:
      "I’m willing to pursue a career in the UAE and I’m very looking forward to OpGuru’s new platform. It appears that this will be a fantastic resource for opportunities research and assistance, enabling me to take full advantage of this great opportunity!",
    companyImg: companyImg1,
  },
  {
    name: "Ishaan Jain",
    job: "Employee",
    testimonial:
      "OpGuru is a stimulating and motivating place to work. Here, growth and innovation are encouraged by the innovative and supportive culture, which makes it an excellent environment to contribute to significant professional development and observe the results of our work personally.",
    companyImg: companyImg1,
  },
  {
    name: "Partha Chakraborty",
    job: "Parent",
    testimonial:
      "I'm excited to consult OpGuru in order to support my daughter in pursuing her professional objectives in Germany. I'm not well aware of abroad studies so it's really beneficial for parents like us if they provide such guidance to shape her future.",
    companyImg: companyImg1,
  },
  {
    name: "Rahul More",
    job: "Mentor",
    testimonial:
      "It's really wonderful to see OpGuru's creative approach to career development. I'm excited to take part as a mentor and impart my knowledge to assist others in pursuing their goals and navigating their career paths.",
    companyImg: companyImg1,
  },
];

const CarouselTestimonials = () => {
  const settings = {
    dots: true,
    autoplay: true,
    slidesToShow: 3,
    centerMode: true,
    swipeToSlide: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="carouselWrapper">
      <Slider {...settings}>
        {TestimonialsArr.map((testi) => {
          return (
            <div className="carouselItem">
              <PersonCard
                name={testi.name}
                job={testi.job}
                testimonial={testi.testimonial}
                companyImg={companyImg1}
              ></PersonCard>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default CarouselTestimonials;
