import React from "react";
import "./index.css";

const MentorCard = ({
  name,
  profileImg,
  location,
  description,
  profession,
  students_mentored,
  review_stars,
}) => {
  return (
    <div className="mentorCardWrapper">
      <div className="mentorCardImgWrapper">
        <img className="mentorCardImg" src={profileImg} alt="/" />
        <div className="mentorCardConnect">
          <button className="yellowBtnConnect">Connect</button>
        </div>
      </div>
      <div className="mentorCardDetails">
        <div className="mentorDetail">
          <div className="mentorField">Name : </div>
          <div className="fieldInfo">{name}</div>
        </div>
        <div className="mentorDetail">
          <div className="mentorField">Location :</div>
          <div className="fieldInfo">{location}</div>
        </div>
        <div className="mentorDetailRow">
          <div className="mentorField">About the Mentor :</div>
          <div className="fieldInfo">{description}</div>
        </div>
        <div className="mentorDetailRow">
          <div className="mentorField">Profession :</div>
          <div className="fieldInfo">{profession}</div>
        </div>
        <div className="mentorDetail">
          <div className="mentorField">Review :</div>
          <div className="fieldInfo">{review_stars} ★</div>
        </div>
        <div className="mentorDetail">
          <div className="mentorField">Students Mentored :</div>
          <div className="fieldInfo">{students_mentored}</div>
        </div>
      </div>
    </div>
  );
};

export default MentorCard;
