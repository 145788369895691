import { useNavigate } from "react-router-dom";
import "./mobile-nav.css";
import OpGuruRoutes from "./OpGuruRoutes.js";
import { useState } from "react";

export default function MobileNav() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div>
        <div className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <nav className={isOpen ? "mobileMenu isOpen" : "mobileMenu"}>
          <ul>
            {OpGuruRoutes.map((route, key) => {
              console.log("window location is ", window.location.pathname);
              return (
                <li onClick={() => navigate(`/${route.linkTo}`)}>
                  {route.name}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </>
  );
}
