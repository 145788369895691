import React from "react";

const MyButton = ({
  title,
  titleColor,
  buttonColor,
  buttonWidth,
  buttonOnClick,
  borderRadius,
  buttonFontSize,
  paddingV,
  paddingH,
}) => {
  const buttonStyles = {
    width: buttonWidth || "100%", // Default width if not provided
    borderRadius: borderRadius || "10px", // Default border radius if not provided
    backgroundColor: buttonColor || "#dfa114", // Default color if not provided
    paddingTop: paddingV || "0.5rem",
    paddingBottom: paddingV || "0.5rem",
    paddingRight: paddingH || "1rem",
    paddingLeft: paddingH || "1rem",
    border: "none",
    cursor: "pointer",
    fontSize: buttonFontSize || "2rem",
    color: titleColor || "black",
  };
  return (
    <button style={buttonStyles} onClick={buttonOnClick}>
      {title}
    </button>
  );
};

export default MyButton;
