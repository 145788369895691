import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = () => {
  const handleClick = () => {
    toast("Welcome");
  };
  return (
    <div>
      {" "}
      <button onClick={handleClick}>Welcome</button>
      <ToastContainer />
    </div>
  );
};

export default Toast;
