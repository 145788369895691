import React, { useState } from "react";
import MyButton from "../../Usables/Buttons/Button";
import "./index.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    enquiry: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  return (
    <div className="form-container-contact-us">
      <div className="form-title-contact-us">Contact Us</div>
      <div className="form-description">
        Learn more about how OpGuru can help you with your journey
      </div>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label className="form-label">First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Business Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Your Enquiry</label>
          <textarea
            name="enquiry"
            value={formData.enquiry}
            onChange={handleChange}
            className="form-textarea"
          />
        </div>
        <button type="submit" className="form-submit-button">
          Submit
        </button>
      </form>
      <p className="form-footer">
        By submitting your information, you agree to receive promotional emails
        from OpGuru. You can manage your email preferences or unsubscribe at any
        time.
      </p>
    </div>
  );
};

export default ContactForm;
