import React, { useState, useEffect } from "react";
import "./newpasswordphno.css";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Message from "../../LeftBanner";
import { Button } from "react-bootstrap";
import Showpassword from "../../../assets/showPass.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Resetpassword = () => {
  const [visible, setVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  // getting current phonenum from redux
  const phonenum = useSelector((state) => state.phonenum);

  const changeVisiblityPass = () => {
    setVisible(!visible);
  };

  const changeVisiblityConfirmPass = () => {
    setVisibleConfirm(!visibleConfirm);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    if (password === confirmpassword) {
      const userdetails = {
        phno: phonenum,
        password: password,
      };
      try {
        const response = await axios.post(
          "http://localhost:8080/api/updatepassword/phno",
          userdetails
        );
        // const data = JSON.stringify(response?.data);
        // console.log("data is ", JSON.stringify(response?.data));
        // if (data) {
        //   setPassword("");
        //   setConfirmPassword("");
        //   setSuccess(true);
        //   toast.success("Password updated successfully", {
        //     position: "top-center",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //   });
        //   navigate("/passwordresetsuccess");
        // }
        setPassword("");
        setConfirmPassword("");
        toast.success("Password updated successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/passwordresetsuccess");
      } catch (err) {
        toast.error(err.response.data, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      alert("Passwords are not matching");
    }
  };
  return (
    <div className="resetPassWrapper">
      <div className="messageLogin">
        <Message></Message>
      </div>
      <div className="resetFormWrapper">
        <div className="midWrapperReset">
          <div className="pageName">Reset password</div>
          <div className="dontWorryMsg">
            Your new password must be different from previous passwords
          </div>
          <form onSubmit={handleReset} id="resetForm">
            <div className="passwordWrap">
              <input
                type={visible ? "text" : "password"}
                id="password"
                placeholder="Password"
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                className="inputPassword"
              />
              <div className="showPassword">
                <Button
                  className="showPasswordBtn"
                  onClick={changeVisiblityPass}
                >
                  <img src={Showpassword} alt="/" />
                </Button>
              </div>
            </div>
            <div className="passwordWrap">
              <input
                type={visibleConfirm ? "text" : "password"}
                id="confirmpassword"
                placeholder="Confirm New Password"
                autoComplete="off"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmpassword}
                required
                className="inputPassword"
              />
              <div className="showPassword">
                <Button
                  className="showPasswordBtn"
                  onClick={changeVisiblityConfirmPass}
                >
                  <img src={Showpassword} alt="/" />
                </Button>
              </div>
            </div>
          </form>
          <button
            type="submit"
            className="yellowBtn"
            form="resetForm"
            value="Submit"
          >
            Reset Password
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Resetpassword;
