import React, { useState, useRef, useEffect } from "react";
import Message from "../LeftBanner";
import { useNavigate, Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useUserAuth } from "../../context/UserAuthContext.js";
import "./register.css";
import { Form, Button } from "react-bootstrap";
import OtpImg from "../../assets/Lock.png";
import { auth } from "../../firebase.js";
import InputField from "../Usables/InputField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { TailSpin } from "react-loader-spinner";
import OpguruLogo from "../../assets/opgurulogo.png";
import GoogleButton from "react-google-button";
import GoogleIcon from "../../assets/google.png";
import { useLoader } from "../../context/LoaderContext.js";

const Register = (props) => {
  const { setIsLoading } = useLoader();
  console.log("setisloading function is ", setIsLoading);
  const elementRef = useRef(null);
  const captchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [phno, setPhno] = useState("");
  const [otp, setOtp] = useState("");
  const [flag, setFlag] = useState(false);
  const [isdisable, setDisable] = useState(false);

  const { googleSignIn } = useUserAuth();

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
  });

  const { name, email } = inputValue;

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(inputValue);
  };

  useEffect(() => {
    setFlag(flag);
  }, [flag]);

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/usertype");
    } catch (error) {
      console.log(error.message);
    }
  };

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            handleSubmit();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted");
    setLoading(true);
    if (phno === "" || phno === undefined) {
      toast.error("Please enter a valid phone number!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phno, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setFlag(true);
        toast.success("OTP sended successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
        setLoading(false);
      });
  };

  const onOTPVerify = (e) => {
    e.preventDefault();
    // e.preventDefault() is very important for a form
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setLoading(false);
        navigate("/enterdetails", {
          state: { email: email, name: name, phno: phno },
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  return (
    <div className="registerWrapper">
      <div className="messageLogin">
        <Message></Message>
      </div>
      <div
        className="userRegisterForm"
        style={{ display: !flag ? "flex" : "none" }}
      >
        <div ref={elementRef} className="midWrapperRegister">
          <div className="authMessage">
            <div className="authMessageText">Sign Up to</div>
            <div
              className="opguruLogoAuth"
              onClick={() => {
                navigate("/home");
              }}
            >
              <img src={OpguruLogo} alt="opguruLogo" />
            </div>
          </div>
          <div className="welcomeMessage">
            Please enter your details to Sign Up.
          </div>
          <form onSubmit={handleSubmit} id="registerForm">
            <div className="nameWrap">
              <InputField
                type="text"
                value={name}
                placeholder="Full Name"
                name="name"
                onChange={handleChange}
              />
            </div>
            <div className="phonenumWrap">
              <PhoneInput
                defaultCountry="IN"
                value={phno}
                onChange={setPhno}
                placeholder="Enter Phone Number"
                required
              />
              <div ref={captchaRef} id="recaptcha-container"></div>
              {/* {error && <Alert variant="danger">{error}</Alert>} */}
            </div>
            <div className="emailWrap">
              <InputField
                type="text"
                value={email}
                placeholder="Email"
                name="email"
                onChange={handleChange}
              />
            </div>
          </form>
          <div className="termsConditions">
            By signing up, you are agreeing to our
            <span className="termsConditions" style={{ fontWeight: "700" }}>
              Terms And Conditions.
            </span>
          </div>
          <button
            type="submit"
            className="yellowBtn"
            form="registerForm"
            value="Submit"
            disabled={isdisable}
          >
            Next
          </button>
          {loading && (
            <TailSpin
              visible={true}
              height="40"
              width="40"
              color="#000000"
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
          {/* <div className="methodChoice">Or</div>
          <Button className="whiteBtn" onClick={handleGoogleSignIn}>
            <div className="googleSignUp">
              <div className="googleIcon">
                <img src={GoogleIcon} alt="/" className="GoogleImg" />
              </div>
              <div className="googleMessage">Sign Up with Google</div>
            </div>
          </Button> */}
          <div className="alreadyLogin">
            Already have an account?
            <Link to="/login" className="linkStyle1">
              {" "}
              Login
            </Link>
          </div>
          <div className="alreadyLogin">
            Go back to
            <Link to="/" className="linkStyle1">
              {" "}
              Home
            </Link>
          </div>
        </div>
      </div>
      <div className="verifyOTP" style={{ display: flag ? "flex" : "none" }}>
        <div className="midWrapperRegister">
          <div className="OtpImgCon">
            <img src={OtpImg} alt="/" className="OtpImg" />
          </div>
          <div className="otpMessage">OTP Verification</div>
          <div className="OtpSent">
            We have send you a 6 digit OTP Verification code to{" "}
            <span className="OtpSent" style={{ fontWeight: "700" }}>
              {phno}
            </span>
          </div>
          <Form onSubmit={onOTPVerify} style={{ width: "100%" }}>
            <Form.Group controlId="formBasicOtp">
              <Form.Control
                type="otp"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
                className="form-control"
                style={{ marginBottom: "2rem" }}
              />
            </Form.Group>
            <div className="otpSubmitButton">
              <Button type="submit" variant="primary" className="yellowBtn">
                Verify
              </Button>
            </div>
            {/* {loading && (
              <TailSpin
                visible={true}
                height="40"
                width="40"
                color="#000000"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )} */}
          </Form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
