import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
function ProfileCard({ personalData, onEditClick }) {
  
    const iconStyle = {
        color: 'black', // Set the background color to white
      };  

  return (
    <div className="card">
      <h1 className="card-title">Personal Details</h1>
      <button className="edit-button" onClick={onEditClick}>
      <FontAwesomeIcon icon={faPencilAlt} style = {iconStyle} /> 
      </button>
      <div className="flex flex-wrap">
        <div className="flex flex-column flex-cell">
          <div className="card-details">Full Name</div>
          <div className="card-subtitle">{personalData.fullName}</div>
        </div>
        <div className="flex flex-column flex-cell">
          <div className="card-details">Contact Number</div>
          <div className="card-subtitle">{personalData.contactNumber}</div>
        </div>
        <div className="flex flex-column flex-cell">
          <div className="card-details">Email</div>
          <div className="card-subtitle">{personalData.email}</div>
        </div>
        <div className="flex flex-column flex-cell">
          <div className="card-details">Gender</div>
          <div className="card-subtitle">{personalData.gender}</div>
        </div>
        <div className="flex flex-column flex-cell">
          <div className="card-details">Address</div>
          <div className="card-subtitle">{personalData.address}</div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;