import React from "react";
import "./index.css";
import LatestOppurtunity from "./LatestOppurtunity/index.js";
import LatestOppsList from "./LatestOpps";
const LatestOpps = () => {
  return (
    <div className="latestOppsWrapper">
      <div className="latestOppsHeading">LATEST OPPURTUNITIES</div>
      <div className="searchbarLatestOpps"></div>
      <div className="latestOpps">
        {LatestOppsList.map((opp) => {
          return (
            <LatestOppurtunity
              name={opp.name}
              views={opp.views}
              time={opp.time}
            ></LatestOppurtunity>
          );
        })}
      </div>
      <div className="viewAllLatestOpps">
        <button className="viewAllBtn">View All</button>
      </div>
    </div>
  );
};

export default LatestOpps;
