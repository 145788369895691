import React, { useState, useEffect, useRef } from "react";
//import ProficiencyExams from "./ProficiencyExam.js";
import deleteImg from "../../../../../assets/delete.png";
import "./index.css";
const BachelorsExam = ({ id, exams, setExams, options }) => {
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const [visible, setVisible] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [wordshow, setWordShow] = useState("");
  const [finalArr, setFinalArr] = useState([]);
  const [criteria, SetCriteria] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleChangeDate = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setExams((s) => {
      const newArr = s.slice();
      newArr[index - 1].date = e.target.value;
      return newArr;
    });
  };

  const handleChangePercent = (e) => {
    //e.preventDefault();
    const index = e.target.id;
    exams[index - 1].percentage = e.target.value;
    setExams((s) => {
      const newArr = s.slice();
      newArr[index - 1].percentage = e.target.value;
      console.log("the exam right now is ", newArr);
      return newArr;
    });
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = options.filter((value) => {
      return value.name
        .toString()
        .toLowerCase()
        .includes(searchWord.toString().toLowerCase());
    });
    setFinalArr(newFilter);
    const newObj = {
      name: "Please enter complete name of Exam",
      criteria: "100%",
      examName: searchWord,
    };

    if (searchWord === "") {
      setFilteredData(options);
    } else {
      setFinalArr((current) => [...current, newObj]);
      setFilteredData(finalArr);
    }
    setVisible(true);
  };

  const OnItemClick = (event) => {
    const crt = event.criteria;
    const name = event.name;
    const examName = event.examName;
    console.log("current state is", crt, name, examName);
    if (name === "Please enter complete name of Exam") {
      setVisible(false);
      setWordShow(examName);
      SetCriteria(crt);
    } else {
      exams[id - 1].name = name;
      setVisible(false);
      setWordEntered(name);
      SetCriteria(crt);
    }
  };

  const deleteExam = () => {
    // if (id === 1) return;
    setExams((current) =>
      current.filter((ex) => {
        if (ex.id > 1 && ex.id === id) {
          return false;
        } else {
          return true;
        }
      })
    );
  };

  return (
    <div className="examWrapper">
      <div
        className="searchDropdown"
        style={{ marginBottom: "2rem" }}
        ref={dropdownRef}
      >
        <input
          type="text"
          value={wordEntered}
          className="form-control"
          placeholder="Proficiency Exam"
          onChange={handleFilter}
          onClick={handleFilter}
        />
        <div
          className="exam-dropdown-menu"
          style={{ display: visible ? "flex" : "none" }}
        >
          {filteredData.map((option) => (
            <div className="dropdown-item" onClick={() => OnItemClick(option)}>
              <div className="singleItem">
                <div className="itemName">{option.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="examDetails">
        <div className="examPercent">
          <div className="examScore">
            <input
              placeholder="Score"
              className="scoreInput"
              // value={valuePercent}
              id={id}
              onChange={handleChangePercent}
            ></input>
          </div>
          <div className="outOfTeller">
            {criteria == "" ? "100 %" : criteria}
          </div>
        </div>
        <div className="examDate">
          <div className="datePicker">
            <input
              placeholder="Date of Exam"
              type="date"
              className="datePickerReact"
              // value={valueDate}
              id={id}
              onChange={handleChangeDate}
            ></input>
          </div>
        </div>
        <div className="examDelete" onClick={deleteExam}>
          <img src={deleteImg} alt="/" />
        </div>
      </div>
    </div>
  );
};

export default BachelorsExam;
